import Smart from 'hocs/smart';
import { legsForAssetOrdered, historicalLegsForAsset } from 'selectors/legs';
import AssetContextBox from './assetContextBox-view';

export default Smart(
  AssetContextBox,
  (state, ownProps) => ({
    organisationId: state.session.organisationId,
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // assetState: state.assetState[ownProps.selectedAsset?.id],
    now: state.app.now,
    timezone: state.settings.locale.timezone,
  }),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar,
    setMostRecentDeviceReport: ac.reports.setMostRecentDeviceReport,
  })
);
