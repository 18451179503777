import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  wrapper: {
    position: 'relative',
    width: '90%',
    color: 'pink',
    zIndex: 1
  },
  mapView: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRow: 'span 1',
    gridColumn: 'span 1'
  }
}));
