import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    gridArea: 'nav',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.palette.common.white,
    borderRight: theme.border.default,
    transition: 'all 0.1s',
    overflow: 'hidden',
    zIndex: 11,
    boxShadow: '1px 0 2px -1px rgba(0, 0, 0, .15), 4px 0 5px 0 rgba(0, 0, 0, .08)',
    position: 'absolute',
    height: '100%'
  },
  open: {
    width: '150px',
    transitionDelay: '0.3s'
  },
  closed: {
    width: '43px'
  },
  openCloseButton: {
    maxWidth: '42px'
  }
}));
