import React from 'react';
import useTranslation from 'hooks/useTranslation';
import insensitiveSort from 'utils/insensitiveSort';
import {
  Container,
  Box,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import { capitalizeFirstLetter } from 'helpers/text';
import tableIcons from 'components/shared/icons/tableIcons';
import { useQuery } from 'react-query';
import { fetchAssetsDevices } from 'apis/trackstar/serenity';
import useStyles from './devicesList-styles';
import PersistentTable from "components/shared/persistentTable";

const DeviceListPage = ({
  organisationId,
  tableSettings,
  serialType,
  displaySnackbar,
  updateSetting
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.devicesList');
  const serialTypes = ['tpSerial', 'imei', 'manufacturerSerial'];
  const maxTableHeight = `${window.innerHeight > 570 ? window.innerHeight - 420 : 150}px`;

  const getAssets = useQuery(['assets', organisationId, null], () => fetchAssetsDevices());
  if (getAssets.isError) {
    displaySnackbar({
      id: 'assetLoadError',
      text: getAssets?.error ? getAssets.isError.toString() : '',
      type: 'error',
    });
  }

  const cleanedDevices = !getAssets.isSuccess ? [] : getAssets.data.devices.map(device => {
    const asset = getAssets.data.assets.find(a => a.id === device.assetId);
    const assetMakeModel = asset.make || asset.model ? `(${[asset.make, asset.model].filter(Boolean).join(' ')})` : '';
    return {
      id: device?.id,
      imei: device?.imei ?? '-',
      tpSerial: device?.tpSerial ?? '-',
      manufacturerSerial: device?.manufacturerSerial ?? '-',
      makeModel: [device?.make, device?.model].filter(Boolean).join(' '),
      status: capitalizeFirstLetter(t(device?.status.toLowerCase())),
      asset: `${asset.name} ${assetMakeModel}`,
    };
  });

  // TODO: Editing devices
  // const editDevice = rowData => {
  //   const link = `/device/${rowData.id}`;
  //   navigate(link);
  // };

  return (
    <Container className={classes.tableContainer} maxWidth="md">
      <Box className={classes.materialTable}>
        <Select
          variant="standard"
          id="serialType"
          value={serialType}
          displayEmpty
          label={t('language')}
          onChange={e => updateSetting('ui', 'serialType', e.target.value)}
          className={classes.select}
        >
          { serialTypes.map(st => <MenuItem key={st} value={st}>{t(st)}</MenuItem>) }
        </Select>
        <PersistentTable
          settingsCategory={'devicesTable'}
          title={t('title')}
          icons={tableIcons}
          data={cleanedDevices}
          columns={[{
            title: t(serialType),
            field: serialType,
            headerStyle: { textAlign: 'left' },
            cellStyle: { textAlign: 'left' },
            defaultSort: 'asc',
            customSort: (a, b) => insensitiveSort(a[serialType].toString(), b[serialType].toString()),
          }, {
            title: t('makeModel'),
            field: 'makeModel',
            headerStyle: { textAlign: 'left' },
            cellStyle: { textAlign: 'left' },
            customSort: (a, b) => insensitiveSort(a.makeModel, b.makeModel)
          }, {
            title: t('status'),
            field: 'status',
            headerStyle: { textAlign: 'left' },
            cellStyle: { textAlign: 'left' },
            customSort: (a, b) => insensitiveSort(a.status, b.status)
          }, {
            title: t('assignedAsset'),
            field: 'asset',
            headerStyle: { textAlign: 'left' },
            cellStyle: { textAlign: 'left' },
            customSort: (a, b) => insensitiveSort(a.asset, b.asset)
          }]}
          // TODO: Device editing (we have no more information to show)
          // actions={[{
          //   icon: () => <Edit className={classes.actionButton} />,
          //   tooltip: t('editDevice'),
          //   onClick: (e, row) => editDevice(row),
          //   disabled: !user.isStaff,
          //   hidden: !user.isStaff
          // }
          // TODO: Disabling device creation until we turn sync off (because it's not compatible with Legacy sync)
          // , {
          //   icon: () => <Add />,
          //   iconProps: { color: 'primary' },
          //   tooltip: t('newButton'),
          //   isFreeAction: true,
          //   onClick: event => event.preventDefault(),
          //   disabled: !user.isStaff,
          //   hidden: !user.isStaff
          // }
          // ]}
          options={{
            draggable: false,
            showTitle: false,
            search: true,
            paging: true,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            searchFieldStyle: {
              borderRadius: '4px',
              paddingLeft: '18px',
              paddingRight: '10px'
            },
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: maxTableHeight,
          }}
          // TODO: Device editing
          // onRowClick={(e, rowData) => {
          //   editDevice(rowData);
          // }}
          components={{
            // TODO: Device creation on trackstar
            // eslint-disable-next-line react/destructuring-assignment
            // Action: props => ((props.action.position === 'toolbar') && user.isStaff
            //   ? (
            //     <Button
            //       component={Link}
            //       to="/device/new"
            //       className={classes.newButton}
            //       variant="contained"
            //     >
            //       <Add />
            //       {t('newButton')}
            //     </Button>
            //   ) : <MTableAction {...props} />)
          }}
        />
      </Box>
    </Container>
  );
};

export default DeviceListPage;
