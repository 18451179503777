import React, { useEffect } from 'react';
import darkTheme from 'themes/darkTheme';
import lightTheme from 'themes/lightTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as ThemeProvider2 } from '@mui/styles';
import { Box } from '@mui/material';
import Router from 'components/router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import SnackbarWrapper from 'components/snackbarWrapper';
import compatibilityCheck from 'utils/compatibility';
import LiveUpdateDummy from 'components/mapContainer/liveUpdateDummy/liveUpdateDummy-redux';
import mixpanel from 'mixpanel-browser';
import PageTitle from './pageTitle';

import useStyles from './app-styles';

const Application = ({
  isDarkMode,
  toggleDarkMode,
  navigate,
  now,
  loggedIn,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!compatibilityCheck()) navigate('/compatibility');
  }, []);

  // CHECK FOR DARKMODE PREFERENCE
  let preferredTheme = lightTheme;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  if (isDarkMode === null && prefersDarkMode) {
    preferredTheme = darkTheme;
    toggleDarkMode();
  } else {
    preferredTheme = isDarkMode
      ? darkTheme
      : lightTheme;
  }

  const isProduction = window.location.hostname.toLowerCase() === 'cloud.tracplus.com';
  const mixpanelProdToken = 'ac2c34b6033814a2b69193a0cc51896e';
  const mixpanelDevToken = '81544ac2daaad44037897132b6b7917b';
  mixpanel.init(isProduction ? mixpanelProdToken : mixpanelDevToken, { debug: true });

  // NOTE: separate ThemeProvider components from @mui/material/styles and @mui/styles is used for now to provide to different components.
  // This shouldn't need to be done but seems to be necessary for @material-table/core

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={preferredTheme}>
        <ThemeProvider2 theme={preferredTheme}>
          <DndProvider backend={HTML5Backend}>
            <CssBaseline enableColorScheme />
            <PageTitle />
            <SnackbarWrapper>
              <Box className={classes.app}>
                {/* Disable live reports in historical mode */}
                {!now && loggedIn && <LiveUpdateDummy now={now} />}
                <Router />
              </Box>
            </SnackbarWrapper>
          </DndProvider>
        </ThemeProvider2>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Application;
