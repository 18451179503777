import geomagnetism from 'geomagnetism';
import moment from 'moment';
import LatLon from 'geodesy/latlon-spherical';

const distanceUnitsDetails = units => {
  switch (units.toLowerCase().replace(' ', '')) {
    case 'kilometres':
    case 'km':
      return {
        ratio: 1 / 1000,
        label: 'km'
      };
    case 'statutemiles':
    case 'miles':
      return {
        ratio: 1 / (1000 * 1.609344),
        label: 'mi'
      };
    case 'nauticalmiles':
      return {
        ratio: 1 / 1852,
        label: 'nm'
      };
    case 'metres':
      return {
        ratio: 1,
        label: 'm'
      };
    case 'feet':
      return {
        ratio: 3.28084,
        label: 'ft'
      };
    default:
      throw new Error(`No conversion for distance to ${units}`);
  }
};
export const distance = {
  fromSI: (distanceInMetres, targetUnits) => distanceInMetres * distanceUnitsDetails(targetUnits).ratio,
  // Returns distance along surface of the earth between two points (using haversine formula)
  distanceTo: (lat1, long1, lat2, long2) => (
    new LatLon(lat1, long1).distanceTo(new LatLon(lat2, long2))
  ),
  // alternative to distanceTo; returns distance between two lat/long pairs, as the crow flies (in m)
  betweenCoords: (lat1, long1, lat2, long2) => {
    const toRad = value => (value * Math.PI) / 180; // Converts numeric degrees to radians
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(long2 - long1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
      + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(toRad(lat1)) * Math.cos(toRad(lat2));
    return 6371000 * (2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)));
  },
  withUnits: (distanceInUnits, targetUnits, decimalPlaces = 0) => (
    `${distanceInUnits.toFixed(decimalPlaces)}${distanceUnitsDetails(targetUnits).label}`
  ),
  label: units => distanceUnitsDetails(units).label
};
export const altitude = distance;

export const calculateDistance = (from, to, distanceUnits) => {
  if (!from.latitude || !from.longitude || !to.latitude || !to.longitude) return '--';
  return distance.fromSI(
    distance.distanceTo(from.latitude, from.longitude, to.latitude, to.longitude),
    distanceUnits
  );
};

const speedUnitsDetails = units => {
  switch (units.toLowerCase()) {
    case 'kmh':
    case 'km/h':
      return {
        ratio: 1,
        label: 'km/h'
      };
    case 'mph':
      return {
        ratio: 0.621371,
        label: 'mph'
      };
    case 'knots':
      return {
        ratio: 0.539957,
        label: 'kt'
      };
    default:
      throw new Error(`No conversion for speed to ${units}`);
  }
};
export const speed = {
  fromKmh: (speedInKilometresPerHour, targetUnits) => (
    speedInKilometresPerHour * speedUnitsDetails(targetUnits).ratio
  ),
  withUnits: (speedInUnits, targetUnits, decimalPlaces = 0) => (
    `${speedInUnits.toFixed(decimalPlaces)}${speedUnitsDetails(targetUnits).label}`
  ),
  label: units => speedUnitsDetails(units).label
};

const timestampWithinMagneticModelRange = timestamp => {
  const timestampMoment = moment(timestamp);
  return (timestampMoment.isAfter('2019-12-12') && timestampMoment.isBefore('2024-12-10'));
};
const bearingUnitsDetails = units => {
  switch (units.toLowerCase()) {
    case 'degreestrue':
    case 'degreesgeographic':
      return {
        fromSI: x => x,
        label: () => '°T',
      };
    case 'degreesmagnetic': {
      // Magnetic course is calculated using the World Magnetic Model (WMM). The model must change over time as the earth's
      // magnetic field changes and so every 5 years a new model is produced. The current model is only valid for dates between
      // 10 Dec 2019 and 10 Dec 2024. If the timestamp of the report is outside this range the below code falls back to °T.
      return {
        fromSI: (bearingInDegreesTrue, timestamp, latitude, longitude) => {
          if (!timestampWithinMagneticModelRange(timestamp)) return bearingInDegreesTrue;
          let dateTime;
          if (moment.isMoment(timestamp)) {
            dateTime = timestamp.toDate();
          } else if (typeof timestamp === 'string') {
            dateTime = new Date(timestamp);
          } else if (timestamp instanceof Date) {
            dateTime = timestamp;
          } else {
            console.error('Unknown timestamp type.');
            /* Let it crash the magnetism model. */
            dateTime = timestamp;
          }
          const { decl } = geomagnetism.model(dateTime).point([latitude, longitude]);
          return (bearingInDegreesTrue + decl + 360) % 360;
        },
        label: timestamp => (timestampWithinMagneticModelRange(timestamp) ? '°m' : '°T'),
      };
    }
    default:
      throw new Error(`No conversion for speed to ${units}`);
  }
};
export const bearing = {
  fromSI: (bearingInDegreesTrue, timestamp, { latitude, longitude }, targetUnits) => (
    bearingUnitsDetails(targetUnits).fromSI(bearingInDegreesTrue, timestamp, latitude, longitude)
  ),
  withUnits: (bearingInUnits, units, decimalPlaces = 0, timestamp) => (
    `${bearingInUnits.toFixed(decimalPlaces)}${bearingUnitsDetails(units).label(timestamp)}`
  ),
  label: (units, timestamp) => bearingUnitsDetails(units).label(timestamp)
};

const coordinateUnitsDetails = units => {
  switch (units.toLowerCase()) {
    case 'coordinatesdd':
      return {
        label: 'd',
        decimalPlaces: 5,
      };
    case 'coordinatesddm':
      return {
        label: 'dm',
        decimalPlaces: 3,
      };
    case 'coordinatesdms':
      return {
        label: 'dms',
        decimalPlaces: 1,
      };
    default:
      throw new Error(`No conversion for coordinate to ${units}`);
  }
};
export const coordinate = {
  fromLatLon: (lat, long, targetUnits) => {
    const dp = coordinateUnitsDetails(targetUnits).decimalPlaces;
    if (targetUnits === 'coordinatesDD') return `${lat.toFixed(dp)}, ${long.toFixed(dp)}`;
    return new LatLon(lat, long).toString(
      coordinateUnitsDetails(targetUnits).label,
      coordinateUnitsDetails(targetUnits).decimalPlaces
    );
  },
  label: units => coordinateUnitsDetails(units).label,
};

const areaUnitsDetails = units => {
  switch (units.toLowerCase()) {
    case 'squarekilometres':
      return {
        label: 'km²'
      };
    case 'acres':
      return {
        label: 'acres'
      };
    case 'hectares':
      return {
        label: 'ha'
      };
    case 'squaremiles':
      return {
        label: 'mi²'
      };
    case 'squarenauticalmiles':
      return {
        label: 'nm²'
      };
    default:
      throw new Error(`No conversion for speed to ${units}`);
  }
};
export const area = {
  label: units => areaUnitsDetails(units).label
};
