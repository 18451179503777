import Smart from 'hocs/smart';
import View from './speed-view';

export default Smart(
  View,
  state => ({
    units: state.settings.units.speed
  }),
  () => ({
  })
);
