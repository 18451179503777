import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import ButtonIcon from '../buttonIcon';
import useStyles from './category-styles';

const CreateAssetCategory = ({
  categories,
  currentCategoryId,
  onSelectCategoryId,
  t
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h1" className={classes.headingText}>{t('categoryDescription')}</Typography>
      <Grid container spacing={3} justifyContent="center">
        {categories.map(c => (
          <Grid item xs={12} sm={3} key={c.id}>
            <Box
              className={clsx(classes.groupButton, { [classes.isCurrent]: currentCategoryId === c.id })}
              onClick={() => onSelectCategoryId(c.id)}
            >
              <Box className={classes.button}>
                <Box className={classes.iconWrapper}>
                  <ButtonIcon name={c.label} />
                </Box>
                <Typography className={classes.buttonText}>{t(c.label.toLowerCase())}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CreateAssetCategory;
