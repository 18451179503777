import React from 'react';
import { Box, Toolbar } from '@mui/material';

import SystemStatusIcon from './systemStatus';
import CursorPosition from './cursorPosition';
import Clock from './clock';
import Regional from './regional';

import useStyles from './statusbar-styles';

const StatusBar = ({
  toggleSystemStatus
}) => {
  const classes = useStyles();

  // TODO: pull from trackstar (if we actually implement this feature)
  // health {
  //   connection
  //   messages
  //   services {
  //     name
  //     status
  //     message
  //   }
  // }

  return (
    <Toolbar className={classes.toolbar} disableGutters>

      <SystemStatusIcon statusColour="GREEN" onClick={toggleSystemStatus} />

      <Box className={classes.positionWrapper}>
        <CursorPosition />
      </Box>
      <Regional />
      <Clock />
    </Toolbar>
  );
};

export default StatusBar;
