import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  selectInput: {
    borderRadius: 3,
    backgroundColor: theme.palette.background.inputBackground,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  icon: {
    top: 'calc(50% - 10px)',
    color: theme.palette.common.text,
    right: '6px',
    position: 'absolute',
    pointerEvents: 'none'
  },
  textField: {
    '& > div': {
      backgroundColor: theme.palette.background.inputBackground
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  boolField: {
    width: '100%'
  },
  /* all the CSS below is for the ColourPicker control */
  colorField: {
    width: '100%',
    textAlign: 'left',
    '& label': {
      fontSize: '.75rem',
      marginBottom: '5px',
      marginTop: theme.spacing(1)
    },
    '& input': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.common.text,
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#F6FAFD',
      border: '1px solid #E0EAF1',
      borderColor: '#E0EAF1',
      fontSize: '1rem',
      outline: 'none',
      padding: '10px 12px',
      height: 'auto',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    },
    '& div': {
      '&:before, &:after': {
        display: 'none'
      }
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(4)
    }
  },
  swatchWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'start',
    alignContent: 'space-between',
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    zIndex: 'unset',
    '& label': {
      fontSize: '.75rem',
    }
  },
  swatch: {
    display: 'flex',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: '100%',
    border: theme.border.default,
    height: 30,
    width: 30,
    marginRight: theme.spacing(1),
    boxShadow: 'inset 0 0 0 3px white'
  },
  pickerDialog: {
    position: 'absolute',
    zIndex: '2'
  },
  pickerWrapper: {
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  chromeColorPickerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 3
  },
  backToCirclePickerButton: {
    alignSelf: 'center',
    marginLeft: 6,
    lineHeight: 1,
    textTransform: 'none'
  },
  circlePickerContainer: {
    position: 'relative',
    width: '100%',
    marginTop: 6
  },
  useCustomColorButton: {
    lineHeight: 1,
    textTransform: 'none'
  }
}));
