import Smart from 'hocs/smart';
import SetNewPassword from './setNewPassword-view';

export default Smart(
  SetNewPassword,
  () => ({}),
  ac => ({
    navigate: ac.router.navigate,
    displaySnackbar: ac.app.displaySnackbar,
  })
);
