import React from 'react';
import { useTranslation } from 'react-i18next';
import languages from 'constants/languages';
import LanguageOptionButton from './languageOptionButton';
import useStyles from './languageSelector-styles';

const LanguageSelector = ({
  setLanguage
}) => {
  const classes = useStyles();
  const { i18n } = useTranslation('common', { useSuspense: false });

  const updateLanguage = l => {
    i18n.changeLanguage(l);
    setLanguage(l);
  };

  return (
    <div className={classes.container}>
      {languages.map(l => (
        <LanguageOptionButton
          key={l}
          language={l}
          isActive={i18n.languages[0] === l}
          onClick={updateLanguage}
        />
      ))}
    </div>
  );
};

export default LanguageSelector;
