import React from 'react';

import { speed } from 'helpers/unitsOfMeasure';
import Quantity from '../quantity';

const Speed = ({
  speedInMetersPerSecond,
  units,
  precision,
  smallUnits = true
}) => {
  const speedInLocale = speed.fromKmh(speedInMetersPerSecond, units);
  const unitsLabel = speed.label(units);
  return (
    <Quantity value={speedInLocale} units={unitsLabel} precision={precision} smallUnits={smallUnits} />
  );
};

export default Speed;
