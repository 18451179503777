import React from 'react';
import { categories } from 'apis/trackstar/maps';
import LoadingPage from 'components/pages/loading';
import { useGetAssetBasic, useGetDeviceBasic } from 'apis/rest/assets';
import MissingAssetPage from './missingAsset-view';
import AssetPage from './asset-page';

const AssetQuery = ({ match, organisationId, ...rest }) => {
  const assetId = +match.params.id;
  const getAsset = useGetAssetBasic(organisationId, assetId);
  const getDevice = useGetDeviceBasic(getAsset.isSuccess && getAsset.data?.deviceId != null, organisationId, getAsset.data?.deviceId);

  if (getAsset.isError || getDevice.isError) {
    // TODO: proper error handling here.
    return <MissingAssetPage />;
  }

  return (getAsset.isLoading || getDevice.isLoading) ? <LoadingPage /> : (
    ((getAsset.isSuccess && getAsset.data?.deviceId == null) || (getAsset.isSuccess && getAsset.data?.deviceId != null && getDevice.isSuccess)) ? (
      <AssetPage
        {...rest}
        match={match}
        asset={getAsset.data}
        device={getDevice.data}
        hasDevice={getAsset.data?.deviceId != null}
        organisationId={organisationId}
        categories={categories}
      />
    ) : <MissingAssetPage />
  );
};

export default AssetQuery;
