import { Subject } from 'rxjs';
import { ReportsRepository } from './reports';
import { CatmulRomSpline } from './spline';

export enum EventType {
  NEW_REPORT,
  NEW_REPORTS,
  NEW_ASSET,
  CLEAR,
  SET_ALL
}

export interface ReportEvent {
  type: EventType,
  reportId?: number // only set on NEW_REPORT event
  reportIds?: number[]
  assetId?: number
  assetIds?: number[]
}

export class Repository {
  subject = new Subject<ReportEvent>();
  reportRepository = new ReportsRepository();

  insertReport(report: Report, cullBeforeTime?: number): void {
    this.reportRepository.insertReport(report, cullBeforeTime);
    this.subject.next({
      type: EventType.NEW_REPORT,
      reportId: report.id,
      assetId: report.assetId
    });
  }

  insertReports(reports: Report[], cullBeforeTime?: number): void {
    this.reportRepository.insertReports(reports, cullBeforeTime);

    this.subject.next({
      type: EventType.NEW_REPORTS,
      reportIds: reports.map(r => r.id),
      assetIds: reports.map(r => r.assetId)
    });
  }

  setAssetReports(assetId: number, reports: Report[], updateAsset = true): void {
    this.reportRepository.setAssetReports(assetId, reports);
    if (updateAsset) {
      this.subject.next({
        type: EventType.NEW_ASSET,
        assetId
      });
    }
  }

  updateAllAssets(): void {
    this.subject.next({ type: EventType.SET_ALL });
  }

  getReportsForAsset(assetId: number, valid: boolean): Report[] {
    return valid ? this.reportRepository.getSortedPositionsForAsset(assetId) : this.reportRepository.getSortedReportsForAsset(assetId);
  }

  getReportsForAssets(assetIds: number[], valid: boolean): Record<number, Report[]> {
    const result = {} as Record<number, Report[]>;
    assetIds.forEach(id => {
      result[id] = valid ? this.reportRepository.getSortedPositionsForAsset(id) : this.reportRepository.getSortedReportsForAsset(id);
    });
    return result;
  }

  getReport(reportId: number): Report | undefined {
    return this.reportRepository.getReport(reportId);
  }

  getLatestPosition(assetId: number): Report | undefined {
    return this.reportRepository.getLatestReport(assetId);
  }

  getLatestPositionsForAssets(assetIds: number[]): Record<number, Report | undefined> {
    const result = {} as Record<number, Report | undefined>;
    assetIds.forEach(id => { result[id] = this.reportRepository.getLatestReport(id); });
    return result;
  }

  getClosestReport(latitude: number, longitude: number, assetIds: number[]): Report | undefined {
    return this.reportRepository.getClosestReport(latitude, longitude, assetIds);
  }

  getSplineForAsset(assetId: number): [number, number][] {
    return this.reportRepository.getSplineForAsset(assetId);
  }

  getSplinesForAssets(assetIds: number[]): Record<number, [number, number][]> {
    const result = {} as Record<number, [number, number][]>;
    assetIds.forEach(id => { result[id] = this.reportRepository.getSplineForAsset(id); });
    return result;
  }

  clear(): void {
    this.reportRepository.clearReports();
    this.subject.next({ type: EventType.CLEAR });
  }
}

const reports = new Repository();

// this is particularly used for legs, as the reports are filtered elsewhere using e.g. redux data.
export const generateSpline = (inReports: Report[]): [number, number][] => {
  const spline = new CatmulRomSpline(inReports.filter(r => r.isValid).map(r => [r.longitude, r.latitude]), 0.5, 15);
  return spline.interpolated;
};

// TODO: remove this when we stop debugging
// reports.subject.subscribe(event => console.log(event));

// @ts-ignore
// globalThis.reportsRepository = reports;

export default reports;
