import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import View from './groups-view';

export default Smart(
  View,
  (state: any) => ({
    userPermissions: permissionsForCurrentUserAndOrg(state)
  }),
  (ac: any) => ({
    displaySnackbar: ac.app.displaySnackbar,
  })
);
