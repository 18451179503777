// Custom fragment shader for OutlinedIconLayer
export default `\

#define SHADER_NAME outlined-fragment-shader

precision highp float;
uniform float opacity;
uniform sampler2D iconsTexture;
uniform float alphaCutoff;
uniform vec4 outlineColor;
uniform float outlineOffset;
uniform float outlineCutoff;
varying float vColorMode;
varying vec4 vColor;
varying vec2 vTextureCoords;
varying vec2 uv;
varying vec2 iconSize;
varying vec2 texSize;

float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

void main(void) {
  vec4 textureColor = texture2D(iconsTexture, vTextureCoords);
  gl_FragColor = mix(vColor, outlineColor, textureColor.r);
  gl_FragColor.a = map(textureColor.a, 0.0, 1.0, 0.0, vColor.a);
}

`;
