import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    color: theme.palette.common.whiteConstant,
    backgroundColor: theme.palette.background.standOut,
    margin: theme.spacing(0),
    padding: theme.spacing(3),
    fontSize: '.9rem',
    textAlign: 'left',
    '& p': {
      color: theme.palette.common.whiteConstant
    }
  },
  defaultCursor: {
    cursor: 'default',
  },
  emergencyBgColor: {
    backgroundColor: theme.palette.primary.distressRed
  },
  topRow: {
    display: 'flex',
    alignItems: 'initial',
    textAlign: 'left',
    flexGrow: '1',
    marginBottom: theme.spacing(2)
  },
  assetDetails: {
    marginRight: 'auto',
    '& h3': {
      color: theme.palette.common.whiteConstant,
      fontSize: '1.1rem',
      fontWeight: '500',
      marginRight: theme.spacing(1),
      wordBreak: 'break-word',
    },
    '& p': {
      color: theme.palette.common.whiteConstant,
      fontSize: '.9rem',
      fontWeight: '400',
      wordBreak: 'break-word',
    }
  },
  small: {
    fontSize: '0.8rem',
  },
  bottomRow: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    fontSize: '.6rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    marginBottom: 4
  },
  value: {
    fontSize: '.9rem',
    marginTop: 2
  },
  clickableValue: {
    cursor: 'pointer',
    textDecoration: 'underline dotted rgba(255,255,255,.5)'
  },
  quickTasksBar: {
    justifyContent: 'center',
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    right: '0px',
    padding: theme.spacing(0),
    zIndex: 1,
    '&:after': {
      content: '\'\'',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      right: '0px',
      height: '80px',
      background: 'linear-gradient(to top, rgba(0, 0, 0, .75) 0%, transparent 100%)',
      zIndex: 1
    }
  },
  quickTasksButton: {
    color: theme.palette.common.whiteConstant,
    padding: theme.spacing(2),
    zIndex: 3
  },
  hidden: {
    display: 'none'
  }
}));
