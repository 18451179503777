import { ClassNameMap } from '@mui/styles';
import clsx from 'clsx';
import {
  Box, SvgIcon, Tooltip, Typography
} from '@mui/material';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { NoHeaderNoFooterLoadingPage } from 'components/pages/loading';
import mixpanel from 'mixpanel-browser';
import useStyles from '../analysisbox/analysisbox-styles';
import useTranslation from '../../hooks/useTranslation';
import Analysisbox from '../analysisbox/analysisbox-redux';

interface AnalysisboxContainerProps {
  selectedAsset: Asset,
  analysisboxOpen: boolean;
  toggleAnalysisbox: () => void;
  contextboxOpen: boolean;
  selectedItem: any;
  omniboxOpen: boolean;
}

const AnalysisboxContainer = ({
  selectedAsset,
  analysisboxOpen,
  toggleAnalysisbox,
  contextboxOpen,
  selectedItem,
  omniboxOpen,
}: AnalysisboxContainerProps): JSX.Element => {
  const classes: ClassNameMap = useStyles();
  const t = useTranslation('analysisbox');

  const [isDragging, setIsDragging] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(analysisboxOpen ? 400 : 35);
  const [initialHeight, setInitialHeight] = useState(analysisboxOpen ? 400 : 35);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = useCallback(e => {
    e.preventDefault();
    const newHeight = drawerHeight + ((window.innerHeight - e.clientY) - initialHeight);
    if (newHeight > 182 && newHeight < window.innerHeight - 56) {
      setDrawerHeight(newHeight);
    }
  }, [drawerHeight, initialHeight]);
  // eslint wants to wrap this in a useCallback, but doing so breaks the UI
  // eslint-disable-next-line
  const handleMouseUp = (): void => {
    setIsDragging(false);
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };
  const handleMouseDown = useCallback((): void => {
    setIsDragging(true);
    setInitialHeight(wrapperRef.current ? wrapperRef.current.offsetHeight : 400);
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  }, [handleMouseMove, handleMouseUp]);

  const handleCloseButtonClick = useCallback(() => {
    toggleAnalysisbox();
    mixpanel.track('Toggle Analysis Box', {
      open: !(analysisboxOpen && selectedItem)
    });
    if (analysisboxOpen && selectedItem) {
      setDrawerHeight(35);
    } else {
      setDrawerHeight(400);
    }
  }, [analysisboxOpen, selectedItem, toggleAnalysisbox]);

  useEffect(() => {
    if (!analysisboxOpen) {
      setDrawerHeight(35);
    } else {
      setDrawerHeight(400);
    }
  }, [analysisboxOpen]);

  if (!selectedItem || !selectedAsset?.deviceId || selectedItem.type !== 'asset') {
    return (
      <Box className={clsx(classes.analysisboxWrapper, { [classes.collapsed]: !analysisboxOpen || !selectedItem })}>
        <Box className={classes.analysisbox} style={{ marginRight: !contextboxOpen ? '300px' : '0', marginLeft: omniboxOpen ? '300px' : '0' }}>
          <NoHeaderNoFooterLoadingPage extraClasses={classes.loading} />
        </Box>
      </Box>
    );
  }

  return (
    <div
      ref={wrapperRef}
      className={clsx(classes.analysisboxWrapper, { [classes.collapsed]: !analysisboxOpen || !selectedItem })}
      style={{ height: drawerHeight }}
    >
      <Box className={clsx([classes.resizer, isDragging && classes.isDragging])} onMouseDown={handleMouseDown} />
      <Box className={classes.analysisbox} style={{ marginRight: !contextboxOpen ? '300px' : '0', marginLeft: omniboxOpen ? '300px' : '0' }}>
        <Box className={classes.collapseButtonWrapper}>
          <Tooltip
            title={analysisboxOpen ? t('collapsePanel') : t('expandPanel')}
            placement="top"
            PopperProps={{
              popperOptions: { modifiers: { offset: { enabled: true, offset: '0px, 10px' } } }
            }}
          >
            <Box className={classes.collapseButton} onClick={handleCloseButtonClick}>
              <Typography>{analysisboxOpen ? t('hideReports') : t('showReports')}</Typography>
              <SvgIcon className={clsx(classes.icon, { [classes.iconReversed]: analysisboxOpen })} width="16" height="16" viewBox="0 0 16 16">
                <polygon fill="#fff" points="10,13.4 4.6,8 10,2.6 11.4,4 7.4,8 11.4,12" />
              </SvgIcon>
            </Box>
          </Tooltip>
        </Box>
        { // @ts-ignore
          analysisboxOpen && (<Analysisbox selectedAsset={selectedAsset} drawerHeight={drawerHeight} />)
        }
      </Box>
    </div>
  );
};

export default AnalysisboxContainer;
