import React from 'react';
import {
  Grid,
  Paper,
  Box,
  Typography,
  Divider
} from '@mui/material';

import Field from '../field';

import useStyles from './category-styles';

const FormCategory = ({
  id,
  title,
  description,
  fields,
  values,
  update,
  previousColors,
  setPreviousColors,
  showDivider = true
}) => {
  const classes = useStyles();
  const updateField = update && update.bind(null, id);

  return (
    <Box id={id}>
      {showDivider
      && (
        <Divider className={classes.divider} />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <Typography variant="h5" gutterBottom>{title}</Typography>
          <Typography variant="body1" className={classes.categoryDescription} gutterBottom>{description}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Paper className={classes.fieldsPanel}>
            <Grid container spacing={2}>
              {fields.filter(field => !field.hidden).map(field => {
                // Unpack 'subFields' from 'field' to avoid error displaying
                const { subFields, ...cleanedField } = field;
                // Centre the pickers' position horizontally
                const positionLeftValue = () => (field.id === 'defaultPulseColor' ? 30 : -5);

                // Extract Pulse Color Pickers and add 'pulseColor' theming to make them inline displaying
                if (field.type === 'color') {
                  return (
                    <Grid className={classes.pulseColor} key={field.label} item xs={12} sm={6}>
                      <Field
                        {...cleanedField}
                        value={values[id][field.id]}
                        onChange={updateField}
                        isDarkMode={values.ui.darkMode}
                        previousColors={previousColors}
                        setPreviousColors={setPreviousColors}
                        colorPickerPositionTop={-300}
                        colorPickerPositionLeft={positionLeftValue()}
                      />
                    </Grid>
                  );
                }

                return (
                  <Grid key={field.label} item xs={12} sm={field.width}>
                    <Field {...cleanedField} value={values[id][field.id]} onChange={updateField} />
                    {subFields && (
                      <Box className={classes.subFieldsWrapper}>
                        {subFields.map(subField => (
                          <Grid key={subField.label} item xs={12} sm={subField.width}>
                            <Field {...subField} value={values[id][subField.id]} onChange={updateField} disabled={!values[id][field.id]} />
                          </Grid>
                        ))}
                      </Box>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormCategory;
