import distressApi from '../apis/distress';

export const deleteAsset = asset => ({
  type: 'DELETE_ASSET',
  payload: asset
});

export const processInboundTag = (assetId, tag, now) => ({
  type: 'PROCESS_INBOUND_TAG',
  payload: {
    assetId,
    tag,
    now
  }
});

export const fetchDistressIncident = assetId => dispatch => {
  distressApi.getCurrentDistressIncidentForAsset(assetId)
    .then(incident => dispatch({ type: 'FETCH_INCIDENT_SUCCESS', payload: { assetId, incident } }))
    .catch(err => dispatch({ type: 'FETCH_INCIDENT_FAILURE', payload: err }));
};
