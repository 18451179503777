import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  icon: {
    width: '43px',
  },
  button: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '12px',
    paddingBottom: '12px',
    cursor: 'pointer',
    transition: 'all 0.05s',
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none'
    },
    '&:not(:last-child)': {
      marginBottom: '2px'
    },
    '& svg, path, circle, line': {
      stroke: 'none',
      fill: theme.palette.common.text
    }
  },
  text: {
    fontWeight: '500',
    color: theme.palette.common.text,
    flex: 1,
    textAlign: 'left',
    whiteSpace: 'nowrap'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.common.lightBlue,
      transition: 'all 0.05s',
      '& svg, g, path, circle, line': {
        stroke: 'none',
        transition: 'all 0.05s'
      },
      '& p': {
        color: theme.palette.common.text,
        flex: 1
      }
    }
  },
  active: {
    position: 'relative',
    backgroundColor: theme.palette.common.lightBlue,
    '&::after': {
      position: 'absolute',
      content: '\'\'',
      top: '0px',
      bottom: '0px',
      left: '0px',
      backgroundColor: theme.palette.primary.main,
      width: '3px',
      zIndex: 1
    },
    '& svg, g, path, circle, line': {
      stroke: 'none',
      fill: theme.palette.primary.main
    },
    '& p': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: theme.palette.common.lightBlue,
      '& svg, g, path, circle, line': {
        stroke: 'none'
      },
      '& p': {
        color: theme.palette.primary.main
      }
    }
  },
  badge: {
    position: 'absolute',
    right: 18,
    top: 18,
    '& > span': {
      backgroundColor: theme.palette.primary.distressRed
    }
  },
  navigationText: {
    textAlign: 'left',
    maxHeight: '46px',
    minWidth: '106px'
  }
}));
