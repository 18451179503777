import makeStyles from '@mui/styles/makeStyles';

let headerBarHeight = 50;

const width = window.innerWidth;
if (width > 1440) {
  headerBarHeight = 56;
}

export default makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minHeight: headerBarHeight,
    height: headerBarHeight,
    '& > div': {
      position: 'relative'
    }
  },
  hamburgerButton: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
    padding: theme.iconography.padding.header,
    backgroundColor: theme.palette.background.hamburgerButton,
    borderRadius: '0px',
    width: '56px',
    height: '100%'
  },
  white: {
    color: theme.palette.common.white
  },
  appbar: {
    boxShadow: theme.boxShadow.default,
    backgroundColor: theme.palette.primary.darkBlue
  }
}));
