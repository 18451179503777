import Smart from 'hocs/smart';
import AccountSettingsView from './accountSettings-view';

export default Smart(
  AccountSettingsView,
  state => ({
    user: state.session.user
  }),
  ac => ({
  })
);
