import makeStyles from '@mui/styles/makeStyles';

const statusIconWidth = 56;

export default makeStyles(theme => ({
  statusIcon: {
    width: statusIconWidth,
    height: '35px',
    padding: '0px',
    borderRight: theme.border.default,
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: theme.palette.common.lightBlue
    }
  }
}));
