import Smart from 'hocs/smart';
// steal someone elses' view as its identical, just with different units
import View from '../distance/distance-view';

export default Smart(
  View,
  (state, ownProps) => ({
    units: state.settings.units.altitude,
    distanceInMetres: ownProps.altitudeInMetres,
    precision: ownProps.precision
  }),
  () => ({
  })
);
