import Smart from 'hocs/smart';
import QueryReportsDummyView from './queryReportsDummy-view';

export default Smart(
  QueryReportsDummyView,
  state => ({
    organisationId: state.session.organisationId,
    now: state.app.now,
    timezone: state.settings.locale.timezone,
    legs: state.legs,
  }),
  ac => ({
    setAssetLegs: ac.legs.setAssetLegs,
    setMostRecentDeviceReport: ac.reports.setMostRecentDeviceReport,
    displaySnackbar: ac.app.displaySnackbar,
  })
);
