import { fetchOptions } from 'helpers/api';
import { getRefreshedToken } from 'apis/auth';

const BASE_URL = `${import.meta.env.VITE_SERENITY_REST_URL}`;

const checkStatus = (response: any): Promise<any> => (
  response.status >= 200 && response.status < 300
    ? Promise.resolve(response)
    : Promise.reject(new Error(`${response.status}: ${response.statusText}`))
);

/**
 * Fetch a list of all devices shared by or with the current user.
 * The shares API also adds information about the asset the shared device is installed on, as a convenience.
 * @returns Devices shared with, or shared by, the current organisation.
 */
export const fetchShares = async (orgId: string): Promise<Share[]> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`
  };
  if (!token) return new Promise(() => [] as Share[]);
  return fetch(`${BASE_URL}/organisation/${orgId}/shares`, {
    ...fetchOptions,
    headers
  } as RequestInit)
    .then(checkStatus)
    .then(response => response.json())
    .catch(console.error);
};
