import React from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import useStyles from './controlButtons-styles';

const CustomColorPickerButton = ({
  onClick,
  label,
  children
}) => {
  const classes = useStyles();
  const isApplyButton = label === 'Apply' ? 'apply' : '';

  return (
    <Box className={clsx(classes.button, isApplyButton)} onClick={onClick}>
      <Box className={classes.label}>
        { label || children }
      </Box>
    </Box>
  );
};

export default CustomColorPickerButton;
