import React from 'react';

const Other = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 30.801391,7.673902 c -1.314769,0.08607 -17.651648,36.263377 -15.586409,38.908869 2.065239,2.645492 7.680084,-2.750991 16.019845,-2.699577 8.33976,0.05142 13.424416,5.399198 15.240047,2.873727 C 48.290503,44.231449 32.116158,7.587823 30.801391,7.673902 Z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Other;
