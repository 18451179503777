import moment from 'moment';

const ReactMapGlGibsSatelliteTemplate = (lang, now) => {
  const date = (now || moment().subtract(1, 'day')).format('YYYY-MM-DD');
  const tilePathTerra = 'wmts/epsg3857/best/'
    + 'MODIS_Terra_CorrectedReflectance_TrueColor/default/'
    + `${date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg`;

  const tilePathAqua = 'wmts/epsg3857/best/'
    + 'MODIS_AQUA_CorrectedReflectance_TrueColor/default/'
    + `${date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg`;

  const tilePathSNPP = 'wmts/epsg3857/best/'
    + 'VIIRS_SNPP_CorrectedReflectance_TrueColor/default/'
    + `${date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg`;

  const tilePathPlacenames = 'wmts/epsg3857/best/'
    + 'Reference_Labels/default/'
    + 'GoogleMapsCompatible_Level9/{z}/{y}/{x}.png';

  const tilePathFeatures = 'wmts/epsg3857/best/'
    + 'Reference_Features/default/'
    + 'GoogleMapsCompatible_Level9/{z}/{y}/{x}.png';

  return {
    version: 8,
    name: 'Satellite Daily',
    provider: 'NASA GIBS',
    sources: {
      'gibs-satellite-snpp': {
        type: 'raster',
        tiles: [
          `https://gibs-a.earthdata.nasa.gov/${tilePathSNPP}`,
          `https://gibs-b.earthdata.nasa.gov/${tilePathSNPP}`,
          `https://gibs-c.earthdata.nasa.gov/${tilePathSNPP}`
        ],
        minZoom: 1,
        maxZoom: 8,
        tileSize: 256
      },
      'gibs-satellite-placenames': {
        type: 'raster',
        tiles: [
          `https://gibs-a.earthdata.nasa.gov/${tilePathPlacenames}`,
          `https://gibs-b.earthdata.nasa.gov/${tilePathPlacenames}`,
          `https://gibs-c.earthdata.nasa.gov/${tilePathPlacenames}`
        ],
        minZoom: 1,
        maxZoom: 8,
        tileSize: 256
      },
      'gibs-satellite-features': {
        type: 'raster',
        tiles: [
          `https://gibs-a.earthdata.nasa.gov/${tilePathFeatures}`,
          `https://gibs-b.earthdata.nasa.gov/${tilePathFeatures}`,
          `https://gibs-c.earthdata.nasa.gov/${tilePathFeatures}`
        ],
        minZoom: 1,
        maxZoom: 8,
        tileSize: 256
      },
    },
    layers: [{
      id: 'gibs-satellite-suomi',
      type: 'raster',
      source: 'gibs-satellite-snpp'
    }, {
      id: 'gibs-satellite-features',
      type: 'raster',
      source: 'gibs-satellite-features'
    }, {
      id: 'gibs-satellite-placenames',
      type: 'raster',
      source: 'gibs-satellite-placenames'
    }],
    thumbnailUrl: '/img/base-layer-thumbnails/gibs-satellite-thumbnail.jpg',
    minZoom: 1,
    maxZoom: 8,
  };
};

export default ReactMapGlGibsSatelliteTemplate;
