import Smart from 'hocs/smart';
import MapContainerView from './controls-view';

export default Smart(
  MapContainerView,
  state => ({
    activeQuery: state.app.query,
    selectedItem: state.app.selectedItem,
  }),
  ac => ({
    setShowReportDetails: ac.settings.setShowReportDetails
  })
);
