import Smart from 'hocs/smart';
import SettingsDrawerView from './settingsDrawer-view';

export default Smart(
  SettingsDrawerView,
  state => ({
    mapId: state.map.selectedMapId,
    trailsOption: state.map?.maps[state?.map?.selectedMapId]?.trailsOption,
    highContrastControls: state.settings.map.highContrastControls,
    performanceMode: state.app.performanceMode,
    viewport: state.map?.viewports[state?.map?.selectedMapId]
  }),
  ac => ({
    moveLayer: ac.map.moveLayer,
    updateConfig: ac.map.updateConfig,
    updateMapControlsContrast: ac.settings.updateMapControlsContrast,
    setTrailsOption: ac.map.setTrailsOption,
  })
);
