export const labelToIconType = label => label.toLowerCase()
  .replace(/(evt)|\s|_/g, '');
export const labelToDisplayLabel = label => label && label
  .substring(4)
  .replace(/-|_/g, ' ')
  .toLowerCase()
  .replace(/oooi/g, 'OOOI')
  .replace(/(e|E)ngine/g, 'engine ')
  .replace(/\soff/g, ' stop')
  .replace(/\son/g, ' start')
  .replace(/(P|p)oi/g, 'POI')
  .replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

export const isSignificantEvent = e => !['EVT_STANDARD', 'EVT_INFLIGHT', 'EVT_SCHEDULED'].includes(e);

export const isSignificantReport = report => report.events.some(e => isSignificantEvent(e));
