import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    border: theme.border.default,
    backgroundColor: theme.palette.common.white,
    overflowY: 'auto',
    borderRadius: '6px',
    outline: 'none',
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
    maxHeight: '90%',
    maxWidth: '600px',
    width: '100%',
    boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: theme.palette.common.white,
      padding: '0 4px 0 0',
    },
  },
  header: {
    padding: theme.spacing(3, 6),
    borderBottom: theme.border.default
  },
  modalContent: {
    padding: theme.spacing(3, 6),
  },
  footer: {
    padding: theme.spacing(3, 6),
    borderTop: theme.border.default,
    textAlign: 'right'
  },
  select: {
    borderRadius: 3,
    backgroundColor: theme.palette.background.inputBackground,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  invalidNumberWarningText: {
    color: theme.palette.primary.distressRed
  },
  actionButton: {
    color: theme.palette.common.text
  },
  deleteButton: {
    textAlign: 'center',
    alignSelf: 'center',
  },
  notifyCheckboxContainer: {
    textAlign: 'center',
    alignSelf: 'center',
  },
  notifyContainerFormControl: {
    flexDirection: 'row',
  },
  notifyCheckbox: {
    '& .Mui-disabled': {
      color: theme.palette.common.disabled,
    }
  },
  textField: {
    width: '100%',
    '& > div': {
      backgroundColor: theme.palette.background.inputBackground
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  inputButton: {
    padding: theme.spacing(2, 6),
    marginLeft: theme.spacing(2),
    fontSize: '1rem'
  },
  nameLanguage: {
    marginBottom: theme.spacing(2),
  },
  iceButton: {
    color: theme.palette.common.text,
    '&.Mui-disabled': {
      visibility: 'hidden'
    },
  },
  active: {
    '& svg': {
      color: theme.palette.button.checked
    }
  },
  notify: {
    textAlign: 'center',
    fontWeight: 700,
  },
  assetMode: {
    pointerEvents: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  assetModeLabel: {
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0px, 0px) scale(0.75)',
    }
  }
}));
