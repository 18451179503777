import makeStyles from '@mui/styles/makeStyles';

const regionalIconWidth = 56;

export default makeStyles(theme => ({
  icon: {
    color: theme.palette.common.textConstant,
    width: regionalIconWidth,
    height: '35px',
    padding: '0px',
    borderLeft: theme.border.default,
    borderRadius: '0px',
    marginLeft: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.common.lightBlue
    }
  },
  label: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left'
  },
  dialogContentWrapper: {
    padding: theme.spacing(3)
  },
  timezoneSelector: {
    minWidth: 500,
    paddingBottom: theme.spacing(1),
  }
}));
