import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  fields: {
    display: 'flex',
    paddingTop: '1px'
  },
  single: {
    padding: '1px',
    margin: '0 1px 0 1px',
    width: '45px'
  },
  double: {
    padding: '1px',
    marginLeft: '5px',
    width: '95px'
  }
}));

export const styles = {
  // Style for passing to EditableInputStyles
  input: {
    width: '98%',
    height: '11px',
    margin: '0 2px 0 0',
    padding: '2px 0',
    border: 'none',
    boxShadow: 'inset 0 0 0 1px #ccc',
    fontSize: '11px',
    fontWeight: '500',
    textAlign: 'center',
    backgroundColor: 'transparent',
    color: '#909090'
  },
  label: {
    display: 'block',
    width: '95%',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: '450',
    margin: '1.5px 0',
    textTransform: 'capitalize'
  }
};
