import {
  useMemo, useCallback, useEffect, useState
} from 'react';
import { useQuery } from 'react-query';
import moment, { Moment } from 'moment';
import { fetchAssetsDevices } from 'apis/trackstar/serenity';
import reports from 'repositories/reports';
import { useHaveReportData } from 'repositories/reports/hooks';
import { getReportsHistoric, getReportsLatest } from 'apis/rest/reports';

interface QueryReportsDummyProps {
  now: Moment | null,
  timezone: string,
  organisationId: string,
  setMostRecentDeviceReport: (timestamp: number) => void,
}

const QueryReportsDummy = ({
  now,
  timezone,
  organisationId,
  setMostRecentDeviceReport,
}:QueryReportsDummyProps): null => {
  const time = useMemo(() => now?.clone().tz(timezone).endOf('day') || null, [now, timezone]);
  useQuery(
    ['assets', localStorage.getItem('organisationId'), time],
    () => fetchAssetsDevices(time || undefined),
    {
      select: data => data.assets,
    }
  );

  const hasData = useHaveReportData();
  const [gotCurrentReports, setGotCurrentReports] = useState(false);
  const [historicReportsTime, setHistoricReportsTime] = useState<Moment | undefined>();

  // latest reports also works for reports older than a day
  const getLatestReports = useCallback(() => getReportsLatest(organisationId).then(latestReports => {
    latestReports.forEach(latestReport => reports.setAssetReports(latestReport.assetId, [latestReport], false));
    reports.updateAllAssets();
  }), [organisationId]);

  useEffect(() => {
    setGotCurrentReports(false);
    reports.clear();
  }, [organisationId, now]);

  useEffect(() => {
    // historic mode
    if (now && time && historicReportsTime !== now) {
      console.log('getting historical reports');
      if (hasData) {
        reports.clear();
        setGotCurrentReports(false);
      }

      getReportsHistoric(organisationId, time.clone().startOf('day'), time.clone().endOf('day')).then(allReports => {
        const assetReports = allReports.reduce((reportsDict, report) => {
          reportsDict[report.assetId] = [...(reportsDict[report.assetId] || []), report];
          return reportsDict;
        }, {} as Record<number, Report[]>);

        Object.entries(assetReports).forEach(([asset, reportsForAsset]) => reports.setAssetReports(parseInt(asset, 10), reportsForAsset, false));
        reports.updateAllAssets();
      });
      setHistoricReportsTime(now);
      return;
    }

    if (!gotCurrentReports && !now) {
      console.log('getting current reports');
      if (hasData) {
        reports.clear();
        setHistoricReportsTime(undefined);
      }

      const currentMoment = moment();
      getLatestReports()
        // .then(() => new Promise<void>((res, rej) => (performanceMode ? rej() : res())))
        .then(() => getReportsHistoric(organisationId, currentMoment.tz(timezone).clone().startOf('day'), currentMoment))
        .then(allReports => {
          const assetReports = allReports.reduce((reportsDict, report) => {
            reportsDict[report.assetId] = [...(reportsDict[report.assetId] || []), report];
            return reportsDict;
          }, {} as Record<number, Report[]>);

          Object.entries(assetReports).forEach(([asset, reportsForAsset]) => {
            const assetId = parseInt(asset, 10);
            reports.setAssetReports(assetId, reportsForAsset, false);
          });

          console.log(`inserted ${reports.reportRepository.reports.size} reports`);
          reports.updateAllAssets();
          setMostRecentDeviceReport(allReports.sort((a, b) => b.received - a.received)[0].received * 1000);
        });
      setGotCurrentReports(true);
    }
  }, [now, time, historicReportsTime, gotCurrentReports, getLatestReports, organisationId, hasData, setMostRecentDeviceReport, timezone]);

  // @ts-ignore
  // globalThis.getReports = () => setGotCurrentReports(false);

  return null;
};

export default QueryReportsDummy;
