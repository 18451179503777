import React from 'react';
import {
  SvgIcon, Fab, Tooltip, Box
} from '@mui/material';

import clsx from 'clsx';

import useStyles from './controls-styles';

const ControlButton = ({
  text,
  onClick,
  children,
  className,
  tooltipPlacement = 'right',
  disabled = false
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={text} placement={tooltipPlacement} classes={{ tooltip: classes.extraMargin }}>
      <Box>
        <Fab size="small" color="primary" aria-label={text} className={clsx(className, classes.iconButton)} onClick={onClick} disabled={disabled}>
          <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={classes.icon} fontSize="small">
            {children}
          </SvgIcon>
        </Fab>
      </Box>
    </Tooltip>
  );
};

export default ControlButton;
