import React from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import {
  Dialog, DialogContent, Grid, Typography, IconButton, Tooltip, TextField
} from '@mui/material';
import Select from 'components/shared/forms/inputs/select';
import LanguageSelector from 'components/shared/languageSelector';
import useTranslation from 'hooks/useTranslation';
import timezones from 'constants/timezones';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import useStyles from './regional-styles';

const RegionalSettings = ({
  timezone,
  organisationId,
  organisationTimezone,
  setOrganisationTimezone
}) => {
  const classes = useStyles();
  const t = useTranslation('statusbar.regional');
  // const [componentTimezone, setComponentTimezone] = useState(timezone);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <Tooltip title={t('tooltip')} placement="top">
      <IconButton className={classes.icon} onClick={handleClickOpen} size="large">
        <LanguageIcon />
      </IconButton>
    </Tooltip>
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.dialogContentWrapper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.label} variant="body1">
              {`${t('languageLabel')}:`}
            </Typography>
            <LanguageSelector />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              className={classes.timezoneSelector}
              id="dialog-select"
              options={timezones.map(({ id, label }) => ({ id, label }))}
              disableClearable
              variation="contained"
              getOptionLabel={option => option.label}
              isOptionEqualToValue={(option, value) => Object.keys(value).length === 0 || value.id === option.id}
              value={organisationTimezone ? timezones.find(tz => tz.id === organisationTimezone) : timezones.find(tz => tz.id === timezone)}
              onChange={(event, tz) => setOrganisationTimezone(organisationId, tz.id)}
              renderInput={params => <TextField variant="standard" {...params} label={t('timezoneLabel')} fullWidth />}
              placeholder={t('timezonePlaceholder')}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  </>;
};

export default RegionalSettings;
