import React from 'react';
import clsx from 'clsx';
import {
  Tooltip
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useStyles from './participantNameListItem-styles';

interface ParticipantNameListItemProps {
  participant: Participant;
  addComma: boolean;
  showDetails?: boolean;
  defaultSerialType: string,
}

const ParticipantNameListItem = ({
  participant,
  addComma,
  showDetails = false,
  defaultSerialType,
}: ParticipantNameListItemProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('shared.staffIndicator');
  const t2 = useTranslation('contextbox.asset.details');

  /* this uses whitespace: nowrap to ensure the name is not broken up */
  const renderName = (p: Participant): JSX.Element => {
    const makeModel = showDetails && (p.make || p.model) ? `(${p.make} ${p.model})` : '';
    const serial = (p as any)[defaultSerialType];
    return (
      <>
        <span className={clsx(classes.noBreak, { [classes.blueStaff]: p.isStaff })}>
          {`${p.name} ${makeModel}`}
        </span>
        {showDetails && (
          <>
            <br />
            <span className={clsx(classes.details)}>
              {`${t2(defaultSerialType)}: ${serial} ${t2('owner')}: ${p.ownerName}`}
            </span>
          </>
        )}
      </>
    );
  };

  /* this component colours tracplus staff names blue and adds a tooltip */
  return (
    <React.Fragment key={participant.id}>
      {
        participant.isStaff
          ? (
            <Tooltip title={t('staffmember')} placement="bottom" arrow>
              {renderName(participant)}
            </Tooltip>
          )
          : renderName(participant)
      }
      { /* this is rendered separately so that line breaks work at end of names */
        addComma && <span>{', '}</span>
      }
    </React.Fragment>
  );
};

export default ParticipantNameListItem;
