import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  amsSettingsPanel: {
    display: 'block',
    padding: theme.spacing(4)
  },
  checkBoxContainer: {
    paddingBottom: theme.spacing(4)
  }
}));
