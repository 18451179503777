/* eslint-disable no-underscore-dangle */
import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import useStyles from './results-styles';
import GroupedQueryResults from './groupedResults';

interface OmniboxResultsProps {
  organisationId: string,
  selectedMapId: string,
  selectedGroupBy: Record<string, unknown>,
  assignItemToMap: (mapId: string, asset: Asset) => void,
  selectItem: (asset: Asset) => void
  data: { assets: Asset[] }
}

const OmniboxResults = ({
  organisationId,
  selectedMapId,
  selectedGroupBy,
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // assetState,
  assignItemToMap,
  selectItem,
  data,
}: OmniboxResultsProps): JSX.Element => {
  const classes = useStyles();
  const selectAsset = useCallback(asset => {
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // const selectedAssetState = assetState[asset.id];
    // const isAssetInDistress = tagHelper.isInDistress(selectedAssetState);
    selectItem(asset);
    // if (isAssetInDistress) fetchDistressIncident(asset.id);
    // and also track which map is selected
    assignItemToMap(selectedMapId, { ...asset, organisationId });
    // setMissionTargetArea(selectedMapId, null);
    // TODO: performance - this use effect is selecting assets when switching maps, this is also happening in mapContainer-view
  }, [assignItemToMap, organisationId, selectItem, selectedMapId]);

  const assets = useCallback(() => data.assets, [data])();

  // const latestPositionsByAsset = useLatestPositionsForAssets(assets);
  //
  // const sortedAssets = useMemo(() => data.assets.sort((a, b) => {
  //   const aTime = latestPositionsByAsset[a.id]?.received || 0;
  //   const bTime = latestPositionsByAsset[b.id]?.received || 0;
  //   return bTime - aTime;
  // }), [data.assets, latestPositionsByAsset]);

  return (
    <Box id="omniboxResults" className={classes.container}>
      <Box className={classes.listWrapper}>
        {data.assets && (
          <GroupedQueryResults
            results={assets}
            groupBy={selectedGroupBy.assets}
            selectAsset={selectAsset}
          />
        )}
      </Box>
    </Box>
  );
};

export default OmniboxResults;
