import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import View from './friends-view';

export default Smart(
  View,
  state => ({
    userPermissions: permissionsForCurrentUserAndOrg(state),
    organisationId: state.session.organisationId,
  }),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar,
  })
);
