import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  IconButton, ListItem, ListItemAvatar, styled
} from '@mui/material';
import DragIndicator from '@mui/icons-material/DragIndicator';

interface SortableItemProps {
  id: string;
  item: string;
  itemMap: (item: string) => JSX.Element;
  secondaryActionMap?: (item: string) => JSX.Element;
}

export const SortableItem = ({
  id, item, itemMap, secondaryActionMap
}: SortableItemProps): JSX.Element => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform)
  };

  const secondaryAction = secondaryActionMap ? secondaryActionMap(item) : undefined;

  let padding = 48;
  if (secondaryAction?.type === React.Fragment) {
    padding = 48 * secondaryAction.props.children.length;
  }

  const ListItemWithPadding = styled(ListItem)(({ theme }) => ({
    '&.MuiListItem-root': {
      paddingRight: padding
    }
  }));

  return (
    <div ref={setNodeRef} style={style}>
      <ListItemWithPadding secondaryAction={secondaryAction}>
        <ListItemAvatar>
          <IconButton {...attributes} {...listeners}>
            <DragIndicator />
          </IconButton>
        </ListItemAvatar>
        {itemMap(item)}
      </ListItemWithPadding>
    </div>
  );
};
