import Smart from 'hocs/smart';
import View from './editContactModal-view';

export default Smart(
  View,
  state => ({
    defaultLanguage: state.settings.locale.language,
    organisationId: state.session.organisationId,
  }),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar,
  })
);
