import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  picker: {
    marginTop: '1px',
    width: '8px',
    height: '8px',
    border: theme.border.prettyGridDark,
    borderRadius: '5px',
    transform: 'translate(-3px, -1px)',
    backgroundColor: 'rgb(248, 248, 248)',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)'
  }
}));
