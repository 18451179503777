import { MapboxInterface } from 'apis/mapbox';

const apikey = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN || '';
const geocodingInterface = new MapboxInterface(apikey.toString(), 'legs.unknown');

export const eventMap = {
  EVT_STARTUP: 'S',
  EVT_SHUTDOWN: 's',
  EVT_TAKEOFF: 'T',
  EVT_LANDING: 'L',
  EVT_ENGINEON: 'S',
  EVT_ENGINEOFF: 's'
};
export const legEventTypes = Object.keys(eventMap).join(',');
export const stopEvents = [eventMap.EVT_ENGINEOFF, eventMap.EVT_SHUTDOWN, eventMap.EVT_LANDING];

const reducePrecision = (coord?: number): number => +parseFloat(String(coord)).toFixed(3);
const reduceReportPrecision = (reports: Report[], leg: LegRaw): {startLat: number; startLon: number; endLat: number, endLon: number} => ({
  startLat: reducePrecision(reports[leg.start]?.latitude),
  startLon: reducePrecision(reports[leg.start]?.longitude),
  endLat: reducePrecision(leg.end && reports[leg.end]?.latitude),
  endLon: reducePrecision(leg.end && reports[leg.end]?.longitude)
});
// Regex that define legs, once mapped using eventMap
const legRegex = /S?TL(?!s)|TS*Ls?|Ss|STLs/g;

interface LegRaw {
  start: number;
  end?: number;
  takeoff?: number;
  landing?: number
}

interface GeocodedLocation {
  lat: number,
  lon: number,
  location: string
}

const processLegs = async (legsStringMapped: string, legIndices: number[], reports: Report[], existingLegs: Leg[]): Promise<Leg[]> => {
  let lastEvent = 0;
  let firstEvent = legIndices.length;
  const geocodingCache: GeocodedLocation[] = localStorage?.geocodingCache ? JSON.parse(localStorage?.geocodingCache) : [];

  const legMatches: LegRaw[] = [...legsStringMapped.matchAll(legRegex)].map(match => {
    const legStart = match.index || 0;
    const legEnd = legStart + match[0].length - 1;
    lastEvent = Math.max(lastEvent, legEnd);
    firstEvent = Math.min(firstEvent, legStart);

    const takeoff = match[0].indexOf('T') >= 0 ? legStart + match[0].indexOf('T') : undefined;
    const landing = match[0].indexOf('L') > 0 ? legStart + match[0].indexOf('L') : undefined;

    // If the next event is a start of a leg, integrate in-between time into preceding leg.
    if (!stopEvents.includes(legsStringMapped[legEnd + 1]) && !!legsStringMapped[legEnd + 1]) {
      // Edge case when the next start that I want to be just before the end of happens to be received at the same time as other reports
      // because legs are defined by start-end times down to the second, this means that it will include all the reports captured
      // at the same time. Fixed by working back from the next start to find the report with a time before the next start.
      const nextStart = reports[legIndices[legEnd + 1]];
      const startTime = nextStart.received;
      let reportsBeforeStart = 0;
      let foundReportBeforeStart = false;
      while (!foundReportBeforeStart) {
        reportsBeforeStart++;
        const reportIndex = legIndices[legEnd + 1] - reportsBeforeStart;
        if ((reports[reportIndex]?.received || 0) < startTime) {
          foundReportBeforeStart = true;
        }
      }
      return {
        start: legIndices[legStart],
        end: legIndices[legEnd + 1] - reportsBeforeStart,
        takeoff: takeoff && legIndices[takeoff],
        landing: landing && legIndices[landing]
      };
    }
    return {
      start: legIndices[legStart],
      end: legIndices[legEnd],
      takeoff: takeoff && legIndices[takeoff],
      landing: landing && legIndices[landing]
    };
  });

  // Add legs where some might be missing (e.g. some standard reports before first event, standard reports after last event)
  if (legIndices.at(lastEvent) !== reports.length - 1 && legMatches.length > 0) {
    legMatches.push({ start: legIndices[lastEvent] + 1 });
  } else if (legIndices.at(firstEvent) !== 0 || legMatches.length === 0) {
    legMatches.push({ start: 0, end: legIndices[firstEvent] - 1 });
  }

  // 1. make list of leg lat/lons that aren't in geocodingCache
  const locationsToGeocode: {lat: number, lon: number}[] = [];
  // TODO: this is horrifically inefficient, needs an implementation that doesn't have 4 nested loops
  legMatches.forEach(leg => {
    const {
      startLat, startLon, endLat, endLon
    } = reduceReportPrecision(reports, leg);
    const cachedStartLocation = geocodingCache.find(g => g.lat === startLat && g.lon === startLon) || locationsToGeocode.find(g => g.lat === startLat && g.lon === startLon);
    const cachedEndLocation = geocodingCache.find(g => g.lat === endLat && g.lon === endLon) || locationsToGeocode.find(g => g.lat === endLat && g.lon === endLon);
    if (!cachedStartLocation) locationsToGeocode.push({ lat: startLat, lon: startLon });
    if (!cachedEndLocation) locationsToGeocode.push({ lat: endLat, lon: endLon });
  });

  // 2. fetch locations for above list and add it to cache
  const geocodedLocations = await Promise.all(locationsToGeocode.map(async ({ lat, lon }): Promise<GeocodedLocation> => {
    const location = await geocodingInterface.reverseGeocode(lat, lon);
    return { lat, lon, location };
  }));
  const updatedCache = geocodingCache.concat(geocodedLocations);
  localStorage.setItem('geocodingCache', JSON.stringify(updatedCache));

  // 3. return legs with locations from geocodingCache
  return legMatches.flatMap(leg => {
    // return existing leg if it exists instead of requesting geocoding again
    const existingLeg = existingLegs?.length && existingLegs?.find(l => l.id === reports[leg.start].id);
    if (existingLeg) return [];

    // get from/to from cache and return
    const {
      startLat, startLon, endLat, endLon
    } = reduceReportPrecision(reports, leg);
    const from = updatedCache.find(g => g.lat === startLat && g.lon === startLon)?.location;
    const to = updatedCache.find(g => g.lat === endLat && g.lon === endLon)?.location;

    return [{
      id: reports[leg.start].id,
      deviceId: reports[leg.start].deviceId,
      start: reports[leg.start].received,
      // If this leg doesn't have an end, use the time of the most recent report as the end for accurate elapsed time
      end: leg.end && reports[leg.end]?.received ? reports[leg.end].received : reports.at(-1)!.received,
      from,
      to,
      complete: !!leg.end,
      takeoff: leg.takeoff && reports[leg.takeoff]?.received ? reports[leg.takeoff].received : null,
      landing: leg.landing && reports[leg.landing]?.received ? reports[leg.landing].received : null,
    }];
  });
};

export default (reports: Report[], existingLegs: Leg[]): Promise<Leg[]> => {
  if (reports.length === 0) return Promise.resolve([]);
  // Create string representation of leg starts/ends
  // Create list of indices of reports relating to string representation
  let legStringMapped = '';
  const legIndices: number[] = [];
  reports.reverse().forEach((report, index) => {
    if (report.events[0] && Object.keys(eventMap).includes(report.events[0])) {
      // @ts-ignore
      const mapped = eventMap[report.events[0]];
      // compress SSss to Ss, with the first S and the second s
      if (legStringMapped.at(-1) !== mapped) {
        legStringMapped += mapped;
        legIndices.push(index);
      } else if (stopEvents.includes(mapped)) {
        legIndices[legIndices.length - 1] = index;
      }
    }
  });
  return processLegs(legStringMapped, legIndices, reports, existingLegs);
};
