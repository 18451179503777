import { CompositeLayer } from 'deck.gl';
import { PathLayer } from '@deck.gl/layers';
import { TRAILS_OPTIONS } from 'constants/trailsoptions';
import AssetIcon from 'components/shared/icons/assetIcons';
import { hexToRGBArray } from 'helpers/color';
import AntTrailLayer from './antTrailLayer';
import HighlightedTrailLayer from './highlightedTrailLayer';

const duplicate = (trails, additionalRenders) => trails.flatMap(feature => [
  ...[...Array(additionalRenders?.west || 0).keys()]
    .map(i => (i + 1) * 360)
    .map(offset => ({
      ...feature,
      path: feature.path.map(([lon, lat]) => ([lon - offset, lat]))
    })),
  feature,
  ...[...Array(additionalRenders?.east || 0).keys()]
    .map(i => (i + 1) * 360)
    .map(offset => ({
      ...feature,
      path: feature.path.map(([lon, lat]) => ([lon + offset, lat]))
    }))
]);

class TrailsLayer extends CompositeLayer {
  updateState({ props, changeFlags }) {
    if (changeFlags.dataChanged || changeFlags.propsChanged) {
      // make a copy of the assets
      const assets = [...props.data.assets];
      const trails = duplicate(assets
        .map(asset => {
          const assetColor = asset.color ? hexToRGBArray(asset.color) : [255, 105, 180, 255];
          const trail = props?.data?.assetTrails?.[asset.id] ?? [];
          return {
            properties: {
              color: !props.data.assetSelected || (asset.id === props.data.selectedAssetId) ? assetColor : [...assetColor, 150],
              assetId: asset.id,
            },
            path: trail,
            totalPositions: props.totalPositions?.[asset.id] || [],
          };
        }), props.additionalRenders);

      const trail = props.highlightTrail ?? [];
      const trailHighlightAsset = assets.find(a => a.id === props.trailHighlight?.assetId);
      const trailHighlightAssetColor = trailHighlightAsset?.color ? hexToRGBArray(trailHighlightAsset.color) : [255, 105, 180, 255];
      const highlights = (props.trailHighlight?.assetId) ? duplicate([{
        properties: {
          color: trailHighlightAssetColor,
          assetId: props.trailHighlight.assetId
        },
        path: trail
      }], props.additionalRenders) : [];

      this.setState({ trails, highlights });
    }
  }

  renderLayers() {
    /**
     *   Note:
     *   deck.gl does not like conditional rendering.
     *   This was previously wrapped in if clauses and would sometimes work and sometimes it would not, until you refreshed the browser.
     *   The preferred approach is to just always render the same layers and toggle visibility or other props as needed.
     */
    return [
      // white edging
      // other trails (outline)
      new PathLayer({
        id: `trails-layer-outline-${this.props.id}`,
        data: this.state.trails.filter(a => a.properties.assetId !== this.props.data.selectedAssetId),
        pickable: false,
        getColor: d => [255, 255, 255, d.properties.color[3]],
        getWidth: this.props.trailWidth + 3,
        opacity: 1,
        widthUnits: 'pixels',
        widthScale: 1,
        jointRounded: true,
        capRounded: true,
        parameters: { depthTest: false },
        visible: this.props.trailsOption === TRAILS_OPTIONS.allTrailsIcons
      }),
      // all the other trails
      new AntTrailLayer({
        id: `trails-layer-${this.props.id}`,
        data: this.state.trails.filter(a => a.properties.assetId !== this.props.data.selectedAssetId),
        pickable: false,
        getColor: d => d.properties.color,
        getWidth: this.props.trailWidth + 1,
        getTotalPositions: d => d.totalPositions,
        opacity: 1,
        widthUnits: 'pixels',
        widthScale: 1,
        jointRounded: true,
        capRounded: true,
        dashScale: 2 ** (15 - this.props.zoom) / 20,
        animationSpeed: (2 ** (15 - this.props.zoom)) / 1280,
        animate: this.props.animate && !this.props.animateSelectedTrailOnly,
        parameters: { depthTest: false },
        visible: this.props.trailsOption === TRAILS_OPTIONS.allTrailsIcons
      }),
      // selected trail (outline)
      new PathLayer({
        id: `trails-layer-outline2-${this.props.id}`,
        data: this.state.trails.filter(a => a.properties.assetId === this.props.data.selectedAssetId),
        pickable: false,
        getColor: [255, 255, 255, 255],
        getWidth: this.props.trailWidth + 3,
        opacity: 1,
        widthUnits: 'pixels',
        widthScale: 1,
        jointRounded: true,
        capRounded: true,
        parameters: { depthTest: false },
        visible: this.props.trailsOption !== TRAILS_OPTIONS.noTrails
      }),
      // the selected trail
      new AntTrailLayer({
        id: `trails-layer2-${this.props.id}`,
        data: this.state.trails.filter(a => a.properties.assetId === this.props.data.selectedAssetId),
        pickable: false,
        getColor: d => d.properties.color,
        getWidth: this.props.trailWidth + 1,
        getTotalPositions: d => d.totalPositions,
        opacity: 1,
        widthUnits: 'pixels',
        widthScale: 1,
        jointRounded: true,
        capRounded: true,
        dashScale: 2 ** (15 - this.props.zoom) / 20,
        animationSpeed: (2 ** (15 - this.props.zoom)) / 1280,
        animate: this.props.animate,
        parameters: { depthTest: false },
        visible: this.props.trailsOption !== TRAILS_OPTIONS.noTrails
      }),
      // Highlighted leg
      new HighlightedTrailLayer({
        id: `trails-layer-highlight-${this.props.id}`,
        data: this.state.highlights,
        pickable: false,
        animate: true,
        getColor: d => d.properties.color,
        getWidth: this.props.trailWidth + 4,
        opacity: 1,
        widthUnits: 'pixels',
        widthScale: 1,
        jointRounded: true,
        capRounded: true,
        parameters: { depthTest: false },
        visible: this.props.trailsOption !== TRAILS_OPTIONS.noTrails
      })
    ];
  }
}

export default TrailsLayer;
