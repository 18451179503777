import React from 'react';
import {
  Box, Grid, Paper, Typography
} from '@mui/material';
import IceContacts from 'components/shared/iceContacts';
import useTranslation from 'hooks/useTranslation';
import useStyles from '../organisationSettings-styles';

const OrganisationIceContacts = ({
  organisationId,
  readOnly,
}) => {
  const t = useTranslation('pages.organisationSettings');
  const classes = useStyles();

  return (
    <Box id="contactSettings">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h5" gutterBottom>{t('emergencyContacts.title')}</Typography>
          <Typography variant="body1">{t('emergencyContacts.description')}</Typography>
        </Grid>
        <Grid item sm={12} md={8}>
          <Paper className={classes.panel}>
            <IceContacts organisationId={organisationId} readOnly={readOnly} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrganisationIceContacts;
