import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import AssetsList from './assetsList-view';

export default Smart(
  AssetsList,
  state => ({
    permissions: permissionsForCurrentUserAndOrg(state),
    organisationId: state.session.organisationId,
    serialType: state.settings.ui.serialType,
  }),
  ac => ({
    navigate: ac.router.navigate,
    deleteAsset: ac.asset.deleteAsset,
    displaySnackbar: ac.app.displaySnackbar,
    updateSetting: ac.settings.updateSetting
  })
);
