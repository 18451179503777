export const fsDecl = `\
uniform float time;
uniform float animationSpeed;
uniform bool animate;
`;

export const fsMainEnd = `\
if( animate ) {
  float t = mod(time / (1000.0*animationSpeed), 3.1415926535897932384626433832795); // To keep Nvidia drivers happy, keep t in the range of PI
  float w = abs(sin(t));
  gl_FragColor = vec4(vColor.rgb*(0.5+w), w);
  return;
}
gl_FragColor = vColor;
`;
