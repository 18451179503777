import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  pageWrapper: {
    backgroundColor: theme.palette.common.lightGrey,
    padding: theme.spacing(8, 0, 6, 0),
    flex: 1,
    overflowY: 'auto',
    textAlign: 'left'
  },
  assetsTab: {
    '& .MuiSvgIcon-root': {
      margin: '0 7px 0 0',
      position: 'relative',
      top: '7px'
    }
  },
  devicesTab: {
    '& .MuiSvgIcon-root': {
      margin: '0 12px 0 0',
      position: 'relative',
      top: '9px'
    }
  },
  Tabs: {
    '& .MuiTab-root': {
      fontSize: '1.3rem',
      fontWeight: '500',
      textTransform: 'capitalize',
      fontFamily: theme.typography.fontFamily,
      minHeight: '55px',
      flexDirection: 'row',
      minWidth: 160
    },
    '& .MuiTab-iconWrapper': {
      marginBottom: theme.spacing(1),
      alignSelf: 'baseline'
    },
    '& .MuiTab-wrapper': {
      display: 'inline'
    }
  }
}));
