import makeStyles from '@mui/styles/makeStyles';

let drawerWidth = 324;
let headerHeight = 50;
const footerBarHeight = 35;

const width = window.innerWidth;
if (width > 1440) {
  drawerWidth = 343;
  headerHeight = 56;
}

export default makeStyles(theme => ({
  mapSettingsDrawer: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0)'
    },
    '& .MuiDrawer-paper': {
      top: headerHeight,
      bottom: footerBarHeight,
      height: 'auto',
      boxShadow: 'none'
    },
    '& *': {
      scrollbarColor: `${theme.palette.common.scrollBar} ${theme.palette.common.scrollBarBackground}`
    },
    '& *::-webkit-scrollbar-track': {
      background: theme.palette.common.scrollBarBackground
    },
    '& *::-webkit-scrollbar': {
      backgroundColor: theme.palette.common.scrollBarBackground
    },
    '& *::-webkit-scrollbar-thumb': {
      background: theme.palette.common.scrollBar
    }
  },
  drawerWrapper: {
    width: drawerWidth,
    overflowX: 'hidden',
    backgroundColor: theme.palette.settingsDrawer.background
  },
  header: {
    position: 'relative',
    padding: theme.spacing(4, 3, 3, 3),
    '& h2': {
      color: theme.palette.settingsDrawer.label
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.settingsDrawer.label
  },
  footer: {
    padding: theme.spacing(2, 4),
    borderTop: theme.border.default,
    textAlign: 'right'
  },
  tabsWrapper: {
    borderBottom: theme.border.default,
    '& .MuiTab-root': {
      fontSize: '1rem',
      textTransform: 'none'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      opacity: 1,
      color: theme.palette.primary.main
    }
  },
  sliderWrapper: {
    paddingTop: theme.spacing(1)
  },
  icon: {
    fontSize: '1.1rem'
  },
  settingsSection: {
    padding: theme.spacing(3, 3, 2, 3),
    borderTop: theme.border.default
  },
  settingsTitle: {
    fontSize: '.8rem',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    color: theme.palette.settingsDrawer.label,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  buttonGroup: {
    textTransform: 'capitalize',
    fontWeight: 400,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.whiteConstant,
    color: theme.palette.primary.main,
    border: theme.border.default,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.common.lightGrey
    }
  },
  buttonGroupActive: {
    textTransform: 'capitalize',
    fontWeight: 400,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.whiteConstant,
    border: theme.border.default,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    }
  },
  gridItem: {
    maxWidth: '20%',
    flexBasis: '20%'
  },
  trailIcons: {
    padding: theme.spacing(1, 0)
  },
  trailIconWrapper: {
    '& svg': {
      display: 'block',
      width: '100%',
      height: 'auto',
      borderColor: theme.palette.common.white,
      borderWidth: 4,
      borderStyle: 'solid',
      borderRadius: '50%',
      transition: 'all 0.25s',
    },
    '& svg:hover': {
      borderColor: theme.palette.common.blueConstant,
      cursor: 'pointer',
    }
  },
  trailIconWrapperActive: {
    '& svg': {
      display: 'block',
      width: '100%',
      height: 'auto',
      borderColor: theme.palette.primary.main,
      borderWidth: 4,
      borderStyle: 'solid',
      borderRadius: '50%',
    },
    '& svg:hover': {
      borderColor: theme.palette.primary.main,
    }
  },
  inactiveSelect: {
    minWidth: 120,
    marginLeft: 20,
  },
  inactiveSelectLabel: {
    display: 'inline',
  },
}));
