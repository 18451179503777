import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import IceContactsContactsView from './iceContacts-view';

export default Smart(
  IceContactsContactsView,
  state => ({
    permissions: permissionsForCurrentUserAndOrg(state)
  }),
  ac => ({
    navigate: ac.router.navigate,
  })
);
