import React, { useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Grid,
} from '@mui/material';

import Speed from 'components/shared/speed';
import Altitude from 'components/shared/altitude';
import Course from 'components/shared/course';
import DateTime from 'components/shared/datetime';
import Coordinate from 'components/shared/coordinate';
import Icon from 'components/shared/icons/eventIcons';
import useTranslation from 'hooks/useTranslation';
import moment from 'moment';
import clsx from 'clsx';
import DirectionIcon from 'components/shared/icons/directionIcon';
import { chain } from 'lodash';
import useStyles from './adsbDetails-styles';

// Renders the given field as a clean 2-row control with label / value.
// Optionally renders children instead of value (if a more complex control is required)
const DetailField = ({
  label,
  value,
  className,
  fullWidth,
  children
}) => (
  <Grid className={className} item xs={12} sm={fullWidth ? 12 : 6}>
    <Typography>{label}</Typography>
    {children || <Typography>{value}</Typography>}
  </Grid>
);

// Renders the given data object as a 2-column array of <DetailField>
const Simple2dGrid = ({
  data,
  containerClassName,
  itemClassName
}) => {
  const keys = Object.keys(data);

  return chain(keys).groupBy(fieldName => Math.floor(keys.indexOf(fieldName) / 2)).map((row, key) => (
    /* eslint-disable-next-line react/no-array-index-key *///* this is a lodash map over a dictionary not an array
    <Grid key={key} className={containerClassName} container spacing={0}>
      {row.map(fieldName => (
        <DetailField
          key={fieldName}
          className={itemClassName}
          label={fieldName}
          value={data[fieldName]}
        />
      ))}
    </Grid>
  )).value();
};

const Header = ({
  classes,
  t,
  aircraft,
  numAircraft,
}) => (
  <Box className={classes.detailsPanelHeader}>
    <Grid container spacing={0} alignItems="center" className={classes.title}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h3">{aircraft.r || aircraft.flight || aircraft.hex}</Typography>
      </Grid>
      <Grid className={classes.flexWrapper} item xs={12} sm={6}>
        <Box className={classes.iconWrapper}>
          <DirectionIcon style={{ transition: 'all 0.15s', transform: `rotate(${aircraft.track || '0'}deg)` }} fillColor="rgba(0,0,0,0.5)" circle />
        </Box>
      </Grid>
    </Grid>
    <Grid container spacing={0} alignItems="center">
      <DetailField className={classes.gridItem} label={t('transport')} value="ADS-B" />
      <DetailField className={classes.gridItem} label="Flight" value={aircraft.flight || '--'} />
    </Grid>
  </Box>
);

const PositionDetails = ({
  classes,
  t,
  position
}) => (
  <>
    <Grid className={classes.gridContainer} container spacing={0}>
      <DetailField label={t('timeOfFix')} fullWidth>
        <DateTime value={moment.unix(position.received)} />
      </DetailField>
    </Grid>

    <Grid className={classes.gridContainer} container spacing={0}>
      <DetailField label="Coordinates" fullWidth>
        <Coordinate latitude={position.lat} longitude={position.lon} />
      </DetailField>
    </Grid>

    <Grid className={classes.gridContainer} container spacing={0}>
      <DetailField className={classes.gridItem} label={`${t('altitude')} (baro)`}>
        <Altitude altitudeInMetres={position.alt_baro / 3.28084} precision={0} />
      </DetailField>
      <DetailField className={classes.gridItem} label="Air Speed">
        <Speed speedInMetersPerSecond={position.tas / 1.944} />
      </DetailField>
    </Grid>

    <Grid className={classes.gridContainer} container spacing={0}>
      <DetailField className={classes.gridItem} label="Ground Speed">
        {/* this is actually speedInKmph */}
        <Speed speedInMetersPerSecond={position.gs * 1.852} />
      </DetailField>
      <DetailField className={classes.gridItem} label={t('course')}>
        <Course courseInDegreesTrue={position.track} timestamp={position.received} position={position} />
      </DetailField>
    </Grid>
  </>
);

const AircraftDetails = ({ classes, t, aircraft }) => (
  <>
    <Grid className={classes.gridContainer} container spacing={0}>
      <DetailField className={classes.gridItem} label="Squawk" value={aircraft.squawk || '--'} />
      <DetailField className={classes.gridItem} label="Category" value={aircraft.category || '--'} />
    </Grid>
    <Grid className={classes.gridContainer} container spacing={0}>
      <DetailField className={classes.gridItem} label="Type" value={aircraft.t || '--'} />
    </Grid>
  </>
);

const ADSBDetailsOverlay = ({
  numAircraft,
  aircraft,
  highContrastControls,
  setReportDetailsPanelWidth
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.map.reportDetails');
  const reportPanelRef = useRef(null);
  useEffect(() => {
    setReportDetailsPanelWidth(reportPanelRef?.current?.clientWidth ?? 0);
  }, [reportPanelRef?.current?.clientWidth, setReportDetailsPanelWidth]);

  if (!aircraft) { return null; }

  return (
    <Box className={clsx(classes.detailsPanel, { [classes.highContrastBackground]: highContrastControls })} ref={reportPanelRef}>
      <Header classes={classes} t={t} aircraft={aircraft} numAircraft={numAircraft} />

      <Box className={classes.detailsPanelBody}>
        <AircraftDetails classes={classes} t={t} aircraft={aircraft} />
      </Box>
      <Box className={clsx(classes.detailsPanelBody, classes.borderTop)}>
        <PositionDetails classes={classes} t={t} position={aircraft} />
        {/*
        <Simple2dGrid
          data={aircraft}
          containerClassName={classes.gridContainer}
          itemClassName={classes.gridItem}
        />
        */}

        {/*
        {report?.form?.dataJson && (
          <Simple2dGrid
            data={JSON.parse(report.form.dataJson)}
            containerClassName={classes.gridContainer}
            itemClassName={classes.gridItem}
          />
        )}
        */}
      </Box>
    </Box>
  );
};

export default ADSBDetailsOverlay;
