import Smart from 'hocs/smart';
import { eventFilterSelector } from 'selectors/events';
import { visibleAssets } from 'selectors/assets';
import ReactMapGLView from './reactmapgl-map-view';

export default Smart(
  ReactMapGLView,
  (state, ownProps) => ({
    // assetTrails: trailsForZoomByAsset(state, { zoom: zoomForMap(state, { mapId: ownProps.config.id }) }),
    // assetRedactedReports: redactedReportsForZoomByAsset(state, { zoom: zoomForMap(state, { mapId: ownProps.config.id }), organisationId: state.session?.organisationId, now: state.app?.now }),
    // assetHighlightTrail: trailsRangeForAsset(state, { zoom: zoomForMap(state, { mapId: ownProps.config.id }), assetId: state.map.trailHighlight?.assetId, range: { start: state.map.trailHighlight?.legStartId, end: state.map.trailHighlight?.legEndId } }),
    // events: visibleEventsSelectorByAsset(state, { map: ownProps.config.id }),
    // closestReport: closestReportForMap(state, { mapId: ownProps.config.id }),
    viewport: state.map.viewports[ownProps.config.id],
    additionalRenders: state.positions.maps[ownProps.config.id]?.viewportBounds?.additionalRenders,
    measurementMarker: state.map.measurementMarkers[ownProps.config.id]?.currentMeasurementMarker,
    appSelectedItemId: state.app.selectedItem?.id,
    thisMapSelectedItemId: state.map.selectedAssets[ownProps.config.id]?.id,
    eventFilter: eventFilterSelector(state),
    missionTargetArea: state.map.missionTargetAreas[ownProps.config.id],
    hiddenAssets: state.map.hiddenAssets,
    visibleAssets: visibleAssets(state, { mapId: ownProps.config.id }),
    hiddenInactiveAssets: state.map.hiddenInactiveAssets,
    trailHighlight: state.map.trailHighlight,
    cbHighlightedReportId: state.reports.highlightedReportId,
    selectedReport: state.reports.selectedReportPerMap[ownProps.config.id],
    cursorPosition: state.positions.cursor
  }),
  ac => ({
    patchViewport: ac.map.patchViewport,
    updateCursorPosition: ac.map.updateCursorPosition,
    assignMarkerToAsset: ac.map.assignMarkerToAsset,
    selectItem: ac.app.selectItem,
    assignItemToMap: ac.map.assignItemToMap,
    setHighlightedReportId: ac.reports.setHighlightedReportId,
    hideAssetsOnMap: ac.map.hideAssetsOnMap,
    showAssetsOnMap: ac.map.showAssetsOnMap,
    updateHiddenInactiveAssets: ac.map.updateHiddenInactiveAssets,
    displaySnackbar: ac.app.displaySnackbar,
    setSelectedReport: ac.reports.setSelectedReport
  })
);
