import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: ({ isInDistress, isOverdue }) => ({
    color: theme.palette.common.whiteConstant,
    backgroundColor: isInDistress || isOverdue
      ? theme.palette.primary.distressRed
      : theme.palette.background.standOut,
    margin: theme.spacing(0),
    padding: theme.spacing(3),
    fontSize: '.9rem',
    textAlign: 'left',
    '& p': {
      color: theme.palette.common.whiteConstant
    }
  }),
  topRow: {
    display: 'flex',
    alignItems: 'initial',
    textAlign: 'left',
    flexGrow: '1',
    marginBottom: theme.spacing(2)
  },
  middleRow: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  deviceDetails: {
    marginRight: 'auto',
    '& h3': {
      color: theme.palette.common.whiteConstant,
      fontSize: '1.1rem',
      fontWeight: '500',
      marginRight: theme.spacing(1)
    },
    '& p': {
      fontSize: '.9rem',
      fontWeight: '400',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  chip: ({ isInDistress, isOverdue }) => ({
    fontWeight: '500',
    fontSize: '.8rem',
    backgroundColor: theme.palette.common.whiteConstant,
    color: isInDistress || isOverdue
      ? theme.palette.primary.distressRed
      : theme.palette.background.standOut
  }),
  bottomRow: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    fontSize: '.6rem',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  value: {
    fontSize: '.8rem'
  },
  serialLabel: {
    fontSize: '.8rem',
    minWidth: '120px'
  },
  serialValue: {
    maxWidth: '120px',
    fontSize: '.8rem',
    padding: '5px 0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  statusValue: {
    fontSize: '.8rem',
    textTransform: 'capitalize'
  },
  detailsTable: {
    '& td': {
      color: theme.palette.common.whiteConstant,
      padding: 0,
      border: 0
    }
  },
  closeButton: {
    position: 'absolute',
    top: 3,
    right: 3,
  }
}));
