import Smart from 'hocs/smart';
import LegacyInviteUser from './legacyInviteUser-view';

export default Smart(
  LegacyInviteUser,
  (state: any) => ({
    legacyUser: state.session.legacyUser,
  }),
  (ac: any) => ({
    displaySnackbar: ac.app.displaySnackbar,
  })
);
