import React, { useMemo } from 'react';
import moment from 'utils/moment';
import InfiniteScroll from 'components/shared/InfiniteScrollUp';
import { NoHeaderNoFooterLoadingPage } from 'components/pages/loading';
import {
  Typography, Box
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { fetchMessages } from 'apis/trackstar/serenity';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import MessageInputBox from '../messageInputBox';
import MessageView from '../message';
import useStyles from './conversation-styles';

interface ConversationProps {
  conversation: Conversation;
  userId: string;
  organisationId: string;
  displaySnackbar: (Snack: Snack) => void;
}
const ConversationView = ({
  conversation,
  userId,
  organisationId,
  displaySnackbar,
}: ConversationProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('pages.messaging');
  const deliveryQueue: Message[] = [];
  // TODO: read receipts currently not possible
  // const sendReadReceipt: (convoId: string, msgId?: string) => void = () => {};

  const getMessagesInfinite = useInfiniteQuery(
    ['messagesInf', conversation.deviceId],
    (ctx: QueryFunctionContext) => fetchMessages(conversation.deviceId, ctx.pageParam ?? 0, 30),
    {
      getNextPageParam: (lastPage: Message[], allPages: Message[][]) => {
        if (lastPage.length === 0) return undefined;
        return allPages.reduce((count: number, page: Message[]) => count + page.length, 0);
      },
      onError: () => displaySnackbar({ id: 'getMessagesFailed', text: t('getMessagesFailed'), type: 'error' }),
      refetchInterval: 15 * 1000, // 15 Seconds.
      refetchIntervalInBackground: true,
    }
  );
  const messages: Message[] = useMemo((
    () => getMessagesInfinite.data?.pages
      ?.reduce((all: Message[], page: Message[]) => all.concat(page) ?? [])
      // sort to make sure getInfiniteQuery always provides pages in the right order
      .sort((a, b) => a.timestamp - b.timestamp) ?? []
  ), [getMessagesInfinite.data]);

  const isAssetConvo = true; // TODO: this is always true until we re-implement user to user messaging
  // send and view text messages are one permission in Trackstar, so this is always true
  const isMessageableAsset = true;

  // A new message has arrived while this convo is selected, update the read cursor
  if (conversation.latestMessage?.realId && conversation.readCursor !== conversation.latestMessage?.realId) {
    // TODO: read receipts currently not possible
    // sendReadReceipt(conversation.latestMessage?.conversationId, conversation.latestMessage?.realId);
  }

  return (
    // <div className={classes.messages} id="messages" ref={scrollRef} onScroll={debounce(handleScroll, 1000, { leading: true })}>
    <>
      {isAssetConvo && (
        <Typography className={classes.assetWarning}>
          {isMessageableAsset ? t('assetConversationWarning') : t('unmessageableAssetConversationWarning')}
        </Typography>
      )}
      <Box className={classes.messages} id="messages">
        <InfiniteScroll
          loadMore={() => getMessagesInfinite.fetchNextPage()}
          isLoading={getMessagesInfinite.isFetching || getMessagesInfinite.isFetchingNextPage}
          hasMore={getMessagesInfinite.hasNextPage}
          loadingPlaceholder={<NoHeaderNoFooterLoadingPage />}
        >
          <>
            {getMessagesInfinite.isLoading && <NoHeaderNoFooterLoadingPage />}
            {getMessagesInfinite.isSuccess && messages.map((msg, index) => {
              // The message sender is either the device (in which case the recipient will be in message.recipient)
              // or the sender will be at the start of the package (and content) message fields, in which case
              // we extract them as the sender to display inline in the conversation.
              // I know this his horrific, but it's the best we can do with what trackstar provides.
              const extractSender = (m: Message): string | undefined => {
                if (m.recipient) return `${conversation.participants[0].name} to ${m.recipient}`;
                if (m.package?.substring(0, 11) === 'User:System') return 'TracPlus User';
                if (m.package?.substring(0, 5) === 'User:') return m.content.split(':')[0];
                return undefined;
              };
              const lastMessage = messages[Math.max(0, index - 1)];
              const thisMessageSender = extractSender(msg);
              const lastMessageSender = extractSender(lastMessage);

              const lastMessageTimestamp = moment.unix(lastMessage.timestamp);
              const messageTimestamp = moment.unix(msg.timestamp);
              const timeStampDiff = messageTimestamp.diff(lastMessageTimestamp, 'minutes');
              const timestampFrom = messageTimestamp.from(moment());
              const lastTimestampFrom = lastMessageTimestamp.from(moment());
              // show duration since message timestamp if it's been more than 30mins since the last message, and this duration
              // since is different to the last one shown. This avoids repeating 'a month ago', 'a month ago' between
              // messages sent on multiple different days a month ago.
              const showTimestamp = timeStampDiff > 30 && timestampFrom !== lastTimestampFrom;

              return (
                <MessageView
                  /*
                  // @ts-ignore */
                  message={msg}
                  sender={thisMessageSender}
                  isMyMessage={msg.sender.ownerId === organisationId}
                  showSender={(index === 0 || thisMessageSender !== lastMessageSender)}
                  // always display duration since message timestamp at the start of the conversation
                  showTimestamp={showTimestamp || index === 0}
                  key={msg.id}
                  isDelivering={deliveryQueue.find(m => m.id === msg.id)}
                  isAssetConvo={isAssetConvo}
                />
              );
            })}
          </>
        </InfiniteScroll>
      </Box>
      <MessageInputBox
        /*
        // @ts-ignore */
        deviceId={conversation.deviceId}
        isAssetConvo={isAssetConvo}
        disabled={(isAssetConvo && !isMessageableAsset)}
      />
    </>
  );
};

export default ConversationView;
