import React, {
  ChangeEvent, useState, useEffect, useCallback
} from 'react';
import {
  Box,
  Container,
  Button, FormControl, TextField, InputLabel, Select, MenuItem,
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { ClassNameMap } from '@mui/styles';
import { MTableAction } from '@material-table/core';
import GetApp from '@mui/icons-material/GetApp';
import { useQuery } from 'react-query';
import tableIcons from 'components/shared/icons/tableIcons';
import insensitiveSort from 'utils/insensitiveSort';
import { DateTime } from 'luxon';
import processLegs, { legEventTypes } from 'helpers/legs';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import clsx from 'clsx';
import { fetchAssetsDevices, fetchEventReportsForDevice } from 'apis/trackstar/serenity';
import { mapU1ReportToReport } from 'apis/trackstar/maps';
import PersistentTable from 'components/shared/persistentTable';
import mixpanel from 'mixpanel-browser';
import useStyles from '../reporting-styles';

interface Snack { id: string, text: string, type: string }
interface TripReportsProps {
  existingLegs: {
    [x: number]: Leg[]
  },
  now: string
  timezone: string
  setAssetLegs: (id: string, legs: Leg[]) => void
  assets: Asset[]
  tableSettings: {
    searchQuery: string,
    selectedPage: number,
    query: {
      start: moment.MomentInput;
      asset: number,
      from: string,
      until: string
    } | null
  },
  rowsPerPage: number,
  serialType: string,
  displaySnackbar: (Snack: Snack) => void,
  updateSetting: (category: string, field: string, value: any) => void
}

const TripReports = ({
  existingLegs,
  now,
  timezone,
  setAssetLegs,
  tableSettings,
  assets,
  displaySnackbar,
  updateSetting
}: TripReportsProps): JSX.Element => {
  const classes: ClassNameMap = useStyles();
  const t = useTranslation('pages.reporting');
  const { query } = tableSettings;
  const setQuery = (newQuery: any): void => updateSetting('tripReportsTable', 'query', newQuery);
  const [asset, setAsset] = useState<number | undefined>(query?.asset);
  const exportFilename = `TracPlus Trip Reports Export ${assets.find(a => a.id === query?.asset)?.name} ${moment(query?.start).format('YYYY-MM-DD')}`;

  const getAssetList = useQuery(
    ['assets', localStorage.getItem('organisationId'), now],
    () => fetchAssetsDevices(),
    {
      select: (data: AssetsDevices): Asset[] => data.assetsWithDevices,
    }
  );
  if (getAssetList.isError) displaySnackbar({ id: 'getAssetListFailedSnackbar', text: t('getAssetListFailed'), type: 'error' });

  // if selected asset is no longer in assets (e.g. user changed orgs) reset the page
  useEffect(() => {
    if (getAssetList.data && assets.every(a => a.id !== asset)) {
      setQuery(null);
      setAsset(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, assets, getAssetList.data]);

  // months for month selector
  const months = [...Array(12)].map((_, i) => ({
    monthName: new Date(`${i + 1}/01/2022`).toLocaleString('default', { month: 'long' }),
    monthNumber: i + 1,
  }));
  // past 10 years for year selector
  const years = [...Array(10)].map((_, i) => (+new Date().getFullYear() - i).toString());
  const nowMonth = DateTime.now().toFormat('M');
  const nowYear = DateTime.now().toFormat('yyyy');
  let queryYear: string | undefined;
  let queryMonth: string | undefined;
  if (query) {
    const queryFrom = DateTime.fromFormat(query.from, 'MM/dd/yyyy 00:00:00');
    queryYear = queryFrom.toFormat('yyyy');
    queryMonth = queryFrom.toFormat('M');
  }
  const [month, setMonth] = useState(queryMonth ?? nowMonth);
  const [year, setYear] = useState(queryYear ?? nowYear);
  const isNow = () => (query?.from && nowMonth === queryMonth && nowYear === queryYear);

  const maxTableHeight = `${window.innerHeight > 570 ? window.innerHeight - 420 : 150}px`;

  const getEventReports = useQuery(
    ['getEventReports', query?.asset, query?.from, query?.until],
    () => {
      const deviceId = assets.find(a => a.id === query?.asset)?.deviceId;
      if (!deviceId) return [];
      if (!query) return [];
      return fetchEventReportsForDevice(deviceId.toString(), query.from, query.until, legEventTypes);
    },
    {
      enabled: query !== null && !!assets.find(a => a.id === query?.asset),
      onSuccess: data => processLegs(data.map(mapU1ReportToReport).reverse(), existingLegs[query!.asset]).then(legs => setAssetLegs(query!.asset.toString(), legs)),
      staleTime: isNow() ? 5000 : Infinity,
    }
  );
  if (getEventReports.isError) displaySnackbar({ id: 'getEventReportsFailedSnackbar', text: t('getEventReportsFailed'), type: 'error' });

  const getLegs = () => {
    const filterStart = DateTime.fromFormat(`${year}-${month}-01`, 'yyyy-M-dd');
    const filterEnd = filterStart.plus({ months: 1 });
    setQuery({ asset, from: filterStart.toFormat('MM/dd/yyyy 00:00:00'), until: filterEnd.toFormat('MM/dd/yyyy 00:00:00') });
    console.log('getting legs for', asset, filterStart.toFormat('MM/dd/yyyy 00:00:00'), filterEnd.toFormat('MM/dd/yyyy 00:00:00'));
    mixpanel.track('Fetch Trip Reports', { asset, start: filterStart.toFormat('yyyy-MM-dd'), end: filterEnd.toFormat('yyyy-MM-dd') });
  };

  const formatDuration = (duration: moment.Duration): string => {
    const hours = duration.get('hours') < 10 ? `0${duration.get('hours')}` : duration.get('hours');
    const minutes = duration.get('minutes') < 10 ? `0${duration.get('minutes')}` : duration.get('minutes');
    const seconds = duration.get('seconds') < 10 ? `0${duration.get('seconds')}` : duration.get('seconds');
    return `${hours}:${minutes}:${seconds}`;
  };

  const visibleLegs = useCallback(() => (
    query?.asset && existingLegs[query!.asset] ? (existingLegs as any)[query.asset]?.filter((leg: Leg) => (
      moment.unix(leg.start).isAfter(moment(query.from)) && moment.unix(leg.end).isBefore(moment(query.until))
    )).map((leg: Leg) => ({
      ...leg,
      assetName: assets.find(a => a.id === query?.asset)?.name,
      startFormatted: moment.unix(leg.start).tz(timezone).format('D MMM yyyy, HH:mm:ss'),
      endFormatted: moment.unix(leg.end).tz(timezone).format('D MMM yyyy, HH:mm:ss'),
      duration: (() => {
        const duration = moment.duration(moment.unix(leg.end).diff(moment.unix(leg.start)));
        return formatDuration(duration);
      })(),
      takeoffFormatted: leg.takeoff ? moment.unix(leg.takeoff).tz(timezone).format('D MMM yyyy, HH:mm:ss') : '',
      landingFormatted: leg.landing ? moment.unix(leg.landing).tz(timezone).format('D MMM yyyy, HH:mm:ss') : '',
      flightDuration: (() => {
        if (!leg.takeoff || !leg.landing) return '';
        const duration = moment.duration(moment.unix(leg.landing).diff(moment.unix(leg.takeoff)));
        return formatDuration(duration);
      })(),
    })) : []
  ), [query, assets, existingLegs, timezone])();

  const prepareCSVExport = (): string => {
    const getAssetNameForDeviceId = (deviceId: any) => assets.find(a => a?.deviceId === deviceId)?.name;
    const rows = visibleLegs.map(({
      id, deviceId, from, to, startFormatted, endFormatted, duration, takeoffFormatted, landingFormatted, flightDuration
    }: any) => ({
      'Trip Id': id,
      Asset: getAssetNameForDeviceId(deviceId),
      from,
      to,
      [`Start (${timezone})`]: startFormatted,
      [`Takeoff (${timezone})`]: takeoffFormatted,
      [`Landing (${timezone})`]: landingFormatted,
      [`End (${timezone})`]: endFormatted,
      flightDuration,
      duration
    }));
    const CSVData = [
      Object.keys(rows[0]).map(value => `"${value}"`),
      ...rows
        .map((r: any) => Object.values(r).map(value => `"${value}"`))
    ].map(e => e.join(',')).join('\n');
    return `data:text/csv;charset=utf-8,${CSVData}`;
  };

  const downloadCSV = (): void => {
    const encodedUri = encodeURI(prepareCSVExport());
    const element = document.createElement('a');
    element.setAttribute('href', encodedUri);
    element.setAttribute('download', `${exportFilename}.csv`);
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <Container className={classes.tableContainer} maxWidth="md">
      <Box className={classes.materialTable}>
        <PersistentTable
          settingsCategory="tripReportsTable"
          title={(
            <Box className={classes.container}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                  className={clsx(classes.autocompleteBox, classes.select)}
                  loading={getAssetList.isLoading}
                  loadingText="Loading assets..."
                  renderInput={params => <TextField {...params} label="Select an asset" variant="outlined" />}
                  options={assets}
                  getOptionLabel={a => a.name}
                  value={assets.find(a => a.id === asset) ?? null}
                  onChange={(event: ChangeEvent<any>, option: Asset | null) => setAsset(option?.id)}
                  size="small"
                />
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Month</InputLabel>
                <Select
                  variant="outlined"
                  className={classes.select}
                  value={month}
                  onChange={(e: React.ChangeEvent<any>) => setMonth(e.target.value)}
                  label="Age"
                >
                  {Object.values(months).map((m: { monthNumber: number, monthName: string }) => <MenuItem key={m.monthNumber} value={m.monthNumber}>{m.monthName}</MenuItem>)}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Year</InputLabel>
                <Select
                  variant="outlined"
                  className={classes.select}
                  value={year}
                  onChange={(e: React.ChangeEvent<any>) => setYear(e.target.value)}
                  label="Age"
                >
                  {years.map((y: string) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <Button disabled={!asset || !month || !year} className={classes.goButton} variant="outlined" onClick={getLegs}>Go</Button>
              </FormControl>
            </Box>
          )}
          icons={tableIcons}
          // TODO: download CSV button that downloads list of reports in a CSV
          actions={[{
            icon: () => <GetApp />,
            isFreeAction: true,
            onClick: () => downloadCSV(),
          }]}
          isLoading={getEventReports.isLoading || getEventReports.isFetching || getEventReports.isRefetching}
          data={visibleLegs}
          columns={[
            {
              title: t('asset'),
              field: 'assetName',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a:any, b:any) => insensitiveSort(a.name, b.name),
            },
            {
              title: t('from'),
              field: 'from',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a:any, b:any) => insensitiveSort(a.name, b.name),
            },
            {
              title: t('to'),
              field: 'to',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
            },
            {
              title: t('start'),
              field: 'startFormatted',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              customSort: (a:any, b:any) => a.start - b.start,
            },
            {
              title: t('takeoff'),
              field: 'takeoffFormatted',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              customSort: (a:any, b:any) => a.takeoff - b.takeoff,
            },
            {
              title: t('landing'),
              field: 'landingFormatted',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              customSort: (a:any, b:any) => a.landing - b.landing,
            },
            {
              title: t('end'),
              field: 'endFormatted',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              customSort: (a:any, b:any) => a.end - b.end,
            },
            {
              title: t('flightDuration'),
              field: 'flightDuration',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
            },
            {
              title: t('duration'),
              field: 'duration',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
            }
          ]}
          options={{
            draggable: false,
            showTitle: true,
            search: true,
            paging: true,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
            maxBodyHeight: maxTableHeight,
          }}
          localization={{
            header: {
              actions: t('download')
            }
          }}
          components={{
            // eslint-disable-next-line react/destructuring-assignment
            Action: (props: any) => {
              const { action, data } = props;
              return ((action.position === 'toolbar')
                ? (
                  <Button
                    onClick={event => action.onClick(event, data)}
                    className={classes.downloadAsButton}
                    variant="contained"
                  >
                    <GetApp />
                    {t('downloadCSV')}
                  </Button>
                ) : <MTableAction {...props} />
              );
            }
          }}
        />
      </Box>
    </Container>
  );
};

export default TripReports;
