import Smart from 'hocs/smart';
import UserView from './user-view';

export default Smart(
  UserView,
  state => ({
    organisationId: state.session.organisationId,
    isStaff: state.session.user.isStaff,
  }),
  ac => ({
    navigate: ac.router.navigate,
    setOrganisation: ac.session.setOrganisation,
    displaySnackbar: ac.app.displaySnackbar,
    leaveOrganisation: ac.session.leaveOrganisation,
    updateUser: ac.session.updateUser,
  })
);
