import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import OrganisationsListView from './organisationsList-view';

export default Smart(
  OrganisationsListView,
  state => ({
    user: state.session.user,
    organisationId: state.session.organisationId,
    permissions: permissionsForCurrentUserAndOrg(state),
    organisationsJoined: state.session.user.memberOf,
  }),
  ac => ({
    navigate: ac.router.navigate,
    setOrganisation: ac.session.setOrganisation,
    joinOrganisation: ac.session.joinOrganisation,
    leaveOrganisation: ac.session.leaveOrganisation,
    // createOrganisation: ac.organisations.createOrganisation
  })
);
