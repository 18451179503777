import Smart from 'hocs/smart';
import NewConversationBar from './newConversationBar-view';

export default Smart(
  NewConversationBar,
  (state: any) => ({
    organisationId: state.session.organisationId,
  }),
  (ac: any) => ({
    displaySnackbar: ac.app.displaySnackbar,
  })
);
