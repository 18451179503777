import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import OrganisationSettingsQuery from './organisationSettings-view';

export default Smart(
  OrganisationSettingsQuery,
  state => ({
    orgHomeBase: state.settings.map.homeBases[state.session.organisationId],
    user: state.session.user,
    organisationId: state.session.organisationId,
    timezone: state.settings.locale.timezone,
    organisationTimezone: state.settings.locale.organisationTimezone[state.session.organisationId],
    organisationTimezones: state.settings.locale.organisationTimezones,
    permissions: permissionsForCurrentUserAndOrg(state)
  }),
  ac => ({
    setOrgHomeBase: ac.settings.setOrgHomeBase,
    setOrganisationTimezone: ac.settings.setOrganisationTimezone,
    displaySnackbar: ac.app.displaySnackbar,
  })
);
