import React from 'react';
import {
  Box, Typography, Grid, Tooltip, Toolbar, IconButton
} from '@mui/material';
import clsx from 'clsx';
import * as tagHelper from 'helpers/tags';
import useTranslation from 'hooks/useTranslation';
import { Edit as EditIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import InfoIcon from 'components/shared/icons/infoIcon';
import Module from '../../modules/module';
import ContextBoxImage from '../../modules/image';
import useStyles from './details-styles';

const ACBDetails = ({
  asset,
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // assetState,
  selectItem,
  navigate,
  defaultSerialType,
  permissions,
  organisationId,
}) => {
  const classes = useStyles();
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // const isInDistress = tagHelper.isInDistress(assetState);
  // const isOverdue = tagHelper.isOverdue(assetState);
  const isAssetEmergency = false; // isInDistress || isOverdue;

  const t = useTranslation('contextbox.asset.details');

  if (!asset) navigate('/404');

  const handleDeviceClick = device => {
    selectItem(device);
  };

  // Assigned Device Serial
  const deviceSerial = asset.device?.[defaultSerialType]
    ? `${t(defaultSerialType)}: ${asset.device[defaultSerialType]}`
    : t('noSerialSet', { serialType: t(defaultSerialType) });

  const canEditAsset = asset.ownerId.toLowerCase() === organisationId.toLowerCase() && asset.category !== 'System';

  return (
    <Module
      contentClassname={clsx(classes.container, { [classes.emergencyBgColor]: isAssetEmergency })}
      customHeader={(
        <ContextBoxImage obj={asset}>
          <Toolbar className={classes.quickTasksBar}>
            <Tooltip title={canEditAsset ? t('editAsset') : t('viewAsset')} placement="top">
              <Link to={`/asset/${asset.id}`}>
                <IconButton
                  className={clsx(classes.quickTasksButton, { [classes.hidden]: !permissions.canEditAssets })}
                  disabled={!permissions.canEditAssets}
                  size="large"
                >
                  {canEditAsset ? <EditIcon /> : <InfoIcon />}
                </IconButton>
              </Link>
            </Tooltip>
          </Toolbar>
        </ContextBoxImage>
      )}
    >
      <Grid container justifyContent="flex-start">
        <Grid item xs={12} className={classes.topRow}>
          <Box className={classes.assetDetails}>
            <Typography className={classes.defaultCursor} variant="h3" gutterBottom>{asset.name}</Typography>
            <Tooltip title={t('makeModelVariantTooltip')}>
              <Typography className={classes.defaultCursor} variant="body1" gutterBottom>{[asset.make, asset.model, asset.variant].filter(Boolean).join(', ')}</Typography>
            </Tooltip>
            {asset.messagingHandle
            && (
              <Tooltip title={t('messagingHandleTooltip')}>
                <Typography className={classes.defaultCursor} variant="body1">{`@${(asset.messagingHandle || '').replace(/^@?/, '')}`}</Typography>
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.bottomRow}>
          {!asset.device
            ? <Typography>{t('noDevices')}</Typography>
            : (
              <>
                <Typography className={classes.label}>{t('device')}</Typography>
                <Box key={asset.device.id}>
                  <Typography className={clsx(classes.defaultCursor, classes.value, { [classes.clickableValue]: true })} onClick={() => handleDeviceClick(asset.device)}>
                    {`${asset.device.make || ''} ${asset.device.model || ''}`}
                  </Typography>
                  <Typography className={clsx(classes.defaultCursor, classes.small)}>{deviceSerial}</Typography>
                </Box>
              </>
            )}
        </Grid>
      </Grid>
    </Module>
  );
};

export default ACBDetails;
