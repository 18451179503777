import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  dropArea: {
    margin: '8px',
    textAlign: 'center'
  },
  dropAreaHighlight: {
    margin: '8px',
    textAlign: 'center',
    backgroundColor: '#4A525C'
  }
}));
