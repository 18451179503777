import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { CompositeLayer } from 'deck.gl';
import { TextLayer } from '@deck.gl/layers';
import { TRAILS_OPTIONS } from 'constants/trailsoptions';
import AssetIcon from 'components/shared/icons/assetIcons';
import { hexToRGBArray } from 'helpers/color';
import PulseIconLayer from './pulseIconLayer';
import OutlinedIconLayer from './outlinedIconLayer';

// takes line features and renders them 3 times so they show on maps zoomed out to show more than a world width
// Would like to conditionally render these, but cant find bounds correctly see https://github.com/uber/deck.gl/issues/4060
const triplicate = icons => icons.flatMap(icon => [
  { ...icon, longitude: icon.longitude - 360 },
  icon,
  { ...icon, longitude: icon.longitude + 360 }
]);

const ICONSIZE = 128;
class AssetIconsLayer extends CompositeLayer {
  // eslint-disable-next-line class-methods-use-this
  shouldUpdateState({ changeFlags }) {
    return changeFlags.dataChanged || changeFlags.propsChanged;
  }

  updateState({ props }) {
    if (!props.data || !props.data.assets || !props.data.latestPositions) {
      this.setState({ pulseIcons: [], icons: [] });
      return;
    }

    let assets = props.data.assets.filter(a => Object.keys(props.data.latestPositions).includes(a.id.toString()));
    if (props.trailsOption === TRAILS_OPTIONS.selectedTrails) {
      // Only show the icon for the selected item:
      assets = assets.filter(a => a.id === props.data.selectedItemId);
    }

    let pulseIcons = [];
    const latestPositionForSelectedAsset = props.data.latestPositions[props.appSelectedItemId];

    if (latestPositionForSelectedAsset && props.data.assetSelected && !props.selectedItemIsHidden && props.appSelectedItemId) {
      // If we have a selected asset, set the pulse icon under it:
      if (latestPositionForSelectedAsset) {
        pulseIcons = triplicate([{
          name: 'Pulse',
          properties: {
            id: 'pulse',
            color: [0, 0, 0],
            icon: {
              url: 'img/radial-gradient.png',
              height: ICONSIZE,
              width: ICONSIZE,
              id: 'pulse-icon',
              anchorX: ICONSIZE / 2,
              anchorY: ICONSIZE / 2,
              mask: true
            }
          },
          latitude: latestPositionForSelectedAsset.latitude,
          longitude: latestPositionForSelectedAsset.longitude
        }]);
      }
    }

    // create a list of icons for each asset - also used for the text labels
    const icons = triplicate(assets
      .flatMap(asset => {
        const assetColor = asset.color ? hexToRGBArray(asset.color) : [255, 105, 180, 255];
        const icon = asset.visualKey.id || 'unknown';
        const selected = !props.data.assetSelected || asset.id === props.data.selectedItemId;
        // I cannot pass in the correct asset color to the SVG because the way deck.gl renders it makes it black.
        // It is passed into the OutlinedFragmentShader, which maps the white outline/black fill to white outline/<specified color> fill
        const iconUrl = `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<AssetIcon type={icon} opacity={1} />))}`;
        const latestPosition = props.data.latestPositions[asset.id];

        if (!latestPosition) return [];

        return {
          properties: {
            id: asset.id,
            name: asset.name,
            size: 5,
            color: selected ? assetColor : [...assetColor, 200],
            angle: latestPosition?.course ? (180 - latestPosition?.course) + 180 + props.bearing : props.bearing,
            icon: {
              url: iconUrl,
              height: ICONSIZE,
              width: ICONSIZE,
              id: icon,
              anchorX: ICONSIZE / 2,
              anchorY: ICONSIZE / 2,
              mask: true
            }
          },
          latitude: latestPosition.latitude,
          longitude: latestPosition.longitude
        };
      }));
    this.setState({ pulseIcons, icons });
  }

  renderLayers() {
    return [
      new PulseIconLayer({
        key: 'icon-layer-pulse',
        id: 'icon-layer-pulse',
        data: this.state.pulseIcons,
        pickable: false,
        getIcon: d => d.properties.icon,
        sizeScale: 1,
        getPosition: d => [d.longitude, d.latitude],
        getSize: 59,
        speedModifier: this.props.follow ? 0.5 : 1.0,
        getColor: this.props.follow ? this.props.followPulseColor : this.props.pulseColor,
        opacity: this.props.follow ? 0.7 : 0.4,
        getAngle: d => d.properties.angle,
        parameters: { depthTest: false },
        visible: this.props.trailsOption
      }),
      new OutlinedIconLayer({
        key: 'icon-layer',
        id: 'icon-layer',
        data: this.state.icons,
        pickable: false,
        autoHighlight: true,
        getIcon: d => d.properties.icon,
        sizeScale: 1,
        getPosition: d => [d.longitude, d.latitude],
        getSize: 40,
        getAngle: d => d.properties.angle,
        getColor: d => d.properties.color,
        outlineColor: [255, 255, 255],
        parameters: { depthTest: false },
        visible: this.props.trailsOption
      }),
      new TextLayer({
        key: 'label-layer',
        id: 'label-layer',
        data: this.state.icons,
        getText: d => d.properties.name,
        pickable: false,
        fontWeight: 550,
        getPosition: d => [d.longitude, d.latitude],
        getSize: 12,
        // getColor: [36, 39, 48, 255],
        getColor: [255, 255, 255, 255],
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        getPixelOffset: [0, 32],
        visible: this.props.textVisible,
        parameters: { depthTest: false },
        fontSettings: { sdf: true, fontSize: 30, radius: 12 },
        fontFamily: 'objektiv-mk2,sans-serif',
        background: true,
        getBackgroundColor: [0, 0, 0, 128],
        backgroundPadding: [3, 1, 3, 3],
        // outlineColor: [255, 255, 255, 235],
        // outlineWidth: 0.3,
      })
    ];
  }
}

export default AssetIconsLayer;
