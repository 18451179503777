import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Button
} from '@mui/material';
import { CirclePicker } from 'react-color';
import useTranslation from 'hooks/useTranslation';
import useComparableState from 'hooks/useComparableState';
import { SUGGESTED_ASSET_COLORS, SUGGESTED_DARK_MODE_COLORS, SUGGESTED_LIGHT_MODE_COLORS } from 'constants/colors';
import CustomColorPicker from './customColorPicker/customColorPicker-view';

import useStyles from './input-styles';

const CIRCLE_SIZE = 24;
const CIRCLE_SPACING = 4;

const ColorPicker = ({
  id,
  className,
  label,
  onChange,
  readOnly,
  value,
  isDarkMode,
  previousColors,
  setPreviousColors,
  colorPickerPositionTop,
  colorPickerPositionLeft,
  ...rest
}) => {
  const classes = useStyles();
  const [previousColor, currentColor, setColor] = useComparableState(value);

  const displayColor = {
    backgroundColor: currentColor
  };

  const pickerDialogPositionStyle = {
    top: colorPickerPositionTop,
    left: colorPickerPositionLeft
  };

  let suggestedColors;
  if (id === 'color') {
    suggestedColors = SUGGESTED_ASSET_COLORS;
  } else if (isDarkMode) {
    suggestedColors = SUGGESTED_DARK_MODE_COLORS;
  } else {
    suggestedColors = SUGGESTED_LIGHT_MODE_COLORS;
  }

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    onChange?.(id, value);
  }, [value])

  const t = useTranslation('colorPicker');
  // Color Picker
  const [open, setOpen] = React.useState(false);
  const [displayPicker, setDisplayPicker] = React.useState(false);
  const [displayCirclePicker, setDisplayCirclePicker] = React.useState(true);
  const handleClickOpen = () => {
    if (!readOnly) {
      setOpen(true);
      setDisplayPicker(true);
    }
  };
  const handleClose = () => {
    // eslint-disable-next-line no-unused-expressions
    onChange?.(id, previousColor);
    setOpen(false);
    setDisplayPicker(false);
  };
  const acceptedColor = () => {
    setColor(value);
    setPreviousColors(value);

    setOpen(false);
    setDisplayPicker(false);
  };

  return (
    <FormControl variant="standard" className={classes.swatchWrapper}>
      <FormLabel>{label}</FormLabel>
      {
        displayCirclePicker ? (
          <Box className={classes.circlePickerContainer}>
            <CirclePicker
              {...rest}
              color={value}
              colors={suggestedColors}
              circleSize={CIRCLE_SIZE}
              circleSpacing={CIRCLE_SPACING}
              width={`${(CIRCLE_SIZE + CIRCLE_SPACING) * 8}px`}
              onChange={color => onChange && onChange(id, color.hex)}
            />
            <Button
              className={classes.useCustomColorButton}
              style={{ marginTop: CIRCLE_SPACING, height: CIRCLE_SIZE }}
              color="primary"
              variant="contained"
              onClick={() => setDisplayCirclePicker(!displayCirclePicker)}
            >
              {t('useCustomColor')}
            </Button>
          </Box>
        ) : (
          <Box className={classes.chromeColorPickerContainer}>
            <Box className={classes.swatch} style={displayColor} onClick={handleClickOpen} />
            <Button
              className={classes.backToCirclePickerButton}
              style={{ height: CIRCLE_SIZE }}
              color="primary"
              variant="contained"
              onClick={() => setDisplayCirclePicker(!displayCirclePicker)}
              disabled={open}
            >
              {t('useSuggestedColor')}
            </Button>
          </Box>
        )
      }
      {displayPicker && (
        <Box className={classes.pickerDialog} style={pickerDialogPositionStyle}>
          <Box className={classes.pickerWrapper} />
          <CustomColorPicker
            {...rest}
            color={value}
            onChange={color => onChange && onChange(id, color.hex)}
            onAccept={acceptedColor}
            onCancel={handleClose}
            previousColors={previousColors}
          />
        </Box>
      )}
    </FormControl>
  );
};

export default ColorPicker;
