import Smart from 'hocs/smart';
import LanguageSelectorView from './languageSelector-view';

export default Smart(
  LanguageSelectorView,
  () => ({}),
  ac => ({
    setLanguage: ac.settings.setLanguage
  })
);
