/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDateTime } from 'hooks/settings';
import {
  Tooltip
} from '@mui/material';
import { Moment } from 'moment';

const DATE_FORMAT = 'YYYY/MM/DD';
const TIME_FORMAT = 'HH:mm:ss';
const FULL_FORMAT = 'YYYY MM DD HH:mm:ss z';

interface DateTimeProps {
  value: Moment | Date;
  format?: string;
  timeOnly: boolean;
  dateOnly: boolean;
  tooltip: boolean;
  Component: React.ElementType;
}

const DateTime = <ComponentType extends React.ElementType>({
  value,
  format,
  timeOnly = false,
  dateOnly = false,
  tooltip = false,
  Component = 'span',
  ...passthroughProps
}: DateTimeProps & React.ComponentProps<ComponentType>): JSX.Element => {
  const formatStr = format
    || (dateOnly && DATE_FORMAT)
    || (timeOnly && TIME_FORMAT)
    || FULL_FORMAT;

  const dateTimeStr = useDateTime(value, formatStr);
  const tooltipStr = useDateTime(value, 'YYYY-MM-DD HH:mm:ss z');

  return (tooltip && value
    ? (
      <Tooltip title={tooltipStr} placement="bottom" arrow>
        <Component {...passthroughProps}>{dateTimeStr}</Component>
      </Tooltip>
    ) : (
      <Component {...passthroughProps}>{dateTimeStr}</Component>
    )
  );
};

export default DateTime;
