import React from 'react';

import { distance } from 'helpers/unitsOfMeasure';
import Quantity from '../quantity';

const Distance = ({
  distanceInMetres,
  units,
  precision,
  smallUnits = true
}) => {
  const distanceInLocale = distance.fromSI(distanceInMetres, units);
  const unitsLabel = distance.label(units);
  return (
    <Quantity value={distanceInLocale} units={unitsLabel} precision={precision} smallUnits={smallUnits} />
  );
};

export default Distance;
