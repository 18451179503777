import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'utils/moment';

// This hook takes a datetime (as an ISO8601 string, Date() or moment()) and returns
// it as a string in the given format, converted to the current timezone as set
// in the Settings reducer.  Updates if the datetime, format or locale.timezone setting change.

const formatDate = (datetime, timezone, format) => {
  if (!datetime) return '--';
  const momentifiedDateTime = moment.isMoment(datetime) ? datetime : moment(datetime);
  return momentifiedDateTime.tz(timezone).format(format);
};

export default (datetime, format = 'YYYY-MM-DD hh:mm:ss z', timezone = null) => {
  const tz = timezone ?? useSelector(state => (
    state.settings.locale.organisationTimezone[state.session.organisationId] || state.settings.locale.timezone
  ));
  const [formattedString, setFormattedString] = useState(formatDate(datetime, tz, format));

  useEffect(() => {
    setFormattedString(formatDate(datetime, tz, format));
  }, [datetime, format, tz]);

  return formattedString;
};
