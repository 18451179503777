import { createSelector } from 'reselect';
import kdTree from 'kdt';
import { project } from 'utils/projection';
import { redactedReportsForZoomByAsset } from './reports';
import { zoomForMap } from './map';

const areCoordinatesInBounds = ({
  north, east, south, west
}, latitude, longitude) => {
  let inLongitude = longitude >= west && longitude <= east;
  if (west > east) {
    inLongitude = !inLongitude;
  }
  return inLongitude && latitude <= north && latitude >= south;
};

export const inViewReportsForMap = createSelector(
  [
    (state, { mapId }) => redactedReportsForZoomByAsset(state, { zoom: zoomForMap(state, { mapId }), organisationId: state.session?.organisationId, now: state.app?.now }),
    (state, { mapId }) => state.positions.maps[mapId]?.viewportBounds
  ],
  (assetsReports, viewportBounds) => {
    if (!viewportBounds) return null;
    return Object.values(assetsReports).flatMap(asset => asset)
      .filter(r => areCoordinatesInBounds(viewportBounds, r.position.latitude, r.position.longitude));
  },
  { memoizeOptions: { maxSize: 100 } }
);

const inViewTreeForMap = createSelector(
  [
    (state, { mapId }) => inViewReportsForMap(state, { mapId }),
    (state, { mapId }) => state.positions.maps[mapId]?.viewport,
    (state, { mapId }) => state.map.maps[mapId].trailsOption,
    (state, { mapId }) => state.map.selectedAssets[mapId],
  ],
  (inViewReports, viewport, trailsOption, selectedAsset) => {
    if (!inViewReports || inViewReports.length === 0 || !viewport) {
      return null;
    }
    const inViewAssets = inViewReports
      .flatMap(report => {
        if (selectedAsset?.deviceId && selectedAsset?.deviceId !== report.deviceId) return [];
        const [x, y] = project(viewport, report.position.latitude, report.position.longitude);
        return [{
          report, x, y, assetId: report.assetId
        }];
      });

    if (inViewAssets.length === 0) {
      return null;
    }

    const tree = kdTree.createKdTree(
      inViewAssets,
      (a, b) => ((a.x - b.x) ** 2) + ((a.y - b.y) ** 2),
      ['x', 'y']
    );
    tree.findClosestReport = (x, y) => {
      if (!tree) {
        return null;
      }
      const [[nearest]] = tree.nearest({ x, y }, 1);
      return nearest.report;
    };
    return tree;
  },
  { memoizeOptions: { maxSize: 100 } }
);

export const closestReportForMap = createSelector(
  [
    (state, { mapId }) => inViewTreeForMap(state, { mapId }),
    (state, { mapId }) => state.positions.maps[mapId]?.cursor,
    (state, { mapId }) => state.positions.maps[mapId]?.prevCursor,
    (state, { mapId }) => state.map.maps[mapId].trailsOption,
  ],
  (inViewTree, cursor, prevCursor, trailsOption) => {
    if (trailsOption === 1) return null;
    if (inViewTree) {
      if (cursor) {
        return inViewTree.findClosestReport(cursor.x, cursor.y);
      }
      // cursor is null, user may have gone off screen or onto control with mouse, so return report for last known good position
      if (prevCursor) {
        return inViewTree.findClosestReport(prevCursor.x, prevCursor.y);
      }
    }
    return null;
  },
  { memoizeOptions: { maxSize: 100 } }
);
