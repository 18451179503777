import React from 'react';
import {
  Box, Grid, Container, Typography, Tabs, Tab, SvgIcon, TextField, Tooltip,
} from '@mui/material';
import Page from 'components/pages/page';
import useTranslation from 'hooks/useTranslation';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import FriendsTable from './friends';
import GroupsTable from './groups';
import useStyles from './connections-styles';
import TabPanel from '../../shared/tabPanel';

const FriendsPage = ({
  organisationId,
  pubkey,
  displaySnackbar,
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.connections');
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChange = (event, tab) => {
    setCurrentTab(tab);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(pubkey);
    displaySnackbar({
      id: 'copiedPublicKey',
      type: 'success',
      text: t('copiedPublicKey', { pubkey }),
    });
  };

  const friendsIcon = (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32">
      <circle cx="25" cy="18" r="4" />
      <circle cx="7" cy="18" r="4" />
      <path d="M12.525,25.433C11.421,24.779,9.565,24,7,24c-2.599,0-4.446,0.776-5.539,1.429 C0.56,25.967,0,26.955,0,28.007V31h14v-2.982C14,26.96,13.435,25.97,12.525,25.433z" />
      <path d="M30.525,25.433C29.421,24.779,27.565,24,25,24c-2.599,0-4.446,0.776-5.539,1.429 C18.56,25.967,18,26.955,18,28.007V31h14v-2.982C32,26.96,31.435,25.97,30.525,25.433z" />
      <path d="M16,1c-3.309,0-6,2.691-6,6c0,3.383,4.755,8.176,5.297,8.712L16,16.405l0.703-0.693 C17.245,15.176,22,10.383,22,7C22,3.691,19.309,1,16,1z M16,9c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2 C18,8.105,17.105,9,16,9z" />
    </SvgIcon>
  );

  const groupsIcon = (
    <SvgIcon className={classes.groupsIcon} viewBox="0 0 20 20">
      <path d="M12 12.75c1.63 0 3.07.39 4.24.9 1.08.48 1.76 1.56 1.76 2.73V18H6v-1.61c0-1.18.68-2.26 1.76-2.73 1.17-.52 2.61-.91 4.24-.91zM4 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm1.13 1.1c-.37-.06-.74-.1-1.13-.1-.99 0-1.93.21-2.78.58C.48 14.9 0 15.62 0 16.43V18h4.5v-1.61c0-.83.23-1.61.63-2.29zM20 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4 3.43c0-.81-.48-1.53-1.22-1.85-.85-.37-1.79-.58-2.78-.58-.39 0-.76.04-1.13.1.4.68.63 1.46.63 2.29V18H24v-1.57zM12 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3z" />
    </SvgIcon>
  );

  return (
    <Page>
      <Grid container className={classes.pageWrapper}>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <Box>
              <Typography variant="h1" gutterBottom>{t('title')}</Typography>
              <Typography variant="body1" paragraph>{t('description')}</Typography>
              <Grid container>
                <Grid item>
                  <Typography variant="body1" paragraph className={classes.publicKeyDesc}>{t('publicKeyDesc')}</Typography>
                </Grid>
                <Grid item onClick={copyToClipboard} id="publicKey">
                  <Tooltip title={t('copyToClipboard')}>
                    <TextField id="publicKey" variant="outlined" className={classes.publicKey} value={pubkey} disabled />
                  </Tooltip>
                  <FilterNoneOutlinedIcon className={classes.copyIcon} />
                </Grid>
              </Grid>
            </Box>
            <Tabs
              className={classes.Tabs}
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              onChange={handleChange}
              aria-label="friends and groups tabs"
            >
              <Tab className={classes.friendsTab} icon={friendsIcon} label={t('tabs.friends')} />
              <Tab className={classes.friendsTab} icon={groupsIcon} label={t('tabs.groups')} />
            </Tabs>
            <TabPanel currentTab={currentTab} index={0}><FriendsTable organisationId={organisationId} /></TabPanel>
            <TabPanel currentTab={currentTab} index={1}><GroupsTable organisationId={organisationId} /></TabPanel>
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default FriendsPage;
