import { useQuery, UseQueryResult } from 'react-query';
import { getRefreshedToken } from 'apis/auth';

const BASE_URL = `${import.meta.env.VITE_SERENITY_REST_URL}`;

export const useGetAssetsList = (organisationId:string): UseQueryResult<AssetBasic[], Error> => useQuery(
  ['assetBasicsList', organisationId],
  async () => {
    const token = await getRefreshedToken();
    const headers = {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    const response = await fetch(`${BASE_URL}/organisations/${organisationId}/assets/`, {
      method: 'GET',
      headers
    });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    return response.json();
  }
);

export const useGetAssetBasic = (organisationId:string, assetId:number): UseQueryResult<AssetBasic, Error> => useQuery(
  ['assetBasic', assetId],
  async () => {
    const token = await getRefreshedToken();
    const headers = {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    const response = await fetch(`${BASE_URL}/organisations/${organisationId}/assets/${assetId}/`, {
      method: 'GET',
      headers
    });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    return response.json();
  }
);

export const useGetDeviceBasic = (enabled:boolean, organisationId:string, deviceId:number): UseQueryResult<DeviceBasic, Error> => useQuery(
  ['deviceBasic', deviceId],
  async () => {
    const token = await getRefreshedToken();
    const headers = {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    const response = await fetch(`${BASE_URL}/organisations/${organisationId}/devices/${deviceId}/`, {
      method: 'GET',
      headers
    });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    return response.json();
  }, {
    enabled
  }
);

export const useGetAssetBasicAssignedToDevice = (enabled:boolean, organisationId:string, deviceId:number): UseQueryResult<AssetBasic, Error> => useQuery(
  ['assetBasicAssignedToDevice', deviceId],
  async () => {
    const token = await getRefreshedToken();
    const headers = {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    const response = await fetch(`${BASE_URL}/organisations/${organisationId}/devices/${deviceId}/asset/`, {
      method: 'GET',
      headers
    });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    return response.json();
  }, {
    enabled
  }
);
