import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import {
  TextField,
} from '@mui/material';

import useStyles from './input-styles';

const TextInput = ({
  id,
  label,
  type = 'text',
  onChange,
  onBlur = undefined,
  multiline = false,
  helperText,
  hasError = false,
  defaultValue = undefined,
  value = '',
  inputProps = undefined,
  ...inputBaseProps
}) => {
  const classes = useStyles();
  return (
    <TextField
      {...inputBaseProps}
      id={id}
      type={type}
      onChange={e => onChange?.(id, e.target?.value ?? '')}
      onBlur={e => onBlur?.(id, e.target?.value ?? '')}
      InputProps={{ ...inputProps, className: classes.textInput }}
      className={clsx([classes.textField, inputBaseProps.className])}
      label={label}
      error={hasError}
      defaultValue={defaultValue}
      value={value}
      variant="outlined"
      fullWidth
      helperText={helperText}
      multiline={multiline}
    />
  );
};

export default TextInput;
