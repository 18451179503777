import React, { useState } from 'react';
import {
  Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@mui/material';
import DragAndDropTable from 'components/shared/dragAndDropTable';
import useTranslation from 'hooks/useTranslation';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { fetchContacts, setDeviceEmergencyContacts } from 'apis/trackstar/serenity';
import { NoHeaderNoFooterLoadingPage } from 'components/pages/loading';
import mixpanel from 'mixpanel-browser';
import useStyles from './voiceContacts-styles';

const VoiceContactsList = ({
  deviceId,
  readOnly,
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.organisationSettings');
  const queryClient = useQueryClient();
  const [voiceContacts, setVoiceContacts] = useState([]);

  const getContacts = useQuery(['contacts', deviceId], () => fetchContacts(deviceId), {
    onSuccess: data => setVoiceContacts(data.filter(c => c.icePhone)),
  });

  // TODO: success message, error message, rollback UI fail
  const updateVoiceContacts = useMutation(params => setDeviceEmergencyContacts(params), {
    // onSuccess: () => ,
    onSettled: () => {
      queryClient.invalidateQueries(['contacts', deviceId]);
      mixpanel.track('Reorder Voice Contacts', { deviceId });
    },
    mutationKey: 'updateVoiceContacts',
  });

  const reorder = ({ source, destination }) => {
    if (destination) {
      const filteredVoiceContacts = voiceContacts.filter((_, index) => index !== source.index);
      filteredVoiceContacts.splice(destination.index, 0, voiceContacts[source.index]);
      const newVoiceContactsOrder = filteredVoiceContacts.map(vc => vc.id);
      const newVoiceContacts = newVoiceContactsOrder.map((id, index) => {
        const voiceContact = voiceContacts.find(vc => vc.id === id);
        return { ...voiceContact, priority: index };
      });
      setVoiceContacts(newVoiceContacts);
      const nonVoiceSmsContacts = getContacts.data?.filter(c => !newVoiceContacts.find(vc => c.id === vc.id) && c.iceSms);
      updateVoiceContacts.mutate({
        deviceId,
        phoneContacts: newVoiceContacts.filter(c => c.icePhone),
        smsContacts: [newVoiceContacts.filter(c => c.iceSms), nonVoiceSmsContacts].flat(),
        emailContacts: getContacts.data?.filter(c => c.iceEmail),
      });
    }
  };

  return (
    <Box id="voiceContacts" className={classes.category}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <Typography variant="h5" gutterBottom>{t('voiceContacts')}</Typography>
          <Typography variant="body1">{t('voiceContactsDescription')}</Typography>
        </Grid>
        <Grid item sm={12} md={8}>
          <Paper className={classes.tableWrapper}>
            {getContacts.isLoading
              ? (<NoHeaderNoFooterLoadingPage extraClasses={classes.loading} />)
              : !readOnly ? (
                <DragAndDropTable
                  columns={[
                    {
                      title: t('name'),
                      field: 'name'
                    },
                    {
                      title: t('language'),
                      field: 'language',
                      textAlign: 'center'
                    },
                    {
                      title: t('phoneNumber'),
                      field: 'contact',
                      textAlign: 'right'
                    }
                  ]}
                  rowData={voiceContacts.sort((a, b) => a.priority - b.priority)}
                  noDataText={t('noVoiceContacts')}
                  onDrop={!readOnly && reorder}
                  includeNumberIndex
                />
              ) : (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" />
                      <TableCell align="left">{t('name')}</TableCell>
                      <TableCell align="left">{t('language')}</TableCell>
                      <TableCell align="left">{t('phoneNumber')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { voiceContacts.sort((a, b) => a.priority - b.priority).map((contact, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <TableRow key={contact.name + index}>
                        <TableCell>{`${index}.`}</TableCell>
                        <TableCell>{contact.name}</TableCell>
                        <TableCell>{contact.language}</TableCell>
                        <TableCell>{contact.contact}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VoiceContactsList;
