import React, { useRef, useState } from 'react';
import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Container, Box, Button, Grid, Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ChevronLeft as LeftArrow, CloudUpload, Refresh } from '@mui/icons-material';
import tableIcons from 'components/shared/icons/tableIcons';
import { useQuery } from 'react-query';
import { getAllProvisioningRows } from 'apis/rest/provisioning';
import { DateTime } from 'luxon';
import Page from '../../page/page-view';
import useStyles from './provisioningList-styles';
import { DetailPanel } from './detailPanel-view';
import { UploadModal } from './uploadModal-view';

const ProvisioningListPage = ({
  displaySnackbar
}: {displaySnackbar: (snack: Snack) => void}): JSX.Element => {
  const classes = useStyles();
  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [csv, setCsv] = useState<File>();

  const provisioningRows = useQuery(['provisioning'], () => getAllProvisioningRows());

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = async event => {
    if (!event.target.files?.length) return;
    setCsv(event.target.files[0]);

    // reset it so that repeated uploads of the same file still trigger changehandler.
    event.target.value = '';

    setUploadModalOpen(true);
  };

  const openUpload = (): void => {
    fileUploadRef.current?.click();
  };

  const refresh = (): void => {
    provisioningRows.refetch();
  };

  return (
    <Page>
      <Grid container className={classes.pageWrapper}>
        {' '}
        <Grid item xs={12}>
          <Container maxWidth="md">
            <input ref={fileUploadRef} type="file" id="provisioning-csv" name="provisioning-csv" className={classes.hidden} onChange={changeHandler} />
            <Link to="/staff" className={classes.link}>
              <Typography className={classes.linkText} variant="body1" gutterBottom>
                <LeftArrow />
                Support Tools
              </Typography>
            </Link>
            <Box>
              <Typography variant="h1" gutterBottom>Provisioning</Typography>
              <Typography variant="body1" paragraph>Create and provision devices and assets</Typography>
            </Box>
            <Container className={classes.tableContainer} maxWidth="md">
              <Box className={classes.materialTable}>
                <MaterialTable
                  isLoading={provisioningRows.isLoading || provisioningRows.isFetching}
                  title="Provisioning Rows"
                  icons={tableIcons}
                  columns={[
                    {
                      title: 'Proccessing ID',
                      field: 'provisioningProcessingId',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      defaultSort: 'asc',
                    },
                    {
                      title: 'Attempts',
                      field: 'attempts',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                    },
                    {
                      title: 'Make',
                      field: 'deviceMake',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                    },
                    {
                      title: 'Model',
                      field: 'deviceModel',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                    },
                    {
                      title: 'Manufacturer Serial',
                      field: 'deviceManufacturerSerial',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                    },
                    {
                      title: 'TP Serial',
                      field: 'deviceTpSerial',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                    },
                    {
                      title: 'Owner ID',
                      field: 'deviceOwnerId',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left', fontFamily: 'monospace' },
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left', fontFamily: 'monospace' },
                    },
                    {
                      title: 'Last Updated',
                      field: 'auditUpdated',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      render: (row: ProvisioningProcessorRow) => DateTime.fromISO(row.auditUpdated).toFormat('D TT'),
                      customSort: (a, b) =>
                        DateTime.fromISO(b.auditUpdated).toUnixInteger() - DateTime.fromISO(a.auditUpdated).toUnixInteger()
                    },
                  ]}
                  data={provisioningRows.isSuccess ? provisioningRows.data : []}
                  actions={[
                    {
                      icon: () => <CloudUpload />,
                      tooltip: 'Upload CSV',
                      isFreeAction: true,
                      onClick: openUpload
                    },
                    {
                      icon: () => <Refresh />,
                      isFreeAction: true,
                      onClick: () => refresh(),
                      tooltip: 'Refresh'
                    }]}
                  options={{
                    filtering: true,
                    draggable: false,
                    showTitle: false,
                    search: true,
                    actionsColumnIndex: -1,
                    searchFieldStyle: {
                      borderRadius: '4px',
                      paddingLeft: '18px',
                      paddingRight: '10px'
                    },
                    searchFieldVariant: 'outlined',
                    thirdSortClick: false,
                    pageSize: 100,
                    pageSizeOptions: [10, 25, 100],
                    emptyRowsWhenPaging: false,
                    headerStyle: { position: 'sticky', top: 0 },
                    maxBodyHeight: '550px',
                  }}
                  components={{
                    // eslint-disable-next-line react/destructuring-assignment
                    Action: props => {
                      const { action, data } = props;
                      if (action.position === 'toolbar') {
                        return (
                          <Button
                            onClick={event => action.onClick(event, data)}
                            className={classes.newButton}
                            variant="contained"
                            startIcon={action.icon()}
                          >
                            {action.tooltip}
                          </Button>
                        );
                      }
                      return (<MTableAction {...props} />);
                    }
                  }}
                  detailPanel={[{
                    tooltip: 'More details',
                    render: row => <DetailPanel row={row.rowData} />
                  }]}
                />
              </Box>
            </Container>
          </Container>
        </Grid>
      </Grid>
      <UploadModal
        file={csv}
        close={() => setUploadModalOpen(false)}
        isOpen={uploadModalOpen}
        onDone={refresh}
        displaySnackbar={displaySnackbar}
      />
    </Page>
  );
};

export default ProvisioningListPage;
