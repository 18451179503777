import React from 'react';
import Location from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useTranslation from 'hooks/useTranslation';

interface PageTitleProps {
  location: Location,
}

const PageTitle = ({
  location,
}: PageTitleProps): JSX.Element => {
  const t = useTranslation('pageTitles');

  let key = location.pathname.substr(1).replaceAll('/', '-');
  if (location.pathname === '/') {
    key = 'map';
  } else if (location.pathname.startsWith('/asset/') && location.pathname !== '/asset/new') {
    key = 'edit-asset';
  } else if (location.pathname.startsWith('/device/') && location.pathname !== '/device/new') {
    key = 'edit-device';
  } else if (location.pathname.startsWith('/staff/billing/plans/') && location.pathname !== '/staff/billing/plans/new') {
    key = 'edit-plan';
  } else if (location.pathname.startsWith('/staff/billing/addons/') && location.pathname !== '/staff/billing/addons/new') {
    key = 'edit-addon';
  }

  return (
    <Helmet>
      <title>{t(key, 'TracPlus Cloud')}</title>
    </Helmet>
  );
};

export default PageTitle;
