const initialState = {
  filters: {
    default: {
      blacklist: ['Course Change', 'course_change']
    },
    asset: {}
  },
  highlightedReportId: null,
  forms: true,
  selectedReportPerMap: { default: null },
  textMessages: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'MARK_READ': {
      const { [action.payload.deviceId]: removed, ...textMessages } = state.textMessages;
      return {
        ...state,
        textMessages
      };
    }

    case 'SET_UNREAD_TEXT_MESSAGE': {
      // don't set a conversation unread (and ding) if it's already set as unread with the same message
      if (state.textMessages[action.payload.deviceId] === action.payload.reportId) return state;
      const alertSound = new Audio('audio/ding.mp3');
      alertSound.play();
      return {
        ...state,
        textMessages: {
          ...state.textMessages,
          [action.payload.deviceId]: action.payload.reportId,
        }
      };
    }

    case 'FILTER_ASSET_REPORTS':
      return {
        ...state,
        filters: {
          ...state.filters,
          asset: {
            ...state.filters.asset, // <- Must be singular because it comes from type
            [action.payload.assetId]: action.payload.filter
          }
        }
      };

    case 'FILTER_MISSION_REPORTS':
      return {
        ...state,
        filters: {
          ...state.filters,
          mission: {
            ...state.filters.mission,
            [action.payload.missionId]: action.payload.filter
          }
        }
      };

    case 'SET_HIGHLIGHTED_REPORT':
      return {
        ...state,
        highlightedReportId: action.payload.id
      };

    case 'DELETE_ASSET': {
      const assetId = action.payload.id;
      if (!assetId || !state.filters?.asset || !state.filters.asset[assetId]) return state;

      const { [assetId]: deletedAssetFilters, ...asset } = state.filters.asset;

      return {
        ...state,
        filters: {
          ...state.filters,
          asset
        }
      };
    }
    case 'SET_SELECTED_REPORT':
      return {
        ...state,
        selectedReportPerMap: {
          ...state.selectedReportPerMap,
          [action.payload.mapId]: action.payload.report
        }
      };

    case 'SET_NOW': {
      const newSelectedReports = {};
      Object.keys(state.selectedReportPerMap).forEach(m => { newSelectedReports[m] = null; });
      return {
        ...state,
        selectedReportPerMap: newSelectedReports,
      };
    }

    case 'CLEAR_DATA':
    case 'RESET_EVERYTHING':
      return initialState;

    default:
      return state;
  }
};
