import {
  all, select, put, takeEvery
} from 'redux-saga/effects';
import { isLoggedIn } from 'selectors/session';

// This emits an action about the current state of auth at page load.
// useful for chaining other things which only happen when the app is
// logged in
export function* watchInit() {
  yield takeEvery('INIT', function* handle() {
    if (yield select(isLoggedIn)) {
      yield put({ type: 'IS_LOGGED_IN' });
    } else {
      yield put({ type: 'IS_NOT_LOGGED_IN' });
    }
  });
}

export default function* () {
  yield all([
    watchInit()
  ]);
}
