import React, { useMemo } from 'react';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import Distance from 'components/shared/distance';
import { sumBy } from 'lodash';
import moment from 'utils/moment';

// @ts-ignore
import LatLon from 'geodesy/latlon-spherical';
import useStyles from './legsTotals-styles';

interface LegTotalsProps {
  activityGroups: ActivityGroup[],
  now: moment.Moment | undefined
}

const LegsTotals = ({
  activityGroups,
  now
}: LegTotalsProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('contextbox.asset.activity');

  const totalDistance = useMemo(() => activityGroups
    .map(ag => ag.reports).flat().filter(r => r.isValid)
    .reduce(({ dist, prevLatLon }: {dist: number, prevLatLon: LatLon | undefined}, current) => {
      const latLon = new LatLon(current.latitude, current.longitude);
      if (prevLatLon) {
        return {
          dist: dist + prevLatLon.distanceTo(latLon),
          prevLatLon: latLon
        };
      }
      return {
        dist: 0,
        prevLatLon: latLon
      };
    }, { dist: 0, prevLatLon: undefined })
    .dist, [activityGroups]);

  const totalTime = useMemo(() => moment.duration(sumBy(activityGroups, legActivity => {
    if (!legActivity.leg?.start) {
      return 0;
    }

    if (now) {
      if (!legActivity.leg?.end || legActivity.leg.end > now.unix()) {
        return (now.unix() - legActivity.leg.start) * 1000;
      }
    }

    if (!legActivity.leg?.end) {
      return 0;
    }

    return (legActivity.leg.end - legActivity.leg.start) * 1000;
  })), [activityGroups, now]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Box className={classes.totalWrapper}>
          <Typography className={classes.label}>{t('totalDistance')}</Typography>
          <Distance className={classes.data} distanceInMetres={totalDistance} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.totalWrapper}>
          <Typography className={classes.label}>{t('totalTime')}</Typography>
          <Typography className={classes.data}>{totalTime.format('h[h] m[m] s[s]')}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LegsTotals;
