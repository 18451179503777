import { CompositeLayer } from 'deck.gl';
import { ScatterplotLayer } from '@deck.gl/layers';
import { TRAILS_OPTIONS } from 'constants/trailsoptions';
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import DirectionIcon from 'components/shared/icons/directionIcon';
import ColouredIconLayer from './colouredIconLayer';

// takes line features and renders them 3 times so they show on maps zoomed out to show more than a world width
// Would like to conditionally render these, but cant find bounds correctly see https://github.com/uber/deck.gl/issues/4060=
const triplicate = (features => features.flatMap(feature => [
  { ...feature, position: [feature.position[0] - 360, feature.position[1]] },
  feature,
  { ...feature, position: [feature.position[0] + 360, feature.position[1]] }
]));

const ICONSIZE = 128;

class ReportDotLayer extends CompositeLayer {
  updateState({ props, changeFlags }) {
    if (changeFlags.dataChanged) {
      let reports;
      let reportDots;
      if (props.data.selectedReport) {
        reports = triplicate([
          {
            color: props.data.color,
            report: props.data.selectedReport,
            position: [props.data.selectedReport.longitude, props.data.selectedReport.latitude]
          },
          {
            color: props.data.selectedReport.deviceId === props.data.closestReport?.deviceId ? props.data.color?.map(color => (255 - color) * 0.3 + color) : props.data.closestPointColorAfterSelect,
            report: props.data.closestReport,
            position: [props.data.closestReport?.longitude, props.data.closestReport?.latitude]
          }
        ]);
      } else if (props.data.report) {
        reports = triplicate([
          {
            color: props.data.color,
            report: props.data.report,
            position: [props.data.report.longitude, props.data.report.latitude]
          }
        ]);
      } else {
        reports = [];
      }

      const directionIcon = `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<DirectionIcon fillColor="rgba(1,1,1,1)" />))}`;

      if (props.data.allReports?.length) {
        reports = [...reports, ...props.data.allReports
          .filter(r => ![props.data.selectedReport?.id, props.data.report?.id, props.data.closestReport?.id].includes(r.id))
          .map(r => ({
            minor: true,
            color: props.data.closestPointColorAfterSelect,
            position: [r.longitude, r.latitude]
          }))];
      }

      if (props.data.closestReport) {
        reportDots = [{
          color: [255, 255, 255],
          icon: {
            url: directionIcon,
            height: ICONSIZE,
            width: ICONSIZE,
            id: 'directionIndicator',
            anchorX: ICONSIZE / 2,
            anchorY: ICONSIZE / 2,
            mask: false
          },
          size: 26,
          direction: -props.data.closestReport.course,
          position: [props.data.closestReport.longitude, props.data.closestReport.latitude]
        }];
      }

      if (props.data.report) {
        reportDots = [...reportDots, {
          color: [255, 255, 255],
          icon: {
            url: directionIcon,
            height: ICONSIZE,
            width: ICONSIZE,
            id: 'directionIndicator',
            anchorX: ICONSIZE / 2,
            anchorY: ICONSIZE / 2,
            mask: false
          },
          size: 26,
          direction: -props.data.report.course,
          position: [props.data.report.longitude, props.data.report.latitude]
        }];
      }

      this.setState({ reports: reports.reverse(), reportDots });
    }

    if (changeFlags.dataChanged || changeFlags.propsChanged) {
      let visible = !(props.trailsOption === TRAILS_OPTIONS.noTrails);
      // eslint-disable-next-line no-underscore-dangle
      const isSelectedItem = props.data?.report?.assetId === props.selectedItemId;
      if ((props.trailsOption === TRAILS_OPTIONS.selectedTrails || props.trailsOption === TRAILS_OPTIONS.selectedTrailsIcons) && !isSelectedItem) visible = false;
      if (props.hiddenAssets?.find(a => a.deviceId === props.data?.report?.deviceId)) visible = false;
      this.setState({ visible });
    }
  }

  renderLayers() {
    const {
      id,
      fixedRadius,
      radius,
    } = this.props;

    const getRadiusPixels = d => {
      if (d.minor) { return fixedRadius; }
      return radius + 4;
    };

    return [
      new ScatterplotLayer({
        id: `dot-layer-${id}`,
        data: this.state.reports,
        pickable: false,
        stroked: true,
        filled: true,
        opacity: 1,
        getFillColor: d => d.color,
        getLineColor: [255, 255, 255],
        lineWidthUnits: 'pixels',
        radiusUnits: 'pixels',
        getRadius: d => getRadiusPixels(d),
        getLineWidth: d => (d.minor ? 0.4 : 1),
        // radiusMinPixels: getRadiusPixels(),
        // radiusMaxPixels: getRadiusPixels(),
        lineWidthScale: 3,
        visible: this.state.visible
      }),
      new ColouredIconLayer({
        key: 'all-dots-layer',
        id: `all-dots-layer-${id}`,
        data: this.state.reportDots,
        getIcon: d => d.icon,
        sizeScale: 0.75,
        getPosition: d => d.position,
        getSize: d => d.size,
        getColor: d => d.color,
        getAngle: d => d.direction,
        parameters: { depthTest: false },
        transitions: { getSize: 225 },
        pickable: false,
        visible: this.props.trailsOption !== TRAILS_OPTIONS.noTrails
      })
    ];
  }
}

export default ReportDotLayer;
