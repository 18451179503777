/**
 * This class is used to store state (i.e. access token/api key) when interacting with the Mapbox API's
 * Currently this only refers to the geocoding api.
 */
export class MapboxInterface {
  apikey: string
  unknown: string

  /**
   * Construct a MapboxInterface
   * @param apikey the access token from Mapbox
   * @param unknown the string to use when the location is not found.
   */
  constructor(apikey: string, unknown: string) {
    this.apikey = apikey;
    this.unknown = unknown;
  }

  /**
   * Use the Mapbox reverse geocoding API to query the things at a particular latitude and longitude.
   * It is currently defined to use place, district, and region as the category of 'thing' to find.
   * This can lead to undesirable, unspecific results, however that is better than no result
   *
   * @param lat Latitude of location to query
   * @param lon Longitude of location to query
   * @returns The name of the thing at the position
   */
  async reverseGeocode(lat: number, lon: number): Promise<string> {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${this.apikey}&types=locality,place,region&limit=1`;
    return fetch(url)
      .then(r => r.json())
      .then(r => {
        if (r.features.length > 0) {
          return r.features[0].text;
        }
        return 'Unknown';
      });
  }
}
