import React, { useEffect, useState } from 'react';
import {
  Typography, Box, Tooltip, IconButton, Drawer, Grid
} from '@mui/material';
import CheckboxInput from 'components/shared/forms/inputs/bool';
import SliderInput from 'components/shared/forms/inputs/slider';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import useTranslation from 'hooks/useTranslation';

import { TRAILS_OPTIONS } from 'constants/trailsoptions';
import BaseLayerPicker from './modules/baseLayerPicker';
import useStyles from './settingsDrawer-styles';
import KmlLoader from './modules/kmlLoader';

const SettingsDrawer = ({
  settingsDrawerOpen,
  handleSettingsDrawerClose,
  config,
  updateConfig,
  updateMapControlsContrast,
  highContrastControls,
  setTrailsOption,
  mapId,
  trailsOption,
  setKmlOrder,
  kmlOrder,
  kmlStatus,
  setKmlStatus,
  performanceMode,
  viewport
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.map.settingsDialog');
  // if performanceMode is on and allTrailsIcons is selected for this map, force it to selectedTrailsIcons (for performance reasons)
  const [performanceTrailsOption, setPerformanceTrailsOption] = useState((performanceMode && trailsOption === TRAILS_OPTIONS.allTrailsIcons)
    ? TRAILS_OPTIONS.selectedTrailsIcons
    : trailsOption);

  // Handle Trail options
  const selectTrailOption = option => {
    setTrailsOption(mapId, option);
  };
  useEffect(() => {
    // If no trailsOption is set default to showing all trails and assets
    if (!trailsOption) setTrailsOption(mapId, TRAILS_OPTIONS.allTrailsIcons);
    // if trailsOption changes, update performanceTrailsOption accordingly
    setPerformanceTrailsOption((performanceMode && trailsOption === TRAILS_OPTIONS.allTrailsIcons)
      ? TRAILS_OPTIONS.selectedTrailsIcons
      : trailsOption);
  }, [trailsOption, mapId, config?.id, performanceMode, setTrailsOption]);

  const inactiveHoursOptions = [
    { hours: '1', label: '1 hour' },
    { hours: '6', label: '6 hours' },
    { hours: '12', label: '12 hours' },
    { hours: '24', label: '1 day' },
    { hours: '48', label: '2 days' },
    { hours: '168', label: '7 days' },
    { hours: '720', label: '30 days' },
  ];

  return (
    <Drawer
      className={classes.mapSettingsDrawer}
      anchor="left"
      open={settingsDrawerOpen}
      onClose={handleSettingsDrawerClose}
    >
      <Box className={classes.drawerWrapper}>
        <Box className={classes.header}>
          <Typography variant="h2">{t('title')}</Typography>
          {handleSettingsDrawerClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleSettingsDrawerClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
        <Box className={classes.drawerContent}>
          <Box className={classes.settingsSection}>
            <Typography className={classes.settingsTitle}>{t('mapLayers')}</Typography>
            <BaseLayerPicker mapId={config.id} />
          </Box>
          <Box className={classes.settingsSection}>
            <Typography variant="h6" className={classes.settingsTitle}>{t('customLayers')}</Typography>
            <KmlLoader
              mapId={config.id}
              setKmlOrder={setKmlOrder}
              kmlOrder={kmlOrder}
              kmlStatus={kmlStatus}
              setKmlStatus={setKmlStatus}
              t={t}
            />
          </Box>
          <Box className={classes.settingsSection}>
            <Typography className={classes.settingsTitle}>{t('animations')}</Typography>
            <CheckboxInput
              label={t('animateTrails')}
              value={config.animateTrails}
              onChange={(id, value) => updateConfig(config.id, { animateTrails: value })}
            />
            <CheckboxInput
              label={t('animateSelectedTrailOnly')}
              value={config.animateSelectedTrailOnly}
              onChange={(id, value) => updateConfig(config.id, { animateSelectedTrailOnly: value })}
              disabled={!config.animateTrails}
            />
          </Box>
          <Box className={classes.settingsSection}>
            <Typography className={classes.settingsTitle}>{t('trailOptions')}</Typography>
            <Grid container spacing={1} className={classes.trailIcons}>
              <Grid item xs={12} className={classes.gridItem}>
                <Tooltip title={t('noTrails')}>
                  <Box
                    className={clsx(classes.trailIconWrapper, (performanceTrailsOption === TRAILS_OPTIONS.noTrails) ? classes.trailIconWrapperActive : classes.trailIconWrapper)}
                    onClick={() => selectTrailOption(TRAILS_OPTIONS.noTrails)}
                  >
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48">
                      <circle fill="#3A414C" cx="24" cy="24" r="26" />
                      <path
                        fill="#2A93D5"
                        d="M33.13,9.75c-3.39,0-6.14,2.75-6.14,6.14c0,3.66,4.65,8.39,5.85,9.55c0.16,0.15,0.41,0.15,0.57,0
                        c1.21-1.16,5.85-5.89,5.85-9.55C39.27,12.5,36.52,9.75,33.13,9.75z M33.13,17.93c-1.13,0-2.05-0.92-2.05-2.05s0.92-2.05,2.05-2.05
                        s2.05,0.92,2.05,2.05S34.26,17.93,33.13,17.93z"
                      />
                      <path
                        fill="#2A93D5"
                        d="M19.42,14.26c-2.42,0-4.37,1.96-4.37,4.37c0,2.61,3.31,5.98,4.17,6.81c0.11,0.11,0.29,0.11,0.4,0
                        c0.86-0.83,4.17-4.2,4.17-6.81C23.79,16.22,21.83,14.26,19.42,14.26z M19.42,20.09c-0.81,0-1.46-0.65-1.46-1.46
                        c0-0.81,0.65-1.46,1.46-1.46c0.81,0,1.46,0.65,1.46,1.46C20.87,19.44,20.22,20.09,19.42,20.09z"
                      />
                      <path
                        fill="#FFFFFF"
                        d="M33.35,26.96c-0.64-0.11-1.26,0.31-1.38,0.95c0,0.02-0.34,1.67-1.66,2.49c-0.92,0.57-2.16,0.64-3.68,0.22
                        c-1.95-0.55-3.64-0.34-5.03,0.61c-2.59,1.79-2.96,5.57-2.98,5.73c-0.06,0.65,0.42,1.23,1.07,1.29l0.11,0
                        c0.62,0,1.12-0.46,1.18-1.08c0-0.03,0.29-2.84,1.96-3.99c0.77-0.53,1.8-0.63,3.04-0.28c2.2,0.62,4.08,0.45,5.59-0.5
                        c2.22-1.39,2.7-3.96,2.72-4.07C34.42,27.69,33.99,27.07,33.35,26.96z M19.81,37.75L19.81,37.75L19.81,37.75L19.81,37.75z"
                      />
                      <path
                        fill="#FFFFFF"
                        d="M20.58,28.24c0.11-0.64-0.31-1.26-0.95-1.37c-0.64-0.11-1.26,0.31-1.38,0.94c0,0.01-0.22,1.06-1.03,1.56
                        c-0.57,0.35-1.36,0.39-2.35,0.11c-1.49-0.42-2.8-0.26-3.87,0.48c-1.98,1.37-2.26,4.21-2.27,4.33c-0.06,0.65,0.42,1.23,1.07,1.29
                        l0.11,0c0.61,0,1.12-0.46,1.18-1.07c0-0.02,0.2-1.88,1.26-2.6c0.47-0.32,1.09-0.37,1.88-0.15c1.66,0.47,3.1,0.33,4.26-0.39
                        C20.2,30.29,20.57,28.32,20.58,28.24z M9.91,35.09L9.91,35.09L9.91,35.09L9.91,35.09z"
                      />
                    </svg>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.gridItem}>
                <Tooltip title={t('selectedTrails')}>
                  <Box
                    className={clsx(classes.trailIconWrapper, (performanceTrailsOption === TRAILS_OPTIONS.selectedTrails) ? classes.trailIconWrapperActive : classes.trailIconWrapper)}
                    onClick={() => selectTrailOption(TRAILS_OPTIONS.selectedTrails)}
                  >
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48">
                      <circle fill="#3A414C" cx="24" cy="24" r="26" />
                      <path
                        fill="#2A93D5"
                        d="M33.13,9.75c-3.39,0-6.14,2.75-6.14,6.14c0,3.66,4.65,8.39,5.85,9.55c0.16,0.15,0.41,0.15,0.57,0
                        c1.21-1.16,5.85-5.89,5.85-9.55C39.27,12.5,36.52,9.75,33.13,9.75z M33.13,17.93c-1.13,0-2.05-0.92-2.05-2.05s0.92-2.05,2.05-2.05
                        s2.05,0.92,2.05,2.05S34.26,17.93,33.13,17.93z"
                      />
                      <path
                        fill="#FFFFFF"
                        d="M19.42,14.26c-2.42,0-4.37,1.96-4.37,4.37c0,2.61,3.31,5.98,4.17,6.81c0.11,0.11,0.29,0.11,0.4,0
                        c0.86-0.83,4.17-4.2,4.17-6.81C23.79,16.22,21.83,14.26,19.42,14.26z M19.42,20.09c-0.81,0-1.46-0.65-1.46-1.46
                        c0-0.81,0.65-1.46,1.46-1.46c0.81,0,1.46,0.65,1.46,1.46C20.87,19.44,20.22,20.09,19.42,20.09z"
                      />
                      <path
                        fill="#2A93D5"
                        d="M33.35,26.96c-0.64-0.11-1.26,0.31-1.38,0.95c0,0.02-0.34,1.67-1.66,2.49c-0.92,0.57-2.16,0.64-3.68,0.22
                        c-1.95-0.55-3.64-0.34-5.03,0.61c-2.59,1.79-2.96,5.57-2.98,5.73c-0.06,0.65,0.42,1.23,1.07,1.29l0.11,0c0.62,0,1.12-0.46,1.18-1.08
                        c0-0.03,0.29-2.84,1.96-3.99c0.77-0.53,1.8-0.63,3.04-0.28c2.2,0.62,4.08,0.45,5.59-0.5c2.22-1.39,2.7-3.96,2.72-4.07
                        C34.42,27.69,33.99,27.07,33.35,26.96z M19.81,37.75L19.81,37.75L19.81,37.75L19.81,37.75z"
                      />
                      <path
                        fill="#FFFFFF"
                        d="M20.58,28.24c0.11-0.64-0.31-1.26-0.95-1.37c-0.64-0.11-1.26,0.31-1.38,0.94c0,0.01-0.22,1.06-1.03,1.56
                        c-0.57,0.35-1.36,0.39-2.35,0.11c-1.49-0.42-2.8-0.26-3.87,0.48c-1.98,1.37-2.26,4.21-2.27,4.33c-0.06,0.65,0.42,1.23,1.07,1.29
                        l0.11,0c0.61,0,1.12-0.46,1.18-1.07c0-0.02,0.2-1.88,1.26-2.6c0.47-0.32,1.09-0.37,1.88-0.15c1.66,0.47,3.1,0.33,4.26-0.39
                        C20.2,30.29,20.57,28.32,20.58,28.24z M9.91,35.09L9.91,35.09L9.91,35.09L9.91,35.09z"
                      />
                    </svg>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.gridItem}>
                <Tooltip title={t('selectedTrailsIcons')}>
                  <Box
                    className={clsx(classes.trailIconWrapper, (performanceTrailsOption === TRAILS_OPTIONS.selectedTrailsIcons) ? classes.trailIconWrapperActive : classes.trailIconWrapper)}
                    onClick={() => selectTrailOption(TRAILS_OPTIONS.selectedTrailsIcons)}
                  >
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48">
                      <circle fill="#3A414C" cx="24" cy="24" r="26" />
                      <path
                        fill="#2A93D5"
                        d="M33.13,9.75c-3.39,0-6.14,2.75-6.14,6.14c0,3.66,4.65,8.39,5.85,9.55c0.16,0.15,0.41,0.15,0.57,0
                        c1.21-1.16,5.85-5.89,5.85-9.55C39.27,12.5,36.52,9.75,33.13,9.75z M33.13,17.93c-1.13,0-2.05-0.92-2.05-2.05s0.92-2.05,2.05-2.05
                        s2.05,0.92,2.05,2.05S34.26,17.93,33.13,17.93z"
                      />
                      <path
                        fill="#2A93D5"
                        d="M19.42,14.26c-2.42,0-4.37,1.96-4.37,4.37c0,2.61,3.31,5.98,4.17,6.81c0.11,0.11,0.29,0.11,0.4,0
                        c0.86-0.83,4.17-4.2,4.17-6.81C23.79,16.22,21.83,14.26,19.42,14.26z M19.42,20.09c-0.81,0-1.46-0.65-1.46-1.46
                        c0-0.81,0.65-1.46,1.46-1.46c0.81,0,1.46,0.65,1.46,1.46C20.87,19.44,20.22,20.09,19.42,20.09z"
                      />
                      <path
                        fill="#2A93D5"
                        d="M33.35,26.96c-0.64-0.11-1.26,0.31-1.38,0.95c0,0.02-0.34,1.67-1.66,2.49c-0.92,0.57-2.16,0.64-3.68,0.22
                        c-1.95-0.55-3.64-0.34-5.03,0.61c-2.59,1.79-2.96,5.57-2.98,5.73c-0.06,0.65,0.42,1.23,1.07,1.29l0.11,0c0.62,0,1.12-0.46,1.18-1.08
                        c0-0.03,0.29-2.84,1.96-3.99c0.77-0.53,1.8-0.63,3.04-0.28c2.2,0.62,4.08,0.45,5.59-0.5c2.22-1.39,2.7-3.96,2.72-4.07
                        C34.42,27.69,33.99,27.07,33.35,26.96z M19.81,37.75L19.81,37.75L19.81,37.75L19.81,37.75z"
                      />
                      <path
                        fill="#FFFFFF"
                        d="M20.58,28.24c0.11-0.64-0.31-1.26-0.95-1.37c-0.64-0.11-1.26,0.31-1.38,0.94c0,0.01-0.22,1.06-1.03,1.56
                        c-0.57,0.35-1.36,0.39-2.35,0.11c-1.49-0.42-2.8-0.26-3.87,0.48c-1.98,1.37-2.26,4.21-2.27,4.33c-0.06,0.65,0.42,1.23,1.07,1.29
                        l0.11,0c0.61,0,1.12-0.46,1.18-1.07c0-0.02,0.2-1.88,1.26-2.6c0.47-0.32,1.09-0.37,1.88-0.15c1.66,0.47,3.1,0.33,4.26-0.39
                        C20.2,30.29,20.57,28.32,20.58,28.24z M9.91,35.09L9.91,35.09L9.91,35.09L9.91,35.09z"
                      />
                    </svg>
                  </Box>
                </Tooltip>
              </Grid>
              {!performanceMode && (
              <Grid item xs={12} sm={3} className={classes.gridItem}>
                <Tooltip title={t('allTrailsIcons')}>
                  <Box
                    className={clsx(classes.trailIconWrapper, (performanceTrailsOption === TRAILS_OPTIONS.allTrailsIcons) ? classes.trailIconWrapperActive : classes.trailIconWrapper)}
                    onClick={() => selectTrailOption(TRAILS_OPTIONS.allTrailsIcons)}
                  >
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48">
                      <circle fill="#3A414C" cx="24" cy="24" r="26" />
                      <path
                        fill="#2A93D5"
                        d="M33.13,9.75c-3.39,0-6.14,2.75-6.14,6.14c0,3.66,4.65,8.39,5.85,9.55c0.16,0.15,0.41,0.15,0.57,0
                        c1.21-1.16,5.85-5.89,5.85-9.55C39.27,12.5,36.52,9.75,33.13,9.75z M33.13,17.93c-1.13,0-2.05-0.92-2.05-2.05s0.92-2.05,2.05-2.05
                        s2.05,0.92,2.05,2.05S34.26,17.93,33.13,17.93z"
                      />
                      <path
                        fill="#2A93D5"
                        d="M19.42,14.26c-2.42,0-4.37,1.96-4.37,4.37c0,2.61,3.31,5.98,4.17,6.81c0.11,0.11,0.29,0.11,0.4,0
                        c0.86-0.83,4.17-4.2,4.17-6.81C23.79,16.22,21.83,14.26,19.42,14.26z M19.42,20.09c-0.81,0-1.46-0.65-1.46-1.46
                        c0-0.81,0.65-1.46,1.46-1.46c0.81,0,1.46,0.65,1.46,1.46C20.87,19.44,20.22,20.09,19.42,20.09z"
                      />
                      <path
                        fill="#2A93D5"
                        d="M33.35,26.96c-0.64-0.11-1.26,0.31-1.38,0.95c0,0.02-0.34,1.67-1.66,2.49c-0.92,0.57-2.16,0.64-3.68,0.22
                        c-1.95-0.55-3.64-0.34-5.03,0.61c-2.59,1.79-2.96,5.57-2.98,5.73c-0.06,0.65,0.42,1.23,1.07,1.29l0.11,0c0.62,0,1.12-0.46,1.18-1.08
                        c0-0.03,0.29-2.84,1.96-3.99c0.77-0.53,1.8-0.63,3.04-0.28c2.2,0.62,4.08,0.45,5.59-0.5c2.22-1.39,2.7-3.96,2.72-4.07
                        C34.42,27.69,33.99,27.07,33.35,26.96z M19.81,37.75L19.81,37.75L19.81,37.75L19.81,37.75z"
                      />
                      <path
                        fill="#2A93D5"
                        d="M20.58,28.24c0.11-0.64-0.31-1.26-0.95-1.37c-0.64-0.11-1.26,0.31-1.38,0.94c0,0.01-0.22,1.06-1.03,1.56
                        c-0.57,0.35-1.36,0.39-2.35,0.11c-1.49-0.42-2.8-0.26-3.87,0.48c-1.98,1.37-2.26,4.21-2.27,4.33c-0.06,0.65,0.42,1.23,1.07,1.29
                        l0.11,0c0.61,0,1.12-0.46,1.18-1.07c0-0.02,0.2-1.88,1.26-2.6c0.47-0.32,1.09-0.37,1.88-0.15c1.66,0.47,3.1,0.33,4.26-0.39
                        C20.2,30.29,20.57,28.32,20.58,28.24z M9.91,35.09L9.91,35.09L9.91,35.09L9.91,35.09z"
                      />
                    </svg>
                  </Box>
                </Tooltip>
              </Grid>
              )}
            </Grid>
            <CheckboxInput
              label={t('reportDots')}
              value={config.reportDots}
              onChange={(id, value) => updateConfig(config.id, { reportDots: value })}
            />
            <SliderInput
              label={t('reportDotsZoom')}
              value={config.hideReportDotsAtZoom}
              onChange={(id, value) => updateConfig(config.id, { hideReportDotsAtZoom: value })}
              disabled={!config.reportDots}
              min={0}
              max={19}
              step={0.5}
              marks={[{ value: viewport.zoom, label: t('currentZoom') }]}
            />
          </Box>
          <Box className={classes.settingsSection}>
            <Typography className={classes.settingsTitle}>{t('labels')}</Typography>
            <CheckboxInput
              label={t('assetLabels')}
              value={config.permanentLabels}
              onChange={(id, value) => updateConfig(config.id, { permanentLabels: value })}
            />
            <CheckboxInput
              label={t('kmlLabels')}
              value={config.kmlLabels}
              onChange={(id, value) => updateConfig(config.id, { kmlLabels: value })}
            />
          </Box>
          <Box className={classes.settingsSection}>
            <Typography className={classes.settingsTitle}>ADS-B</Typography>
            <CheckboxInput
              label="Show ADS-B Data"
              value={config.adsb}
              onChange={(id, value) => updateConfig(config.id, { adsb: value })}
            />
            <SliderInput
              label="Hide ADS-B Emitters Above Altitude (feet)"
              value={config.hideAdsbAbove}
              onChange={(id, value) => updateConfig(config.id, { hideAdsbAbove: value })}
              disabled={!config.adsb}
              min={0}
              max={40000}
              step={1000}
            />
          </Box>
          {
          // <Box className={classes.settingsSection}>
          //   <Typography className={classes.settingsTitle}>{t('iconAppearance')}</Typography>
          //   <CheckboxInput
          //     label={t('assetClustering')}
          //     value={config.assetClustering}
          //     onChange={(id, value) => updateConfig(config.id, { assetClustering: value })}
          //   />
          // </Box>
          // TODO: turning this on causes the page to crash, commenting out for now as it seems it doesn't actually work in production anyway
          // <Box className={classes.settingsSection}>
          //   <Typography className={classes.settingsTitle}>{t('assetActivity')}</Typography>
          //   <CheckboxInput
          //   label={t('showActiveAssetsOnly')}
          //   value={config.hideInactiveAssets}
          //   onChange={(id, value) => updateConfig(config.id, {hideInactiveAssets: value})}
          //   />
          //   <Box className={classes.sliderWrapper}>
          //     <InputLabel className={classes.inactiveSelectLabel}>Inactive for:</InputLabel>
          //     <Select
          //       className={classes.inactiveSelect}
          //       onChange={e => updateConfig(config.id, { inactiveSinceHours: e.target.value })}
          //       placeholder="Duration of inactivity"
          //       value={config.inactiveSinceHours}
          //       label={t('hoursOfInactivity')}
          //       disabled={!config.hideInactiveAssets}
          //     >
          //       {inactiveHoursOptions.map(opt => (
          //         <MenuItem value={opt.hours} key={opt.hours}>{opt.label}</MenuItem>
          //       ))}
          //     </Select>
          //   </Box>
          // </Box>
          }
          <Box className={classes.settingsSection}>
            <Typography className={classes.settingsTitle}>{t('mapControls')}</Typography>
            <CheckboxInput
              label={t('highContrastControls')}
              value={highContrastControls}
              onChange={() => updateMapControlsContrast(!highContrastControls)}
            />
            <p>{highContrastControls}</p>
          </Box>
          <Box className={classes.settingsSection}>
            <Typography className={classes.settingsTitle}>Weather</Typography>
            <CheckboxInput
              label="Wind Trails"
              value={config.windTrails}
              onChange={(id, value) => updateConfig(config.id, { windTrails: value })}
            />
            <CheckboxInput
              label="Wind Velocity Heatmap"
              value={config.windVelocity}
              onChange={(id, value) => updateConfig(config.id, { windVelocity: value })}
            />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SettingsDrawer;
