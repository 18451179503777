/* eslint-disable no-param-reassign */
import produce from 'immer';
// eslint-disable-next-line import/no-cycle
import { persistor } from '../store';

// TODO: these were GQL queries (assetsQuery and devicesQuery), need to replace with something from trackstar
const queries = [
  {
    name: 'Assets',
    graphql: '', // graphqlAssetsQuery,
    query: 'type:asset',
    icon: 'A'
  },
  {
    name: 'Devices',
    graphql: '', // graphqlDevicesQuery,
    query: 'type:device',
    icon: 'D'
  }];

/**
 * This reducer is for state that needs to be shared application-wide and
 * doesn't fit into any of the other reducers.  For example, pagination
 * arguments for apollo queries belong here.
 *
 * Avoid adding state here if possible.
* */

const initialState = Object.freeze({
  // The currently selected top-level item within the application; eg: Asset/Device/Leg/Mission
  selectedItem: null,
  selectedLeg: null,

  // Omnibox search term / selected query
  textFilter: '',
  // TODO: this was populated by a GQl query
  query: queries[0],
  queries,
  // grouping for each type of omnibox result
  groupBy: {
    assets: 'latestActivity',
    devices: 'none',
    missions: 'none'
  },

  // Timezones for any clocks that to be displayed
  clocks: ['utc'],

  // Used for looking into the past.  NULL means now.
  now: null,

  // application notifications (NOT snackbar notifications)
  notifications: [],

  // snackbar notifications at bottom of screen
  snackbars: {},

  // true if there are a large number of assets on screen
  // used to disable features like 'All asset trails & icons' trail option
  performanceMode: true,
});

const persistFields = ['clocks', 'groupBy'];

// eslint-disable-next-line consistent-return
const appReducer = (state = initialState, action) => produce(state, draft => {
  /* eslint-disable-next-line */
  switch (action.type) {
    case 'SELECT_ITEM':
      // Because each component selects the fields it requires,
      // we can only rely on id and type when selecting something
      draft.selectedItem = {
        category: action.payload.category,
        color: action.payload.color,
        deviceId: action.payload.deviceId,
        id: action.payload.id,
        make: action.payload.make,
        model: action.payload.model,
        variant: action.payload.variant,
        name: action.payload.name,
        messagingHandle: action.payload.messagingHandle,
        operatorId: action.payload.operatorId,
        operatorName: action.payload.operatorName,
        ownerId: action.payload.ownerId,
        ownerName: action.payload.ownerName,
        // eslint-disable-next-line no-underscore-dangle
        type: action.payload.type || action.payload.__typename,
        device: action.payload.device,
        visualKey: action.payload.visualKey
      };
      break;
    case 'SELECT_LEG':
      draft.selectedLeg = state.selectedLeg?.id === action.payload.id
        ? null
        : { id: action.payload.id };
      break;
    case 'DELETE_ASSET':
      // clear selected item if necessary
      if (state.selectedItem?.id === action.payload.id) {
        draft.selectedItem = null;
      }
      break;
    case 'CLEAR_SELECTION':
      draft.selectedItem = null;
      break;

    case 'SET_ORGANISATIONID':
      // De-select any selected object when switching organisation
      draft.selectedItem = null;
      // Turn performance mode on to avoid downloading all trails etc on orgs with 1000 assets
      draft.performanceMode = true;
      break;

    case 'SET_TEXT_FILTER':
      draft.textFilter = action.payload;
      break;
    case 'SET_ACTIVE_QUERY':
      draft.query = action.payload;
      break;

    case 'SET_QUERY_GROUP_BY':
      draft.query = {
        ...state.query,
        groupBy: action.payload
      };
      break;

    case 'SET_QUERY_SORT_BY':
      draft.query = {
        ...state.query,
        sortBy: action.payload
      };
      break;

    case 'SET_OMNIBOX_GROUP_BY':
      draft.groupBy = action.payload;
      break;

    case 'SET_NOW':
      draft.now = action.payload;
      break;

    case 'ADD_CLOCK':
      draft.clocks = [
        ...new Set(...state.clocks, action.payload)
      ];
      break;

    case 'REMOVE_CLOCK':
      draft.clocks = draft.clocks.filter(c => c !== action.payload);
      break;

    case 'TURN_IT_OFF_AND_ON_AGAIN':
      setTimeout(() => {
        sessionStorage.clear();
        localStorage.clear();
        persistor.purge();
        window.location.reload();
      });
      break;

    case 'DISPLAY_SNACKBAR':
      draft.snackbars = {
        ...state.snackbars,
        [action.payload.id]: {
          ...action.payload
        }
      };
      break;

    case 'DESTROY_SNACKBAR': {
      const newSnackbars = {
        ...state.snackbars
      };
      delete newSnackbars[action.payload.id];

      draft.snackbars = newSnackbars;
      break;
    }

    case 'SET_PERFORMANCE_MODE': {
      draft.performanceMode = !!action.payload.on;
      break;
    }

    case 'CLEAR_DATA':
      draft.selectedItem = null;
      draft.notifications = [];
      break;
    case 'RESET_EVERYTHING': return initialState;
  }
});

export default {
  key: 'app',
  reducer: appReducer,
  version: 4,
  whitelist: persistFields,
  migrations: {
    0: () => initialState,
    1: state => ({
      ...state,
      // TODO: this was populated by a GQl query
      query: queries[0],
      availableQueries: null
    }),
    2: state => ({
      ...state,
      groupBy: {
        assets: 'latestActivity',
        devices: 'none',
        missions: 'none'
      }
    }),
    3: state => ({
      ...state,
      selectedLeg: null
    }),
    4: state => ({
      ...state,
      performanceMode: true,
    }),
  }
};
