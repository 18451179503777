import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import ClickToEditField from 'components/shared/clickToEditField/clickToEditField-view';
import { Paper, Typography, Box } from '@mui/material';
import QRCode from 'react-qr-code';
import useTranslation from 'hooks/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { saveUsercode } from 'apis/trackstar/serenity';
import useStyles, { QRCODE_SIZE } from './organisationDetails-styles';

const OrganisationDetails = ({
  organisation,
  permissions,
  displaySnackbar,
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.organisationSettings');
  const [name, setName] = useState(organisation.name);
  const error = null;
  // TODO: re-enable this when we build a new tracking app?
  const QRCodeEnabled = false;
  const queryClient = useQueryClient();

  useEffect(() => {
    setName(organisation.name);
  }, [organisation]);

  const updateOrganisationName = useMutation(updatedOrg => saveUsercode(updatedOrg), {
    onError: () => {
      displaySnackbar({ id: 'orgNameUpdateFailed', text: t('orgNameUpdateFailed'), type: 'error' });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'orgNameUpdated', text: t('orgNameUpdated'), type: 'success' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['organisations']);
    },
    onMutate: newUsercode => newUsercode,
    mutationKey: 'updateOrganisationName'
  });

  return (
    <Paper className={classes.panel}>
      <Box className={classes.detail}>
        <Typography variant="h5" className={classes.label} gutterBottom>{t('orgName')}</Typography>
        <ClickToEditField
          value={name}
          className={classes.editableField}
          canEdit={permissions.canEditOrganisation}
          onComplete={v => updateOrganisationName.mutate({ ...organisation, name: v })}
          canbeblank={false}
        />
        {/* TODO: check git history for JSX to render org description, when we add an org details field to trackstar */}
        {error && (
          <>
            <Typography variant="h5" className={clsx(classes.label, classes.errorText)} gutterBottom>{t('error')}</Typography>
            <Typography className={classes.errorText}>{` ${error.message}`}</Typography>
          </>
        )}
      </Box>
      {QRCodeEnabled && (
      <Box className={classes.qrCodeWrapper}>
        <QRCode value={organisation.id} size={QRCODE_SIZE} />
      </Box>
      )}
    </Paper>
  );
};

export default OrganisationDetails;
