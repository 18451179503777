import React from 'react';
import { Link } from 'react-router-dom';
import Page from 'components/pages/page';
import useTranslation from 'hooks/useTranslation';
import {
  Grid,
  Container,
  Typography,
  Box,
  Button
} from '@mui/material';
import LeftArrow from '@mui/icons-material/ChevronLeft';
import {
  distance, speed, bearing, coordinate, area
} from 'helpers/unitsOfMeasure';

import TableOfContents from 'components/shared/tableOfContents';
import useStyles from './generalSettings-styles';
import SettingsDetail from './modules/settingsDetail';

const GeneralSettingsPage = ({
  settings,
  updateSetting,
  previousColors,
  setPreviousColors
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.generalSettings');
  const makeUnits = (tag, units) => ({
    id: tag,
    value: `${t(tag)} (${units.label(tag)})`
  });

  const settingsTemplate = [{
    id: 'ui',
    label: t('userInterfaceLabel'),
    description: t('userInterfaceDescription'),
    fields: [{
      id: 'darkMode',
      label: t('darkModeLabel'),
      type: 'bool',
      default: false
    }, {
      id: 'assetDetailSelected',
      label: t('assetDetailToDisplay'),
      type: 'select',
      default: 'bearingAtSpeed',
      options: [
        { id: 'bearingAtSpeed', value: t('bearingAtSpeed') },
        { id: 'altitudeAtSpeed', value: t('altitudeAtSpeed') },
        { id: 'position', value: t('position') },
        { id: 'make', value: t('make') },
        { id: 'model', value: t('model') },
        { id: 'variant', value: t('variant') },
        { id: 'owner', value: t('owner') },
        { id: 'category', value: t('category') }
      ]
    }, {
      id: 'serialType',
      label: t('defaultSerialType'),
      type: 'select',
      default: 'tpSerial',
      options: [
        { id: 'tpSerial', value: t('tpSerial') },
        { id: 'imei', value: t('imei') },
        { id: 'manufacturerSerial', value: t('manufacturerSerial') },
      ]
    }, {
      id: 'rowsPerPage',
      label: t('rowsPerPage'),
      type: 'select',
      default: 10,
      options: [
        { id: 10, value: `10 ${t('rows')}` },
        { id: 25, value: `25 ${t('rows')}` },
        { id: 50, value: `50 ${t('rows')}` },
        { id: 100, value: `100 ${t('rows')}` },
      ]
    }]
  }, {
    id: 'units',
    label: t('unitsLabel'),
    description: t('unitsDescription'),
    fields: [{
      id: 'distance',
      label: t('distanceLabel'),
      type: 'select',
      default: 'kilometres',
      options: [
        makeUnits('kilometres', distance),
        makeUnits('statuteMiles', distance),
        makeUnits('nauticalMiles', distance)
      ]
    }, {
      id: 'speed',
      label: t('speedLabel'),
      type: 'select',
      default: 'kmh',
      options: [
        makeUnits('kmh', speed),
        makeUnits('mph', speed),
        makeUnits('knots', speed)
      ]
    }, {
      id: 'altitude',
      label: t('altitudeLabel'),
      type: 'select',
      default: 'metres',
      options: [
        makeUnits('metres', distance),
        makeUnits('feet', distance)
      ]
    }, {
      id: 'bearing',
      label: t('bearingLabel'),
      type: 'select',
      default: 'degreesTrue',
      options: [
        makeUnits('degreesTrue', bearing),
        makeUnits('degreesMagnetic', bearing)
      ]
    }, {
      id: 'coordinate',
      label: t('coordinateLabel'),
      type: 'select',
      default: 'coordinatesDD',
      options: [
        makeUnits('coordinatesDD', coordinate),
        makeUnits('coordinatesDMS', coordinate),
        makeUnits('coordinatesDDM', coordinate)
      ]
    }, {
      id: 'area',
      label: t('areaLabel'),
      type: 'select',
      default: 'squareKilometres',
      options: [
        makeUnits('squareKilometres', area),
        makeUnits('acres', area),
        makeUnits('hectares', area),
        makeUnits('squareMiles', area),
        makeUnits('squareNauticalMiles', area)
      ]
    }]
  }];

  const tocCategories = Object.keys(settingsTemplate).map(c => ({
    id: settingsTemplate[c].id,
    title: settingsTemplate[c].label
  }));

  const resetFields = category => fields => fields.forEach(field => {
    updateSetting(category.id, field.id, field.default);
    if (field.subFields) resetFields(category)(field.subFields);
  });

  const resetSettingsToDefault = () => {
    settingsTemplate.forEach(category => resetFields(category)(category.fields));
  };

  return (
    <Page>
      <Grid container justifyContent="center" id="scrollSpyWrapper" className={classes.pageWrapper} spacing={0}>
        <Grid item xs={12} sm={10}>
          <Container maxWidth="md">
            <Box className={classes.fieldsWrapper}>
              <Link to="/settings" className={classes.link}>
                <Typography className={classes.linkText} variant="body1" gutterBottom>
                  <LeftArrow />
                  {` ${t('backButton')}`}
                </Typography>
              </Link>
              <Typography variant="h1" component="h1" gutterBottom>{t('title')}</Typography>
              <SettingsDetail
                settingsTemplate={settingsTemplate}
                settings={settings}
                updateSetting={updateSetting}
                previousColors={previousColors}
                setPreviousColors={setPreviousColors}
              />
              <Box className={classes.actionsWrapper}>
                <Button size="large" variant="contained" color="primary" className={classes.button} onClick={resetSettingsToDefault}>
                  {t('resetButton')}
                </Button>
              </Box>
            </Box>
          </Container>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TableOfContents categories={tocCategories} rootElId="#scrollSpyWrapper" />
        </Grid>
      </Grid>
    </Page>
  );
};

export default GeneralSettingsPage;
