/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { Typography, TextField } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useStyles from './clickToEditField-style';

// source: https://github.com/learnwithparam/logrocket-inline-edit-ui
const ClickToEditField = ({
  value,
  placeholder,
  canEdit,
  onComplete,
  canbeblank,
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);
  const classes = useStyles();
  const [isValid, setIsValid] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const isValidEntry = () => {
    if (!canbeblank && (!fieldValue || fieldValue.trim() === '')) {
      setIsValid(false);
      return false;
    }
    setIsValid(true);
    return true;
  };

  const handleKeyDown = event => {
    const { key } = event;
    const allKeys = ['Escape', 'Tab', 'Enter'];
    if (allKeys.indexOf(key) > -1 && isValidEntry()) {
      setEditing(false);
      onComplete(fieldValue);
    }
  };

  const renderText = () => (
    <Typography className={classes.plainText}>
      {fieldValue || placeholder}
    </Typography>
  );

  const clickedOutside = () => {
    if (isEditing && isValidEntry()) {
      onComplete(fieldValue);
      setEditing(false);
    }
  };
  useOnClickOutside(ref, () => clickedOutside());

  const onChange = text => {
    setFieldValue(text);
    isValidEntry();
  };

  const renderEditableField = () => (
    <>
      {
        isEditing ? (
          <div
            ref={ref}
            className={classes.editing}
            onBlur={() => setEditing(false)}
          >
            <TextField
              variant="standard"
              value={fieldValue}
              onChange={e => onChange(e.target.value)}
              className={classes.textField}
              onKeyDown={e => handleKeyDown(e)}
              error={!isValid}
              helperText={!isValid && 'Required Field'}
            />
          </div>
        ) : (
          <div
            className={classes.notEditing}
            onClick={() => setEditing(true)}
          >
            {renderText()}
            <EditIcon className={classes.editIcon} />
          </div>
        )
      }
    </>
  );

  return (
    <section {...props}>
      {canEdit
        ? renderEditableField()
        : renderText() }
    </section>
  );
};

export default ClickToEditField;
