import React, { useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { fetchOrganisationJobs } from 'apis/rest/jobs';
import { useQuery } from 'react-query';
import { useAssetPositions, useAssetReports } from 'repositories/reports/hooks';
import Details from './details';
import Distress from './distress';
import Activity from './activity';
import Jobs from './jobs';
import CollapsePanel from '../modules/collapsePanel';
import useStyles from '../contextbox-styles';

const isAnyGroup = (selectedAsset, desiredGroups) => {
  const groupsArray = Array.isArray(desiredGroups)
    ? desiredGroups
    : [desiredGroups];

  return groupsArray.indexOf(selectedAsset?.category?.group) > -1;
};
const hasActivity = reports => reports?.length > 0;
const hasPositions = positions => positions?.length > 0;

const MODULES = [{
  id: 'jobs',
  Component: Jobs,
  shouldInclude: ({ jobs }) => jobs && jobs.length > 0,
  getProps: ({
    selectedAsset, jobs, timezone, now
  }) => ({
    selectedAsset, jobs, timezone, now
  })
}, {
  id: 'activity',
  Component: Activity,
  shouldInclude: () => true,
  getProps: ({ selectedAsset, reports, orderedLegs }) => ({ selectedAsset, reports, orderedLegs })
}];

const AssetContextBox = ({
  organisationId,
  selectedAsset,
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // assetState,
  contextboxOpen,
  now,
  timezone,
  displaySnackbar,
  setMostRecentDeviceReport,
}) => {
  const classes = useStyles();
  const reports = useAssetReports(selectedAsset?.id);
  const positions = useAssetPositions(selectedAsset?.id);

  const getOrganisationJobs = useQuery(['jobs', organisationId, now], () => fetchOrganisationJobs(organisationId, now), {
    select: data => data?.filter(job => job.assetIds.includes(selectedAsset?.id?.toString()))
  });

  if (!selectedAsset) return null;

  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  const isInDistress = false; // tagHelper.isInDistress(assetState);

  const modules = MODULES
    .filter(mod => mod.shouldInclude({
      selectedAsset,
      reports,
      positions,
      jobs: getOrganisationJobs.isSuccess ? getOrganisationJobs.data : [],
      now,
    }))
    .map(mod => {
      const { Component } = mod;
      const props = mod.getProps({
        selectedAsset,
        reports,
        positions,
        jobs: getOrganisationJobs.isSuccess ? getOrganisationJobs.data : [],
        now,
        timezone,
      });
      return (
        <Component key={mod.id} {...props} />
      );
    });

  return (
    <Box className={clsx(classes.contextboxWrapper, { [classes.collapsed]: contextboxOpen })}>
      <Box className={classes.contextbox}>
        <Box className={classes.contentWrapper}>
          <Details asset={selectedAsset} />
          <Distress isInDistress={isInDistress} asset={selectedAsset} />
          {modules}
        </Box>
      </Box>

      <CollapsePanel />
    </Box>
  );
};

export default AssetContextBox;
