import { fetchOptions } from 'helpers/api';
import moment, { Moment } from 'moment';
import { getRefreshedToken } from 'apis/auth';

const BASE_URL = `${import.meta.env.VITE_SERENITY_REST_URL}/jobs/v1`;

const checkStatus = (response: any) => (
  response.status >= 200 && response.status < 300
    ? Promise.resolve(response)
    : Promise.reject(new Error(`${response.status}: ${response.statusText}`))
);

// Gets current jobs (jobs that haven't ended yet or have no end date set) and jobs that ended
// in the past 24 hours from `now` for specified organisation
// NOTE: now param MUST be in UTC time
export const fetchOrganisationJobs = async (orgId: string, now?: Moment): Promise<Job[]> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`
  };
  if (!token) return new Promise(() => [] as Job[]);
  const nowUtc = now ? now.utc() : moment().utc(); // server only talks UTC
  const yesterday = nowUtc.clone().subtract(1, 'days').toISOString(); // 24 hours ago
  const until = nowUtc.clone().add(365, 'days').toISOString(); // 365 days from now
  return fetch(`${BASE_URL}/jobs?organisationId=${orgId}&from=${yesterday}&until=${until}`, {
    ...fetchOptions,
    headers
  } as RequestInit)
    .then(checkStatus)
    .then(response => response.json())
    .catch(console.error);
};
