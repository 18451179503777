import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  app: {
    textAlign: 'center',
    minHeight: '100vh',
    width: '100%',
    display: 'flex'
  }
}));
