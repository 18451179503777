export default (state = { messages: [], sentMessages: {} }, action) => {
  switch (action.type) {
    case 'RECEIVE_MESSAGE': {
      return {
        ...state,
        messages: [...state.messages, action.message],
      };
    }

    case 'STATUS_MESSAGE': {
      // deal with out of order status messages (if state is already set to sent, don't change it)
      if (state.sentMessages[action.payload.messageId]?.status === 'sent') return state;
      return {
        ...state,
        sentMessages: { ...state.sentMessages, [action.payload.messageId]: action.payload },
      };
    }

    default:
      return state;
  }
};
