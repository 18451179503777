import React from 'react';

const Container = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m21.744 1.4541h-11.497c-0.12602 0-0.22296 0.10663-0.22296 0.23266v28.626c0 0.12602 0.10664 0.23266 0.23266 0.23266h11.487c0.12602 0 0.23266-0.10663 0.23266-0.23266v-28.626c-0.009694-0.12602-0.10663-0.23266-0.23266-0.23266z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Container;
