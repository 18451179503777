import React, { useState } from 'react';
import Modal from 'react-modal';
import {
  Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography
} from '@mui/material';
import languages from 'constants/languages';
import useTranslation from 'hooks/useTranslation';
import { isValidEmail } from 'utils/strings';
import { useMutation, useQueryClient } from 'react-query';
import { createContact } from 'apis/trackstar/serenity';
import { CountryCode, AsYouType } from 'libphonenumber-js';
import useStyles from '../editContactModal/editContactModal-styles';

interface CreateContactModalProps {
  creatingContact: boolean;
  onClose: () => void;
  defaultLanguage: string;
  countryAndCallingCodes: any[];
}
const CreateContactModal = ({
  creatingContact,
  onClose,
  defaultLanguage = 'en',
  countryAndCallingCodes,
}: CreateContactModalProps): JSX.Element => {
  const t = useTranslation('pages.organisationSettings.contact');
  const classes = useStyles();
  const [name, setName] = useState('');
  const [language, setLanguage] = useState(defaultLanguage);
  const [type, setType] = useState<'SMS' | 'Email' | 'Phone'>('SMS');
  const [contact, setContact] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const queryClient = useQueryClient();

  const stringEmpty = (s: string): boolean => !s || s.trim().length === 0;

  const doCreateContact = useMutation((c: any) => createContact(c.contact, c.type, c.name, c.language), {
    // TODO: success message, error message, rollback UI fail
    // onMutate: async variables => console.log('mutate'),
    // onError: (err, variables, context) => console.log('error'),
    // onSuccess: () => console.log('success'),
    onSettled: () => queryClient.invalidateQueries(['contacts', null]),
    mutationKey: 'updatePerson',
  });

  const canSave = () => {
    if (type === 'Email') return !stringEmpty(contact) && isValidEmail(contact);
    if (type === 'Phone' || type === 'SMS') return !stringEmpty(countryCode) && !stringEmpty(contact);
    return true;
  };

  const onSave = () => {
    // // TODO: validate and format based on country code (using libphonenumber-js)
    // // convertToE164Format('phone', phoneCountryCode, next, setPhone, setPhoneE164Format)}
    if (!canSave()) return;
    const updatedCallingCode = countryAndCallingCodes.find(ccc => countryCode === ccc.countryCode)?.callingCode;
    const numberWithUpdatedCallingCode = `+${updatedCallingCode} ${contact}`;
    doCreateContact.mutate({
      name,
      language,
      type: type.toLowerCase(),
      contact: type === 'Email' ? contact : numberWithUpdatedCallingCode,
    });
    onClose();
  };

  return (
    <Modal
      isOpen={creatingContact}
      onRequestClose={onClose}
      className={classes.container}
      overlayClassName={classes.modalOverlay}
    >
      <>
        { /* # Modal Title */ }
        <Box className={classes.header}>
          <Typography variant="h2">{t('addContact')}</Typography>
        </Box>
        <Box className={classes.modalContent}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={9} className={classes.nameLanguage}>
              <TextField
                variant="outlined"
                className={classes.textField}
                label={t('name')}
                onChange={e => setName(e.target.value)}
                value={name || ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{t('language')}</InputLabel>
                <Select
                  variant="standard"
                  id="language"
                  value={language}
                  displayEmpty
                  label={t('language')}
                  onChange={e => setLanguage(e.target.value as string)}
                  className={classes.select}
                >
                  {languages.map(lang => (
                    <MenuItem key={lang} value={lang}>{lang}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{t('type')}</InputLabel>
                <Select
                  variant="standard"
                  id="contactType"
                  name="contactType"
                  value={type}
                  onChange={e => {
                    setContact('');
                    setType(e.target.value as 'SMS' | 'Phone' | 'Email');
                  }}
                  className={classes.select}
                >
                  {['SMS', 'Phone', 'Email'].map(contactType => (
                    <MenuItem key={contactType.toLowerCase()} value={contactType}>{contactType}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {type.toLowerCase() !== 'email' ? (
              <>
                <Grid item xs={12} sm={3}>
                  {/* > Country Code Input */}
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>{t('countryCode')}</InputLabel>
                    <Select
                      variant="standard"
                      id="countryCode"
                      name="countryCode"
                      label={t('countryCode')}
                      onChange={e => setCountryCode(e.target.value as string)}
                      className={classes.select}
                      value={countryCode}
                    >
                      {countryAndCallingCodes.map(country => (
                        <MenuItem key={country.countryCode} value={country.countryCode}>{`${country.countryCode} (+${country.callingCode})`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* > Phone/SMS Input */}
                  <TextField
                    variant="outlined"
                    className={classes.textField}
                    label={t(type.toLowerCase())}
                    onChange={e => setContact(new AsYouType(countryCode as CountryCode).input(e.target.value))}
                    value={contact}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={9}>
                {/* > Email Input */}
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  label={t('email')}
                  onChange={e => setContact(e.target.value)}
                  value={contact || ''}
                  error={!!contact && !isValidEmail(contact)}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </>
      { /* # Cancel / Save Buttons */ }
      <Box className={classes.footer}>
        <Button className={classes.inputButton} onClick={onClose} tabIndex={-1}>{t('cancel')}</Button>
        <Button
          className={classes.inputButton}
          variant="contained"
          color="primary"
          disabled={!name || !contact || (type === 'Email' && !isValidEmail(contact))}
          onClick={onSave}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateContactModal;
