import React from 'react';
import Arrow from '@mui/icons-material/KeyboardArrowDown';
import { Select, MenuItem, FormControl } from '@mui/material';
import { useQueryClient } from 'react-query';
import BootstrapInput from 'components/shared/forms/inputs/bootstrap';
import useStyles from './organisationSelector-styles';

const HeaderOrganisationSelector = ({
  organisationId,
  organisations,
  setOrganisation,
  selectedMapId,
  orgHomeBases,
  jumpToHomeBase,
  setFollow
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const organisationsSortedByName = organisations?.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1)) ?? [];

  const onChange = async e => {
    setOrganisation(e.target.value);
    queryClient.invalidateQueries({ predicate: q => !(q.queryKey[0] in ['membership', 'allOrganisations']) });
    setFollow(selectedMapId, null);
    jumpToHomeBase(selectedMapId, orgHomeBases[e.target.value]);
  };

  if (!organisations.length) return (<></>);

  return (
    <FormControl variant="standard" className={classes.wrapper}>
      <Select
        variant="standard"
        id="organisationSelector"
        value={organisationId}
        onChange={onChange}
        input={<BootstrapInput className={classes.input} />}
        IconComponent={() => (<Arrow className={classes.icon} />)}
      >
        {organisationsSortedByName.map(o => (
          <MenuItem className={classes.menuItem} key={o.id} value={o.id}>{o.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default HeaderOrganisationSelector;
