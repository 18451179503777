import React from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import DeviceDetails from './details';
import CollapsePanel from '../modules/collapsePanel';

import useStyles from '../contextbox-styles';

const DeviceContextBox = ({
  deviceId,
  contextboxOpen,
  now,
  clearSelection,
}) => {
  if (!deviceId) throw new Error('Cannot render a Device Contextbox without providing a Device Id');

  const classes = useStyles();

  return (
    <Box className={clsx(classes.contextboxWrapper, { [classes.collapsed]: contextboxOpen })}>
      <Box className={classes.contextbox}>
        <Box className={classes.contentWrapper}>
          <DeviceDetails
            deviceId={deviceId}
            now={now}
            clearSelection={clearSelection}
          />
        </Box>
      </Box>

      <CollapsePanel />
    </Box>
  );
};

export default DeviceContextBox;
