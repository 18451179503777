import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  picker: {
    background: theme.palette.common.white,
    border: theme.border.default,
    borderRadius: '3px',
    boxShadow: theme.boxShadow.default,
    boxSizing: 'initial',
    width: '250px',
    height: '290px'
  },
  saturation: {
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    width: '100%',
    paddingBottom: '65%',
    position: 'relative',
    overflow: 'hidden'
  },
  Saturation: {
    radius: '3px',
    shadow: 'inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)'
  },
  hue: {
    margin: '3px 4px 3px 6px',
    position: 'relative',
    width: 'auto',
    height: '10px',
    overflow: 'hidden'
  },
  Hue: {
    radius: '2px',
    shadow: 'inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)'
  },
  controls: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden'
  },
  previews: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 12px 2px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1px',
    textAlign: 'right',
    '& button:first-child': {
      marginRight: '15px'
    }
  },
  footer: {
    display: 'flex',
    height: 'auto',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    borderTop: theme.border.default
  }
}));
