import React, {useState, useEffect, MouseEventHandler} from 'react';
import clsx from 'clsx';
import {
  Grid,
  MenuItem,
  InputLabel,
  Select
} from '@mui/material';
import Arrow from '@mui/icons-material/KeyboardArrowDown';
import BootstrapInput from 'components/shared/forms/inputs/bootstrap';
import useTranslation from 'hooks/useTranslation';
import mixpanel from 'mixpanel-browser';
import useStyles from './quickGroup-styles';

type Group = Record<string, unknown>;

interface Query {
  id: string,
  name: string
}
interface QuickViewProps {
  activeQuery: Query,
  setSelectedGroupBy: (group: Group) => void,
  selectedGroupBy: Group,
  listOptionsToggle: boolean,
  omniboxOpen: boolean
}

const QuickView = ({
  activeQuery,
  setSelectedGroupBy,
  selectedGroupBy,
  listOptionsToggle,
  omniboxOpen
}: QuickViewProps): JSX.Element => {
  const t = useTranslation('omnibox.modules.options');
  const PREDEFINED_GROUPINGS = {
    assets: [
      { id: 'latestActivity', name: t('latestActivity') },
      { id: 'make', name: t('make') },
      { id: 'model', name: t('model') },
      // { id: 'status', name: t('status') },
      { id: 'owner', name: t('owner') },
      { id: 'category', name: t('category') },
      { id: 'watchlistGroup', name: t('watchlist') },
      { id: 'operatorName', name: t('operator') }
    ],
    devices: [
      { id: 'none', name: t('none') },
      { id: 'make', name: t('make') },
      { id: 'model', name: t('model') },
      { id: 'status', name: t('status') }
    ],
    missions: [
      { id: 'none', name: t('none') },
      { id: 'type', name: t('type') }
    ]
  } as Record<string, {id: string, name: string}[]>;

  const listOptionsClass = listOptionsToggle ? 'open' : 'closed';
  const omniboxClass = omniboxOpen ? 'omniOpen' : 'omniClosed';

  const classes = useStyles();
  const [query, setQuery] = useState(activeQuery.name.toLowerCase());

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    if (event?.target?.value) {
      setSelectedGroupBy({ ...selectedGroupBy, [query]: event.target.value });
      mixpanel.track('Change Group By', { groupBy: event.target.value });
    }
  };

  // reset selection on query change
  useEffect(() => {
    setQuery(activeQuery.name.toLowerCase());
  }, [activeQuery.name, query]);

  return (
    <Grid container spacing={0} className={clsx(classes.wrapper, listOptionsClass, omniboxClass)}>
      <Grid item xs={12} sm={6}>
        <InputLabel id="quicksort" className={classes.label}>{`${t('groupBy')}:`}</InputLabel>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.selectWrapper}>
        <Select
          variant="standard"
          id="quicksort"
          className={classes.select}
          value={selectedGroupBy[query]}
          onChange={handleChange}
          input={<BootstrapInput />}
          IconComponent={() => (<Arrow className={classes.icon} />)}
        >
          {PREDEFINED_GROUPINGS[query].map(group => (
            <MenuItem key={group.id} value={group.id} className={classes.menuItem}>{group.name}</MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export default QuickView;
