import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Typography
} from '@mui/material';

import AppleAppStoreIcon from './appleAppStoreIcon-view';
import GooglePlayIcon from './googlePlayIcon-view';

const APP_STORE_URL = 'https://apps.apple.com/nz/app/tracplus/id307298018';
const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.tracplus.android';

const UserGuideMessageAndDownloadIcon = ({
  navigate,
  report,
  t,
  classes
}) => {
  if (report.ios) {
    return (
      <>
        <Typography className={classes.continueText}>{t('downloadTheAppFromAppleAppStore')}</Typography>
        <Box className={classes.storeIconWrapper}>
          <Link to={{ pathname: APP_STORE_URL }} target="_blank">
            <AppleAppStoreIcon />
          </Link>
        </Box>
      </>
    );
  }
  if (report.android) {
    return (
      <>
        <Typography className={classes.continueText}>{t('downloadTheAppFromGooglePlay')}</Typography>
        <Box className={classes.storeIconWrapper}>
          <Link to={{ pathname: GOOGLE_PLAY_URL }} target="_blank">
            <GooglePlayIcon />
          </Link>
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography className={classes.continueText}>{t('continueAnyway')}</Typography>
      <Button
        type="button"
        variant="contained"
        className={classes.continueButton}
        onClick={() => navigate('/')}
      >
        {t('continueAnywayButtonText')}
      </Button>
    </>
  );
};

export default UserGuideMessageAndDownloadIcon;
