import React from 'react';
import {
  Box,
  Container,
  Button,
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { ClassNameMap } from '@mui/styles';
import { MTableAction } from '@material-table/core';
import { GetApp } from '@mui/icons-material';
import { useQuery } from 'react-query';
import tableIcons from 'components/shared/icons/tableIcons';
import insensitiveSort from 'utils/insensitiveSort';
import { fetchMissionReports } from 'apis/trackstar/serenity';
import { DateTime } from 'luxon';
import mixpanel from 'mixpanel-browser';
import useStyles from '../reporting-styles';
import PersistentTable from '../../../shared/persistentTable';

const MissionReports = (): JSX.Element => {
  const classes: ClassNameMap = useStyles();
  const t = useTranslation('pages.reporting');

  const getMissionReports = useQuery(['missionReports'], () => fetchMissionReports());
  if (getMissionReports.isError) console.log(getMissionReports.error);

  const data = getMissionReports.isSuccess ? getMissionReports.data.map(mr => ({
    ...mr,
    asset: `${mr.assetRegistration} (${mr.assetMake} ${mr.assetModel})`,
    duration: DateTime.fromMillis(DateTime.fromISO(mr.endTime).toMillis() - DateTime.fromISO(mr.startTime).toMillis()).toFormat('HH:mm:ss'),
  })) : [];

  const maxTableHeight = `${window.innerHeight > 570 ? window.innerHeight - 420 : 150}px`;

  return (
    <Container className={classes.tableContainer} maxWidth="md">
      <Box className={classes.materialTable}>
        <PersistentTable
          settingsCategory="missionReportsTable"
          icons={tableIcons}
          actions={[{
            icon: () => <GetApp />,
            iconProps: { color: 'primary' },
            tooltip: t('downloadPDF'),
            onClick: (e: any, rowData: MissionReport) => {
              const element = document.createElement('a');
              element.setAttribute('href', rowData.reportUrl);
              element.setAttribute('download', rowData.reportUrl);
              element.setAttribute('target', '_blank');
              document.body.appendChild(element); // Required for this to work in FireFox
              element.click();
              mixpanel.track('Download Mission Report', { missionReport: rowData });
            },
          }]}
          isLoading={getMissionReports.isLoading}
          data={data}
          columns={[
            {
              title: t('missionId'),
              field: 'missionId',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a: MissionReport, b: MissionReport) => insensitiveSort(a.missionId, b.missionId),
              render: (rowData: MissionReport) => rowData.missionId || '-',
            },
            {
              title: t('asset'),
              field: 'asset',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
            },
            {
              title: t('start'),
              field: 'startTime',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              render: (rowData: MissionReport) => DateTime.fromISO(rowData.startTime).toFormat('d MMM yyyy, HH:mm:ss'),
            },
            {
              title: t('end'),
              field: 'endTime',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              render: (rowData: MissionReport) => DateTime.fromISO(rowData.endTime).toFormat('d MMM yyyy, HH:mm:ss'),
            },
            {
              title: t('duration'),
              field: 'duration',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
            }
          ]}
          options={{
            draggable: false,
            showTitle: false,
            search: true,
            paging: true,
            pageSizeOptions: [10, 25, 50, 100],
            pageSize: 10,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
            maxBodyHeight: maxTableHeight,
          }}
          localization={{
            header: {
              actions: t('download')
            }
          }}
        />
      </Box>
    </Container>
  );
};

export default MissionReports;
