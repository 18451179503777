import React, { useState } from 'react';

import { CustomPicker } from 'react-color';
import { Saturation, Hue } from 'react-color/lib/components/common';
import useStyles from './customColorPicker-style';
import CustomColorPickerFields from './customColorPickerModules/inputFields/inputFields-view';
import CustomColorPickerPointerCircle from './customColorPickerModules/saturationPointerCircle/saturationPointerCircle-view';
import CustomColorPickerPointer from './customColorPickerModules/huePointer/huePointer-view';
import CustomColorPickerButton from './customColorPickerModules/controlButtons/controlButtons-view';
import CustomColorPickerPreviews from './customColorPickerModules/colorPreviews/colorPreviews-view';
import CustomColorPickerPreviousSelectedColors from './customColorPickerModules/previousSelectedColors/previousSelectedColors-view';

const CustomColorPicker = ({
  rgb,
  hex,
  hsl,
  hsv,
  onChange,
  onAccept,
  onCancel,
  previousColors
}) => {
  const initialColor = previousColors?.length > 0 ? previousColors[previousColors.length - 1] : hex;

  const [currentColor, setCurrentColor] = useState(initialColor);

  const classes = useStyles();

  return (
    <div className={classes.picker}>
      <div className={classes.saturation}>
        <Saturation
          className={classes.Saturation}
          hsl={hsl}
          hsv={hsv}
          pointer={CustomColorPickerPointerCircle}
          onChange={onChange}
        />
      </div>

      <div className={classes.hue}>
        <Hue
          style={classes.Hue}
          hsl={hsl}
          onChange={onChange}
          pointer={CustomColorPickerPointer}
        />
      </div>

      <div className={classes.controls}>
        <div>
          <CustomColorPickerFields
            onChange={onChange}
            rgb={rgb}
            hsl={hsl}
            hex={hex}
          />
          <div className={classes.footer}>
            <CustomColorPickerPreviousSelectedColors
              selectedColors={previousColors}
              onClick={onChange}
            />
            <div className={classes.previews}>
              <CustomColorPickerPreviews
                rgb={rgb}
                currentColor={currentColor}
              />
              <div className={classes.actions}>
                <CustomColorPickerButton label="Cancel" onClick={onCancel} />
                <CustomColorPickerButton label="Apply" onClick={onAccept} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPicker(CustomColorPicker);
