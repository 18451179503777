/* eslint-disable no-param-reassign */
import produce from 'immer';
import moment from 'utils/moment';
import { AnyAction } from 'redux';

/**
 * This reducer is for all application configuration, UI settings etc.
 */

const defaultTimezone = moment.tz.guess();

export interface TableSettings {
  searchQuery: string;
  selectedPage: string;
  sortOrder: { field: string, order: 'asc' | 'desc' } | null;
  filters?: { columnId: string, value: string }[];
}

export interface Settings {
  ui: {
    darkMode: boolean | null,
    assetDetailSelected: string,
    serialType: 'tpSerial' | 'imei' | 'manufacturerSerial',
    rowsPerPage: number
  },
  locale: {
    timezone: string,
    language: 'en' | 'es',
  },
  map: any,
  assetsTable: TableSettings,
  devicesTable: TableSettings,
  tripReportsTable: TableSettings & {
    query: {
      asset: string,
      from: string,
      until: string
    } | null
  },
  missionReportsTable: TableSettings,
  friendsTable: TableSettings,
  groupsTable: TableSettings,
  sharedByMeTable: TableSettings,
  sharedToMeTable: TableSettings,
  units: {
    distance: 'kilometres' | 'statuteMiles' | 'nauticalMiles' | 'metres' | 'feet',
    speed: 'kmh' | 'km/h' | 'mph' | 'knots',
    altitude: 'metres',
    bearing: 'degreesTrue' | 'degreesGeographic' | 'degreesMagnetic',
    coordinate: 'coordinatesDD' | 'coordinatesDMS' | 'coordinatesDDM',
    area: 'squareKilometres' | 'acres' | 'hectares' | 'squareMiles' | 'squareNauticalMiles',
  }
}

const initialTableSettings: TableSettings = {
  searchQuery: '',
  selectedPage: 0,
  sortOrder: null,
};

const initialState: Settings = {
  ui: {
    darkMode: null,
    assetDetailSelected: 'altitudeAtSpeed',
    serialType: 'tpSerial',
    rowsPerPage: 10
  },
  locale: {
    timezone: defaultTimezone,
    organisationTimezone: {},
    language: 'en'
  },
  map: {
    highContrastControls: false,
    previousColors: [], // this is a history of colors selected in the custom color picker plugin
    homeBases: {}
  },
  assetsTable: {
    filters: [],
    ...initialTableSettings
  },
  devicesTable: initialTableSettings,
  tripReportsTable: {
    query: null,
    ...initialTableSettings
  },
  missionReportsTable: initialTableSettings,
  friendsTable: initialTableSettings,
  groupsTable: initialTableSettings,
  sharedByMeTable: initialTableSettings,
  sharedToMeTable: initialTableSettings,
  // Units can be assumed to be stored metric, displayed as per these settings
  units: {
    distance: 'kilometres',
    speed: 'kmh',
    altitude: 'metres',
    bearing: 'degreesTrue',
    coordinate: 'coordinatesDD',
    area: 'squareKilometres'
  }
};

// eslint-disable-next-line consistent-return
const settingsReducer = (state: Settings = initialState, action: AnyAction): Settings => produce(state, draft => {
  /* eslint-disable-next-line */
  switch (action.type) {
    // Set any setting by providing the category and field ids, along with a value
    case 'UPDATE_SETTING':
      draft[action.payload.category][action.payload.field] = action.payload.value;
      break;

    case 'TOGGLE_CONTROL_CONTRAST':
      draft.map.highContrastControls = action.payload;
      break;

    case 'TOGGLE_DARKMODE':
      draft.ui.darkMode = !state.ui.darkMode;
      break;

    case 'SET_ORG_TIMEZONE': {
      const { organisationId, newOrganisationTimezone } = action.payload;
      draft.locale.organisationTimezone = {
        ...state.locale.organisationTimezone,
        [organisationId]: newOrganisationTimezone
      };
      draft.locale.timezone = newOrganisationTimezone;
      break;
    }

    case 'SET_LANGUAGE':
      draft.locale.language = action.payload;
      // Load locale data common to the new language, in case we haven't got it yet
      moment.locale(action.payload);
      break;

    case 'SET_ORG_HOME_BASE': {
      const { orgId, homeBase } = action.payload;
      draft.map.homeBases[orgId] = {
        ...homeBase
      };
      break;
    }

    // Shortcut setters for specific fields, so we can offer easy-toggle
    case 'SET_PREVIOUS_COLORS':
      if (state.map.previousColors) {
        const preColorsLen = state.map.previousColors.length;
        if (preColorsLen < 5) {
          draft.map.previousColors = [...state.map.previousColors, action.payload];
        } else {
          draft.map.previousColors = [...state.map.previousColors.slice(1), action.payload];
        }
      } else {
        draft.map.previousColors = [action.payload];
      }
      break;

    case 'RESET_EVERYTHING': return initialState;
  }
});

export default {
  key: 'settings',
  reducer: settingsReducer,
  version: 12,
  migrations: {
    0: (): Settings => initialState,
    1: (state): any => ({
      ...state,
      map: {
        ...state.map,
        highlightSelectedObject: true,
        unselectedItemOpacity: 0.5
      }
    }),
    2: (state): any => ({
      ...state,
      map: {
        ...state.map,
        showZoomControls: true,
        animateToSelection: false
      }
    }),
    3: (state): any => ({
      ...state,
      map: {
        ...state.map,
        homeBases: {}
      }
    }),
    4: (state): any => ({
      ...state,
      locale: {
        ...state.locale,
        organisationTimezone: {}
      }
    }),
    5: (state): any => ({
      ...state,
      map: {
        ...state.map,
        previousColors: []
      }
    }),
    6: (state): any => ({
      ...state,
      map: {
        ...state.map,
        lastNumberOfDaysReported: 1
      }
    }),
    7: (state): any => ({
      ...state,
      ui: {
        ...state.ui,
        assetDetailSelected: 'altitudeAtSpeed',
        defaultSerialType: 'tpSerial'
      }
    }),
    8: (state): any => state,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    9: (state): any => {
      delete state.settings.locale.availableTimezones;
      delete state.settings.locale.availableLanguages;
      delete state.settings.availableUnits;
      return state;
    },
    10: (state): any => ({
      ...state,
      panOnSelect: false,
      units: {
        ...state.units,
        bearing: 'degreestrue',
        coordinate: 'coordinatesdd',
        area: 'squarekilometres'
      },
    }),
    11: (state): any => ({
      ...state,
      units: {
        ...state.units,
        bearing: 'degreesTrue',
        coordinate: 'coordinatesDD',
        area: 'squareKilometres'
      },
    }),
    12: (state): any => ({
      ...state,
      ui: {
        ...state.ui,
        serialType: state.ui.defaultSerialType,
        rowsPerPage: 10,
      },
      assetsTable: {
        ...state.assetsTable,
        ...initialTableSettings,
        filters: []
      },
      devicesTable: {
        ...state.devicesTable,
        ...initialTableSettings,
        selectedPage: 0,
      },
      tripReportsTable: {
        ...state.tripReportsTable,
        ...initialTableSettings,
        query: null
      },
      missionReportsTable: {
        ...state.missionReportsTable,
        ...initialTableSettings,
      },
      friendsTable: {
        ...state.friendsTable,
        ...initialTableSettings,
      },
      groupsTable: {
        ...state.groupsTable,
        ...initialTableSettings,
      },
      sharedByMeTable: {
        ...state.sharedByMeTable,
        ...initialTableSettings,
      },
      sharedToMeTable: {
        ...state.sharedToMeTable,
        ...initialTableSettings,
      },
    })
  }
};
