import { SvgIcon } from '@mui/material';
import React from 'react';

const DirectionIcon = ({ style, fillColor, circle }: {style?: React.CSSProperties, fillColor?: string, circle?: boolean}): JSX.Element => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={style}>
    {circle && <circle fill="#fff" cx="12" cy="12" r="12" />}
    <path fill={fillColor || 'rgba(0,0,0,1)'} d="M17.74,16.68C17.54,16.89,17.27,17,17,17c-0.12,0-0.25-0.02-0.37-0.07L12,15.08l-4.63,1.85c-0.38,0.15-0.83,0.05-1.11-0.25c-0.28-0.31-0.34-0.75-0.16-1.12l5-10c0.34-0.68,1.45-0.68,1.79,0l5,10C18.08,15.92,18.02,16.37,17.74,16.68z" />
  </SvgIcon>
);

export default DirectionIcon;
