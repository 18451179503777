/* eslint-disable import/no-named-as-default-member */
import { getApiUrl } from 'helpers/api';
import { push } from 'connected-react-router';
import { staffJoinOrganisation, leaveOrganisation as leaveOrganisationApi } from 'apis/organisations';
import { queryClient } from 'react-query-client';
import { AuthErrorCodes } from 'firebase/auth';
import { authenticateLegacy } from 'apis/trackstar/serenity';
import mixpanel from 'mixpanel-browser';
import api from '../apis/auth';

const MESSAGING_LIVE = getApiUrl('messaging/v1/live', 'ws');

let tokenRefresher;

export const signup = (name, email, password, token) => dispatch => {
  dispatch({
    type: 'SIGNUP_REQUEST'
  });

  api.createInvitedUser(name, email, password, token)
    .then(
      signupResponse => {
        dispatch({ type: 'SET_AUTHTOKEN', payload: signupResponse.token });
        dispatch({
          type: 'SIGNUP_SUCCESS'
        });
        mixpanel.track('User Signup', { success: true, name, email });
        dispatch(push('/'));
      },
      error => {
        dispatch({
          type: 'SIGNUP_FAILURE',
          payload: error
        });
        mixpanel.track('User Signup', { success: false, name, email });
      }
    );
};

// Callbacks are separated because of redirects needing to split before-login and
// after-login functions
export const SSOSignupCallbackSuccess = signupResponse => dispatch => {
  dispatch({ type: 'SET_AUTHTOKEN', payload: signupResponse.token });
  dispatch({
    type: 'SIGNUP_SUCCESS'
  });
  mixpanel.track('User SSO Signup', { success: true });
  dispatch(push('/login?loading'));
};

export const SSOSignupCallbackFailure = error => dispatch => {
  dispatch({
    type: 'SIGNUP_FAILURE',
    payload: error
  });
  mixpanel.track('User SSO Signup', { success: false });
};

export const login = (email, password, rememberMe) => dispatch => {
  dispatch({
    type: 'LOGIN_REQUEST'
  });

  api.login(email, password, rememberMe)
    .then(
      loginResponse => {
        // Set current timestamp on login so we can force logout before auth token expires
        localStorage.setItem('loginTimestamp', Date.now());
        dispatch({ type: 'SET_AUTHTOKEN', payload: loginResponse.token });
        return dispatch({ type: 'LOGIN_SUCCESS' });
      },
      error => {
        if (error === 'Internal Server Error') {
          dispatch({ type: 'LOGIN_FAILURE', payload: 'serverError' });
        } else if (error.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
          dispatch({ type: 'LOGIN_FAILURE', payload: 'tooManyAttempts' });
        } else {
          dispatch({ type: 'LOGIN_FAILURE', payload: 'loginFailed' });
        }
      }
    );
};

export const legacyLogin = (email, password) => async dispatch => {
  localStorage.setItem('legacyUser', email);
  localStorage.setItem('legacyPw', password);
  const loginResponse = await authenticateLegacy(email, password);
  if (loginResponse?.success === true) {
    await dispatch({ type: 'LEGACY_LOGIN', payload: loginResponse.usercodes.usercode[0] });
    dispatch(push('/invite-viewonly'));
  }
};

export const updateUser = (name, email) => ({
  type: 'UPDATE_USER',
  payload: { name, email },
});

export const joinOrganisation = (organisation, user) => dispatch => {
  const adminRoleId = user.allRoles.find(r => r.label === 'Administrator').id;
  staffJoinOrganisation(organisation.id, [adminRoleId])
    .then(dispatch({ type: 'JOIN_ORGANISATION_SUCCESS', payload: { organisation, user } }))
    .then(() => queryClient.invalidateQueries('organisations'))
    .catch(e => dispatch({ type: 'JOIN_ORGANISATION_FAILURE', payload: e }));
};

export const leaveOrganisation = organisation => dispatch => {
  leaveOrganisationApi(organisation.id)
    .then(dispatch({ type: 'LEAVE_ORGANISATION_SUCCESS', payload: organisation }))
    .catch(e => dispatch({ type: 'LEAVE_ORGANISATION_FAILURE', payload: e }));
};

export const SSOLoginCallbackSuccess = loginResponse => dispatch => {
  localStorage.setItem('loginTimestamp', Date.now());
  dispatch({ type: 'SET_AUTHTOKEN', payload: loginResponse.token });
  return dispatch({ type: 'LOGIN_SUCCESS' });
};

export const SSOLoginCallbackFailure = error => dispatch => {
  if (error.code === 'auth/account-exists-with-different-credential') {
    dispatch({ type: 'LOGIN_FAILURE', payload: 'differentProviderError' });
  } else if (error === 'Internal Server Error') {
    dispatch({ type: 'LOGIN_FAILURE', payload: 'serverError' });
  } else {
    dispatch({ type: 'LOGIN_FAILURE', payload: 'loginFailed' });
  }
};

export const logout = (redirect = true) => dispatch => {
  dispatch({ type: 'LOGOUT' });
  dispatch({ type: 'CLEAR_DATA' });
  dispatch({ type: 'WS_DISCONNECT', host: MESSAGING_LIVE });
  api.logout();
  clearInterval(tokenRefresher);
  if (redirect) dispatch(push('/login'));
};

export const setOrganisation = orgId => ({
  type: 'SET_ORGANISATIONID',
  payload: orgId
});

export const setAuthToken = token => ({
  type: 'SET_AUTHTOKEN',
  payload: token
});

export const setRememberMe = rememberMe => ({
  type: 'SET_REMEMBER_ME',
  payload: rememberMe
});

export const setStatusColor = color => ({
  type: 'SERVER_UPDATE_STATUS_COLOR',
  payload: color
});
export const readAllNotifications = () => ({
  type: 'READ_ALL_NOTIFICATIONS'
});

export const unauthorized = () => ({
  type: 'UNAUTHORIZED'
});
export const unauthenticated = logout.bind(null, true);

export const initApp = () => ({
  type: 'INIT'
});
