import Smart from 'hocs/smart';
import MapView from './map-view';

export default Smart(
  MapView,
  (state, ownProps) => ({
    isClosable: state.map.isClosable,
    highContrastControls: state.settings.map.highContrastControls,
    orgHomeBase: state.settings.map.homeBases[state.session.organisationId],
    follow: state.map.assetsAreBeingFollowedOnMaps[ownProps.config.id],
    organisationId: state.session.organisationId,
    selectedReport: state.reports.selectedReportPerMap[ownProps.config.id],
    viewport: state.map.viewports[ownProps.config.id],
    cursorPosition: state.positions.cursor,
    historicMode: !!state.app.now,
    adsbAircraft: state.map.adsbAircraft[ownProps.config.id]
  }),
  ac => ({
    jumpToHomeBase: ac.map.jumpToHomeBase,
    resetMap: ac.map.resetMap,
    closeMap: ac.map.closeMap,
    setFollow: ac.map.setFollow,
    setSelectedReport: ac.reports.setSelectedReport,
    zoomIn: ac.map.zoomIn,
    zoomOut: ac.map.zoomOut,
    setAdsbAircraft: ac.map.setAdsbAircraft
  })
);
