import { getAllTimezones, getCountryForTimezone } from 'countries-and-timezones';

const onlyUtc = ({ name }) => /(\w+\/\w+|^UTC$)/g.test(name) && !/etc/gi.test(name);
const byName = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};
const toFriendlyString = ({ name, countries, utcOffsetStr }) => ({
  id: name,
  label: `${countries.length ? getCountryForTimezone(name).name : 'Coordinated Universal Time'} (${name.replace(/_/g, ' ')}) GMT ${utcOffsetStr}`
});

export default Object
  .values(getAllTimezones())
  .filter(onlyUtc)
  .sort(byName)
  .map(toFriendlyString);
