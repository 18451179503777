import React from 'react';
import moment from 'moment';
import {
  Box, List, ListItem, ListItemIcon, ListItemText
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { isSignificantReport } from 'helpers/events';
import SignificantReport from './significantReport';
import InsignificantReports from './insignificantReports';

import useStyles from './leg-styles';

const isEventVisible = (event, filter) => event && !filter.blacklist.includes(event);

const LegInner = ({
  reports,
  reportFilter
}) => {
  const classes = useStyles();
  const t = useTranslation('contextbox.asset.activity');

  if (!reports) {
    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.iconWrapper}>
            <img alt="no event icon" src="eventIcons/none.svg" />
          </ListItemIcon>
          <ListItemText className={classes.listText}>
            <Box className={classes.eventName}>{t('noActivity')}</Box>
          </ListItemText>
        </ListItem>
      </List>
    );
  }

  const entries = [];
  let insignificantReports = [];
  reports.slice(0).reverse().forEach(report => {
    const hasEventInView = report.events[0] && isEventVisible(report.events[0], reportFilter);
    const hasForm = report.form && reportFilter.forms;
    const isSignificant = (hasEventInView || hasForm) && isSignificantReport(report);

    if (isSignificant) {
      // Collapse all previously-seen insignificant events
      if (insignificantReports.length > 0) {
        entries.push({
          key: insignificantReports[0].id,
          insignificantReports
        });
        insignificantReports = [];
      }

      // Add a significant entry for this report
      entries.push({
        key: report.id,
        significantReport: report
      });
    } else {
      insignificantReports.push(report);
    }
  });

  if (insignificantReports.length > 0) {
    entries.push({
      key: insignificantReports[0].id,
      insignificantReports
    });
  }

  return (
    <List className={classes.list}>
      {reports?.length > 0
        ? entries.map(entry => (
          entry.significantReport
            ? <SignificantReport key={entry.key} currentTime={moment()} report={entry.significantReport} /> /* Passing in the currentTime here forces the component tree to rerender correctly */
            : <InsignificantReports key={entry.key} reports={entry.insignificantReports} />
        ))
        : (
          <InsignificantReports />
        )}
    </List>
  );
};

export default LegInner;
