import React, { useState, useMemo } from 'react';
import {
  Box, Tooltip, SvgIcon, Fab, Grid, Stack
} from '@mui/material';
import clsx from 'clsx';
import useTranslation from 'hooks/useTranslation';
import filterByText from 'utils/textFilter';
import { fetchAssetsDevices } from 'apis/trackstar/serenity';
import { useQuery } from 'react-query';
import moment from 'utils/moment';
import { fetchOrganisationJobs } from 'apis/rest/jobs';
import { useHaveReportData } from 'repositories/reports/hooks';
import Search from './modules/search';
import DatePicker from './modules/datePicker';
import OmniboxResults from './modules/results';
import useStyles from './omnibox-styles';
import LoadingComponent from './omnibox-results-loading';
import QuickGroup from './modules/quickGroup';

interface OmniboxProps {
  textFilter: string;
  setTextFilter: (text: string) => void,
  now: moment.Moment | undefined,
  toggleOmni: () => void,
  omniboxOpen: boolean,
  setNow: (now: moment.Moment | null) => void,
  displaySnackbar: (snack: Snack) => void,
  organisationId: string,
  setPerformanceMode: (perf: boolean) => void
}

const Omnibox = ({
  textFilter,
  setTextFilter,
  now,
  toggleOmni,
  omniboxOpen,
  setNow,
  displaySnackbar,
  organisationId,
  setPerformanceMode,
}: OmniboxProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('omnibox.settings');
  const [showListOptions, toggleListOptions] = useState(false);

  // if now is null this should result in the same latest reports already in fetchAssetsDevices
  // if in historical mode this will return the latest positions as of midnight on selected date
  const getAssetList = useQuery(
    ['assets', organisationId, now],
    () => fetchAssetsDevices(now),
    {
      notifyOnChangeProps: ['data', 'error'],
      select: data => data.assetsWithDevices,
      refetchInterval: 300 * 1000,
      refetchIntervalInBackground: true,
      onSuccess: (data: Asset[]) => {
        // turn off performance mode if showing less than 200 assigned assets (enables showing all trails at once etc)
        if (data.filter(a => a.device).length < 200) setPerformanceMode(false);
        // setPerformanceMode(false);
      },
    }
  );
  if (getAssetList.isError) displaySnackbar({ id: 'getAssetListFailedSnackbar', text: 'Failed to get asset list, please reload the page.', type: 'error' });

  const getOrganisationJobs = useQuery(['jobs', organisationId, now], () => fetchOrganisationJobs(organisationId, now), {
    refetchInterval: 30 * 1000, // 30 Seconds.
    refetchIntervalInBackground: true,
  });

  const dataWithJobs = useMemo(() => {
    const data: {assets: Asset[] } = { assets: [] };
    if (getAssetList.isSuccess) {
      // Hacky but good luck otherwise. We need to add job data to assets for searching
      const nowDate = now ? moment(now).toDate() : new Date();
      const yesterday = new Date(nowDate.getTime() - 86400000);
      const orgJobs = getOrganisationJobs.isSuccess ? getOrganisationJobs.data : [];
      data.assets = getAssetList.data
        .filter(a => a.device)
        .map(asset => ({
          ...asset,
          jobs: orgJobs?.filter(job => job.assetIds.includes(asset.id.toString()) && (!job.endUTC || new Date(job.endUTC) > yesterday)) || []
        }));
    }
    return data;
  }, [getAssetList, getOrganisationJobs, now]);
  const filteredData = useMemo(() => filterByText(dataWithJobs, textFilter), [textFilter, dataWithJobs]);
  const handleToggleListOptions = (): void => toggleListOptions(!showListOptions);
  const hasReportData = useHaveReportData();

  return (
    <Box className={clsx(classes.omniboxWrapper, { [classes.collapsed]: !omniboxOpen })}>
      <Box className={classes.omnibox}>
        <Box className={classes.overflowWrapper}>
          <Stack spacing={0} direction="column" alignItems="stretch" justifyContent="center" className={classes.queryContainer}>
            <Search className={classes.search} value={textFilter} setValue={setTextFilter} />
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4 / 3}>
              <DatePicker className={classes.datepicker} liveMode={now === null} setNow={setNow} />
              <Fab className={clsx(classes.listOptionsButton, showListOptions ? 'active' : 'inactive')} color="primary" aria-label="add" size="small" onClick={handleToggleListOptions}>
                <SvgIcon className={classes.optionsIcon} width="12" height="12" viewBox="0 0 12 12" fontSize="small">
                  <polygon points="10 0 8 0 8 2 6 2 6 4 12 4 12 2 10 2 10 0" />
                  <rect x="8" y="5" width="2" height="7" />
                  <polygon points="0 10 2 10 2 12 4 12 4 10 6 10 6 8 0 8 0 10" />
                  <rect x="2" width="2" height="7" />
                </SvgIcon>
              </Fab>
            </Stack>
          </Stack>
        </Box>
        <QuickGroup listOptionsToggle={showListOptions} />
        {(getAssetList.isSuccess && getAssetList.data && hasReportData)
          ? <OmniboxResults data={filteredData} />
          : <LoadingComponent /> }
      </Box>
      <Tooltip
        title={omniboxOpen ? t('collapsePanel') : t('expandPanel')}
        placement="right"
        PopperProps={{
          popperOptions: {
            modifiers: {
              offset: {
                enabled: true,
                offset: '0px, 10px'
              }
            }
          }
        }}
      >
        <Box className={classes.collapseButton} onClick={toggleOmni}>
          <SvgIcon className={clsx(classes.icon, { [classes.iconReversed]: !omniboxOpen })} width="16" height="16" viewBox="0 0 16 16">
            <polygon fill="#fff" points="10,13.4 4.6,8 10,2.6 11.4,4 7.4,8 11.4,12" />
          </SvgIcon>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default Omnibox;
