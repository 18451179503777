import { useState, useEffect, useRef } from 'react';

const usePreviousValue = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useComparableState = initialValue => {
  const [value, setValue] = useState(initialValue);
  const previousValue = usePreviousValue(value);
  return [previousValue, value, setValue];
};

export default useComparableState;
