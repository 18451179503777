import React, { useState } from 'react';
import {
  Grid, Button, FormControlLabel, Checkbox
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import TextInput from 'components/shared/forms/inputs/text';
import useStyles from './assetAms-styles';

const OverriddenAmsConfig = ({
  assetId,
  readOnly,
  doUpdateAmsConfiguration,
  config,
}) => {
  const t = useTranslation('pages.assetView');
  const classes = useStyles();
  const [ofConcernTimeout, setOfConcernTimeout] = useState(config.ofConcernTimeout);
  const [overdueTimeout, setOverdueTimeout] = useState(config.overdueTimeout);
  const [stationaryCount, setStationaryCount] = useState(config.stationaryCount || 0);
  const stationaryCountDisabled = !stationaryCount;
  const MIN_TIMEOUT = 2;
  const MAX_TIMEOUT = 60;
  const validateTimeout = timeout => !Number.isNaN(timeout) && !Number.isNaN(parseInt(timeout, 10)) && timeout >= MIN_TIMEOUT && timeout < MAX_TIMEOUT;

  return (
    <Grid container spacing={3} className={classes.assetAmsConfig}>
      <Grid item sm={5}>
        <TextInput
          id="ofConcernTimeout"
          label={t('amsSettings.ofConcernTimeout')}
          type="number"
          value={ofConcernTimeout}
          onChange={(id, value) => setOfConcernTimeout(value)}
          hasError={!validateTimeout(ofConcernTimeout)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item sm={5}>
        <TextInput
          id="overdueTimeout"
          label={t('amsSettings.overdueTimeout')}
          type="number"
          value={overdueTimeout}
          onChange={(id, value) => setOverdueTimeout(value)}
          hasError={!validateTimeout(overdueTimeout)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item sm={5}>
        <TextInput
          id="stationaryCount"
          label={t('amsSettings.stationaryCount')}
          type="number"
          value={stationaryCount}
          onChange={(id, value) => setStationaryCount(value)}
          hasError={!stationaryCountDisabled && !validateTimeout(stationaryCount)}
          disabled={readOnly || stationaryCountDisabled}
        />
      </Grid>
      <Grid item sm={5} className={classes.enableCheckbox}>
        <FormControlLabel
          label={t('amsSettings.enableStationaryCount')}
          control={(
            <Checkbox
              checked={!stationaryCountDisabled}
              onChange={e => setStationaryCount(e.target.checked ? 10 : 0)}
              disabled={readOnly}
            />
          )}
        />
      </Grid>
      <Grid item sm={2} className={classes.inputButtonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.inputButton}
          disabled={readOnly}
          onClick={() => doUpdateAmsConfiguration.mutate({
            assetId, ofConcernTimeout, overdueTimeout, stationaryCount
          })}
        >
          {t('amsSettings.saveButton')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default OverriddenAmsConfig;
