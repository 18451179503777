import React, { useState, useEffect } from 'react';
import moment from 'utils/moment';

const parseTimeSpan = (value, start, end, humanize, format) => {
  if (!value && value !== 0 && !start) {
    // No data whatsoever
    return '--';
  }

  let actualValue = value;

  // Allow omitting value in favour of 2 bookend dates
  if (!value && value !== 0) {
    actualValue = moment(end).diff(moment(start));
  }

  actualValue = moment.duration(actualValue);

  return humanize ? actualValue.humanize() : actualValue.format(format);
};

// Expects one of:
// `value`: an optional number of ms, or
// `startDateTime`, `endDateTime`: bookend dates, their diff will be used as `value`
// Formatting options:
// `humanize` will return a human-friendly string, or
// `format` is a standard moment format string for formatting the output (w/ sensible default)
const TimeSpan = ({
  value,
  startDateTime,
  endDateTime,
  tooltip,
  humanize,
  format = 'YY[y] MM[m] DD[d] h[h] mm[m] ss[s]',
  Component = 'span',
  ...passthroughProps
}) => {
  const [actualValue, setActualValue] = useState(parseTimeSpan(value, startDateTime, endDateTime, humanize, format));

  useEffect(() => {
    setActualValue(parseTimeSpan(value, startDateTime, endDateTime, humanize, format));
  }, [value, startDateTime, endDateTime, humanize, format]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Component {...passthroughProps}>{actualValue}</Component>
  );
};

export default TimeSpan;
