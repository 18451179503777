import React, { useState } from 'react';
import {
  Button, Box, FormControl, Container,
  Dialog, DialogTitle, DialogContent,
  DialogActions, DialogContentText, TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useTranslation from 'hooks/useTranslation';
import MaterialTable, { MTableAction, Action } from '@material-table/core';
import { Add } from '@mui/icons-material';
import tableIcons from 'components/shared/icons/tableIcons';
import { fetchShares } from 'apis/rest/shares';
import {
  befriend, fetchFriends, unfriend,
} from 'apis/trackstar/trackstar';
import insensitiveSort from 'utils/insensitiveSort';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import PersistentTable from 'components/shared/persistentTable';
import mixpanel from 'mixpanel-browser';
import useStyles from './friends-styles';

interface FriendsTableParams {
  userPermissions: any;
  displaySnackbar: (Snack: Snack) => void;
  organisationId: string;
}

const FriendsTable = ({
  userPermissions,
  displaySnackbar,
  organisationId,
}:FriendsTableParams): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('pages.connections.friends');
  const queryClient = useQueryClient();
  const { canEditFriends } = userPermissions;

  const [friends, setFriends] = useState<Friend[]>([]);
  const [invitee, setInvitee] = useState('');
  const [addFriendDialogOpen, setAddFriendDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState({} as Friend);

  const getShares = useQuery(['shares'], () => fetchShares(organisationId));
  if (getShares.isError) displaySnackbar({ id: 'getSharesFailedSnackbar', text: t('getSharesFailed'), type: 'error' });

  const getFriends = useQuery(
    'friends',
    fetchFriends,
    { onSuccess: data => setFriends(data) }
  );
  if (getFriends.isError) displaySnackbar({ id: 'getFriendsFailedSnackbar', text: t('getFriendsFailed'), type: 'error' });

  const removeFriend = useMutation(publicKey => unfriend(publicKey), {
    onMutate: async (publicKey: string) => {
      // Optimistic update
      await queryClient.cancelQueries('friends');
      const previousFriends: Friend[] | undefined = queryClient.getQueryData('friends');
      queryClient.setQueryData(
        'friends',
        (old: any) => old.filter((f: Friend) => (!(f.publicKey === publicKey)))
      );
      return { previousFriends };
    },
    onError: (err, newFriend, context) => {
      // Roll back optimistic update if unfriend fails
      queryClient.setQueryData('friends', context?.previousFriends);
      displaySnackbar({ id: 'removeFriendError', text: t('removeFriendError'), type: 'error' });
      mixpanel.track('Remove Friend', { success: false });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'friendRemoved', text: t('friendRemoved'), type: 'success' });
      mixpanel.track('Remove Friend', { success: true });
    },
    onSettled: () => {
      // Fetch latest friends and groups on error or success
      queryClient.invalidateQueries('friends');
      queryClient.invalidateQueries('groups');
    },
    mutationKey: 'removeFriend',
  });

  //
  // Add Friend
  //
  const handleAddFriend = async (): Promise<any> => {
    let result = {} as Result;
    try {
      result = await befriend(invitee);
    } catch {
      displaySnackbar({ id: 'addFriendError', text: t('addFriendError'), type: 'error' });
      mixpanel.track('Add Friend', { success: false });
    }
    setInvitee('');
    setAddFriendDialogOpen(false);
    if (result.success) {
      displaySnackbar({ id: 'friendAdded', text: t('friendAdded'), type: 'success' });
      mixpanel.track('Add Friend', { success: true });
    }
    await queryClient.invalidateQueries('friends');
    return result;
  };
  const handleAddFriendDialogClose = (): void => {
    setAddFriendDialogOpen(false);
  };

  //
  // Remove Friend
  //
  const handleDeleteConfirm = (): void => {
    removeFriend.mutate(selectedFriend.publicKey);
    setDeleteDialogOpen(false);
  };
  const deleteFriend = (friend: Friend): void => {
    setSelectedFriend(friend);
    setDeleteDialogOpen(true);
  };

  //
  // Actions
  //
  const deleteAction = (rowData: Friend): ((rowData: Friend) => Action<Friend>) | Action<Friend> => ({
    icon: () => <DeleteIcon />,
    tooltip: t('delete'),
    onClick: () => deleteFriend(rowData),
  });
  const createAction = {
    icon: () => <Add className={classes.addButton} />,
    iconProps: { color: 'primary' },
    tooltip: t('befriendInput'),
    isFreeAction: true,
    onClick: () => setAddFriendDialogOpen(true),
  };

  const tableFriends: any = friends.map((friend: Friend) => ({
    ...friend,
    sharesWith: getShares.isSuccess ? getShares.data.filter(s => (
      s.sharee === friend.organisation.name)).length : 0,
    sharesFrom: getShares.isSuccess ? getShares.data.filter(s => (
      s.sharer === friend.organisation.name)).length : 0,
    type: friend.isGroup ? t('group') : t('organisation'),
  }));

  const maxTableHeight = `${window.innerHeight > 570 ? window.innerHeight - 420 : 150}px`;
  return (
    <Container className={classes.tableContainer} maxWidth="md">
      <Box className={classes.materialTable}>
        <PersistentTable
          settingsCategory="friendsTable"
          title={t('title')}
          icons={tableIcons}
          isLoading={getFriends.isLoading}
          columns={[
            {
              title: t('columns.name'),
              field: 'organisation.name',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a: any, b: any) => insensitiveSort(a.organisation.name, b.organisation.name)
            },
            {
              title: t('columns.type'),
              field: 'type',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a: any, b: any) => insensitiveSort(a.organisation.name, b.organisation.name)
            },
            {
              title: t('columns.sharesWith'),
              field: 'sharesWith',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a: any, b: any) => a.sharesWith - b.sharesWith
            },
            {
              title: t('columns.sharesFrom'),
              field: 'sharesFrom',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a: any, b: any) => a.sharesFrom - b.sharesFrom
            },
          ]}
          data={tableFriends}
          actions={canEditFriends ? [createAction, deleteAction] : [] as any}
          options={{
            draggable: false,
            showTitle: false,
            search: true,
            paging: true,
            pageSizeOptions: [10, 25, 50, 100],
            pageSize: 10,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
            maxBodyHeight: maxTableHeight,
          }}
          localization={{
            header: {
              actions: t('columns.actions')
            }
          }}
          components={canEditFriends ? {
            Action: (props: any) => {
              const { action, data } = props;
              return ((action.position === 'toolbar')
                ? (
                  <Button
                    onClick={event => action.onClick(event, data)}
                    className={classes.addButton}
                    variant="contained"
                  >
                    <Add />
                    {t('addButton')}
                  </Button>
                ) : <MTableAction {...props} />
              );
            }
          } : {}}
        />
      </Box>

      <Dialog open={addFriendDialogOpen} onClose={handleAddFriendDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle>{t('addFriendDialogTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogDesc}>{t('befriendDescription')}</DialogContentText>
          <FormControl variant="standard" className={classes.inviteInputWrapper}>
            <TextField
              id="addFriend"
              value={invitee}
              onChange={e => setInvitee(e.target?.value)}
              variant="outlined"
              label={t('friendPublicKeyPrompt')}
              autoFocus
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="primary" className={classes.inputButton} onClick={handleAddFriendDialogClose}>{t('cancel')}</Button>
          <Button disabled={!invitee} variant="contained" className={classes.inputButton} onClick={handleAddFriend}>{t('inviteButton')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="confirmation-dialog-title">{t('confirmDeleteFriendTitle', { friend: selectedFriend?.organisation?.name })}</DialogTitle>
        <DialogContent dividers>{t('confirmDeleteFriendMessage', { friend: selectedFriend?.organisation?.name })}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDeleteDialogOpen(false)}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={handleDeleteConfirm} color="primary">
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
};

export default FriendsTable;
