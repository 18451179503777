import React, {
  useState, useEffect, useCallback, useMemo
} from 'react';
import clsx from 'clsx';
// eslint-disable-next-line object-curly-newline
import { Box, Grid, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import Maps from 'components/maps';
import ReportDetails from 'components/map/overlays/reportDetails';
import AdsbDetails from 'components/map/overlays/adsbDetails';
import useInterval from 'hooks/useInterval';
import { getAircraftWithin } from 'apis/adsb/adsbexchange';
import WebMercatorViewport from '@math.gl/web-mercator';
import { debounce } from 'lodash';
import ldb from 'localdata';
import MapControls from './modules/controls';
import SettingsDrawer from './modules/settingsDrawer';
import useStyles from './map-styles';
import MeasurementPanel from './overlays/measurementDetails';
import { KdTree } from '../../repositories/reports/kdTree';

const distance = (a, b) => ((a.latitude - b.latitude) ** 2) + ((a.longitude - b.longitude) ** 2);

const Map = ({
  index,
  xs,
  config,
  template,
  selected,
  onMouseDown,
  selectedItemForThisMap,
  resetMap,
  assets,
  closeMap,
  isClosable,
  highContrastControls,
  orgHomeBase,
  jumpToHomeBase,
  follow,
  setFollow,
  organisationId,
  setSelectedReport,
  selectedReport,
  zoomIn,
  zoomOut,
  viewport,
  cursorPosition,
  adsbAircraft,
  setAdsbAircraft,
  historicMode
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.map.mapControls');

  const MapInstance = Maps[config.baseLayerLibrary];

  const [measureToggle, setMeasureToggle] = useState(false);
  const [reportToggle, setReportToggle] = useState(false);
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);
  const [reportDetailsPanelWidth, setReportDetailsPanelWidth] = useState(0);
  const [kmlOrder, setKmlOrder] = useState([]);
  const [kmlStatus, setKmlStatus] = useState({});

  useEffect(() => {
    try {
      ldb.list(keys => {
        const persistedOrder = JSON.parse(localStorage.getItem(`${config.id}-kmlOrder`)) || [];
        setKmlOrder(persistedOrder.filter(k => keys.includes(k))); // filter out files that aren't in ldb

        const persistedStatus = JSON.parse(localStorage.getItem(`${config.id}-kmlStatus`)) || {};
        Object.keys(persistedStatus).filter(k => !keys.includes(k)).forEach(k => delete persistedStatus[k]);

        setKmlStatus(persistedStatus);
      });
    } catch (e) {
      console.log('ignoring json parsing error');
    }
  }, [config.id]);

  const setKmlOrderLS = order => {
    if (order instanceof Function) {
      const val = order(kmlOrder);
      setKmlOrder(val);
      localStorage.setItem(`${config.id}-kmlOrder`, JSON.stringify(val));
    } else {
      setKmlOrder(order);
      localStorage.setItem(`${config.id}-kmlOrder`, JSON.stringify(order));
    }
  };

  const setKmlStatusLS = status => {
    if (status instanceof Function) {
      const val = status(kmlStatus);
      setKmlStatus(val);
      localStorage.setItem(`${config.id}-kmlOrder`, JSON.stringify(val));
    } else {
      setKmlStatus(status);
      localStorage.setItem(`${config.id}-kmlStatus`, JSON.stringify(status));
    }
  };

  const getAircraftWithinDebounced = useMemo(() => debounce(getAircraftWithin, 1000), []);

  const getAircraft = useCallback(() => {
    if (!config.adsb || viewport.zoom < 6.5 || historicMode) {
      setAdsbAircraft(config.id, []);
      return;
    }
    if (!viewport.latitude || !viewport.longitude) { return; }

    getAircraftWithinDebounced(viewport.latitude, viewport.longitude)?.then(aircraft => {
      setAdsbAircraft(config.id, aircraft
        .filter(ac => ac.alt_baro !== 'ground')
        .filter(ac => assets.every(a => !a.name.includes(ac.r)))
        .filter(ac => ac.alt_baro < (config.hideAdsbAbove || 40000)));
    });
  }, [config.adsb, config.id, config.hideAdsbAbove, viewport.zoom, viewport.latitude, viewport.longitude, historicMode, setAdsbAircraft, assets]);

  useInterval(getAircraft, 5000);
  useEffect(() => { getAircraft(); }, [getAircraft, viewport]);

  const adsbKdTree = useMemo(() => new KdTree(
    adsbAircraft?.map(a => ({
      hex: a.hex,
      latitude: a.lat,
      longitude: a.lon
    })) || [], distance, ['latitude', 'longitude']
  ), [adsbAircraft]);

  const wmViewport = useMemo(() => (new WebMercatorViewport(viewport)), [viewport]);

  const nearestAdsb = useMemo(() => {
    if (!cursorPosition || !config.adsb) { return undefined; }
    const hex = adsbKdTree?.nearest(cursorPosition, 1)?.at(0)?.at(0)?.hex;
    const ac = adsbAircraft?.find(a => a.hex === hex);
    if (!ac?.lon || !ac?.lat) { return undefined; }
    const projected = wmViewport.project([ac.lon, ac.lat]);
    if (projected[0] > 0 && projected[0] < wmViewport.width && projected[1] > 0 && projected[1] < wmViewport.height) {
      return ac;
    }
    return undefined;
  }, [wmViewport, adsbAircraft, adsbKdTree, config.adsb, cursorPosition]);

  const { minZoom } = Object.values(template.sources)[0];
  const { maxZoom } = Object.values(template.sources)[0];

  const followAsset = useCallback(() => {
    setFollow(config.id, !follow);
  }, [config.id, follow, setFollow]);

  useEffect(() => {
    if (selected) setSelectedReport(config.id, null);
  }, [config.id, selected, selectedItemForThisMap, setSelectedReport]);

  // Jump to the pre-set Home Base when users login
  useEffect(() => {
    jumpToHomeBase(organisationId, orgHomeBase);
  }, [jumpToHomeBase, orgHomeBase, organisationId]);

  // Settings Drawer show and close logic
  const handleSettingsDrawerOpen = () => setSettingsDrawerOpen(true);
  const handleSettingsDrawerClose = () => setSettingsDrawerOpen(false);

  // Measurement panel toggle on and off
  const toggleMeasurePanel = () => {
    setMeasureToggle(!measureToggle);
    setReportToggle(false);
  };

  // Report panel toggle on and off
  const toggleReportPanel = () => {
    setReportToggle(!reportToggle);
    setMeasureToggle(false);
  };

  const onJumpToHomeBaseClick = () => {
    setFollow(config.id, false);
    jumpToHomeBase(config.id, orgHomeBase);
  };

  const resetTheMap = () => {
    resetMap(config.id);
  };

  const closeTheMap = () => {
    closeMap(config.id);
  };

  const selectedReportAsset = assets.find(a => a?.deviceId === selectedReport?.deviceId);
  return (
    <Grid item xs={xs} key={`grid${index}`} className={classes.mapInstance}>
      <MapInstance
        className={classes.mapView}
        key={config.id}
        template={template}
        config={config}
        onMouseDown={onMouseDown}
        selected={selected}
        minZoom={minZoom}
        maxZoom={maxZoom}
        follow={follow}
        measureToggle={measureToggle}
        selectedReport={selectedReport}
        assets={assets}
        highContrastControls={highContrastControls}
        kml={kmlOrder}
        kmlStatus={kmlStatus}
        adsbAircraft={config.adsb ? adsbAircraft : []}
        nearestAdsbHex={nearestAdsb?.hex}
      >
        { measureToggle && <MeasurementPanel highContrastControls={highContrastControls} mapId={config.id} selected={selected} reportPanelIsOpen={!!selectedReport} reportDetailsPanelWidth={reportDetailsPanelWidth} /> }
        { (reportToggle || selectedReport) && (
        <ReportDetails
          asset={selectedReportAsset}
          mapId={config.id}
          selectedReport={selectedReport}
          highContrastControls={highContrastControls}
          setReportDetailsPanelWidth={setReportDetailsPanelWidth}
        />
        ) }
        { nearestAdsb && !(selectedReport || reportToggle) && (
          <AdsbDetails
            numAircraft={adsbAircraft.length}
            aircraft={nearestAdsb}
            mapId={config.id}
            selectedReport={selectedReport}
            highContrastControls={highContrastControls}
            setReportDetailsPanelWidth={setReportDetailsPanelWidth}
          />
        ) }
        { follow && (
        <Box className={classes.trackingPanel}>
          <Typography>{`${t('following')}: ${selectedItemForThisMap?.name}`}</Typography>
        </Box>
        ) }
      </MapInstance>

      <Box className={clsx(classes.controlsWrapper, { [classes.controlsWrapperEnabled]: selected })}>
        <MapControls
          provider={template.provider}
          handleSettingsDrawerOpen={handleSettingsDrawerOpen}
          settingsDrawerOpen={settingsDrawerOpen}
          followAsset={followAsset}
          follow={follow}
          reportToggle={reportToggle}
          toggleReportPanel={toggleReportPanel}
          toggleMeasurePanel={toggleMeasurePanel}
          measureToggle={measureToggle}
          jumpToHomeBase={onJumpToHomeBaseClick}
          resetMap={resetTheMap}
          closeMap={closeTheMap}
          isClosable={isClosable}
          highContrastControls={highContrastControls}
          controlsVisible={selected}
          zoomIn={() => zoomIn(config.id, maxZoom)}
          zoomOut={() => zoomOut(config.id, minZoom)}
        />
      </Box>

      <SettingsDrawer
        settingsDrawerOpen={settingsDrawerOpen}
        handleSettingsDrawerClose={handleSettingsDrawerClose}
        config={config}
        setKmlOrder={setKmlOrderLS}
        kmlOrder={kmlOrder}
        kmlStatus={kmlStatus}
        setKmlStatus={setKmlStatusLS}
      />
    </Grid>
  );
};

export default Map;
