import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper
} from '@mui/material';
import Page from 'components/pages/page';
import MultiStageForm from 'components/shared/forms/multiStage';
import LeftArrow from '@mui/icons-material/ChevronLeft';
import useTranslation from 'hooks/useTranslation';
import Group from './group';
import Category from './category';
import Details from './details';
import useStyles from './createAsset-styles';

/**
 * This Page renders a 3-stage form:
 *   1) Select Group (eg: Land/Sea/Air)
 *   2) Select Category (eg: Bus/Truck/Helicopter/Person)
 *   3) Describe the Asset details (eg: name/make/model) and submit
 *
 * The Group, Category and Asset are owned by the Page
 */
const CreateAssetPage = ({
  organisationId,
  navigate,
  previousColors,
  setPreviousColors
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.createAsset');
  const [error, setError] = useState(null);

  // TODO: pull processCategories from trackstar instead
  //   export const categories = gql`
  //   query assetCategories {
  //     assetCategories {
  //       id
  //       label
  //       group
  //     }
  //   }
  // `;
  //
  //   export const createAsset = gql`
  //   mutation createAsset ($asset: AssetInput!) {
  //     createAsset(assetInput: $asset) {
  //       id
  //       name
  //       make
  //       model
  //       variant
  //       color
  //       category {
  //         id
  //         label
  //         group
  //       }
  //       owner {
  //         id
  //         name
  //       }
  //     }
  //   }
  // `;
  // const theseGoLast = ['other', 'unknown', 'uncategorized'];
  // const customSort = (a, b) => {
  //   const fieldA = a.toLowerCase();
  //   const fieldB = b.toLowerCase();
  //   if (theseGoLast.indexOf(fieldA) > -1) return 1;
  //   if (theseGoLast.indexOf(fieldB) > -1) return -1;
  //   if (fieldA < fieldB) return -1;
  //   if (fieldA > fieldB) return 1;
  //   return 0;
  // };
  // const sortByLabel = (a, b) => customSort(a.label, b.label);
  // const processCategories = data => {
  //   // sort the asset categories by group so that 'other, etc' always comes last
  //   // note: have to use tolowercase here, is used to match icons and translations as well.
  //   const categories = data?.reduce((acc, category) => ({
  //     ...acc,
  //     [category.group.toLowerCase()]: [
  //       ...(acc[category.group.toLowerCase()] || []),
  //       category
  //     ]
  //   }), {});
  //
  //   Object.values(categories).forEach(a => a.sort(sortByLabel));
  //
  //   // We need to sort the category keys as well
  //   // We can't assume 'other' will end up last
  //   const keys = Object.keys(categories).sort(customSort);
  //   const sortedCategories = {};
  //
  //   keys.forEach(key => {
  //     sortedCategories[key] = categories[key];
  //   });
  //
  //   return sortedCategories;
  // };
  const groupedCategories = {}; // processCategories(data?.assetCategories);
  const createAsset = () => false;

  // The Group (eg: Land, Sea, Air) is the key into `groupedCategories`
  // to find the categories that are valid for that Group.
  // The Asset itself doesn't know anything about the Group - it's just
  // for selecting the Asset.Category.
  const [group, setGroup] = useState(null);
  const handleSelectGroup = next => newGroup => {
    setGroup(newGroup);
    next();
  };

  // The Asset contains all the details entered by the user
  const [asset, setAsset] = useState({
    name: '',
    categoryId: '',
    make: '',
    model: '',
    variant: '',
    color: '',
    ownerOrganisationId: organisationId,
    imageUrl: '/img/placeholder-contextbox-images/unknown.jpg'
  });
  const updateAsset = (fieldId, value) => setAsset(prevAsset => ({
    ...prevAsset,
    [fieldId]: value
  }));

  // The Category (eg: Bus, Rocket, Person, Helicopter) depends on the Group
  // and is saved into the Asset.
  const [category, setCategory] = useState(null);
  const handleSelectCategory = next => newCategoryId => {
    const newCategory = groupedCategories[group]?.find(c => c.id === newCategoryId);
    if (!newCategory) return;

    updateAsset('categoryId', newCategory.id);

    // Translate
    setCategory({
      ...newCategory,
      label: t(newCategory.label.toLowerCase())
    });

    next();
  };

  // Ensure that the newly created Asset is "owned by" the correct
  // Organisation, even if the selected Organisation is changed partway
  // through creation.
  useEffect(() => {
    if (organisationId.toLowerCase() !== asset.ownerId.toLowerCase()) {
      setAsset(prevAsset => ({
        ...prevAsset,
        ownerOrganisationId: organisationId
      }));
    }
  }, [asset, organisationId]);

  // And finally the Submit handler which just triggers a mutation,
  // passing the Asset details we've collected throughout the form.
  // TODO: replace with trackstar create asset endpoint
  // const [createAsset] = useMutation(CREATE_ASSET, {
  //   onCompleted: ({ createAsset: { id } }) => navigate(`/asset/${id}`),
  //   onError: setError
  // });
  const handleSubmit = () => createAsset({ variables: { asset } });

  const steps = [{
    name: t('landSeaAirStep'),
    isComplete: !!group,
    breadcrumb: { id: group, label: t(group).toLowerCase() },
    getContent: next => (
      <Box className={classes.wrapper}>
        <Typography variant="h1" className={classes.headingText}>{t('landSeaAirDescription')}</Typography>
        <Grid container spacing={3} justifyContent="center">
          {Object.keys(groupedCategories).map(g => (
            <Group
              key={g}
              name={g}
              isCurrent={group === g}
              onSelectGroup={handleSelectGroup(next)}
              t={t}
            />
          ))}
        </Grid>
      </Box>
    )
  }, {
    name: t('categoryStep'),
    isComplete: !!category,
    breadcrumb: category,
    getContent: next => (
      <Category
        categories={groupedCategories[group]}
        currentCategoryId={category?.id}
        onSelectCategoryId={handleSelectCategory(next)}
        t={t}
      />
    )
  }, {
    name: t('assetStep'),
    isComplete: asset?.name && asset?.categoryId && asset?.color,
    getContent: () => (
      <Details
        asset={asset}
        categories={groupedCategories[group]}
        updateAsset={updateAsset}
        previousColors={previousColors}
        setPreviousColors={setPreviousColors}
      />
    )
  }];

  return (
    <Page>
      <Grid className={classes.pageWrapper} container spacing={0}>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <Box className={classes.headerWrapper}>
              <Link to="/assets-and-devices" className={classes.link}>
                <Typography className={classes.linkText} variant="body1" gutterBottom>
                  <LeftArrow />
                  {` ${t('assetsList')}`}
                </Typography>
              </Link>
              <Typography variant="h1">{t('createAsset')}</Typography>
            </Box>
            <Paper className={classes.formPanel}>
              <MultiStageForm
                steps={steps}
                onComplete={handleSubmit}
                error={error}
                translate={t}
                enableReset={false}
              />
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default CreateAssetPage;
