import { sortBy } from 'lodash';
import LatLon from 'geodesy/latlon-spherical';
import moment from 'moment';
import { createSelector } from 'reselect';
import { reportsForAssetOrdered } from './reports';

export const selectedLeg = createSelector(
  [
    state => state.legs[state.app.selectedItem?.type === 'asset' && state.app.selectedItem?.id],
    state => state.app.selectedLeg?.id,
  ],
  (legs, selectedLegId) => legs?.find(leg => leg.id === selectedLegId) || undefined,
  { memoizeOptions: { maxSize: 2000 } }
);

export const legsForAssetOrdered = createSelector(
  [assetLegs => assetLegs || []],
  legs => sortBy(legs, leg => leg.start),
  { memoizeOptions: { maxSize: 2000 } }
);

// in historical mode, we need to filter by cutoff date, and we want to truncate the legs
export const historicalLegsForAsset = createSelector(
  [
    (state, { assetId }) => legsForAssetOrdered(state.legs[assetId]),
    state => state.app.until
  ],
  (legs, until) => {
    legs.filter(leg => leg.start?.isBefore(until) || leg.end?.isBefore(until))
      .map(leg => ({
        ...leg,
        end: leg.end?.isAfter(until)
          ? null
          : leg.end
      }));
  },
  { memoizeOptions: { maxSize: 2000 } }
);

// # NOTE: this is notoriously badly performing since leg generation was implemented client side!!!
export const activityGroupsForAsset = createSelector(
  [
    ({ assetLegs }) => legsForAssetOrdered(assetLegs),
    ({ assetId, organisationId, now }) => reportsForAssetOrdered({ assetId, organisationId, now })
  ],
  (legs, reports) => {
    const makeLeglessKey = (last, next) => `legless|${last?.id}-${next?.id}`;
    let lastLeg = legs[0];
    let remainingLegs = legs;
    const activityGrouped = [];
    let activityGroup;
    const addReportToLeg = (key, report, leg) => {
      if (!activityGroup || activityGroup.key !== key) {
        activityGroup = {
          key, reports: [], leg
        };
        activityGrouped.push(activityGroup);
      }
      activityGroup.reports.push(report);
    };
    const checkReport = report => {
      if (!remainingLegs[0] || moment(report.time) < remainingLegs[0].start) {
        addReportToLeg(makeLeglessKey(lastLeg, remainingLegs[0]), report);
      } else if (remainingLegs[0].end && moment(report.time) > remainingLegs[0].end) {
        [lastLeg] = remainingLegs;
        remainingLegs = remainingLegs.slice(1);
        checkReport(report); // try again with the new leg position
      } else {
        addReportToLeg(remainingLegs[0].id, report, remainingLegs[0]);
      }
    };
    reports.forEach(checkReport);
    return activityGrouped;
  },
  { memoizeOptions: { maxSize: 2000 } }
);

// This is so that we don't repeat the distance calculation for legs on every new position.
// when there's a new position we only need to add the distance since the last point, the whole
// leg distance should only ever need to be calculated once.
export const distanceForLegForAsset = createSelector(
  [
    ({
      assetId, organisationId, now, assetLegs
    }) => activityGroupsForAsset({
      assetId, organisationId, now, assetLegs
    }),
    ({ legId }) => legId
  ],
  (activityGroups, legId) => {
    const reports = activityGroups.find(g => g.leg?.id === legId)?.reports;
    if (!reports) return 0;

    const reportsWithPositions = reports.filter(r => r.position);
    let prevLatLon;
    let distance = 0;
    reportsWithPositions.forEach(report => {
      const latLon = new LatLon(report.position.latitude, report.position.longitude);
      if (prevLatLon) {
        distance += prevLatLon.distanceTo(latLon);
      }
      prevLatLon = latLon;
    });
    return distance;
  },
  { memoizeOptions: { maxSize: 2000 } }
);

export const distanceByLegForAsset = createSelector(
  [
    (state, { assetId }) => state.legs[assetId],
    (state, { assetId }) => assetId,
    state => state.session?.organisationId,
    state => state.app?.now,
  ],
  (assetLegs, assetId, organisationId, now) => {
    if (!assetLegs) return {};
    return assetLegs.reduce((acc, leg) => {
      acc[leg.id] = distanceForLegForAsset({
        assetId, legId: leg.id, organisationId, now, assetLegs
      });
      return acc;
    }, {});
  },
  { memoizeOptions: { maxSize: 2000 } }
);
