import Smart from 'hocs/smart';
import AssetResultView from './assetResult-view';

export default Smart(
  AssetResultView,
  (state, ownProps) => ({
    now: state.app.now,
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // assetState: state.assetState[ownProps.asset?.id],
    assetDetailSelected: state.settings.ui.assetDetailSelected,
    timezone: state.settings.locale.timezone,
  }),
  ac => ({
    clearSelection: ac.app.clearSelection,
    unassignItemFromMap: ac.map.unassignItemFromMap,
  })
);
