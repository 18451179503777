import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    backgroundColor: 'transparent',
    textAlign: 'right',
  },
  content: {
    overflowY: 'auto',
    backgroundColor: theme.palette.common.white,
    border: theme.border.default,
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 12px',
    borderRadius: '4px',
    flexWrap: 'wrap',
    position: 'absolute',
    padding: '10px',
    userSelect: 'none',
    outline: 'none',
    zIndex: -1,
    display: 'none',
    '&.open': {
      top: '55px',
      right: '5px',
      width: '260px',
      zIndex: 999999,
      display: 'flex'
    }
  }
}));
