import React from 'react';
import { Box, Grid } from '@mui/material';
import Lottie from 'react-lottie-player';
import Header from 'components/header';
import StatusBar from 'components/statusbar';
import SystemStatus from 'components/systemStatus';
import useStyles from './loading-styles';
import './loading.css';

const LoadingPage = ({
  systemStatusOpen
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} direction="column">
      <Grid item xs={12} className={classes.headerBar}>
        <Header />
      </Grid>

      <Grid item xs={12} className={classes.contentContainer}>
        <Box className={classes.content}>
          <Box className={classes.wrapper}>
          </Box>
        </Box>
      </Grid>

      {systemStatusOpen && (<SystemStatus />)}

      <Grid item xs={12} className={classes.footerBar}>
        <StatusBar />
      </Grid>
    </Grid>
  );
};

export default LoadingPage;
