import {
  deleteObject,
  getDownloadURL, ref, uploadBytesResumable, UploadTask
} from '@firebase/storage';
import { storage } from 'firebase';

export const UploadAssetImage = (assetId: number, data: File | Blob): UploadTask => {
  const imageRef = ref(storage, `assets/${assetId}`);
  return uploadBytesResumable(imageRef, data);
};

export const GetAssetImage = (assetId: number): Promise<string | null | undefined> => {
  const imageRef = ref(storage, `assets/${assetId}`);
  return (getDownloadURL(imageRef)
    .catch(e => {
      if (['storage/unauthorized', 'storage/object-not-found'].includes(e.code)) {
        return null;
      }
      throw e;
    }));
};

export const RemoveAssetImage = (assetId: number): Promise<void> => {
  const imageRef = ref(storage, `assets/${assetId}`);
  return (deleteObject(imageRef)
    .catch(e => {
      if (['storage/unauthorized', 'storage/object-not-found'].includes(e.code)) {
        return;
      }
      throw e;
    }));
};
