import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'utils/moment';
import useInterval from 'hooks/useInterval';
import useTranslation from 'hooks/useTranslation';
import AssetIcon from 'components/shared/icons/assetIcons';
import { useBearingAtSpeed, useAltitudeAtSpeed, useCoordinates } from 'hooks/settings';
import TimeSince from 'components/shared/timeSince';
import { isInDistress, isOverdue } from 'helpers/tags';
import { useLatestPosition } from 'repositories/reports/hooks';
import useStyles from '../queryResult/queryResult-styles';

// Assets that haven't reported for this long are considered inactive
const INACTIVE_MILLISECONDS = 30 * 60 * 1000;
const getIsActive = (time: number | undefined, now: moment.Moment | undefined): boolean => {
  if (!time) return false;
  return Math.abs(moment(now || undefined).diff(moment.unix(time))) < INACTIVE_MILLISECONDS;
};
const updateIsActive = (time: number | undefined, now: moment.Moment | undefined, isActive: boolean, setIsActive: (active: boolean) => void): void => {
  const newIsActive = getIsActive(time, now);
  if (newIsActive !== isActive) setIsActive(newIsActive);
};

// const getStatesToDisplay = (assetState, tTag) => {
//   const statesToDisplay = [];
//   if (!assetState) return statesToDisplay;
//
//   if (assetState.distress) {
//     statesToDisplay.push(tTag(assetState.distress.translationKey));
//   }
//   if (assetState.ams) {
//     statesToDisplay.push(tTag(assetState.ams.translationKey));
//   }
//   if (assetState.airborne) {
//     statesToDisplay.push(tTag(assetState.airborne.translationKey));
//   }
//   if (assetState.engine) {
//     statesToDisplay.push(tTag(assetState.engine.translationKey));
//   }
//
//   return statesToDisplay;
// };

interface OBResultAssetProps {
  asset: Asset
  selectAsset: (asset: Asset) => void
  isHidden: boolean
  isSelected: boolean
  unassignItemFromMap: () => void
  now: moment.Moment | undefined
  assetDetailSelected: string
  clearSelection: () => void
  timezone: string
}

const OBResultAsset = ({
  asset,
  selectAsset,
  isHidden,
  isSelected,
  unassignItemFromMap,
  now,
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // assetState,
  assetDetailSelected,
  clearSelection,
  timezone,
}: OBResultAssetProps): JSX.Element => {
  const t = useTranslation('omnibox.modules.results');
  const fallbackString = t('never');
  const fallbackStringHistoric = t('neverHistoric');
  // const tTag = useTranslation('tags');
  // const statesToDisplay = getStatesToDisplay(assetState, tTag);
  const classes = useStyles();
  const theme = useTheme();

  const latestPosition = useLatestPosition(asset.id);

  // For determining if the current asset is active or not
  const [isActive, setIsActive] = useState(getIsActive(latestPosition?.received, now));
  // Handle an asset going from inactive -> active
  useEffect(() => {
    updateIsActive(latestPosition?.received, now, isActive, setIsActive);
  }, [isActive, asset, now, setIsActive, latestPosition?.received]);
  // Handle an asset going from active -> inactive
  useInterval(() => {
    updateIsActive(latestPosition?.received, now, isActive, setIsActive);
  }, 60 * 1000);

  const courseSpeed = useBearingAtSpeed(latestPosition);
  const altitudeSpeed = useAltitudeAtSpeed(latestPosition);
  const coordinates = useCoordinates(latestPosition?.latitude, latestPosition?.longitude);

  const assetDetails: Record<string, string | undefined> = {
    bearingAtSpeed: courseSpeed,
    altitudeAtSpeed: altitudeSpeed,
    position: coordinates,
    make: asset.make,
    model: asset.model,
    variant: asset.variant,
    owner: asset.ownerName,
    category: t(asset.category.label.toLowerCase())
  };

  const onAssetItemClick = () => {
    if (isHidden) return;
    selectAsset(asset);
    if (isSelected) {
      clearSelection();
      // eslint-disable-next-line no-unused-expressions
      unassignItemFromMap();
    }
  };

  const assetStateClasses = {
    hidden: isHidden,
    selected: isSelected,
    inactive: !isActive,
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    distress: latestPosition?.events?.includes('EVT_DISTRESS')
      && !latestPosition?.events?.includes('EVT_DISTRESS_CANCEL'), // sInDistress(assetState) || isOverdue(assetState)
  };
  const resultClasses = clsx(classes.result, assetStateClasses, { [classes.hiddenResult]: isHidden, [classes.visibleResult]: !isHidden });
  const resultIconWrapperClasses = clsx(classes.resultIconWrapper, assetStateClasses, { [classes.hiddenResultIconWrapper]: isHidden, [classes.visibleResultIconWrapper]: !isHidden });
  // const distressTextClass = clsx({ distressMessage: isInDistress(assetState) || isOverdue(assetState) });
  const assetIcon = asset.visualKey.id;
  // const latestReportDateTime = moment(asset.positionAt?.timeOfFix).tz(timezone).format('HH:mm DD MMM YYYY');

  return (
    <div className={resultClasses} onClick={onAssetItemClick} role="button" onKeyDown={onAssetItemClick} tabIndex={0}>
      <div className={classes.resultIconBackground}>
        <div className={resultIconWrapperClasses}>
          <div className={classes.resultIcon}>
            <AssetIcon
              type={assetIcon}
              fillColor={theme.palette.common.white}
              outline={false}
            />
          </div>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.headerRow}>
          <Tooltip title={asset.name}>
            <Typography variant="body1" noWrap>{asset.name}</Typography>
          </Tooltip>
        </div>
        {
          //   statesToDisplay.slice(1).map(state => (
          //   <Box key={state} className={classes.headerRow}>
          //     <Typography variant="body1" />
          //     <Typography variant="body1">{state}</Typography>
          //   </Box>
          // ))
        }
        <div className={classes.headerRow}>
          <TimeSince Component="p" time={latestPosition?.received && moment.unix(latestPosition?.received)} fallbackString={now ? fallbackStringHistoric : fallbackString} tooltip />
          <span className={classes.truncate}>{assetDetails[assetDetailSelected] || courseSpeed}</span>
        </div>
      </div>
    </div>
  );
};

export default OBResultAsset;
