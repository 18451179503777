import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    flex: 1
  },
  alertIcon: {
    marginRight: theme.spacing(1),
    '& polygon, path': {
      fill: theme.palette.primary.distressRed
    }
  },
  alertButton: {
    color: theme.palette.primary.distressRed,
    backgroundColor: theme.palette.common.whiteConstant,
    boxShadow: 'none',
    borderRadius: '40px',
    padding: '4px 12px 4px 6px',
    marginRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.common.whiteConstant,
      boxShadow: '0 0 0 0.2rem rgba(255,255,255,.25)'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(255,255,255,.5)'
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.common.whiteConstant,
    boxShadow: '0 0 0 0.2rem rgba(255,255,255,.25)'
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(255,255,255,.5)'
  },
  avatar: {
    minWidth: 46
  },
  distressIcon: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.primary.distressRed
  },
  listItemText: {
    '& span': {
      fontSize: '.9rem',
      color: theme.palette.common.text
    }
  },
  originName: {
    fontSize: '0.8rem',
    fontWeight: '600',
    marginRight: '3px'
  },
  message: {
    fontSize: '0.8rem',
    fontWeight: '500',
    textTransform: 'initial'
  }
}));
