import { queryClient } from 'react-query-client';
import { createSelector } from 'reselect';

export const getAsset = createSelector(
  [
    ({ assetId }: { assetId: number }) => assetId,
    ({ organisationId }: { organisationId: string }) => organisationId,
    ({ now }: { now: string | null }) => now,
    ({ organisationId, now }: any) => queryClient.getQueryState(['assets', organisationId, now])?.dataUpdatedAt,
  ],
  // @ts-ignore
  (assetId, organisationId, now) => queryClient.getQueryData(['assets', organisationId, now])?.assetsWithDevices?.find(a => a.id === assetId),
  { memoizeOptions: { maxSize: 2000 } }
);

export const getAssets = createSelector(
  [
    ({ organisationId }: { organisationId: string }) => organisationId,
    ({ now }: { now: string | null }) => now,
    ({ organisationId, now }: any) => queryClient.getQueryState(['assets', organisationId, now])?.dataUpdatedAt,
  ],
  // @ts-ignore
  (organisationId, now, dataUpdatedAt) => queryClient.getQueryData(['assets', organisationId, now])?.assetsWithDevices || [],
  { memoizeOptions: { maxSize: 100 } }
);

export const visibleAssets = createSelector(
  [
    state => getAssets({ organisationId: state.session?.organisationId, now: state.app?.now }),
    state => state.map.hiddenAssets,
  ],
  (assets, hiddenAssets) => {
    const hiddenAssetIds = hiddenAssets.map((a: Asset) => a.id);
    return assets.filter((a: Asset) => !hiddenAssetIds.includes(a.id) && a.device);
  },
  { memoizeOptions: { maxSize: 100 } }
);

interface selectedAssetProps {
  organisationId: string;
  now: string | undefined;
  selectedItemId: number | undefined;
}
export const getSelectedAsset = createSelector(
  [
    // @ts-ignore
    (state: any, { organisationId, now }: selectedAssetProps) => queryClient.getQueryData(['assets', organisationId, now])?.assetsWithDevices,
    (state: any, { selectedItemId }: selectedAssetProps) => selectedItemId
  ],
  (assets, selectedItemId) => assets?.find((a: Asset) => a.id === selectedItemId) || {},
  { memoizeOptions: { maxSize: 2000 } }
);

export const getAssetIds = createSelector(
  // @ts-ignore
  ({ organisationId, now }) => queryClient.getQueryData(['assets', organisationId, now])?.assets,
  (assets: any) => assets?.map((a: Asset) => a.id) || [],
  { memoizeOptions: { maxSize: 100 } }
);
