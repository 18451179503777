import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  icon: {
    color: theme.palette.common.whiteConstant
  },
  dialogTitle: {
    padding: theme.spacing(2),
    '& h2': {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    }
  },
  closeButton: {
    position: 'absolute',
    padding: theme.spacing(1),
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  dialogActions: {
    padding: theme.spacing(4)
  },
  dialogSubmitButton: {
    backgroundColor: theme.palette.button.primary,
    color: theme.palette.button.primaryColor,
    padding: theme.spacing(2),
    textTransform: 'capitalize',
    fontSize: '1rem',
    fontWeight: '500',
    width: '100%',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.button.primaryHover
    }
  },
  confirmText: {
    color: 'green',
    textAlign: 'center',
    marginBottom: '20px'
  }
}));
