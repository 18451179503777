import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Container, Divider, Typography
} from '@mui/material';
import ScrollSpyContainer from 'components/shared/scrollSpyContainer';
import Page from 'components/pages/page';
import LeftArrow from '@mui/icons-material/ChevronLeft';
import useTranslation from 'hooks/useTranslation';
import { useQuery } from 'react-query';
import { fetchUsercodes } from 'apis/trackstar/serenity';
import LoadingPage from 'components/pages/loading/loading-view';
import useStyles from './organisationSettings-styles';
import Organisation from './organisation';
import OrganisationIceContacts from './organisationIceContacts';
import OrganisationAms from './organisation/organisationAms';

const OrganisationSettings = ({
  user,
  permissions,
  organisationId,
  // displaySnackbar,
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.organisationSettings');
  const amsEnabled = false;
  const readOnly = !permissions.canEditOrganisation;

  const organisations = useQuery('organisations', fetchUsercodes);
  const organisation = organisations.isSuccess && organisations.data.find(o => o.id === organisationId);

  const scrollCategories = [
    {
      id: 'orgDetails',
      title: t('tableDetails')
    }, {
      id: 'orgUsers',
      title: t('organisationUsersTitle')
    }, {
      id: 'contactSettings',
      title: t('contactsTitle'),
      visible: false,
      hasError: false,
      hasWarning: false,
    }, {
      id: 'amsSettings',
      title: t('amsSettings.title'),
      hidden: !amsEnabled
    },
  ];

  if (organisations.isLoading || !organisation) return (<LoadingPage />);

  return (
    <Page>
      <ScrollSpyContainer categories={scrollCategories}>
        <Box className={classes.categoriesWrapper}>
          <Container maxWidth="md">
            <Link to="/settings" className={classes.link}>
              <Typography className={classes.linkText} variant="body1" gutterBottom>
                <LeftArrow />
                {t('settings')}
              </Typography>
            </Link>
            <Typography variant="h1" component="h1" gutterBottom>{t('title', { orgName: organisation.name })}</Typography>
            <Divider className={classes.divider} />
            <Organisation organisation={organisation} user={user} classes={classes} />
            <Divider className={classes.divider} />
            <OrganisationIceContacts organisationId={organisationId} readOnly={readOnly} />
            {/* TODO: check git history for JSX to render org timezone when we implement it in trackstar */}
            {/* TODO: check git history for JSX to render homebase settings when we implement it in trackstar */}
            {amsEnabled && (
              <>
                <Divider className={classes.divider} />
                <OrganisationAms organisationId={organisation.id} />
              </>
            )}
            {/* TODO: check git history for JSX to render org-level billing settings when we implement in trackstar */}
          </Container>
        </Box>
      </ScrollSpyContainer>
    </Page>
  );
};

export default OrganisationSettings;
