import React from 'react';
import {
  Box,
  Container,
  Divider,
  Typography
} from '@mui/material';
import LeftArrow from '@mui/icons-material/ChevronLeft';
import { Link } from 'react-router-dom';
import ScrollSpyContainer from 'components/shared/scrollSpyContainer';
import Page from 'components/pages/page';
import useTranslation from 'hooks/useTranslation';
import UserDetails from './user';
import useStyles from './accountSettings-styles';

const AccountSettings = ({ user }) => {
  const classes = useStyles();
  const t = useTranslation('pages.accountSettings');

  // For now have hardcoded titles for table of contents component
  const categories = [
    {
      id: 'userProfile',
      title: t('userTitle')
    },
    {
      id: 'organisationDetails',
      title: t('organisations')
    }
  ];

  return (
    <Page>
      <ScrollSpyContainer categories={categories}>
        <Box className={classes.categoriesWrapper}>
          <Container maxWidth="md">
            <Link to="/settings" className={classes.link}>
              <Typography className={classes.linkText} variant="body1" gutterBottom>
                <LeftArrow />
                {' '}
                {t('backButton')}
              </Typography>
            </Link>
            <Typography variant="h1" component="h1" gutterBottom>{t('title')}</Typography>
            <Divider className={classes.divider} />
            <UserDetails
              classes={classes}
              user={user}
            />
          </Container>
        </Box>
      </ScrollSpyContainer>
    </Page>
  );
};

export default AccountSettings;
