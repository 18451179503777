import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Badge,
  Typography
} from '@mui/material';

import MapIcon from 'components/shared/icons/mapIcon';
import MessagingIcon from 'components/shared/icons/messagingIcon';
import SharingIcon from 'components/shared/icons/sharingIcon';
import FriendsIcon from 'components/shared/icons/friendsIcon';
import SettingsIcon from 'components/shared/icons/settingsIcon';
import ManageIcon from 'components/shared/icons/manageIcon';
import StaffPageIcon from 'components/shared/icons/staffPageIcon';
import AssignmentIcon from '@mui/icons-material/Assignment';
import useStyles from './navigationButton-styles';

const iconMap = {
  Map: MapIcon,
  Messaging: MessagingIcon,
  Sharing: SharingIcon,
  Friends: FriendsIcon,
  Reporting: AssignmentIcon,
  Settings: SettingsIcon,
  Manage: ManageIcon,
  StaffPage: StaffPageIcon,
};

const NavigationButton = ({
  iconName,
  text,
  link,
  badge,
  indicator
}) => {
  const classes = useStyles();
  const Icon = iconMap[iconName];

  return (
    <NavLink to={link || '/'} exact={!link} className={classes.link} activeClassName={classes.active}>
      <Box className={classes.button}>
        <Icon sx={{ width: 43 }} />
        { badge && (
          <Badge className={classes.badge} variant="dot" overlap="circular" invisible={!indicator} />
        )}
      </Box>
      <Typography className={classes.navigationText}>{text}</Typography>
    </NavLink>
  );
};

export default NavigationButton;
