import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Divider,
  Paper,
  IconButton,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import useTranslation from 'hooks/useTranslation';
import OrganisationAccess from './organisationAccess';
import OrganisationMembers from './members';
import UserInvitation from './userInvitation/index';
import OrganisationContactModal from './organisationContactModal';
import OrganisationContact from './organisationContact';
import OrganisationDetails from './organisationDetails';

const OrganisationSettings = ({
  user,
  organisation,
  permissions,
  classes,
}) => {
  const t = useTranslation('pages.organisationSettings');

  const [editingPrimaryContact, setEditingPrimaryContact] = useState(false);
  const [editingBillingContact, setEditingBillingContact] = useState(false);

  return (
    <>
      <Box id="orgDetails">
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" gutterBottom>{t('tableDetails')}</Typography>
            <Typography variant="body1">{t('tableDetailsSubtext')}</Typography>
          </Grid>
          { /* # Organisation Name */ }
          <Grid item sm={12} md={8}>
            <OrganisationDetails organisation={organisation} />
          </Grid>
          { /* # Primary Contact */ }
          <Grid item xs={12} sm={8}>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ flexGrow: 1 }}>
                <Paper className={classes.formPanel}>
                  <Grid container spacing={3}>
                    <Grid item xs={10}>
                      <Typography variant="h5">{t('primaryOrgContactTitle')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {permissions.canEditOrganisation && (
                        <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                          <IconButton size="small" onClick={() => setEditingPrimaryContact(true)} aria-label="edit">
                            <EditIcon />
                          </IconButton>
                          { editingPrimaryContact ? (
                            <OrganisationContactModal
                              departmentName={t('primaryOrgContactTitle')}
                              organisation={organisation}
                              mode="primary"
                              onClose={() => setEditingPrimaryContact(false)}
                            />
                          ) : undefined }
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <OrganisationContact organisation={organisation} mode="primary" />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              { /* # Billing Contact */ }
              <Grid item xs={6} style={{ flexGrow: 1 }}>
                <Paper className={classes.formPanel}>
                  <Grid container spacing={3}>
                    <Grid item xs={10}>
                      <Typography variant="h5">{t('billingOrgContactTitle')}</Typography>
                    </Grid>
                    {/* TODO: check git history for JSX to edit primary contact once we can trackstar */}
                    <Grid item xs={2}>
                      {permissions.canEditOrganisation && organisation.billingId && (
                        <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                          <IconButton size="small" onClick={() => setEditingBillingContact(true)} aria-label="edit">
                            <EditIcon />
                          </IconButton>
                          { editingBillingContact ? (
                            <OrganisationContactModal
                              departmentName={t('billingOrgContactTitle')}
                              organisation={organisation}
                              mode="billing"
                              onClose={() => setEditingBillingContact(false)}
                            />
                          ) : undefined }
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {organisation.billingId ? <OrganisationContact organisation={organisation} mode="billing" /> : <Typography>{t('billingOrgContactNone')}</Typography>}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider className={classes.divider} />
      { /* # Organisation Users */ }
      <Box id="orgUsers">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" gutterBottom>
              {`${organisation.name} ${t('users')}`}
            </Typography>
            <Typography variant="body1">{t('userListDescription')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <OrganisationMembers organisation={organisation} user={user} permissions={permissions} />
          </Grid>
        </Grid>
      </Box>
      { /* # Invite User */ }
      {permissions.canEditOrganisation && (
        <Box id="inviteUser">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h5" gutterBottom>{t('newUserTitle')}</Typography>
              <Typography variant="body1">{t('newUserDescription')}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <UserInvitation classes={classes} organisation={organisation} orgId={organisation.id} />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default OrganisationSettings;
