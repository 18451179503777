import React, { useState, useEffect, useRef } from 'react';
import moment from 'utils/moment';
import { useDateTime } from 'hooks/settings';

const TIME_FORMAT = 'HH:mm:ss z';

const Clocks = ({
  clocks
}) => {
  const canvasRef = useRef(null);

  // Create a clock and "tick" it every 1000ms
  const [time, updateTime] = useState(moment());
  useEffect(() => {
    const interval = setInterval(() => {
      updateTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const { width, height } = canvas.getBoundingClientRect();

    const { devicePixelRatio: ratio = 1 } = window;

    if (ratio !== 1) {
      const ctx = canvas.getContext('2d');
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      ctx.scale(ratio, ratio);
    }
  }, []);

  const timeStringLocal = useDateTime(time, TIME_FORMAT);
  const timeStringClock = useDateTime(time, TIME_FORMAT, clocks[0]);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.font = '0.9em objektiv-mk2,sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
    ctx.fillStyle = '#A0A7B4';
    ctx.fillText(`${timeStringLocal}  ${timeStringClock}`, 4, 14);
  }, [timeStringLocal, timeStringClock]);

  return <canvas width={200} height={20} ref={canvasRef} style={{ width: '200px', height: '20px' }} />;
};

export default Clocks;
