import React from 'react';
import {
  Container, Tabs, Tab, Grid, SvgIcon, Box, Typography
} from '@mui/material';
import TabPanel from 'components/shared/tabPanel';
import AssetsList from 'components/pages/manageAssetsAndDevices/assetsList';
import DevicesList from 'components/pages/manageAssetsAndDevices/devicesList';
import useTranslation from 'hooks/useTranslation';
import Page from '../page';
import useStyles from './manageAssetsAndDevices-styles';

const AssetsAndDevicesPage = ({ location }) => {
  const classes = useStyles();
  const t = useTranslation('pages.assetsAndDevices');
  const { hash } = location;
  const [currentTab, setCurrentTab] = React.useState((hash && hash.indexOf('device') > -1) ? 1 : 0);

  const handleChange = (event, tab) => {
    setCurrentTab(tab);
  };

  const assetsIcon = (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" className={classes.icon}>
      <path d="M15.6,11.2V9.6l-6.4-4V1.2C9.2,0.54,8.66,0,8,0S6.8,0.54,6.8,1.2v4.4l-6.4,4v1.6l6.4-2v4.4l-1.6,1.2V16L8,15.2l2.8,0.8v-1.2l-1.6-1.2V9.2L15.6,11.2z" />
    </SvgIcon>
  );
  const devicesIcon = (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" className={classes.icon}>
      <path d="M14.17,8.05H1.83C0.82,8.05,0,8.87,0,9.88l0,0c0,1.01,0.82,1.83,1.83,1.83h12.34c1.01,0,1.83-0.82,1.83-1.83l0,0
              C16,8.87,15.18,8.05,14.17,8.05z M2.58,10.86c-0.55,0-0.99-0.44-0.99-0.99s0.44-0.98,0.99-0.98s0.99,0.44,0.99,0.98
              S3.12,10.86,2.58,10.86z M5.02,10.86c-0.55,0-0.99-0.44-0.99-0.99s0.44-0.98,0.99-0.98s0.99,0.44,0.99,0.98S5.57,10.86,5.02,10.86z
              M10.98,10.86c-0.55,0-0.98-0.44-0.98-0.99s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98C11.97,10.42,11.52,10.86,10.98,10.86z
              M13.42,10.86c-0.55,0-0.98-0.44-0.98-0.99s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98S13.97,10.86,13.42,10.86z"
      />
      <path d="M1.83,7.78h12.34c0.3,0,0.57,0.07,0.82,0.17L11.82,4.8C11.49,4.47,11.06,4.3,10.6,4.3H5.41c-0.46,0-0.9,0.18-1.22,0.51
              L1,7.96C1.25,7.84,1.53,7.78,1.83,7.78z"
      />
    </SvgIcon>
  );

  return (
    <Page>
      <Grid container className={classes.pageWrapper}>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <Box>
              <Typography variant="h1" gutterBottom>{t('title')}</Typography>
              <Typography paragraph>{t('description')}</Typography>
            </Box>
            <Tabs
              className={classes.Tabs}
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              onChange={handleChange}
              aria-label="manage devices and assets tabs"
            >
              <Tab className={classes.assetsTab} icon={assetsIcon} label={t('assetsTabTitle')} />
              <Tab className={classes.devicesTab} icon={devicesIcon} label={t('devicesTabTitle')} />
            </Tabs>
            <TabPanel currentTab={currentTab} index={0}><AssetsList /></TabPanel>
            <TabPanel currentTab={currentTab} index={1}><DevicesList /></TabPanel>
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default AssetsAndDevicesPage;
