import { all } from 'redux-saga/effects';

import watchSession from './session';
import watchCurrentUser from './currentUser';

export default function* rootSaga() {
  yield all([
    watchSession(),
    watchCurrentUser()
  ]);
}
