import Smart from 'hocs/smart';
import { eventFilterSelector } from 'selectors/events';
import { activityGroupsForAsset } from 'selectors/legs';
import View from './activity-view';

export default Smart(
  View,
  (state, ownProps) => ({
    reportFilter: eventFilterSelector(state),
    now: state.app.now,
  }),
  ac => ({
    updateFilter: ac.reports.filterAssetReports,
    setTrailHighlight: ac.map.setTrailHighlight,
    setAssetLegs: ac.legs.setAssetLegs
  })
);
