import Smart from 'hocs/smart';
import { getAssets } from 'selectors/assets';
import TripReports from './tripReports-view';

export default Smart(
  TripReports,
  (state: any) => ({
    now: state.app.now,
    timezone: state.settings.locale.timezone,
    assets: getAssets({ organisationId: state.session?.organisationId, now: state.app?.now }),
    existingLegs: state.legs,
    tableSettings: state.settings.tripReportsTable,
    serialType: state.settings.ui.serialType,
    rowsPerPage: state.settings.ui.rowsPerPage,
  }),
  (ac: any) => ({
    setAssetLegs: ac.legs.setAssetLegs,
    displaySnackbar: ac.app.displaySnackbar,
    updateSetting: ac.settings.updateSetting,
  })
);
