import { createSelector } from 'reselect';
import { getAssets } from './assets';
import { getReportsForDevice } from './reports';

export const eventFilterSelector = createSelector(
  [state => state.app.selectedItem, state => state.reports.filters],
  (selectedItem, eventFilters) => eventFilters[selectedItem?.type]?.[selectedItem?.id] || eventFilters[selectedItem?.type]?.default || eventFilters.default,
);

export const visibleEventsSelector = createSelector(
  [
    state => state.app.selectedItem,
    eventFilterSelector,
    state => state.session?.organisationId,
    state => state.app?.now,
    state => state.positions.mostRecentDeviceReport, // bust cache when new device reports arrive
  ],
  (selectedItem, filter, organisationId, now) => {
    if (!selectedItem || !selectedItem?.id) return [];
    switch (selectedItem.type) {
      case 'asset': {
        const deviceId = getAssets({ organisationId, now }).find(a => a.id === selectedItem?.id)?.deviceId;
        const reports = deviceId ? getReportsForDevice({ deviceId, now }) : [];
        return reports?.filter(report => report.event && !filter.blacklist.includes(report.event.label)) || [];
      }
      default:
        return [];
    }
  }
);

export const visibleEventsSelectorByAsset = createSelector(
  [
    (state, { map }) => map,
    (state, { map }) => state.map.selectedAssets[map]?.id,
    state => state.session?.organisationId,
    state => state.app?.now,
    state => state.positions.mostRecentDeviceReport, // bust cache when new device reports arrive
  ],
  (map, selectedAssetId, organisationId, now) => {
    const deviceId = selectedAssetId && getAssets({ organisationId, now }).find(a => a.id === selectedAssetId)?.deviceId;
    const reports = deviceId ? getReportsForDevice({ deviceId, now }) : [];
    if (!selectedAssetId) return undefined;
    return reports?.filter(r => r.event) ?? [];
  },
  { memoizeOptions: { maxSize: 2000 } }
);
