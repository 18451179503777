const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_DATA':
    case 'RESET_EVERYTHING': return initialState;

    case 'FETCH_INCIDENT_SUCCESS': {
      const {
        assetId,
        incident
      } = action.payload;

      return {
        ...state,
        [assetId]: incident
      };
    }

    case 'FETCH_INCIDENT_FAILURE': {
      return { ...state, error: action.payload };
    }

    default:
      return state;
  }
};
