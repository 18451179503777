import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import mixpanel from 'mixpanel-browser';

export default ({
  component: Component,
  loggedIn,
  navigate,
  isStaffOnly,
  user,
  userSettings,
  mapSettings,
  ...rest
}) => {
  const doRedirect = location => (<Redirect to={{ pathname: '/login', state: { from: location } }} />);
  const renderComponent = props => (<Component {...props} />);
  const { update, trackEvent } = useIntercom();

  if (!loggedIn || (loggedIn && isStaffOnly && !user.isStaff)) {
    return (
      <Route
        {...rest}
        render={
          props => doRedirect(props.location)
        }
      />
    );
  }

  // remove settings that are initialized as empty objects (which will cause mixpanel to crash)
  const { layers, ...cleanMapSettings } = mapSettings;
  const { locale, map, ...cleanUserSettings } = userSettings;
  mixpanel.identify(user.id);
  mixpanel.people.set({
    $name: user.name,
    $email: user.email,
    isStaff: user.isStaff,
    memberOf: user.memberOf.map(org => org.name).join(', '),
    userSettings: cleanUserSettings,
    localeSettings: { timezone: userSettings?.locale?.timezone, language: userSettings?.locale?.language },
    mapSettings: cleanMapSettings,
  });

  update({
    name: user.name,
    email: user.email,
    user_id: user.id,
    customAttributes: {
      isStaff: user.isStaff,
      memberOf: user.memberOf.map(org => org.name).join(', '),
    }
  });

  trackEvent('navigate', {
    path: rest.path,
    time_at: +new Date(),
  });

  return (
    <Route
      {...rest}
      render={
        props => renderComponent(props)
      }
    />
  );
};
