import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    padding: theme.spacing(1, 0)
  },
  gridItem: {
    maxWidth: '20%',
    flexBasis: '20%'
  },
  buttonWrapper: {
    display: 'flex',
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    borderWidth: 4,
    borderStyle: 'solid',
    transition: 'all 0.25s',
    '& img': {
      width: '100%'
    },
    '&:hover': {
      borderWidth: 4,
      borderColor: theme.palette.common.blueConstant
    }
  },
  selected: {
    borderColor: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main
    }
  }
}));
