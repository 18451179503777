import React from 'react';
import { Swatch } from 'react-color/lib/components/common';
import useStyles, { styles } from './previousSelectedColors-styles';

const CustomColorPickerSelectedColors = ({
  selectedColors,
  onClick = () => {}
}) => {
  const classes = useStyles();

  const handleClick = (hex, e) => {
    onClick({
      hex,
      source: 'hex'
    }, e);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Previous Colours</div>
      <div className={classes.colors}>
        { selectedColors?.map((colorObjOrString, index) => {
          const c = typeof colorObjOrString === 'string'
            ? { color: colorObjOrString }
            : colorObjOrString;
          const key = `${index}${c.color}${c.title || ''}`;
          return (
            <div key={key} className={classes.swatchWrap}>
              <Swatch
                {...c}
                style={styles.swatch}
                onClick={handleClick}
                focusStyle={{
                  boxShadow: `inset 0 0 0 1px rgba(0,0,0,.15), 0 0 4px ${c.color}`
                }}
              />
            </div>
          );
        }) }
      </div>
    </div>
  );
};

export default CustomColorPickerSelectedColors;
