import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Typography
} from '@mui/material';
import RightArrow from '@mui/icons-material/ChevronRight';
import useStyles from './breadCrumbs-styles';

const toCapCase = s => `${s?.substr(0, 1)?.toUpperCase() || ''}${s?.substr(1) || ''}`;

const BreadCrumbs = ({
  crumbs
}) => {
  const classes = useStyles();

  if (!crumbs || crumbs.length === 0) return null;

  return (
    <Box className={classes.breadCrumbWrapper}>
      <Box className={classes.breadCrumb}>
        <Typography>
          {crumbs.map((crumb, index) => {
            const lastStep = index === crumbs.length - 1;

            return (
              <React.Fragment key={crumb.id}>
                <Box
                  component="span"
                  className={clsx({ [classes.breadCrumbsActive]: lastStep })}
                >
                  {toCapCase(crumb.label)}
                </Box>
                {!lastStep && (
                  <RightArrow className={classes.rightArrow} />
                )}
              </React.Fragment>
            );
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default BreadCrumbs;
