import React from 'react';

const Heartbeat = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m28.144 5.4423c-3.2111-3.2111-8.4304-3.2111-11.632 0-0.18432 0.18432-0.34925 0.36865-0.51417 0.56267-0.16492-0.19403-0.32984-0.38805-0.51417-0.56267-3.2111-3.2111-8.4304-3.2111-11.632 0-3.2014 3.2111-3.2111 8.4304 0 11.632l11.7 11.7c0.12612 0.12612 0.28134 0.18432 0.44626 0.18432 0.16492 0 0.32014-0.05821 0.44626-0.18432l11.7-11.7c3.2111-3.2014 3.2111-8.4207 0-11.632z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Heartbeat;
