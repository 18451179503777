import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  pageWrapper: {
    backgroundColor: theme.palette.common.lightGrey,
    padding: theme.spacing(8, 0),
    flex: 1,
    overflowY: 'auto',
    textAlign: 'left'
  },
  panel: {
    padding: theme.spacing(3, 4),
    marginTop: theme.spacing(2),
    boxShadow: 'none',
    border: theme.border.default
  },
  settingsButton: {
    position: 'relative',
    borderRadius: '4px',
    fontSize: '.8rem',
    fontWeight: '400',
    padding: theme.spacing(2, 3),
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    transition: 'all 0.15s',
    '&:focus': {
      backgroundColor: theme.palette.common.lightGrey,
      border: theme.border.default
    },
    '&:hover': {
      backgroundColor: theme.palette.common.lightGrey
    },
    '& div:first-child': {
      backgroundColor: theme.palette.common.white
    }
  },
  link: {
    textDecoration: 'none'
  },
  details: {
    marginLeft: theme.spacing(2),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'left',
    '& p:first-child': {
      color: theme.palette.primary.main,
      fontSize: '1rem',
      fontWeight: '500',
      textDecoration: 'none'
    },
    '& p:last-child': {
      fontSize: '.9rem',
      fontWeight: '400',
      textDecoration: 'none'
    }
  },
  iconWrapper: {
    padding: theme.spacing(2),
    borderRadius: '30px',
    backgroundColor: theme.palette.common.lightGrey,
    transition: 'all 0.15s',
    color: theme.palette.common.text
  },
  icon: {
    display: 'block',
    fontSize: '1.4rem',
    lineHeight: '1',
    fill: theme.palette.common.text,
    transition: 'all 0.15s'
  }
}));
