import React from 'react';
import {
  Box, Typography, Grid, TableContainer, Table, TableBody, TableRow, TableCell, IconButton
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { useQuery } from 'react-query';
import { fetchAssetsDevices } from 'apis/trackstar/serenity';
import { Close } from '@mui/icons-material';
import Module from '../../modules/module';
import useStyles from './details-styles';
import LoadingComponent from './details-loading';

const DeviceContextBoxDetails = ({
  clearSelection,
  deviceId,
}) => {
  const classes = useStyles();
  const t = useTranslation('contextbox.device.details');
  const getAssetList = useQuery(
    ['assets', localStorage.getItem('organisationId'), null],
    () => fetchAssetsDevices(),
    { select: data => data.assetsWithDevices }
  );

  if (!getAssetList.isSuccess) return LoadingComponent;
  const { name: assignedToAsset, device } = getAssetList.data.find(a => a.device?.id === deviceId) || {};

  return (
    <Module noHeader contentClassname={classes.container}>
      <Grid container justifyContent="flex-start">
        <Grid item xs={12} className={classes.topRow}>
          <Box className={classes.deviceDetails}>
            <IconButton
              className={classes.closeButton}
              onClick={() => clearSelection()}
              size="large"
            >
              <Close />
            </IconButton>
            <Typography variant="h3">{`${device.make} ${device.model}`}</Typography>
            <Typography variant="body1">{device.assetId ? `${t('assignedTo')} ${assignedToAsset}` : t('deviceUnassigned')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.bottomRow}>
          <Typography className={classes.label}>{t('serialNumbers')}</Typography>
          <TableContainer>
            <Table size="small" className={classes.detailsTable}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.serialLabel}>{t('manufacturerSerial')}</TableCell>
                  <TableCell className={classes.serialValue}>{device.manufacturerSerial ?? '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.serialLabel}>{t('tpSerial')}</TableCell>
                  <TableCell className={classes.serialValue}>{device.tpSerial ?? '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.serialLabel}>{t('imei')}</TableCell>
                  <TableCell className={classes.serialValue}>{device.imei ?? '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Module>
  );
};

export default DeviceContextBoxDetails;
