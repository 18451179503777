import makeStyles from '@mui/styles/makeStyles';

let omniboxWidth = 280;

const width = window.innerWidth;
if (width > 1440) {
  omniboxWidth = 300;
}

export default makeStyles(theme => ({
  omniboxWrapper: {
    position: 'relative',
    maxWidth: omniboxWidth,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    zIndex: 3,
    transition: 'all 0.05s'
  },
  omnibox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.background.omniboxBackground,
    boxShadow: 'rgba(0, 0, 0, 0.1) 12px 0px 12px -12px',
    zIndex: 3,
    '& > *': {
      padding: 0
    }
  },
  overflowWrapper: {
    overflow: 'hidden'
  },
  collapsed: {
    maxWidth: '0px'
  },
  collapseButton: {
    position: 'absolute',
    display: 'flex',
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 6,
    paddingLeft: 4,
    top: 16,
    right: -26,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,.4)',
    zIndex: 1,
    transition: 'all 0.05s',
    transform: 'translateX(-16px)',
    '&:hover': {
      transform: 'translateX(0px)',
    }
  },
  icon: {
    transition: 'all 0.05s'
  },
  iconReversed: {
    transform: 'rotate(180deg)'
  },
  queryContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.omniboxBackground,
    color: theme.palette.common.white,
    overflow: 'hidden'
  },
  listOptionsButton: {
    width: 30,
    height: 30,
    minHeight: 30,
    backgroundColor: theme.palette.omnibox.overlay,
    '&.active': {
      backgroundColor: theme.palette.omnibox.highlight,
      boxShadow: 'none',
      '& polygon, & rect': {
        fill: theme.palette.common.white
      }
    }
  },
  optionsIcon: {
    fontSize: '0.85rem',
    '& path, & polygon, & rect': {
      fill: theme.palette.common.whiteConstant
    }
  }
}));
