import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box
} from '@mui/material';
import clsx from 'clsx';
import useTranslation from 'hooks/useTranslation';
import useStyles from './organisationAccess-styles';
// import { updateOrganisationAccess as mutateOrgAccess } from '../../organisationSettings-data';

const OrganisationAccess = ({
  organisation,
  permissions,
  // modifyOrganisation // used to be in organisations reducer, before it was killed
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.organisationSettings');

  // TODO: replace with trackstar call instead of GQL useMutation
  // const [updateOrgAccess] = useMutation(mutateOrgAccess);
  const [accessLevel, setAccessLevel] = useState(organisation.access);
  const accessLevels = {
    PUBLIC: { name: t('access.accessLevels.public'), description: t('access.publicDescription') },
    PRIVATE: { name: t('access.accessLevels.private'), description: t('access.privateDescription') },
    HIDDEN: { name: t('access.accessLevels.hidden'), description: t('access.hiddenDescription') }
  };
  const isAuthorisedUser = permissions.canChangeOrganisationAccess;

  useEffect(() => {
    setAccessLevel(organisation.access);
  }, [organisation.access, organisation.id]);

  return (
    <>
      <Paper className={classes.panel}>
        <Grid item sm={12} md={12}>
          <Typography variant="h5" className={classes.detailTitle} gutterBottom>{t('access.details')}</Typography>
        </Grid>
        <RadioGroup
          className={classes.radioGroup}
          aria-label={t('access.label')}
          value={accessLevel}
          onChange={(id, value) => {
            console.warn('Organisation access disabled for development');
            return null;
            // updateOrgAccess({
            //   variables: {
            //     access: value.toUpperCase()
            //   }
            // });
            // modifyOrganisation(organisation.id, { access: value });
            // setAccessLevel(value);
          }}
        >
          {Object.keys(accessLevels).map(level => (
            <FormControlLabel
              key={level}
              value={level}
              control={<Radio disabled={!isAuthorisedUser} />}
              label={(
                <Box>
                  <Typography variant="h6">{accessLevels[level].name}</Typography>
                  <Typography>{accessLevels[level].description}</Typography>
                </Box>
              )}
              className={clsx(accessLevel === level && classes.selected)}
            />
          ))}
        </RadioGroup>
      </Paper>
    </>
  );
};

export default OrganisationAccess;
