import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useToggle from 'hooks/useToggle';
import { useAssetReports, useLatestPosition } from 'repositories/reports/hooks';
import processLegs from 'helpers/legs';
import Leg from './leg';
import Filter from './filter';
import LegsTotals from './legsTotals';
import JumpToLatestActionButton from './actionButtons/jumpToLatest';
import FilterActionButton from './actionButtons/filter';
import useStyles from './activity-styles';

const AssetContextBoxActivity = ({
  selectedAsset,
  reports,
  reportFilter,
  updateFilter,
  now,
  setTrailHighlight,
  setAssetLegs,
}) => {
  const classes = useStyles();
  const t = useTranslation('contextbox.asset.activity');
  const [filterEnabled, toggleFilter] = useToggle(false);
  const [activityGroups, setActivityGroups] = useState([]);

  const latestPosition = useLatestPosition(selectedAsset?.id);
  const reportsForAsset = useAssetReports(selectedAsset?.id);
  // const activityGroups = orderedLegs.map((l, id) => ({
  //   key: `leg${id}${selectedAsset?.id}`,
  //   reports: reportsForAsset.filter(r => r.received > l.start && r.received < l.end),
  //   leg: l
  // }));

  // generate legs on the fly (reduce overhead for loading on large organisations)
  useEffect(() => {
    if (!selectedAsset || !reportsForAsset.length) { setActivityGroups([]); return; }

    processLegs(reportsForAsset, [])
      .then(legs => new Promise((resolve, reject) => {
        if (legs[0].deviceId !== selectedAsset.deviceId) {
          reject(new Error('asset changed while calculating legs'));
        }
        setAssetLegs(selectedAsset.id, legs);
        resolve(legs);
      }))
      .then(legs => legs
        .sort((l1, l2) => l1.start - l2.start)
        .map(l => ({
          key: `leg-${l.id}-${selectedAsset?.id}`,
          reports: reportsForAsset.filter(r => r.received >= l.start && r.received <= l.end),
          leg: l
        })))
      .then(setActivityGroups)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {}); // ignore if asset changed.
  }, [selectedAsset, reportsForAsset, setAssetLegs]);

  return (
    <Box>
      <Box className={classes.moduleHeader}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.moduleHeaderText}>{t('title')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.actionIconWrapper}>
            <JumpToLatestActionButton latestPosition={latestPosition} />
            <FilterActionButton isActive={filterEnabled} onClick={toggleFilter} />
          </Grid>
        </Grid>
      </Box>
      <Filter
        toggleFilter={toggleFilter}
        filtersToggle={filterEnabled}
        reports={reports}
        updateFilter={nextFilter => updateFilter(selectedAsset?.id, nextFilter)}
        filter={reportFilter}
      />
      <Box className={classes.moduleContent}>
        {activityGroups?.length > 0 && (
          <LegsTotals activityGroups={activityGroups} assetId={selectedAsset?.id} />
        )}
        {activityGroups?.length > 0 && activityGroups.filter(g => g.leg || g.reports.length > 3).slice(0).reverse().map(g => (
          <Leg
            key={g.key}
            reports={g.reports}
            leg={g.leg}
            reportFilter={reportFilter}
            now={now}
            setTrailHighlight={setTrailHighlight}
          />
        ))}
      </Box>
    </Box>
  );
};

export default AssetContextBoxActivity;
