import Smart from 'hocs/smart';
import { closestReportForMap } from 'selectors/closestReport';
import ReportDetailsOverlay from './reportDetails-view';

export default Smart(
  ReportDetailsOverlay,
  state => ({
    currentTimezone: state.settings.locale.timezone
  }),
  () => ({})
);
