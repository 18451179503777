import React from 'react';
import {
  Box,
  Grid,
  Divider,
  Typography,
  IconButton
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

import DateTime from 'components/shared/datetime';
import TimeSpan from 'components/shared/timespan';

import useTranslation from 'hooks/useTranslation';
import clsx from 'clsx';
import useStyles from './leg-styles';
import LegInner from './legInner-view';
import moment from "moment";

const AssetContextBoxActivityLeg = ({
  leg,
  reports,
  reportFilter,
  now,
  setTrailHighlight,
  toggleAnalysisbox,
  selectedLeg,
  analysisBoxOpen,
  selectLeg,
}) => {
  const classes = useStyles();
  const t = useTranslation('contextbox.asset.latestActivity');
  const expanded = selectedLeg?.id === leg?.id;
  const endDateTime = moment.unix(leg?.end);
  const displayEndDateTime = leg?.complete ? endDateTime : null;

  const doSetTrailHighlight = () => {
    if (reports.length < 1) {
      setTrailHighlight(null);
      return;
    }
    const { assetId } = reports[0];
    setTrailHighlight({
      assetId,
      legStart: reports[0],
      legEnd: reports[reports.length - 1],
      legStartId: reports[0].id,
      legEndId: reports[reports.length - 1].id
    });
  };

  const handleToggleAnalysisBox = legId => {
    if (!analysisBoxOpen) toggleAnalysisbox();
    selectLeg(legId);
  };

  return (
    <Box className={classes.legWrapper} onMouseEnter={doSetTrailHighlight} onMouseLeave={() => setTrailHighlight(null)}>
      { leg && (
        <>
          <Grid container spacing={0}>
            <Grid item xs={10} sm={10} className={classes.locationsData}>
              <Typography>
                {`${leg?.from || ''} - ${leg?.to || ''}`}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} className={classes.openAnalysisButton}>
              { leg?.id && (
              <IconButton size="small" onClick={() => handleToggleAnalysisBox(leg?.id)}>
                {expanded ? (
                  <Remove className={clsx(classes.icon, { [classes.activeIcon]: selectedLeg && (selectedLeg.id === leg?.id) })} />
                ) : (
                  <Add className={clsx(classes.icon, { [classes.activeIcon]: selectedLeg && (selectedLeg.id === leg?.id) })} />
                )}
              </IconButton>
              )}
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} className={classes.borderRight}>
              <Box className={classes.legDataWrapper}>
                <Typography className={classes.label}>{t('departed')}</Typography>
                <Typography className={classes.data}>
                  <DateTime value={moment.unix(leg?.start)} timeOnly tooltip />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.borderRight}>
              <Box className={classes.legDataWrapper}>
                <Typography className={classes.label}>{t('arrived')}</Typography>
                <Typography className={classes.data}>
                  <DateTime value={displayEndDateTime} timeOnly tooltip />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className={classes.legDataWrapper}>
                <Typography className={classes.label}>{t('elapsed')}</Typography>
                <Typography className={classes.data}>
                  <TimeSpan startDateTime={moment.unix(leg?.start)} endDateTime={endDateTime} />
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </>
      )}
      <Box className={clsx(classes.legReportsWrapper, { [classes.legCollapsed]: !expanded })}>
        <LegInner reports={reports} reportFilter={reportFilter} />
      </Box>
    </Box>
  );
};

export default AssetContextBoxActivityLeg;
