import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(4),
    cursor: 'pointer',
    fontSize: '1rem',
    '& path': {
      fill: theme.palette.common.text
    }
  },
  addIcon: {
    fontSize: '1.8rem',
    position: 'relative',
    top: '-5px',
    marginRight: 17,
  },
  addIconButton: {
    margin: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& .MuiIconButton-label': {
      margin: 0,
    }
  },
  targetIcon: {
    fontSize: '1.2rem',
    position: 'relative',
    top: '-1px'
  },
  activeIcon: {
    '& path': {
      fill: theme.palette.primary.main
    }
  }
}));
