import React from 'react';
import AS350 from './as350';
import AT802 from './at802';
import AT802F from './at802f';
import B200 from './b200';
import B206 from './b206';
import B747 from './b747';
import Bronco from './bronco';
import C130 from './c130';
import Cessna206 from './cessna206';
import Cessna208 from './cessna208';
import Cessna208A from './cessna208a';
import GA8 from './ga8';
import Kodiak from './kodiak';
import R44 from './r44';
import PA750 from './pa750';
import T6 from './t6';
import S2Tracker from './s2tracker';
import Aircraft from './aircraft';
import Balloon from './balloon';
import Car from './car';
import Container from './container';
import Firetruck from './firetruck';
import Heartbeat from './heartbeat';
import Helicopter from './helicopter';
import Person from './person';
import Powerboat from './powerboat';
import Other from './other';
import Yacht from './yacht';
import Tug from './tug';
import Inflatable from './inflatable';
import Containership from './containership';
import EC145 from './ec145';
import B212 from './b212';
import B412 from './b412';
import AW139 from './aw139';
import B407 from './b407';
import MD369 from './md369';
import MD500 from './md500';
import MD520N from './md520n';
import MD600N from './md600n';
import R22 from './r22';
import S64 from './s64';
import S70 from './s70';
import CH47 from './ch47';
import CL415 from './cl415';
import Truck from './truck';
import Ambulance from './ambulance';
import Airship from './airship';
import B727 from './727';
import B737 from './b737';
import DC10 from './dc10';
import Learjet from './learjet';
import CessnaCitation from './cessnacitation';
import Dash8 from './dash8';
import Dash8300 from './dash8300';
import B146 from './b146';
import A320 from './a320';
import PA31 from './pa31';
import DHC6 from './dhc6';
import S61 from './s61';
import B429 from './b429';
import EC130 from './ec130';
import MI17 from './mi17';
import AW109 from './aw109';
import KMAX from './kmax';
import AS332 from './as332';
import KA32 from './ka32';
import Cessna337 from './cessna337';
import Convair580 from './convair580';
import DC3 from './dc3';
import Sirius from './sirius';

const AssetIcon = ({
  type, outline = true, fillColor = '#000'
}) => {
  const outlineColor = '#fff';
  const iconProps = {
    outlineColor,
    fillColor,
    outlineOpacity: outline ? 1 : 0
  };
  switch (type) {
    // Custom models first
    case 'a320':
      return <A320 {...iconProps} />;
    case 'as350':
      return <AS350 {...iconProps} />;
    case 'at802':
      return <AT802 {...iconProps} />;
    case 'at802f':
      return <AT802F {...iconProps} />;
    case 'aw139':
      return <AW139 {...iconProps} />;
    case 'b146':
      return <B146 {...iconProps} />;
    case 'b200':
      return <B200 {...iconProps} />;
    case 'b206':
      return <B206 {...iconProps} />;
    case 'b212':
      return <B212 {...iconProps} />;
    case 'b407':
      return <B407 {...iconProps} />;
    case 'b412':
      return <B412 {...iconProps} />;
    case 'b727':
      return <B727 {...iconProps} />;
    case 'b737':
      return <B737 {...iconProps} />;
    case 'b747':
      return <B747 {...iconProps} />;
    case 'bronco':
      return <Bronco {...iconProps} />;
    case 'c130':
      return <C130 {...iconProps} />;
    case 'cessna206':
      return <Cessna206 {...iconProps} />;
    case 'cessna208':
      return <Cessna208 {...iconProps} />;
    case 'cessna208a':
      return <Cessna208A {...iconProps} />;
    case 'cessnacitation':
      return <CessnaCitation {...iconProps} />;
    case 'ch47':
      return <CH47 {...iconProps} />;
    case 'cl415':
      return <CL415 {...iconProps} />;
    case 'dash8':
      return <Dash8 {...iconProps} />;
    case 'dash8300':
      return <Dash8300 {...iconProps} />;
    case 'dc10':
      return <DC10 {...iconProps} />;
    case 'dhc6':
      return <DHC6 {...iconProps} />;
    case 'ec145':
      return <EC145 {...iconProps} />;
    case 'ga8':
      return <GA8 {...iconProps} />;
    case 'kodiak':
      return <Kodiak {...iconProps} />;
    case 'learjet':
      return <Learjet {...iconProps} />;
    case 'md369':
      return <MD369 {...iconProps} />;
    case 'md500':
      return <MD500 {...iconProps} />;
    case 'md520n':
      return <MD520N {...iconProps} />;
    case 'md600n':
      return <MD600N {...iconProps} />;
    case 'pa31':
      return <PA31 {...iconProps} />;
    case 'pa750':
      return <PA750 {...iconProps} />;
    case 't6':
      return <T6 {...iconProps} />;
    case 's2tracker':
      return <S2Tracker {...iconProps} />;
    case 's64':
      return <S64 {...iconProps} />;
    case 's70':
      return <S70 {...iconProps} />;
    case 'r22':
      return <R22 {...iconProps} />;
    case 'r44':
      return <R44 {...iconProps} />;
    case 's61':
      return <S61 {...iconProps} />;
    case 'b429':
      return <B429 {...iconProps} />;
    case 'ec130':
      return <EC130 {...iconProps} />;
    case 'mi17':
      return <MI17 {...iconProps} />;
    case 'aw109':
      return <AW109 {...iconProps} />;
    case 'kmax':
      return <KMAX {...iconProps} />;
    case 'as332':
      return <AS332 {...iconProps} />;
    case 'ka32':
      return <KA32 {...iconProps} />;
    case 'cessna337':
      return <Cessna337 {...iconProps} />;
    case 'convair580':
      return <Convair580 {...iconProps} />;
    case 'dc3':
      return <DC3 {...iconProps} />;
    case 'sirius':
      return <Sirius {...iconProps} />;
    case 'aircraft':
      return <Aircraft {...iconProps} />;
    case 'airship':
      return <Airship {...iconProps} />;
    case 'balloon':
      return <Balloon {...iconProps} />;
    case 'truck':
      return <Truck {...iconProps} />;
    case 'ambulance':
      return <Ambulance {...iconProps} />;
    case 'car':
      return <Car {...iconProps} />;
      // Temp turn a bunch of things into cars to match existing behavior
    case 'campervan':
      return <Car {...iconProps} />;
    case 'policecar':
      return <Car {...iconProps} />;
    case 'minibus':
      return <Car {...iconProps} />;
    case 'mobilehome':
      return <Car {...iconProps} />;
    case 'tractor':
      return <Car {...iconProps} />;
    case 'motorcycle':
      return <Car {...iconProps} />;
    case 'bus':
      return <Car {...iconProps} />;
    case 'helicopter':
      return <Helicopter {...iconProps} />;
    case 'person':
      return <Person {...iconProps} />;
    case 'powerboat':
      return <Powerboat {...iconProps} />;
    case 'inflatable':
      return <Inflatable {...iconProps} />;
    case 'containership':
      return <Containership {...iconProps} />;
    case 'container':
      return <Container {...iconProps} />;
    case 'tug':
      return <Tug {...iconProps} />;
    case 'yacht':
      return <Yacht {...iconProps} />;
    case 'firetruck':
      return <Firetruck {...iconProps} />;
    case 'heartbeat':
      return <Heartbeat {...iconProps} />;
    default: return <Other {...iconProps} />;
  }
};

export default AssetIcon;
