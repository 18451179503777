import Smart from 'hocs/smart';
import View from './members-view';

export default Smart(
  View,
  state => ({
    user: state.session.user,
  }),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar,
  })
);
