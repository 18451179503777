import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { CompositeLayer } from 'deck.gl';
import { TextLayer } from '@deck.gl/layers';
import AssetIcon from 'components/shared/icons/assetIcons';
import { visualKey } from 'apis/trackstar/maps';
import OutlinedIconLayer from './outlinedIconLayer';

// takes line features and renders them 3 times so they show on maps zoomed out to show more than a world width
// Would like to conditionally render these, but cant find bounds correctly see https://github.com/uber/deck.gl/issues/4060
const triplicate = icons => icons.flatMap(icon => [
  { ...icon, longitude: icon.longitude - 360 },
  icon,
  { ...icon, longitude: icon.longitude + 360 }
]);

const ICONSIZE = 128;

class AdsbIconsLayer extends CompositeLayer {
  // eslint-disable-next-line class-methods-use-this
  shouldUpdateState({ changeFlags }) {
    return changeFlags.dataChanged || changeFlags.propsChanged;
  }

  updateState({ props }) {
    if (!props.aircraft) {
      this.setState({ icons: [] });
      return;
    }

    const { aircraft, nearestAdsbHex } = props;

    let text = [];

    const icons = triplicate(aircraft
      .map(ac => {
        const assetColor = [0, 0, 0, 128];

        // I cannot pass in the correct asset color to the SVG because the way deck.gl renders it makes it black.
        // It is passed into the OutlinedFragmentShader, which maps the white outline/black fill to white outline/<specified color> fill
        const iconUrl = `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<AssetIcon type={visualKey(ac.category, ac.t).id} opacity={1} />))}`;

        const hovered = ac.hex === nearestAdsbHex;

        const icon = {
          properties: {
            id: ac.id,
            name: ac.r || ac.flight || ac.hex,
            size: 5,
            color: hovered ? [0, 0, 0, 255] : assetColor,
            angle: ac.track ? (180 - ac.track) + 180 + props.bearing : 0,
            icon: {
              url: iconUrl,
              height: ICONSIZE,
              width: ICONSIZE,
              id: `${ac.category}-${ac.t}`,
              anchorX: ICONSIZE / 2,
              anchorY: ICONSIZE / 2,
              mask: true
            }
          },
          latitude: ac.lat,
          longitude: ac.lon
        };

        if (hovered) { text = [icon]; }

        return icon;
      }));

    this.setState({ icons, text });
  }

  renderLayers() {
    return [
      new OutlinedIconLayer({
        key: 'adsb-icon-layer',
        id: 'adsb-icon-layer',
        data: this.state.icons,
        pickable: false,
        autoHighlight: true,
        getIcon: d => d.properties.icon,
        sizeScale: 1,
        getPosition: d => [d.longitude, d.latitude],
        getSize: 30,
        getAngle: d => d.properties.angle,
        getColor: d => d.properties.color,
        outlineColor: [255, 255, 255],
        parameters: { depthTest: false },
        visible: true
      }),
      new TextLayer({
        key: 'adsb-label-layer',
        id: 'adsb-label-layer',
        data: this.state.text,
        getText: d => d.properties.name,
        pickable: false,
        fontWeight: 550,
        getPosition: d => [d.longitude, d.latitude],
        getSize: 12,
        // getColor: [36, 39, 48, 255],
        getColor: [255, 255, 255, 255],
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        getPixelOffset: [0, 32],
        visible: this.props.textVisible,
        parameters: { depthTest: false },
        fontSettings: { sdf: true, fontSize: 30, radius: 12 },
        fontFamily: 'objektiv-mk2,sans-serif',
        background: true,
        getBackgroundColor: [0, 0, 0, 128],
        backgroundPadding: [3, 1, 3, 3],
        // outlineColor: [255, 255, 255, 235],
        // outlineWidth: 0.3,
      })
    ];
  }
}

export default AdsbIconsLayer;
