import Smart from 'hocs/smart';
import ResetPasswordView from './resetPassword-view';

export default Smart(
  ResetPasswordView,
  state => ({}),
  ac => ({
    getResetPasswordEmail: ac.session.getResetPasswordEmail
  })
);
