import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  messageWrapper: {
    textAlign: 'left'
  },
  messageWrapperNoSender: {
    textAlign: 'left'
  },
  myMessageWrapper: {
    textAlign: 'right'
  },
  messageBubble: {
    display: 'flex',
    padding: theme.spacing(1, 3),
    borderRadius: '12px',
    maxWidth: '75%'
  },
  messageNoSender: {
    display: 'inline-block',
    padding: theme.spacing(1, 3),
    borderRadius: '12px'
  },
  messageText: {
    padding: '10px 20px 10px 20px',
    backgroundColor: theme.palette.messaging.theirMessageBackground,
    color: theme.palette.messaging.theirMessageText,
    borderRadius: '10px'
  },
  myMessageText: {
    backgroundColor: theme.palette.messaging.myMessageBackground,
    color: theme.palette.messaging.myMessageText,
    float: 'right',
    textAlign: 'left'
  },
  senderName: {
    color: theme.palette.common.text,
    fontSize: '0.8rem',
    fontWeight: 600,
    margin: '20px 15px 0 15px'
  },
  text: {
    color: theme.palette.common.text,
    fontSize: '1rem'
  },
  timestamp: {
    color: theme.palette.common.text,
    fontSize: '.8rem'
  },
  timestampWrapper: {
    textAlign: 'center'
  },
  statusIcon: {
    position: 'absolute',
    right: 26,
    color: theme.palette.common.disabled,
    fontSize: '1rem',
    marginTop: 39,
  },
}));

export default useStyles;
