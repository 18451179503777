import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import DetailsView from './details-view';

export default Smart(
  DetailsView,
  (state, ownProps) => ({
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // assetState: state.assetState[ownProps.asset.id],
    defaultSerialType: state.settings.ui.serialType,
    organisationId: state.session.organisationId,
    permissions: permissionsForCurrentUserAndOrg(state),
  }),
  ac => ({
    selectItem: ac.app.selectItem,
    navigate: ac.router.navigate,
  })
);
