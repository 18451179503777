import Smart from 'hocs/smart';
import LiveUpdateDummyView from './liveUpdateDummy-view';

export default Smart(
  LiveUpdateDummyView,
  state => ({
    timezone: state.settings.locale.timezone,
    organisationId: state.session.organisationId,
    mostRecentDeviceReport: state.positions.mostRecentDeviceReport
  }),
  ac => ({
    setUnreadTextMessage: ac.reports.setUnreadTextMessage,
    setMostRecentDeviceReport: ac.reports.setMostRecentDeviceReport,
  })
);
