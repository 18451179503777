import Smart from 'hocs/smart';
import MessagingView from './messaging-view';

export default Smart(
  MessagingView,
  (state: any) => ({
    userId: state.session.user.id,
    organisationId: state.session.organisationId,
    textMessages: state.reports.textMessages,
  }),
  (ac: any) => ({
    displaySnackbar: ac.app.displaySnackbar,
    markRead: ac.reports.markRead,
  })
);
