import Smart from 'hocs/smart';
import QuickGroupView from './quickGroup-view';

export default Smart(
  QuickGroupView,
  state => ({
    activeQuery: state.app.query,
    selectedGroupBy: state.app.groupBy,
    omniboxOpen: state.ui.omniboxOpen
  }),
  ac => ({
    setSelectedGroupBy: ac.app.setOmniboxGroupBy,
    showAllAssetsOnMap: ac.map.showAllAssetsOnMap
  })
);
