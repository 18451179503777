import React, { useState } from 'react';
import {
  Box,
  Fab,
  Typography,
  Tooltip, Paper, IconButton, InputBase
} from '@mui/material';
import { Chat, Search as SearchIcon } from '@mui/icons-material';
import clsx from 'clsx';
import { fetchConversations } from 'apis/trackstar/serenity';
import { useQuery } from 'react-query';
import useTranslation from 'hooks/useTranslation';
import Page from 'components/pages/page';
import { NoHeaderNoFooterLoadingPage } from 'components/pages/loading';
import { ClassNameMap } from '@mui/styles';
import uuid from 'utils/uuid';
import Preview from './preview';
import ConversationView from './conversation';
import ParticipantNameListItem from './participantNameListItem';
import useStyles from './messaging-styles';
import NewConversationBar from './newConversationBar';

interface MessagingPageProps {
  userId: string;
  organisationId: string;
  displaySnackbar: (Snack: Snack) => void;
  textMessages: any;
  markRead: (deviceId: number) => void;
}

const MessagingPage = ({
  userId,
  organisationId,
  displaySnackbar,
  textMessages,
  markRead,
}: MessagingPageProps): JSX.Element => {
  const classes: ClassNameMap = useStyles();
  const t = useTranslation('pages.messaging');
  const [search, setSearch] = useState('');
  const [conversations, setConversations] = useState<Conversation[]>([]);

  const getConversations = useQuery(['conversations'], fetchConversations, {
    // TODO: need to add optimistic convos to react-query conversations to stop new convos persisting when switching orgs
    onSuccess: data => {
      const optimisticConversations = conversations.filter(c => !c.latestMessage && !data.find(d => d.deviceId === c.deviceId));
      setConversations([...optimisticConversations, ...data]);
    },
    refetchInterval: 30 * 1000, // 30 Seconds.
    refetchIntervalInBackground: true,
  });
  if (getConversations.isError) displaySnackbar({ id: 'getConversationsFailed', text: t('getConversationsFailed'), type: 'error' });

  const [selectedConversationId, selectConversationId] = useState<string | null | undefined>(null);
  const createConversation = (participant: Participant): Conversation => {
    const newConvo: Conversation = {
      deviceId: participant.deviceId,
      assetName: participant.name,
      id: uuid(),
      latestMessage: null,
      participants: [participant],
      readCursor: null
    };
    setConversations(convos => [...convos, newConvo]);
    return newConvo;
  };

  // TODO should be useMemo?
  conversations.sort((a, b) => {
    if (!a.latestMessage) return -1; // New conversations go at start
    if (!b.latestMessage) return 1; // Same but for right-hand side of sort
    return b.latestMessage.timestamp - a.latestMessage.timestamp;
  });
  const selectedConversation = conversations.find(c => c.id === selectedConversationId);
  // select the first conversation by default (on page load)
  if (!selectedConversation && conversations.length > 0) {
    selectConversationId(conversations[0].id);
  }

  const [creatingConversation, setCreatingConversation] = useState(false);

  const createNewConversation = (participant: Participant): void => {
    const convo: Conversation = createConversation(participant);
    setCreatingConversation(false);
    selectConversationId(convo.id);
  };

  const asset = selectedConversation?.participants.find(p => p.type === 'asset');
  const recipients = asset ? [asset] : selectedConversation?.participants.filter(p => p.id !== userId);

  const matchConversations = (c: Conversation): boolean => {
    const lowercaseFilter = search?.trim().toLowerCase();
    if (!lowercaseFilter) return true;
    const latestMessageMatch = c.latestMessage?.content?.toLowerCase().includes(lowercaseFilter);
    const senderMatch = c.participants.find(p => p.name.toLowerCase().includes(lowercaseFilter)
      || p.make?.toString().toLowerCase().includes(lowercaseFilter)
      || p.model?.toString().toLowerCase().includes(lowercaseFilter)
      || p.imei?.toString().toLowerCase().includes(lowercaseFilter)
      || p.manufacturerSerial?.toString().toLowerCase().includes(lowercaseFilter)
      || p.tpSerial?.toString().toLowerCase().includes(lowercaseFilter)
      || p.ownerId?.toString().toLowerCase().includes(lowercaseFilter));
    return !!(latestMessageMatch || senderMatch);
  };

  return (
    <Page>
      <Box className={classes.container}>
        <Box className={classes.left}>
          <Box className={classes.conversationHeader}>
            <Paper className={classes.omniSearch}>
              <IconButton className={classes.searchIcon} aria-label="Search" size="large">
                <SearchIcon />
              </IconButton>
              <InputBase
                className={classes.input}
                type="search"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={t('findConversation')}
              />
            </Paper>
            <Tooltip title={t('newConversation')}>
              <Fab
                color="primary"
                aria-label="chat"
                className={classes.newConversation}
                // className={clsx({ [classes.hidden]: !permissions.canCreateNewConversation })}
                onClick={(): void => setCreatingConversation(true)}
                size="medium"
                // disabled={!permissions.canCreateNewConversation}
                style={{ alignSelf: 'center' }}
              >
                <Chat />
              </Fab>
            </Tooltip>
          </Box>
          <Box className={classes.conversationWrapper}>
            {getConversations.isLoading
              ? (<NoHeaderNoFooterLoadingPage />)
              : conversations.filter(matchConversations).map(conv => (
                // Conversation can't exist without a message
                <Preview
                  key={conv.id}
                  userId={userId}
                  // TODO: uncomment this when we get readCursor's back to set unread styling
                  isSeen={!textMessages[conv.deviceId]} // {conv.readCursor === conv.latestMessage?.id}
                  participants={conv.participants}
                  msg={conv.latestMessage}
                  handleClick={(): void => {
                    setCreatingConversation(false);
                    selectConversationId(conv.id);
                    markRead(conv.deviceId);
                  }}
                  isSelected={conv.id === selectedConversationId}
                />
              ))}
          </Box>
        </Box>
        <Box className={classes.right}>
          <div className={classes.conversationContainer}>
            <div className={clsx(classes.messageHeader, { [classes.messageHeaderWithAutocomplete]: creatingConversation })}>
              {creatingConversation
                ? (
                  <NewConversationBar
                    // @ts-ignore
                    createNewConversation={createNewConversation}
                    conversations={conversations}
                    selectConversationId={selectConversationId}
                    setCreatingConversation={setCreatingConversation}
                  />
                )
                : selectedConversation && (
                  <Typography variant="h4" style={{ textAlign: 'left' }}>
                    {recipients?.map((p, index) => {
                      const addComma = index < recipients.length - 1;
                      // @ts-ignore
                      return <ParticipantNameListItem key={p.id} participant={p} showDetails addComma={addComma} />;
                    })}
                  </Typography>
                )}
            </div>
            {selectedConversation && (
              <ConversationView
                conversation={selectedConversation}
                userId={userId}
                organisationId={organisationId}
                displaySnackbar={displaySnackbar}
              />
            )}
          </div>
        </Box>
      </Box>
    </Page>
  );
};

export default MessagingPage;
