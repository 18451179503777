import Smart from 'hocs/smart';
import { latestReportForAsset } from 'selectors/reports';
import DistressView from './distress-view';

export default Smart(
  DistressView,
  state => ({
    latestReport: latestReportForAsset({ assetId: state.app.selectedItem?.id, organisationId: state.session?.organisationId, now: state.app?.now }),
    incident: state.incidents[state.app.selectedItem?.id],
  }),
  ac => ({})
);
