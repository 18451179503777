import Smart from 'hocs/smart';
import Message from './message-view';

export default Smart(
  Message,
  state => ({
    timezone: state.settings.locale.timezone,
    sentMessages: state.websocket.sentMessages,
  }),
  ac => ({})
);
