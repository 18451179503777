import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { ClassNameMap } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';

import { TextField } from '@mui/material';
import useStyles from './input-styles';

interface AutocompleteInputProps {
  id: string
  label: string
  options: unknown[]
  onChange: (id: string, newValue: unknown) => void
  value: unknown
  className?: string,
  getOptionLabel?: (option: unknown) => string
}

const defaultProps = {
  className: undefined,
  getOptionLabel: option => String(option)
};

const AutocompleteInput = ({
  id,
  label,
  options,
  onChange,
  value,
  className,
  ...inputBaseProps
}: AutocompleteInputProps): JSX.Element => {
  const classes: ClassNameMap = useStyles();

  const handleChange = (event: ChangeEvent<{ OrganisationOption }>, newValue: unknown): void => {
    onChange(id, newValue);
  };

  return (
    <Autocomplete
      {...inputBaseProps}
      id={id}
      onChange={handleChange}
      className={clsx([classes.textField, className])}
      renderInput={params => <TextField {...params} label={label} variant="outlined" />}
      value={value}
      fullWidth
      options={options}
    />
  );
};

AutocompleteInput.defaultProps = defaultProps;

export default AutocompleteInput;
