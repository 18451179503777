import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  amsSettingsPanel: {
    display: 'block',
    padding: theme.spacing(4),
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 0,
      '& .MuiFormControlLabel-root': {
        margin: 0,
        display: 'inline'
      },
      '& .MuiRadio-root': {
        display: 'none'
      }
    },
    '& .MuiInputLabel-outlined': {
      backgroundColor: theme.palette.background.paper,
      paddingRight: 5,
    },
  },
  inputDisabled: {
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.3)'
      }
    }
  },
  firstLabel: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.toggleButtons.deselectedBg,
    '& .MuiTypography-root': {
      fontWeight: 600,
      color: theme.palette.toggleButtons.deselectedText
    },
    '&.selected': {
      backgroundColor: theme.palette.toggleButtons.selectedBg,
      '& .MuiTypography-root': {
        fontWeight: 600,
        color: theme.palette.toggleButtons.selectedText
      }
    }
  },
  secondLabel: {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    padding: theme.spacing(3),
    paddingRight: theme.spacing(4),
    backgroundColor: theme.palette.toggleButtons.deselectedBg,
    '& .MuiTypography-root': {
      fontWeight: 600,
      color: theme.palette.toggleButtons.deselectedText
    },
    '&.selected': {
      backgroundColor: theme.palette.toggleButtons.selectedBg,
      '& .MuiTypography-root': {
        fontWeight: 600,
        color: theme.palette.toggleButtons.selectedText
      }
    }
  },
  checkBoxContainer: {
    paddingBottom: theme.spacing(4)
  },
  category: {
    marginTop: theme.spacing(4)
  },
  amsDisabledWarning: {
    padding: theme.spacing(4),
    textAlign: 'center'
  },
  inputButton: {
    padding: theme.spacing(2, 6),
    fontSize: '1rem',

  },
  inputButtonWrapper: {
    textAlign: 'right',
  },
  enableCheckbox: {
    marginTop: 6,
  },
  assetAmsConfig: {
    marginTop: theme.spacing(4),
  },
  panelWrapper: {
    textAlign: 'left',
    boxShadow: 'none',
    border: theme.border.default
  },
}));
