export const updateSetting = (category, field, value) => ({
  type: 'UPDATE_SETTING',
  payload: {
    category,
    field,
    value
  }
});
export const setOrgHomeBase = (orgId, homeBase) => ({
  type: 'SET_ORG_HOME_BASE',
  payload: {
    orgId,
    homeBase
  }
});

export const updateMapControlsContrast = value => ({
  type: 'TOGGLE_CONTROL_CONTRAST',
  payload: value
});

export const toggleDarkMode = () => ({
  type: 'TOGGLE_DARKMODE'
});

export const setOrganisationTimezone = (organisationId, newOrganisationTimezone) => ({
  type: 'SET_ORG_TIMEZONE',
  payload: {
    organisationId,
    newOrganisationTimezone
  }
});

export const setLanguage = lang => ({
  type: 'SET_LANGUAGE',
  payload: lang
});

export const setPreviousColors = color => ({
  type: 'SET_PREVIOUS_COLORS',
  payload: color
});
