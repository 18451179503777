import produce from 'immer';
import { v4 as uuid } from 'uuid';
import { VIEWS_PER_LAYOUT, DEFAULT_MAP_LAYOUT } from 'constants/maplayouts';
import { TRAILS_OPTIONS } from 'constants/trailsoptions';
/**
 * This reducer is for Map and Layer state only.  Map Settings
 * exist in the settings reducer under the 'map' key.
* */

const defaultId = 'default';
const defaultMap = {
  id: defaultId,
  sortOrder: 0,
  baseLayerId: 'reactmapgl-here-hybrid',
  baseLayerLibrary: 'reactmapgl',
  animateToSelection: false,
  animateTrails: true,
  animateSelectedTrailOnly: true,
  permanentLabels: false,
  kmlLabels: true,
  highlightSelectedObject: true,
  unselectedItemOpacity: 0.75,
  defaultPulseColor: [0, 0, 0], // default pulse is black
  followPulseColor: [0, 255, 0], // follow pulse is green
  assetClustering: false,
  trailsOption: TRAILS_OPTIONS.allTrailsIcons,

  windTrails: false,
  windVelocity: false,

  // To spline, or not to spline... and _how_ to spline...
  splineTrails: true,
  splineTension: 0.5,
  splineSegmentCount: 25,

  trailWidth: 2,
  trailUnderlineWidth: 4,
  // TODO: hardcode hideInactiveAssets to false (it's broken at the moment so we're hiding it)
  hideInactiveAssets: false,
  inactiveSinceHours: 1,
  // Layers are ordered top-down, ie: order: 0 is on top
  layers: {
    assetIcons: {
      id: 'assetIcons',
      label: 'Asset Icons',
      order: 1
    },
    adsbIcons: {
      id: 'adsbIcons',
      label: 'ADSB Icons',
      order: 2
    },
    events: {
      id: 'events',
      label: 'Events',
      order: 3
    },
    dots: {
      id: 'dots',
      label: 'Dots',
      order: 4
    },
    trails: {
      id: 'trails',
      label: 'Trails',
      order: 5
    },
    missionTarget: {
      id: 'missionTarget',
      label: 'Mission Target Area',
      order: 6
    },
    measureTool: {
      id: 'measureTool',
      label: 'Measure Tool',
      order: 7
    },
    kml: {
      id: 'kml',
      label: 'KML',
      order: 8
    },
    windVelocity: {
      id: 'windVelocity',
      label: 'Wind Velocity',
      order: 9
    },
  },
  reportDots: true,
  hideReportDotsAtZoom: 7.5,
  adsb: false,
  hideAdsbAbove: 40000,
  viewport: { // not used anymore
    center: [0, 0],
    zoom: 1
  }
};

const initialState = {
  maps: { default: defaultMap },
  viewports: { default: { center: [0, 0], zoom: 1 } },
  measurementMarkers: { default: { currentMeasurementMarker: null, measurementMarkers: {} } },
  selectedAssets: { default: null },
  customLayerKml: { default: null },
  selectedMapId: defaultId,
  selectedMapLayout: DEFAULT_MAP_LAYOUT,
  isClosable: false,
  missionTargetAreas: { default: null },
  hiddenAssetGroups: [],
  hiddenAssets: [],
  hiddenInactiveAssets: [],
  trailHighlight: null,
  assetsAreBeingFollowedOnMaps: { default: false },
  adsbAircraft: { default: [] }
};

const persistFields = ['maps', 'viewports'];

const mapReducer = (state = initialState, action) => produce(state, () => {
  /* eslint-disable-next-line */
  switch (action.type) {
    case 'MOVE_LAYER': {
      const map = { ...state.maps[action.payload.mapId] };
      if (!map) {
        console.error('Could not find map while attempting to move layer', action.payload);
        return state;
      }

      const {
        from,
        to
      } = action.payload;

      const layers = Object.values(map.layers);
      const [removed] = layers.splice(from, 1);
      layers.splice(to, 0, removed);
      for (let i = 0; i < layers.length; i++) {
        layers[i].order = i + 1;
      }

      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapId]: map
        }
      };
    }
    case 'JUMP_TO_HOME_BASE': {
      const {
        mapId,
        homeBase
      } = action.payload;
      const viewport = state.viewports[mapId];

      if (!homeBase || !viewport) return state;
      return {
        ...state,
        viewports: {
          ...state.viewports,
          [mapId]: {
            ...viewport,
            ...homeBase
          }
        }
      };
    }
    case 'SET_ASSET_FOLLOW':
      return {
        ...state,
        assetsAreBeingFollowedOnMaps: {
          ...state.assetsAreBeingFollowedOnMaps,
          [action.payload.mapId]: action.payload.isFollowed
        }
      };
    case 'RESET_MAP': {
      const rmviewport = state.viewports[action.payload.mapId];
      if (!rmviewport) return state;
      return {
        ...state,
        viewports: {
          ...state.viewports,
          [action.payload.mapId]: {
            ...rmviewport,
            // reset the map rotation and angle
            pitch: 0.0,
            yaw: 0.0,
            bearing: 0.0
          }
        }
      };
    }
    case 'CLOSE_MAP': {
      const currentLayoutCount = VIEWS_PER_LAYOUT[state.selectedMapLayout];
      if (currentLayoutCount < 2) return state; // Can't close last map

      const newLayoutCount = currentLayoutCount - 1;
      const newMapLayout = Object.keys(VIEWS_PER_LAYOUT).find(key => VIEWS_PER_LAYOUT[key] === newLayoutCount);
      const remainingMaps = Object.values(state.maps)
        .filter(m => m.id !== action.payload) // Remove deleted map
        .sort((a, b) => a.sortOrder - b.sortOrder) // Ensure the remaining maps are sorted
        .reduce((acc, m, i) => {
          // Fill in the hole created by the deleted map
          acc[m.id] = {
            ...m,
            sortOrder: i
          };
          return acc;
        }, {}); // Reduce back to a id:map object

      return {
        ...state,
        selectedMapLayout: newMapLayout,
        selectedMapId: null,
        maps: remainingMaps,
        isClosable: newLayoutCount > 1
      };
    }
    case 'PATCH_VIEWPORT': {
      // This replaces UPDATE_VIEWPORT and only changes the parts of the viewport that changed.
      // If anything weird happens with the viewports in the future it may be due to this change.
      const viewport = state.viewports[action.payload.mapId];
      if (!viewport) {
        console.error('Could not find viewport while attempting to patch viewport', action.payload);
        return state;
      }

      return {
        ...state,
        viewports: {
          ...state.viewports,
          [action.payload.mapId]: {
            ...viewport,
            ...action.payload.viewport
          }
        }
      };
    }
    case 'UPDATE_MAP_CONFIG': {
      const config = state.maps[action.payload.mapId];
      if (!config) return state;

      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapId]: {
            ...config,
            ...action.payload.config
          }
        }
      };
    }
    case 'SET_BASE_LAYER': {
      let nextZoom = state.viewports[action.payload.mapId].zoom;
      const templateMap = Object.values(action.payload.baseLayer.template.sources)[0];
      if (templateMap.minZoom > nextZoom) {
        nextZoom = templateMap.minZoom;
      }
      if (templateMap.maxZoom < nextZoom) {
        nextZoom = templateMap.maxZoom;
      }
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapId]: {
            ...state.maps[action.payload.mapId],
            viewport: {
              ...state.maps[action.payload.mapId].viewport,
              zoom: nextZoom
            },
            baseLayerId: action.payload.baseLayer.id,
            baseLayerLibrary: action.payload.baseLayer.library
          }
        },
        viewports: {
          ...state.viewports,
          [action.payload.mapId]: {
            ...state.viewports[action.payload.mapId],
            zoom: nextZoom
          }
        }
      };
    }
    case 'UPDATE_MAP_LAYOUT': {
      // it is possible that the user has selected a map that will not be displayed in the new viewport layout, so we need to check for that
      // and select a different viewport if necessary - arbitrary choice, choose the first map

      const {
        layout,
        defaultMapSettings
      } = action.payload;

      // assign a number to the layouts, so that we can write simple(r) logic here
      const currentLayoutCount = VIEWS_PER_LAYOUT[state.selectedMapLayout];
      const newLayoutCount = VIEWS_PER_LAYOUT[layout];

      const newMaps = {};
      const newViewports = {};
      const newMeasurementMarkers = {};
      const newSelectedAssets = {};
      const newAdsbAircraft = {};

      // General Settings (map settings) apply to each newly created map
      for (let i = Object.keys(state.maps).length; i <= newLayoutCount; i++) {
        const newMapConfigId = uuid();

        newMaps[newMapConfigId] = {
          ...defaultMapSettings,
          id: newMapConfigId,
          sortOrder: i,
          baseLayerId: 'reactmapgl-here-hybrid',
          baseLayerLibrary: 'reactmapgl',
        };
        newViewports[newMapConfigId] = { ...state.viewports.default };
        newMeasurementMarkers[newMapConfigId] = { currentMeasurementMarker: null, measurementMarkers: {} };
        newSelectedAssets[newMapConfigId] = state.selectedAssets.default ? { ...state.selectedAssets.default } : null;
        newAdsbAircraft[newMapConfigId] = [];
      }

      // there are two conditions that must be met to trigger a change in map selection
      // 1: reduction in map layout
      // 2: current selection will fall outside the new layout
      const resetMapSelection = (newLayoutCount < currentLayoutCount) && ((state.maps[state.selectedMapId].sortOrder + 1) > newLayoutCount);

      return {
        ...state,
        selectedMapLayout: layout,
        selectedMapId: resetMapSelection ? defaultId : state.selectedMapId,
        isClosable: newLayoutCount > 1,
        maps: {
          ...state.maps,
          ...newMaps
        },
        viewports: {
          ...state.viewports,
          ...newViewports
        },
        measurementMarkers: {
          ...state.measurementMarkers,
          ...newMeasurementMarkers
        },
        selectedAssets: {
          ...state.selectedAssets,
          ...newSelectedAssets
        },
        adsbAircraft: {
          ...state.adsbAircraft,
          ...newAdsbAircraft
        }
      };
    }
    case 'SELECT_MAP':
      return {
        ...state,
        selectedMapId: action.payload
      };
    case 'ASSIGN_ITEM_TO_MAP': {
      let cmm = null;
      if (state.measurementMarkers[action.payload.mapId]?.measurementMarkers
        && state.measurementMarkers[action.payload.mapId]?.measurementMarkers[action.payload.item.id]) {
        cmm = {
          lat: state.measurementMarkers[action.payload.mapId].measurementMarkers[action.payload.item.id].lat,
          lng: state.measurementMarkers[action.payload.mapId].measurementMarkers[action.payload.item.id].lng
        };
      }
      const assetToAssign = {
        category: action.payload.item.category,
        color: action.payload.item.color,
        deviceId: action.payload.item.deviceId,
        device: action.payload.item.device,
        id: action.payload.item.id,
        make: action.payload.item.make,
        model: action.payload.item.model,
        variant: action.payload.item.variant,
        name: action.payload.item.name,
        messagingHandle: action.payload.item.messagingHandle,
        operatorId: action.payload.item.operatorId,
        operatorName: action.payload.item.operatorName,
        ownerId: action.payload.item.ownerId,
        ownerName: action.payload.item.ownerName,
        // eslint-disable-next-line no-underscore-dangle
        type: action.payload.item.type || action.payload.item.__typename,
      };
      return {
        ...state,
        measurementMarkers: {
          ...state.measurementMarkers,
          [action.payload.mapId]: {
            ...state.measurementMarkers[action.payload.mapId],
            currentMeasurementMarker: cmm
          }
        },
        selectedAssets: {
          ...state.selectedAssets,
          [action.payload.mapId]: assetToAssign
        },
        missionTargetArea: null,
        // turn off following on selected map when changing asset selection
        assetsAreBeingFollowedOnMaps: {
          ...state.assetsAreBeingFollowedOnMaps,
          [action.payload.mapId]: false,
        }
      };
    }
    case 'UNASSIGN_ITEM_FROM_MAP':
      if (!state.selectedMapId) return state;
      return {
        ...state,
        selectedAssets: {
          ...state.selectedAssets,
          [state.selectedMapId]: null
        }
      };
    case 'ASSIGN_MARKER_TO_ASSET':
      return {
        ...state,
        measurementMarkers: {
          ...state.measurementMarkers,
          [action.payload.mapId]: {
            ...state.measurementMarkers[action.payload.mapId],
            measurementMarkers: {
              ...state.measurementMarkers[action.payload.mapId].measurementMarkers,
              [action.payload.assetId]: {
                lat: action.payload.lat,
                lng: action.payload.lng
              }
            },
            currentMeasurementMarker: {
              lat: action.payload.lat,
              lng: action.payload.lng
            }
          }
        }
      };
    case 'SET_MISSION_TARGET_AREA':
      return {
        ...state,
        missionTargetAreas: {
          ...state.missionTargetAreas,
          [action.payload.mapId]: action.payload.targetArea
        }
      };

    case 'DELETE_ASSET': {
      // ensure none of the maps have been assigned the removed asset
      const assetId = action.payload.id;

      const mapIds = Object.keys(state.selectedAssets);
      const cleanupTheseMapIds = mapIds.filter(mapId => state.selectedAssets[mapId]?.assetId === assetId);

      const cleanedEntries = {};
      cleanupTheseMapIds.forEach(mapId => {
        cleanedEntries[mapId] = null;
      });

      return {
        ...state,
        selectedAssets: {
          ...state.selectedAssets,
          ...cleanedEntries
        }
      };
    }

    case 'SET_TRAILS_OPTION': {
      if (!action.payload.mapId) return state;
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapId]: {
            ...state.maps[action.payload.mapId],
            trailsOption: action.payload.trailsOption
          }
        }
      };
    }
    case 'HIDE_ASSETS_GROUP': {
      const hiddenGroups = [...state.hiddenAssetGroups, action.payload];
      return {
        ...state,
        hiddenAssetGroups: hiddenGroups.filter((e, i) => hiddenGroups.indexOf(e) === i)
      };
    }
    case 'REMOVE_FROM_HIDDEN_ASSET_GROUPS':
      return {
        ...state,
        hiddenAssetGroups: state.hiddenAssetGroups.filter(assetGroup => assetGroup !== action.payload)
      };
    case 'HIDE_ASSETS_ON_MAP': {
      const hiddenAssetsArray = [...state.hiddenAssets, ...action.payload];
      return {
        ...state,
        hiddenAssets: hiddenAssetsArray.filter((e, i) => hiddenAssetsArray.indexOf(e) === i)
      };
    }
    case 'SHOW_ASSETS_ON_MAP': {
      const assetsToShow = action.payload.map(a => a.id);
      return {
        ...state,
        hiddenAssets: state.hiddenAssets.filter(a => !assetsToShow.includes(a.id))
      };
    }
    case 'SHOW_ALL_ASSETS_ON_MAP': {
      return {
        ...state,
        hiddenAssetGroups: [],
        hiddenAssets: []
      };
    }
    case 'UPDATE_HIDDEN_INACTIVE_ASSETS': {
      return {
        ...state,
        hiddenInactiveAssets: [...action.payload]
      };
    }

    case 'SET_TRAIL_HIGHLIGHT': {
      return {
        ...state,
        trailHighlight: action.payload.highlightTrail
      };
    }

    case 'ZOOM_IN': {
      const viewport = state.viewports[action.payload.mapId];
      if (!viewport) return state;

      return {
        ...state,
        viewports: {
          ...state.viewports,
          [action.payload.mapId]: {
            ...viewport,
            zoom: Math.min(viewport.zoom + 1, action.payload.maxZoom),
            transitionDuration: 500
          }
        }
      };
    }
    case 'ZOOM_OUT': {
      const viewport = state.viewports[action.payload.mapId];
      if (!viewport) return state;

      return {
        ...state,
        viewports: {
          ...state.viewports,
          [action.payload.mapId]: {
            ...viewport,
            zoom: Math.max(viewport.zoom - 1, action.payload.minZoom),
            transitionDuration: 500
          }
        }
      };
    }

    case 'SET_ORGANISATIONID': {
      const resetSelectedAssets = Object.keys(state.selectedAssets).map(mapId => ({ [mapId]: null }));
      return {
        ...state,
        selectedAssets: Object.assign({}, ...resetSelectedAssets),
      };
    }
    case 'SET_ADSB_AIRCRAFT': {
      return {
        ...state,
        adsbAircraft: {
          ...state.adsbAircraft,
          [action.payload.mapId]: action.payload.aircraft
        }
      };
    }

    case 'CLEAR_DATA':
    case 'RESET_EVERYTHING':
      return initialState;
    default:
      return state;
  }
});

export default {
  key: 'map',
  reducer: mapReducer,
  version: 10,
  whitelist: persistFields,
  migrations: {
    5: () => initialState,
    6: state => ({
      ...state,
      maps: Object.keys(state.maps).reduce((acc, mapId) => ({
        ...acc,
        [mapId]: {
          ...state.maps[mapId],
          lastNumberOfDaysReported: 1
        }
      }), {})
    }),
    7: state => ({
      ...state,
      assetsAreBeingFollowedOnMaps: { default: false }
    }),
    8: state => ({
      ...state,
      maps: Object.keys(state.maps).reduce((acc, mapId) => ({
        ...acc,
        [mapId]: {
          ...state.maps[mapId],
          assetClustering: false,
        }
      }), {})
    }),
    9: state => ({
      ...state,
      maps: Object.keys(state.maps).reduce((acc, mapId) => ({
        ...acc,
        [mapId]: {
          ...state.maps[mapId],
          reportDots: true,
          hideReportDotsAtZoom: 7.5,
        }
      }), {})
    }),
    10: state => ({
      ...state,
      maps: Object.keys(state.maps).reduce((acc, mapId) => ({
        ...acc,
        [mapId]: {
          ...state.maps[mapId],
          layers: defaultMap.layers
        }
      }), {})
    })
  }
};
