import Smart from 'hocs/smart';
import ProvisioningListView from './provisioningList-view';

export default Smart(
  ProvisioningListView,
  () => ({}),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar
  })
);
