import { combineReducers } from 'redux';
import { purgeStoredState, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import app from './app';
import positions from './positions';
import map from './map';
import session from './session';
import settings from './settings';
import ui from './ui';
import reports from './reports';
import legs from './legs';
import incidents from './incidents';
import websocket from './websocket';

const DEBUG = true;

const purgeStore = () => {
  purgeStoredState({ key: app.key, storage });
  purgeStoredState({ key: map.key, storage });
  purgeStoredState({ key: session.key, storage });
  purgeStoredState({ key: settings.key, storage });
};

const wireUp = config => persistReducer({
  key: config.key,
  version: config.version,
  whitelist: config.whitelist,
  blacklist: config.blacklist,
  migrate: createMigrate(config.migrations, { debug: DEBUG }),
  storage
}, config.reducer);

export default router => {
  const allReducers = combineReducers({
    app: wireUp(app),
    positions,
    legs,
    map: wireUp(map),
    session: wireUp(session),
    settings: wireUp(settings),
    ui,
    reports,
    incidents,
    websocket,
    router
  });
  return (state, action) => {
    if (action.type === 'RESET_EVERYTHING') {
      purgeStore();
    }
    return allReducers(state, action);
  };
};
