import React from 'react';
import { Link } from 'react-router-dom';
import Page from 'components/pages/page';
import {
  Grid,
  Container,
  Typography,
  Box,
  Paper,
  SvgIcon
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useStyles from './settings-styles';

const SettingsPage = () => {
  const classes = useStyles();
  const t = useTranslation('pages.settings');
  return (
    <Page>
      <Grid container className={classes.pageWrapper} spacing={0}>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <Typography variant="h1" paragraph>{t('title')}</Typography>
            <Paper className={classes.panel}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Link to="/settings/general" className={classes.link}>
                    <Box className={classes.settingsButton}>
                      <Box className={classes.iconWrapper}>
                        <SvgIcon width="16" height="16" viewBox="0 0 16 16" className={classes.icon}>
                          <path d="M13.3,5.2l1.1-2.1l-1.4-1.4l-2.1,1.1c-0.3-0.2-0.7-0.3-1.1-0.4L9,0H7L6.2,2.3C5.9,2.4,5.5,2.5,5.2,2.7 L3.1,1.6L1.6,3.1l1.1,2.1C2.5,5.5,2.4,5.9,2.3,6.2L0,7v2l2.3,0.8c0.1,0.4,0.3,0.7,0.4,1.1l-1.1,2.1l1.4,1.4l2.1-1.1 c0.3,0.2,0.7,0.3,1.1,0.4L7,16h2l0.8-2.3c0.4-0.1,0.7-0.3,1.1-0.4l2.1,1.1l1.4-1.4l-1.1-2.1c0.2-0.3,0.3-0.7,0.4-1.1L16,9V7 l-2.3-0.8C13.6,5.9,13.5,5.5,13.3,5.2z M8,11c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S9.7,11,8,11z" />
                        </SvgIcon>
                      </Box>
                      <Box className={classes.details}>
                        <Typography variant="body1">{t('generalSettingsButton.title')}</Typography>
                        <Typography variant="body1">{t('generalSettingsButton.description')}</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Link to="/settings/organisation" className={classes.link}>
                    <Box className={classes.settingsButton}>
                      <Box className={classes.iconWrapper}>
                        <SvgIcon width="16" height="16" viewBox="0 0 16 16" className={classes.icon}>
                          <path d="M8,6L8,6C6.3,6,5,4.7,5,3v0c0-1.7,1.3-3,3-3h0c1.7,0,3,1.3,3,3v0C11,4.7,9.7,6,8,6z" />
                          <polygon points="10,16 6,16 6,12 4,10 4,7 12,7 12,10 10,12 " />
                          <path data-color="color-2" d="M2,5L2,5C0.9,5,0,4.1,0,3v0c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v0C4,4.1,3.1,5,2,5z" />
                          <polygon data-color="color-2" points="3,10.4 3,6 0,6 0,9 1,10 1,14 4,14 4,11.4 " />
                          <path data-color="color-2" d="M14,5L14,5c1.1,0,2-0.9,2-2v0c0-1.1-0.9-2-2-2h0c-1.1,0-2,0.9-2,2v0C12,4.1,12.9,5,14,5z" />
                          <polygon data-color="color-2" points="13,10.4 13,6 16,6 16,9 15,10 15,14 12,14 12,11.4 " />
                        </SvgIcon>
                      </Box>
                      <Box className={classes.details}>
                        <Typography variant="body1">{t('organisationSettingsButton.title')}</Typography>
                        <Typography variant="body1">{t('organisationSettingsButton.description')}</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Link to="/settings/account" className={classes.link}>
                    <Box className={classes.settingsButton}>
                      <Box className={classes.iconWrapper}>
                        <SvgIcon width="16" height="16" viewBox="0 0 16 16" className={classes.icon}>
                          <path d="M8,0C3.58,0,0,3.58,0,8s3.58,8,8,8s8-3.58,8-8S12.42,0,8,0z M8,2.4c1.32,0,2.4,1.08,2.4,2.4
                            c0,1.33-1.08,2.4-2.4,2.4S5.6,6.13,5.6,4.8C5.6,3.48,6.68,2.4,8,2.4z M8,13.76c-2,0-3.76-1.02-4.8-2.58C3.22,9.6,6.4,8.72,8,8.72
                            s4.78,0.88,4.8,2.46C11.76,12.74,10,13.76,8,13.76z"
                          />
                        </SvgIcon>
                      </Box>
                      <Box className={classes.details}>
                        <Typography variant="body1">{t('accountSettingsButton.title')}</Typography>
                        <Typography variant="body1">{t('accountSettingsButton.description')}</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default SettingsPage;
