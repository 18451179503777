import Smart from 'hocs/smart';
import AnalysisboxContainer from './analysisboxContainer-view';

export default Smart(
  AnalysisboxContainer,
  (state: any) => ({
    analysisboxOpen: state.ui.analysisboxOpen,
    contextboxOpen: state.ui.contextboxOpen,
    selectedItem: state.app.selectedItem,
    omniboxOpen: state.ui.omniboxOpen,
  }),
  (ac: any) => ({
    toggleAnalysisbox: ac.ui.toggleAnalysisbox,
  })
);
