export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_ASSET_LEGS': {
      const newLegs = action.payload.legs;
      const updatedLegs = state[action.payload.id]?.length ? state[action.payload.id].concat(newLegs) : newLegs;
      return {
        ...state,
        [action.payload.id]: updatedLegs,
      };
    }
    case 'DELETE_ASSET': {
      // remove all references to deleted asset
      const assetId = action.payload.id;
      const { [assetId]: deletedAsset, ...rest } = state;
      return rest;
    }
    case 'CLEAR_DATA':
    case 'RESET_EVERYTHING': return {};
    default:
      return state;
  }
};
