import React from 'react';
import {
  FormControl, MenuItem, Select, InputLabel
} from '@mui/material';
import BootstrapInput from 'components/shared/forms/inputs/bootstrap';
import Arrow from '@mui/icons-material/KeyboardArrowDown';
import useStyles from './input-styles';

const SelectInput = ({
  id,
  label,
  options,
  value,
  onChange
}) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" fullWidth>
      {label
        && (
        <InputLabel
          id={id}
          htmlFor={id}
          style={{ left: '-10px', marginTop: '15px', position: 'relative' }}
          shrink
        >
          {label}
        </InputLabel>
        )}
      <Select
        variant="standard"
        labelId={id}
        value={value}
        onChange={e => onChange(id, e.target.value)}
        input={<BootstrapInput />}
        IconComponent={() => (<Arrow className={classes.icon} />)}
        style={{ marginTop: 0 }}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>{option.label || option.value}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
