import React from 'react';
import {
  Container, Grid, Box, Typography, Paper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Page from 'components/pages/page';
import { ClassNameMap } from '@mui/styles';
import {
  Fingerprint as FingerprintIcon,
  Business as BusinessIcon,
  CloudUpload as CloudUploadIcon,
  NotificationImportant as NotificationImportantIcon
} from '@mui/icons-material';
import clsx from 'clsx';
import { getRefreshedToken } from 'apis/auth';
import useStyles from './staffPage-styles';

const StaffPage = (): JSX.Element => {
  const classes: ClassNameMap = useStyles();

  return (
    <Page>
      <Grid container className={classes.pageWrapper}>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <Typography variant="h1" gutterBottom className={classes.sectionHeader}>Support Tools</Typography>
            <Paper className={classes.panel}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Link to="/staff/organisations" className={classes.link}>
                    <Box className={classes.settingsButton}>
                      <Box className={classes.iconWrapper}>
                        <BusinessIcon classes={{ root: clsx(classes.icon) }} />
                      </Box>
                      <Box className={classes.details}>
                        <Typography variant="body1">Organisations</Typography>
                        <Typography variant="body1">Manage all organisations</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Link to="/staff/provisioning" className={classes.link}>
                    <Box className={classes.settingsButton}>
                      <Box className={classes.iconWrapper}>
                        <CloudUploadIcon classes={{ root: clsx(classes.icon) }} />
                      </Box>
                      <Box className={classes.details}>
                        <Typography variant="body1">Provisioning</Typography>
                        <Typography variant="body1">Programatic device creation</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Link to="/staff/distress-incidents" className={classes.link}>
                    <Box className={classes.settingsButton}>
                      <Box className={classes.iconWrapper}>
                        <NotificationImportantIcon fontSize="large" classes={{ root: clsx(classes.icon) }} />
                      </Box>
                      <Box className={classes.details}>
                        <Typography variant="body1">Active Incidents</Typography>
                        <Typography variant="body1">All currently open distress and AMS incidents</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
                {
                // <Grid item xs={12} sm={4}>
                //   <Link to="/staff/asset-search" className={classes.link}>
                //     <Box className={classes.settingsButton}>
                //       <Box className={classes.iconWrapper}>
                //         <SearchIcon classes={{ root: clsx(classes.icon) }} />
                //       </Box>
                //       <Box className={classes.details}>
                //         <Typography variant="body1">Asset Search</Typography>
                //         <Typography variant="body1">Global asset search</Typography>
                //       </Box>
                //     </Box>
                //   </Link>
                // </Grid>
                //
                }
              </Grid>
            </Paper>
          </Container>

          {
          // <Container maxWidth="md">
          //   <Typography variant="h1" gutterBottom className={classes.sectionHeader}>Billing</Typography>
          //   <Paper className={classes.panel}>
          //     <Grid container spacing={3}>
          //       <Grid item xs={12} sm={4}>
          //         <Link to="/staff/billing/iridium-usage" className={classes.link}>
          //           <Box className={classes.settingsButton}>
          //             <Box className={classes.iconWrapper}>
          //               <RouterIcon classes={{ root: clsx(classes.icon) }} />
          //             </Box>
          //             <Box className={classes.details}>
          //               <Typography variant="body1">Iridium Usage</Typography>
          //               <Typography variant="body1">Look up iridium usage by Org or IMEI</Typography>
          //             </Box>
          //           </Box>
          //         </Link>
          //       </Grid>
          //       <Grid item xs={12} sm={4}>
          //         <Link to="/staff/billing/plansAddons" className={classes.link}>
          //           <Box className={classes.settingsButton}>
          //             <Box className={classes.iconWrapper}>
          //               <CollectionsBookmarkIcon classes={{ root: clsx(classes.icon) }} />
          //             </Box>
          //             <Box className={classes.details}>
          //               <Typography variant="body1">Plans and Addons</Typography>
          //               <Typography variant="body1">Manage device plans and addons</Typography>
          //             </Box>
          //           </Box>
          //         </Link>
          //       </Grid>
          //     </Grid>
          //   </Paper>
          // </Container>
          }

          <Container maxWidth="md">
            <Typography variant="h1" gutterBottom className={classes.sectionHeader}>Development</Typography>
            <Paper className={classes.panel}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Box
                    className={classes.settingsButton}
                    onClick={async () => {
                      navigator.clipboard.writeText((await getRefreshedToken()) || '');
                    }}
                  >
                    <Box className={classes.iconWrapper}>
                      <FingerprintIcon classes={{ root: clsx(classes.icon) }} />
                    </Box>
                    <Box className={classes.details}>
                      <Typography variant="body1">Copy Firebase Token</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default StaffPage;
