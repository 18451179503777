import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    width: '100%'
  },
  headingText: {
    fontWeight: 400,
    color: theme.palette.common.text,
    marginBottom: theme.spacing(6)
  },
  colorWrapper: {
    textAlign: 'left'
  }
}));
