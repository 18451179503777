// Lighten or Darken a hex based color
export const lightenDarkenColor = (col, amt) => {
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00FF) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000FF) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

// Convert HEX based color to RGB(A) - useful for apply opacity
export const hexToRGB = (hex, alpha) => {
  if (!hex) return null;
  const cleanHex = hex.replace(/[^0-9A-F]/gi, ''); // strip out # etc
  const r = parseInt(cleanHex.slice(0, 2), 16);
  const g = parseInt(cleanHex.slice(2, 4), 16);
  const b = parseInt(cleanHex.slice(4, 6), 16);
  if (alpha) {
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return `rgb(${r},${g},${b})`;
};

// Convert HEX based color to plain [ R, G, B, (A)] array
export const hexToRGBArray = (hex, alpha) => {
  if (!hex) return null;
  const cleanHex = hex.replace(/[^0-9A-F]/gi, ''); // strip out # etc
  const r = parseInt(cleanHex.slice(0, 2), 16);
  const g = parseInt(cleanHex.slice(2, 4), 16);
  const b = parseInt(cleanHex.slice(4, 6), 16);
  if (alpha) {
    return [r, g, b, (alpha * 255)];
  }
  return [r, g, b];
};

const rgbToHex = rgb => {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = `0${hex}`;
  }
  return hex;
};

// convert [ r, g, b ] array to nice hex value, e.g. #000000
export const RGBArrayToHex = array => {
  if (!array) return '#000000';
  return `#${rgbToHex(array[0])}${rgbToHex(array[1])}${rgbToHex(array[2])}`;
};
