import React from 'react';
import ContentLoader from 'react-content-loader';

const OBResultsLoading = () => (
  <ContentLoader
    viewBox="0 0 340 68"
    speed={2}
    backgroundColor="rgba(0,0,0,.1)"
    foregroundColor="rgba(0,0,0,.2)"
  >
    <circle cx="40" cy="34" r="15" />
    <rect x="65" y="19" rx="4" ry="4" width="144" height="13" />
    <rect x="65" y="38" rx="4" ry="4" width="80" height="8" />
    <rect x="262" y="19" rx="4" ry="4" width="40" height="13" />
  </ContentLoader>
);

export default OBResultsLoading;
