export const toggleOmnibox = () => ({
  type: 'TOGGLE_OMNIBOX'
});

export const toggleContextbox = () => ({
  type: 'TOGGLE_CONTEXTBOX'
});

export const toggleSystemStatus = () => ({
  type: 'TOGGLE_SYSTEM_STATUS'
});

export const toggleAnalysisbox = () => ({
  type: 'TOGGLE_ANALYSISBOX'
});
