import Smart from 'hocs/smart';
import DeviceContextBox from './deviceContextBox-view';

export default Smart(
  DeviceContextBox,
  state => ({
    now: state.app.now
  }),
  ac => ({
    clearSelection: ac.app.clearSelection
  })
);
