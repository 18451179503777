import React, { useState } from 'react';
import {
  Button, Box, Container,
  Dialog, DialogTitle, DialogContent,
  DialogActions, DialogContentText,
  List, ListItem, ListItemText
} from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import useTranslation from 'hooks/useTranslation';
import { MTableAction } from '@material-table/core';
import { Add } from '@mui/icons-material';
import tableIcons from 'components/shared/icons/tableIcons';
import { befriendGroup, fetchGroups, unfriendGroup } from 'apis/trackstar/serenity';
import insensitiveSort from 'utils/insensitiveSort';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { sharePermissions } from 'apis/trackstar/maps';
import PersistentTable from 'components/shared/persistentTable';
import mixpanel from 'mixpanel-browser';
import useStyles from '../friends/friends-styles';

interface GroupsTableParams {
  userPermissions: any;
  displaySnackbar: (Snack: Snack) => void;
}

const GroupsTable = ({
  userPermissions,
  displaySnackbar,
}:GroupsTableParams): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('pages.connections.groups');
  const queryClient = useQueryClient();
  const { canEditFriends } = userPermissions;
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

  const getGroups = useQuery('groups', fetchGroups);
  if (getGroups.isError) displaySnackbar({ id: 'getGroupsFailedSnackbar', text: t('getGroupsFailed'), type: 'error' });

  //
  // Unfriend Group
  //
  const removeGroup = useMutation(publicKey => unfriendGroup(publicKey), {
    onMutate: async (publicKey: string) => {
      await queryClient.cancelQueries('groups');
      // TODO: optimistic update = too hard basket with the awful XML datastructure 😭
    },
    onError: (err, newGroup, context) => {
      displaySnackbar({ id: 'removeGroupError', text: t('removeGroupError'), type: 'error' });
      mixpanel.track('Leave Group', { success: false });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'groupRemoved', text: t('groupRemoved'), type: 'success' });
      mixpanel.track('Leave Group', { success: true });
    },
    onSettled: () => {
      // Fetch latest friends and groups on error or success
      queryClient.invalidateQueries('groups');
      queryClient.invalidateQueries('friends');
    },
    mutationKey: 'removeGroup',
  });
  const handleRemoveGroup = (): void => {
    if (selectedGroup) removeGroup.mutate(selectedGroup.pubkey);
    setGroupDialogOpen(false);
  };

  //
  // Befriend Group
  //
  const addGroup = useMutation(publicKey => befriendGroup(publicKey), {
    onMutate: async (publicKey: string) => {
      await queryClient.cancelQueries('groups');
      // TODO: optimistic update = too hard basket with the awful XML datastructure 😭
    },
    onError: (err, newGroup, context) => {
      displaySnackbar({ id: 'addGroupError', text: t('addGroupError'), type: 'error' });
      mixpanel.track('Join Group', { success: false });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'groupAdded', text: t('groupAdded'), type: 'success' });
      mixpanel.track('Join Group', { success: true });
    },
    onSettled: () => {
      // Fetch latest friends and groups on error or success
      queryClient.invalidateQueries('groups');
      queryClient.invalidateQueries('friends');
    },
    mutationKey: 'removeGroup',
  });
  const handleAddGroup = async (): Promise<any> => {
    if (selectedGroup) addGroup.mutate(selectedGroup.pubkey);
    setGroupDialogOpen(false);
  };

  //
  // Actions
  //
  const handleRowClick = (e: React.MouseEvent | undefined, group: Group | undefined): void => {
    if (!group) return;
    setSelectedGroup(group);
    setGroupDialogOpen(true);
  };

  const maxTableHeight = `${window.innerHeight > 570 ? window.innerHeight - 420 : 150}px`;
  return (
    <Container className={classes.tableContainer} maxWidth="md">
      <Box className={classes.materialTable}>
        <PersistentTable
          settingsCategory="groupsTable"
          title={t('title')}
          onRowClick={handleRowClick}
          icons={tableIcons}
          isLoading={getGroups.isLoading}
          columns={[
            {
              title: t('columns.name'),
              field: 'name',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              width: '32%',
              defaultSort: 'asc',
              customSort: (a: any, b: any) => insensitiveSort(a.name, b.name)
            },
            {
              title: 'Description',
              field: 'subText',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              width: '40%',
            },
            {
              title: 'Region',
              field: 'country',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              render: (rowData: any) => [rowData.state, rowData.country].join(' '),
            },
            {
              title: 'Friends',
              field: 'joined',
              headerStyle: { textAlign: 'right' },
              cellStyle: { textAlign: 'right', paddingRight: 54 },
              render: (rowData: any) => rowData.joined && (<CheckCircle className={classes.groupFriendCheck} />),
            }
          ]}
          data={getGroups.isSuccess ? getGroups.data : []}
          options={{
            draggable: false,
            showTitle: false,
            search: true,
            paging: true,
            pageSizeOptions: [10, 25, 50, 100],
            pageSize: 10,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
            maxBodyHeight: maxTableHeight,
          }}
          localization={{
            header: {
              actions: t('columns.actions')
            }
          }}
          components={canEditFriends ? {
            Action: (props: any) => {
              const { action, data } = props;
              return ((action.position === 'toolbar')
                ? (
                  <Button
                    onClick={event => action.onClick(event, data)}
                    className={classes.addButton}
                    variant="contained"
                  >
                    <Add />
                    {t('addButton')}
                  </Button>
                ) : <MTableAction {...props} />
              );
            }
          } : {}}
        />
      </Box>

      <Dialog open={groupDialogOpen} onClose={() => setGroupDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>{selectedGroup?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogSubtext}>{selectedGroup?.subText}</DialogContentText>
          <DialogContentText className={classes.dialogDesc}>{selectedGroup?.description}</DialogContentText>
          <DialogContentText className={classes.dialogHeading}>Group Members</DialogContentText>
          <List style={{
            width: '100%',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
          }}
          >
            {selectedGroup?.groupMembers?.groupMember?.map(gm => (
              <ListItem style={{ padding: '0 0 0 20px' }} key={gm.id}><ListItemText>{gm.name}</ListItemText></ListItem>
            ))}
          </List>
          <DialogContentText className={classes.dialogHeading}>Minimum Permissions Required</DialogContentText>
          <List>
            {selectedGroup?.minimumPermissions.split('').map(p => (
              <ListItem style={{ padding: '0 0 0 20px' }} key={p}>
                <ListItemText>{sharePermissions[p]}</ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" className={classes.inputButton} onClick={() => setGroupDialogOpen(false)}>{t('cancel')}</Button>
          {getGroups.isSuccess && selectedGroup && getGroups.data.filter(g => g.joined).map(g => g.id).includes(selectedGroup.id)
            ? <Button disabled={!selectedGroup} variant="contained" className={classes.inputButton} onClick={handleRemoveGroup}>{t('unfriendGroup')}</Button>
            : <Button disabled={!selectedGroup} variant="contained" className={classes.inputButton} onClick={handleAddGroup}>{t('befriendGroup')}</Button>}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GroupsTable;
