import {
  Box,
  Grid, List, ListItem, Typography
} from '@mui/material';
import React from 'react';
import { DateTime } from 'luxon';
import useStyles from './provisioningList-styles';

const highlightDates = (instr: string): (JSX.Element | string)[] | string => {
  if (!instr) return instr;
  const highlightRegex = /\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}/g;
  const matches = [...instr.matchAll(highlightRegex)];
  if (!matches.length) return instr;
  const sections: (JSX.Element | string)[] = [];
  let previousIndex = 0;
  matches.forEach((match, i, arr) => {
    if (previousIndex !== match.index) {
      sections.push(instr.slice(previousIndex, match.index));
    }
    previousIndex = (match.index || 0) + match[0].length;
    sections.push(
      <>
        {i !== 0 && <hr />}
        <strong>{instr.slice(match.index, previousIndex)}</strong>
      </>
    );
  });
  sections.push(instr.slice(previousIndex));
  return sections;
};

export const DetailPanel = ({ row }: { row: ProvisioningProcessorRow }): JSX.Element => {
  const classes = useStyles();
  const fields = [
    ['Asset ID', row.assetId],
    ['Device ID', row.deviceId],
    ['IMEI', row.deviceImei],
    ['Iridium Plan', row.deviceIridiumPlan],
    ['Gateway', row.deviceGateway],
    ['IMSI/ICCID', row.deviceSimIccid],
    ['Iridium Contract', row.iridiumContractId],
    ['Script', row.deviceScriptFirmware],
    ['Processing Row Created', DateTime.fromISO(row.auditCreated).toFormat('yyyy/MM/dd hh:mm:ss')],
    ['Processing Row Updated', DateTime.fromISO(row.auditUpdated).toFormat('yyyy/MM/dd hh:mm:ss')],
    ['Created by', row.auditUser]
  ];
  return (
    <Grid container className={classes.gridContainer}>
      <Grid item sm={12}>
        <Typography className={classes.detailPanelTitle}>More information</Typography>
        <List>
          {fields.map(([n, v]) => (
            <ListItem key={n} className={classes.listItem}>
              <Typography className={classes.label}>
                {n}
                :
                {' '}
              </Typography>
              <Typography>{v}</Typography>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item>
        <Typography className={classes.detailPanelTitle}>Errors/Warnings</Typography>
        {(row.errors?.length || row.warnings?.length)
          ? (
            <Box className={classes.errors}>
              <Typography>
                {row.errors}
                {row.warnings}
              </Typography>
            </Box>
          ) : ''}
      </Grid>
    </Grid>
  );
};
