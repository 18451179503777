import React from 'react';
import ReactDOMServer from 'react-dom/server';
import EventIcon from 'components/shared/icons/eventIcons';

import { CompositeLayer } from 'deck.gl';
import { TRAILS_OPTIONS } from 'constants/trailsoptions';

import { labelToIconType } from 'helpers/events';
import ColouredIconLayer from './colouredIconLayer';

const ICONSIZE = 128;

// takes icons and renders them 3 times so they show on maps zoomed out to show more than a world width
// Would like to conditionally render these, but cant find bounds correctly see https://github.com/uber/deck.gl/issues/4060=
const triplicate = (icons => icons.flatMap(icon => [
  { ...icon, longitude: icon.longitude - 360 },
  icon,
  { ...icon, longitude: icon.longitude + 360 }
]));

class EventsLayer extends CompositeLayer {
  updateState({ props, changeFlags }) {
    if (changeFlags.dataChanged) {
      const events = triplicate(props.data?.events?.filter(r => r.isValid && r.events.length > 0)?.map(r => {
        const iconUrl = `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<EventIcon type={r.events[0]} size={64} />))}`;

        return {
          name: r.events[0],
          properties: {
            id: r.id,
            report: r,
            color: props.data.color,
            icon: {
              url: iconUrl,
              height: ICONSIZE,
              width: ICONSIZE,
              id: r.events[0],
              anchorX: ICONSIZE / 2,
              anchorY: ICONSIZE / 2,
              mask: false
            },
            size: (r.id === props.data.highlightedReportId) ? 48 : 28
          },
          latitude: r.latitude,
          longitude: r.longitude,
          object: {
            hi: true
          }
        };
      }) || []);

      this.setState({ events });
    }
  }

  renderLayers() {
    return [
      new ColouredIconLayer({
        key: 'events-layer',
        id: `events-layer-${this.props.id}`,
        data: this.state.events,
        getIcon: d => d.properties.icon,
        sizeScale: 0.75,
        getPosition: d => [d.longitude, d.latitude],
        getSize: d => d.properties.size,
        getColor: d => d.properties.color,
        getAngle: 0,
        parameters: { depthTest: false },
        transitions: { getSize: 225 },
        pickable: false,
        visible: this.props.trailsOption !== TRAILS_OPTIONS.noTrails
      })
    ];
  }
}

export default EventsLayer;
