import makeStyles from '@mui/styles/makeStyles';

let contextboxWidth = 280;

const width = window.innerWidth;
if (width > 1440) {
  contextboxWidth = 300;
}

export default makeStyles(theme => ({
  contextboxWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: contextboxWidth,
    flex: '1 1 0%',
    zIndex: 3,
    transition: 'all 0.05s'
  },
  contextbox: {
    height: '100%',
    backgroundColor: theme.palette.contextbox.background,
    boxShadow: 'rgba(0, 0, 0, 0.1) -12px 0px 12px -12px',
    zIndex: 3,
  },
  collapsed: {
    maxWidth: '0px!important',
    minWidth: '0px!important'
  },
  contentWrapper: {
    height: '100%',
    overflowY: 'auto'
  },
  loaderWrapper: {
    backgroundColor: theme.palette.contextbox.background,
    height: '100%',
    width: '300px',
    boxShadow: 'rgba(0, 0, 0, 0.1) -12px 0px 12px -12px',
    zIndex: 3,
  },
  collapseButton: {
    position: 'absolute',
    display: 'flex',
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 4,
    paddingLeft: 6,
    top: 16,
    left: -26,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,.4)',
    zIndex: 1,
    transition: 'all 0.05s',
    transform: 'translateX(16px)',
    '&:hover': {
      transform: 'translateX(0px)',
    }
  },
  icon: {
    transition: 'all 0.05s'
  },
  iconReversed: {
    transform: 'rotate(180deg)'
  },
  noResults: {
    marginTop: theme.spacing(4),
    color: theme.palette.common.text,
    '& p:first-child': {
      fontSize: '1rem',
      fontWeight: '500'
    },
    '& p:last-child': {
      fontWeight: '400',
      fontSize: '.9rem'
    }
  }
}));
