import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  divider: {
    margin: theme.spacing(4, 0),
    borderColor: theme.border.color
  },
  categoryDescription: {
    fontSize: '.9rem',
    lineHeight: '1.8'
  },
  fieldsPanel: {
    padding: theme.spacing(3, 4),
    boxShadow: 'none',
    border: theme.border.default
  },
  subFieldsWrapper: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(0)
  },
  pulseColor: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    justifyContent: 'center'
  }
}));
