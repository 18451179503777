import React from 'react';
import { useCoordinates } from 'hooks/settings';
import useStyles from './coordinate-styles';

const Coordinate = ({
  latitude,
  longitude,
  Component = 'p'
}) => {
  const latLng = useCoordinates(latitude, longitude);
  const classes = useStyles();

  return (
    <Component className={classes.coordinate}>{latLng}</Component>
  );
};

export default Coordinate;
