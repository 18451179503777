import Smart from 'hocs/smart';
import View from './kmlLoader-view';

export default Smart(
  View,
  () => ({
  }),
  ac => ({
    setCustomLayerKml: ac.map.setCustomLayerKml
  })
);
