import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Tooltip,
  SvgIcon
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useStyles from '../actionButtons-styles';

const JumpToLatestActivity = ({
  isActive,
  onClick
}) => {
  const classes = useStyles();
  const t = useTranslation('contextbox.asset.activity.actionButtons');

  return (
    <Box className={classes.iconWrapper}>
      <Tooltip title={t('filterTooltip')}>
        <SvgIcon
          className={clsx(classes.icon, { [classes.activeIcon]: isActive })}
          width="12"
          height="12"
          viewBox="0 0 12 12"
          onClick={onClick}
          fontSize="small"
        >
          <path d="M11.941.264A.5.5,0,0,0,11.5,0H.5A.5.5,0,0,0,.084.777L4,6.651V11.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V6.651L11.916.777A.5.5,0,0,0,11.941.264Z" />
        </SvgIcon>
      </Tooltip>
    </Box>
  );
};

export default JumpToLatestActivity;
