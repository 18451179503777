import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  colors: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    margin: '1px 5px 1px 6px',
    padding: '5px',
    width: '90px',
    height: '36px',
    border: theme.border.prettyGridLight,
    borderRadius: '3px',
    position: 'relative',
    bottom: 3
  },
  wrapper: {
    marginBottom: '8px'
  },
  swatchWrap: {
    width: '12px',
    height: '35px',
    margin: '1px 3px 0 3px',
    border: theme.border.color
  },
  title: {
    margin: '2px 10px 5px 10px',
    fontSize: '11px',
    fontWeight: '400',
    textAlign: 'right',
    color: theme.palette.common.black
  }
}));

export const styles = {
  // Styles for passing to Swatch
  swatch: {
    borderRadius: '2px',
    boxShadow: 'inset 0 0 0 1px rgba(0,0,0,.15)'
  }
};
