import React from 'react';
import {
  Divider,
} from '@mui/material';
import Category from 'components/shared/forms/category';
import useStyles from './settingsDetail-style';

const SettingsDetail = ({
  settingsTemplate,
  settings,
  updateSetting,
  previousColors,
  setPreviousColors
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {settingsTemplate.map(category => (
        <Category
          key={category.id}
          id={category.id}
          title={category.label}
          description={category.description}
          fields={category.fields}
          values={settings}
          update={updateSetting}
          previousColors={previousColors}
          setPreviousColors={setPreviousColors}
        />
      ))}
      <Divider className={classes.divider}/>
    </React.Fragment>
  );
};

export default SettingsDetail;
