import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  datePicker: {
    flex: 1,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    backgroundColor: theme.palette.omnibox.overlay,
    borderRadius: 30,
    border: 'none',
    transition: 'all 0.15s',

    '& .MuiInputBase-root': {
      height: 44,
      paddingRight: theme.spacing(5 / 3),
    },
    '& input': {
      fontSize: '1rem',
      color: theme.palette.common.whiteConstant,
      padding: 0,
      marginLeft: theme.spacing(3),
    },
    '& button': {
      color: theme.palette.common.whiteConstant
    },
    '& fieldset': {
      border: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.omnibox.overlayHover
    }
  },
  historyWrapper: {
    position: 'relative',
    display: 'flex',
    flex: 1,
  },
  historyButton: {
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    textAlign: 'left',
    fontSize: '1rem',
    color: theme.palette.common.whiteConstant,
    backgroundColor: theme.palette.omnibox.overlay,
    height: 44,
    borderRadius: 30,
    zIndex: 0,
    transition: 'all 0.15s',
    '& .MuiSvgIcon-root': {
      marginLeft: theme.spacing(2),
    },
    '&:hover': {
      backgroundColor: theme.palette.omnibox.overlayHover
    },
  },
  visible: {
    zIndex: 1
  },
  hidden: {
    display: 'none'
  },
  activeDay: {
    borderBottomColor: theme.palette.primary.activeGreen,
    borderBottomStyle: 'solid',
    borderBottomWidth: '3px',
    width: 0,
    position: 'absolute',
    transition: 'width 200ms',
    bottom: 5
  },
  calIcon: {
    marginRight: theme.spacing(2)
  },
  infoSnackbar: {
    backgroundColor: theme.palette.primary.main
  },
  icon: {
    fontSize: '1.6rem',
    marginRight: theme.spacing(2)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));
