import React from 'react';
import { MTableAction } from '@material-table/core';
import {
  Box,
  Button,
  Select, MenuItem,
  Container,
  Tooltip,
} from '@mui/material';
import {
  Add, Delete, Edit, People
} from '@mui/icons-material';
import tableIcons from 'components/shared/icons/tableIcons';
import useTranslation from 'hooks/useTranslation';
import insensitiveSort from 'utils/insensitiveSort';
import clsx from 'clsx';
import { sharePermissions } from 'apis/trackstar/maps';
import useStyles from '../simpleSharing-styles';
import PersistentTable from '../../../shared/persistentTable';

const SimpleShareTable = ({
  loading,
  mode,
  shares,
  allowCreateShare,
  allowEditShare,
  serialType,
  editShare,
  deleteShare,
  createShare,
  updateSetting,
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.simpleSharing');
  const maxTableHeight = `${window.innerHeight > 570 ? window.innerHeight - 420 : 150}px`;
  const serialTypeMap = {
    tpSerial: 'deviceTpSerial',
    imei: 'deviceImei',
    manufacturerSerial: 'deviceManufacturerSerial',
  };
  const serialTypes = ['tpSerial', 'imei', 'manufacturerSerial'];
  const devSerialType = serialTypeMap[serialType];

  //
  // Table Actions
  //
  const createAction = {
    icon: () => <Add className={classes.actionButton} />,
    tooltip: t('newButton'),
    isFreeAction: true,
    onClick: createShare,
  };
  const editAction = rowData => ({
    icon: () => <Edit className={classes.actionButton} />,
    tooltip: t('editShare'),
    onClick: (e, row) => editShare(row),
    disabled: !allowEditShare,
    hidden: !allowEditShare,
  });
  const deleteAction = rowData => ({
    icon: () => <Delete className={classes.actionButton} />,
    tooltip: t('deleteShare'),
    onClick: (e, row) => deleteShare(row),
    disabled: !allowEditShare,
    hidden: !allowEditShare,
  });

  //
  // Custom Create Share Button
  //
  const createShareButton = {
    Action: props => {
      const { action, data } = props;
      return ((action.position === 'toolbar')
        ? (
          <Button
            onClick={event => action.onClick(event, data)}
            className={classes.addButton}
            variant="contained"
          >
            <Add />
            {t('addButton')}
          </Button>
        ) : <MTableAction {...props} />
      );
    }
  };

  return (
    <Container className={classes.tableContainer} maxWidth="md">
      <Box className={classes.materialTable}>
        <Select
          variant="standard"
          id="serialType"
          value={serialType}
          displayEmpty
          label={t('language')}
          onChange={e => updateSetting('ui', 'serialType', e.target.value)}
          className={classes.select}
        >
          { serialTypes.map(st => <MenuItem key={st} value={st}>{t(serialTypeMap[st])}</MenuItem>) }
        </Select>
        <PersistentTable
          settingsCategory={`${mode}Table`}
          isLoading={loading}
          icons={tableIcons}
          columns={[
            {
              title: t('name'),
              field: 'assetName',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a, b) => insensitiveSort(a.assetName, b.assetName)
            },
            {
              title: t('makeModel'),
              field: 'assetMakeModel',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              customSort: (a, b) => insensitiveSort(a.assetMakeModel, b.assetMakeModel)
            },
            {
              title: t('installation'),
              field: 'deviceMakeModel',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a, b) => insensitiveSort(a.deviceMakeModel, b.deviceMakeModel)
            },
            {
              title: t(devSerialType),
              field: devSerialType,
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a, b) => insensitiveSort(a[devSerialType].toString(), b[devSerialType].toString())
            },
            {
              title: mode === 'sharedByMe' ? t('sharedWith') : t('owner'),
              field: mode === 'sharedByMe' ? 'sharee' : 'sharer',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              customSort: mode === 'sharedByMe'
                ? (a, b) => insensitiveSort(a.sharee, b.sharee)
                : (a, b) => insensitiveSort(a.sharer, b.sharer),
              render: r => (
                r.group ? (
                  mode === 'sharedByMe' ? (
                    <p>
                      <Tooltip title={t('group')}>
                        <People className={classes.inlineIcon} />
                      </Tooltip>
                      {` ${r.group}`}
                    </p>
                  ) : `${r.sharer} ${t('via')} ${r.group}`
                ) : (mode === 'sharedByMe' ? r.sharee : r.sharer))
            },
            {
              title: t('access'),
              field: 'accessLevel',
              /* eslint-disable react/no-array-index-key */
              render: rowData => (
                <Tooltip title={rowData.sharePermissionsList} placement="bottom">
                  <Box>
                    {[...Array(rowData.accessLevel)].map((e, i) => (
                      <Box key={i} className={clsx(classes.accessLevelBar)} />
                    ))}
                    {[...Array(5 - rowData.accessLevel)].map((e, i) => (
                      <Box key={i} className={clsx([classes.accessLevelBar, classes.accessGrey])} />
                    ))}
                  </Box>
                </Tooltip>
              ),
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
            }
          ]}
          data={shares.map(s => ({
            ...s,
            // exclude unused 'view media' (m) and 'poll current position' (p) permissions
            accessLevel: s.access.replace(/[mp]/gi, '').length,
            sharePermissionsList: s.access.split('').map(p => sharePermissions[p]).filter(p => !!p).join(', '),
            deviceMakeModel: [s.deviceMake, s.deviceModel].filter(Boolean).join(' '),
            assetMakeModel: [s.assetMake, s.assetModel].filter(Boolean).join(' '),
          }))}
          actions={allowEditShare && mode === 'sharedByMe' ? [createAction, editAction, deleteAction] : []}
          options={{
            draggable: false,
            showTitle: false,
            search: true,
            paging: true,
            pageSizeOptions: [10, 25, 50, 100],
            pageSize: 10,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: maxTableHeight,
          }}
          localization={{
            header: {
              actions: t('actions')
            },
            toolbar: {
              searchPlaceholder: t('search')
            }
          }}
          components={allowCreateShare && mode === 'sharedByMe' ? createShareButton : {}}
        />
      </Box>
    </Container>
  );
};

export default SimpleShareTable;
