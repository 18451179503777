import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  categoriesWrapper: {
    padding: theme.spacing(8, 0),
    textAlign: 'left'
  },
  link: {
    textDecoration: 'none'
  },
  linkText: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
  divider: {
    margin: theme.spacing(4, 0),
    borderColor: theme.border.color
  },
  category: {
    marginTop: theme.spacing(4)
  },
  container: {
    textAlign: 'left'
  },
  fieldsPanel: {
    padding: theme.spacing(3, 4),
    boxShadow: 'none',
    border: theme.border.default
  },
  formPanel: {
    padding: theme.spacing(3, 4),
    boxShadow: 'none',
    border: theme.border.default,
    height: '100%',
  },
  panel: {
    textAlign: 'left',
    boxShadow: 'none',
    border: theme.border.default,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  inputContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(3, 5)
  },
  inputButton: {
    padding: theme.spacing(2, 6),
    width: '100%',
    height: theme.spacing(8),
    fontSize: '1rem'
  },
  textField: {
    '& > div': {
      backgroundColor: theme.palette.common.lightGrey
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  detailTitle: {
    marginTop: theme.spacing(2)
  },
  list: {
    paddingTop: theme.spacing(0),
    marginBottom: theme.spacing(4)
  },
  listItem: {
    padding: theme.spacing(0, 4)
  },
  listItemText: {
    margin: theme.spacing(1),
    display: 'list-item',
    listStyleType: 'disc',
    color: theme.palette.common.text
  },
  selectedText: {
    color: theme.palette.common.black,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight
  },
  select: {
    width: '100%',
  },
}));
