import Smart from 'hocs/smart';
import DatePickerView from './datePicker-view';

export default Smart(
  DatePickerView,
  state => ({
    now: state.app.now,
    timezone: state.settings.locale.timezone,
    historicSnackbar: state.app.snackbars.historicSnackbarMessage,
    selectedAsset: state.app.selectedItem,
    organisationId: state.session.organisationId
  }),
  ac => ({
    updateNow: ac.app.setNow,
    displaySnackbar: ac.app.displaySnackbar,
  })
);
