import React, { useState, useEffect } from 'react';
import useTranslation from 'hooks/useTranslation';
import {
  Box, Button,
  Grid,
  Paper, TextField,
  Typography
} from '@mui/material';
import GroupedSelectInput from 'components/shared/forms/inputs/grouped-select';
import TextInput from 'components/shared/forms/inputs/text';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { fetchAssetsDevices } from 'apis/trackstar/serenity';
import Autocomplete from '@mui/material/Autocomplete';
import useStyles from './asset-styles';

const AssetDetails = ({
  organisationId,
  localAsset,
  readOnly,
  categories,
  saveAssetDetails,
}) => {
  const t = useTranslation('pages.assetView');
  const classes = useStyles();

  const getAssets = useQuery(['assets', organisationId, null], () => fetchAssetsDevices(), {
    select: data => data.assets,
  });

  const groupedCategories = () => categories.filter(c => c?.id !== 'System').reduce((acc, obj) => {
    const key = obj.group;
    if (!acc[key]) {
      acc[key] = {
        name: key.charAt(0).toUpperCase() + key.slice(1), // capitalize group name
        children: []
      };
    }
    acc[key].children.push(obj);
    acc[key].children.sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    return acc;
  }, {});

  const [category, setCategory] = useState(localAsset.category);
  const [assetDetails, setAssetDetails] = useState({
    name: localAsset.name || '',
    make: localAsset.make || '',
    model: localAsset.model || '',
    variant: localAsset.variant || '',
    messagingHandle: localAsset.messagingHandle || '',
    watchlistGroup: localAsset.watchlistGroup || '',
  });
  useEffect(() => {
    setAssetDetails({
      name: localAsset.name || '',
      make: localAsset.make || '',
      model: localAsset.model || '',
      variant: localAsset.variant || '',
      messagingHandle: localAsset.messagingHandle || '',
      watchlistGroup: localAsset.watchlistGroup || '',
    });
  }, [localAsset]);

  const save = e => {
    e.preventDefault();
    saveAssetDetails({
      ...assetDetails,
      category: categories.find(c => c.label === category),
    });
  };

  return (
    <Box id="general" className={classes.category}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <Typography variant="h5" gutterBottom>{t('generalTitle')}</Typography>
          <Typography variant="body1">{t('generalDescription')}</Typography>
        </Grid>
        <Grid item sm={12} md={8}>
          <Paper className={classes.panel}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={12}>
                <TextInput
                  id="name"
                  label={t('name')}
                  value={assetDetails.name}
                  onChange={(key, value) => setAssetDetails({ ...assetDetails, [key]: value })}
                  disabled={readOnly}
                  maxLength="50"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <GroupedSelectInput
                  id="category"
                  label={t('category')}
                  options={groupedCategories()}
                  value={category}
                  onChange={(key, value) => setCategory(value)}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <Autocomplete
                  freeSolo
                  variant="outlined"
                  options={!getAssets.isSuccess ? [] : getAssets.data
                    .map(a => a.make?.toString() || '') // get makes of all assets
                    .filter((asset, index, array) => asset && array.indexOf(asset) === index) // filter undefined & duplicates
                    .sort((a, b) => a?.localeCompare(b))} // sort alphabetical
                  getOptionLabel={option => option}
                  id="make"
                  label={t('make')}
                  value={assetDetails.make}
                  onChange={(key, value) => setAssetDetails({ ...assetDetails, make: value })}
                  onInputChange={(key, value) => setAssetDetails({ ...assetDetails, make: value })}
                  renderInput={params => <TextField {...params} variant="outlined" label={t('make')} fullWidth />}
                  disabled={readOnly}
                  maxLength="50"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <Autocomplete
                  freeSolo
                  variant="outlined"
                  options={!getAssets.isSuccess ? [] : getAssets.data
                    .map(a => a.model?.toString() || '') // get models of all assets
                    .filter((asset, index, array) => asset && array.indexOf(asset) === index) // filter undefined & duplicates
                    .sort((a, b) => a?.localeCompare(b))} // sort alphabetical
                  getOptionLabel={option => option}
                  id="model"
                  label={t('model')}
                  value={assetDetails.model}
                  onChange={(key, value) => setAssetDetails({ ...assetDetails, model: value })}
                  onInputChange={(key, value) => setAssetDetails({ ...assetDetails, model: value })}
                  renderInput={params => <TextField {...params} variant="outlined" label={t('model')} fullWidth />}
                  disabled={readOnly}
                  maxLength="50"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <Autocomplete
                  freeSolo
                  variant="outlined"
                  options={!getAssets.isSuccess ? [] : getAssets.data
                    .map(a => a.variant?.toString() || '') // get variants of all assets
                    .filter((asset, index, array) => asset && array.indexOf(asset) === index) // filter undefined & duplicates
                    .sort((a, b) => a?.localeCompare(b))} // sort alphabetical
                  getOptionLabel={option => option}
                  id="variant"
                  value={assetDetails.variant}
                  onChange={(key, value) => setAssetDetails({ ...assetDetails, variant: value })}
                  onInputChange={(key, value) => setAssetDetails({ ...assetDetails, variant: value })}
                  renderInput={params => <TextField {...params} variant="outlined" label={t('variant')} fullWidth />}
                  disabled={readOnly}
                  maxLength="50"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextInput
                  id="messagingHandle"
                  label={t('messagingHandle')}
                  value={assetDetails.messagingHandle}
                  onChange={(key, value) => setAssetDetails({ ...assetDetails, [key]: value })}
                  disabled={readOnly}
                  className={clsx([classes.readOnly, classes.helperText])}
                  helperText="@msg.tracplus.com"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <Autocomplete
                  freeSolo
                  id="watchlistGroup-select"
                  variant="outlined"
                  options={!getAssets.isSuccess ? [] : getAssets.data
                    .map(a => a.watchlistGroup?.toString() || '') // get watchlist groups of all assets
                    .filter((asset, index, array) => asset && array.indexOf(asset) === index) // filter undefined & duplicates
                    .sort((a, b) => a.name?.localeCompare(b.name))} // sort alphabetical
                  getOptionLabel={option => option}
                  value={assetDetails.watchlistGroup}
                  disabled={readOnly}
                  onChange={(key, value) => setAssetDetails({ ...assetDetails, watchlistGroup: value })}
                  onInputChange={(key, value) => setAssetDetails({ ...assetDetails, watchlistGroup: value })}
                  renderInput={params => <TextField {...params} variant="outlined" label={t('watchlistGroup')} fullWidth />}
                />
              </Grid>
              {!readOnly && (
                <Grid item sm={12} md={12} className={classes.inputButtonWrapper}>
                  <Button className={classes.inputButton} variant="contained" onClick={save}>{t('save')}</Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetDetails;
