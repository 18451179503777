import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    backgroundColor: 'transparent',
    marginBottom: theme.spacing(2)
  },
  textField: {
    width: '100%'
  },
  searchIcon: {
    color: theme.palette.omnibox.searchText
  },
  omniSearch: {
    borderRadius: '30px',
    boxShadow: 'none',
    textAlign: 'left',
    border: theme.palette.omnibox.searchBorder,
    backgroundColor: theme.palette.omnibox.searchBackground,
    color: theme.palette.omnibox.searchText,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingRight: theme.spacing(2),
    transition: 'all 0.15s',
    '&:hover': {
      borderWidth: '1px'
    }
  },
  input: {
    color: theme.palette.omnibox.searchText,
    fontWeight: '500',
    flex: 1
  },
  iconDate: {
    color: theme.palette.primary.main,
    textAlign: 'right'
  }
}));
