import Smart from 'hocs/smart';
import GeneralSettingsView from './generalSettings-view';

export default Smart(
  GeneralSettingsView,
  state => ({
    settings: state.settings,
    previousColors: state.settings.map.previousColors
  }),
  ac => ({
    updateSetting: ac.settings.updateSetting,
    setPreviousColors: ac.settings.setPreviousColors
  })
);
