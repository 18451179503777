import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import DevicesList from './devicesList-view';

export default Smart(
  DevicesList,
  state => ({
    permissions: permissionsForCurrentUserAndOrg(state),
    organisationId: state.session.organisationId,
    tableSettings: state.settings.devicesTable,
    serialType: state.settings.ui.serialType,
    rowsPerPage: state.settings.ui.rowsPerPage,
  }),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar,
    updateSetting: ac.settings.updateSetting,
  })
);
