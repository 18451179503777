import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import SimpleShareTable from './simpleShareTable-view';

export default Smart(
  SimpleShareTable,
  state => ({
    serialType: state.settings.ui.serialType,
  }),
  ac => ({
    updateSetting: ac.settings.updateSetting,
  })
);
