import React, { useState, useRef, useEffect } from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import Arrow from '@mui/icons-material/KeyboardArrowDown';
import useStyles from './input-styles';

const SelectInput = ({
  id,
  label,
  options,
  value,
  onChange,
  disabled
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.field} variant="outlined" fullWidth>
      {label
        && (
        <InputLabel
          className={classes.label}
          htmlFor={id}
          shrink
        >
          {label}
        </InputLabel>
        )}
      <Select
        variant="outlined"
        native
        value={value}
        disabled={disabled}
        onChange={e => onChange(id, e.target.value)}
        IconComponent={() => (<Arrow className={classes.icon} />)}
        className={classes.selectInput}
        inputProps={{
          name: 'value',
          id: label
        }}
        label={label}
      >
        {options.map(option => (
          <option key={option.id} value={option.id}>{option.label || option.value}</option>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
