import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import DisplayModal from 'components/shared/displayModal';
import ServiceWidget from './serviceWidget';
import useStyles from './systemStatus-styles';

const SystemStatusDialog = ({
  isOpen,
  toggleModal
}) => {
  const classes = useStyles();
  const t = useTranslation('statusbar.systemStatus');
  const health = {
    connection: 'GREEN',
    messages: [],
    services: [{
      name: 'TracPlus Cloud',
      status: 'GREEN',
    }]
  };

  // TODO: translate messages ?  perhaps we need to limit to predefined messages and pass message ids instead of text content ?
  return (
    <DisplayModal
      open={isOpen}
      setOpen={toggleModal}
      modalHeader={t('title')}
      shouldCloseOnOverlayClick
    >
      {health.messages && (
        <Box>
          <List className={classes.messages}>
            {health.messages?.map(message => (
              <ListItem className={classes.message} key={message}>
                <Typography variant="body1">{message}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <Box>
        <List className={classes.servicesList}>
          {health.services?.map(service => (
            <ServiceWidget key={service.name} className={classes.serviceListItem} service={service} />
          ))}
        </List>
      </Box>

      {/* eslint-disable-next-line no-undef */}
      <Typography gutterBottom>{`TracPlus Cloud Version: ${import.meta.env.PACKAGE_VERSION}`}</Typography>
    </DisplayModal>
  );
};

export default SystemStatusDialog;
