import Smart from 'hocs/smart';
import mapTemplates from 'mapTemplates';
import View from './baseLayerPicker-view';

export default Smart(
  View,
  (state, ownProps) => ({
    baseLayerId: state.map.maps[ownProps.mapId].baseLayerId,
    baseLayers: Object.values(mapTemplates).map(template => template(state.settings.locale.language))
  }),
  ac => ({
    setBaseLayer: ac.map.setBaseLayer
  })
);
