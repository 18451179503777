import React, { useState } from 'react';
import {
  Paper, Grid, Button, Box, Typography
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import TextInput from 'components/shared/forms/inputs/text';
import { isValidEmail } from 'utils/strings';
import { useMutation } from 'react-query';
import { ClassNameMap } from '@mui/styles';
import TracplusIcon from 'components/shared/icons/tracplusIcon/tracplusIcon-view';
import { history } from 'store';
import { inviteUserLegacy } from 'apis/trackstar/serenity';
import useStyles from '../organisationSettings/organisationSettings-styles';
import useLoginStyles from '../auth/login/login-styles';

const LegacyInviteUser = ({
  legacyUser,
  displaySnackbar,
}: { legacyUser: any, displaySnackbar: (snack: Snack) => void }): JSX.Element => {
  const t = useTranslation('pages.legacyInviteUser');
  const classes = useStyles();
  const loginClasses: ClassNameMap = useLoginStyles();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const isValid = isValidEmail(email) && name;

  const doInviteUser = useMutation((invite: any) => inviteUserLegacy(invite.email, invite.userName, invite.organisationName), {
    // onMutate: async variables => {},
    onError: (err, variables, context) => {
      displaySnackbar({ id: 'inviteSendFailed', text: t('inviteSendFailed'), type: 'error' });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'inviteSent', text: t('inviteSent'), type: 'success' });
    },
    mutationKey: 'inviteUser',
  });

  const sendUserInvite = () => {
    doInviteUser.mutate({
      organisationName: legacyUser.name,
      userName: name,
      email,
    });
    setName('');
    setEmail('');
  };

  if (!legacyUser?.id) history.push('/login');

  return (
    <Box className={loginClasses.container}>
      <Grid className={loginClasses.gridWrapper} container spacing={0}>
        <Box className={loginClasses.formWrapper}>
          <Box className={loginClasses.iconWrapper}>
            <TracplusIcon overrideStyle={undefined} />
          </Box>
          <Typography variant="h1" className={loginClasses.title}>{t('title')}</Typography>
          <Typography variant="body1" className={loginClasses.subtitle}>{t('paragraph')}</Typography>
          <Box m={3} id="inviteUser">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.formPanel}>
                  <Grid container spacing={3}>
                    <Grid item sm={6}>
                      <TextInput
                        id="email"
                        label={t('emailLabel')}
                        type="email"
                        value={email.toLowerCase()}
                        onChange={(field: string, value: string) => setEmail(value.toLowerCase())}
                        helperText={undefined}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextInput
                        id="name"
                        label={t('nameLabel')}
                        value={name}
                        onChange={(field: string, value: string) => setName(value)}
                        helperText={undefined}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.inputButton}
                        disabled={!isValid}
                        onClick={sendUserInvite}
                      >
                        {t('inviteButton')}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="body1" className={loginClasses.subtitle}>
            {t('contactPrimaryContact')}
            <br />
            { `${legacyUser?.contactName} ${legacyUser?.email}` }
          </Typography>
        </Box>
        <Grid item xs={12} sm={6}>
          <Box className={loginClasses.imageOfTheWeek} style={{ backgroundImage: 'url(/img/south-pacific-helicopters.png)' }}>
            <Box className={loginClasses.imageOfTheWeekText}>South Pacific Helicopters NZ</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LegacyInviteUser;
