import * as firebaseAuth from 'firebase/auth';

const INVITE_BASE_URL = `${import.meta.env.VITE_FUNCTIONS_BASE_URL}/invites`;

/**
 * Get the bearer token for the current user.
 *
 * @return The user's current bearer token for making API requests.
 */
export function getToken(): Promise<string> {
  const auth: firebaseAuth.Auth = firebaseAuth.getAuth();
  const tokenPromise: Promise<string> = new Promise((resolve, reject) => {
    firebaseAuth.onAuthStateChanged(auth, user => {
      if (user) {
        const token: Promise<string> = firebaseAuth.getIdToken(user, false);
        resolve(token);
      }
      const error: Error = new Error('No user is signed in to get a token');
      reject(error);
    });
  });
  return tokenPromise;
}

/**
 * Get token call that refreshes when it's out of date (for regular use in API requests)
 *
 * This differs from above because it does not only resolve on authstatechanged.
 *
 * @returns the token, or null if the user does not exist
 */
export const getRefreshedToken = async (): Promise<string | null> => {
  const user = firebaseAuth.getAuth().currentUser;
  if (user) return firebaseAuth.getIdToken(user);
  return getToken(); // Attempt to wait for auth state change ( might not resolve? )
};

/**
 * Get the current user's ID
 */
export const getUID = (): string | null => {
  const user = firebaseAuth.getAuth().currentUser;
  return user?.uid || null;
};

/**
 * LoginResponse contains the authentication server's response to TracPlus
 * cloud, describing the user.
 *
 * @remarks LoginResponse is legacy crud left over from when we used our own
 * authentication server. The response is somewhat redundant: The decoded
 * `token` contains the user ID (subject) and expiry time. In the future we
 * could use the decoded Javascript Web Token Directly.
 *
 * LoginResponse will not encode error conditions. These are handled by promise
 * rejection.
 */
export class LoginResponse {
  /** Authenticated user's ID code. */
  userId: string

  /** Authenticated user's session token. */
  token: string

  /** Expiry time of the user's session token. */
  expiryUtc: Date

  /**
   * Construct a LoginResponse from the user's authentication details.
   *
   * LoginResponse would not normally be used directly. Instead see static
   * methods to construct a LoginResponse from an authentication server's
   * response.
   *
   * @see {@link LoginResponse.FromFirebaseCredential}
   *
   * @param userId The user's ID code.
   * @param token The user's session JWT (Javascript Web Token).
   * @param expiryUtc The user's JWT expiry time.
   */
  constructor(userId: string, token: string, expiryUtc: Date) {
    this.userId = userId;
    this.token = token;
    this.expiryUtc = expiryUtc;
  }

  /**
   * Create a LoginResponse from a Firebase/Google Identity Platform response.
   *
   * @param credential The credential returned by the Firebase auth library.
   * @returns User authentication details based on the Firebase credential.
   */
  static async FromFirebaseCredential(credential: firebaseAuth.UserCredential): Promise<LoginResponse> {
    return credential.user.getIdTokenResult()
      .then((tokenResult: firebaseAuth.IdTokenResult): LoginResponse => {
        const userId = String(tokenResult.claims.sub);
        const jwt: string = tokenResult.token;
        const expiryUtc: Date = new Date(tokenResult.expirationTime);
        return new LoginResponse(userId, jwt, expiryUtc);
      }).catch(Promise.reject);
  }
}

/**
 * Interface to store details returned from an invite verification, for
 * displaying on signup.
 */
export interface InviteDetails {
  OrgID?: string
  Name?: string
  Email?: string
  Role?: string
  Verified: boolean
}

const inviteDetailsFromFunctionsResponse = (input: FunctionsInviteResponse): InviteDetails => (
  {
    Email: input.invite?.email,
    Name: input.invite?.name,
    OrgID: input.invite?.organisationID,
    Role: input.invite?.role,
    Verified: input.success
  }
);

interface FunctionsInvite {
  accepted: boolean
  cancelled: boolean
  email: string
  name: string
  organisationID: string
  organisationName: string
  role: string
}
interface FunctionsInviteResponse {
  invite: FunctionsInvite
  message: string
  success: boolean
}

/**
 * Login in a TPC account using their email address and password.
 *
 * `login` gets a login token from Firebase
 * @param email Email address of the user to log in as.
 * @param password The user's (plaintext) password.
 * @param rememberMe true = don't log out when session ends, false = do
 * @returns The user's authenticated details, or an error.
 */
export async function login(email: string, password: string, rememberMe: boolean): Promise<LoginResponse> {
  const auth: firebaseAuth.Auth = firebaseAuth.getAuth();
  await auth.setPersistence(rememberMe ? firebaseAuth.browserLocalPersistence : firebaseAuth.browserSessionPersistence);
  return firebaseAuth.signInWithEmailAndPassword(auth, email, password)
    .then(LoginResponse.FromFirebaseCredential);
}

/**
 * Login using a firebase oAuth provider using firebase's provider service
 *
 * The function will redirect to the provider Login page, and once back, the result
 * can be retreived with `getRedirectResult` or `getRedirectResultLogin`
 *
 * @param providerURL url for provider e.g. 'microsoft.com'
 */
export function loginWithSSO(providerURL: string): Promise<void> {
  const auth: firebaseAuth.Auth = firebaseAuth.getAuth();
  const provider: firebaseAuth.OAuthProvider = new firebaseAuth.OAuthProvider(providerURL);
  return firebaseAuth.signInWithRedirect(auth, provider)
    .catch(e => Promise.reject(e));
}

/**
 * A wrapper for firebase/auth getRedirectResult that waits for the redirect
 * result to be ready
 *
 * @remarks I have no idea why this isn't handled by firebase already, I've
 * seen people using 3s setTimeouts to solve this problem! If the user hasn't
 * properly been signed out before proceeding, onAuthStateChange will trigger
 * with a null user, and this will fail.
 *
 * @returns Credentials of user that just signed in
 */
async function getRedirectResult(): Promise<firebaseAuth.UserCredential> {
  const auth: firebaseAuth.Auth = firebaseAuth.getAuth();
  return new Promise((resolve, reject) => {
    firebaseAuth.onAuthStateChanged(auth, () => {
      firebaseAuth.getRedirectResult(auth)
        .then(r => {
          if (r !== null) {
            resolve(r);
          }
          reject(Error('User is still null. Try logging out.'));
        });
    });
  });
}

/**
 * Convenience function for when getRedirectResult is needed, with parsing
 * to a LoginResponse. Intended for use on the login page.
 *
 * @returns Login response (`LoginResponse`)
 */
export async function getRedirectResultLogin(): Promise<LoginResponse> {
  return getRedirectResult().then(LoginResponse.FromFirebaseCredential);
}

/**
 * As mentioned above, log out must be deep (removal from local storage)
 * before the onAuthEventChange will trigger when the redirect is resolved.
 *
 * @returns Void promise that resolves if the user successfully logs out
 */
export async function logout(): Promise<void> {
  const auth: firebaseAuth.Auth = firebaseAuth.getAuth();
  return firebaseAuth.signOut(auth);
}

/**
 * Create a new user account, based on a username and password.
 *
 * @remarks signup does not need to be called when creating SSO accounts.
 * Firebase/GIP will create the required entries on SSO sign in.
 *
 * signup does not create any backend records required - this may cause bugs if
 * backend assumes some records are created during signup, as a hangover from
 * the days of operating our own auth server.
 */
export async function signup(name: string, email: string, password: string): Promise<firebaseAuth.UserCredential> {
  const auth: firebaseAuth.Auth = firebaseAuth.getAuth();
  return firebaseAuth.createUserWithEmailAndPassword(auth, email, password)
    .then(userCred => {
      firebaseAuth.updateProfile(userCred.user, {
        displayName: name
      })
        .catch(err => console.error(`could not update user's name ${err}`));
      return userCred;
    })
    .catch(err => {
      if (err.code === firebaseAuth.AuthErrorCodes.EMAIL_EXISTS) {
        return firebaseAuth.signInWithEmailAndPassword(auth, email, password);
      }
      return Promise.reject(Error('Failed signup: could not create user'));
    });
}

/**
 * Verify an invite token with a call to the backend.
 *
 * @param token invite token to be verified
 * @returns A promise for the invite details, or an error if not verified
 */
export async function verifyInvite(token: string): Promise<InviteDetails> {
  const res = await fetch(`${INVITE_BASE_URL}/verify?id=${token}`);
  if (!res.ok) return { Verified: false };
  const resBody = await res.json();
  return inviteDetailsFromFunctionsResponse(resBody as FunctionsInviteResponse);
}

/**
 * Accept invitation, after user creation and invite verification.
 *
 * This adds the user to the organisation, and makes the invite invalid from now on.
 *
 * @param token token of invite to accept/remove
 * @param userCredentials credentials of user to now associate with organisation
 * @returns A promise for the loginresponse, resulting from the newly created user
 */
async function acceptInvite(token: string, userCredentials: firebaseAuth.UserCredential): Promise<LoginResponse> {
  const accepted: boolean = await fetch(`${INVITE_BASE_URL}/accept`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      newUid: userCredentials.user.uid,
      inviteId: token
    })
  })
    .then(r => r.json())
    .then(r => r.success);

  if (accepted) {
    return LoginResponse.FromFirebaseCredential(userCredentials);
  }

  return Promise.reject(Error('Failed signup: could not accept invite'));
}

/**
 * Send off the invite reqeust to cloud functions
 */
interface inviteUserParams {
  orgId: string,
  name: string,
  email: string,
  role: string,
  organisationName: string
}
export async function inviteUser({
  orgId, name, email, role, organisationName
}: inviteUserParams): Promise<boolean> {
  const response: Response = await fetch(`${INVITE_BASE_URL}/invite?org=${orgId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getRefreshedToken()}`
    },
    body: JSON.stringify({
      organisationId: orgId,
      name,
      email,
      role,
      organisationName
    })
  });
  if (!response.ok) throw new Error('Failed to send invite');
  return true;
}

/**
 * The create user process simplifies the train of
 * verify -> create/signup -> accept/remove invite
 *
 * Applies only to email-password invites, SSO tokens will be different.
 *
 * @param name Information from form
 * @param email
 * @param password
 * @param token Token of invite to be verified and accepted with user creation
 *
 * @returns Promise for `LoginResponse`, to be used to subsequently login. User must reauthenticate to get new claims on login
 */
export async function createInvitedUser(name: string, email: string, password: string, token: string): Promise<LoginResponse> {
  return verifyInvite(token)
    .then(invDetails => {
      if (invDetails.Verified) {
        return signup(name, email, password)
          .then(userCredentials => acceptInvite(token, userCredentials));
      }
      return Promise.reject(Error('Failed signup: invite unverified'));
    });
}

/**
 * First stage of the signup process (before redirect to SSO)
 *
 * Second stage is in `finishCreateInvitedMSUser`
 *
 * @param token Token which will be verified before signing in/creating user
 * @param providerURL URL of SSO/OAuth provider (e.g. 'microsoft.com')
 * @returns Promise<void> which resolves when the signup starts (user will be redirected though)
 */
export async function createInvitedSSOUser(token: string, providerURL: string): Promise<void> {
  return verifyInvite(token)
    .then(invDetails => {
      if (invDetails.Verified) {
        return loginWithSSO(providerURL);
      }
      return Promise.reject(Error('Failed signup: invite unverified'));
    });
}

/**
 * The second stage of the signup process, after the user has done SSO.
 *
 * @param token Token which will be used to accept the invite/add to organisation
 * @returns Promise for LoginResponse, which can be used to sign in afterwards
 */
export async function finishCreateInvitedSSOUser(token: string): Promise<LoginResponse> {
  return getRedirectResult()
    .then(userCredentials => {
      if (userCredentials === null) {
        return Promise.reject(Error('No credentials in redirect'));
      }
      return acceptInvite(token, userCredentials);
    })
    .catch(e => Promise.reject(e));
}

/**
 * linkMSEmail is used to resolve multi-provider email issues.
 *
 * @remarks Once the signup flow rejects with the different-credential error
 * the flow should then prompt for a password, pass that through to this
 * function, which will associate the two accounts and return a
 * `LoginResponse` when the flow has completed.
 *
 * @param pendingCredential SSO AuthCredential to link to email/password
 * @param email Email to be linked
 * @param password Password to sign in to link emails
 * @returns Promise for a `LoginResponse`, or an error (rejects)
 */
export async function linkSSOEmail(pendingCredential: firebaseAuth.AuthCredential, email: string, password: string): Promise<LoginResponse> {
  const auth = firebaseAuth.getAuth();
  return firebaseAuth.signInWithEmailAndPassword(auth, email, password)
    .then(emailCred => firebaseAuth.linkWithCredential(emailCred.user, pendingCredential))
    .then(LoginResponse.FromFirebaseCredential)
    .catch(Promise.reject);
}

/**
 * Change the user's password.
 *
 * @remarks `setPassword` updates the current user's password. Firebase/GIP
 * the credentials of the currently signed in user to authorised the update,
 * so this request can be safely issued directly from the client.
 *
 * @param newPassword User's new password.
 * @returns A promise the update the current user's password.
 */
export function setPassword(newPassword: string): Promise<void> {
  const auth: firebaseAuth.Auth = firebaseAuth.getAuth();
  const user: firebaseAuth.User | null = auth.currentUser;
  if (user === null) {
    const error: Error = new Error('There is no active user to update their password');
    return Promise.reject(error);
  }
  return firebaseAuth.updatePassword(user, newPassword)
    .catch(Promise.reject);
}

/**
 * Change the current user's email address.
 *
 * @remarks changeEmail uses the credentials of the currently signed in user.
 * Arbitrary users' email addresses can not be changed with the Firebase client
 * APIs.
 *
 * @param newEmail User's new email address.
 * @param oldEmail User's old email address.
 * @param password User's current password (used for reauthentication).
 * @return A promise to change the current user's email address.
 */
export async function changeEmail(newEmail: string, oldEmail: string, password: string): Promise<void> {
  const auth: firebaseAuth.Auth = firebaseAuth.getAuth();
  const user: firebaseAuth.User | null = auth.currentUser;
  if (user === null) {
    const error: Error = new Error('No user is signed in to change their email address');
    return Promise.reject(error);
  }

  const emailCredential = firebaseAuth.EmailAuthProvider.credential(oldEmail, password);
  const newUserCredential = await firebaseAuth.reauthenticateWithCredential(user, emailCredential);

  return firebaseAuth.updateEmail(user, newEmail)
    .catch(Promise.reject);
}

/**
 * Sent a user a password reset email.
 *
 * @remarks The password reset email is dispatched by Firebase/GIP, and can be
 * configured in the Firebase admin console.
 *
 * @param emailAddress Address of the user to send a reset link.
 * @returns A promise to send a password reset link.
 */
export function sendPasswordResetEmail(emailAddress: string): Promise<void> {
  const auth = firebaseAuth.getAuth();
  return firebaseAuth.sendPasswordResetEmail(auth, emailAddress)
    .catch(Promise.reject);
}

/**
 * Reset a user's password using a reset token.
 *
 * @remarks Reset tokens are generated by GIP/Firebase, and emailed to the user
 * using {@link sendPasswordResetEmail}. The tokens are stored inside GIP, and
 * not the TPC database. Any references you may find to storing tokens in TPC's
 * DB are leftover legacy crud from the time when we rolled our own auth, and
 * can be deleted.
 *
 * @param newPassword New password to configure for the user.
 * @param resetToken Single use password reset token emailed by {@link sendPasswordResetEmail}
 * @returns A promise to update the user's password.
 */
export function resetPassword(newPassword: string, resetToken: string): Promise<void> {
  const auth = firebaseAuth.getAuth();
  return firebaseAuth.verifyPasswordResetCode(auth, resetToken)
    .then(() => firebaseAuth.confirmPasswordReset(auth, resetToken, newPassword))
    .catch(err => Promise.reject(err));
}

/**
 * Change the current user's display name.
 *
 * @remarks changeEmail uses the credentials of the currently signed in user.
 * Arbitrary users' email addresses can not be changed with the Firebase client
 * APIs.
 *
 * @param newName New display name for the current user.
 * @return A promise to change the current user's display name.
 */
export function changeDisplayName(newName: string): Promise<void> {
  const auth: firebaseAuth.Auth = firebaseAuth.getAuth();
  const user: firebaseAuth.User | null = auth.currentUser;
  if (user === null) {
    const error: Error = new Error('No user is signed in to update their profile');
    return Promise.reject(error);
  }
  return firebaseAuth.updateProfile(user, {
    displayName: newName
  })
    .catch(Promise.reject);
}

export const cancelInvitation = async ({ orgId, inviteId }: { orgId: string, inviteId: string }): Promise<Response> => {
  console.log('stf', orgId, inviteId);
  const response: Response = await fetch(`${INVITE_BASE_URL}/cancel?org=${orgId}&id=${inviteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getRefreshedToken()}`
    },
  });
  if (!response.ok) throw new Error('Failed to cancel invite');
  return response;
};

export default {
  login,
  logout,
  createInvitedUser,
  verifyInvite,
  getToken
};
