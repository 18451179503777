import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  servicesList: {
    width: '100%',
    padding: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  messages: {
    padding: theme.spacing(0),
    width: '100%',
    listStyleType: 'none',
    '& > *:not(:last-child)': {
      marginBottom: '20px'
    }
  },
  message: {
    padding: theme.spacing(0)
  }
}));
