import Smart from 'hocs/smart';
import SignupView from './signup-view';

export default Smart(
  SignupView,
  () => ({}),
  ac => ({
    signup: ac.session.signup,
    SSOSignupSuccess: ac.session.SSOSignupCallbackSuccess,
    SSOSignupFailure: ac.session.SSOSignupCallbackFailure,
    logout: ac.session.logout
  })
);
