import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { GetAssetImage } from 'apis/assetImages';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import useStyles from './image-styles';

const ContextBoxImage = ({
  obj,
  children
}) => {
  const classes = useStyles();
  const assetImageQuery = useQuery(
    ['assetImage', obj.id],
    () => GetAssetImage(obj.id),
    // TODO: aggressive caching since the client currently doesn't know if this asset has a custom profile image
    // This can be removed (and the placeholder loaded without waiting for the failed custom image query) if we
    // store an indication that there is a custom image against the asset table in db (e.g. in the image_url field)
    { staleTime: Infinity, cacheTime: Infinity }
  );
  let url = assetImageQuery.data || obj?.imageUrl;

  // Fallback for assets - use their category to choose a default image
  if (!url && obj.visualKey?.id) {
    url = `img/placeholder-contextbox-images/${obj.visualKey.id}.jpg`;
  }

  if (!url) {
    return null;
  }

  return (
    <Box className={clsx([classes.container, { [classes.loading]: assetImageQuery.isLoading }])}>
      {assetImageQuery.isLoading ? <LoadingIcon size={75} /> : (
        <Box className={classes.image} style={{ backgroundImage: `url(${url})` }}>
          {children}
          {obj.visualKey?.attribution && (
            <Tooltip title={obj.visualKey.attribution} placement="bottom">
              <img className={classes.ccIcon} alt="CC" src="https://mirrors.creativecommons.org/presskit/icons/cc.svg" />
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ContextBoxImage;
