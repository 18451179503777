export const vsDecl = `\
attribute vec2 totalPositions;
varying float tpp;
`;

export const vsMain = 'tpp = totalPositions.x + (totalPositions.y - totalPositions.x) * vPathPosition.y / vPathLength;';

export const fsDecl = `\
varying float tpp;
uniform float time;
uniform float dashScale;
uniform float animationSpeed;
uniform bool animate;
`;

export const fsMainEnd = `\
vec4 color = vColor;
if( animate ) {
  color = vec4(vColor.rgb*0.7, 1);
  if( mod( tpp - (animationSpeed * (time / 40.0)), dashScale ) < (dashScale/2.) ) {
    gl_FragColor = vec4(color.rgb, 0.5);
    return;
  }
}
gl_FragColor = color;
`;
