import React, { useCallback } from 'react';
import Modal from 'react-modal';
import {
  Box,
  Button
} from '@mui/material';

import useStyles from './formModal-styles';

const FormModal = ({
  form,
  onClose
}) => {
  const classes = useStyles();
  const formIframeRef = useCallback(node => {
    if (node) { // also gets called on unmount
      // setInterval(() => {
      //   node.contentWindow.postMessage(form.dataJson);
      // }, 1000);
      // this is a messy hack to get data into some existing forms. It would be much cleaner to
      // rewrite the forms in a way that doesnt involve proxying data into an iframe.
    }
  }, []);

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      className={classes.modal}
      overlayClassName={classes.modalOverlay}
    >
      <Box className={classes.modalContent}>
        <iframe
          title="form"
          className={classes.formIframe}
          src={form.blobLocation}
          onLoad={e => {
            if (e.target) {
              // this is a messy hack to get data into some existing forms. It would be much cleaner to
              // rewrite the forms in a way that doesnt involve proxying data into an iframe.
              e.target.contentWindow.postMessage(form.dataJson, form.blobLocation);
            }
          }}
          ref={formIframeRef}
          width={660}
          height={550}
          scrolling="yes"
        />
      </Box>
      <Box className={classes.footer}>
        <Button className={classes.footerButton} variant="contained" onClick={onClose} tabIndex={-1}>Close</Button>
      </Box>
    </Modal>
  );
};

export default FormModal;
