import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  actionButton: {
    color: theme.palette.common.text
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    textAlign: 'right'
  },
  fab: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(4)
  },
  materialTable: {
    height: '100%',
    overflow: 'hidden',
    '& .MuiTableSortLabel-icon': {
      fontSize: '18px'
    },
    '& input.MuiInputBase-input': {
      // NOTE: this is an ugly workaround because there isn't a more specific selector for the search input
      padding: theme.spacing(1.8, 0),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '& > div': {
      maxHeight: '100%',
      boxShadow: 'none',
      borderRadius: 0,
      borderBottom: theme.border.default,
      '& > div': {
        maxHeight: '100%',
        '& > div > div': {
          overflowY: 'unset!important'
        }
      }
    },
    '& .MuiToolbar-regular': {
      paddingRight: '20px'
    },
    '& .MuiInput-formControl': {
      border: theme.border.default
    },
    '& .MuiInput-formControl::before, & .MuiInput-formControl::after': {
      display: 'none'
    },
    '& table td, & table th': {
      borderBottom: theme.border.default
    },
    '& table tr:last-of-type td': {
      borderBottom: 'none'
    },
    '& .MuiTableRow-root': {
      fontSize: '0.95rem'
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(8 / 3)
    },
    '& .MuiTableCell-root.MuiTableCell-paddingNone.MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .MuiTableCell-paddingCheckbox:last-child': {
      paddingRight: theme.spacing(4)
    },
    '& > .MuiPaper-elevation2': {
      boxShadow: 'none'
    }
  },
  noIceContacts: {
    color: theme.palette.primary.distressRed,
    textAlign: 'center'
  },
  assetLink: {
    marginTop: theme.spacing(2),
    marginBottom: -theme.spacing(3)
  },
  assetName: {
    marginLeft: theme.spacing(2)
  },
  orgSettingsLink: {
    float: 'right',
    padding: theme.spacing(3, 2, 0, 2),
    cursor: 'pointer',
  }
}));
