import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Grid,
  Box,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent, DialogContentText, FormControl, TextField, DialogActions
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchDevices } from 'apis/trackstar/serenity';
import useTranslation from 'hooks/useTranslation';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { assignDeviceToAsset, removeDeviceFromAsset } from 'apis/rest/devices';
import { useGetAssetBasicAssignedToDevice } from 'apis/rest/assets';
import { deviceStatus } from 'apis/trackstar/maps';
import mixpanel from 'mixpanel-browser';
import useStyles from './asset-styles';

const AssetDevices = ({
  asset,
  hasDevice,
  device,
  organisationId,
  displaySnackbar,
  serialType,
  readOnly
}) => {
  const t = useTranslation('pages.assetView');
  const classes = useStyles();
  const [devices, setDevices] = useState([]);
  const [selectDeviceDialogOpen, setSelectDeviceDialogOpen] = useState(false);
  const [confirmChangeDeviceDialogOpen, setConfirmChangeDeviceDialogOpen] = useState(false);
  const [confirmRemoveDeviceDialogOpen, setConfirmRemoveDeviceDialogOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({});
  const getAssetAssignedToDevice = useGetAssetBasicAssignedToDevice(!!selectedDevice?.id, organisationId, selectedDevice?.id);
  const queryClient = useQueryClient();

  const getDevices = useQuery(
    ['devices', organisationId],
    () => fetchDevices(),
    {
      select: data => data,
      onSuccess(data) {
        const ownOrgData = data.filter(deviceFilter => deviceFilter?.ownerId.toLowerCase() === organisationId.toLowerCase());
        const notOriginalDeviceData = ownOrgData.filter(deviceFilter => deviceFilter?.id !== asset.deviceId);
        return setDevices(notOriginalDeviceData);
      }
    }
  );
  if (getDevices.isError) {
    displaySnackbar({ id: 'getDevicesFailedSnackbar', text: t('getDevicesFailed'), type: 'error' });
  }

  const clearState = () => {
    setSelectedDevice({});
  };

  const mutationRemoveDeviceFromAsset = useMutation(removeDeviceFromAsset, {
    onError: () => {
      displaySnackbar({ id: 'removeDeviceFromAssetFailed', text: t('removeDeviceFromAssetFailed'), type: 'error' });
      mixpanel.track('Unassign Device from Asset', { success: false, asset, organisationId });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'removeDeviceFromAssetSucceeded', text: t('removeDeviceFromAssetSucceeded'), type: 'success' });
      mixpanel.track('Unassign Device from Asset', { success: true, asset, organisationId });
    },
    onSettled: async () => {
      setConfirmRemoveDeviceDialogOpen(false);
      clearState();
      queryClient.removeQueries(['deviceBasic', asset.deviceId]);
      return queryClient.invalidateQueries(['assetBasic', asset.id]);
    },
    mutationKey: 'removeDeviceFromAsset'
  });

  const handleRemoveDeviceDialogConfirm = () => {
    mutationRemoveDeviceFromAsset.mutate({ organisationId, assetId: asset.id, deviceId: device.id });
  };

  const openRemoveDeviceDialog = () => {
    setConfirmRemoveDeviceDialogOpen(true);
  };

  const handleRemoveDeviceDialogClose = () => {
    setConfirmRemoveDeviceDialogOpen(false);
  };

  const mutationSaveAssignedDeviceToAsset = useMutation(assignDeviceToAsset, {
    onError: () => {
      displaySnackbar({ id: 'assetDetailsSaveFailed', text: t('assignDeviceToAssetFailed'), type: 'error' });
      mixpanel.track('Assign Device to Asset', {
        success: false, asset, device: selectedDevice, organisationId
      });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'assetDetailsSaved', text: t('assignDeviceToAssetSaved'), type: 'success' });
      mixpanel.track('Assign Device to Asset', {
        success: true, asset, device: selectedDevice, organisationId
      });
    },
    onSettled: async () => {
      setConfirmChangeDeviceDialogOpen(false);
      clearState();
      queryClient.removeQueries(['deviceBasic', asset.deviceId]);
      queryClient.removeQueries(['deviceBasic', selectedDevice.id]);
      return queryClient.invalidateQueries(['assetBasic', asset.id]);
    },
    mutationKey: 'assignDeviceToAsset'
  });

  const handleChangeDeviceConfirm = () => {
    setSelectDeviceDialogOpen(false);
    setConfirmChangeDeviceDialogOpen(true);
  };

  const handleConfirmChangeDeviceConfirm = () => {
    mutationSaveAssignedDeviceToAsset.mutate({ organisationId, assetId: asset.id, deviceId: selectedDevice.id });
  };

  const openSelectDeviceDialog = () => {
    setSelectDeviceDialogOpen(true);
  };

  const handleChangeDeviceDialogClose = () => {
    setSelectDeviceDialogOpen(false);
    clearState();
  };

  const handleConfirmChangeDeviceDialogClose = () => {
    setConfirmChangeDeviceDialogOpen(false);
    clearState();
  };

  const getDeviceOptionLabel = option => {
    let serial = '';
    if (serialType === 'tpSerial') {
      serial = option.tpSerial;
    } else if (serialType === 'manufacturerSerial') {
      serial = option.manufacturerSerial;
    } else if (serialType === 'imei') {
      serial = option.imei;
    }
    const makeModel = option.make || option.model ? `${option?.make} ${option?.model}` : '';
    return makeModel ? `${makeModel} (${serial})` : '';
  };

  const isSystemAsset = assetToCheck => {
    if (assetToCheck == null) {
      return false;
    }
    return assetToCheck.category === 'System';
  };

  const getAssetDisplayInformation = assetToDisplay => {
    if (assetToDisplay == null) {
      return '...';
    }
    let display = '';
    if (assetToDisplay.category !== 'System') {
      display += assetToDisplay.name;
    }
    return display;
  };

  return (
    <Box id="devices" className={classes.category}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <Typography variant="h5" gutterBottom>{t('devicesTitle')}</Typography>
          <Typography variant="body1">{t('devicesDescription')}</Typography>
        </Grid>
        <Grid item sm={12} md={8}>
          <Paper className={classes.panel}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('makeModel')}</TableCell>
                  <TableCell align="left">{t('serial')}</TableCell>
                  <TableCell align="left">{t('status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hasDevice ? (
                  <React.Fragment key={device.id}>
                    <TableRow>
                      <TableCell align="left">
                        {device.make}
                        {' '}
                        {device.model}
                      </TableCell>
                      <TableCell className={clsx(classes.deviceSerial)} component="th" scope="row">
                        <Grid container className={classes.serialTable}>
                          <Grid item md={6}>
                            {t('imei')}
                            :
                          </Grid>
                          <Grid item md={6}>{device.imei}</Grid>
                          <Grid item md={6}>
                            {t('tpSerial')}
                            :
                          </Grid>
                          <Grid item md={6}>{device.tracPlusSerial}</Grid>
                          <Grid item md={6}>
                            {t('manufacturerSerial')}
                            :
                          </Grid>
                          <Grid item md={6}>{device.manufacturerSerial}</Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        {deviceStatus.find(s => s.id === device?.status)?.description || t('unknownStatus')}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ) : (
                  <TableRow>
                    <TableCell className={classes.deviceSerial} component="th" scope="row" colSpan={3}>
                      {t('noDevicesAssigned')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!readOnly && (
            <Grid container justifyContent="flex-end">
              {hasDevice ? (
                <>
                  <Grid item sm={2} className={clsx([classes.inputButtonWrapper, classes.changeDeviceButton])}>
                    <Button id="removeDeviceButton" className={classes.inputButton} variant="contained" color="primary" onClick={openRemoveDeviceDialog}>
                      {t('removeDevice')}
                    </Button>
                  </Grid>
                  <Grid item sm={2} className={clsx([classes.inputButtonWrapper, classes.changeDeviceButton])}>
                    <Button id="changeDeviceButton" className={classes.inputButton} variant="contained" color="primary" onClick={openSelectDeviceDialog}>
                      {t('changeDevice')}
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item sm={2} className={clsx([classes.inputButtonWrapper, classes.changeDeviceButton])}>
                  <Button id="changeDeviceButton" className={classes.inputButton} variant="contained" color="primary" onClick={openSelectDeviceDialog}>
                    {t('assign')}
                  </Button>
                </Grid>
              )}
            </Grid>
            )}
          </Paper>
        </Grid>
        <Dialog open={selectDeviceDialogOpen} onClose={handleChangeDeviceDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{hasDevice ? t('changeDeviceDialogTitle') : t('assignDeviceDialogTitle') }</DialogTitle>
          <DialogContent className={classes.changeDeviceDialog}>
            <DialogContentText>
              {hasDevice ? t('changeDeviceDialogContent') : t('assignDeviceDialogContent') }
            </DialogContentText>
            <FormControl variant="standard" className={classes.formControl}>
              <Autocomplete
                id="dialog-select"
                options={devices.sort((a, b) => a.tpSerial?.localeCompare(b.tpSerial))}
                getOptionLabel={option => getDeviceOptionLabel(option)}
                isOptionEqualToValue={(option, value) => Object.keys(value).length === 0 || value.id === option.id}
                value={selectedDevice}
                onChange={(e, newValue) => { if (newValue) { setSelectedDevice(newValue); } }}
                renderInput={params => <TextField variant="standard" {...params} label={t('device')} fullWidth />}
                filterOptions={createFilterOptions({
                  stringify: option => getDeviceOptionLabel(option)
                })}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleChangeDeviceDialogClose}>
              {t('cancelButton')}
            </Button>
            <Button variant="contained" onClick={handleChangeDeviceConfirm} color="primary" disabled={!selectedDevice?.id}>
              {t('selectDeviceButton')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={confirmChangeDeviceDialogOpen} onClose={handleConfirmChangeDeviceDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('confirmChangeDeviceDialogTitle')}</DialogTitle>
          <DialogContent className={classes.confirmChangeDeviceDialog}>
            {isSystemAsset(getAssetAssignedToDevice.data) && (
            <DialogContentText>
              {t('confirmChangeDeviceDialogDevice')}
              {' '}
              {getDeviceOptionLabel(selectedDevice)}
              {' '}
              {t('confirmChangeDeviceDialogWillBeAssignedTo')}
              {' '}
              {asset.name}
              .
            </DialogContentText>
            )}
            {!isSystemAsset(getAssetAssignedToDevice.data) && (
            <DialogContentText>
              {t('confirmChangeDeviceDialogDevice')}
              {' '}
              {getDeviceOptionLabel(selectedDevice)}
              {' '}
              {t('confirmChangeDeviceDialogWillBeUnassignedFrom')}
              {' '}
              {getAssetDisplayInformation(getAssetAssignedToDevice.data)}
              {' '}
              {t('confirmChangeDeviceDialogAndAssignedTo')}
              {' '}
              {asset.name}
              .
            </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleConfirmChangeDeviceDialogClose}>
              {t('cancelButton')}
            </Button>
            <Button variant="contained" onClick={handleConfirmChangeDeviceConfirm} color="primary" disabled={!selectedDevice?.id}>
              {t('changeDeviceButton')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={confirmRemoveDeviceDialogOpen} onClose={handleRemoveDeviceDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('removeDeviceDialogTitle')}</DialogTitle>
          <DialogContent className={classes.removeDeviceDialog}>
            <DialogContentText>
              {t('removeDeviceDialogText')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleRemoveDeviceDialogClose}>
              {t('cancelButton')}
            </Button>
            <Button variant="contained" onClick={handleRemoveDeviceDialogConfirm} color="primary">
              {t('removeDeviceConfirmationButton')}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};

export default AssetDevices;
