import React from 'react';
import clsx from 'clsx';
import moment from 'utils/moment';
import {
  Box,
  Tooltip,
  SvgIcon
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useStyles from '../actionButtons-styles';

/**
 * Renders a "target" icon which, when clicked, jumps the applications
 * `now` value to the most recent positions timestamp for the given asset id
 */
const JumpToLatestActivity = ({
  isActive,
  now,
  setNow,
  displaySnackbar,
  latestPosition,
  centerMapOnPosition,
  setHighlightedReportId,
  follow,
  setFollow,
  selectedMapId,
  timezone
}) => {
  const classes = useStyles();
  const t = useTranslation('contextbox.asset.activity.actionButtons');

  // If there's no activity, don't render the jumpToLatestActivity button...
  if (!latestPosition) return <></>;

  const handleClick = () => {
    if (follow) setFollow(selectedMapId, false);
    centerMapOnPosition(latestPosition);
    setHighlightedReportId(latestPosition.id);

    if (moment.unix(latestPosition.received).isSame(moment(), 'day')) {
      if (now) {
        displaySnackbar({
          id: 'historicSnackbarMessage',
          toDelete: true,
        });
        setNow(null);
      }
    } else {
      const newDate = moment.unix(latestPosition.received).tz(timezone).endOf('d');
      newDate.set({
        hour: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 0
      });
      setNow(newDate);
      displaySnackbar({
        id: 'historicSnackbarMessage',
        text: t('historicSnackBar'),
        persist: true
      });
    }
  };

  return (
    <Box className={classes.iconWrapper}>
      <Tooltip title={t('jumpToLatestTooltip')}>
        <SvgIcon
          className={clsx([classes.icon, classes.targetIcon], { [classes.activeIcon]: isActive })}
          width="12"
          height="12"
          viewBox="0 0 12 12"
          onClick={handleClick}
        >
          <path d="M6 4c-1.1 0-2 0.9-2 2s0.9 2 2 2 2-0.9 2-2-0.9-2-2-2z m4.47 1.5a4.5 4.5 0 0 0-3.97-3.97v-1.03h-1v1.03a4.5 4.5 0 0 0-3.97 3.97h-1.03v1h1.03a4.5 4.5 0 0 0 3.97 3.97v1.03h1v-1.03a4.5 4.5 0 0 0 3.97-3.97h1.03v-1h-1.03z m-4.47 4c-1.94 0-3.5-1.56-3.5-3.5s1.56-3.5 3.5-3.5 3.5 1.56 3.5 3.5-1.56 3.5-3.5 3.5z" />
        </SvgIcon>
      </Tooltip>
    </Box>
  );
};

export default JumpToLatestActivity;
