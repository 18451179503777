import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  stepWrapper: {
    borderBottom: theme.border.default,
    '& > div': {
      backgroundColor: theme.palette.primary.white
    },
    '& .MuiStepConnector-line': {
      display: 'none'
    }
  },
  step: {
    '& svg': {
      color: theme.palette.common.lightBlue,
      width: '30px',
      height: '30px',
      '&.MuiStepIcon-active, &.MuiStepIcon-completed': {
        color: theme.palette.primary.main,
        '& text': {
          fill: theme.palette.common.white,
          fontWeight: 600
        }
      }
    },
    '& text': {
      fill: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  stepText: {
    '& span': {
      fontSize: '1.1rem'
    }
  },
  button: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(2, 6),
    fontSize: '1rem',
    textTransform: 'capitalize',
    boxShadow: 'none'
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  stepContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(6),
    minHeight: 300
  },
  actionsWrapper: {
    borderTop: theme.border.default,
    textAlign: 'right',
    padding: theme.spacing(3, 6)
  }
}));
