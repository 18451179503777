/* eslint-disable no-param-reassign */
import { PathLayer } from '@deck.gl/layers';
import {
  fsDecl, fsMainEnd
} from './shaders/highlight-trail-shader.glsl';

const defaultProps = {
  animationSpeed: 0.8,
  animate: false
};

export default class HighlightedTrailLayer extends PathLayer {
  initializeState(params) {
    super.initializeState(params);
  }

  draw({ uniforms }) {
    super.draw({
      uniforms:
      {
        ...uniforms,
        time: this.context.timeline.time,
        animationSpeed: this.props.animationSpeed,
        animate: this.props.animate
      }
    });
  }

  getShaders() {
    const shaders = super.getShaders();
    shaders.inject = {
      'fs:#decl': fsDecl,
      'fs:#main-end': fsMainEnd
    };

    return shaders;
  }
}

HighlightedTrailLayer.layerName = 'HighlightedTrailLayer';
HighlightedTrailLayer.defaultProps = defaultProps;
