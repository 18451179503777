import React from 'react';
import useStyles from './saturationPointerCircle-styles';

const CustomColorPickerPointerCircle = ({ hsl }) => {
  const classes = useStyles(hsl);

  return (
    <div className={classes.picker} />
  );
};

export default CustomColorPickerPointerCircle;
