import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Typography } from '@mui/material';
import TracplusIcon from 'components/shared/icons/tracplusIcon';
import MicrosoftLoginButton from 'components/shared/microsoftLoginButton';
import LangSelector from 'components/shared/languageSelector';
import useTranslation from 'hooks/useTranslation';
import { loginWithSSO, getRedirectResultLogin } from 'apis/auth';
import CheckboxInput from 'components/shared/forms/inputs/bool';
import { NoHeaderNoFooterLoadingPage } from 'components/pages/loading';
import * as animationData from 'components/pages/loading/loader.json';
import Lottie from 'react-lottie-player';
import LoginInput from '../loginInput';
import SubmitButton from '../submitButton';
import useStyles from './login-styles';

const LoginPage = ({
  loggingIn,
  loggedIn,
  loginError,
  onSubmit,
  onSubmitSSOSuccess,
  onSubmitSSOFailure,
  rememberMe,
  setRememberMe,
  replace,
  legacyLogin,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation('pages.login');

  React.useEffect(() => {
    if (window.location.search.includes('redirect')) {
      setLoading(true);
      getRedirectResultLogin()
        .then(r => {
          if (r !== null) {
            setSubmitted(true);
            onSubmitSSOSuccess(r);
          } else {
            onSubmitSSOFailure(Error('User is null. Try logging in again or logging out.'));
            setLoading(false);
          }
        })
        .catch(e => {
          setLoading(false);
          onSubmitSSOFailure(e);
        });
    } else if (window.location.search.includes('loading')) {
      setLoading(true);
    }
    setError(loginError);
  }, [loginError]);

  if (loggedIn) {
    setTimeout(() => replace('/'));
    return null;
  }

  const handleInputChange = setField => e => {
    setField(e.target.value);
    setError(null);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    if (email.includes('@')) {
      onSubmit(email, password, rememberMe);
    } else {
      // assume legacy usercode login attempt
      legacyLogin(email, password);
    }
  };

  const handleSSOSubmit = (e, provider) => {
    e.preventDefault();
    setSubmitted(true);
    loginWithSSO(provider);
    history.replace({
      pathname: '/login',
      search: '?redirect'
    });
  };

  return (
    <Box className={classes.container}>
      <Grid className={classes.gridWrapper} container spacing={0}>
        <Grid item xs={12} sm={5}>
          { loading ? (
            <NoHeaderNoFooterLoadingPage />
          ) : (
            <Box className={classes.formWrapper}>
              <Box className={classes.loginForm}>
                <Box className={classes.iconWrapper}>
                  <TracplusIcon />
                </Box>
                <Typography variant="h1" className={classes.title}>{t('title')}</Typography>
                <Typography variant="body1" className={classes.subtitle}>{t('paragraph')}</Typography>

                <LoginInput
                  type="email"
                  placeholderText={t('emailPlaceholder')}
                  value={email}
                  onChange={handleInputChange(setEmail)}
                  hasError={submitted && !email}
                  onEnterKeypress={handleSubmit}
                />

                <LoginInput
                  type="password"
                  placeholderText={t('passwordPlaceholder')}
                  helperText={t('forgottenPassword')}
                  value={password}
                  onChange={handleInputChange(setPassword)}
                  hasError={submitted && !password}
                  onEnterKeypress={handleSubmit}
                />

                <SubmitButton
                  text={loggingIn ? (
                    <Lottie
                      loop
                      animationData={animationData}
                      play
                      style={{ width: 30, height: 30 }}
                      rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                    />
                  ) : t('submitButton')}
                  onSubmit={handleSubmit}
                  style={loggingIn ? { backgroundColor: '#818285' } : {}}
                />

                <MicrosoftLoginButton onClick={handleSSOSubmit} />

                {error && (
                  <Typography className={classes.errorMessage}>{t(error)}</Typography>
                )}

                <CheckboxInput
                  label={t('rememberMe')}
                  value={rememberMe}
                  onChange={(id, value) => setRememberMe(value)}
                />

                <LangSelector />
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={7}>
          <Box className={classes.imageOfTheWeek} style={{ backgroundImage: 'url(/img/south-pacific-helicopters.png)' }}>
            <Box className={classes.imageOfTheWeekText}>South Pacific Helicopters NZ</Box>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default LoginPage;
