import Smart from 'hocs/smart';
import SystemStatus from './systemStatus-view';

export default Smart(
  SystemStatus,
  state => ({
    isOpen: state.ui.systemStatusOpen
  }),
  ac => ({
    toggleModal: ac.ui.toggleSystemStatus
  })
);
