import Smart from 'hocs/smart';
import HeaderView from './header-view';

export default Smart(
  HeaderView,
  state => ({
    user: state.session.user,
    organisationId: state.session.organisationId
  }),
  ac => ({
  })
);
