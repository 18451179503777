import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  iceButton: {
    cursor: 'default',
    margin: theme.spacing(0, 2),
    color: theme.palette.common.text,
    '&.Mui-disabled': {
      visibility: 'hidden'
    }
  },
  active: {
    '& svg': {
      color: theme.palette.button.checked
    }
  },
  disabled: {
    cursor: 'not-allowed'
  }
}));
