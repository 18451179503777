import React from 'react';

const ButtonIcon = ({
  name
}) => {
  switch (name) {
    case 'air': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M18.5,11.77H1.16C0.52,11.77,0,11.24,0,10.6s0.52-1.16,1.16-1.16H18.5c1.22,0,2.32-0.78,2.72-1.94
          c0.53-1.51-0.27-3.16-1.78-3.69c-1.51-0.53-3.16,0.27-3.69,1.78c-0.21,0.61-0.88,0.93-1.48,0.71c-0.61-0.21-0.93-0.87-0.71-1.48
          c0.95-2.72,3.93-4.16,6.65-3.21c1.32,0.46,2.38,1.41,2.98,2.66s0.69,2.67,0.23,3.99C22.68,10.35,20.71,11.76,18.5,11.77z"
        />
        <path d="M10.39,30.68c-0.78,0-1.55-0.17-2.26-0.52c-1.26-0.61-2.2-1.66-2.66-2.98c-0.21-0.6,0.11-1.27,0.71-1.48
          c0.6-0.21,1.27,0.11,1.48,0.72c0.25,0.73,0.78,1.32,1.48,1.65c0.7,0.34,1.48,0.38,2.21,0.13c0.73-0.25,1.32-0.78,1.65-1.48
          c0.34-0.7,0.38-1.48,0.13-2.21c-0.4-1.16-1.5-1.94-2.73-1.94H1.16C0.52,22.56,0,22.04,0,21.4s0.52-1.16,1.16-1.16h9.24
          c2.21,0.01,4.19,1.41,4.92,3.5c0.46,1.32,0.38,2.73-0.23,3.99c-0.61,1.26-1.66,2.2-2.98,2.66C11.55,30.58,10.97,30.68,10.39,30.68z
          "
        />
        <path d="M26.78,25.28c-2.16,0-4.18-1.35-4.93-3.5c-0.21-0.61,0.11-1.27,0.71-1.48c0.61-0.21,1.27,0.11,1.48,0.71
          c0.53,1.51,2.18,2.31,3.69,1.78c0.73-0.25,1.32-0.78,1.65-1.48c0.34-0.7,0.38-1.48,0.13-2.21c-0.4-1.16-1.5-1.94-2.72-1.94H1.16
          C0.52,17.16,0,16.64,0,16c0-0.64,0.52-1.16,1.16-1.16h25.63c2.21,0.01,4.19,1.41,4.92,3.5c0.95,2.72-0.49,5.7-3.21,6.65
          C27.93,25.18,27.35,25.28,26.78,25.28z"
        />
      </svg>
    );
    case 'land': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path d="M15.684,8.059A6.873,6.873,0,0,0,16,6c0-3.309-2.243-6-5-6S6,2.691,6,6a6.873,6.873,0,0,0,.316,2.059A8.451,8.451,0,0,0,4,14a7.944,7.944,0,0,0,5,7.663V28h2V15a1,1,0,0,1,1-1h0a1,1,0,0,1,1,1v6.663A7.944,7.944,0,0,0,18,14,8.451,8.451,0,0,0,15.684,8.059Z" />
        <path d="M25.684,8.059A6.873,6.873,0,0,0,26,6c0-3.309-2.243-6-5-6a4.722,4.722,0,0,0-3.852,2.179,8.949,8.949,0,0,1,.719,5.348A10.54,10.54,0,0,1,20,14a10.562,10.562,0,0,1-2.494,6.915A6.426,6.426,0,0,0,20,21.909V28h2V21.909c3.387-.558,6-3.887,6-7.909A8.451,8.451,0,0,0,25.684,8.059Z" />
        <path d="M31.625,21.372a25.435,25.435,0,0,0-3.073-2.1,10.068,10.068,0,0,1-1.178,1.631A23.349,23.349,0,0,1,30,22.639V30H2V22.639a23.156,23.156,0,0,1,2.615-1.727,9.775,9.775,0,0,1-1.19-1.623,25.292,25.292,0,0,0-3.05,2.083,1,1,0,0,0-.375.78V31a1,1,0,0,0,1,1H31a1,1,0,0,0,1-1V22.152A1,1,0,0,0,31.625,21.372Z" />
      </svg>
    );
    case 'sea': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path d="M1,23a6.7,6.7,0,0,0,5.205-2.432,3,3,0,0,1,4.589,0,6.786,6.786,0,0,0,10.387.029,3.032,3.032,0,0,1,4.638,0A6.659,6.659,0,0,0,31,23a2.966,2.966,0,0,1,1,.184V17a1,1,0,0,0-1-1H29.636a6.031,6.031,0,0,1-6.371-5.441c-.142-1.588.442-3.578,2.551-3.956l1.649-.3L26.422,5c-.115-.144-2.868-3.551-6.853-3.956a8.228,8.228,0,0,0-6.235,2.118C11.1,5.022,10.05,7.617,9.123,9.907,7.744,13.312,6.654,16,2.364,16H1a1,1,0,0,0-1,1v6.184A2.966,2.966,0,0,1,1,23Z" />
        <path d="M31,31H1a1,1,0,0,1-1-1V26a1,1,0,0,1,1-1,8.674,8.674,0,0,0,6.735-3.144A1,1,0,0,1,8.5,21.5h0a1,1,0,0,1,.764.355,8.785,8.785,0,0,0,13.463.01,1.034,1.034,0,0,1,1.546,0A8.654,8.654,0,0,0,31,25a1,1,0,0,1,1,1v4A1,1,0,0,1,31,31Z" />
      </svg>
    );
    case 'other': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path d="M11.382,6l3-6H8C7.761,0,7.53,0.085,7.349,0.241L0.631,6H11.382z" />
        <path d="M20.618,6h10.751l-6.719-5.759C24.47,0.085,24.239,0,24,0h-6.382L20.618,6z" />
        <path d="M21,8v10H11V8H0v23c0,0.552,0.448,1,1,1h30c0.552,0,1-0.448,1-1V8H21z" />
      </svg>
    );
    case 'Aircraft': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M11.52,11.76l6.19-6.19L4.52,0.72c-0.26-0.1-0.55-0.03-0.74,0.16L0.93,3.67C0.78,3.82,0.7,4.03,0.72,4.24
          c0.02,0.21,0.13,0.41,0.31,0.53L11.52,11.76z"
        />
        <path d="M26.43,14.29l-6.19,6.19l6.99,10.49c0.12,0.18,0.31,0.29,0.53,0.31c0.02,0,0.04,0,0.07,0
          c0.19,0,0.37-0.08,0.51-0.21l2.79-2.85c0.19-0.2,0.25-0.49,0.16-0.74L26.43,14.29z"
        />
        <path d="M31.3,0.71c-1-1-3.02-0.72-4.13-0.46C25.47,0.64,23.88,1.43,23,2.3L8.76,16.54c-0.05,0.05-0.09,0.1-0.12,0.16
          L5.47,22.4l-4.7-0.36c-0.66-0.05-1.03,0.75-0.56,1.21l3.08,3.08l-1.77,3.19c-0.15,0.28-0.11,0.62,0.12,0.85
          c0.23,0.23,0.57,0.27,0.85,0.12l3.19-1.77l3.08,3.08c0.47,0.47,1.26,0.1,1.21-0.56l-0.36-4.7l5.69-3.16
          c0.06-0.03,0.11-0.07,0.16-0.12L29.7,9.01C31.43,7.28,32.92,2.34,31.3,0.71z"
        />
      </svg>
    );
    case 'Helicopter': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M20.73,16.85l10.96,0.03c0.16,0,0.29-0.13,0.29-0.28L32,15.76c0.01-0.08-0.02-0.15-0.08-0.21
          c-0.05-0.05-0.13-0.08-0.21-0.09l-9.55-0.03c0.42-0.42,0.7-0.7,0.76-0.75l0.03-0.03c0.21-0.23,0.24-0.5,0.07-0.66
          c-0.17-0.17-0.44-0.15-0.67,0.07l-0.94,0.94l0.03-0.34c0.01-0.07-0.02-0.13-0.05-0.18c0.06-0.07,0.13-0.14,0.19-0.22
          c0.38-0.45,0.71-0.88,0.97-1.2c0.26-0.33,0.42-0.55,0.44-0.57l0.01-0.01c0.33-0.49,0.51-0.92,0.6-1.29c-0.01-0.01-0.01-0.01,0-0.01
          c0,0,0.01-0.01,0-0.01c0.1-0.43,0.07-0.79,0-1.05c-0.14-0.52-0.43-0.82-0.5-0.87l-0.2-0.2c-0.06-0.07-0.35-0.36-0.86-0.49
          c-0.27-0.07-0.63-0.1-1.05-0.01c-0.01,0.01-0.01,0-0.01,0l-0.01,0.01c-0.38,0.09-0.81,0.27-1.29,0.6c-0.01,0.01-0.01,0-0.01,0
          c-0.06,0.04-0.88,0.66-1.78,1.41c-0.07,0.07-0.15,0.13-0.23,0.2c-0.05-0.04-0.12-0.06-0.18-0.06l-0.34,0.03l0.93-0.93
          c0.01-0.01,0.01-0.01,0.01-0.01c0.21-0.22,0.23-0.49,0.07-0.66c-0.17-0.17-0.44-0.15-0.66,0.06l-0.04,0.04
          c-0.03,0.03-0.15,0.16-0.33,0.34l0.02-9.07c0-0.15-0.12-0.28-0.28-0.28L16,0.17c-0.07,0-0.15,0.03-0.21,0.08
          C15.73,0.3,15.7,0.38,15.7,0.46L15.66,11c-1.19,1.19-2.77,2.77-4.09,4.08L0.8,15.06c-0.16,0-0.29,0.13-0.29,0.28l-0.01,0.84
          c-0.01,0.08,0.02,0.15,0.08,0.21c0.05,0.05,0.13,0.08,0.21,0.09l11.19,0.04c-0.47,0.55-0.89,1.07-1.19,1.51l-0.01,0.01
          c-0.04,0.05-0.07,0.1-0.1,0.15c-0.04,0.05-0.04,0.12-0.04,0.18l-0.08,0.05c-0.03,0.03-0.06,0.06-0.08,0.1
          c-0.07,0.12-0.13,0.34-0.07,0.6l-0.61,1.86l-4.07,4.18l-1.68-1.68l0.18-0.14c0.07-0.05,0.11-0.13,0.11-0.21
          c0.01-0.08-0.03-0.16-0.09-0.22l-0.12-0.12c-0.1-0.1-0.26-0.11-0.37-0.03L3.5,22.95c-0.05-0.04-0.12-0.06-0.18-0.07
          c-0.14-0.01-0.27,0.06-0.4,0.19l-0.63,0.63c0,0-0.01,0.01-0.01,0.01c-0.02,0.02-0.07,0.09-0.09,0.19l-1.63,1.17
          c-0.07,0.05-0.11,0.13-0.12,0.22c-0.01,0.08,0.03,0.16,0.09,0.22l0.13,0.13c0.1,0.1,0.25,0.12,0.37,0.03l1.58-1.13l1.89,1.89
          c-0.6,0.63-1.25,1.4-1.7,1.95l-0.18-0.18l0.04-0.05C2.7,28.12,2.7,28.07,2.71,28l1.25-1.41c0.1-0.1,0.1-0.29-0.01-0.39L3.9,26.14
          c-0.06-0.06-0.14-0.1-0.22-0.09s-0.15,0.04-0.21,0.1c-0.41,0.47-1.05,1.21-1.26,1.44c-0.04,0.02-0.09,0.03-0.13,0.07l-0.06,0.06
          c-0.09-0.01-0.18,0-0.25,0.07L1.7,27.86c-0.03,0.03-0.03,0.06-0.04,0.1c-0.04,0.01-0.07,0.01-0.1,0.04L1.5,28.07
          c-0.09,0.09-0.09,0.21-0.04,0.31l-0.12,0.13c-0.04,0.04-0.04,0.08-0.04,0.13l-1.22,1.44c-0.1,0.12-0.1,0.29,0.01,0.39l0.06,0.06
          c0.06,0.06,0.14,0.1,0.22,0.09c0.08-0.01,0.15-0.04,0.21-0.1l1.23-1.45c0.03-0.01,0.07-0.02,0.09-0.04l0.07-0.07l0.15,0.15
          c0.03,0.03,0.07,0.03,0.11,0.05c-0.01,0.03-0.04,0.05-0.04,0.08c-0.03,0.18,0.1,0.35,0.21,0.46c0.01,0.01,0.01,0.01,0.01,0.01
          s0,0,0.01,0.01c0,0,0.01,0.01,0.01,0.01c0.01,0.01,0.01,0.01,0.01,0.01c0.13,0.12,0.28,0.24,0.46,0.21c0.07,0,0.14-0.04,0.18-0.08
          c0.17-0.14,1.6-1.27,2.6-2.23l1.64,1.62l-1.5,1.09c-0.07,0.04-0.11,0.13-0.13,0.21c-0.01,0.08,0.03,0.16,0.09,0.22l0.12,0.12
          c0.1,0.1,0.26,0.13,0.38,0.04l1.58-1.13l0.01,0.01c0.06,0.07,0.14,0.11,0.24,0.12c0.14,0.01,0.27-0.05,0.4-0.18l0.64-0.64
          c0.01-0.01,0.03-0.03,0.03-0.04c0.02-0.02,0.08-0.13,0.06-0.27l0.27-0.19c0.07-0.05,0.1-0.13,0.11-0.21
          c0.01-0.08-0.03-0.16-0.09-0.22l-0.1-0.1c-0.1-0.1-0.24-0.11-0.35-0.04L8.76,28.2l-1.8-1.8l4.19-4.06l1.86-0.61
          c0.26,0.05,0.49-0.01,0.61-0.08c0.02-0.02,0.06-0.04,0.08-0.08l0.05-0.08c0.07,0.01,0.13,0,0.18-0.04c0.04-0.03,0.1-0.07,0.15-0.1
          c0,0,0.01-0.01,0.02-0.01c0.35-0.25,0.77-0.58,1.21-0.95l-0.01,11.14c0,0.07,0.03,0.15,0.08,0.2c0.05,0.05,0.12,0.09,0.19,0.09
          l0.85,0.02c0.07,0,0.15-0.03,0.21-0.07c0.06-0.06,0.09-0.13,0.08-0.21l0.04-10.71C17.98,19.6,19.5,18.08,20.73,16.85z"
        />
      </svg>
    );
    case 'Ambulance': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M25.74,12.52h-2.78c-0.36-0.02-0.67,0.25-0.7,0.62c0,0.03,0,0.05,0,0.08v2.09
          c-0.02,0.36,0.25,0.67,0.62,0.7c0.03,0,0.05,0,0.08,0h2.78c0.36,0.02,0.67-0.25,0.7-0.62c0-0.03,0-0.05,0-0.08v-2.09
          c0.02-0.36-0.25-0.67-0.62-0.7C25.79,12.52,25.77,12.52,25.74,12.52z"
        />
        <path d="M31.59,16.77l-2.37-1.18v-5.15c0.02-0.36-0.25-0.67-0.62-0.7c-0.03,0-0.05,0-0.08,0h-7.65V4.87
          c0.02-0.36-0.25-0.67-0.62-0.7c-0.03,0-0.05,0-0.08,0H0.7C0.33,4.15,0.02,4.43,0,4.79c0,0.03,0,0.05,0,0.08v18.08
          c-0.02,0.36,0.25,0.67,0.62,0.7c0.03,0,0.05,0,0.08,0h3.55c0.37,2.65,2.82,4.5,5.47,4.13c2.15-0.3,3.83-1.99,4.13-4.13h8.49
          c-0.06,0.23-0.08,0.46-0.07,0.7c0,1.92,1.56,3.48,3.48,3.48s3.48-1.56,3.48-3.48c0.01-0.23-0.01-0.47-0.07-0.7h2.16
          c0.36,0.02,0.67-0.25,0.7-0.62c0-0.03,0-0.05,0-0.08v-5.56C32,17.12,31.84,16.87,31.59,16.77z M9.04,26.43
          c-1.92,0-3.48-1.56-3.48-3.48s1.56-3.48,3.48-3.48c1.92,0,3.48,1.56,3.48,3.48c0.02,1.9-1.51,3.46-3.41,3.48
          C9.09,26.43,9.07,26.43,9.04,26.43z M13.22,12.52h-2.09v2.09c0,0.38-0.31,0.7-0.7,0.7c-0.38,0-0.7-0.31-0.7-0.7l0,0v-2.09H7.65
          c-0.38,0-0.7-0.31-0.7-0.7c0-0.38,0.31-0.7,0.7-0.7h2.09V9.04c0-0.38,0.31-0.7,0.7-0.7c0.38,0,0.7,0.31,0.7,0.7v2.09h2.09
          c0.38,0,0.7,0.31,0.7,0.7C13.91,12.21,13.6,12.52,13.22,12.52L13.22,12.52L13.22,12.52z M25.74,26.43c-1.15,0-2.09-0.93-2.09-2.09
          c0-1.15,0.93-2.09,2.09-2.09s2.09,0.93,2.09,2.09c0.02,1.13-0.88,2.07-2.01,2.09C25.79,26.43,25.76,26.43,25.74,26.43z M30.61,22.26
          h-2.09c-1.15-1.54-3.33-1.85-4.87-0.7c-0.26,0.2-0.5,0.43-0.7,0.7h-2.09V11.13h6.96V16c0,0.27,0.16,0.52,0.42,0.63l2.37,1.18
          L30.61,22.26z"
        />
      </svg>
    );
    case 'Bus': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <circle cx="6.88" cy="24.39" r="3.42" />
        <path d="M29.22,4.59H2.78C1.25,4.6,0,5.84,0,7.38v16.7c0,0.38,0.31,0.7,0.69,0.7c0,0,0,0,0,0h1.39
          c0-2.69,2.18-4.87,4.87-4.87c2.69,0,4.87,2.18,4.87,4.87h8.35c0-2.69,2.18-4.87,4.87-4.87s4.87,2.18,4.87,4.87h1.39
          c0.38,0,0.7-0.31,0.7-0.69c0,0,0,0,0,0V7.38C32,5.84,30.75,4.59,29.22,4.59z M21.56,13.64H1.39V7.37c0-0.77,0.62-1.39,1.39-1.39
          h18.78V13.64z M30.61,13.64h-7.65V5.98h6.27c0.77,0,1.39,0.62,1.39,1.39L30.61,13.64z"
        />
        <circle cx="25.11" cy="24.39" r="3.42" />
      </svg>

    );
    case 'Car': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M31.24,15.5l-2.05-2.56l-1.2-9.04c-0.14-1.04-1.02-1.81-2.07-1.81H6.09
          c-1.04,0-1.93,0.78-2.07,1.81l-1.21,9.04L0.76,15.5C0.27,16.12,0,16.88,0,17.67v7.37c0,0.38,0.31,0.7,0.7,0.7c0,0,0,0,0,0H31.3
          c0.38,0,0.7-0.31,0.7-0.7v0v-7.37C32,16.88,31.73,16.12,31.24,15.5z M5.4,4.08c0.05-0.34,0.34-0.6,0.69-0.6h19.83
          c0.35,0,0.64,0.26,0.69,0.6l1.12,8.44H4.27L5.4,4.08z M6.26,21.57c-1.15,0-2.09-0.93-2.09-2.09c0-1.15,0.93-2.09,2.09-2.09
          s2.09,0.93,2.09,2.09l0,0C8.35,20.63,7.41,21.57,6.26,21.57C6.26,21.56,6.26,21.56,6.26,21.57L6.26,21.57z M18.08,20.17h-4.17
          c-0.38,0-0.7-0.31-0.7-0.7c0-0.38,0.31-0.7,0.7-0.7h4.17c0.38,0,0.7,0.31,0.7,0.7C18.78,19.86,18.47,20.17,18.08,20.17L18.08,20.17
          L18.08,20.17z M25.74,21.57c-1.15,0-2.09-0.93-2.09-2.09s0.93-2.09,2.09-2.09c1.15,0,2.09,0.93,2.09,2.09
          C27.82,20.63,26.89,21.56,25.74,21.57L25.74,21.57z"
        />
        <path d="M2.09,27.13v2.09c0,0.38,0.31,0.7,0.7,0.7c0,0,0,0,0,0h5.57c0.38,0,0.7-0.31,0.7-0.7l0,0
          v-2.09H2.09z"
        />
        <path d="M22.96,27.13v2.09c0,0.38,0.31,0.7,0.7,0.7l0,0h5.57c0.38,0,0.7-0.31,0.7-0.7l0,0v-2.09H22.96
          z"
        />
      </svg>
    );
    case 'Fire Truck': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M19.05,10.44v8.19c-0.05,0-0.08,0.01-0.12,0.01h-6.15c-0.44,0-0.89,0-1.33,0
          c-0.75,0.02-1.37-0.57-1.4-1.32c0-0.02,0-0.03,0-0.05c-0.01-0.53,0-1.06,0-1.6c0-1.28,0-2.55,0-3.83c-0.03-0.75,0.55-1.37,1.3-1.4
          c0.03,0,0.06,0,0.09,0c2.48,0,4.96,0,7.45,0H19.05z"
        />
        <path d="M7.68,23.75H6.51c-0.46-1.47-2.02-2.3-3.49-1.84c-0.88,0.27-1.57,0.96-1.84,1.84H0v-0.19
          c0-1.85-0.01-3.7,0-5.55c-0.02-1.54,0.65-3.01,1.83-4c0.4-0.35,0.85-0.63,1.33-0.83c0.07-0.03,0.12-0.09,0.15-0.17
          c0.11-0.4,0.42-0.72,0.82-0.84c0-0.27,0-0.54,0-0.82c0-0.66,0.55-1.2,1.21-1.2c0.62,0,1.13,0.48,1.19,1.09c0.02,0.32,0,0.64,0,0.95
          c0.25,0.1,0.46,0.28,0.6,0.5c0.04,0.04,0.09,0.06,0.14,0.06c0.13,0.01,0.25,0,0.4,0L7.68,23.75z M1.86,17.44h4.46v-2.62H6.11
          c-0.53,0-1.05,0-1.58,0c-1.45-0.03-2.65,1.13-2.68,2.58C1.85,17.41,1.85,17.42,1.86,17.44L1.86,17.44z"
        />
        <path d="M23.54,18.63v-8.18c0.04,0,0.08-0.01,0.13-0.01c2.23,0,4.45,0,6.68,0
          c0.8-0.02,1.46,0.61,1.48,1.41c0,0.03,0,0.05,0,0.08c0,1.73,0,3.47,0,5.2c0.02,0.81-0.61,1.48-1.41,1.51c-0.03,0-0.06,0-0.09,0
          c-2.21,0-4.42,0-6.63,0L23.54,18.63z"
        />
        <path d="M24.01,23.7c-0.38,0-0.77,0-1.15-0.01c-0.05,0-0.11-0.07-0.14-0.13
          c-0.59-1.41-2.21-2.08-3.62-1.5c-0.68,0.28-1.22,0.82-1.5,1.5c-0.02,0.09-0.11,0.15-0.21,0.13c-2.32,0-4.64,0-6.96,0
          c-0.52,0.03-0.96-0.37-0.99-0.89c0-0.04,0-0.07,0-0.11c0-0.34,0-0.69,0-1.03c-0.02-0.53,0.39-0.97,0.92-0.99c0.03,0,0.05,0,0.08,0
          h9.5c1.76,0,3.52,0.01,5.28,0.01c1.58,0,3.15,0,4.73-0.01h2.05c0,0.44,0.02,0.86-0.01,1.29c-0.01,0.25-0.07,0.5-0.17,0.73
          c-0.28,0.62-0.89,1.01-1.57,0.99c-0.33,0.01-0.67,0-0.98,0c-0.53-1.45-2.14-2.2-3.59-1.67C24.9,22.31,24.29,22.92,24.01,23.7
          L24.01,23.7z"
        />
        <path d="M26.59,22.89c1.13,0.01,2.03,0.93,2.02,2.05c-0.01,1.13-0.93,2.03-2.05,2.02
          c-1.12-0.01-2.02-0.91-2.02-2.03c-0.01-1.12,0.89-2.04,2.01-2.05C26.56,22.89,26.58,22.89,26.59,22.89z"
        />
        <path d="M22.14,24.93c0,1.13-0.91,2.04-2.04,2.04s-2.04-0.91-2.04-2.04c0-1.13,0.91-2.04,2.04-2.04
          c0,0,0,0,0,0c1.12-0.01,2.03,0.89,2.04,2C22.14,24.91,22.14,24.92,22.14,24.93z"
        />
        <path d="M3.91,22.89c1.13,0.01,2.03,0.93,2.02,2.05c-0.01,1.13-0.93,2.03-2.05,2.02
          c-1.13-0.01-2.03-0.93-2.02-2.05c0-1.12,0.9-2.02,2.02-2.02C3.89,22.89,3.9,22.89,3.91,22.89z"
        />
        <path d="M22.29,16.45h-2v-1.3h1.99L22.29,16.45z" />
        <path d="M9.21,6.56c0.31,0,0.54,0.19,0.52,0.44C9.72,7.06,9.7,7.13,9.67,7.2
          C9.39,7.76,9.1,8.33,8.81,8.89c-0.12,0.27-0.44,0.38-0.7,0.26C8.07,9.13,8.03,9.1,7.99,9.07C7.83,8.97,7.78,8.76,7.87,8.6
          C8.17,8,8.48,7.39,8.79,6.8C8.87,6.64,9.03,6.54,9.21,6.56z"
        />
        <path d="M5.93,6.42c0,0.31,0,0.62,0,0.93c0.02,0.23-0.16,0.44-0.39,0.45c-0.03,0-0.06,0-0.08,0
          c-0.34,0-0.52-0.14-0.52-0.44c0-0.62,0-1.24,0-1.86C4.9,5.27,5.07,5.06,5.3,5.03c0.03,0,0.07,0,0.11,0c0.36,0,0.52,0.15,0.53,0.49
          C5.93,5.82,5.93,6.12,5.93,6.42z"
        />
        <path d="M1.85,6.56c0.19-0.01,0.36,0.1,0.44,0.27c0.28,0.57,0.57,1.13,0.86,1.69
          c0.08,0.16,0.14,0.31,0.02,0.47C3.04,9.14,2.84,9.22,2.63,9.19c-0.16-0.01-0.3-0.11-0.36-0.25C1.99,8.37,1.7,7.81,1.41,7.24
          C1.22,6.87,1.42,6.56,1.85,6.56z"
        />
        <path d="M22.29,11.66h-2v-1.21h2V11.66z" />
        <path d="M20.29,13.92v-1.21h2v1.21H20.29z" />
        <path d="M20.29,17.4h1.99v1.22h-1.99V17.4z" />
      </svg>
    );
    case 'Motorcycle': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M29.06,17.66c1.3-1.51,2.94-4.03,2.94-7.23c0-5.57-5.8-7.54-6.04-7.62c-0.25-0.08-0.52-0.02-0.71,0.17
          l-1.39,1.39c-0.15,0.15-0.23,0.37-0.2,0.58c0.03,0.21,0.16,0.4,0.34,0.51c1.2,0.69,2.43,2.14,2.43,2.87c0,0.45-0.21,0.71-0.57,1.11
          l-3.38,3.95c-0.25,0.29-0.69,0.33-0.98,0.08c-0.29-0.25-0.33-0.69-0.08-0.98l2.82-3.3l-0.93-0.31c-3.7-1.23-7.76,0.03-10.09,3.15
          l-0.36,0.47H9.33L6.75,9.94c-0.09-0.09-0.2-0.15-0.32-0.18L0.86,8.37c-0.21-0.05-0.43,0-0.6,0.13S0,8.83,0,9.04v2.78
          c0,0.19,0.08,0.37,0.22,0.51l4.74,4.5C2.13,17.44,0,19.95,0,22.96c0,3.45,2.81,6.26,6.26,6.26c3.22,0,5.87-2.44,6.22-5.57h1.43
          h5.61c0.35,3.13,3,5.57,6.22,5.57c3.45,0,6.26-2.81,6.26-6.26C32,20.73,30.82,18.77,29.06,17.66z M6.26,27.83
          c-2.69,0-4.87-2.18-4.87-4.87c0-2.67,2.15-4.84,4.82-4.87l2.78,2.78H7.65c-0.77,0-1.39,0.62-1.39,1.39s0.62,1.39,1.39,1.39h3.42
          C10.74,26.01,8.71,27.83,6.26,27.83z M25.74,27.83c-2.69,0-4.87-2.18-4.87-4.87s2.18-4.87,4.87-4.87s4.87,2.18,4.87,4.87
          S28.43,27.83,25.74,27.83z"
        />
      </svg>
    );
    case 'Person': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M16,0c-2.31,0-4.17,1.87-4.17,4.17S13.69,8.35,16,8.35s4.17-1.87,4.17-4.17l0,0
          C20.17,1.87,18.3,0,16,0"
        />
        <path d="M13.22,9.74c-2.3,0-4.17,1.86-4.17,4.17c0,0,0,0.01,0,0.01v6.96c0,0.32,0.22,0.6,0.53,0.68
          l2.3,0.57l0.65,9.24c0.03,0.36,0.33,0.64,0.69,0.64h5.57c0.36,0,0.66-0.28,0.69-0.64l0.65-9.24l2.3-0.57
          c0.31-0.08,0.53-0.36,0.53-0.68v-6.96c0-2.3-1.86-4.17-4.17-4.18c0,0-0.01,0-0.01,0H13.22z"
        />
      </svg>
    );
    case 'Truck': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <circle cx="24.5" cy="24.5" r="2.5" />
        <path d="M31.66,17.03l-2.72-0.91l-0.95-5.21c-0.04-0.24-0.25-0.41-0.49-0.41h-8c-0.28,0-0.5,0.22-0.5,0.5v13
          c0,0.28,0.22,0.5,0.5,0.5H21c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5h3.5c0.28,0,0.5-0.22,0.5-0.5v-6.5
          C32,17.28,31.86,17.09,31.66,17.03z"
        />
        <path d="M17,5H1C0.45,5,0,5.45,0,6v18c0,0.28,0.22,0.5,0.5,0.5h2.53C3.01,24.34,3,24.17,3,24c0-2.21,1.79-4,4-4
          s4,1.79,4,4c0,0.17-0.01,0.34-0.03,0.5h6.53c0.28,0,0.5-0.22,0.5-0.5V6C18,5.45,17.55,5,17,5z"
        />
        <circle cx="7" cy="24" r="3" />
      </svg>
    );
    case 'Container': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M12.32,15.97c0-3.21,0-6.43,0-9.64c0-0.53,0.05-0.57,0.57-0.48c5.22,0.98,10.44,1.97,15.66,2.96
          C29.53,9,30.52,9.18,31.5,9.37C32,9.46,32,9.46,32,9.96c0,3.27,0,6.53,0,9.8c0,0.79-0.01,1.59,0,2.38c0,0.3-0.11,0.43-0.4,0.49
          c-2.84,0.53-5.69,1.07-8.53,1.61c-3.41,0.64-6.83,1.28-10.24,1.93c-0.43,0.08-0.5,0.02-0.5-0.42
          C12.32,22.49,12.32,19.23,12.32,15.97C12.32,15.97,12.32,15.97,12.32,15.97z M13.85,15.99c0,0.52,0,1.04,0,1.55
          c0,2.04,0,4.08,0,6.12c0,0.3,0.1,0.41,0.39,0.35c0.18-0.04,0.35-0.07,0.53-0.07c0.3-0.01,0.39-0.16,0.39-0.45c-0.01-5-0.01-10,0-15
          c0-0.26-0.08-0.39-0.35-0.41c-0.2-0.01-0.4-0.03-0.6-0.08c-0.3-0.08-0.36,0.04-0.36,0.32C13.86,10.87,13.85,13.43,13.85,15.99z
          M16.73,15.98c0,2.31,0,4.63,0,6.94c0,0.76,0,0.74,0.75,0.63c0.34-0.05,0.44-0.2,0.44-0.54c-0.01-4.58-0.01-9.15-0.01-13.73
          c0-0.85,0.12-0.7-0.71-0.86c-0.44-0.09-0.47-0.06-0.47,0.39C16.73,11.2,16.73,13.59,16.73,15.98z M19.31,15.98
          c0,2.29,0,4.59,0,6.88c0,0.34,0.05,0.39,0.39,0.34c0.07-0.01,0.15-0.02,0.22-0.02c0.46-0.05,0.47-0.06,0.47-0.51
          c0-4.36,0-8.73,0-13.09c0-0.68,0.13-0.67-0.63-0.75c-0.45-0.05-0.45-0.06-0.45,0.37C19.31,11.45,19.31,13.72,19.31,15.98z
          M21.66,15.97c0,0.45,0,0.91,0,1.36c0,1.72,0,3.44,0.01,5.17c0,0.12,0.06,0.3,0.14,0.34c0.37,0.17,0.8-0.14,0.8-0.55
          c0-4.15,0-8.3,0-12.46c0-0.56,0-0.56-0.54-0.66c-0.38-0.07-0.41-0.04-0.41,0.36C21.66,11.68,21.66,13.83,21.66,15.97z M23.74,16
          c0,0.38,0,0.76,0,1.14c0,1.67,0,3.34,0,5.01c0,0.12,0.03,0.3,0.11,0.36c0.28,0.19,0.78-0.12,0.78-0.45c0-3.99,0-7.99,0-11.98
          c0-0.48,0-0.48-0.46-0.57c-0.43-0.09-0.45-0.07-0.45,0.37C23.74,11.92,23.74,13.96,23.74,16z M25.72,16.01c0,1.45,0,2.89,0,4.34
          c0,0.54-0.01,1.08,0.01,1.62c0,0.1,0.11,0.28,0.19,0.29c0.35,0.07,0.62-0.17,0.62-0.52c0-3.85,0-7.69,0-11.54
          c0-0.36-0.09-0.41-0.57-0.42c-0.34-0.01-0.24,0.23-0.24,0.4C25.72,12.12,25.72,14.07,25.72,16.01z M29.1,16.01
          c0,0.51,0,1.01,0,1.52c0,1.28,0,2.56,0.01,3.84c0,0.12,0.08,0.32,0.16,0.34c0.33,0.1,0.56-0.09,0.56-0.43c0-3.53,0-7.06,0-10.59
          c0-0.32-0.1-0.4-0.46-0.4c-0.3,0-0.27,0.2-0.27,0.39C29.1,12.46,29.1,14.23,29.1,16.01z M27.48,16c0,0.42,0,0.85,0,1.27
          c0,1.45,0,2.9,0.01,4.34c0,0.11,0.07,0.29,0.15,0.33c0.28,0.13,0.56-0.08,0.56-0.4c0-3.69,0-7.38,0-11.07
          c0-0.34-0.11-0.43-0.5-0.42c-0.3,0.01-0.21,0.24-0.21,0.4C27.48,12.3,27.48,14.15,27.48,16z M30.6,16.01c0,1.71,0,3.43,0,5.14
          c0,0.36,0.06,0.4,0.41,0.32c0.24-0.05,0.22-0.22,0.22-0.39c0-3.38,0-6.77,0-10.15c0-0.33-0.1-0.44-0.4-0.43
          c-0.28,0.01-0.23,0.23-0.23,0.4C30.6,12.61,30.6,14.31,30.6,16.01z"
        />
        <path d="M11.41,15.97c0,3.25,0,6.49,0,9.74c0,0.49-0.04,0.53-0.53,0.45c-1.47-0.23-2.94-0.47-4.41-0.7
          C6.18,25.42,6.13,25.25,6.13,25c0-3.8,0-7.59,0-11.39c0-2.18,0-4.36,0-6.53c0-0.47,0-0.47,0.45-0.54c1.48-0.24,2.96-0.48,4.44-0.72
          c0.31-0.05,0.39,0.02,0.39,0.35C11.41,9.44,11.41,12.71,11.41,15.97z M7.64,15.47C7.64,15.47,7.64,15.47,7.64,15.47
          c-0.26,0-0.53-0.01-0.79,0.01c-0.27,0.02-0.4,0.19-0.4,0.45c0,0.24,0.13,0.42,0.37,0.43c0.53,0.02,1.06,0.02,1.58,0
          c0.25-0.01,0.39-0.2,0.38-0.46c-0.01-0.27-0.16-0.41-0.42-0.42C8.13,15.46,7.88,15.47,7.64,15.47z"
        />
        <path d="M5.32,15.97c0,2.89,0,5.77,0,8.66c0,0.53-0.07,0.57-0.6,0.49c-1.41-0.23-2.82-0.45-4.22-0.67C0.02,24.37,0,24.36,0,23.87
          c0-5.26,0-10.53,0-15.79C0,7.64,0.02,7.6,0.44,7.54c1.5-0.24,3-0.48,4.5-0.72c0.32-0.05,0.38,0.02,0.38,0.4c0,2.06,0,4.12,0,6.18
          C5.32,14.26,5.32,15.12,5.32,15.97z M3.47,15.47C3.47,15.47,3.47,15.47,3.47,15.47c-0.25,0-0.51-0.01-0.76,0.01
          c-0.24,0.02-0.39,0.15-0.4,0.4c-0.02,0.26,0.1,0.46,0.36,0.48c0.53,0.03,1.05,0.02,1.58,0c0.25-0.01,0.41-0.19,0.4-0.45
          c-0.01-0.27-0.17-0.42-0.45-0.43C3.96,15.46,3.71,15.47,3.47,15.47z"
        />
      </svg>
    );
    case 'Container Ship': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M0.03,17.79c0.8,0,1.54,0,2.38,0c0-2.2,0-4.35,0-6.56c0.56,0,1.01,0,1.57,0c0,1.08,0,2.16,0,3.28c0.42,0,0.74,0,1.12,0
          c0.03-0.52,0.05-0.99,0.08-1.55c0.5,0,0.95,0,1.48,0c0-1.38,0-2.68,0-4.03c0.4,0,0.71,0,1.12,0c0,1.33,0,2.64,0,4.01
          c0.7,0,1.32,0,2,0c0,0.63,0,1.19,0,1.82c-0.21,0.05-0.44,0.1-0.72,0.16c0,0.93,0,1.85,0,2.84c7.63,0,15.22,0,22.93,0
          c-0.14,0.29-0.21,0.48-0.32,0.66c-0.88,1.39-1.75,2.79-2.67,4.15c-0.16,0.24-0.55,0.47-0.83,0.47c-8.32,0.02-16.64,0.03-24.96,0.01
          c-1.62,0-2.97-1.18-3.17-2.78C-0.05,19.49,0.03,18.69,0.03,17.79z"
        />
        <path d="M12.95,16.99c0-0.67,0-1.3,0-1.98c1.28,0,2.56,0,3.88,0c0,0.63,0,1.27,0,1.98C15.58,16.99,14.31,16.99,12.95,16.99z" />
        <path d="M22.35,16.99c0-0.68,0-1.3,0-1.99c1.29,0,2.56,0,3.89,0c0,0.64,0,1.28,0,1.99C24.98,16.99,23.71,16.99,22.35,16.99z" />
        <path d="M21.51,17.02c-1.28,0-2.5,0-3.8,0c0-0.68,0-1.34,0-2.03c1.25,0,2.5,0,3.8,0C21.51,15.65,21.51,16.28,21.51,17.02z" />
        <path d="M12.96,13.95c0-0.68,0-1.29,0-1.96c1.31,0,2.58,0,3.89,0c0,0.66,0,1.27,0,1.96C15.57,13.95,14.3,13.95,12.96,13.95z" />
        <path d="M22.37,13.96c0-0.69,0-1.31,0-1.97c1.29,0,2.54,0,3.86,0c0,0.63,0,1.27,0,1.97C24.96,13.96,23.7,13.96,22.37,13.96z" />
        <path d="M21.52,12c0,0.66,0,1.28,0,1.95c-1.27,0-2.52,0-3.83,0c0-0.62,0-1.26,0-1.95C18.95,12,20.22,12,21.52,12z" />
      </svg>
    );
    case 'Tug': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M0.29,26.84l0.38-2.4H0v-1.16h0.85l0.17-1.08h0.67v-2.96h1.16v2.96h2.37v0.04h0.01
          C5.22,22.16,5.22,22.08,5.22,22c0-1.62,1.32-2.94,2.94-2.94s2.94,1.32,2.94,2.94c0,0.08,0,0.16-0.01,0.24h8.99l1.19-1.49l7.7-1.25
          l1.45-0.18l1.26,0.79l-0.39,1.6H32l-0.63,2.51h-0.7l-0.64,2.62H0.29z"
        />
        <polygon points="12.84,21.08 12.84,16.49 15.93,16.49 16.67,17.42 23.62,17.42 24.03,19.13 20.64,19.68 19.51,21.08 " />
        <path d="M22.24,12.62l-1-1l-4.82-0.02l-0.09-0.72h0.96V9.72h-1.1l-0.55-4.57l-1.15,0.14l0.77,6.3l-0.6,0l-1,1l0.33,2.92
          h2.3l0.87,1.11h4.52l-0.18-1.52L22.24,12.62z M15.97,14.2H15l-0.17-1.3h1.16L15.97,14.2z M18.72,14.97h-1.09V12.9h1.09V14.97z
          M20.33,14.97h-0.45V12.9h1.11L20.33,14.97z"
        />
      </svg>
    );
    case 'Yacht': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M19.32,21.78c-3.95,0-7.77,0-11.77,0C12.78,15.92,16.54,9.27,20,2.2c0,7.93,0,15.64,0,23.41
          c-0.18,0.02-0.38,0.05-0.68,0.08C19.32,24.38,19.32,23.13,19.32,21.78z"
        />
        <path d="M0,26.53c10.73,0,21.31,0,31.9,0c0.03,0.05,0.07,0.11,0.1,0.16c-1.16,0.76-2.3,1.54-3.47,2.29
          c-0.93,0.59-1.97,0.84-3.08,0.82c-7.03-0.13-14.07-0.24-21.1-0.41C2.42,29.35,1.06,28.26,0,26.53z"
        />
        <path d="M21.23,5.75c4.7,5.88,7.43,12.45,9.3,19.69c-2.85-2.22-5.83-3.57-9.3-3.71C21.23,16.46,21.23,11.22,21.23,5.75z" />
        <path d="M18.41,25.64c-4.65,0-9.29,0-14.05,0c0.48-0.91,0.9-1.76,1.38-2.59c0.08-0.14,0.35-0.23,0.54-0.23
          c2.35-0.02,4.7-0.01,7.06-0.01c0.16,0,0.34,0.03,0.48,0.11c1.55,0.86,3.09,1.73,4.63,2.59C18.43,25.56,18.42,25.6,18.41,25.64z
          M9.75,23.64c-0.68,0-1.32,0-1.92,0c0,0.48,0,0.87,0,1.26c0.66,0,1.27,0,1.92,0C9.75,24.47,9.75,24.07,9.75,23.64z M12.34,24.9
          c0-0.46,0-0.86,0-1.29c-0.67,0-1.3,0-1.95,0c0,0.45,0,0.85,0,1.29C11.04,24.9,11.65,24.9,12.34,24.9z M15.63,24.91
          c-0.85-0.5-1.59-1.23-2.65-1.39c0,0.55,0,0.96,0,1.39C13.9,24.91,14.77,24.91,15.63,24.91z M5.69,24.89c0.49,0,0.98,0,1.51,0
          c0-0.26,0.01-0.48,0-0.69C7.19,24,7.17,23.79,7.16,23.6C6.21,23.53,6.06,23.66,5.69,24.89z"
        />
      </svg>
    );
    case 'Heartbeat': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path d="M18.7,23.062a1,1,0,0,1-1.632-.046l-4.938-7.408L10.99,17.514a1,1,0,0,1-.857.486H3.956C8.326,24.634,16,30.964,16,30.964S23.674,24.634,28.044,18H22.5Z" />
        <path d="M30.18,14A10.12,10.12,0,0,0,31,10.315,8.169,8.169,0,0,0,16,5.727,8.3,8.3,0,0,0,9.2,2.036,8.248,8.248,0,0,0,1,10.315,10.123,10.123,0,0,0,1.821,14H0v2H9.566l2.479-4.131,5.907,8.861L21.5,16H32V14Z" />
      </svg>
    );
    case 'QuestionMark': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path d="M29,0H3A1,1,0,0,0,2,1V31a1,1,0,0,0,1,1H29a1,1,0,0,0,1-1V1A1,1,0,0,0,29,0ZM16,26a2,2,0,1,1,2-2A2,2,0,0,1,16,26Zm4.926-11.645c-.958,1.142-2.715,2.138-3.374,3.144a3.323,3.323,0,0,0-.382,1.908H14.79a5.552,5.552,0,0,1,.486-2.794,6.314,6.314,0,0,1,1.7-1.833c1.6-1.267,2.282-1.926,2.282-3.277,0-1.5-1.123-2.112-2.634-2.112a8.476,8.476,0,0,0-4.054,1.117l-1.02-2.136A10.681,10.681,0,0,1,16.829,7,5.707,5.707,0,0,1,20.6,8.165,3.963,3.963,0,0,1,22,11.382,4.319,4.319,0,0,1,20.926,14.355Z" />
      </svg>
    );
    case 'Other': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
        <path d="M31.86,7.49c-0.07-0.17-0.17-0.33-0.32-0.45L23.47,0.3C23.26,0.13,23,0.04,22.73,0.04h-5.39h-2.69H9.27
          C9,0.04,8.74,0.13,8.53,0.3L0.45,7.04C0.31,7.16,0.21,7.32,0.14,7.49C0.06,7.64,0,7.81,0,8c0,0.07,0.03,0.13,0.04,0.2v22.62
          c0,0.63,0.51,1.14,1.14,1.14h29.63c0.63,0,1.14-0.51,1.14-1.14V8.2C31.97,8.13,32,8.07,32,8C32,7.81,31.94,7.64,31.86,7.49z
          M22.32,2.33l5.44,4.53h-6.91l-1.81-4.53H22.32z M15.43,2.33h1.15l1.81,4.53h-4.77L15.43,2.33z M13.1,9.14h5.8l0,7.06h-5.8V9.14z
          M9.68,2.33h3.29l-1.81,4.53H4.24L9.68,2.33z M29.67,29.67H2.33V9.14h8.49v8.2c0,0.63,0.51,1.14,1.14,1.14h8.08
          c0.3,0,0.59-0.12,0.81-0.33c0.21-0.21,0.33-0.51,0.33-0.81l0-8.2h8.49V29.67z"
        />
      </svg>
    );
    default: return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path d="M29,0H3A1,1,0,0,0,2,1V31a1,1,0,0,0,1,1H29a1,1,0,0,0,1-1V1A1,1,0,0,0,29,0ZM16,26a2,2,0,1,1,2-2A2,2,0,0,1,16,26Zm4.926-11.645c-.958,1.142-2.715,2.138-3.374,3.144a3.323,3.323,0,0,0-.382,1.908H14.79a5.552,5.552,0,0,1,.486-2.794,6.314,6.314,0,0,1,1.7-1.833c1.6-1.267,2.282-1.926,2.282-3.277,0-1.5-1.123-2.112-2.634-2.112a8.476,8.476,0,0,0-4.054,1.117l-1.02-2.136A10.681,10.681,0,0,1,16.829,7,5.707,5.707,0,0,1,20.6,8.165,3.963,3.963,0,0,1,22,11.382,4.319,4.319,0,0,1,20.926,14.355Z" />
      </svg>
    );
  }
};

export default ButtonIcon;
