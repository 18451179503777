import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  dragAndDropTable: {
    width: '100%',
    padding: 0,
    borderCollapse: 'collapse',
    '& caption': {
      borderTop: theme.border.default,
      padding: 16,
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'left'
    },
    '& th:last-of-type, & td:last-of-type': {
      textAlign: 'right'
    },
    '& thead tr': {
      borderBottom: theme.border.default,
      '& th': {
        padding: 16,
        fontWeight: 500
      }
    },
    '& tbody tr': {
      transition: 'background-color 300ms ease',
      '& td': {
        padding: 16
      },
      '&:hover': {
        backgroundColor: theme.border.color
      }
    }
  },
  isDragging: {
    backgroundColor: theme.palette.background.overlay
  },
  noVoiceContacts: {
    color: '#d32f2f',
    '& td:only-child': {
      textAlign: 'center'
    },
    '&:only-child:hover': {
      backgroundColor: 'transparent'
    }
  },
  draggingTr: {
    color: theme.palette.common.text,
    backgroundColor: theme.border.color
  },
  draggingTd: {
    borderBottom: 'none!important'
  },
  numberIndexColumn: {
    width: '2.5%'
  },
  draggableIconColumn: {
    width: '10%'
  }
}));
