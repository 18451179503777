import Smart from 'hocs/smart';
import AlertDataLoader from './alert-view';

export default Smart(
  AlertDataLoader,
  state => ({
    organisationId: state.session.organisationId,
    now: state.app.now,
    selectedMapId: state.map.selectedMapId,
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // assetState: state.assetState
  }),
  ac => ({
    selectItem: ac.app.selectItem,
    navigate: ac.router.navigate,
    assignItemToMap: ac.map.assignItemToMap
  })
);
