import { sortBy } from 'lodash';
import { queryClient } from 'react-query-client';
import { createSelector } from 'reselect';
import moment from 'moment';
import {
  altitude, bearing, coordinate, distance, speed, calculateDistance
} from 'helpers/unitsOfMeasure';
// eslint-disable-next-line import/no-cycle
import { getAsset, getAssetIds } from './assets';
// eslint-disable-next-line import/no-cycle
import { selectedLeg as getSelectedLeg } from './legs';

export const getReportsForDevice = createSelector(
  [
    ({ deviceId }) => deviceId,
    ({ now }) => now,
    ({ deviceId, now }) => queryClient.getQueryState(['deviceReports', deviceId?.toString(), now])?.dataUpdatedAt,
  ],
  (deviceId, now, dataUpdatedAt) => {
    if (!deviceId) return [];
    return queryClient.getQueryData(['deviceReports', deviceId?.toString(), now]) || [];
  },
  { memoizeOptions: { maxSize: 2000 } }
);

export const reportsForAssetOrdered = createSelector(
  ({ assetId, organisationId, now }) => getReportsForDevice({ deviceId: getAsset({ assetId, organisationId, now })?.deviceId, now }),
  reports => sortBy(reports, r => r.time),
  { memoizeOptions: { maxSize: 2000 } }
);

export const reportsWithPositionsForAssetOrdered = createSelector(
  ({ assetId, organisationId, now }) => reportsForAssetOrdered({ assetId, organisationId, now }),
  reports => (reports || []).filter(report => report.position?.accuracy.isValid),
  { memoizeOptions: { maxSize: 2000 } }
);

export const latestReportForAsset = createSelector(
  ({ assetId, organisationId, now }) => reportsWithPositionsForAssetOrdered({ assetId, organisationId, now }),
  assetReports => (assetReports?.length > 0 ? assetReports[assetReports.length - 1] : null),
  { memoizeOptions: { maxSize: 2000 } }
);

const distanceSq = (aX, aY, bX, bY) => ((aX - bX) ** 2) + ((aY - bY) ** 2);
export const redactedReportsForAssetAtZoom = createSelector(
  [
    ({ assetId, organisationId, now }) => reportsWithPositionsForAssetOrdered({ assetId, organisationId, now }),
    ({ zoom }) => zoom
  ],
  (assetReports, zoom) => {
    const redactedReports = [];
    let lastReport;
    assetReports.forEach((report, ix) => {
      if (report.position.accuracy?.isValid
        && (!lastReport
          || ix === assetReports.length - 1
          || distanceSq(lastReport.position.longitude, lastReport.position.latitude, report.position.longitude, report.position.latitude) >= zoom
          || report.position.event)) {
        lastReport = report;
        redactedReports.push(report);
      }
    });
    return redactedReports;
  },
  { memoizeOptions: { maxSize: 2000 } }
);

export const redactedReportsRangeForAssetAtZoom = createSelector(
  [
    ({ assetId, organisationId, now }) => (assetId ? reportsForAssetOrdered({ assetId, organisationId, now }) : null),
    ({ zoom }) => zoom,
    ({ range }) => range
  ],
  (assetReports, zoom, range) => {
    if (!assetReports || !range) return [];
    const redactedReports = [];
    let slicedReports = [];
    let started = false;
    assetReports.forEach(report => {
      if (report.id === range.start) started = true;
      if (started) {
        slicedReports.push(report);
      }
      if (report.id === range.end) started = false;
    });
    slicedReports = slicedReports.filter(report => report.position?.accuracy.isValid);
    let lastReport;
    slicedReports.forEach((report, ix) => {
      if (report.position.accuracy?.isValid
        && (!lastReport
        || ix === assetReports.length - 1
        || distanceSq(lastReport.position.longitude, lastReport.position.latitude, report.position.longitude, report.position.latitude) >= zoom
        || report.position.event)) {
        lastReport = report;
        redactedReports.push(report);
      }
    });
    return redactedReports;
  },
  { memoizeOptions: { maxSize: 2000 } }
);

export const redactedReportsForZoomByAsset = createSelector(
  [
    (state, { organisationId, now }) => getAssetIds({ organisationId, now }),
    (state, { zoom }) => zoom,
    (state, { organisationId }) => organisationId,
    (state, { now }) => now,
    state => state.positions.mostRecentDeviceReport, // bust cache when new device reports arrive
  ],
  (assetIds, zoom, organisationId, now) => assetIds.reduce((acc, assetId) => {
    acc[assetId] = redactedReportsForAssetAtZoom({
      zoom, assetId, organisationId, now
    });
    return acc;
  }, {}),
  { memoizeOptions: { maxSize: 2000 } }
);

export const analysisBoxReports = createSelector(
  [
    state => state.app.selectedItem, // TODO: should this be referring to selected item for the currently selected map split???
    state => getSelectedLeg(state),
    state => getReportsForDevice({ deviceId: state.app.selectedItem?.deviceId, now: state.app.now }),
    state => state.settings.locale.timezone,
    state => state.settings.units,
  ],
  (selectedItem, selectedLeg, reports, timezone, units) => {
    // console.log('analysisBoxReports', selectedItem, selectedLeg, reports.length);
    if (!selectedItem && !selectedLeg) return [];

    let selectedReports = [];
    if (selectedItem) {
      if (selectedLeg && reports?.length) {
        selectedReports = reports.filter(r => moment(r.time).isSameOrAfter(selectedLeg.start) && moment(r.time).isSameOrBefore(selectedLeg.end || moment()));
      } else if (selectedItem && reports?.length) {
        selectedReports = reports;
      } else {
        selectedReports = [];
      }
    }

    const dateSort = (a, b) => moment(a.time).tz(timezone).unix() - moment(b.time).tz(timezone).unix();
    return selectedReports?.sort(dateSort).map((r, i, a) => {
      // const textRecipient = r.metadata?.recipient;
      // const textMessage = r.metadata?.text;
      const compareTimes = (end, start) => moment
        .utc(moment.duration(moment(end).tz(timezone).diff(moment(start).tz(timezone)), 'seconds').as('seconds'))
        .format('H:mm:ss');
      return {
        id: r.id,
        timestamp: (r.time && timezone)
          ? moment.isMoment(r.time)
            ? r.time.tz(timezone).format('D MMM YYYY H:mm:ss z')
            : moment(r.time).tz(timezone).format('D MMM YYYY H:mm:ss z')
          : '--',
        position: r.position,
        coordinates: (r.position?.latitude && r.position?.longitude && units.coordinate)
          ? coordinate.fromLatLon(r.position.latitude, r.position.longitude, units.coordinate)
          : '--',
        altitude: (r.position?.altitude && units.altitude)
          ? altitude.fromSI(r.position.altitude, units.altitude)
          : '--',
        bearing: (r.position?.course && r.time && units.bearing)
          ? bearing.fromSI(r.position.course, r.time, r.position, units.bearing)
          : '--',
        speed: (r.position?.speed && units.speed)
          ? speed.fromKmh(r.position.speed, units.speed)
          : '--',
        event: r.event?.label
          ? r.event.label
          : 'Standard',
        // text: (textRecipient && textMessage)
        //   ? `${textRecipient}: ${textMessage}`
        //   : '--',
        dop: r.position?.accuracy?.pdop
          ? r.position.accuracy.pdop
          : '--',
        latency: (r.logged && r.time)
          ? compareTimes(r.logged, r.time)
          : '--',
        elapsed: (a[i - 1]?.time && r.time)
          ? compareTimes(r.time, a[i - 1].time)
          : '--',
        distance: (a[i - 1]?.position && r?.position)
          ? calculateDistance(a[i - 1].position, r.position, units.distance)
          : '--',
        gateway: r.gateway
          ? r.gateway
          : '--',
        metadata: r.metadata
          ? JSON.stringify(r.metadata, null, 1).slice(1, -1).replace(/["|\n]/g, '')
          : '--',
      };
    });
  },
  { memoizeOptions: { maxSize: 2000 } },
);
