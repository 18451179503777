import React from 'react';
import useStyles from './huePointer-styles';

const CustomColorPickerPointerCircle = () => {
  const classes = useStyles();

  return (
    <div className={classes.picker} />
  );
};

export default CustomColorPickerPointerCircle;
