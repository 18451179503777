import { IconLayer } from '@deck.gl/layers';

import pulseFragmentShader from './shaders/pulse-fragment-shader.glsl';

export default class PulseIconLayer extends IconLayer {
  draw({ uniforms }) {
    super.draw({
      uniforms:
      {
        ...uniforms,
        time: this.context.timeline.time,
        speedModifier: this.props.speedModifier
      }
    });
  }

  getShaders() {
    return { ...super.getShaders(), fs: pulseFragmentShader };
  }
}

PulseIconLayer.defaultProps = {
  speedModifier: 1.0
};
