import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    marginLeft: theme.spacing(2),
    marginRight: 'auto'
  },
  icon: {
    top: 'calc(50% - 10px)',
    color: theme.palette.common.whiteConstant,
    right: '6px',
    position: 'absolute',
    pointerEvents: 'none'
  },
  input: {
    '& div': {
      backgroundColor: theme.palette.primary.darkBlue,
      color: theme.palette.common.whiteConstant,
      border: 'none',
      fontWeight: '500',
      lineHeight: '1.2',
      padding: '10px 30px 10px 12px',
      height: 'inherit',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: theme.palette.background.hamburgerButton
      },
      '&:focus': {
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }
    }
  }
}));
