import React, { useState } from 'react';
import { Block as BlockIcon, Delete as DeleteIcon } from '@mui/icons-material';
import insensitiveSort from 'utils/insensitiveSort';
import {
  Paper, IconButton, Tooltip, Typography, Box
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import Select from 'components/shared/forms/inputs/select';
import MaterialTable from '@material-table/core';
import ConfirmDialog from 'components/shared/confirmDialog';
import StaffIndicator from 'components/shared/staffIndicator';
import tableIcons from 'components/shared/icons/tableIcons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { cancelInvitation } from 'apis/auth';
import { removeMember, updateRole } from 'apis/organisations';
import { getMembers } from 'apis/trackstar/serenity';
import useStyles from './members-styles';

/* case insensitive search/filter for the rows, using fields on memberRow */
const customFilterAndSearch = (term, memberRow, fieldA, fieldB) => {
  let field;
  if (memberRow.user) {
    field = memberRow.user[fieldA];
  } else {
    field = memberRow[fieldB];
  }
  if (!field) field = '';
  return field.toLowerCase().indexOf(term.toLowerCase()) > -1;
};

/* sorts on role, comparing against a fixed value if the row contains a non user object */
const customRoleSort = (memberRowA, memberRowB, nonRoleText) => {
  let valueA = nonRoleText;
  let valueB = nonRoleText;

  if (memberRowA?.role?.label) {
    valueA = memberRowA.role.label;
  }
  if (memberRowB?.role?.label) {
    valueB = memberRowB.role.label;
  }

  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

/* filters on role, comparing against a fixed value if the row contains a non user object */
const customRoleFilterSearch = (term, memberRow, nonRoleText) => {
  let value = nonRoleText;
  if (memberRow?.role?.label) {
    value = memberRow.role.label;
  }
  return value.toLowerCase().indexOf(term.toLowerCase()) > -1;
};

const deleteAction = (member, organisation, t, doRemoveMember, showDialog) => (
  <Tooltip title={t('deleteButton')} placement="top">
    <span>
      <IconButton
        aria-label="delete"
        size="small"
        disabled={!member.role}
        onClick={() => {
          showDialog(t('removeUserFromOrganisation'), t('removeUserDialogText', { userName: member.name, organisationName: organisation.name }), () => {
            doRemoveMember.mutate({ orgId: organisation.id, userId: member.id });
          });
        }}
      >
        <DeleteIcon />
      </IconButton>
    </span>
  </Tooltip>
);

const cancelInviteAction = (member, organisation, t, doCancelInvitation, showDialog) => (
  <Tooltip title={t('cancelInviteButton')} placement="top">
    <IconButton
      aria-label="cancel"
      size="small"
      onClick={() => {
        showDialog(t('cancelInviteToOrganisation'), t('cancelInviteDialogText', { name: member.name, email: member.email, organisationName: organisation.name }), () => {
          doCancelInvitation.mutate({ orgId: organisation.id, inviteId: member.inviteId });
        });
      }}
    >
      <BlockIcon />
    </IconButton>
  </Tooltip>
);

const OrganisationMembers = ({
  user,
  organisation,
  permissions,
  displaySnackbar,
}) => {
  const t = useTranslation('pages.organisationSettings');
  const classes = useStyles();
  const queryClient = useQueryClient();
  const orgMembers = useQuery(['organisationMembers', organisation.id], () => getMembers());

  //
  // Update Role
  //
  const doUpdateRole = useMutation(updateRole, {
    onMutate: async variables => {
      // Optimistic update
      await queryClient.cancelQueries(['organisationMembers', variables.orgId]);
      const previousMembers = queryClient.getQueryData(['organisationMembers', variables.orgId]);
      const newRole = user.allRoles.find(r => r.id === variables.roleId);
      queryClient.setQueryData(
        ['organisationMembers', variables.orgId],
        // This digs out the member whose role is being updated and changes the role label optimistically in react-query cache
        old => ({ ...old, members: old.members.map(m => (m.id === variables.userId ? { ...m, role: newRole } : m)) })
      );
      return { previousMembers };
    },
    onError: (err, variables, context) => {
      // Roll back optimistic update if unfriend fails
      const previousRole = context?.previousMembers.members.find(m => m.id === variables.userId)?.role.id;
      queryClient.setQueryData(['organisationMembers', variables.orgId], context?.previousMembers);
      displaySnackbar({ id: 'userRemovedError', text: t('roleUpdateError'), type: 'error' });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'userRemoved', text: t('roleUpdated'), type: 'success' });
    },
    onSettled: () => {
      // Fetch latest members on error or success
      queryClient.invalidateQueries(['organisationMembers', organisation.id]);
    },
    mutationKey: 'updateRole',
  });

  //
  // Remove User
  //
  const doRemoveMember = useMutation(removeMember, {
    onMutate: async variables => {
      // Optimistic update
      await queryClient.cancelQueries(['organisationMembers', variables.orgId]);
      const previousMembers = queryClient.getQueryData(['organisationMembers', variables.orgId]);
      queryClient.setQueryData(
        ['organisationMembers', variables.orgId],
        old => ({ ...old, members: old.members.filter(m => (!(m.id === variables.userId))) })
      );
      return { previousMembers };
    },
    onError: (err, variables, context) => {
      // Roll back optimistic update if unfriend fails
      queryClient.setQueryData(['organisationMembers', variables.orgId], context?.previousMembers);
      displaySnackbar({ id: 'userRemovedError', text: t('userRemovedError'), type: 'error' });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'userRemoved', text: t('userRemoved'), type: 'success' });
    },
    onSettled: () => {
      // Fetch latest members on error or success
      queryClient.invalidateQueries(['organisationMembers', organisation.id]);
    },
    mutationKey: 'removeMember',
  });

  //
  // Cancel Invite
  //
  const doCancelInvitation = useMutation(cancelInvitation, {
    onMutate: async variables => {
      // Optimistic update
      await queryClient.cancelQueries(['organisationMembers', variables.orgId]);
      const previousMembers = queryClient.getQueryData(['organisationMembers', variables.orgId]);
      queryClient.setQueryData(
        ['organisationMembers', variables.orgId],
        old => ({ ...old, pendingMembers: old.pendingMembers.filter(m => (!(m.inviteId === variables.inviteId))) })
      );
      return { previousMembers };
    },
    onError: (err, variables, context) => {
      // Roll back optimistic update if unfriend fails
      queryClient.setQueryData(['organisationMembers', variables.orgId], context?.previousMembers);
      displaySnackbar({ id: 'userRemovedError', text: t('userRemovedError'), type: 'error' });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'userRemoved', text: t('userRemoved'), type: 'success' });
    },
    onSettled: () => {
      // Fetch latest members on error or success
      queryClient.invalidateQueries(['organisationMembers', organisation.id]);
    },
    mutationKey: 'cancelInvitation',
  });

  // variables for confirm dialog
  const [dialog, setDialog] = useState({
    show: false,
    title: '',
    message: '',
    confirmFunc: null
  });

  const showConfirmDialog = (title, message, confirmFunc) => {
    setDialog(
      {
        ...dialog,
        message,
        title,
        confirmFunc,
        show: true
      }
    );
  };

  return (
    <Paper className={classes.panel}>
      <Box className={classes.materialTable} id="material-table">
        <MaterialTable
          icons={tableIcons}
          isLoading={orgMembers.isLoading}
          data={orgMembers.isSuccess ? orgMembers.data.members.concat(orgMembers.data.pendingMembers).filter(m => m.email) : []}
          options={{
            draggable: false,
            showTitle: false,
            search: true,
            actionsColumnIndex: -1,
            searchFieldStyle: {
              borderRadius: '4px',
              paddingLeft: '18px',
              paddingRight: '10px'
            },
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
            emptyRowsWhenPaging: false,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '550px',
          }}
          // every part of the table supports localization
          localization={{
            body: {
              emptyDataSourceMessage: t('noUsersFound')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            pagination: {
              labelRows: t('rows'),
              labelDisplayedRows: ` {from}-{to} ${t('of')} {count}`,
              firstTooltip: t('firstPage'),
              previousTooltip: t('previousPage'),
              nextTooltip: t('nextPage'),
              lastTooltip: t('lastPage')
            }
          }}
          columns={[
            {
              title: t('tableName'),
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              render: m => (
                <div>
                  {m.name}
                  <StaffIndicator user={m} overrideStyle={{ width: '1.6rem', height: '1.6rem' }} />
                </div>
              ),
              searchable: true,
              customFilterAndSearch: (term, m) => customFilterAndSearch(term, m, 'name', 'name'),
              customSort: (a, b) => insensitiveSort(a.name, b.name),
            },
            ...(permissions.canEditPermissions ? [{
              title: t('emailLabel'),
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              render: m => (
                <div>{m.email}</div>
              ),
              searchable: true,
              customFilterAndSearch: (term, m) => customFilterAndSearch(term, m, 'email', 'email'),
              customSort: (a, b) => insensitiveSort(a.email, b.email)
            }] : []),
            {
              title: t('tableRoles'),
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              render: m => {
                if (!m.role) return (<div>{t('pending')}</div>);
                if (!permissions.canEditPermissions || m.id === user.id) return (<Typography>{m.role.label}</Typography>);
                return (
                  <Select
                    id={organisation.id}
                    value={m.role.id}
                    disabled={!m.role}
                    options={user.allRoles}
                    onChange={(orgId, roleId) => doUpdateRole.mutate({ userId: m.id, orgId, roleId })}
                  />
                );
              },
              searchable: true,
              customFilterAndSearch: (term, m) => customRoleFilterSearch(term, m, t('pending')),
              customSort: (a, b) => customRoleSort(a, b, t('pending'))
            },
            ...(permissions.canEditPermissions ? [{
              title: t('tableActions'),
              headerStyle: { textAlign: 'center' },
              cellStyle: { textAlign: 'center' },
              searchable: false,
              sorting: false,
              render: m => (
                m.role
                  ? deleteAction(m, organisation, t, doRemoveMember, showConfirmDialog)
                  : cancelInviteAction(m, organisation, t, doCancelInvitation, showConfirmDialog)
              )
            }] : [])

          ]}
        />
      </Box>
      <ConfirmDialog okButtonText={t('okButton')} cancelButtonText={t('cancelButton')} onConfirm={dialog.confirmFunc} open={dialog.show} setOpen={value => setDialog({ ...dialog, show: value })} title={dialog.title} message={dialog.message} />
    </Paper>
  );
};

export default OrganisationMembers;
