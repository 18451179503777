import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { distance, speed as uomSpeed } from 'helpers/unitsOfMeasure';

// This hook takes a position and returns the altitude and speed as a string
// formatted using the units.altitude and units.speed settings from the Settings
// reducer. Updates if the position, units.altitude or units.speed settings change.

const formatAltitudeAtSpeed = (position, altitudeUnits, speedUnits) => {
  if (!position) return '--';

  const {
    altitude = 0,
    speed = 0
  } = position;

  const formattedAltitude = distance.fromSI(altitude, altitudeUnits);
  const altitudeLabel = distance.label(altitudeUnits);

  const speedInLocale = uomSpeed.fromKmh(speed, speedUnits);
  const speedUnitsLabel = uomSpeed.label(speedUnits);

  return `${formattedAltitude.toFixed(0)} ${altitudeLabel} @ ${speedInLocale.toFixed(0)} ${speedUnitsLabel}`;
};

export default position => {
  const altitudeUnits = useSelector(state => state.settings.units.altitude);
  const speedUnits = useSelector(state => state.settings.units.speed);

  const [formattedString, setFormattedString] = useState(formatAltitudeAtSpeed(position, altitudeUnits, speedUnits));

  useEffect(() => {
    setFormattedString(formatAltitudeAtSpeed(position, altitudeUnits, speedUnits));
  }, [position, altitudeUnits, speedUnits]);

  return formattedString;
};
