import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { CompositeLayer } from 'deck.gl';
import { PathStyleExtension } from '@deck.gl/extensions';
import { IconLayer, PathLayer } from '@deck.gl/layers';
import AssetIcon from 'components/shared/icons/assetIcons';

const ICONSIZE = 128;
/* Three layers for the measurement tool - one for the measured distance, one for the line that follows the mouse cursor and one for the end marker */

class MeasureToolLayer extends CompositeLayer {
  updateState({ props, changeFlags }) {
    if (changeFlags.dataChanged) {
      let path = [];
      let mousePath = [];
      let lat = null;
      let lng = null;
      let showMarker = false;

      if (props.data.currentAssetPosition) {
        lat = props.data.currentAssetPosition.latitude;
        lng = props.data.currentAssetPosition.longitude;

        if (props.data.selected) {
          mousePath = [[props.data.mouseCoords.lng, props.data.mouseCoords.lat], [lng, lat]];
        }

        if (props.data.measurementMarker) {
          showMarker = true;
          path = [[props.data.measurementMarker.lng, props.data.measurementMarker.lat], [lng, lat]];
          lat = props.data.measurementMarker.lat;
          lng = props.data.measurementMarker.lng;
        }
      }

      const measureData = {
        properties: {
          id: 0,
          size: 5,
          angle: 0,
          icon: {
            url: `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<AssetIcon type="measure" />))}`,
            height: ICONSIZE,
            width: ICONSIZE,
            id: 0,
            anchorX: ICONSIZE / 2,
            anchorY: ICONSIZE / 2,
            mask: false
          }
        },
        latitude: lat,
        longitude: lng,
        path,
        mousePath,
        showMarker
      };

      this.setState({ measureData });
    }
  }

  renderLayers() {
    return [
      // TODO: Need to get this working using PathStyleExtension, it's currently broken
      // https://github.com/visgl/deck.gl/blob/master/docs/api-reference/extensions/path-style-extension.md
      new PathLayer({
        key: 'measure-line-mousefollow-layer',
        id: `measure-line-mousefollow-layer-${this.props.id}`,
        data: this.state.measureData,
        pickable: false,
        getPath: d => d.mousePath,
        getColor: [255, 255, 255, 153],
        getWidth: this.props.trailWidth,
        visible: this.props.visible && this.props.selected,
        parameters: { depthTest: false },
        getDashArray: [10, 6],
        dashStart: (Date.now() / 100) % 1000,
        lineWidthUnits: 'pixels',
        widthUnits: 'pixels',
        widthMinPixels: 2,
        widthMaxPixels: 2,
        extensions: [new PathStyleExtension({ highPrecisionDash: true })]
      }),
      new PathLayer({
        key: 'measure-line-layer',
        id: `measure-line-layer-${this.props.id}`,
        data: this.state.measureData,
        pickable: false,
        getPath: d => d.path,
        getColor: [255, 255, 255],
        getWidth: this.props.trailWidth,
        visible: (this.state.measureData.showMarker && this.props.visible),
        parameters: { depthTest: false },
        lineWidthUnits: 'pixels',
        widthMinPixels: 2,
        widthMaxPixels: 2
      }),
      new IconLayer({
        key: 'measure-marker-layer',
        id: `measure-marker-layer-${this.props.id}`,
        visible: this.state.measureData.showMarker && this.props.visible,
        data: this.state.measureData,
        pickable: false,
        getIcon: d => d.properties.icon,
        sizeScale: 10,
        getPosition: d => [d.longitude, d.latitude],
        getSize: 1.5,
        getAngle: 0,
        parameters: { depthTest: false }
      })
    ];
  }
}

export default MeasureToolLayer;
