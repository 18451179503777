import Smart from 'hocs/smart';
import AppView from './app-view';

export default Smart(
  AppView,
  state => ({
    isDarkMode: state.settings.ui.darkMode,
    now: state.app.now,
    loggedIn: state.session.loggedIn,
  }),
  ac => ({
    toggleDarkMode: ac.settings.toggleDarkMode,
    setFullscreen: ac.ui.setFullscreen,
    navigate: ac.router.navigate
  })
);
