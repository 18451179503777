import Smart from 'hocs/smart';
import CompatibilityView from './compatibility-view';

export default Smart(
  CompatibilityView,
  () => ({}),
  ac => ({
    navigate: ac.router.navigate
  })
);
