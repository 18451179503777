import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  category: {
    marginTop: theme.spacing(4)
  },
  tableWrapper: {
    marginTop: theme.spacing(0),
    boxShadow: 'none',
    border: theme.border.default
  },
  loading: {
    height: 100,
  },
}));
