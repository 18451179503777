import Smart from 'hocs/smart';
import { getAssets } from 'selectors/assets';
import MapContainerView from './mapContainer-view';

export default Smart(
  MapContainerView,
  state => ({
    maps: state.map.maps,
    language: state.settings.locale.language,
    mapLayout: state.map.selectedMapLayout,
    selectedMapId: state.map.selectedMapId,
    selectedAssets: state.map.selectedAssets,
    now: state.app.now,
    defaultMapSettings: state.map.maps.default,
    highContrastControls: state.settings.map.highContrastControls,
    performanceMode: state.app.performanceMode,
  }),
  ac => ({
    updateMapLayout: ac.map.updateMapLayout,
    selectMap: ac.map.selectMap,
    selectItem: ac.app.selectItem,
    closeMap: ac.map.closeMap,
    startCenterOnAsset: ac.map.startCenterOnAsset
  })
);
