import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  pageWrapper: {
    backgroundColor: theme.palette.common.lightGrey,
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(8, 0)
  },
  headerWrapper: {
    textAlign: 'left'
  },
  link: {
    textDecoration: 'none'
  },
  linkText: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
  formPanel: {
    marginTop: theme.spacing(3),
    boxShadow: 'none',
    border: theme.border.default,
    overflow: 'hidden'
  },
  groupsWrapper: {
    display: 'flex'
  },
  wrapper: {
    width: '100%'
  },
  headingText: {
    fontWeight: 400,
    color: theme.palette.common.text,
    marginBottom: theme.spacing(6)
  }
}));
