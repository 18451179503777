import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  fetchContacts, fetchMessagingWhitelist, removeMessagingWhitelistContact, addMessagingWhitelistContact
} from 'apis/trackstar/serenity';
import useTranslation from 'hooks/useTranslation';
import {
  Box,
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Link,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import MaterialTable, { MTableAction } from '@material-table/core';
import tableIcons from 'components/shared/icons/tableIcons';
import DeleteIcon from '@mui/icons-material/Delete';
import { Add } from '@mui/icons-material';
import clsx from 'clsx';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import mixpanel from 'mixpanel-browser';
import useStyles from '../asset-styles';

interface MessagingWhitelistParams {
  asset: AssetBasic
  readOnly: boolean,
  navigate: (url: string) => void,
  displaySnackbar: (Snack: Snack) => void;
  organisationId: string,
}

const MessagingWhitelist = ({
  asset,
  readOnly,
  navigate,
  displaySnackbar,
  organisationId,
}:MessagingWhitelistParams): JSX.Element => {
  const t = useTranslation('pages.assetView');
  const classes = useStyles();
  const [addMessagingWhitelistContactModal, openAddMessagingWhitelistContactModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState<null | Contact>(null);
  const queryClient = useQueryClient();

  const getMessagingWhitelist = useQuery(['messagingWhitelist', asset.deviceId], () => fetchMessagingWhitelist(asset.deviceId));
  const getContacts = useQuery(['contacts', null], () => fetchContacts());

  const doAddMessagingWhitelistContact = useMutation((whitelistContact: { contactId: number }) => addMessagingWhitelistContact(whitelistContact.contactId, asset?.deviceId), {
    onError: () => {
      displaySnackbar({ id: 'addMessagingWhitelistContactFailed', text: t('addMessagingWhitelistContactFailed'), type: 'error' });
      mixpanel.track('Add Messaging Whitelist Contact', { success: false, asset, organisationId });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'addMessagingWhitelistContactSuccess', text: t('addMessagingWhitelistContactSuccess'), type: 'success' });
      mixpanel.track('Add Messaging Whitelist Contact', { success: true, asset, organisationId });
    },
    onSettled: () => {
      setSelectedContact(null);
      openAddMessagingWhitelistContactModal(false);
      queryClient.invalidateQueries(['messagingWhitelist', asset.deviceId]);
    },
    mutationKey: 'addMessagingWhitelistContact',
  });
  const doRemoveMessagingWhitelistContact = useMutation((whitelistContact: { contactId: number }) => removeMessagingWhitelistContact(whitelistContact.contactId, asset?.deviceId), {
    onError: () => {
      displaySnackbar({ id: 'removeMessagingWhitelistContactFailed', text: t('removeMessagingWhitelistContactFailed'), type: 'error' });
      mixpanel.track('Remove Messaging Whitelist Contact', { success: false, asset, organisationId });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'removeMessagingWhitelistContactSuccess', text: t('removeMessagingWhitelistContactSuccess'), type: 'success' });
      mixpanel.track('Remove Messaging Whitelist Contact', { success: true, asset, organisationId });
    },
    onSettled: () => queryClient.invalidateQueries(['messagingWhitelist', asset.deviceId]),
    mutationKey: 'addMessagingWhitelistContact',
  });

  // Custom Add Contact
  const addContactButton = {
    Action: (props: any) => {
      const { action, data } = props;
      return ((action.position === 'toolbar')
        ? (
          <Button
            onClick={event => action.onClick(event, data)}
            className={classes.addButton}
            variant="contained"
          >
            <Add />
            {t('addContact')}
          </Button>
        ) : <MTableAction {...props} />
      );
    }
  };
  return (
    <Box id="messagingWhitelist" className={classes.category}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <Typography variant="h5" gutterBottom>{t('messagingWhitelistTitle')}</Typography>
          <Typography variant="body1">{t('messagingWhitelistDesc')}</Typography>
        </Grid>
        <Grid item sm={12} md={8}>
          <Paper className={clsx([classes.iceContactsPanelWrapper, classes.messagingWhitelistTable])}>
            <MaterialTable
              icons={tableIcons}
              data={(getMessagingWhitelist.isSuccess && getContacts.isSuccess)
                // flatMap is used to map contacts and filter whitelist entries that aren't in the contacts list
                ? getMessagingWhitelist.data?.flatMap(row => {
                  const contact = getContacts.data.find(c => c.id === row.contactId);
                  return contact ? { id: row.contactId, name: contact.name, contact: contact.contact } : [];
                })
                : []}
              columns={[{
                title: t('name'),
                field: 'name',
                headerStyle: { textAlign: 'left' },
                cellStyle: { textAlign: 'left' },
                defaultSort: 'asc',
              }, {
                title: t('phoneNumber'),
                field: 'contact',
                headerStyle: { textAlign: 'left' },
                cellStyle: { textAlign: 'left' },
                defaultSort: 'asc',
              }]}
              actions={readOnly ? [] : [contact => ({
                icon: () => <DeleteIcon />,
                tooltip: t('delete'),
                onClick: () => contact?.id && doRemoveMessagingWhitelistContact.mutate({ contactId: contact.id }),
              }),
              {
                icon: () => <Add className={classes.addButton} />,
                iconProps: { color: 'primary' },
                tooltip: t('addContact'),
                isFreeAction: true,
                onClick: () => openAddMessagingWhitelistContactModal(true),
              }]}
              isLoading={getContacts.isLoading || getMessagingWhitelist.isLoading}
              options={{
                draggable: false,
                showTitle: false,
                search: true,
                actionsColumnIndex: -1,
                searchFieldStyle: {
                  borderRadius: '4px',
                  paddingLeft: '18px',
                  paddingRight: '10px'
                },
                searchFieldVariant: 'outlined',
                thirdSortClick: false,
                paging: true,
                pageSizeOptions: [5, 25, 50, 100],
                pageSize: 5,
                emptyRowsWhenPaging: false,
                headerStyle: { position: 'sticky', top: 0 },
                maxBodyHeight: '550px',
              }}
              localization={{
                header: {
                  actions: t('actions')
                },
                pagination: {
                  labelRows: t('rows'),
                  labelDisplayedRows: ` {from}-{to} ${t('of')} {count}`,
                  firstTooltip: t('firstPage'),
                  previousTooltip: t('previousPage'),
                  nextTooltip: t('nextPage'),
                  lastTooltip: t('lastPage')
                },
              }}
              components={addContactButton}
            />
            <Box style={{ textAlign: 'right' }}>
              { // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  className={classes.orgSettingsLink}
                  onClick={e => {
                    e.preventDefault();
                    navigate('/settings/organisation');
                  }}
                  underline="hover"
                >
                  {readOnly ? t('orgSettingsLinkViewOnly') : t('orgSettingsLink')}
                </Link>
              }
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={addMessagingWhitelistContactModal} onClose={() => openAddMessagingWhitelistContactModal(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('addMessagingWhitelistContactsTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('addMessagingWhitelistContactsDesc')}
          </DialogContentText>
          <FormControl variant="standard" style={{ width: '100%' }}>
            <Autocomplete
              id="dialog-select"
              options={getContacts.isSuccess ? getContacts.data.filter(c => c.type === 'sms').sort((a, b) => a.name?.localeCompare(b.name)) : []}
              getOptionLabel={option => `${option.name} ${option.contact}`}
              isOptionEqualToValue={(option, value) => Object.keys(value).length === 0 || value.id === option.id}
              value={selectedContact}
              onChange={(e, newValue) => setSelectedContact(newValue)}
              renderInput={params => <TextField variant="standard" {...params} label={t('selectContact')} fullWidth />}
              filterOptions={createFilterOptions({
                stringify: option => option.name
              })}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => openAddMessagingWhitelistContactModal(false)}>
            {t('cancelButton')}
          </Button>
          <Button
            variant="contained"
            onClick={
              () => selectedContact?.id && doAddMessagingWhitelistContact.mutate({ contactId: selectedContact.id })
            }
            color="primary"
            disabled={!selectedContact}
          >
            {t('addMessagingWhitelistContactsConfirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MessagingWhitelist;
