// The following es-lint exception is to stop lint warnings every time we use draft in this file
/* eslint no-param-reassign: 0 */
import produce from 'immer';

const persistFields = [
  'user',
  'loggedIn',
  'rememberMe',
  'organisationId',
  'statusColour',
  'notifications'
];

// Note these are the defaults; returning users will load persisted settings
const initialState = Object.freeze({
  // Auth / Identity
  user: null,
  loggedIn: false,
  loggingIn: false,
  loginError: null,
  rememberMe: true,
  organisationId: null,

  // Service Status / Notifications etc
  statusColour: 'green',
  notifications: [], // { id, text, read? }
  client: null
});

const sessionReducer = (state = initialState, action) => produce(state, draft => {
  /* eslint-disable-next-line */
  switch (action.type) {
    // Normally we want to do automatic rehydration
    // But this is to recover the session version of organisationId which is per tab
    case 'persist/REHYDRATE':
      draft.organisationId = sessionStorage.getItem('organisationId') || localStorage.getItem('organisationId');
      break;

    case 'SIGNUP_REQUEST':
    case 'LOGIN_REQUEST':
      draft.loggingIn = true;
      draft.loginError = null;
      draft.loggedIn = false;
      break;
    case 'USER_LOADED':
      draft.loggingIn = false;
      draft.loggedIn = true;
      draft.user = { ...action.payload, memberOf: action.payload.memberOf };
      // If none found, default to the first organisation the user is a member of (excluding "Public")
      if (!draft.organisationId || !action.payload.memberOf.some(o => o.id === draft.organisationId)) {
        draft.organisationId = (action.payload.memberOf.find(o => o.name !== 'Public') || action.payload.memberOf[0])?.id;
        sessionStorage.setItem('organisationId', draft.organisationId); // For the api headers
        localStorage.setItem('organisationId', draft.organisationId); // For the api headers
      } else {
        localStorage.setItem('organisationId', draft.organisationId); // For the api headers
      }
      if (!draft.organisationId) {
        console.error('User is not a member of any Organisation.', action.payload);
      }
      break;

    case 'UPDATE_USER':
      draft.user.name = action.payload.name;
      draft.user.email = action.payload.email;
      break;

    case 'SET_AUTHTOKEN':
      // this is the new auth token for the REST apis
      if (action.payload) {
        localStorage.setItem('bearerToken', action.payload); // new bearer token
      }
      break;
    case 'SIGNUP_FAILURE':
    case 'LOGIN_FAILURE':
      draft.loggingIn = false;
      draft.loggedIn = false;
      draft.user = null;
      draft.loginError = action.payload;
      localStorage.removeItem('bearerToken'); // don't forget to remove any tokens
      break;

    case 'LOGOUT':
      draft.loggedIn = false;
      draft.loginError = null;
      draft.user = null;
      localStorage.removeItem('bearerToken'); // don't forget to remove the token
      break;

    case 'SET_ORGANISATIONID':
      draft.organisationId = action.payload;
      sessionStorage.setItem('organisationId', draft.organisationId); // For the api headers
      localStorage.setItem('organisationId', draft.organisationId); // For the api headers
      break;

    case 'SET_REMEMBER_ME':
      draft.rememberMe = action.payload;
      break;

    case 'SERVER_UPDATE_STATUS_COLOUR':
      draft.statusColour = action.payload;
      break;
    case 'READ_ALL_NOTIFICATIONS':
      draft.notifications = state.notifications.map(n => ({ ...n, read: true }));
      break;

    case 'UNAUTHORIZED':
      console.error('Unauthorized');
      break;

    case 'RESET_EVERYTHING': return initialState;

    case 'JOIN_ORGANISATION_SUCCESS': {
      const org = action.payload.organisation;
      const adminRole = draft.user.allRoles.find(r => r.label === 'Administrator');
      draft.user.orgRoles = [...draft.user.orgRoles, { organisationId: org.id, roleId: adminRole.id, roleLabel: adminRole.label }];
      draft.user.memberOf = [...draft.user.memberOf, { id: org.id, name: org.name }];
      break;
    }

    case 'LEAVE_ORGANISATION_SUCCESS':
      draft.user.memberOf = draft.user.memberOf.filter(org => org.id !== action.payload.id);
      break;

    case 'LEGACY_LOGIN': {
      draft.legacyUser = action.payload;
      break;
    }
  }
});

export default {
  key: 'session',
  reducer: sessionReducer,
  version: 3,
  whitelist: persistFields,
  migrations: {
    0: () => initialState,
    1: state => {
      const orgId = state.organisation
        ? state.organisation.id
        : state.user ? state.user.memberOf[0].id : null;
      localStorage.setItem('organisationId', JSON.stringify(orgId));
      return state;
    },
    2: state => {
      // The previous migration incorrectly stored orgId stringified,
      // this undoes this if its set into localStorage
      const orgId = localStorage.getItem('organisationId');
      if (orgId.startsWith('"')) {
        localStorage.setItem('organisationId', JSON.parse(orgId));
      }
      return state;
    },
    3: state => {
      if (state.organisationId) {
        return state;
      }
      const organisationId = localStorage.getItem('organisationId');
      return {
        ...state,
        organisationId
      };
    },
  }
};
