import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { FormControl, Typography, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import useStyles from './loginInput-styles';

const LoginInput = ({
  id: fieldId,
  type,
  value,
  placeholderText,
  helperText,
  validate,
  onChange,
  onEnterKeypress,
  inputError,
  t,
  readOnly = false
}) => {
  const classes = useStyles();
  const [hasEnteredOneCharacter, setHasEnteredOneCharacter] = useState(false);

  const id = `${placeholderText?.replace(/\s/g, '')}Input`;
  const onKeyDown = e => {
    if (e.key === 'Enter') {
      onEnterKeypress(e);
    }
  };

  const [error, setError] = useState(false);
  useEffect(() => {
    if (value?.length > 1) setHasEnteredOneCharacter(true);
    if (validate) setError(validate(value));
  }, [value]);

  return (
    <FormControl variant="standard" className={classes.formControl}>
      <TextField
        id={id}
        type={type}
        value={value}
        onChange={validate ? e => onChange(fieldId, e.target.value) : onChange}
        onKeyDown={onEnterKeypress && onKeyDown}
        className={clsx(classes.input, classes.textField)}
        label={placeholderText}
        variant="outlined"
        error={((!!error || !!inputError) && hasEnteredOneCharacter)}
        disabled={readOnly}
      />
      {hasEnteredOneCharacter && (
        <Typography className={classes.errorMessage}>{(!!error && t(error)) || (!!inputError && t(inputError))}</Typography>
      )}
      {helperText && (
        <Typography className={classes.helperText}>
          <Link to="/reset-password">{helperText}</Link>
        </Typography>
      )}
    </FormControl>
  );
};

export default LoginInput;
