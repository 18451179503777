import zxcvbn from 'zxcvbn';

const MISSING_PASSWORD_ERROR = 'passwordRequired';
const MISSING_CONFIRM_PASSWORD_ERROR = 'confirmPasswordRequired';
const LENGTH_ERROR = 'passwordTooShort';
const DO_NOT_MATCH_ERROR = 'passwordsDontMatch';

export const MINIMUM_PASSWORD_LENGTH = 8;

const PASSWORD_STRENGTHS = [{
  strength: 'veryWeak',
  meterLevel: 20,
  color: 'distressRed'
}, {
  strength: 'weak',
  meterLevel: 40,
  color: 'orange'
}, {
  strength: 'moderate',
  meterLevel: 60,
  color: 'yellow'
}, {
  strength: 'strong',
  meterLevel: 80,
  color: 'activeGreen'
}, {
  strength: 'veryStrong',
  meterLevel: 100,
  color: 'blue'
}];

// eslint-disable-next-line no-underscore-dangle
export const validateSingle = (password, enteredPassword) => {
  // Must be provided
  if (!password && enteredPassword) return MISSING_CONFIRM_PASSWORD_ERROR;
  if (!password) return MISSING_PASSWORD_ERROR;
  // Display error message instantly if confirm password being entered does not match password
  if (enteredPassword && (password !== enteredPassword?.password)) return DO_NOT_MATCH_ERROR;
  // Must be >= minimum length
  if (password.length < MINIMUM_PASSWORD_LENGTH) return LENGTH_ERROR;

  return null;
};

export const validate = (password, confirm) => {
  const passwordError = validateSingle(password);
  if (passwordError) return passwordError;
  const confirmError = validateSingle(confirm);
  if (confirmError) return confirmError;

  // Must match
  if (password !== confirm) return DO_NOT_MATCH_ERROR;

  return null;
};

export const getPasswordStrength = password => {
  const { score } = zxcvbn(password);
  return PASSWORD_STRENGTHS[score];
};
