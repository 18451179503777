import React from 'react';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import ColorInput from 'components/shared/forms/inputs/color';
import useTranslation from 'hooks/useTranslation';
import TextInput from 'components/shared/forms/inputs/text';
import useStyles from './details-styles';

const CreateAssetDetails = ({
  asset,
  categories,
  updateAsset,
  previousColors,
  setPreviousColors
}) => {
  const t = useTranslation('pages.createAsset');
  const classes = useStyles();
  const isAPerson = categories?.find(c => c.id === asset.categoryId)?.label === 'Person';

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h1" className={classes.headingText}>{t('assetDescription')}</Typography>
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <TextInput id="name" label={t('name')} value={asset.name} onChange={updateAsset} helperText={t('nameHelper')} maxLength="50" />
        </Grid>
        {!isAPerson && (
          <Grid item sm={12} md={6}>
            <TextInput id="make" label={t('make')} value={asset.make} onChange={updateAsset} helperText={t('makeHelper')} disabled={isAPerson} maxLength="50" />
          </Grid>
        )}
        <Grid item sm={12} md={6}>
          <TextInput id="variant" label={t('variant')} value={asset.variant} onChange={updateAsset} helperText={t('variantHelper')} maxLength="50" />
        </Grid>
        {!isAPerson && (
          <Grid item sm={12} md={6}>
            <TextInput id="model" label={t('model')} value={asset.model} onChange={updateAsset} helperText={t('modelHelper')} disabled={isAPerson} maxLength="50" />
          </Grid>
        )}
        <Grid item sm={12} md={6}>
          <Box className={classes.colorWrapper}>
            <ColorInput
              id="color"
              label={t('color')}
              color={asset.color || '#000'}
              value={asset.color || '#000'}
              onChange={updateAsset}
              previousColors={previousColors}
              setPreviousColors={setPreviousColors}
              colorPickerPositionTop={-230}
              colorPickerPositionLeft={90}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateAssetDetails;
