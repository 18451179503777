import Smart from 'hocs/smart';
import MessageInputBox from './messageInputBox-view';

export default Smart(
  MessageInputBox,
  (state: any) => ({
    username: state.session.user.name,
    organisationName: state.session.user.memberOf.find((o: Organisation) => o.id === state.session.organisationId)?.name,
    organisationId: state.session.organisationId,
  }),
  (ac: any) => ({
    displaySnackbar: ac.app.displaySnackbar,
  })
);
