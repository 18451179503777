import { useMemo, useRef } from 'react';

/**
 * haversineKm computes the distance, in kilometers, between two points on the
 * globe using the Haversine formula.
 *
 * @param {number} latStart Latitude of the start point.
 * @param {number} lonStart Longitude of the end point.
 * @param {number} latEnd   Latitude of the end point.
 * @param {number} lonEnd   Longitude of the end point.
 * @returns
 */
function haversineKm(latStart, lonStart, latEnd, lonEnd) {
  if ((latStart === latEnd) && (lonStart === latEnd)) {
    return 0;
  }

  const radlat1 = (Math.PI * latStart) / 180;
  const radlat2 = (Math.PI * latEnd) / 180;
  const theta = lonStart - lonEnd;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist *= 1.609344;
  return dist;
}

/**
 * Compute the total distance traveled by assets, upto each report, in
 * kilometers.
 */
export default (assets, assetTrails) => {
  const distanceTraveled = useRef({});
  distanceTraveled.current = useMemo(() => {
    const dt = { ...distanceTraveled.current };
    assets.forEach(a => {
      const trail = assetTrails?.[a.id] ?? [];
      if (!dt[a.id] || (dt[a.id].length !== trail.length)) {
        let distance = 0;
        dt[a.id] = trail.map((t, i) => {
          if (i === 0) return 0;
          if (Number.isFinite(t[0]) && Number.isFinite(t[1])) {
            const l = trail[i - 1];
            distance += haversineKm(l[0], l[1], t[0], t[1]);
          }
          return distance;
        });
      }
    });

    return dt;
  }, [assets, assetTrails]);

  return distanceTraveled.current;
};
