import Smart from 'hocs/smart';
import RegionalView from './regional-view';

export default Smart(
  RegionalView,
  state => ({
    timezone: state.settings.locale.timezone,
    clocks: state.app.clocks,
    organisationId: state.session.organisationId,
    organisationTimezone: state.settings.locale.organisationTimezone[state.session.organisationId]
  }),
  ac => ({
    addClock: ac.app.addClock,
    removeClock: ac.app.removeClock,
    setOrganisationTimezone: ac.settings.setOrganisationTimezone
  })
);
