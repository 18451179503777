import { getRefreshedToken } from 'apis/auth';
import { Moment } from 'moment';
import { filterMetadata } from '../trackstar/maps';

const BASE_URL = `${import.meta.env.VITE_SERENITY_REST_URL}`;

const parseReports = (reports: Report[]): Report[] => reports.map((r: Report) => ({ ...r, metadata: filterMetadata(r.package) }));

/*
  Requests by transmitted time
*/
export const getReports = async (organisationId: string, from: Moment, until: Moment): Promise<Report[]> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
  };
  const params = [
    ['from', from.toISOString()],
    ['until', until.toISOString()]
  ];
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const response = await fetch(`${BASE_URL}/organisation/${organisationId}/reports?${new URLSearchParams(params)}`, { headers } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response.json().then(o => parseReports(o.reports));
};

/*
  Requests by received time
*/
export const getReportsHistoric = async (organisationId: string, from: Moment, until: Moment): Promise<Report[]> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
  };
  const params = [
    ['from', from.toISOString()],
    ['until', until.toISOString()]
  ];
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const response = await fetch(`${BASE_URL}/organisation/${organisationId}/reports/historic?${new URLSearchParams(params)}`,
    { headers } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response.json().then(o => parseReports(o.reports));
};

export const getReportsLatest = async (organisationId: string): Promise<Report[]> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
  };
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const response = await fetch(`${BASE_URL}/organisation/${organisationId}/reports/latest`, { headers } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response.json().then(o => parseReports(o.reports));
};

export const getReportsForAsset = async (organisationId: string, assetId: number, from: Moment, until: Moment): Promise<Report[]> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
  };
  const params = [
    ['from', from.toISOString()],
    ['until', until.toISOString()]
  ];
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const response = await fetch(`${BASE_URL}/organisation/${organisationId}/asset/${assetId}/reports?${new URLSearchParams(params)}`,
    { headers } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response.json().then(o => parseReports(o.reports));
};

export const getReportsForAssetHistoric = async (organisationId: string, assetId: number, from: Moment, until: Moment): Promise<Report[]> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
  };
  const params = [
    ['from', from.toISOString()],
    ['until', until.toISOString()]
  ];
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const response = await fetch(`${BASE_URL}/organisation/${organisationId}/asset/${assetId}/reports/historic?${new URLSearchParams(params)}`,
    { headers } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response.json().then(o => parseReports(o.reports));
};

export const getReportsSummary = async (organisationId: string, from: Moment, until: Moment): Promise<ReportsSummary[]> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
  };
  const params = [
    ['from', from.toISOString()],
    ['until', until.toISOString()]
  ];
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const response = await fetch(`${BASE_URL}/organisation/${organisationId}/reports/summary?${new URLSearchParams(params)}`,
    { headers } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response.json().then(o => o.reportSummaries);
};

export const getReportsSummaryForAsset = async (organisationId: string, assetId: number, from: Moment, until: Moment): Promise<ReportsSummary[]> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
  };
  const params = [
    ['from', from.toISOString()],
    ['until', until.toISOString()]
  ];
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const response = await fetch(`${BASE_URL}/organisation/${organisationId}/assets/${assetId}/reports/summary?${new URLSearchParams(params)}`,
    { headers } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response.json().then(o => o.reportSummaries);
};
