import Smart from 'hocs/smart';
import OrganisationSelectorView from './organisationSelector-view';

export default Smart(
  OrganisationSelectorView,
  state => ({
    organisationId: state.session.organisationId,
    organisations: state.session.user.memberOf,
    selectedMapId: state.map.selectedMapId,
    orgHomeBases: state.settings.map.homeBases,
  }),
  ac => ({
    setOrganisation: ac.session.setOrganisation,
    jumpToHomeBase: ac.map.jumpToHomeBase,
    setFollow: ac.map.setFollow
  })
);
