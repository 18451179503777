import { createSelector } from 'reselect';
import { isLoggedIn } from './session';

export interface Permissions {
  canChangeEmergencyContacts: boolean
  canEditAssets: boolean
  canEditFriends: boolean
  canEditOrganisation: boolean
  canEditPermissions: boolean
  canEditDevices: boolean
  canEditShare: boolean
  canCreateNewConversation: boolean
  canChangeOrganisationAccess: boolean
  canAdministerOrganisations: boolean
  canViewBilling: boolean
  canAdministerBilling: boolean
}

export const permissionsForCurrentUserAndOrg = createSelector(
  [state => state.session.organisationId, state => state.session.user.memberOf, state => isLoggedIn(state)],
  (orgId, memberOf, user) => {
    const isAdmin = (user?.orgRoles.find((r: OrgRole) => r.organisationId === orgId)?.roleLabel === 'Administrator');

    return {
      canChangeEmergencyContacts: isAdmin,
      canEditAssets: isAdmin,
      canEditFriends: isAdmin,
      canEditOrganisation: isAdmin,
      canEditPermissions: isAdmin,
      canEditDevices: isAdmin,
      canEditShare: isAdmin,
      canCreateNewConversation: isAdmin,
      canChangeOrganisationAccess: isAdmin,
      canAdministerOrganisations: user.isStaff,
      canViewBilling: isAdmin || user.isStaff,
      canAdministerBilling: user.isStaff
    } as Permissions;
  }
);
