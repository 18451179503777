import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  infoSnackbar: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main, // white in dark mode, blue in light mode
    '& .MuiSnackbarContent-message': {
      maxWidth: '86%'
    }
  }
}));
