import React from 'react';
import { useTheme } from '@mui/material/styles';

const StatusIcon = ({
  status = 'green'
}) => {
  const theme = useTheme();

  const iconMap = {
    green: {
      title: 'Green Icon',
      color: theme.palette.primary.activeGreen,
      path: 'M16,0A16,16,0,1,0,32,16,16.047,16.047,0,0,0,16,0ZM13,22.414,7.586,17,9,15.586l4,4,10-10L24.414,11Z'
    },
    yellow: {
      title: 'Yellow Icon',
      color: theme.palette.primary.yellow,
      path: 'M16,0C7.178,0,0,7.178,0,16s7.178,16,16,16s16-7.178,16-16S24.822,0,16,0z M17.929,7L17,19h-2L14.071,7 H17.929z M16,25c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2c1.105,0,2,0.895,2,2C18,24.105,17.105,25,16,25z'
    },
    red: {
      title: 'Red Icon',
      color: theme.palette.primary.distressRed,
      path: 'M16,0C7.178,0,0,7.178,0,16s7.178,16,16,16s16-7.178,16-16S24.822,0,16,0z M17.929,7L17,19h-2L14.071,7 H17.929z M16,25c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2c1.105,0,2,0.895,2,2C18,24.105,17.105,25,16,25z'
    }
  };
  const icon = iconMap[status.toLowerCase()];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <title>{icon.title}</title>
      <g fill={icon.color}>
        <path d={icon.path} fill={icon.color} />
      </g>
    </svg>
  );
};

export default StatusIcon;
