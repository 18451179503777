import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, Box, SvgIcon } from '@mui/material';
import clsx from 'clsx';
import useTranslation from 'hooks/useTranslation';

import useStyles from '../../contextbox-styles';

const CollapsePanel = () => {
  const t = useTranslation('contextbox.settings');
  const classes = useStyles();

  const contextboxOpen = useSelector(state => state.ui.contextboxOpen);
  const dispatch = useDispatch();
  const toggleContext = useCallback(
    () => dispatch({ type: 'TOGGLE_CONTEXTBOX' }),
    [dispatch]
  );

  return (
    <Tooltip
      title={contextboxOpen ? t('expandPanel') : t('collapsePanel')}
      placement="left"
      PopperProps={{
        popperOptions: {
          modifiers: {
            offset: {
              enabled: true,
              offset: '0px, 10px'
            }
          }
        }
      }}
    >
      <Box className={classes.collapseButton} onClick={toggleContext}>
        <SvgIcon className={clsx(classes.icon, { [classes.iconReversed]: contextboxOpen })} width="16" height="16" viewBox="0 0 16 16">
          <polygon fill="#fff" points="6,13.4 4.6,12 8.6,8 4.6,4 6,2.6 11.4,8 " />
        </SvgIcon>
      </Box>
    </Tooltip>
  );
};

export default CollapsePanel;
