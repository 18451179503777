import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  mapControls: {
    display: 'inline-flex'
  },
  hereLogo: {
    position: 'relative',
    '& svg': {
      position: 'absolute',
      bottom: 0,
      left: 10
    }
  },
  accreditation: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.mapControls.bg,
    color: theme.palette.mapControls.fg,
    fontWeight: '500',
    height: 30,
    paddingLeft: 15,
    paddingRight: 15,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    '&.highContrastClass': {
      backgroundColor: theme.palette.mapControls.bgHighContrast,
      color: theme.palette.mapControls.fgHighContrast
    }
  },
  iconButton: {
    zIndex: 1,
    width: 30,
    height: 30,
    minHeight: 30,
    borderRadius: 0,
    backgroundColor: theme.palette.mapControls.bg,
    '& svg': {
      '& path, polygon, circle, rect': {
        fill: theme.palette.mapControls.fg
      }
    },
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.mapControls.bgHover
    },
    '&.highContrastClass': {
      backgroundColor: theme.palette.mapControls.bgHighContrast,
      '& svg polygon': {
        fill: theme.palette.mapControls.fgHighContrast
      }
    }
  },
  mapControlsButtonWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDireaction: 'column',
    justifyItems: 'center',
    alignItems: 'center',
    zIndex: 1051,
    width: 30,
    height: 30,
    border: 'none',
    borderRadius: 0,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    outline: 'none',
    backgroundColor: theme.palette.mapControls.bg,
    '& svg': {
      '& path, polygon, circle, rect': {
        fill: theme.palette.mapControls.fg
      }
    },
    cursor: 'pointer',
    transition: 'all 0.15s',
    '&.highContrastClass': {
      backgroundColor: theme.palette.mapControls.bgHighContrast,
      '& svg path': {
        fill: theme.palette.mapControls.fgHighContrast
      },
      '&:hover, &:focus': {
        backgroundColor: theme.palette.mapControls.bgHighContrast,
        '& svg': {
          '& path, polygon, circle': {
            fill: theme.palette.mapControls.fgHighContrast
          }
        }
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.mapControls.bgHover
    }
  },
  zoomButtons: {
    width: 30,
    height: 30,
    border: 'none',
    borderRadius: 0,
    outline: 'none',
    backgroundColor: theme.palette.mapControls.bg,
    '& svg': {
      '& path, polygon, circle, rect': {
        fill: theme.palette.mapControls.fg
      }
    },
    cursor: 'pointer',
    transition: 'all 0.15s',
    '&.highContrastClass': {
      backgroundColor: theme.palette.mapControls.bgHighContrast,
      '& svg path': {
        fill: theme.palette.mapControls.fgHighContrast
      },
      '&:hover, &:focus': {
        backgroundColor: theme.palette.mapControls.bgHighContrast,
        '& svg': {
          '& path, polygon, circle': {
            fill: theme.palette.mapControls.fgHighContrast
          }
        }
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.mapControls.bgHover
    }
  },
  zoomIn: {
    marginLeft: 5,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  zoomOut: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  speedDialWrapper: {
    position: 'relative'
  },
  rounded: {
    '& button': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8
    }
  },
  speedDial: {
    zIndex: 1,
    width: 30,
    height: 30,
    marginRight: 0,
    '&.MuiSpeedDial-directionUp .MuiSpeedDial-actions': {
      paddingBottom: '35px'
    },
    '&.highContrastClass': {
      '& button': {
        backgroundColor: theme.palette.mapControls.bgHighContrast,
        '& svg': {
          '& path, polygon, circle': {
            fill: theme.palette.mapControls.fgHighContrast
          }
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.mapControls.bgDisabledHighContrast,
          '& svg': {
            '& path, polygon, circle': {
              fill: theme.palette.mapControls.fgDisabledHighContrast
            }
          }
        }
      }
    },
    '& > button': {
      minWidth: 30,
      minHeight: 30,
      width: 30,
      height: 30,
      margin: 0,
      borderRadius: 0,
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      backgroundColor: theme.palette.mapControls.bg,
      color: theme.palette.mapControls.fg,
      boxShadow: 'none',
      transition: 'all 0.15s',
      opacity: 0,
      '&:hover, &:focus': {
        boxShadow: 'none',
        backgroundColor: theme.palette.mapControls.bg
      }
    }
  },
  speedDialAction: {
    minWidth: 30,
    minHeight: 30,
    width: 30,
    height: 30,
    margin: 0,
    '&.MuiSpeedDialAction-fab': {
      margin: 0,
      backgroundColor: theme.palette.mapControls.bg,
      color: theme.palette.mapControls.fg,
      '&:hover': {
        backgroundColor: theme.palette.mapControls.bgHover
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.mapControls.bgActive
      }
    },
    borderRadius: 0,
    '&.Mui-disabled': {
      backgroundColor: theme.palette.mapControls.bgDisabled,
      '& svg': {
        '& path, polygon, circle': {
          fill: theme.palette.mapControls.fgDisabled
        }
      }
    },
    '& svg': {
      '& path, polygon, circle, rect': {
        fill: theme.palette.mapControls.fgActive
      }
    },
    boxShadow: 'none',
    transition: 'all 0.15s',
    '&:first-child': {
      borderRadius: '0 0 8px 8px'
    },
    '&:last-child': {
      borderRadius: '8px 8px 0 0 '
    },
    '&.speedDialActionActive': {
      backgroundColor: theme.palette.mapControls.bgActive,
      '&.highContrastClass': {
        backgroundColor: theme.palette.mapControls.bgActive,
        '& svg': {
          '& path, polygon, circle, rect': {
            fill: theme.palette.mapControls.fgActive
          }
        }
      }
    }
  },
  icon: {
    fontSize: '1.2rem'
  },
  controlButtonsWrapper: { /* hack to stop the map views from resizing */
    display: 'block',
    height: 0,
    width: 0
  }
}));
