import React, { useState } from 'react';
import Modal from 'react-modal';
import {
  Box, Typography, Grid, Button, MenuItem, FormControl, InputLabel, Select, Tooltip, IconButton, TextField
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { isValidEmail } from 'utils/strings';
import languages from 'constants/languages';
import { Delete, Undo } from '@mui/icons-material';
import { useMutation, useQueryClient, useQueries } from 'react-query';
import {
  setDeviceEmergencyContacts, deleteContact, saveContact, fetchContactAssets
} from 'apis/trackstar/serenity';
import { Phone, Sms, Email } from '@mui/icons-material';
import clsx from 'clsx';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import mixpanel from 'mixpanel-browser';
import useStyles from './editContactModal-styles';

const OrganisationContactModal = ({
  deviceId,
  assetMode,
  organisationId,
  organisationMode,
  person,
  updatePerson,
  allContacts,
  onClose,
  countryAndCallingCodes,
  getContactCallingCode,
  getContactCountryCode,
  displaySnackbar,
}) => {
  const t = useTranslation('pages.organisationSettings.contact');
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState(person[0]?.name);
  const prevName = person[0]?.name;
  const [language, setLanguage] = useState(person[0]?.language ?? 'en');
  const [contacts, setContacts] = useState(person);
  const queryClient = useQueryClient();
  // const [invalidNumberWarningText, setInvalidNumberWarningText] = useState('');
  // setInvalidNumberWarningText(t('existingEmailIceContact'));
  const numberWithoutCountryCode = c => c.contact.split(' ').splice(1).join(' ');
  const stringEmpty = s => !s || s.trim().length === 0;

  const canSave = !contacts.map(c => {
    if (c.type === 'email') return !stringEmpty(c.contact) && isValidEmail(c.contact);
    if (c.type === 'phone' || c.type === 'sms') return !stringEmpty(getContactCallingCode(c.contact)) && !stringEmpty(numberWithoutCountryCode(c));
    return false;
  }).includes(false);

  const updateContact = (id, field, newValue) => {
    const updatedContact = { ...contacts.find(c => c.id === id), [field]: newValue };
    setContacts(contacts.map(c => (c.id === id ? updatedContact : c)));
  };

  useQueries(person.map(c => c.id).map(contactId => ({
    queryKey: ['contactAssets', contactId.toString()],
    queryFn: () => fetchContactAssets(contactId.toString()),
  })));

  const setContactDeleted = id => {
    const assignedAssets = queryClient.getQueriesData(['contactAssets', id.toString()])[0][1]?.assets?.long;
    console.log(assignedAssets);
    if (assignedAssets) {
      displaySnackbar({
        id: 'deleteContactError',
        type: 'error',
        text: t('deleteContactError', { assignedAssets: assignedAssets.length ?? 1 }),
      });
      return;
    }
    setContacts(contacts.map(c => (c.id === id ? { ...c, deleted: !c.deleted } : c)));
  };

  const updateDeviceEmergencyContacts = useMutation(updatedPerson => setDeviceEmergencyContacts({
    deviceId,
    phoneContacts: updatedPerson.filter(c => c.icePhone),
    smsContacts: updatedPerson.filter(c => c.iceSms),
    emailContacts: updatedPerson.filter(c => c.iceEmail),
  }), {
    // onMutate: async variables => console.log('mutate'),
    onError: (err, variables, context) => {
      displaySnackbar({
        id: 'updateEmergencyContactsError',
        type: 'error',
        text: t('updateEmergencyContactsError'),
      });
      mixpanel.track('Update Emergency Contact', { success: false, deviceId, organisationId });
    },
    onSuccess: () => {
      displaySnackbar({
        id: 'updateEmergencyContactsSuccess',
        type: 'success',
        text: t('updateEmergencyContactsSuccess'),
      });
      mixpanel.track('Update Emergency Contact', { success: true, deviceId, organisationId });
    },
    onSettled: () => queryClient.invalidateQueries(['contacts', deviceId]),
    mutationKey: 'updatePerson',
  });

  const doDeleteContact = useMutation(contactId => deleteContact(contactId), {
    // TODO: error if trying to delete contacts that are assigned
    // onMutate: async variables => console.log('mutate'),
    onError: (err, variables, context) => {
      displaySnackbar({
        id: 'deleteContactFailedError',
        type: 'error',
        text: t('deleteContactFailedError'),
      });
      mixpanel.track('Delete Emergency Contact', { success: false, deviceId, organisationId });
    },
    onSuccess: () => {
      displaySnackbar({
        id: 'deleteContactSuccess',
        type: 'success',
        text: t('deleteContactSuccess'),
      });
      mixpanel.track('Delete Emergency Contact', { success: true, deviceId, organisationId });
    },
    onSettled: () => queryClient.invalidateQueries(['contacts', deviceId]),
    mutationKey: 'deleteContact',
  });

  const doSaveContact = useMutation(contact => saveContact(contact), {
    // onMutate: async variables => console.log('mutate'),
    onError: (err, variables, context) => {
      displaySnackbar({
        id: 'saveContactError',
        type: 'error',
        text: t('saveContactError'),
      });
      mixpanel.track('Update Contact', { success: false, deviceId, organisationId });
    },
    onSuccess: () => {
      displaySnackbar({
        id: 'saveContactSuccess',
        type: 'success',
        text: t('saveContactSuccess'),
      });
      mixpanel.track('Update Contact', { success: true, deviceId, organisationId });
    },
    onSettled: () => queryClient.invalidateQueries(['contacts', deviceId]),
    mutationKey: 'updateContact',
  });

  const onSave = () => {
    // TODO: add UI to add additional contacts to selected person
    updatePerson(prevName, contacts.filter(c => !c.deleted).map(c => ({ ...c, name, language })));
    if (assetMode) {
      updateDeviceEmergencyContacts.mutate(allContacts.map(c => contacts.find(nc => nc.id === c.id) ?? c));
      onClose();
    }
    if (organisationMode) {
      contacts.forEach(c => {
        if (c.deleted) return doDeleteContact.mutate(c.id);
        return doSaveContact.mutate({
          name, language, id: c.id, contact: c.contact
        });
      });
      onClose();
    }
  };

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      className={classes.container}
      overlayClassName={classes.modalOverlay}
    >

      { /* # Modal Title */ }
      <Box className={classes.header}>
        <Typography variant="h2">{organisationMode ? t('editContact') : t('setEmergencyContacts')}</Typography>
      </Box>
      <Box className={classes.modalContent}>
        <Grid container spacing={3}>

          { /* # Name & Language */ }
          <Grid item xs={12} sm={10} className={classes.nameLanguage}>
            <TextField
              variant={assetMode ? 'standard' : 'outlined'}
              className={clsx(classes.textField, { [classes.assetMode]: assetMode })}
              label={t('name')}
              onChange={e => setName(e.target.value)}
              value={name || ''}
              error={stringEmpty(name)}
              inputProps={{ readOnly: assetMode, disabled: assetMode }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel className={clsx({ [classes.assetModeLabel]: assetMode })}>{t('language')}</InputLabel>
              <Select
                variant={assetMode ? 'standard' : 'outlined'}
                id="language"
                value={language}
                displayEmpty
                label={t('language')}
                onChange={e => setLanguage(e.target.value)}
                className={clsx(classes.select, { [classes.assetMode]: assetMode })}
                inputProps={{ readOnly: assetMode, disabled: assetMode }}
              >
                {languages.map(lang => (
                  <MenuItem key={lang} value={lang}>{lang}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {assetMode && (
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item className={classes.notify} sm={2}>{t('enable')}</Grid>
            </Grid>
          )}

          { /* # Contacts map */ }
          {contacts?.sort((a, b) => b.type.localeCompare(a.type)).map(c => (
            <React.Fragment key={c.id}>
              {c.type !== 'email' ? (
                <>
                  <Grid item xs={12} sm={3}>
                    {/* > Country Code Input */}
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel className={clsx({ [classes.assetModeLabel]: assetMode })}>{t('countryCode')}</InputLabel>
                      <Select
                        variant={assetMode ? 'standard' : 'outlined'}
                        id="countryCode"
                        name="countryCode"
                        value={getContactCountryCode(c.contact)}
                        label={t('countryCode')}
                        onChange={e => {
                          const updatedCallingCode = countryAndCallingCodes.find(ccc => e.target.value === ccc.countryCode)?.callingCode;
                          const numberWithUpdatedCallingCode = `+${updatedCallingCode} ${numberWithoutCountryCode(c)}`;
                          updateContact(c.id, 'contact', numberWithUpdatedCallingCode);
                        }}
                        className={clsx(classes.select, { [classes.assetMode]: assetMode })}
                        disabled={c.deleted}
                        inputProps={{ readOnly: assetMode || c.deleted, disabled: assetMode || c.deleted }}
                      >
                        {countryAndCallingCodes.map(country => (
                          <MenuItem key={country.countryCode} value={country.countryCode}>{`${country.countryCode} (+${country.callingCode})`}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    {/* > Phone/SMS Input */}
                    <TextField
                      variant={assetMode ? 'standard' : 'outlined'}
                      className={clsx(classes.textField, { [classes.assetMode]: assetMode })}
                      label={t(c.type)}
                      onChange={e => {
                        const callingCode = getContactCallingCode(c.contact);
                        const updatedNumber = `+${callingCode} ${e.target.value}`;
                        // TODO: validate and format based on country code (using libphonenumber-js)
                        // convertToE164Format('phone', phoneCountryCode, next, setPhone, setPhoneE164Format)}
                        updateContact(c.id, 'contact', formatIncompletePhoneNumber(updatedNumber));
                      }}
                      value={numberWithoutCountryCode(c)}
                      error={stringEmpty(numberWithoutCountryCode(c))} // && (contact?.phone && parsePhoneNumberFromString(contact.phone) ? contact.phone.replace(`+${parsePhoneNumberFromString(contact.phone).countryCallingCode}`, '') !== phone : !phoneFieldsAreValid)
                      disabled={c.deleted}
                      inputProps={{ readOnly: assetMode || c.deleted, disabled: assetMode || c.deleted }}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={10}>
                  {/* > Email Input */}
                  <TextField
                    variant={assetMode ? 'standard' : 'outlined'}
                    className={clsx(classes.textField, { [classes.assetMode]: assetMode })}
                    label={t('email')}
                    onChange={e => updateContact(c.id, 'contact', e.target.value)}
                    value={c.contact || ''}
                    error={!isValidEmail(c.contact)}
                    disabled={c.deleted}
                    inputProps={{ readOnly: assetMode || c.deleted, disabled: assetMode || c.deleted }}
                  />
                </Grid>
              )}
              {assetMode && (
                <Grid item xs={12} sm={2} key={c.id} className={classes.notifyCheckboxContainer}>
                  {/* > Notify Checkbox */}
                  <FormControl variant="outlined" className={classes.notifyContainerFormControl}>
                    {c.type === 'email' && (
                      <Tooltip title={t('emergencyEmail')}>
                        <IconButton
                          className={clsx([classes.iceButton, { [classes.active]: c.iceEmail }])}
                          onClick={() => updateContact(c.id, 'iceEmail', !c.iceEmail)}
                          disabled={c.deleted}
                          size="large"
                        >
                          <Email />
                        </IconButton>
                      </Tooltip>
                    )}
                    {c.type === 'phone' && (
                      <Tooltip title={t('emergencyPhone')}>
                        <IconButton
                          className={clsx([classes.iceButton, { [classes.active]: c.icePhone }])}
                          onClick={() => updateContact(c.id, 'icePhone', !c.icePhone)}
                          disabled={c.deleted}
                          size="large"
                        >
                          <Phone />
                        </IconButton>
                      </Tooltip>
                    )}
                    {c.type === 'sms' && (
                      <>
                        <Tooltip title={t('emergencyPhone')}>
                          <IconButton
                            className={clsx([classes.iceButton, { [classes.active]: c.icePhone }])}
                            onClick={() => updateContact(c.id, 'icePhone', !c.icePhone)}
                            disabled={c.deleted}
                            size="large"
                          >
                            <Phone />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t('emergencySms')}>
                          <IconButton
                            className={clsx([classes.iceButton, { [classes.active]: c.iceSms }])}
                            onClick={() => updateContact(c.id, 'iceSms', !c.iceSms)}
                            disabled={c.deleted}
                            size="large"
                          >
                            <Sms />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </FormControl>
                </Grid>
              )}
              {organisationMode && (
                <Grid item xs={12} sm={2} className={classes.deleteButton}>
                  <Tooltip title={t('deleteIceContact')} placement="bottom" arrow>
                    <IconButton onClick={() => setContactDeleted(c.id)} size="large">
                      {c.deleted ? (
                        <Undo className={classes.actionButton} />
                      ) : (
                        <Delete className={classes.actionButton} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </React.Fragment>
          ))}

        </Grid>
      </Box>

      { /* # Cancel / Save Buttons */ }
      <Box className={classes.footer}>
        <Button className={classes.inputButton} onClick={onClose} tabIndex={-1}>{t('cancel')}</Button>
        <Button
          className={classes.inputButton}
          variant="contained"
          color="primary"
          disabled={!canSave}
          onClick={onSave}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
      </Box>
    </Modal>
  );
};

export default OrganisationContactModal;

// # Delete Contact (Org page only)
//     if (iceContactAssets.length > 0) {
//       setCancelButtonHidden(true);
//       showConfirmDialog(
//         t('unableToDeleteContactTitle', { contactName: contact.name }), (
//           <>
//             <Box component="span">{t('isAssetIceContact', { contactName: contact.name })}</Box>
//             {iceContactAssets.map(({ id: iceContactAssetId, name, make }) => (
//               <ListItem key={iceContactAssetId} className={classes.assetLink} button component="span" onClick={() => navigate(`/asset/${iceContactAssetId}`)}>
//                 &#8729;
//                 <span className={classes.assetName}>{name}</span>
//                 &nbsp;
//                 {make && <span>{`- ${make}`}</span>}
//               </ListItem>
//             ))}
//           </>
//         )
//       );
//     } else {
//       showConfirmDialog(
//         t('confirmDeleteContactTitle', { contactName: contact.name }),
//         t('confirmDeleteContactText', { contactName: contact.name }),
//         () => {
//           setCancelButtonHidden(false);
//           doContactDelete(contact.id);
//         }
//       );
//     }
//   }

// setInvalidNumberWarningText(t('existingEmailIceContact'));
// setInvalidNumberWarningText(t('countryCodeAndNoPhoneAndNoSms', {
// setInvalidNumberWarningText(t('countryCodeAndNoSms', { smsCountryCode }));
// setInvalidNumberWarningText(t('countryCodeAndNoPhone', { phoneCountryCode }));
// setInvalidNumberWarningText(t('phoneAndSmsAndNoCountryCode'));
// setInvalidNumberWarningText(t('phoneAndNoCountryCode'));
// setInvalidNumberWarningText(t('smsAndNoCountryCode'));
// setInvalidNumberWarningText(t('invalidNumbers', {
// setInvalidNumberWarningText(t('invalidPhoneNumber', { phoneCountryCode }));
// setInvalidNumberWarningText(t('invalidSmsNumber', { smsCountryCode }));
