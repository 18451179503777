import React from 'react';
import clsx from 'clsx';
import { Box, Icon } from '@mui/material';
import { Phone, Sms, Email } from '@mui/icons-material';
import useTranslation from 'hooks/useTranslation';
import useStyles from './iceContactState-styles';

const IceContactState = ({
  contact
}) => {
  const t = useTranslation('emergencyContacts');
  const classes = useStyles();
  const phoneOn = contact?.find(c => c.icePhone);
  const emailOn = contact?.find(c => c.iceEmail);
  const smsOn = contact?.find(c => c.iceSms);
  return (
    <Box>
      <Icon
        className={clsx([classes.iceButton, { [classes.active]: phoneOn }])}
        title={t('emergencyPhone')}
      >
        <Phone />
      </Icon>
      <Icon
        className={clsx([classes.iceButton, { [classes.active]: smsOn }])}
        title={t('emergencySMS')}
      >
        <Sms />
      </Icon>
      <Icon
        className={clsx([classes.iceButton, { [classes.active]: emailOn }])}
        title={t('emergencyEmail')}
      >
        <Email />
      </Icon>
    </Box>
  );
};

export default IceContactState;
