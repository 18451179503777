import { debounce } from 'lodash';

export const filterAssetReports = (assetId, filter) => ({
  type: 'FILTER_ASSET_REPORTS',
  payload: { assetId, filter }
});

export const filterMissionReports = (missionId, filter) => ({
  type: 'FILTER_MISSION_REPORTS',
  payload: { missionId, filter }
});

export const setHighlightedReportId = id => ({
  type: 'SET_HIGHLIGHTED_REPORT',
  payload: { id }
});

export const setSelectedReport = (mapId, report) => ({
  type: 'SET_SELECTED_REPORT',
  payload: { mapId, report }
});

const dispatchSetMostRecentDeviceReport = (timestamp, dispatch) => dispatch({
  type: 'SET_MOST_RECENT_DEVICE_REPORT',
  payload: { timestamp }
});
const debouncedSetMostRecentDeviceReport = debounce(dispatchSetMostRecentDeviceReport, 1000, { maxWait: 1000 });
export const setMostRecentDeviceReport = timestamp => dispatch => {
  debouncedSetMostRecentDeviceReport(timestamp, dispatch);
};

export const setUnreadTextMessage = (deviceId, reportId) => ({
  type: 'SET_UNREAD_TEXT_MESSAGE',
  payload: { deviceId, reportId }
});

export const markRead = deviceId => ({
  type: 'MARK_READ',
  payload: { deviceId }
});
