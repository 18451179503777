import React from 'react';
import { Box } from '@mui/material';
import Lottie from 'react-lottie-player';
import clsx from 'clsx';
import useStyles from './noHeaderNoFooterLoading-styles';
import * as animationData from './loader.json';
import './loading.css';

const NoHeaderNoFooterLoadingPage = props => {
  const { extraClasses } = props;
  const classes = useStyles();
  const allClasses = extraClasses ? [classes.wrapper, extraClasses] : [classes.wrapper];

  return (
    <Box className={classes.content}>
      <Box className={clsx(allClasses)}>
        <Lottie
          loop
          animationData={animationData}
          play
          style={{ width: 100, height: 100 }}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
      </Box>
    </Box>
  );
};

export default NoHeaderNoFooterLoadingPage;
