import React from 'react';
import clsx from 'clsx';
import { Grid, Box, Tooltip } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useStyles from './baseLayerPicker-styles';

const BaseLayerPicker = ({
  mapId,
  baseLayerId,
  baseLayers,
  setBaseLayer
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.map.settingsDialog');
  return (
    <Grid container spacing={1} className={classes.container}>
      {baseLayers.map(bl => (
        <Grid item xs={12} key={bl.template.name} className={classes.gridItem}>
          <Tooltip title={bl.template.name}>
            <Box
              className={clsx(classes.buttonWrapper, { [classes.selected]: bl.id === baseLayerId })}
              onClick={() => setBaseLayer(mapId, bl)}
            >
              <img src={bl.template.thumbnailUrl}/>
            </Box>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
};

export default BaseLayerPicker;
