import { db } from 'firebase';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc
} from 'firebase/firestore';
import { getRefreshedToken, getUID } from './auth';

const ORGANISATIONS_BASE_URL = `${import.meta.env.VITE_FUNCTIONS_BASE_URL}/organisations`;

export const fetchGlobalRoles = async (): Promise<Role[]> => {
  const globalRoles = (await getDocs(query(collection(db, 'roles')))).docs.map(d => {
    const r = d.data() as Role;
    r.id = d.ref.path;
    return r;
  });
  return globalRoles;
};

export const fetchOrgRoles = async (organisationIds: string[], uid: string, allRoles: Role[]): Promise<OrgRole[]> => {
  const orgRoles: OrgRole[] = [];
  await Promise.all(organisationIds.map(async orgId => {
    const membership = doc(db, `organisations/${orgId}/members/${uid}`);
    const userDoc = await getDoc(membership);
    const roleId = userDoc.data()?.roles[0].path;
    orgRoles.push({
      organisationId: orgId,
      roleId,
      roleLabel: allRoles.find(r => r.id === roleId)?.label || '',
    });
  }));
  return orgRoles;
};

export const staffJoinOrganisation = async (usercode: string, roles: string[]): Promise<void> => {
  const membershipDoc = doc(db, `organisations/${usercode}/members/${getUID()}`);
  await setDoc(membershipDoc, {
    uid: getUID(),
    roles: roles.map(r => doc(db, r))
  });
};

export const leaveOrganisation = async (usercode: string): Promise<void> => {
  const membershipDoc = doc(db, `organisations/${usercode}/members/${getUID()}`);
  await deleteDoc(membershipDoc);
};

export const removeMember = async ({ orgId, userId }: { orgId: string, userId: string }): Promise<void> => {
  const response: Response = await fetch(`${ORGANISATIONS_BASE_URL}/remove?org=${orgId}&uid=${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getRefreshedToken()}`
    }
  });
  if (!response.ok) throw new Error('Failed to remove member');
};

export const updateRole = async ({ orgId, userId, roleId }: { orgId: string, userId: string, roleId: string }): Promise<Response> => {
  const response: Response = await fetch(`${ORGANISATIONS_BASE_URL}/roles?org=${orgId}&uid=${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getRefreshedToken()}`
    },
    body: JSON.stringify({ roles: [roleId] }),
  });
  if (!response.ok) throw new Error('Failed to update role');
  return response;
};
