import React, {useState, useEffect, useCallback} from 'react';
import moment from 'utils/moment';
import useInterval from 'hooks/useInterval';
import { useDateTime } from 'hooks/settings';
import {
  Tooltip
} from '@mui/material';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const TEN_MINUTES = 10 * ONE_MINUTE;
const ONE_HOUR = 60 * ONE_MINUTE;

const getUpdateInterval = (time: moment.Moment, now: moment.Moment) => {
  // `time: null` will return NaN here, but we don't care because we handle `null` elsewhere
  const msDifference = Math.abs(now.diff(time));

  // For times less than 1 minute away, update every 5 seconds
  if (msDifference < ONE_MINUTE) return 5 * ONE_SECOND;
  // For times less than 1 hour away, update every minute
  if (msDifference < ONE_HOUR) return ONE_MINUTE;
  // Otherwise update every 10 minutes
  return TEN_MINUTES;
};

interface TimeSinceProps {
  time: moment.Moment | null
  now: moment.Moment | null
  timezone: string
  className?: string
  tooltip: boolean
  tooltipFormat: string
  Component: JSX.Element | string
  fallbackString: string
}

// @ts-ignore
const TimeSince = ({
  time, // must be either a moment() or null
  now, // must be either a moment() or null
  timezone,
  className,
  tooltip = false,
  tooltipFormat = 'YYYY-MM-DD HH:mm:ss z',
  Component = 'span',
  fallbackString // Used if !time (eg: "Never")
}: TimeSinceProps): JSX.Element => {
  const nowMoment = now || moment();
  const timeMoment = moment(time);
  const isToday = timeMoment.isSame(nowMoment, 'day');

  const getDisplayTime = useCallback((): string => {
    if (!time) return fallbackString;
    if (now) {
      // historic mode, show a timestamp, not a relative time
      return isToday
        ? timeMoment.tz(timezone).format('HH:mm z')
        : timeMoment.tz(timezone).format('HH:mm, D MMM YYYY');
    }
    // not historic mode, use relative time
    return timeMoment.from(nowMoment);
  }, [fallbackString, isToday, now, nowMoment, time, timeMoment, timezone]);

  const [timespanStr, setTimespanStr] = useState(getDisplayTime());
  useInterval(() => {
    setTimespanStr(getDisplayTime());
  }, getUpdateInterval(timeMoment, nowMoment));
  useEffect(() => {
    setTimespanStr(getDisplayTime());
  }, [timeMoment, nowMoment, fallbackString, getDisplayTime]);

  const tooltipStr = useDateTime(timeMoment, tooltipFormat);

  if (tooltip) {
    return (
      <Tooltip title={tooltipStr} placement="bottom" arrow>
        {/* @ts-ignore */}
        <Component className={className}>{timespanStr}</Component>
      </Tooltip>
    );
  }
  // @ts-ignore
  return <Component className={className}>{timespanStr}</Component>;
};

export default TimeSince;
