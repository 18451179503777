import { debounce } from 'lodash';

/* eslint-disable camelcase */
interface ADSBExchangeResponse {
  ac: Aircraft[]
  msg: string
  now: number
  total: number
  ctime: number
  ptime: number
}

interface Aircraft {
  hex: string
  type: string
  t: string // type of aircraft (e.g. C172)
  alt_baro: string | number
  lat: number
  long: number
  received: number
  alt_geom?: number
  track?: number
  gs?: number // ground speed
  r?: string // registration
  seen: number // seconds before now it was seen
}

export const getAircraftWithin = async (lat: number, lon: number, dist = 250): Promise<Aircraft[]> => {
  const options = {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': '436a491838mshdcf79aca66e2e7dp1b058ejsn62fa8838dec9',
      'X-RapidAPI-Host': 'adsbexchange-com1.p.rapidapi.com'
    }
  };

  const url = `https://adsbexchange-com1.p.rapidapi.com/v2/lat/${lat}/lon/${lon}/dist/${dist}/`;

  const resp = await fetch(url, options);
  const res = await resp.json() as ADSBExchangeResponse;
  return res.ac.map(ac => { ac.received = (res.now / 1000) - ac.seen; return ac; });
};

