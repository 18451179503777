import * as app from './app';
import * as asset from './asset';
import * as map from './map';
import * as router from './router';
import * as session from './session';
import * as settings from './settings';
import * as ui from './ui';
import * as reports from './reports';
import * as legs from './legs';
import * as websocket from './websocket';

export default {
  app,
  asset,
  map,
  router,
  session,
  settings,
  ui,
  reports,
  legs,
  websocket,
};
