import React from 'react';
import { Grid } from '@mui/material';

import TableOfContents from 'components/shared/tableOfContents';
import useStyles from './scrollSpyContainer-styles';

const ScrollSpyContainer = ({
  // Array of { id, title }
  categories,
  children
}) => {
  const classes = useStyles();

  return (
    <Grid
      id="scrollSpyWrapper"
      className={classes.pageWrapper}
      container
      spacing={0}
    >
      <Grid item xs={12} sm={9} md={10}>
        {children}
      </Grid>

      <Grid item xs={12} sm={3} md={2}>
        <TableOfContents
          rootElId="#scrollSpyWrapper"
          categories={categories.filter(category => !category.hidden)}
        />
      </Grid>
    </Grid>
  );
};

export default ScrollSpyContainer;
