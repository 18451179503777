import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Button,
  List
} from '@mui/material';
import { isSignificantReport, labelToDisplayLabel, labelToIconType } from 'helpers/events';
import CloseIcon from '@mui/icons-material/Close';
import useTranslation from 'hooks/useTranslation';
import EventListItem from './eventListItem-view';
import FormListItem from './formListItem-view';
import useStyles from './filter-styles';

const Filter = ({
  toggleFilter,
  filtersToggle,
  reports,
  updateFilter,
  filter
}) => {
  const classes = useStyles();
  const t = useTranslation('contextbox.asset.activity.filter');

  const SELECT_ALL_TEXT = t('selectAll');
  const CLEAR_ALL_TEXT = t('clearAll');

  const [eventTypes, setEventTypes] = useState([]);
  useEffect(() => {
    const ets = reports?.filter(isSignificantReport)
      .reduce((acc, r) => ({
        ...acc,
        [r.events[0]]: true
      }), []) ?? {};
    setEventTypes(Object.keys(ets));
  }, [reports]);

  const [displayButtonText, setDisplayButtonText] = useState('');
  useEffect(() => {
    const filtersLen = filter.blacklist.length + (filter.forms ? 0 : 1);
    const eventsLen = eventTypes.length + 1;
    const moreThanHalf = filtersLen / eventsLen > 0.5;
    setDisplayButtonText(moreThanHalf ? SELECT_ALL_TEXT : CLEAR_ALL_TEXT);
  }, [filter, eventTypes]);

  const handleSelectAll = e => {
    if (e.currentTarget.value === SELECT_ALL_TEXT) {
      updateFilter({
        ...filter,
        blacklist: [],
        forms: true
      });
    } else {
      updateFilter({
        ...filter,
        blacklist: [...eventTypes],
        forms: false
      });
    }
  };

  return (
    <Dialog
      open={filtersToggle}
      onClose={toggleFilter}
      aria-labelledby="event-filter-title"
      aria-describedby="event-filter-description"
    >
      <Box className={classes.header}>
        <Typography variant="h2">{t('eventsFilter')}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={toggleFilter}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent className={classes.dialogContent}>
        <List className={classes.list}>
          {eventTypes.map(type => (
            <EventListItem
              key={type}
              title={labelToDisplayLabel(type)}
              iconType={type}
              selected={!filter.blacklist.includes(type)}
              onSelect={selected => (selected
                ? updateFilter({ ...filter, blacklist: filter.blacklist.filter(b => b !== type) })
                : updateFilter({ ...filter, blacklist: [...filter.blacklist, type] }))}
            />
          ))}
          {reports?.some(r => r.form) && (
            <FormListItem
              selected={filter.forms || false}
              onSelect={selected => (selected
                ? updateFilter({ ...filter, forms: true })
                : updateFilter({ ...filter, forms: false }))}
            />
          )}
        </List>
      </DialogContent>
      <Box className={classes.footer}>
        <Button className={classes.selectAll} value={displayButtonText} onClick={handleSelectAll}>{displayButtonText}</Button>
        <Button className={classes.cancel} onClick={toggleFilter}>{t('close')}</Button>
      </Box>
    </Dialog>
  );
};
export default Filter;
