// Custom fragment shader for ColouredIconLayer
export default `\

#define SHADER_NAME coloured-fragment-shader

precision highp float;
uniform float opacity;
uniform sampler2D iconsTexture;
uniform float alphaCutoff;
varying float vColorMode;
varying vec4 vColor;
varying vec2 vTextureCoords;
varying vec2 uv;
void main(void) {
  /* geometry.uv = uv;
  vec4 texColor = texture2D(iconsTexture, vTextureCoords);
  // if colorMode == 0, use pixel color from the texture
  // if colorMode == 1 or rendering picking buffer, use texture as transparency mask
  vec3 color = mix(texColor.rgb, vColor.rgb, vColorMode);
  // Take the global opacity and the alpha from vColor into account for the alpha component
  float a = texColor.a * opacity * vColor.a;
  if (a < alphaCutoff) {
    discard;
  }
  gl_FragColor = vec4(color, a);
  DECKGL_FILTER_COLOR(gl_FragColor, geometry); */

  geometry.uv = uv;
  vec4 texColor = texture2D(iconsTexture, vTextureCoords);

  if (texColor.a < alphaCutoff) {
    discard;
  }

  float d = dot(vec4(1.0, 1.0, 1.0, 0.0), texColor);
  if (d > 1.5) {
    gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
  }
  else if (d > 1.0) {
    gl_FragColor = mix(vColor, vec4(1.0, 1.0, 1.0, 1.0), 0.75);
  }
  else if (d > 0.5) {
    gl_FragColor = mix(vColor, vec4(1.0, 1.0, 1.0, 1.0), 0.5);
  }
  else if (d > 0.1) {
    gl_FragColor = mix(vColor, vec4(1.0, 1.0, 1.0, 1.0), 0.1);
  }
  else {
    gl_FragColor = vec4(vColor.rgb,texColor.a);
  }
  gl_FragColor = picking_filterPickingColor(gl_FragColor);
}

`;
