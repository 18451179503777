import React from 'react';
import {
  FormControl,
  Typography,
  FormHelperText,
  Slider
} from '@mui/material'

import useStyles from './input-styles';

const SliderInput = ({
  id,
  label,
  value,
  onChange,
  helperText,
  marks,
  min,
  max,
  step,
  valueLabelDisplay = 'auto',
  disabled
}) => {
  const classes = useStyles();

  return (
    <FormControl variant="standard" className={classes.sliderField} fullWidth>
      <Typography
        className={classes.label}
        htmlFor={id}
      >
        {label}
      </Typography>
      <Slider
        className={classes.slider}
        id={id}
        marks={marks}
        value={value}
        min={min}
        max={max}
        step={step}
        valueLabelDisplay={valueLabelDisplay}
        onChange={(e, newValue) => newValue !== value && onChange(id, newValue)}
        disabled={disabled}
      />
      {helperText &&
        <FormHelperText>{helperText}</FormHelperText>
      }
    </FormControl>
  );
};

export default SliderInput;
