import Smart from 'hocs/smart';
import { eventFilterSelector } from 'selectors/events';
import View from './significantReport-view';

export default Smart(
  View,
  state => ({
    reportFilter: eventFilterSelector(state),
    highlightedReportId: state.reports.highlightedReportId,
    selectedMapId: state.map.selectedMapId
  }),
  ac => ({
    updateFilter: ac.reports.filterAssetReports,
    setHighlightedReportId: ac.reports.setHighlightedReportId,
    centerMapOnEvent: ac.map.centerMapOnEvent,
    setSelectedReport: ac.reports.setSelectedReport
  })
);
