import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'store';

import MapPage from 'components/pages/map';
import LoginPage from 'components/pages/auth/login';
import ResetPasswordPage from 'components/pages/auth/resetPassword';
import SignupPage from 'components/pages/auth/signup';
import TermsPage from 'components/pages/legal/terms';
import SetNewPasswordPage from 'components/pages/auth/setNewPassword';
import CompatibilityPage from 'components/pages/compatibility';
import ErrorPage from 'components/pages/error';
import MessagingPage from 'components/pages/messaging';
import ConnectionsPage from 'components/pages/connections';
import SimpleSharingPage from 'components/pages/simpleSharing';
import SettingsPage from 'components/pages/settings';
import GeneralSettingsPage from 'components/pages/generalSettings';
import OrganisationSettingsPage from 'components/pages/organisationSettings';
import AccountSettingsPage from 'components/pages/accountSettings';
import ManagePage from 'components/pages/manageAssetsAndDevices';
import CreateAssetPage from 'components/pages/asset/createAsset';
import AssetPage from 'components/pages/asset';
import StaffPage from 'components/pages/staffPage';
import OrganisationsListPage from 'components/pages/staffPage/organisationsList';
import ProvisioningListPage from 'components/pages/staffPage/provisioning';
import DistressIncidentsPage from 'components/pages/staffPage/distressIncidents';
import MissingOrganisationPage from 'components/pages/missingOrganisationPage';
import ReportingPage from 'components/pages/reporting';
import LegacyInviteUser from 'components/pages/legacyInviteUser';
import PrivateRoute from './privateRoute';

const Router = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <PrivateRoute path="/" exact component={MapPage} />
      <Route path="/login" exact component={LoginPage} />
      <Route path="/reset-password" exact component={ResetPasswordPage} />
      <Route path="/signup" exact component={SignupPage} />
      <Route path="/legal/terms" exact component={TermsPage} />
      <Route path="/set-new-password" exact component={SetNewPasswordPage} />
      <Route path="/compatibility" exact component={CompatibilityPage} />
      <Route path="/error" exact component={ErrorPage} />
      <Route path="/invite-viewonly" exact component={LegacyInviteUser} />
      <PrivateRoute path="/search" exact component={MapPage} />
      <PrivateRoute path="/messaging" exact component={MessagingPage} />
      <PrivateRoute path="/friends" exact component={ConnectionsPage} />
      <PrivateRoute path="/sharing" exact component={SimpleSharingPage} />
      <PrivateRoute path="/settings" exact component={SettingsPage} />
      <PrivateRoute path="/settings/general" exact component={GeneralSettingsPage} />
      <PrivateRoute path="/settings/organisation" exact component={OrganisationSettingsPage} />
      <PrivateRoute path="/settings/account" exact component={AccountSettingsPage} />
      <PrivateRoute path="/assets-and-devices" exact component={ManagePage} />
      <PrivateRoute path="/asset/new" exact component={CreateAssetPage} />
      <PrivateRoute path="/asset/:id" exact component={AssetPage} />
      <PrivateRoute path="/reporting" exact component={ReportingPage} />
      <PrivateRoute isStaffOnly path="/staff" exact component={StaffPage} />
      <PrivateRoute isStaffOnly path="/staff/organisations" exact component={OrganisationsListPage} />
      <PrivateRoute isStaffOnly path="/staff/distress-incidents" exact component={DistressIncidentsPage} />
      <PrivateRoute isStaffOnly path="/staff/provisioning" exact component={ProvisioningListPage} />
      <PrivateRoute path="/missing-organisation" exact component={MissingOrganisationPage} />
      <Redirect to="/" />
    </Switch>
  </ConnectedRouter>
);

export default Router;
