import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import DragIndicator from '@mui/icons-material/DragIndicator';
import useStyles from './dragAndDropTable-styles';

// IMPORTANT: rowData is an array of objects and each object inside rowData MUST have an id property
const DragAndDropTable = ({
  tableName,
  columns,
  rowData,
  noDataText,
  onDrop,
  includeNumberIndex
}) => {
  const classes = useStyles();
  return (
    <table className={classes.dragAndDropTable}>
      {tableName && <caption>{tableName}</caption>}
      <thead>
        <tr>
          {rowData?.length > 0 && includeNumberIndex && <th className={classes.numberIndexColumn}> </th>}
          {columns.map(({ title, textAlign }) => <th key={title} style={{ textAlign }}>{title}</th>)}
          {rowData?.length > 0 && <th className={classes.draggableIconColumn}> </th>}
        </tr>
      </thead>
      <DragDropContext onDragEnd={onDrop}>
        <Droppable droppableId={`${tableName}`}>
          {({ droppableProps, innerRef: droppableRef, placeholder }) => (
            <tbody
              {...droppableProps}
              ref={droppableRef}
            >
              {rowData?.length > 0
                ? (rowData.map((data, index) => (
                  <Draggable key={data.id} draggableId={data.id.toString()} index={index}>
                    {({ draggableProps, innerRef: draggableRef, dragHandleProps }, { isDragging }) => {
                      const trStyle = { ...draggableProps.style };

                      return (
                        <tr
                          ref={draggableRef}
                          {...draggableProps}
                          {...dragHandleProps}
                          className={clsx({ [classes.draggingTr]: isDragging })}
                          style={trStyle}
                        >
                          {includeNumberIndex && <td className={clsx({ [classes.draggingTd]: isDragging })}>{`${index + 1}.`}</td>}
                          {columns.map(({ field, textAlign }, i) => {
                            const fields = field.split('.');

                            return (
                              <td key={`${data.id + i}`} className={clsx({ [classes.draggingTd]: isDragging })} style={{ textAlign }}>
                                {fields.reduce((acc, current) => acc[current], data)}
                              </td>
                            );
                          })}
                          <td className={clsx({ [classes.draggingTd]: isDragging })}>
                            <DragIndicator />
                          </td>
                        </tr>
                      );
                    }}
                  </Draggable>
                )))
                : (
                  <tr className={classes.noVoiceContacts}>
                    {rowData?.length > 0 && includeNumberIndex && <td />}
                    <td colSpan={columns.length} style={{ textAlign: includeNumberIndex && 'center' }}>{noDataText}</td>
                  </tr>
                )}
              {placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </table>
  );
};

export default DragAndDropTable;
