import Smart from 'hocs/smart';
import UserPocketView from './userPocket-view';

export default Smart(
  UserPocketView,
  state => ({
    loggedIn: state.session.loggedIn
  }),
  ac => ({
    logout: ac.session.logout,
    resetEverything: ac.app.resetEverything,
    navigate: ac.router.navigate
  })
);