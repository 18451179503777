import Smart from 'hocs/smart';
import LoadingView from './loading-view';

export default Smart(
  LoadingView,
  state => ({
    systemStatusOpen: state.ui.systemStatusOpen
  }),
  () => ({})
);
