import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderBottom: theme.border.default,
  },
  row: {
    marginTop: theme.spacing(2)
  },
  icon: {
    color: theme.palette.grey[600],
    fontSize: '2rem'
  },
  badge: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  }
}));
