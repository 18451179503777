import hereHybrid from './here-hybrid';
import hereDark from './here-dark';
import hereTerrain from './here-terrain';

import gibsSatellite from './gibs-satellite';

// import svVFR from './skyvector-vfr';
// import svIFRHi from './skyvector-ifr-hi';
// import svIFRLo from './skyvector-ifr-lo';

export const library = 'reactmapgl';

const createTemplate = (id, template) => ({ create: (...args) => ({ template: template(...args), library, id }), id });

export default [
  createTemplate('reactmapgl-here-hybrid', hereHybrid),
  createTemplate('reactmapgl-here-terrain', hereTerrain),
  createTemplate('reactmapgl-here-dark', hereDark),
  createTemplate('reactmapgl-gibs-satellite', gibsSatellite)

  /*
       * SkyVector is currently broken.
       *
       * SkyVector's server does not add the `Access-Control-Allow-Origin` header to
       * their server response. Their tiles cannot be consumed by web browsers. U1 had
       * a small proxy running on our own server to access the tiles. We currently
       * do not want to create a proxy for TracStarPro++3Cloud
       */
  // createTemplate('reactmapgl-sv-vfr', svVFR),
  // createTemplate('reactmapgl-sv-ifr-hi', svIFRHi),
  // createTemplate('reactmapgl-sv-ifr-lo', svIFRLo)
].reduce((acc, x) => ({
  ...acc,
  [x.id]: x.create
}), {});
