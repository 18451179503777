import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    width: '100%'
  },
  headingText: {
    fontWeight: 400,
    color: theme.palette.common.text,
    marginBottom: theme.spacing(6)
  },
  groupButton: {
    cursor: 'pointer',
    flex: 1,
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    border: theme.border.default,
    borderColor: theme.border.color,
    backgroundColor: theme.palette.common.white,
    transition: 'all 0.15s',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '& p': {
        color: theme.palette.common.white
      }
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '& p': {
        color: theme.palette.common.white
      }
    },
  },
  isCurrent: {
    borderColor: theme.palette.background.activeButton,
    backgroundColor: theme.palette.background.activeButton,
    '& p': {
      color: theme.palette.common.white
    }
  },
  iconWrapper: {
    display: 'flex',
    width: 42,
    height: 42,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.lightBlue,
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    marginBottom: theme.spacing(2),
    '& svg': {
      width: 24,
      height: 24,
      '& path, circle, polygon': {
        fill: theme.palette.primary.main
      }
    }
  },
  button: {
    textAlign: 'center'
  },
  buttonText: {
    fontSize: '1.2rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    color: theme.palette.common.text
  }
}));
