import React, { useRef } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useStyles from './toggleablePanel-styles';

interface ToggleablePanelProps {
  header: React.ReactNode,
    children: React.ReactNode,
}

// A Toggleable Panel, this will render it's "header" at all times and, if clicked,
// will toggle a Panel containing any `children` at the location specified by `style`.
const ToggleablePanel = ({
  header, // the static content rendered whether the panel is open or not
  children,
}: ToggleablePanelProps): JSX.Element => {
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const classes: ClassNameMap = useStyles();
  useOnClickOutside(ref, () => setIsOpen(false));
  const toggle = (): void => setIsOpen(!isOpen);

  return (
    <div ref={ref} className={classes.container}>
      <Box onClick={toggle}>
        {header}
      </Box>
      <Box className={clsx(classes.content, isOpen ? 'open' : 'close')}>
        {children}
      </Box>
    </div>
  );
};

export default ToggleablePanel;
