/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import useTranslation from 'hooks/useTranslation';
import insensitiveSort from 'utils/insensitiveSort';
import {
  Container,
  Box,
  Select, MenuItem
} from '@mui/material';
import { MTableBodyRow } from '@material-table/core';
import { Edit } from '@mui/icons-material';
import InfoIcon from 'components/shared/icons/infoIcon';
import tableIcons from 'components/shared/icons/tableIcons';
import PersistentTable from 'components/shared/persistentTable';
import { Permissions } from 'selectors/permissions';
import { NoHeaderNoFooterLoadingPage } from 'components/pages/loading';
import { useGetAssetsList } from 'apis/rest/assets';
import useStyles from './assetsList-styles';

interface AssetListPageParams {
  navigate: (url: string) => void,
  organisationId: string,
  permissions: Permissions,
  serialType: string,
  displaySnackbar: (Snack: Snack) => void,
  updateSetting: (category: string, field: string, value: any) => void
}

const AssetListPage = ({
  navigate,
  organisationId,
  permissions,
  serialType,
  displaySnackbar,
  updateSetting
}:AssetListPageParams): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('pages.assetsList');
  const tAssetsTable = useTranslation('pages.assetsList.assetsTable');
  const serialTypes = ['tpSerial', 'imei', 'manufacturerSerial'];
  const getAssets = useGetAssetsList(organisationId);
  const columnObject = useCallback(() => ({
    columns: [
      {
        title: tAssetsTable('name'),
        field: 'name',
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' },
        defaultSort: 'asc',
        customSort: (a:any, b:any) => insensitiveSort(a.name, b.name)
      },
      {
        title: tAssetsTable('makeModelVariant'),
        field: 'makeModelVariant',
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' },
        customSort: (a:any, b:any) => insensitiveSort(a.makeModelVariant, b.makeModelVariant)
      },
      // {
      //   title: tAssetsTable('category'),
      //   field: 'category',
      //   headerStyle: { textAlign: 'left' },
      //   cellStyle: { textAlign: 'left' },
      //   customSort: (a, b) => insensitiveSort(a.category, b.category)
      // },
      {
        title: tAssetsTable('installation'),
        field: 'device',
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' },
        defaultSort: 'asc',
        customSort: (a:any, b:any) => insensitiveSort(a.device, b.device)
      },
      {
        title: t(`assetsTable.${serialType}`),
        field: serialType,
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' },
        defaultSort: 'asc',
        customSort: (a:any, b:any) => insensitiveSort(a[serialType]?.toString(), b[serialType]?.toString())
      },
      {
        title: 'Watchlist Group',
        field: 'watchListGroup',
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' },
        customSort: (a:any, b:any) => insensitiveSort(a.watchListGroup, b.watchListGroup)
      },
      {
        title: tAssetsTable('owner'),
        field: 'owner',
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' },
        customSort: (a:any, b:any) => insensitiveSort(a.owner, b.owner),
      }
    ]
  }), [serialType])();

  if (getAssets.isError) {
    displaySnackbar({
      id: 'assetLoadError',
      text: t('loadAssetsListFailed'),
      type: 'error',
    });
  }

  const maxTableHeight = `${window.innerHeight > 570 ? window.innerHeight - 420 : 150}px`;

  const cleanedAssets = getAssets.data ? getAssets.data.filter(a => a.category !== 'System').map(a => {
    const makeModel = [a.deviceMake, a.deviceModel].filter(Boolean).join(', ');
    return {
      name: a.name,
      category: a.category,
      makeModelVariant: [a.make, a.model, a.variant].filter(Boolean).join(' '),
      device: makeModel,
      owner: a.ownerName,
      ownerId: a.ownerId,
      ownedByUser: a.ownerId.toLowerCase() === organisationId.toLowerCase(),
      id: a.id,
      imei: a.deviceImei ?? '-',
      tpSerial: a.deviceTracPlusSerial ?? '-',
      manufacturerSerial: a.deviceManufacturerSerial ?? '-',
      watchListGroup: a.watchlistGroup,
    };
  }) : [];

  const editAsset = (rowData: Asset): void => {
    const link = `/asset/${rowData.id}`;
    navigate(link);
  };

  if (getAssets.isLoading) return <NoHeaderNoFooterLoadingPage />;

  return (
    <Container className={classes.tableContainer} maxWidth="md">
      <Box className={classes.materialTable}>
        <Select
          variant="standard"
          id="serialType"
          value={serialType}
          displayEmpty
          label={tAssetsTable('language')}
          onChange={e => updateSetting('ui', 'serialType', e.target.value as string)}
          className={classes.select}
        >
          { serialTypes.map(st => <MenuItem key={st} value={st}>{tAssetsTable(st)}</MenuItem>) }
        </Select>
        <PersistentTable
          settingsCategory="assetsTable"
          title={t('title')}
          icons={tableIcons}
          onRowClick={(e, rowData) => {
            // TODO: define per-asset permissions for editing assets, when editing assets is enabled
            editAsset(rowData);
          }}
          columns={columnObject.columns}
          data={cleanedAssets}
          actions={[
            // TODO: define per-asset permissions for editing assets, when editing assets is enabled
            (rowData: any) => ({
              icon: () => (rowData?.ownedByUser && permissions.canEditAssets ? (<Edit className={classes.actionButton} />) : (<InfoIcon className={classes.actionButton} />)),
              tooltip: rowData?.ownedByUser && permissions.canEditAssets ? tAssetsTable('editAsset') : tAssetsTable('viewAsset'),
              onClick: (e, row) => editAsset(row),
              // disabled: !permissions.canEditAssets, // && rowData.ownedByUser,
              // hidden: !permissions.canEditAssets // !rowData.ownedByUser
            }),
          ]}
          options={{
            filtering: true,
            draggable: false,
            showTitle: false,
            search: true,
            actionsColumnIndex: -1,
            searchFieldVariant: 'outlined',
            paging: true,
            emptyRowsWhenPaging: false,
            thirdSortClick: false,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: maxTableHeight,
          }}
          localization={{
            header: {
              actions: tAssetsTable('actions')
            },
            toolbar: {
              searchPlaceholder: tAssetsTable('search')
            }
          }}
          components={{
            Row: props => (
              <MTableBodyRow
                {...props}
                // eslint-disable-next-line react/destructuring-assignment
                // className={clsx({ [classes.notEditable]: !(permissions.canEditAssets) })}
              />
            )
          }}
        />
      </Box>
    </Container>
  );
};

export default AssetListPage;
