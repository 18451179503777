import React, {
  MouseEventHandler, useCallback, useMemo, useState
} from 'react';
import moment from 'utils/moment';
import {
  Box, IconButton, TextField, Tooltip
} from '@mui/material';
import { MobileDatePicker, PickersDay } from '@mui/x-date-pickers';
import {
  CalendarToday as CalendarTodayIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import useTranslation from 'hooks/useTranslation';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { Moment } from 'moment';
import { getReportsSummaryForAsset } from 'apis/rest/reports';
import useStyles from './datePicker-styles';
import './datePicker.css';

const DateFormat = 'ddd, MMM DD, YYYY';

interface DateSelectorProps {
  now?: Moment,
  selectedAsset?: Asset,
  timezone: string,
  updateNow: (now: Moment | null) => void,
  liveMode: boolean,
  displaySnackbar: (snack: Snack) => void,
  historicSnackbar: string,
  organisationId: string
}

const stripTimeInformation = (inp: Moment | null | undefined, timezone: string): Moment => moment.tz(inp?.format('YYYY-MM-DD'), timezone);

const DateSelector = ({
  now,
  selectedAsset,
  timezone,
  updateNow,
  liveMode,
  displaySnackbar,
  historicSnackbar,
  organisationId,
}:DateSelectorProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('omnibox.modules.datePicker');

  const [month, setMonth] = useState<Moment>(moment().tz(timezone).startOf('month'));

  const reportsSummary = useQuery(['reportSummary', selectedAsset?.id, month?.clone().format('YYYY-MM')],
    () => (month && selectedAsset?.id
      ? getReportsSummaryForAsset(organisationId, selectedAsset?.id, month, month.clone().endOf('month'))
      : []));

  const deviceWasActive = useCallback((day: Moment): boolean => {
    if (!reportsSummary.data) { return false; }
    const dayInTz = stripTimeInformation(day, timezone);
    const start = dayInTz.clone().startOf('day').unix();
    const end = dayInTz.clone().endOf('day').unix();
    return reportsSummary.data.some(summary => (summary.day <= start && summary.last >= start) || (summary.day <= end && summary.day >= start && summary.first <= end));
  }, [reportsSummary.data, timezone]);

  // Datetime dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pendingDate, setPendingDate] = React.useState<Moment>();
  const closeDialog = (): void => {
    setMonth((now?.clone() || moment()).tz(timezone).startOf('month'));
    setPendingDate(undefined);
    setIsDialogOpen(false);
  };

  const jumpToNow: MouseEventHandler = e => {
    e.stopPropagation(); // prevent a click on the "now" button from opening the calendar
    setMonth(moment().tz(timezone).startOf('month'));
    if (historicSnackbar) {
      displaySnackbar({
        id: 'historicSnackbarMessage',
        toDelete: true,
      });
    }
    updateNow(null);
  };

  const setHistoricalDateTime = (date: Moment): void => {
    if (moment(date).isSame(moment(), 'day')) {
      if (now) {
        if (historicSnackbar) {
          displaySnackbar({
            id: 'historicSnackbarMessage',
            toDelete: true,
          });
        }
        updateNow(null);
      }
    } else {
      const newDate = stripTimeInformation(date, timezone).endOf('d');
      updateNow(newDate);
      displaySnackbar({
        id: 'historicSnackbarMessage',
        text: t('historicSnackBar'),
        persist: true
      });
    }
  };

  const cancelIcon = <CancelIcon />;
  const momentTz = useMemo(() => moment().tz(timezone), [timezone]);
  const value = pendingDate || now || momentTz;

  return (
    <>
      <Box id="historicalModeButton" className={classes.historyWrapper}>
        <Box className={clsx(classes.historyButton, { [classes.hidden]: !liveMode })} onClick={() => setIsDialogOpen(true)}>
          <CalendarTodayIcon className={classes.calIcon} />
          {t('viewHistoricalData')}
        </Box>
        <MobileDatePicker<Moment>
          open={isDialogOpen}
          onOpen={() => setIsDialogOpen(true)}
          onClose={closeDialog}
          onAccept={() => pendingDate && setHistoricalDateTime(pendingDate)}
          onChange={date => setPendingDate(date ?? undefined)}
          value={value}
          renderInput={props => (now ? <TextField {...props} /> : <></>)}
          inputFormat={DateFormat}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={jumpToNow}>
                <Tooltip title={t('resetToLive')} aria-label={t('resetToLive')} placement="right">
                  {cancelIcon}
                </Tooltip>
              </IconButton>
            )
          }}
          onMonthChange={m => {
            if (!moment.isMoment(m)) { return; }
            setMonth(m.startOf('month'));
          }}
          renderDay={(day, _selectedDays, dayProps) => {
            const wasActive = !dayProps.outsideCurrentMonth && moment.isMoment(day) && deviceWasActive(day.clone());

            return (
              <PickersDay {...dayProps}>
                <Tooltip title={wasActive && selectedAsset?.name}>
                  <span>{day && moment.isMoment(day) ? day.format('D') : undefined}</span>
                </Tooltip>
                <div style={{ width: wasActive ? '50%' : '0%' }} className={classes.activeDay} />
              </PickersDay>
            );
          }}
          className={classes.datePicker}
          dayOfWeekFormatter={day => day.slice(0, 3)}
          toolbarTitle={moment(value).year()}
          disableOpenPicker
          disableFuture
          disableHighlightToday
        />
      </Box>
    </>
  );
};

export default DateSelector;
