import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import SharingView from './simpleSharing-view';

export default Smart(
  SharingView,
  state => ({
    organisationId: state.session.organisationId,
    currentTimezone: state.session.timezone,
    selectedItem: state.app.selectedItem,
    userPermissions: permissionsForCurrentUserAndOrg(state),
  }),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar,
  })
);
