import { createTheme, alpha } from '@mui/material/styles';
import colorPalette from './colorPalette';

export function spacing(...factors) {
  const size = window.innerWidth > 1440 ? 6 : 4;
  return (factors.length === 1) ? factors[0] * size : factors.map(factor => factor * size);
}

export function spacingPixels(...factors) {
  return factors.map(factor => (typeof factor === 'string' ? factor : `${spacing(factor)}px`)).join(' ');
}

// The common theme avoids duplication of styling between the light and dark themes
const createCommonTheme = mode => {
  const colors = colorPalette(mode);

  return createTheme({
    palette: {
      mode
    },
    typography: {
      fontFamily: [
        'objektiv-mk2,sans-serif',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      h1: {
        fontSize: '2rem',
        fontWeight: '500',
        color: alpha(colors.black, 0.87)
      },
      h2: {
        fontSize: '1.4rem',
        fontWeight: '500',
        color: alpha(colors.black, 0.87)
      },
      h3: {
        fontSize: '1.3rem',
        fontWeight: '500',
        color: alpha(colors.black, 0.87)
      },
      h4: {
        fontSize: '1.2rem',
        fontWeight: '500',
        color: alpha(colors.black, 0.87)
      },
      h5: {
        fontSize: '1.1rem',
        fontWeight: '500',
        color: alpha(colors.black, 0.87)
      },
      h6: {
        fontSize: '1rem',
        fontWeight: '500',
        color: alpha(colors.black, 0.87)
      },
      body1: {
        // NOTE: body1 styles are applied to the body element
        fontSize: '1rem',
        color: colors.grey
      },
      body2: {
        fontSize: '0.95rem'
      },
      subtitle1: {
        fontSize: '0.9rem',
        fontWeight: '500',
        color: colors.grey
      },
      subtitle2: {
        fontSize: '0.7rem',
        textTransform: 'uppercase'
      },
      linkUnderline: `underline dotted ${alpha(colors.black, 0.25)}`
    },
    iconography: {
      padding: {
        header: '16px'
      }
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '1.1rem',
            marginBottom: spacing(-1)
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none'
          }
        }
      },
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            padding: '0 18px',
            height: 100,
            justifyContent: 'center',
            '.MuiTypography-overline': {
              fontSize: '0.9rem'
            }
          },
          content: {
            flex: 0,
            '& button': {
              display: 'none'
            }
          }
        }
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontSize: '0.95rem'
          }
        }
      }
    }
  });
};

export default createCommonTheme;
