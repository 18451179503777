import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapper: {
    height: '78vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
