import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  detailsPanel: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(10),
    minWidth: '260px',
    borderRadius: 4,
    overflow: 'hidden',
    opacity: 1,
    textAlign: 'left',
    zIndex: 999,
    boxShadow: 'none',
    '& p': {
      color: theme.palette.common.whiteConstant,
      fontSize: '.9rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& small': {
        fontSize: '.6rem',
      },
      '&:first-child': {
        textTransform: 'uppercase',
        fontSize: '.6rem',
        fontWeight: 500
      }
    }
  },
  highContrastBackground: {
    backgroundColor: theme.palette.mapControls.bgHighContrast,
  },
  detailsPanelHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 2),
    backgroundColor: 'rgba(0,0,0,.4)',
    borderBottom: '1px solid rgba(255,255,255,.10)',
    '& h3': {
      color: theme.palette.common.whiteConstant
    },
    '& p': {
      fontWeight: 500,
      fontSize: '1rem',
      color: theme.palette.common.whiteConstant,
      marginBottom: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  detailsPanelBody: {
    padding: theme.spacing(2, 3, 0),
    backgroundColor: 'rgba(0,0,0,.4)',
    color: theme.palette.common.whiteConstant,
    '&:last-child': {
      paddingBottom: theme.spacing(0)
    }
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',
    fontSize: '1.2rem',
    marginLeft: theme.spacing(1)
  },
  gridItem: {
    '&:first-child': {
      paddingLeft: theme.spacing(0)
    },
    '&:last-child': {
      paddingRight: theme.spacing(0)
    },
    padding: theme.spacing(0, 1)
  },
  gridContainer: {
    paddingBottom: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  eventIcon: {
    display: 'inline-flex',
    flexShrink: '0',
    minWidth: '19px',
    marginLeft: theme.spacing(1),
    '& svg': {
      width: '19px',
      height: '19px',
      '& .icon-base': {
        fill: theme.palette.common.whiteConstant
      },
      '& .icon-detail': {
        fill: theme.palette.common.blackConstant,
        opacity: 0.5
      }
    }
  }
}));
