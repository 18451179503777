import { appId, appCode, getHereLangCode } from 'apis/here';

const ReactMapGlHereHybridTemplate = lang => {
  const langCode = getHereLangCode(lang);

  return {
    version: 8,
    name: 'HERE - Hybrid',
    provider: 'HERE',
    sources: {
      'here-hybrid': {
        type: 'raster',
        tiles: [
          `https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}&lg=${langCode}`,
          `https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}&lg=${langCode}`,
          `https://3.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}&lg=${langCode}`,
          `https://4.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}&lg=${langCode}`
        ],
        minZoom: 1,
        maxZoom: 19,
        tileSize: 512
      }
    },
    layers: [{
      id: 'here-hybrid',
      type: 'raster',
      source: 'here-hybrid'
    }],
    thumbnailUrl: '/img/base-layer-thumbnails/hybrid-map-thumbnail.jpg'
  };
};

export default ReactMapGlHereHybridTemplate;
