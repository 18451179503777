import { getRefreshedToken } from 'apis/auth';
import moment, { Moment } from 'moment';
import { fetchShares } from 'apis/rest/shares';
import {
  mapAsset,
  mapAssetAndDeviceToAssetDevice,
  mapDevice,
  mapTrackstarReportToTPCReport,
  mapTSConversation,
  mapTSMessage,
} from './maps';
// eslint-disable-next-line import/no-cycle
import { fetchAssetReportsAtTime, parseSOAP } from './trackstar';

const SERENITY_SOAP_URL = import.meta.env.VITE_SERENITY_SOAP_URL;

const getHeaders = (endpoint: string): Headers => {
  const headers = new Headers();
  headers.append('SOAPAction', `http://serenity.tracplus.com/services/${endpoint}`);
  headers.append('Content-Type', 'text/xml');
  return headers;
};

export const escapeXml = (unsafe: string): string => unsafe.replace(/[<>&'"]/g, (c: string): string => {
  switch (c) {
    case '<':
      return '&lt;';
    case '>':
      return '&gt;';
    case '&':
      return '&amp;';
    case '\'':
      return '&apos;';
    case '"':
      return '&quot;';
    default:
      return c;
  }
});

// eslint-disable-next-line @typescript-eslint/ban-types
export const objToXml = (obj?: object): string => {
  if (!obj) return '';
  let out = '';
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      Object.entries(value).forEach(([_, value2]) => {
        // eslint-disable-next-line @typescript-eslint/ban-types
        const tmp: Record<string, object> = {};
        tmp[key] = value2;
        out += objToXml(tmp);
      });
    } else {
      out += `<ser:${key}>`;
      if (typeof (value) === 'object') {
        out += objToXml(value);
      } else if (value && typeof (value) === 'string') {
        out += escapeXml(value);
      } else {
        out += value;
      }
      out += `</ser:${key}>`;
    }
  });
  return out;
};

const getXMLBody = async (endpoint: string, extraXMLOptions?: any): Promise<string> => (
  `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://serenity.tracplus.com/services/">
     <soapenv:Header/>
     <soapenv:Body>
        <ser:${endpoint}>
           <ser:userId>${localStorage.getItem('organisationId')}</ser:userId>
           <ser:user>${localStorage.getItem('organisationId')}</ser:user>
           <ser:password>${await getRefreshedToken()}</ser:password>
           ${objToXml(extraXMLOptions)}
        </ser:${endpoint}>
     </soapenv:Body>
  </soapenv:Envelope>`
);

/**
 * Wraps a fetch and parsing call to serenity.
 *
 * @param endpoint The SOAP endpoint to call on /serenity.asmx
 * @param extraXMLOptions Extra xml options to provide aside from usercode/password
 * @returns parsed SOAP result, 4 levels down from root
 */
const fetchSerenity = async (endpoint: string, extraXMLOptions?: any): Promise<any> => {
  const response = await fetch(`${SERENITY_SOAP_URL}?${endpoint}`, {
    method: 'POST',
    headers: getHeaders(endpoint),
    body: await getXMLBody(endpoint, extraXMLOptions),
    redirect: 'follow',
    mode: 'cors',
  });
  if (!response.ok) throw new Error(`Failed to request serenity/${endpoint}.`);
  return parseSOAP(await response.text());
};

// eslint-disable-next-line @typescript-eslint/ban-types
const fetchSerenityLegacy = async (endpoint: string, usercode: string, password: string, extraXMLOptions?: object): Promise<Result> => {
  const response = await fetch(`${SERENITY_SOAP_URL}?${endpoint}`, {
    method: 'POST',
    headers: getHeaders(endpoint),
    body: `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://serenity.tracplus.com/services/">
       <soapenv:Header/>
       <soapenv:Body>
          <ser:${endpoint}>
             <ser:userId>${usercode}</ser:userId>
             <ser:user>${usercode}</ser:user>
             <ser:password>${password}</ser:password>
             ${objToXml(extraXMLOptions)}
          </ser:${endpoint}>
       </soapenv:Body>
    </soapenv:Envelope>`,
    redirect: 'follow',
    mode: 'cors',
  });
  if (!response.ok) throw new Error(`Failed to request serenity/${endpoint}.`);
  return parseSOAP(await response.text());
};

export const inviteUserLegacy = async (email: string, userName: string, organisationName: string): Promise<Result> => {
  // TODO role is hardcoded to the reference for the view-only role. If this role id changes somehow, this will break.
  const extraXMLOptions = {
    email, organisationName, userRole: 'roles/9O19dlyMbhMh8gg9XCXb', userName
  };
  const usercode = localStorage.getItem('legacyUser') ?? '';
  const password = localStorage.getItem('legacyPw') ?? '';
  return fetchSerenityLegacy('inviteUser', usercode, password, extraXMLOptions);
};

export const authenticateLegacy = async (usercode: string, password: string): Promise<Result> => (
  fetchSerenityLegacy('authenticateLegacy', usercode, password));

// #-------------------------------#
// # CONTACTS & EMERGENCY CONTACTS #
// #-------------------------------#
/**
 * Take list of all contacts and list of phone/sms/email contacts and return list of all contacts with flags indicating
 * which ICE modes they are enabled for and with priority attached.
 *
 * @return simplified example: { name: "Nic", contact: "nic@gmail.com", iceEmail: true, priority: "0", ...etc })
 */
const mapIceToContacts = (contacts: Contact[], iceContacts: any) => contacts.map((c: Contact) => {
  const priority = {
    email: iceContacts.emailContacts ? iceContacts.emailContacts.contactDetails.find((ic: Contact) => ic.id === c.id)?.priority.toString() : false,
    sms: iceContacts.smsContacts ? iceContacts.smsContacts.contactDetails.find((ic: Contact) => ic.id === c.id)?.priority.toString() : false,
    phone: iceContacts.voiceContacts ? iceContacts.voiceContacts.contactDetails.find((ic: Contact) => ic.id === c.id)?.priority.toString() : false,
  };
  return {
    ...c,
    iceEmail: !!priority.email,
    iceSms: !!priority.sms,
    icePhone: !!priority.phone,
    priority: priority.phone, // phone priority is the only one that matters
  };
});
export const fetchContacts = async (deviceId?: number): Promise<Contact[]> => {
  const contactsResult = await fetchSerenity('getContacts');
  // make sure contact numbers/emails are always strings
  const contacts = contactsResult.contacts.contactDetails?.map((c: Contact) => (
    { ...c, contact: c.contact.toString() }
  )) || [] as Contact[];
  if (!deviceId) return contacts;
  const extraXMLOptions = { deviceId };
  const iceContactsResult = await fetchSerenity('getDeviceEmergencyContacts', extraXMLOptions);
  return deviceId ? mapIceToContacts(contacts, iceContactsResult) : contacts;
};

interface setDeviceEmergencyContactsProps {
  deviceId: number;
  phoneContacts: Contact[];
  smsContacts: Contact[];
  emailContacts: Contact[];
}

const mapContact = (contact: Contact) => (
  {
    contactId: contact.id,
    priority: contact.priority || 0
  }
);

export const setDeviceEmergencyContacts = async ({
  deviceId, phoneContacts, smsContacts, emailContacts
}: setDeviceEmergencyContactsProps): Promise<Result> => {
  if (!deviceId || !phoneContacts || !smsContacts || !emailContacts) throw new Error(`Failed to set device emergency contacts for device ${deviceId}`);
  const extraXMLOptions = {
    deviceId,
    voiceContacts: { contact: phoneContacts.map(mapContact) },
    smsContacts: { contact: smsContacts.map(mapContact) },
    emailContacts: { contact: emailContacts.map(mapContact) }
  };
  return fetchSerenity('setDeviceEmergencyContacts', extraXMLOptions);
};

export const createContact = async (contact: string, type: string, name: string, language: string): Promise<Result> => {
  const extraXMLOptions = {
    contact,
    type: type.toLowerCase(),
    name,
    language: language.toLowerCase()
  };
  return fetchSerenity('createContact', extraXMLOptions);
};

export const deleteContact = async (contactId: number): Promise<Result> => {
  const extraXMLOptions = { contactId };
  return fetchSerenity('deleteContact', extraXMLOptions);
};

export const saveContact = async (contact: Contact): Promise<Result> => {
  const extraXMLOptions = {
    contactId: contact.id,
    contact: contact.contact,
    name: contact.name,
    language: contact.language.toLowerCase()
  };
  return fetchSerenity('updateContact', extraXMLOptions);
};

export const fetchContactAssets = async (contactId: string): Promise<Result> => {
  const extraXMLOptions = { contactId };
  return fetchSerenity('getContactAssets', extraXMLOptions);
};

// #----------------------#
// # USERS & ORGANISATION #
// #----------------------#

export const saveUsercode = async (usercode: Organisation): Promise<Result> => {
  const extraXMLOptions = { usercode };
  return fetchSerenity('updateUsercode', extraXMLOptions);
};

interface MemberResponse {
  uid: string
  name: string
  email: string
  isStaff: boolean
  roles?: {
    role: Role[]
  }
}

interface PendingMemberResponse extends MemberResponse {
  inviteId: string
}

const responseToMember = (m: MemberResponse): Member => ({
  id: m.uid,
  name: m.name,
  email: m.email,
  isStaff: m.isStaff,
  role: {
    id: m.roles?.role[0].reference || '',
    label: m.roles?.role[0].label || ''
  }
});

const responseToPendingMember = (m: PendingMemberResponse): PendingMember => ({
  name: m.name,
  email: m.email,
  inviteId: m.inviteId
});

export const getMembers = async (): Promise<{ pendingMembers: PendingMember[], members: Member[] }> => {
  const response = await fetchSerenity('getMembers');
  return {
    pendingMembers: response.pendingMembers?.pendingMember?.map(responseToPendingMember) || [],
    members: response.members.member.map(responseToMember)
  };
};

export const staffGetOrganisationList = async (): Promise<Organisation[]> => {
  const response = await fetchSerenity('getAllOrganisations');
  return response.organisations.usercode;
};

/**
 * This is used on login for initial setup.
 * @returns Usercodes with minimal roles (just the admin role)
 */
export const fetchUsercodes = async (): Promise<Organisation[]> => {
  const result = await fetchSerenity('getUsercodes');
  return result?.usercodes.usercode as Organisation[];
};

// #---------------------------#
// # CONVERSATIONS & MESSAGING #
// #---------------------------#
/**
 * List conversations available for the current user.
 *
 * TracStar conversations are held an organisation and a device/terminal.
 * Each conversation includes information on the latest message, intended for use in rendering previews.
 *
 * @returns A list of conversations visible to the current user.
 */
export const fetchConversations = async (): Promise<Conversation[]> => {
  const result = await fetchSerenity('getConversations');
  const conversations = (
    result?.conversations.conversation as TSConversation[])
    ?.map(mapTSConversation) || [];
  // filter out conversations with no latest message timestamp (empty conversations)
  return conversations.filter(c => c.latestMessage);
};

/**
 * List messages between the user and a device.
 *
 * @param deviceId device to find messages with.
 * @param cursor Offset, in messages, to read from. Zero is most recent.
 * @param pageSize Messages to return in one response, starting at {@link cursor}.
 *
 * @returns A list of messages with a device.
 */
export const fetchMessages = async (
  deviceId: number,
  cursor = 0,
  pageSize = 0
): Promise<Message[]> => {
  const extraXMLOptions = { terminalId: deviceId, cursor, pageSize };
  const result = await fetchSerenity('getMessages', extraXMLOptions);
  const messages = (result?.messages.message as TSMessage[])?.map(mapTSMessage) || [];
  // reverse order by timestamp (oldest messages first)
  return messages.sort((a, b) => a.timestamp - b.timestamp);
};

// #------------------#
// # GROUPS & FRIENDS #
// #------------------#
export const fetchGroups = async (): Promise<Group[]> => {
  const result = await fetchSerenity('getGroups');
  const groups = result?.groups.group as Group[];
  const orgId = localStorage.getItem('organisationId') || '';
  const groupsWithJoined = groups.map(g => ({
    ...g,
    joined: g.groupFriends.groupMember.map(gf => gf.id).includes(orgId)
  }));
  return groupsWithJoined || [];
};

export const befriendGroup = async (pubkey: string): Promise<Result> => {
  const extraXMLOptions = { pubkey };
  return fetchSerenity('befriendGroup', extraXMLOptions);
};

export const unfriendGroup = async (pubkey: string): Promise<Result> => {
  const extraXMLOptions = { pubkey };
  return fetchSerenity('unfriendGroup', extraXMLOptions);
};

// #-----------#
// # REPORTING #
// #-----------#
export const fetchMissionReports = async (
  pageSize = 5000,
  cursor = 0
): Promise<MissionReport[]> => {
  const extraXMLOptions = { cursor, pageSize };

  const result = await fetchSerenity('getMissionReports', extraXMLOptions);
  const missionReports: MissionReport[] = result?.missionReports.missionReport || [];
  // reverse order by timestamp (newest messages first)
  return missionReports.sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime());
};

export const fetchEventReportsForDevice = async (
  deviceId: string,
  from: string,
  until: string,
  eventTypes: string,
): Promise<U1Report[]> => {
  const extraXMLOptions = {
    terminalId: deviceId, from, until, eventTypes
  };

  const result = await fetchSerenity('getEventReportsForTerminal', extraXMLOptions);
  const reports: TSReport[] = result?.reports.report || [];
  // reverse order by timestamp (newest messages first)
  return reports.map(mapTrackstarReportToTPCReport).sort((a, b) => moment(a.time).unix() - moment(b.time).unix());
};

// #------------------#
// # ASSETS & DEVICES #
// #------------------#

export const saveAsset = async (asset: Asset): Promise<Asset> => {
  if (!asset.messagingHandle) delete asset.messagingHandle;
  const extraXMLOptions = { asset };
  const result = await fetchSerenity('updateAsset', extraXMLOptions);
  return result.asset as Asset;
};

const fetchAssets = async (): Promise<Asset[]> => {
  const result = await fetchSerenity('getAssets');
  return result.assets.asset?.map(mapAsset) || [] as Asset[];
};
export const fetchDevices = async (): Promise<Device[]> => {
  const result = await fetchSerenity('getDevices');
  const { device } = result.devices;
  return device?.map(mapDevice) || [] as Device[];
};
export const fetchAssetsDevices = async (now?: Moment): Promise<AssetsDevices> => {
  console.log('fetching asset devices');
  const [assets, devices] = await Promise.all([
    fetchAssets(),
    fetchDevices()
  ]);
  const assetsWithDevices = assets.map(asset => {
    const device = devices.find(d => d.assetId === asset.id) || undefined;
    // const latestReport = latestReports?.find(r => r.terminalId === device?.id)?.reports[0];
    return mapAssetAndDeviceToAssetDevice(asset, device);
  });
  // TODO: may need to use this to limit assetDevices to 200 for performance reasons
  // const yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  // return new Date(asset?.latestReport?.received) > yesterday;
  // const assetsByLastActive = assetsWithDevices?.sort((a, b) => moment(b.latestReport?.received).unix() - moment(a.latestReport?.received).unix());
  // return assetsByLastActive.slice(0, 200);
  return {
    assets, devices, assetsWithDevices
  };
};
// TODO: this should reuse the existing assetsDevices react-query and just add share data to it to avoid refetching
export const fetchAssetsDevicesShares = async (organisationId: string): Promise<Asset[]> => {
  const [assets, devices, shares] = await Promise.all([
    fetchAssets(),
    fetchDevices(),
    fetchShares(organisationId),
  ]);
  const assetsWithDevicesWithShares = assets.map(asset => {
    const device = devices.find(d => d.assetId === asset.id) || undefined;
    const share = shares.find(s => s.deviceId.toString() === device?.id.toString());
    return {
      ...mapAssetAndDeviceToAssetDevice(asset, device),
      share,
    };
  });
  return assetsWithDevicesWithShares;
};

export const fetchMessagingWhitelist = async (deviceId?: number): Promise<MessagingWhitelist[]> => {
  if (!deviceId) return [] as MessagingWhitelist[];
  const extraXMLOptions = { deviceId };
  const result = await fetchSerenity('getMessagingWhitelist', extraXMLOptions);
  return result.messagingWhitelist[0].messagingWhitelist;
};

export const removeMessagingWhitelistContact = async (contactId: number, deviceId?: number): Promise<Result> => {
  if (!deviceId) return {} as Result;
  const extraXMLOptions = { deviceId, contactId };
  return fetchSerenity('deleteMessagingWhitelistContact', extraXMLOptions);
};

export const addMessagingWhitelistContact = async (contactId: number, deviceId?: number): Promise<Result> => {
  if (!deviceId) return {} as Result;
  const extraXMLOptions = { deviceId, contactId };
  return fetchSerenity('addMessagingWhitelistContact', extraXMLOptions);
};

// #--------------#
// # AMS SETTINGS #
// #--------------#
export const fetchAmsConfiguration = async (assetId: number): Promise<AssetAmsConfig> => {
  const extraXMLOptions = { assetId };
  try {
    const result = await fetchSerenity('getAmsConfiguration', extraXMLOptions);
    return {
      assetId,
      ofConcernTimeout: result.amsConfig.ofConcernTimeout,
      overdueTimeout: result.amsConfig.overdueTimeout,
      stationaryCount: result.amsConfig.stationaryCount || 0,
      assetOverride: true,
    };
  } catch {
    return {
      assetId,
      ofConcernTimeout: 10,
      overdueTimeout: 10,
      stationaryCount: 0,
      assetOverride: false,
    };
  }
};

export const setAmsConfiguration = async ({
  assetId, ofConcernTimeout, overdueTimeout, stationaryCount
}: AssetAmsConfig): Promise<Result> => {
  const extraXMLOptions = {
    assetId, ofConcernTimeout, overdueTimeout, stationaryCount: stationaryCount || 0
  };
  return fetchSerenity('setAmsConfiguration', extraXMLOptions);
};

export const unsetAmsConfiguration = async (assetId: number): Promise<Result> => {
  const extraXMLOptions = { assetId };
  return fetchSerenity('unsetAmsConfiguration', extraXMLOptions);
};
