import React from 'react';
import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Container, Box, Button, Grid, Tooltip, Typography
} from '@mui/material';
import {
  PersonAdd, PersonAddDisabled, Add, ChevronLeft
} from '@mui/icons-material';
import useTranslation from 'hooks/useTranslation';
import insensitiveSort from 'utils/insensitiveSort';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import tableIcons from 'components/shared/icons/tableIcons';
import TableActions from 'components/shared/materialTable/tableActions';
import { useQuery } from 'react-query';
import { staffGetOrganisationList } from 'apis/trackstar/serenity';
import DetailPanel from './detailPanel';
import useStyles from './organisationsList-styles';
// import CreateDialog from './createDialog';
import Page from '../../page/page-view';

const OrganisationsListPage = ({
  navigate,
  organisationId,
  user,
  setOrganisation,
  joinOrganisation,
  leaveOrganisation,
  // createOrganisation,
  organisationsJoined,
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.organisationsList');
  // const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const getAllOrganisations = useQuery(['allOrganisations'], staffGetOrganisationList);

  const editOrganisation = rowData => {
    const { id } = rowData;
    if (id) {
      setOrganisation(id);
      navigate('/settings/organisation');
    } else {
      navigate('/missing-organisation');
    }
  };

  const handleRowClick = (e, data) => {
    const isMember = !!organisationsJoined.find(org => org.id === data.id);
    if (isMember) editOrganisation(data);
  };

  // const handleCreateClose = orgDetails => {
  //   if (orgDetails) {
  //     const primaryContact = {
  //       name: orgDetails.contactName,
  //       phone: orgDetails.phone,
  //       email: orgDetails.email,
  //       address: orgDetails.address,
  //       city: orgDetails.city,
  //       state: orgDetails.state,
  //       postcode: orgDetails.postcode,
  //       country: orgDetails.country
  //     };
  //     createOrganisation({
  //       name: orgDetails.organisationName,
  //       type: 'Public',
  //       access: orgDetails.accessLevel,
  //       description: orgDetails.organisationDescription,
  //       contactDetails: {
  //         PRIMARY: primaryContact,
  //         BILLING: primaryContact
  //       }
  //     });
  //   }
  //   setCreateDialogOpen(false);
  // };

  return (
    <Page>
      <Grid container className={classes.pageWrapper}>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <Link to="/staff" className={classes.link}>
              <Typography className={classes.linkText} variant="body1" gutterBottom>
                <ChevronLeft />
                Support Tools
              </Typography>
            </Link>
            <Box>
              <Typography variant="h1" gutterBottom>{t('title')}</Typography>
              <Typography variant="body1" paragraph>{t('description')}</Typography>
            </Box>
            <Container className={classes.tableContainer} maxWidth="md">
              <Box className={classes.materialTable}>
                <MaterialTable
                  isLoading={getAllOrganisations.isLoading}
                  title={t('title')}
                  icons={tableIcons}
                  onRowClick={(e, rowData) => handleRowClick(e, rowData)}
                  columns={[
                    {
                      title: t('organisationName'),
                      field: 'name',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      defaultSort: 'asc',
                      customSort: (a, b) => insensitiveSort(a.name, b.name)
                    },
                    {
                      title: t('usercode'),
                      field: 'id',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left', fontFamily: 'monospace' },
                      defaultSort: 'asc',
                      customSort: (a, b) => insensitiveSort(a.id, b.id)
                    },
                    {
                      title: t('enabled'),
                      field: 'enabled',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      defaultSort: 'asc',
                    },
                    {
                      title: t('primaryContactName'),
                      field: 'contactName',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      customSort: (a, b) => insensitiveSort(a.contactName, b.contactName)
                    },
                    {
                      title: t('userCount'),
                      field: 'userCount',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      render: rowData => <Tooltip title={t('userCountTooltip')}><span>{rowData.userCount}</span></Tooltip>
                    }
                  ]}
                  data={getAllOrganisations.isSuccess ? getAllOrganisations.data : []}
                  actions={[
                    rowData => {
                      const isMember = !!user.memberOf?.find(org => org.id === rowData.id);
                      const invalidOrg = rowData.id === organisationId || !rowData.enabled;

                      let tooltip = t('join');
                      if (invalidOrg) {
                        tooltip = undefined;
                      } else if (isMember) {
                        tooltip = rowData.id !== organisationId && t('leave');
                      }

                      return {
                        icon: () => {
                          if (invalidOrg) return null;
                          return (
                            isMember
                              ? <PersonAddDisabled className={clsx(classes.actionButtonColor, { [classes.disabledActionButtonColor]: invalidOrg })} />
                              : <PersonAdd className={clsx(classes.actionButtonColor, { [classes.disabledActionButtonColor]: invalidOrg })} />
                          );
                        },
                        tooltip,
                        onClick: invalidOrg ? undefined : (e, row) => (isMember ? leaveOrganisation(row) : joinOrganisation(row, user)),
                        disabled: invalidOrg
                      };
                    },
                  // TODO: Disabling org creation until we turn sync off (because it's not compatible with Legacy sync)
                  //   {
                  //     icon: () => <Add className={classes.newButton} />,
                  //     tooltip: t('createOrg'),
                  //     isFreeAction: true,
                  //     onClick: () => setCreateDialogOpen(true)
                  //   }
                  ]}
                  options={{
                    draggable: false,
                    showTitle: false,
                    search: true,
                    actionsColumnIndex: -1,
                    searchFieldStyle: {
                      borderRadius: '4px',
                      paddingLeft: '18px',
                      paddingRight: '10px'
                    },
                    searchFieldVariant: 'outlined',
                    thirdSortClick: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 100],
                    emptyRowsWhenPaging: false,
                    headerStyle: { position: 'sticky', top: 0 },
                    maxBodyHeight: '550px',
                  }}
                  localization={{
                    header: {
                      actions: t('actions')
                    },
                    toolbar: {
                      searchPlaceholder: t('search')
                    },
                    pagination: {
                      labelRows: t('rows'),
                      labelDisplayedRows: ` {from}-{to} ${t('of')} {count}`,
                      firstTooltip: t('firstPage'),
                      previousTooltip: t('previousPage'),
                      nextTooltip: t('nextPage'),
                      lastTooltip: t('lastPage')
                    }
                  }}
                  components={{
                    Actions: TableActions,
                    // eslint-disable-next-line react/destructuring-assignment
                    Action: props => {
                      const { action, data } = props;
                      if (action.position === 'toolbar') {
                        return (
                          <Button
                            onClick={event => action.onClick(event, data)}
                            className={classes.newButton}
                            variant="contained"
                          >
                            <Add />
                            {t('createOrg')}
                          </Button>
                        );
                      }
                      return (<MTableAction {...props} />);
                    }
                  }}
                  detailPanel={[{
                    tooltip: t('ShowOrgContactDetails'),
                    render: row => <DetailPanel rowData={row.rowData} />
                  }]}
                />
              </Box>
              {// TODO: Disabling org creation until we turn sync off (because it's not compatible with Legacy sync)
                // <CreateDialog
                //   open={createDialogOpen}
                //   onClose={handleCreateClose}
                //   classes={classes}
                // />
              }
            </Container>
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default OrganisationsListPage;
