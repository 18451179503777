import { useCallback, useEffect, useState } from 'react';
import { filter } from 'rxjs';
import reports, { EventType, ReportEvent } from './index';

const isNewReportEvent = (event: ReportEvent): boolean => [EventType.NEW_ASSET, EventType.NEW_REPORT, EventType.NEW_REPORTS, EventType.SET_ALL].includes(event.type);

const eventRelatesToAsset = (event: ReportEvent, assetId: number): boolean => {
  if (event.type === EventType.SET_ALL) { return true; }
  if (event.type === EventType.NEW_ASSET || event.type === EventType.NEW_REPORT) {
    return event.assetId === assetId;
  }
  if (event.type === EventType.NEW_REPORTS) {
    return event.assetIds?.includes(assetId) || false;
  }
  return false;
};

export const useAssetReports = (assetId: number): Report[] => {
  const [assetReports, setAssetReports] = useState<Report[]>([]);

  useEffect(() => {
    setAssetReports(reports.getReportsForAsset(assetId, false));

    const subscription = reports.subject
      .pipe(filter<ReportEvent>(e => eventRelatesToAsset(e, assetId)))
      .subscribe(() => setAssetReports(reports.getReportsForAsset(assetId, false)));

    return () => subscription.unsubscribe();
  }, [assetId]);

  return assetReports;
};

export const useAssetPositions = (assetId: number): Report[] => {
  const [assetPositions, setAssetPositions] = useState<Report[]>([]);

  useEffect(() => {
    setAssetPositions(reports.getReportsForAsset(assetId, true));

    const subscription = reports.subject
      .pipe(filter<ReportEvent>(e => eventRelatesToAsset(e, assetId)))
      .subscribe(() => setAssetPositions(reports.getReportsForAsset(assetId, true)));

    return () => subscription.unsubscribe();
  }, [assetId]);

  return assetPositions;
};

export const useAssetsPositions = (assets: {id: number}[]): Record<number, Report[]> => {
  const [assetsPositions, setAssetsPositions] = useState<Record<number, Report[]>>({});

  useEffect(() => {
    setAssetsPositions(reports.getReportsForAssets(assets.map(a => a.id), true));

    const subscription = reports.subject
      .pipe(filter(isNewReportEvent), filter<ReportEvent>(e => assets.some(a => eventRelatesToAsset(e, a.id))))
      .subscribe(() => setAssetsPositions(reports.getReportsForAssets(assets.map(a => a.id), true)));

    return () => subscription.unsubscribe();
  }, [assets]);

  return assetsPositions;
};

export const useAssetSpline = (assetId: number): [number, number][] => {
  const [assetSpline, setAssetSpline] = useState<[number, number][]>([]);

  useEffect(() => {
    setAssetSpline(reports.getSplineForAsset(assetId));

    const subscription = reports.subject
      .pipe(filter<ReportEvent>(e => eventRelatesToAsset(e, assetId)))
      .subscribe(() => setAssetSpline(reports.getSplineForAsset(assetId)));

    return () => subscription.unsubscribe();
  }, [assetId]);

  return assetSpline;
};

export const useAssetSplines = (assets: {id: number}[]): Record<number, [number, number][]> => {
  const [assetSplines, setAssetSplines] = useState<Record<number, [number, number][]>>({});

  useEffect(() => {
    setAssetSplines(reports.getSplinesForAssets(assets.map(a => a.id)));

    const subscription = reports.subject
      .pipe(filter(isNewReportEvent), filter<ReportEvent>(e => assets.some(a => eventRelatesToAsset(e, a.id))))
      .subscribe(() => setAssetSplines(reports.getSplinesForAssets(assets.map(a => a.id))));

    return () => subscription.unsubscribe();
  }, [assets]);

  return assetSplines || {};
};

export const useLatestPosition = (assetId: number): Report | undefined => {
  const [latestPosition, setLatestPosition] = useState<Report>();

  useEffect(() => {
    setLatestPosition(reports.getLatestPosition(assetId));

    const subscription = reports.subject
      .pipe(filter<ReportEvent>(e => eventRelatesToAsset(e, assetId)))
      .subscribe(() => setLatestPosition(reports.getLatestPosition(assetId)));

    return () => subscription.unsubscribe();
  }, [assetId]);

  return latestPosition;
};

export const useClosestReport = ({ latitude, longitude }:{latitude: number, longitude: number}, assetIds: number[]): Report | undefined => {
  const [closestReport, setClosestReport] = useState<Report>();

  useEffect(() => {
    if (!latitude || !longitude) { setClosestReport(undefined); }
    setClosestReport(reports.getClosestReport(latitude, longitude, assetIds));
  }, [assetIds, latitude, longitude]);

  return closestReport;
};

interface LatestPositionsForAssets {
  [assetId: number]: Report | undefined
}

export const useLatestPositionsForAssets = (assets: {id: number}[]): LatestPositionsForAssets => {
  const [latestPositions, setLatestPositions] = useState<LatestPositionsForAssets>();

  const updateAllLatestPositions = useCallback(() => {
    // @ts-ignore
    setLatestPositions(assets.reduce((prev: LatestPositionsForAssets, asset) => {
      prev[asset.id] = reports.getLatestPosition(asset.id);
      return prev;
    }, {}));
  }, [assets]);

  useEffect(() => {
    updateAllLatestPositions();

    const subscription = reports.subject
      .pipe(filter(isNewReportEvent), filter<ReportEvent>(e => assets.some(a => eventRelatesToAsset(e, a.id))))
      .subscribe(() => updateAllLatestPositions());

    return () => subscription.unsubscribe();
  }, [assets, updateAllLatestPositions]);

  return latestPositions || {};
};

export const useHaveReportData = (): boolean => {
  const [hasData, setHasData] = useState(false);

  const subscription = useCallback(() => reports.subject.subscribe(event => {
    if (event.type === EventType.CLEAR) setHasData(false);
    else if (!hasData) setHasData(true); // avoid unnecessary rerenders
  }), [hasData])();

  if (hasData) {
    subscription?.unsubscribe();
  }

  return hasData;
};
