import React, { useState } from 'react';
import clsx from 'clsx';
import { Box, Grid, Tooltip } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { sortBy } from 'lodash';

import useTranslation from 'hooks/useTranslation';
import { GRIDSIZES, MAPLAYOUTS } from 'constants/maplayouts';
import Map from 'components/map';
import mapTemplates from 'mapTemplates';
import mixpanel from 'mixpanel-browser';

import useStyles from './mapContainer-styles';
import QueryReportsDummy from './queryReportsDummy';

const MapContainer = ({
  maps,
  language,
  assets,
  updateMapLayout,
  mapLayout,
  selectMap,
  selectedMapId,
  selectItem,
  selectedAssets,
  now,
  defaultMapSettings,
  highContrastControls,
  performanceMode,
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.map.mapLayoutSpeeddial');
  const deviceIds = assets?.filter(a => a.deviceId).map(a => a.deviceId);

  // Handle SpeedDial open and close
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const setMapLayout = value => {
    if (value) {
      mixpanel.track('Split Map', {
        mapSplitSetting: value
      });
      updateMapLayout(value, defaultMapSettings);
    }
    setOpen(true);
  };

  // the map select is tied to mousedown, which appears to be a lot faster than onclick
  const handleMouseDown = mapId => {
    if (mapId === selectedMapId) return;
    selectMap(mapId);
    // selecting a map should also reselect the previously selected asset in the results view,
    const previousSelection = selectedAssets[mapId];
    if (previousSelection) selectItem(previousSelection);
  };

  const gridTitles = [
    t('singleMap'),
    t('twoMapsHorizontal'),
    t('twoMapsVertical'),
    t('threeMapsA'),
    t('threeMapsB'),
    t('fourMaps')
  ];
  const gridSVGIcons = [
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <rect width="16" height="16" rx="1" ry="1" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M0,6V1c0-0.55,0.45-1,1-1h14c0.55,0,1,0.45,1,1v5c0,0.55-0.45,1-1,1H1C0.45,7,0,6.55,0,6z" />
      <path d="M0,15v-5c0-0.55,0.45-1,1-1h14c0.55,0,1,0.45,1,1v5c0,0.55-0.45,1-1,1H1C0.45,16,0,15.55,0,15z" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M6,16H1c-0.55,0-1-0.45-1-1V1c0-0.55,0.45-1,1-1h5c0.55,0,1,0.45,1,1v14C7,15.55,6.55,16,6,16z" />
      <path d="M15,16h-5c-0.55,0-1-0.45-1-1V1c0-0.55,0.45-1,1-1h5c0.55,0,1,0.45,1,1v14C16,15.55,15.55,16,15,16z" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M6,0H1C0.4,0,0,0.4,0,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C7,0.4,6.6,0,6,0z" />
      <path d="M15,0h-5C9.4,0,9,0.4,9,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z" />
      <path d="M0,15v-5c0-0.55,0.45-1,1-1h14c0.55,0,1,0.45,1,1v5c0,0.55-0.45,1-1,1H1C0.45,16,0,15.55,0,15z" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M6,9H1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C7,9.4,6.6,9,6,9z" />
      <path d="M15,9h-5c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C16,9.4,15.6,9,15,9z" />
      <path d="M0,6V1c0-0.55,0.45-1,1-1h14c0.55,0,1,0.45,1,1v5c0,0.55-0.45,1-1,1H1C0.45,7,0,6.55,0,6z" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M6,0H1C0.4,0,0,0.4,0,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C7,0.4,6.6,0,6,0z" />
      <path d="M15,0h-5C9.4,0,9,0.4,9,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z" />
      <path d="M6,9H1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C7,9.4,6.6,9,6,9z" />
      <path d="M15,9h-5c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C16,9.4,15.6,9,15,9z" />
    </svg>
  ];

  let highContrastClass = null;
  if (highContrastControls) {
    highContrastClass = 'highContrastClass';
  } else {
    highContrastClass = null;
  }

  return (
    <Box className={classes.wrapper}>
      {deviceIds?.length > 0 && <QueryReportsDummy deviceIds={deviceIds} now={now} />}
      <Grid container spacing={0}>
        {sortBy(Object.values(maps), x => x.sortOrder).map((config, index) => {
          const selected = (config.id === selectedMapId);
          // if performanceMode is on and allTrailsIcons (4) is selected for this map, force it to selectedTrailsIcons (3) (for performance reasons)
          const performanceConfig = (performanceMode && config.trailsOption === 4) ? { ...config, trailsOption: 3 } : config;

          if (GRIDSIZES[mapLayout][index] > 0) {
            return (
              <Map
                key={`map${performanceConfig.id}`}
                index={index}
                assets={assets}
                xs={GRIDSIZES[mapLayout][index]}
                template={mapTemplates[performanceConfig.baseLayerId](language, now).template}
                selected={selected}
                config={performanceConfig}
                onMouseDown={() => handleMouseDown(performanceConfig.id)}
                selectedItemForThisMap={selectedAssets[performanceConfig.id]}
              />
            );
          }
          return null;
        })}
      </Grid>
      <SpeedDial
        ariaLabel={t('title')}
        className={clsx(classes.speedDial, { highContrastClass })}
        hidden={false}
        icon={(
          <Tooltip title={t('title')} placement="left">
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
              <path d="M6,0H1C0.4,0,0,0.4,0,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C7,0.4,6.6,0,6,0z" />
              <path d="M15,0h-5C9.4,0,9,0.4,9,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z" />
              <path d="M6,9H1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C7,9.4,6.6,9,6,9z" />
              <path d="M15,9h-5c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C16,9.4,15.6,9,15,9z" />
            </svg>
          </Tooltip>
        )}
        open={open}
        direction="up"
      >
        {MAPLAYOUTS.map((layout, index) => (
          <SpeedDialAction
            className={clsx(classes.speedDialAction, { highContrastClass, speedDialActionActive: layout === mapLayout })}
            key={`layoutbutton${layout}`}
            icon={gridSVGIcons[index]}
            tooltipTitle={gridTitles[index]}
            onClick={() => setMapLayout(layout)}
          />
        ))}
      </SpeedDial>
      <button className={clsx(classes.mapLayoutButtonWrapper, { highContrastClass })} onClick={toggleOpen} type="button">
        <Tooltip title={t('title')} placement="left">
          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
            <path d="M6,0H1C0.4,0,0,0.4,0,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C7,0.4,6.6,0,6,0z" />
            <path d="M15,0h-5C9.4,0,9,0.4,9,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z" />
            <path d="M6,9H1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C7,9.4,6.6,9,6,9z" />
            <path d="M15,9h-5c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C16,9.4,15.6,9,15,9z" />
          </svg>
        </Tooltip>
      </button>
    </Box>
  );
};

export default MapContainer;
