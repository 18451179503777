import Smart from 'hocs/smart';
import MessagingView from './participantNameListItem-view';

export default Smart(
  MessagingView,
  (state: any) => ({
    defaultSerialType: state.settings.ui.serialType,
  }),
  (ac: any) => ({
  })
);
