import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import ButtonIcon from '../buttonIcon';
import useStyles from './group-styles';

const CreateAssetGroup = ({
  name,
  isCurrent,
  onSelectGroup,
  t
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={3}>
      <Box
        onClick={() => onSelectGroup(name)}
        className={clsx(classes.groupButton, { [classes.isCurrent]: isCurrent })}
      >
        <Box className={classes.button}>
          <Box className={classes.iconWrapper}>
            <ButtonIcon name={name} />
          </Box>
          <Typography className={classes.buttonText}>{t(name.toLowerCase())}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default CreateAssetGroup;
