import React from 'react';
import {
  Box, Grid, Container, Typography, Tabs, Tab
} from '@mui/material';
import Page from 'components/pages/page';
import useTranslation from 'hooks/useTranslation';
import { ClassNameMap } from '@mui/styles';
import { Flight as TripReportsIcon, Timeline as MissionReportsIcon } from '@mui/icons-material';
import TabPanel from 'components/shared/tabPanel';
import useStyles from './reporting-styles';
import MissionReports from './missionReports';
import TripReports from './tripReports';

const ReportingPage = () => {
  const classes: ClassNameMap = useStyles();
  const t = useTranslation('pages.reporting');
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, tab: any): void => {
    setCurrentTab(tab);
  };

  return (
    <Page>
      <Grid container className={classes.pageWrapper}>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <Box>
              <Typography variant="h1" gutterBottom>{t('title')}</Typography>
              <Typography variant="body1" paragraph>{t('description')}</Typography>
            </Box>
            <Tabs
              className={classes.Tabs}
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              onChange={handleChange}
              aria-label="PDF reports tabs"
            >
              <Tab icon={<MissionReportsIcon className={classes.tabIcon} />} label={t('missionReports')} />
              <Tab icon={<TripReportsIcon className={classes.tabIcon} />} label={t('tripReports')} />
            </Tabs>
            <TabPanel currentTab={currentTab} index={0}><MissionReports /></TabPanel>
            <TabPanel currentTab={currentTab} index={1}><TripReports /></TabPanel>
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ReportingPage;
