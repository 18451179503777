export const SUGGESTED_ASSET_COLORS = [
  '#000000',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#9b1477',
  '#eb3bff',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548'
];

export const SUGGESTED_DARK_MODE_COLORS = [
  '#ffffff',
  '#ffff00',
  '#f8bbd0',
  '#e1bee7',
  '#d1c4e9',
  '#c5cae9',
  '#bbdefb',
  '#b3e5fc',
  '#b2ebf2',
  '#b2dfdb',
  '#c8e6c9',
  '#dcedc8',
  '#f0f4c3',
  '#fff9c4',
  '#ffecb3',
  '#ffe0b2'
];

export const SUGGESTED_LIGHT_MODE_COLORS = [
  '#000000',
  '#00ff00',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107'
];
