import Lottie from 'react-lottie-player';
import * as animationData from './loader.json';

interface LoadingIconProps {
  size: number
}

export const LoadingIcon = ({ size = 100 }: LoadingIconProps): JSX.Element => (
  <Lottie
    loop
    animationData={animationData}
    play
    style={{ width: size, height: size, margin: 'auto' }}
    rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
  />
);
