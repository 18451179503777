import { appId, appCode, getHereLangCode } from 'apis/here';

const ReactMapGlHereTerrainTemplate = lang => {
  const langCode = getHereLangCode(lang);

  return {
    version: 8,
    name: 'HERE - Terrain',
    provider: 'HERE',
    sources: {
      'here-terrain-base': {
        type: 'raster',
        tiles: [
          `https://1.aerial.maps.api.here.com/maptile/2.1/basetile/newest/terrain.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}`,
          `https://2.aerial.maps.api.here.com/maptile/2.1/basetile/newest/terrain.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}`,
          `https://3.aerial.maps.api.here.com/maptile/2.1/basetile/newest/terrain.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}`,
          `https://4.aerial.maps.api.here.com/maptile/2.1/basetile/newest/terrain.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}`
        ],
        minZoom: 1,
        maxZoom: 19,
        tileSize: 512
      },
      'here-terrain-labels': {
        type: 'raster',
        tiles: [
          `https://1.aerial.maps.api.here.com/maptile/2.1/labeltile/newest/terrain.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}&lg=${langCode}`,
          `https://1.aerial.maps.api.here.com/maptile/2.1/labeltile/newest/terrain.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}&lg=${langCode}`,
          `https://1.aerial.maps.api.here.com/maptile/2.1/labeltile/newest/terrain.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}&lg=${langCode}`,
          `https://1.aerial.maps.api.here.com/maptile/2.1/labeltile/newest/terrain.day/{z}/{x}/{y}/512/png8?app_id=${appId}&app_code=${appCode}&lg=${langCode}`
        ],
        minZoom: 1,
        maxZoom: 19,
        tileSize: 512
      }
    },
    layers: [{
      id: 'here-terrain-base',
      type: 'raster',
      source: 'here-terrain-base'
    }, {
      id: 'here-terrain-labels',
      type: 'raster',
      source: 'here-terrain-labels'
    }],
    thumbnailUrl: '/img/base-layer-thumbnails/terrain-map-thumbnail.jpg'
  };
};

export default ReactMapGlHereTerrainTemplate;
