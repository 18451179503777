import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import StatusIcon from './statusIcon-view';
import useTranslation from 'hooks/useTranslation';

import useStyles from './systemStatus-styles';

const SystemStatus = ({
  onClick,
  statusColour
}) => {
  const classes = useStyles();
  const t = useTranslation('statusbar.systemStatus');

  return (
    <React.Fragment>
    <Tooltip title={t("tooltip")} placement="top">
      <IconButton onClick={onClick} className={classes.statusIcon} size="large">
        <StatusIcon status={statusColour} />
      </IconButton>
    </Tooltip>
    </React.Fragment>
  );
};

export default SystemStatus;
