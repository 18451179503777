import React, { useState } from 'react';
import {
  Box, Button,
  Container,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
  Grid, Tab, Tabs, TextField,
  Typography
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import {
  fetchFriends, setPermissions
} from 'apis/trackstar/trackstar';
import { fetchShares } from 'apis/rest/shares';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import TabPanel from 'components/shared/tabPanel';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { fetchAssetsDevices } from 'apis/trackstar/serenity';
import mixpanel from 'mixpanel-browser';
import useStyles from './simpleSharing-styles';
import SimpleShareTable from './simpleShareTable';
import Page from '../page';
import PermissionsCheckboxes from './simpleSharingPermissionsCheckboxes-view';

const SimpleSharingPage = ({
  organisationId,
  userPermissions,
  displaySnackbar,
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.simpleSharing');
  const queryClient = useQueryClient();

  const [sharedByMe, setSharedByMe] = useState([]);
  const [sharedToMe, setSharedToMe] = useState([]);
  const [friends, setFriends] = useState([]);
  const [assets, setAssets] = useState([]);
  const [selectedShare, setSelectedShare] = useState({});
  const [selectedPermissions, setSelectedPermissions] = useState(selectedShare?.access || '');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedFriend, setSelectedFriend] = useState(null);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  //
  // Async
  //
  const getFriends = useQuery(
    ['friends'],
    fetchFriends,
    { onSuccess: data => setFriends(data) }
  );
  if (getFriends.isError) displaySnackbar({ id: 'getFriendsFailedSnackbar', text: t('getFriendsFailed'), type: 'error' });

  const getShares = useQuery(
    ['shares'],
    () => fetchShares(organisationId),
    {
      onSuccess: shares => {
        // NOTE: this filters out self (owner) shares. If we want to display these remove the second condition
        setSharedByMe(shares.filter(s => s.sharerId === organisationId && s.shareeId !== organisationId));
        setSharedToMe(shares.filter(s => s.sharerId !== organisationId));
      }
    }
  );
  if (getShares.isError) displaySnackbar({ id: 'getSharesFailedSnackbar', text: t('getSharesFailed'), type: 'error' });

  const getAssets = useQuery(
    ['assets', localStorage.getItem('organisationId'), null],
    () => fetchAssetsDevices(),
    {
      select: data => data.assetsWithDevices,
      onSuccess: data => setAssets(data.filter(asset => asset.ownerId.toLowerCase() === organisationId.toLowerCase()).filter(asset => asset.deviceId))
    }
  );
  if (getAssets.isError) displaySnackbar({ id: 'getAssetsFailedSnackbar', text: t('getAssetsFailed'), type: 'error' });

  const mutateShare = useMutation(share => setPermissions(share.deviceId, share.pubKey, share.accessCode), {
    onMutate: async share => {
      // Optimistic update
      await queryClient.cancelQueries('shares');
      const previousShares = queryClient.getQueryData('shares');
      // Delete
      if (deleteDialogOpen) {
        queryClient.setQueryData('shares', old => old.filter(s => (
          !(s.deviceId === share.deviceId && s.sharee === share.sharee))));
      }
      // Edit
      if (editDialogOpen) {
        queryClient.setQueryData('shares', old => old.map(s => (
          s.deviceId === share.deviceId && s.sharee === share.sharee ? { ...s, access: share.accessCode } : s)));
      }
      return { previousShares };
    },
    onError: (err, newShare, context) => {
      // Roll back optimistic update if setPermissions fails
      queryClient.setQueryData('shares', context.previousShares);
      displaySnackbar({ id: 'editShareFailedSnackbar', text: t('editShareFailed'), type: 'error' });
      mixpanel.track('Update Share', { success: false, share: { ...newShare, sharer: organisationId } });
    },
    onSuccess: (res, share) => {
      displaySnackbar({ id: 'editShareSuccessSnackbar', text: t('editShareSuccess'), type: 'success' });
      mixpanel.track('Update Share', { success: true, share: { ...share, sharer: organisationId } });
    },
    onSettled: () => {
      // Fetch latest shares on error or success
      queryClient.invalidateQueries('shares');
    },
    mutationKey: 'editShare',
  });
  const clearState = () => {
    setSelectedShare({});
    setSelectedPermissions('');
    setSelectedFriend({});
    setSelectedAsset({});
  };

  //
  // Edit
  //
  const handleEditSave = () => {
    if (selectedShare) {
      const updatedShare = {
        deviceId: selectedShare.deviceId,
        pubKey: selectedShare.shareePublicKey,
        accessCode: selectedPermissions,
        sharee: selectedShare.sharee
      };
      mutateShare.mutate(updatedShare);
    }
    setEditDialogOpen(false);
    clearState();
  };
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    clearState();
  };
  const handleAccessLevelChange = permission => {
    const updatedAccess = selectedPermissions.includes(permission)
      ? selectedPermissions.replace(permission, '')
      : selectedPermissions + permission;
    setSelectedPermissions(updatedAccess);
  };
  const editShare = share => {
    setSelectedShare(share);
    setSelectedPermissions(share.access);
    setEditDialogOpen(true);
  };

  //
  // Delete
  //
  const handleDeleteConfirm = () => {
    const deletedShare = {
      deviceId: selectedShare.deviceId,
      pubKey: selectedShare.shareePublicKey,
      accessCode: '',
      sharee: selectedShare.sharee
    };
    mutateShare.mutate(deletedShare);
    setDeleteDialogOpen(false);
    clearState();
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    clearState();
  };
  const deleteShare = share => {
    setSelectedShare(share);
    setDeleteDialogOpen(true);
  };

  //
  // Create
  //
  const handleCreateConfirm = () => {
    const shareePubKey = selectedFriend.publicKey;
    const newShare = {
      deviceId: selectedAsset.deviceId,
      pubKey: shareePubKey,
      accessCode: selectedPermissions,
      sharee: selectedFriend.organisation.name,
    };
    mutateShare.mutate(newShare);
    setCreateDialogOpen(false);
    clearState();
  };
  const createShare = () => {
    setCreateDialogOpen(true);
  };
  const handleCreateDialogClose = () => {
    setCreateDialogOpen(false);
    clearState();
  };

  //
  // Tabs
  //
  const { hash } = window.location;
  const [currentTab, setCurrentTab] = React.useState((hash && hash.indexOf('withme') > -1) ? 1 : 0);
  const handleChange = (event, tab) => {
    setCurrentTab(tab);
  };

  const getAssetOptionLabel = option => {
    const makeModel = option.make || option.model ? `(${option?.make} ${option?.model})` : '';
    return option?.name ? `${option?.name} ${makeModel}` : '';
  };

  return (
    <Page>
      <Grid container className={classes.pageWrapper}>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <Box>
              <Typography variant="h1" gutterBottom>{t('title')}</Typography>
              <Typography paragraph>{t('description')}</Typography>
            </Box>

            <Tabs
              className={classes.Tabs}
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              onChange={handleChange}
              aria-label="manage devices and assets tabs"
            >
              <Tab className={classes.byMeTab} label={t('title1')} />
              <Tab className={classes.withMeTab} label={t('title2')} />
            </Tabs>

            <TabPanel currentTab={currentTab} index={0}>
              <SimpleShareTable
                loading={getShares.isLoading}
                shares={sharedByMe}
                mode="sharedByMe"
                allowCreateShare={userPermissions.canEditShare}
                allowEditShare={userPermissions.canEditShare}
                editShare={editShare}
                deleteShare={deleteShare}
                createShare={createShare}
              />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={1}>
              <SimpleShareTable
                loading={getShares.isLoading}
                shares={sharedToMe}
                mode="sharedToMe"
                allowCreateShare={userPermissions.canEditShare}
                allowEditShare={userPermissions.canEditShare}
                editShare={editShare}
                deleteShare={deleteShare}
                createShare={createShare}
              />
            </TabPanel>

            <Dialog open={createDialogOpen} onClose={handleCreateDialogClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">{t('createShareDialogTitle')}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('createShareDialogContent')}
                </DialogContentText>
                <FormControl variant="standard" className={classes.formControl}>
                  <Autocomplete
                    id="dialog-select"
                    options={assets.sort((a, b) => a.name?.localeCompare(b.name))}
                    getOptionLabel={option => getAssetOptionLabel(option)}
                    isOptionEqualToValue={(option, value) => Object.keys(value).length === 0 || value.id === option.id}
                    value={selectedAsset}
                    onChange={(e, newValue) => setSelectedAsset(newValue)}
                    renderInput={params => <TextField variant="standard" {...params} label={t('asset')} fullWidth />}
                    filterOptions={createFilterOptions({
                      stringify: option => option.name
                    })}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  className={classes.formControl}
                  style={{ margin: '10px 0 20px 0' }}>
                  <Autocomplete
                    id="dialog-select2"
                    options={friends.sort((a, b) => a.organisation?.name.localeCompare(b.organisation?.name))}
                    getOptionLabel={option => option.organisation?.name ?? ''}
                    isOptionEqualToValue={(option, value) => Object.keys(value).length === 0 || value.organisation.id === option.organisation.id}
                    value={selectedFriend}
                    onChange={(e, newValue) => setSelectedFriend(newValue)}
                    renderInput={params => <TextField variant="standard" {...params} label={t('shareWith')} fullWidth />}
                    filterOptions={createFilterOptions({
                      stringify: option => option.organisation?.name
                    })}
                  />
                </FormControl>
                <PermissionsCheckboxes classes={classes} selectedPermissions={selectedPermissions} handleAccessLevelChange={handleAccessLevelChange} />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleCreateDialogClose}>
                  {t('cancelButton')}
                </Button>
                <Button variant="contained" onClick={handleCreateConfirm} color="primary" disabled={!selectedPermissions || !selectedAsset || !selectedFriend}>
                  {t('newButton')}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="confirmation-dialog-title">{t('confirmDeleteShareTitle', { assetName: selectedShare.assetName, sharedWith: selectedShare.sharee })}</DialogTitle>
              <DialogContent dividers>{t('confirmDeleteShareMessage', { assetName: selectedShare.assetName, sharedWith: selectedShare.sharee })}</DialogContent>
              <DialogActions>
                <Button autoFocus onClick={() => setDeleteDialogOpen(false)}>
                  {t('cancelButton')}
                </Button>
                <Button variant="contained" onClick={handleDeleteConfirm} color="primary">
                  {t('okButton')}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={editDialogOpen} onClose={handleEditDialogClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">{t('editDialogTitle')}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('editDialogContent')}
                </DialogContentText>
                <PermissionsCheckboxes classes={classes} selectedPermissions={selectedPermissions} handleAccessLevelChange={handleAccessLevelChange} />
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button onClick={handleEditDialogClose}>
                  {t('cancel')}
                </Button>
                <Button variant="contained" onClick={handleEditSave} color="primary">
                  {t('save')}
                </Button>
              </DialogActions>
            </Dialog>

          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default SimpleSharingPage;
