/* eslint-disable no-underscore-dangle */
import React, {
  useMemo, useCallback, useEffect, useRef
} from 'react';
import { Typography, Tooltip } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useTranslation from 'hooks/useTranslation';
import moment from 'utils/moment';
import { useLatestPositionsForAssets } from 'repositories/reports/hooks';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { useTheme } from '@mui/material/styles';
import useStyles from '../results-styles';
import QueryResult from '../queryResult';

// Retrieves the translated name of the bucket for the given AssetState
// Prioritises distress > ams > airborne > engine
// const getBucketNameForState = (as, t) => (as
//   ? t((as.distress || as.ams || as.airborne || as.engine)?.translationKey || 'unknown')
//   : t('unknown')
// );

interface GroupedQueryResultsProps {
  selectedItemId: number | undefined
  results: Asset[]
  now: moment.Moment | null
  hiddenInactiveAssets: number[]
  hiddenAssets: Asset[]
  groupBy: string
  hideAssetsGroup: (group: string) => void
  removeFromHiddenAssetGroups: (group: string) => void
  hideAssetsOnMap: (assets: Asset[]) => void
  showAssetsOnMap: (assets: Asset[]) => void
  hiddenAssetGroups: string[]
  selectedItem: Asset
  clearSelection: () => void
  unassignItemFromMap: () => void
  selectAsset: (asset: Asset) => void
  activeQuery: {name: string}
}

interface BucketHeader {
  type: 'header'
  bucket: string
  visibilityIcon: React.ReactNode
  height: number
}

interface BucketResult {
  type: 'assetResult',
  result: Asset
  isHidden: boolean
  isSelected: boolean
  height: number
}

const GroupedQueryResults = ({
  now,
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // assetState,
  hiddenInactiveAssets,
  hiddenAssetGroups,
  hideAssetsGroup,
  activeQuery,
  removeFromHiddenAssetGroups,
  clearSelection,
  hideAssetsOnMap,
  showAssetsOnMap,
  unassignItemFromMap,
  results,
  groupBy,
  selectedItemId,
  hiddenAssets,
  selectAsset,
}: GroupedQueryResultsProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('omnibox.modules.groupQueryResults');
  const t2 = useTranslation('omnibox.modules.results');
  const listRef = useRef<List | null>();

  const latestPositionsByAsset = useLatestPositionsForAssets(results);

  const getBucketNameForLatestActivity = useCallback(latestActivity => {
    const nowMoment = now ? moment(now) : moment();
    if (latestActivity.isAfter(nowMoment.subtract(15, 'minutes'))) return t('timeBuckets.fifteenMinutes');
    if (latestActivity.isAfter(nowMoment.subtract(1, 'hour'))) return t('timeBuckets.hour');
    if (latestActivity.isAfter(nowMoment.subtract(1, 'day'))) return t('timeBuckets.day');
    if (latestActivity.isAfter(nowMoment.subtract(1, 'week'))) return t('timeBuckets.week');
    if (latestActivity.isAfter(nowMoment.subtract(1, 'month'))) return t('timeBuckets.month');
    return t('timeBuckets.older');
  }, [now, t]);

  // group into buckets
  const buckets: Record<string, Asset[]> = useMemo(() => {
    const sortedResults = [...results]
      // .filter(asset => latestPositionsByAsset[asset.id]?.received !== undefined)
      .sort((assetA, assetB) => (latestPositionsByAsset[assetB.id]?.received || 0) - (latestPositionsByAsset[assetA.id]?.received || 0));

    return sortedResults.reduce((acc, r) => {
      // @ts-ignore
      let field = r[groupBy];
      let time;
      // eslint-disable-next-line no-underscore-dangle
      const isAsset = r.__typename === 'Asset';
      if (isAsset) {
        // use this to sort assets by status
        switch (groupBy) {
          // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
          // case 'status':
          //   field = getBucketNameForState(assetState[r.id], tTag);
          //   break;
          case 'category':
            field = r.category.label;
            break;
          case 'owner':
            field = r.ownerName;
            break;
          case 'latestActivity':
            time = latestPositionsByAsset[r.id]?.received;
            field = time
              ? getBucketNameForLatestActivity(moment.unix(time))
              : t('timeBuckets.never');
            break;
          default:
            // do nuthing
            break;
        }
      }

      if (field) {
        acc[field] = acc[field] || [];
        acc[field].push(r);
      }
      // When grouping the assets, if the relevant field on the asset is not set or blank, we still want to show the
      // asset in the omnibox and in an appropriate group, so we sort these unknowns into a special bucket.
      // This ensures assets with blank 'make' and 'model' fields are still shown and grouped correctly in the 'other' bucket.
      // Similary, assets with unknown/no status end up in the special bucket.
      if (isAsset && (!field || field.toString().trim() === '')) {
        const unknownBucket = t('other');
        acc[unknownBucket] = acc[unknownBucket] || [];
        acc[unknownBucket].push(r);
      }

      return acc;
    }, {} as Record<string, Asset[]>);
  }, [latestPositionsByAsset, getBucketNameForLatestActivity, groupBy, results, t]);

  // if grouping by latestActivity then use order defined in timeBucketOrder, otherwise sort groups alphabetically
  const sortedBuckets = useMemo(() => {
    const timeBucketOrder = ['fifteenMinutes', 'hour', 'day', 'week', 'month', 'older', 'never'];
    return groupBy === 'latestActivity'
      ? timeBucketOrder.map(bucket => t(`timeBuckets.${bucket}`))
      : Object.keys(buckets).sort();
  }, [groupBy, buckets, t]);

  useEffect(() => {
    sortedBuckets.forEach(bucket => {
      if (buckets[bucket]) {
        if (buckets[bucket].every(asset => hiddenAssets.find(a => a.id === asset.id))) {
          hideAssetsGroup(bucket);
        } else {
          removeFromHiddenAssetGroups(bucket);
        }
      }
    });
    // TODO: I've purposely left buckets & sortedBuckets out of the deps list because the way they are defined above
    // means they're constantly being re-created causing this expensive loop to be run over and over
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [hiddenAssets]);

  const toggleVisibility = useCallback((e: React.MouseEvent, groupName: string): void => {
    e.stopPropagation();
    const assetsToBeToggledOnMap = buckets[groupName].filter(a => !hiddenInactiveAssets.includes(a.id));
    if (!hiddenAssetGroups.includes(groupName)) {
      hideAssetsGroup(groupName);
      hideAssetsOnMap(assetsToBeToggledOnMap);
    } else {
      removeFromHiddenAssetGroups(groupName);
      showAssetsOnMap(assetsToBeToggledOnMap);
    }
  }, [buckets, hiddenAssetGroups, hiddenInactiveAssets, hideAssetsGroup, hideAssetsOnMap, removeFromHiddenAssetGroups, showAssetsOnMap]);

  const displayVisibilityIcon = useCallback((groupName: string): JSX.Element | null => {
    if (!buckets[groupName]) return null;

    if (buckets[groupName].every(asset => hiddenInactiveAssets.includes(asset.id))) {
      return <VisibilityOff className={classes.visibilityIconDisabled} />;
    }
    if (hiddenAssetGroups.includes(groupName)) {
      if (selectedItemId && hiddenAssets.find(a => a.id === selectedItemId)) {
        clearSelection();
        unassignItemFromMap();
      }
      return (
        <Tooltip title={t('showOnMap')}>
          <VisibilityOff onClick={e => toggleVisibility(e, groupName)} className={classes.visibilityIcon} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title={t('hideOnMap')}>
        <Visibility onClick={e => toggleVisibility(e, groupName)} className={classes.visibilityIcon} />
      </Tooltip>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buckets, hiddenAssetGroups, t, hiddenInactiveAssets, selectedItemId, hiddenAssets, clearSelection, unassignItemFromMap, toggleVisibility]);

  const getBucketResults = useCallback((bucket: string): Asset[] => buckets[bucket] ?? [], [buckets]);

  const theme = useTheme();

  const bucketList = useMemo(
    () => sortedBuckets.map<(BucketHeader | BucketResult)[]>(bucket => {
      const bucketResults = getBucketResults(bucket);
      if (!bucketResults.length) return [];
      const visibleItems = bucketResults.map<BucketResult>(result => ({
        type: 'assetResult',
        result,
        isHidden: !!hiddenAssets.find(ha => ha.id === result.id),
        isSelected: !!selectedItemId && selectedItemId === result.id,
        height: 57
      })).filter(({ isHidden }) => !isHidden);

      if (visibleItems.length) visibleItems[visibleItems.length - 1].height += theme.spacingNumber(3);

      return [
        {
          type: 'header',
          bucket,
          visibilityIcon: displayVisibilityIcon(bucket),
          height: theme.spacingNumber(visibleItems.length ? 4 : 7)
        },
        ...visibleItems
      ];
    }).reduce((acc, curr) => acc.concat(...curr), []),
    [sortedBuckets, displayVisibilityIcon, getBucketResults, hiddenAssets, selectedItemId, theme]
  );

  const generateListElement = useCallback(({ index, key, style }: ListRowProps): JSX.Element | null => {
    const bucketElement = bucketList[index];
    if (!bucketElement) { return null; }

    if (bucketElement.type === 'header') {
      return (
        <div key={key} style={style} className={classes.groupHeaderRow}>
          <Typography className={classes.groupHeaderTitle}>{groupBy === 'status' ? t2(bucketElement.bucket) : bucketElement.bucket}</Typography>
          {activeQuery.name === 'Assets' && bucketElement.visibilityIcon}
        </div>
      );
    }
    return <div key={key} style={style} className={classes.assetRow}><QueryResult selectAsset={selectAsset} {...bucketElement} /></div>;
  }, [activeQuery.name, bucketList, classes.groupHeaderRow, classes.groupHeaderTitle, classes.assetRow, groupBy, selectAsset, t2]);

  const getHeight = useCallback((item: {index: number}) => (bucketList[item.index]?.height), [bucketList]);

  useEffect(() => {
    listRef.current?.recomputeRowHeights();
  }, [bucketList]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          // @ts-ignore
          ref={listRef}
          rowCount={bucketList.length}
          overscanRowCount={10}
          height={height}
          rowHeight={getHeight}
          rowRenderer={generateListElement}
          width={width}
        />
      )}
    </AutoSizer>
  );
};

export default GroupedQueryResults;
