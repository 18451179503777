import { CatmulRomSpline } from './spline';
import { KdTree } from './kdTree';

const distance = (a: Report, b: Report): number => ((a.latitude - b.latitude) ** 2) + ((a.longitude - b.longitude) ** 2);

/*
* This class allows efficient storage and retrieval of reports.
* It has the main external methods:
*   clearReports (for when e.g. changing historic mode)
*   insertReport (for when live reports are coming in, recalculates splines, etc.)
*   insertReports (for the result of polling, insert multiple reports and do recalculations of e.g. kdtrees afterwards)
*   setAssetReports (for initial loads of data at a given time
*/
export class ReportsRepository {
  reports: Map<number, Report>; // reportId -> report
  private assetsReports: Map<number, number[]>; // assetId -> reportId[]
  private assetsPositions: Map<number, number[]>; // assetId -> reportId[] with valid positions, ordered [newest, oldest]
  private assetSplines: Map<number, CatmulRomSpline>;
  private assetLegs: Map<number, Leg[]>;
  private kdTrees: Map<string, KdTree>;

  constructor() {
    this.reports = new Map<number, Report>();
    this.assetsReports = new Map<number, number[]>();
    this.assetsPositions = new Map<number, number[]>();
    this.assetSplines = new Map<number, CatmulRomSpline>();
    this.assetLegs = new Map<number, Leg[]>();
    this.kdTrees = new Map<string, KdTree>();
  }

  clearReports(): void {
    this.reports.clear();
    this.assetsReports.clear();
    this.assetsPositions.clear();
    this.assetSplines.clear();
    this.assetLegs.clear();
    this.kdTrees.clear();
  }

  private addToBeginning(assetId: number, report: Report): void {
    if (report.isValid) {
      if (this.assetsPositions.has(assetId)) {
        this.assetsPositions.set(assetId, [report.id].concat(...this.assetsPositions.get(assetId)!));
      } else {
        this.assetsPositions.set(assetId, [report.id]);
      }
    }
    if (this.assetsReports.has(assetId)) {
      this.assetsReports.set(assetId, [report.id].concat(...this.assetsReports.get(assetId)!));
    } else {
      this.assetsReports.set(assetId, [report.id]);
    }
  }

  private addToSpline(report: Report): void {
    if (this.assetSplines.has(report.assetId)) {
      // @ts-ignore
      this.assetSplines.get(report.assetId).insertNewCoord([report.longitude, report.latitude]);
    } else {
      this.assetSplines.set(report.assetId, new CatmulRomSpline([[report.latitude, report.longitude]]));
    }
  }

  private sortReport(a: number, b: number): number {
    const aR = this.reports.get(a)?.received || 0;
    const bR = this.reports.get(b)?.received || 0;
    return bR - aR; // if b is newer (bigger received), this will be positive and b will be before a in the array.
  }

  private cullOldReports(assetId: number, oldestTime: number): void {
    const oldestReportId = this.assetsReports.get(assetId)?.at(-1);
    if (!oldestReportId) { return; }
    const oldestReportReceived = this.reports.get(oldestReportId)?.received;
    if (!oldestReportReceived) { return; }

    if (oldestReportReceived < oldestTime) {
      this.assetsReports.set(assetId, this.assetsReports.get(assetId)?.filter(id => (this.reports.get(id)?.received || 0) > oldestTime) || []);
      this.assetsPositions.set(assetId, this.assetsReports.get(assetId)?.filter(id => this.reports.get(id)?.isValid) || []);
    }
  }

  private regenerateSpline(assetId: number): void {
    this.assetSplines.set(assetId, new CatmulRomSpline(this.assetsPositions.get(assetId)
      ?.map(id => this.reports.get(id))
      .filter(r => r?.isValid)
      .map(r => [r!.longitude, r!.latitude]) || [], 0.5, 15));
  }

  getLatestReport(assetId: number): Report | undefined {
    const reportId = this.assetsPositions.get(assetId)?.at(0);
    if (!reportId) return undefined;
    return this.reports.get(reportId);
  }

  insertReport(report: Report, cullBeforeTime?: number): void {
    if (this.reports.has(report.id)) {
      return;
    }

    this.reports.set(report.id, report);
    if (report.isValid) {
      if (!this.assetsPositions.has(report.assetId)) this.assetsPositions.set(report.assetId, [report.id]);
    }

    if (!this.assetsReports.has(report.assetId)) this.assetsReports.set(report.assetId, [report.id]);
    else if ((this.getLatestReport(report.assetId)?.received || 0) < report.received) {
      this.addToBeginning(report.assetId, report);
    } else {
      this.assetsReports.set(report.assetId, this.assetsReports
        .get(report.assetId)
        ?.concat(report.id)
        .sort((a, b) => this.sortReport(a, b)) || []);

      if (report.isValid) {
        this.assetsPositions.set(report.assetId, this.assetsPositions
          .get(report.assetId)
          ?.concat(report.id)
          .sort((a, b) => this.sortReport(a, b)) || []);
      }
    }
    if (cullBeforeTime !== undefined) {
      this.cullOldReports(report.assetId, cullBeforeTime);
    }

    if (report.isValid) {
      // recalculate spline because manually inserting it is a pain
      this.regenerateSpline(report.assetId);
    }
  }

  insertReports(reports: Report[], cullBeforeTime?: number): void {
    reports.forEach(r => this.insertReport(r, cullBeforeTime));

    // only remove kdtrees relating to inserted reports
    reports
      .map(r => r.assetId)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map(a => this.clearKdTreesForAsset(a));
  }

  clearKdTreesForAsset(assetId: number): void {
    [...this.kdTrees.keys()].forEach(as => {
      if (as
        .split(',')
        .map(a => parseInt(a, 10))
        .includes(assetId)) {
        this.kdTrees.delete(as);
      }
    });
  }

  setAssetReports(assetId: number, newReports: Report[]): void {
    const reportsForAsset = newReports.filter(r => r.assetId === assetId);
    const valid = newReports.filter(r => r.isValid);
    reportsForAsset.forEach(r => this.reports.set(r.id, r));

    // if (!this.assetSplines.has(assetId)) {
    const spline = new CatmulRomSpline(valid.map(r => [r.longitude, r.latitude]), 0.5, 15);
    this.assetSplines.set(assetId, spline);
    // }
    // } else {
    //   newReports.filter(r => r.isValid).forEach(r => this.assetSplines.get(assetId)?.insertNewCoord([r.longitude, r.latitude]));
    // }
    this.assetsReports.set(assetId, reportsForAsset.map(r => r.id));
    this.assetsPositions.set(assetId, valid.map(r => r.id));
    this.clearKdTreesForAsset(assetId);
  }

  getSortedReportsForAsset(assetId: number): Report[] {
    // @ts-ignore
    return this.assetsReports.get(assetId)?.map(r => this.reports.get(r)) || [];
  }

  getSortedPositionsForAsset(assetId: number): Report[] {
    // @ts-ignore
    return this.assetsPositions.get(assetId)?.map(r => this.reports.get(r)) || [];
  }

  getClosestReport(latitude: number, longitude: number, assetIds: number[]): Report | undefined {
    let kdtree;
    const sortedAssetIds = assetIds.sort();
    // Arrays don't strictly compare as equal, but strings do ([1] !== [1], but "1" === "1")
    const assetIdString = sortedAssetIds.join(',');
    kdtree = this.kdTrees.get(assetIdString);
    if (!kdtree) {
      kdtree = this.getKdTree(sortedAssetIds);
      this.kdTrees.set(assetIdString, kdtree);
    }
    return this.getClosestReportForKdTree(latitude, longitude, kdtree);
  }

  getClosestReportForKdTree(latitude: number, longitude: number, kdtree : KdTree): Report | undefined {
    const reportId = kdtree.nearest({ latitude, longitude }, 1)?.at(0)?.at(0).id;

    if (!reportId) return undefined;
    return this.reports.get(reportId);
  }

  getKdTree(assetIds: number[]): KdTree {
    return new KdTree(
      assetIds.map(a => this.assetsPositions
        ?.get(a)
        ?.map(r => this.reports.get(r)!)
        .map(r => ({
          id: r.id,
          latitude: r.latitude,
          longitude: r.longitude
        })) || []).flat(), distance, ['latitude', 'longitude']
    );
  }

  getReport(reportId: number): Report | undefined {
    return this.reports.get(reportId);
  }

  getSplineForAsset(assetId: number): [number, number][] {
    return this.assetSplines.get(assetId)?.interpolated || [];
  }
}
