import React from 'react';
import ContentLoader from 'react-content-loader';

const DeviceContentBoxDetailsLoading = () => (
  <ContentLoader
    viewBox="0 0 300 285"
    speed={2}
    backgroundColor="rgba(0,0,0,.1)"
    foregroundColor="rgba(0,0,0,.2)"
  >
    <rect x="184" y="178" rx="15" ry="15" width="95" height="30" />
    <rect x="0" y="0" rx="0" ry="0" width="300" height="160" />
    <rect x="18" y="178" rx="4" ry="4" width="58" height="16" />
    <rect x="18" y="198" rx="4" ry="4" width="108" height="16" />
    <rect x="18" y="225" rx="4" ry="4" width="58" height="12" />
    <rect x="18" y="240" rx="4" ry="4" width="78" height="12" />
    <rect x="150" y="225" rx="4" ry="4" width="58" height="12" />
    <rect x="150" y="240" rx="4" ry="4" width="78" height="12" />
  </ContentLoader>
);

export default DeviceContentBoxDetailsLoading;
