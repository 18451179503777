import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { saveAsset } from 'apis/trackstar/serenity';
import Page from 'components/pages/page';
import ScrollSpyContainer from 'components/shared/scrollSpyContainer';
import {
  Container, Box, Typography, Divider
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import LeftArrow from '@mui/icons-material/ChevronLeft';
import VoiceContacts from 'components/shared/voiceContacts';
import { GetAssetImage } from 'apis/assetImages';
import { ClassNameMap } from '@mui/styles';
import mixpanel from 'mixpanel-browser';
import MessagingWhitelist from './messagingWhitelist';
import AssetImage from './assetImage-view';
import AssetDetails from './assetDetails-view';
import AssetDevices from './assetDevices-view';
import AssetIceContacts from './assetIceContacts-view';
import AssetAms from './assetAms';
import useStyles from './asset-styles';

interface AssetPageParams {
  categories: Category[]
  asset: AssetBasic
  device: DeviceBasic,
  hasDevice: boolean,
  navigate: (url: string) => void
  organisationId: string
  displaySnackbar: (params: Snack) => void
  serialType: string
  permissions: { canEditAssets: boolean }
}

const AssetPage = ({
  categories,
  asset,
  device,
  hasDevice,
  navigate,
  organisationId,
  displaySnackbar,
  serialType,
  permissions
}:AssetPageParams): JSX.Element => {
  const t = useTranslation('pages.assetView');
  const classes: ClassNameMap<string> = useStyles();
  const [amsEnabled, setAmsEnabled] = useState(false);
  const assetImageEnabled = true;
  const readOnly = !(asset.ownerId.toLowerCase() === organisationId.toLowerCase()) || !permissions.canEditAssets || asset.category === 'System';
  const queryClient = useQueryClient();
  const [localAsset, setAsset] = useState({ ...asset });
  useEffect(() => setAsset({ ...asset }), [asset]);
  useEffect(() => {
    setAmsEnabled(['Rock7', 'Flightcell'].includes(device?.make) && ['RockAIR', 'RockDASH', 'DZMx', 'DZM3', 'DZM2', 'DZM'].includes(device?.model));
  }, [device, setAmsEnabled]);

  const scrollCategories = [
    {
      id: 'image',
      title: t('image'),
      hidden: true
    }, {
      id: 'general',
      title: t('generalTitle')
    }, {
      id: 'devices',
      title: t('devicesTitle')
    }, {
      id: 'mapdisplay',
      title: t('displayTitle'),
      hidden: true
    }, {
      id: 'emergencycontacts',
      title: t('emergency.title'),
      hasError: false,
      hasWarning: false,
      hidden: !hasDevice,
    }, {
      id: 'amsSettings',
      title: t('amsSettings.title'),
      hidden: !amsEnabled,
    }, {
      id: 'messagingWhitelist',
      title: t('messagingWhitelistTitle'),
      hidden: !hasDevice,
    }
  ];

  if (!asset) {
    navigate('/404');
  }

  const doUpdateAsset = useMutation(updatedAsset => saveAsset(updatedAsset), {
    // onMutate: async variables => {},
    onError: (err: Error) => {
      if (err.message.search('Cannot insert duplicate key row in object \'dbo.installations\' with unique index \'idx_installations_email_prefix\'')) {
        displaySnackbar({ id: 'assetDetailsUniqueMessagingHandle', text: t('assetDetailsUniqueMessagingHandle'), type: 'error' });
        mixpanel.track('Update Asset', {
          success: false, error: 'uniqueMessagingHandle', asset, organisationId
        });
      } else {
        displaySnackbar({ id: 'assetDetailsSaveFailed', text: t('assetDetailsSaveFailed'), type: 'error' });
        mixpanel.track('Update Asset', { success: false, asset, organisationId });
      }
    },
    onSuccess: () => {
      displaySnackbar({ id: 'assetDetailsSaved', text: t('assetDetailsSaved'), type: 'success' });
      mixpanel.track('Update Asset', { success: true, asset, organisationId });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['asset', asset.id]);
    },
    onMutate: (newAsset: any) => newAsset,
    mutationKey: 'updateAsset'
  });
  const saveAssetDetails = (assetDetails: any): void => {
    if (readOnly) return;
    setAsset({ ...localAsset, ...assetDetails });
    doUpdateAsset.mutate({
      id: localAsset.id,
      category: assetDetails.category?.label,
      name: assetDetails.name,
      make: assetDetails.make,
      model: assetDetails.model,
      variant: assetDetails.variant,
      messagingHandle: assetDetails.messagingHandle,
      watchlistGroup: assetDetails.watchlistGroup,
    });
  };

  // const saveAssetTrailColourInner = (name, value) => {
  //   if (!name || readOnly || localAsset[name] === value) return;
  //   setAsset({ ...localAsset, [name]: value });
  //   saveAsset({ ...localAsset, [name]: value });
  //   displaySnackbar({
  //     id: 'assetPropertySavedSnackbar',
  //     text: t('assetPropertySaved', { property: name }),
  //     type: 'success'
  //   });
  // };
  // const saveAssetTrailColour = debounce(saveAssetTrailColourInner, 400);

  const assetImage = useQuery(
    ['assetImage', asset.id],
    () => GetAssetImage(asset.id),
    // TODO: aggressive caching since the client currently doesn't know if this asset has a custom profile image
    // This can be removed (and the placeholder loaded without waiting for the failed custom image query) if we
    // store an indication that there is a custom image against the asset table in db (e.g. in the image_url field)
    { staleTime: Infinity, cacheTime: Infinity }
  );

  const onImageUpload = (success: boolean): void => {
    if (!success) {
      displaySnackbar({ id: 'assetImageSaveFailed', text: t('assetImageSaveFailed'), type: 'error' });
      mixpanel.track('Upload Asset Image', { success: false, asset, organisationId });
      return;
    }
    assetImage.refetch();
    displaySnackbar({ id: 'assetImageSaved', text: t('assetImageSaved'), type: 'success' });
    mixpanel.track('Upload Asset Image', { success: true, asset, organisationId });
  };

  const onImageRemove = async (success: boolean): Promise<void> => {
    if (!success) {
      displaySnackbar({ id: 'assetImageRemoveFailed', text: t('assetImageRemoveFailed'), type: 'error' });
      mixpanel.track('Remove Asset Image', { success: false, asset, organisationId });
      return;
    }
    await assetImage.refetch();
    displaySnackbar({ id: 'assetImageRemoved', text: t('assetImageRemoved'), type: 'success' });
    mixpanel.track('Remove Asset Image', { success: true, asset, organisationId });
  };

  return (
    <Page>
      <ScrollSpyContainer categories={scrollCategories}>
        <Box className={classes.pageWrapper}>
          <Container maxWidth="md" className={classes.container}>
            <Box className={classes.headerWrapper}>
              <Link to="/assets-and-devices" className={classes.link}>
                <Typography className={classes.linkText} variant="body1" gutterBottom>
                  <LeftArrow />
                  {' '}
                  {t('assetList')}
                </Typography>
              </Link>
              <Typography variant="h1" gutterBottom>{localAsset.name ? localAsset.name : 'Asset Settings'}</Typography>
              {localAsset.ownerId.toLowerCase() !== organisationId.toLowerCase() && (
                <Typography variant="body1" gutterBottom>{t('ownedBy', { ownerName: localAsset.ownerName })}</Typography>
              )}
              {assetImageEnabled && !readOnly && (
                <>
                  <Divider className={classes.divider} />
                  <AssetImage
                    classes={classes}
                    t={t}
                    imageUrl={assetImage.data}
                    isLoading={assetImage.isLoading}
                    assetId={asset.id}
                    onUpload={onImageUpload}
                    canEditAsset={!readOnly}
                    onRemove={onImageRemove}
                  />
                </>
              )}
              <Divider className={classes.divider} />
              <AssetDetails
                organisationId={organisationId}
                localAsset={localAsset}
                readOnly={readOnly}
                categories={categories}
                saveAssetDetails={saveAssetDetails}
                // displaySnackbar={displaySnackbar}
              />
              <Divider className={classes.divider} />
              <AssetDevices
                asset={asset}
                hasDevice={hasDevice}
                device={device}
                organisationId={organisationId}
                displaySnackbar={displaySnackbar}
                serialType={serialType}
                readOnly={readOnly}
              />
              {/* TODO: Add Asset Trail color, or hash of terminal id or something */}
              {/* <Divider className={classes.divider} />
              <AssetTrailColour
                classes={classes}
                t={t}
                localAsset={localAsset}
                saveAssetTrailColour
                readOnly={readOnly}
                previousColors={previousColors}
                setPreviousColors={setPreviousColors}
              />
               */}

              {hasDevice && (
                <>
                  <Divider className={classes.divider} />
                  <AssetIceContacts deviceId={asset.deviceId} readOnly={readOnly} />
                  <Divider className={classes.divider} />
                  <VoiceContacts deviceId={asset.deviceId} readOnly={readOnly} />
                </>
              )}

              {amsEnabled && (
                <>
                  <Divider className={classes.divider} />
                  <AssetAms assetId={asset.id} organisationId={organisationId} readOnly={readOnly} displaySnackbar={displaySnackbar} />
                </>
              )}

              {hasDevice && (
                <>
                  <Divider className={classes.divider} />
                  <MessagingWhitelist asset={asset} readOnly={readOnly} navigate={navigate} displaySnackbar={displaySnackbar} organisationId={organisationId} />
                </>
              )}
            </Box>
          </Container>
        </Box>
      </ScrollSpyContainer>
    </Page>
  );
};

export default AssetPage;
