import { CompositeLayer } from 'deck.gl';
import { SolidPolygonLayer } from '@deck.gl/layers';

// takes line features and renders them 3 times so they show on maps zoomed out to show more than a world width
// Would like to conditionally render these, but cant find bounds correctly see https://github.com/uber/deck.gl/issues/4060=
const triplicate = (polygons => polygons.flatMap(polygon => [
  { ...polygon, contour: polygon.contour.map(c => [c[0] - 360, c[1]]) },
  polygon,
  { ...polygon, contour: polygon.contour.map(c => [c[0] + 360, c[1]]) }
]));

class MissionTargetLayer extends CompositeLayer {
  updateState({ props, changeFlags }) {
    if (changeFlags.dataChanged) {
      // Special case if we get 2 points, we use it as a bounding box. More than 2 points and we just use the polygon directly:
      const polygons = triplicate((props.data?.length === 2) ? [{
        contour: [
          [props.data[0].longitude, props.data[0].latitude],
          [props.data[1].longitude, props.data[0].latitude],
          [props.data[1].longitude, props.data[1].latitude],
          [props.data[0].longitude, props.data[1].latitude]
        ]
      }] : [{
        contour: props.data?.map(p => [p.longitude, p.latitude])
      }]);

      this.setState({ polygons });
    }
  }

  renderLayers() {
    return [
      new SolidPolygonLayer({
        id: `poly-layer-${this.props.id}`,
        data: this.state.polygons,
        getPolygon: d => d.contour,
        extruded: true,
        filled: true,
        wireframe: true,
        opacity: 0.3,
        getFillColor: [160, 160, 160],
        getElevation: 10,
        elevationScale: (2 ** (15 - this.context.viewport.zoom)),
        visible: this.props.visible
      })
    ];
  }
}

export default MissionTargetLayer;
