import React from 'react';
import {
  Grid,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import BreadCrumbs from 'components/shared/breadCrumbs';
import useStyles from './multiStage-styles';

/**
 * Takes an ordered array of steps, in the form:
 * [{
 *   // The label for the step
 *   name: stepName,
 *   // True if the step can be skipped
 *   isOptional: bool,
 *   // True if the step has been completed
 *   isComplete: bool,
 *   // Called to render the content for the step.  Receives a function
 *   // (next) which can be called to auto-progress to the next step.
 *   getContent: (next) => contents,
 *   // A string to use as a breadcrumb
 *   breadcrumb: string
 * }]
 */
const MultiStageForm = ({
  steps,
  onComplete,
  error,
  enableReset = true,
}) => {
  const classes = useStyles();
  const t = useTranslation('shared.multiStageForm');

  const [activeStep, setActiveStep] = React.useState(0);
  const [skippedSteps, setSkippedSteps] = React.useState(new Set());

  const handleNext = () => {
    // If this step has previously been skipped, but is now being "nexted",
    // we want to remove it from our list of skipped steps
    if (skippedSteps.has(activeStep)) {
      // Deleting from a Set is mutative, so clone it first
      const updatedSkippedSteps = new Set(...skippedSteps);
      updatedSkippedSteps.delete(activeStep);
      setSkippedSteps(updatedSkippedSteps);
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const handleReset = () => setActiveStep(0);

  const handleSkip = () => {
    if (!steps[activeStep].isOptional) {
      // Should never occur unless someones actively trying to break something.
      throw new Error('You can\'t skip a step that isn\'t optional.');
    }

    // Adding to a Set is mutative, so clone it first
    const updatedSkippedSteps = new Set(...skippedSteps);
    updatedSkippedSteps.add(activeStep);
    setSkippedSteps(updatedSkippedSteps);

    setActiveStep(activeStep + 1);
  };

  const breadcrumbs = steps.slice(0, activeStep).map(step => step.breadcrumb);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.stepWrapper}>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (step.isOptional) {
              labelProps.optional = <Typography variant="caption">{t('optional')}</Typography>;
            }
            if (skippedSteps.has(index)) {
              stepProps.completed = false;
            }
            return (
              <Step className={classes.step} key={step.name} {...stepProps}>
                <StepLabel className={classes.stepText} {...labelProps}>{step.name}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box>
        {activeStep === steps.length ? (
          <Box className={classes.stepContentWrapper}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <Typography className={classes.instructions} variant="h2">{t('finished')}</Typography>
              </Grid>
              {enableReset && (
              <Grid item xs={12}>
                <Button onClick={handleReset} className={classes.button}>{t('reset')}</Button>
              </Grid>
              )}
            </Grid>
          </Box>
        ) : (
          <Box>
            <Box className={classes.stepContentWrapper}>
              {steps[activeStep].getContent(handleNext)}
            </Box>
            <Box className={classes.actionsWrapper}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <BreadCrumbs crumbs={breadcrumbs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {activeStep > 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      {t('back')}
                    </Button>
                  )}
                  {steps[activeStep].isOptional && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSkip}
                      className={classes.button}
                    >
                      {t('skip')}
                    </Button>
                  )}
                  <Button
                    disabled={!steps[activeStep].isComplete}
                    variant="contained"
                    color="primary"
                    onClick={activeStep === steps.length - 1 ? onComplete : handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? t('finish') : t('next')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
      {error && (
        <pre>{`Error: ${error}`}</pre>
      )}
    </Box>
  );
};

export default MultiStageForm;
