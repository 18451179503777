import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  select: {
    width: '250px',
    margin: '20px'
  },
  divider: {
    margin: theme.spacing(4, 0),
    borderColor: theme.border.color
  },
  fieldsPanel: {
    padding: theme.spacing(3, 4),
    boxShadow: 'none',
    border: theme.border.default
  }
}));
