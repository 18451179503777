import makeStyles from '@mui/styles/makeStyles';

let headerBarHeight = 50;
const footerBarHeight = 35;

const width = window.innerWidth;
if (width > 1440) {
  headerBarHeight = 56;
}

export default makeStyles(theme => ({
  headerBar: {
    flexBasis: `${headerBarHeight}px`
  },
  footerBar: {
    width: '100%',
    flexBasis: `${footerBarHeight}px`,
    minHeight: `${footerBarHeight}px`
  },
  contentContainer: {
    flexBasis: `calc(100vh - ${headerBarHeight + footerBarHeight}px)`,
    maxHeight: `calc(100vh - ${headerBarHeight + footerBarHeight}px)`,
    display: 'flex'
  },
  content: {
    display: 'flex',
    flex: 1
  },
  navWrapper: {
    display: 'flex'
  },
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
