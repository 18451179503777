import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  button: {
    margin: '0 5px 0 12px',
    height: '20px',
    width: '50px',
    boxShadow: 'none',
    borderRadius: '3px',
    backgroundColor: theme.palette.common.lightBlue,
    color: theme.palette.common.black,
    '&.apply': {
      backgroundColor: theme.palette.button.primary,
      color: theme.palette.button.primaryColor
    },
    '&:hover': {
      backgroundColor: theme.palette.button.primaryHover,
      color: theme.palette.button.primaryColor
    },
    cursor: 'pointer'
  },
  label: {
    padding: 'auto',
    lineHeight: '20px',
    fontSize: '12px',
    fontWeight: '450',
    textAlign: 'center'
  }
}));
