import Smart from 'hocs/smart';
import CreateAssetDataLoader from './createAsset-view';

export default Smart(
  CreateAssetDataLoader,
  state => ({
    organisationId: state.session.organisationId,
    previousColors: state.settings.map.previousColors
  }),
  ac => ({
    navigate: ac.router.navigate,
    setPreviousColors: ac.settings.setPreviousColors
  })
);
