import Smart from 'hocs/smart';
import GroupedResultsView from './groupedResults-view';

export default Smart(
  GroupedResultsView,
  state => ({
    now: state.app.now,
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // assetState: state.assetState,
    hiddenInactiveAssets: state.map.hiddenInactiveAssets,
    hiddenAssetGroups: state.map.hiddenAssetGroups,
    activeQuery: state.app.query,
    hiddenAssets: state.map.hiddenAssets,
    selectedItemId: state.app.selectedItem?.id
  }),
  ac => ({
    hideAssetsGroup: ac.map.hideAssetsGroup,
    removeFromHiddenAssetGroups: ac.map.removeFromHiddenAssetGroups,
    clearSelection: ac.app.clearSelection,
    hideAssetsOnMap: ac.map.hideAssetsOnMap,
    showAssetsOnMap: ac.map.showAssetsOnMap,
    unassignItemFromMap: ac.map.unassignItemFromMap,
  })
);
