import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import NavButton from './navigationButton';
import useStyles from './navigation-styles';

const Navigation = ({
  isOpen,
  isStaff,
  textMessages,
}) => {
  const classes = useStyles({ isOpen });
  const t = useTranslation('navigation');
  // TODO: re-implement with proper conversations when we have conversations
  const hasUnreadMessages = !!Object.keys(textMessages).length;

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <Box
      onMouseOver={handleDrawerOpen}
      onMouseOut={handleDrawerClose}
      onFocus={handleDrawerOpen}
      onBlur={handleDrawerClose}
      className={clsx(classes.container, { [classes.open]: open, [classes.closed]: !open })}
    >
      <NavButton iconName="Map" text={t('map')} />
      <NavButton iconName="Messaging" text={t('messaging')} link="/messaging" badge indicator={hasUnreadMessages} />
      <NavButton iconName="Sharing" text={t('sharing')} link="/sharing" />
      <NavButton iconName="Friends" text={t('friends')} link="/friends" />
      <NavButton iconName="Reporting" text={t('reporting')} link="/reporting" />
      <NavButton iconName="Manage" text={t('assetsAndDevices')} link="/assets-and-devices" />
      <NavButton iconName="Settings" text={t('settings')} link="/settings" />
      {isStaff && (
        <NavButton iconName="StaffPage" text={t('staffPage')} link="/staff" />
      )}
    </Box>
  );
};

export default Navigation;
