import { BitmapLayer, CompositeLayer } from 'deck.gl';
import { COORDINATE_SYSTEM } from '@deck.gl/core';
import React from 'react';
import windSpeedImageUrl from '../wind-speed-0.25.png';

class WindVelocityLayer extends CompositeLayer {
  renderLayers() {
    const { id, visible } = this.props;

    return [
      new BitmapLayer({
        id: `bitmap-layer-0-${id}`,
        bounds: [-540, -90, -180, 90],
        image: windSpeedImageUrl,
        _imageCoordinateSystem: COORDINATE_SYSTEM.LNGLAT,
        opacity: 0.4,
        visible,
      }),
      new BitmapLayer({
        id: `bitmap-layer-1-${id}`,
        bounds: [-180, -90, 180, 90],
        image: windSpeedImageUrl,
        _imageCoordinateSystem: COORDINATE_SYSTEM.LNGLAT,
        opacity: 0.4,
        visible,
      }),
      new BitmapLayer({
        id: `bitmap-layer-2-${id}`,
        bounds: [180, -90, 540, 90],
        image: windSpeedImageUrl,
        _imageCoordinateSystem: COORDINATE_SYSTEM.LNGLAT,
        opacity: 0.4,
        visible,
      }),
    ];
  }
}

export default WindVelocityLayer;
