import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  picker: {
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    boxShadow: hsl => (hsl.l > 0.5
      ? 'inset 0 0 0 1px #000'
      : 'inset 0 0 0 1px #fff'),
    transform: 'translate(-6px, -6px)'
  }
}));
