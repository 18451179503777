import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  pageWrapper: {
    backgroundColor: theme.palette.common.lightGrey,
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(8, 0)
  },
  headerWrapper: {
    textAlign: 'left'
  },
  link: {
    textDecoration: 'none'
  },
  linkText: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
  container: {
    paddingBottom: '20em' // if we don't pad at the bottom, some of the links in the right sidebar won't appear to work.
  },
  changeDeviceButton: {
    paddingTop: '1.5em'
  },
  changeDeviceDialog: {
    width: '600px'
  },
  divider: {
    borderColor: theme.border.color,
    marginTop: theme.spacing(3)
  },
  category: {
    marginTop: theme.spacing(4),
    '& .MuiInputLabel-outlined': {
      backgroundColor: theme.palette.background.paper,
      paddingRight: 5,
    },
  },
  panel: {
    textAlign: 'left',
    padding: theme.spacing(3, 4),
    boxShadow: 'none',
    border: theme.border.default
  },
  iceContactsPanelWrapper: {
    textAlign: 'left',
    padding: theme.spacing(3, 0),
    boxShadow: 'none',
    border: theme.border.default,
    '& .MuiPaper-elevation2': {
      boxShadow: 'none',
    },
    '& .MuiTableCell-footer': {
      borderBottom: 'none',
    },
  },
  iceContactsPanel: {
    '& > .MuiFormControlLabel-root': {
      marginLeft: theme.spacing(1)
    },
    '& > .MuiToolbar-root MuiToolbar-regular': {
      marginRight: theme.spacing(2)
    }
  },
  fab: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(4)
  },
  table: {
    '& th, & td': {
      borderBottom: theme.border.default
    },
  },
  tableWrapper: {
    marginTop: theme.spacing(0),
    boxShadow: 'none',
    border: theme.border.default,
  },
  deviceSerial: {
    wordBreak: 'break-word'
  },
  colorPickerWrapper: {
    position: 'relative'
  },
  colorSwatch: ({ color }) => ({
    position: 'absolute',
    width: '25px',
    height: '25px',
    bottom: '7px',
    right: '7px',
    borderRadius: '50%',
    backgroundColor: `${color}`,
    zIndex: 1
  }),
  imageCard: {
    border: theme.border.default,
  },
  hoverCursor: {
    '& :hover': {
      cursor: 'pointer'
    }
  },
  imagePanel: {
    textAlign: 'left',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    aspectRatio: 300 / 160,
  },
  cropDialog: {
    overflow: 'hidden'
  },
  media: {
    width: '100%',
    height: '100%',
  },
  imageIcon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2em',
    '& path': {
      fill: theme.palette.common.text
    }
  },
  uploadImageButton: {
    backgroundColor: 'rgba(36, 39, 48, 0.7)',
    '&:hover': {
      backgroundColor: 'rgba(36, 39, 48, 1)',
    },
    color: theme.palette.common.whiteConstant,
    padding: theme.spacing(2, 6),
    fontSize: '14px',
    '& svg path': {
      fill: theme.palette.common.whiteConstant,
    },
  },
  removeImageButton: {
    backgroundColor: 'rgba(36, 39, 48, 0.7)',
    '&:hover': {
      backgroundColor: 'rgba(36, 39, 48, 1)',
    },
    color: theme.palette.common.whiteConstant,
    padding: theme.spacing(2, 6),
    fontSize: '14px',
    '& svg path': {
      fill: theme.palette.common.whiteConstant,
    },
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    textAlign: 'right'
  },
  addDeviceWrapper: {
    padding: theme.spacing(2)
  },
  button: {
    padding: theme.spacing(2, 6),
    fontSize: '14px',
    textTransform: 'capitalize',
    boxShadow: 'none'
  },
  inputButton: {
    padding: theme.spacing(2, 6),
    fontSize: '1rem',
  },
  inputButtonWrapper: {
    textAlign: 'right',
  },
  resultsRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& :not(:last-child)': {
      marginRight: '10px'
    }
  },
  assignmentDate: {
    marginLeft: '32px'
  },
  greyOut: {
    color: '#a1a1a1'
  },
  small: {
    fontSize: 'smaller',
    fontStyle: 'italic',
    textAlign: 'right'
  },
  select: {
    borderRadius: '4px',
    minWidth: '204px',
    '& :focus': {
      backgroundColor: 'inherit'
    }
  },
  list: {
    paddingBottom: 0,
    listStyleType: 'disc',
    listStylePosition: 'inside',
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.typography.h6.color
  },
  option: {
    borderTop: theme.border.default
  },
  listItem: {
    display: 'list-item'
  },
  materialTable: {
    height: '100%',
    overflow: 'hidden',
    '& > div': {
      maxHeight: '100%',
      height: '100%',
      marginTop: 10,
      boxShadow: 'none',
      borderTop: theme.border.default,
      borderBottom: theme.border.default,
      borderRadius: 0,
      '& > div': {
        maxHeight: '100%',
        '& > div > div': {
          overflowY: 'unset!important'
        }
      }
    },
    '& .MuiToolbar-regular': {
      marginTop: '8px',
      paddingRight: '20px'
    },
    '& .MuiInput-formControl': {
      border: theme.border.default
    },
    '& .MuiInput-formControl::before, & .MuiInput-formControl::after': {
      display: 'none'
    },
    '& table td, & table th': {
      borderBottom: theme.border.default
    },
    '& table tr:last-of-type td': {
      borderBottom: 'none'
    },
    '& .MuiTableRow-root': {
      fontSize: '0.95rem',
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(8 / 3)
    },
    '& .MuiTableCell-root.MuiTableCell-paddingNone.MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .MuiTableCell-paddingCheckbox:last-child': {
      paddingRight: theme.spacing(4)
    },
    '& > .MuiPaper-elevation2': {
      boxShadow: 'none'
    },
  },
  noIceContacts: {
    color: theme.palette.primary.distressRed,
    textAlign: 'center'
  },
  icon: {
    fontSize: '1.6rem',
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iceButton: {
    color: theme.palette.common.text,
    '&.Mui-disabled': {
      visibility: 'hidden'
    }
  },
  active: {
    '& svg': {
      color: theme.palette.button.checked
    }
  },
  actionButton: {
    color: theme.palette.common.text
  },
  disabled: {
    cursor: 'not-allowed'
  },
  readOnly: {
    '&:hover': {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.disabled,
      }
    },
  },
  helperText: {
    flexDirection: 'row',
    '& .MuiFormHelperText-root': {
      zIndex: 100,
      marginLeft: -130,
      alignSelf: 'center',
      fontSize: '0.9rem',
    }
  },
  serialTable: {
    maxWidth: 400,
  },
  addButton: {
    backgroundColor: theme.palette.primary.main,
    height: '3rem',
    marginLeft: 8,
    padding: '19.5px 14px 19.5px 10px',
    borderRadius: 24,
    '& .MuiSvgIcon-root': {
      fontSize: '1.8rem'
    }
  },
  orgSettingsLink: {
    padding: theme.spacing(3, 2, 0, 2),
    cursor: 'pointer',
  },
  messagingWhitelistTable: {
    '& .MuiTableRow-root': {
      fontSize: '0.95rem'
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(8 / 3),
    },
    '& .MuiTableCell-root.MuiTableCell-paddingNone.MuiTableCell-body': {
      color: theme.palette.common.text,
    },
    '& .MuiTableCell-paddingCheckbox:last-child': {
      paddingRight: theme.spacing(4)
    },
    '& > .MuiPaper-elevation2': {
      boxShadow: 'none'
    },
    '& table td, & table th': {
      borderBottom: theme.border.default
    },
  },
  formControl: {
    width: '100%',
  },
  quickTasksBar: {
    justifyContent: 'center',
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    right: '0px',
    padding: theme.spacing(0),
    zIndex: 1,
    '&:after': {
      content: '\'\'',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      right: '0px',
      height: '80px',
      background: 'linear-gradient(to top, rgba(0, 0, 0, .75) 0%, transparent 100%)',
      zIndex: 1
    }
  },
  quickTasksButton: {
    color: theme.palette.common.whiteConstant,
    padding: theme.spacing(2),
    zIndex: 3
  },
  hidden: {
    display: 'none'
  },
}));
