import Smart from 'hocs/smart';
import NavigationView from './navigation-view';

export default Smart(
  NavigationView,
  state => ({
    organisationId: state.session.organisationId,
    isStaff: state.session.user.isStaff,
    textMessages: state.reports.textMessages,
  }),
  () => ({})
);
