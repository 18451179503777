import Smart from 'hocs/smart';
import LoginView from './login-view';

export default Smart(
  LoginView,
  state => ({
    loggingIn: state.session.loggingIn,
    loggedIn: state.session.loggedIn,
    rememberMe: state.session.rememberMe,
    loginError: state.session.loginError,
  }),
  ac => ({
    onSubmitSSOSuccess: ac.session.SSOLoginCallbackSuccess,
    onSubmitSSOFailure: ac.session.SSOLoginCallbackFailure,
    onSubmit: ac.session.login,
    legacyLogin: ac.session.legacyLogin,
    setRememberMe: ac.session.setRememberMe,
    replace: ac.router.replace
  })
);
