import React from 'react';

import { bearing } from 'helpers/unitsOfMeasure';
import Quantity from '../quantity';

const Course = ({
  courseInDegreesTrue,
  timestamp,
  position,
  units,
  precision = 0,
  smallUnits = false
}) => {
  const speedInLocale = bearing.fromSI(courseInDegreesTrue, timestamp, position, units);
  const unitsLabel = bearing.label(units, timestamp);
  return (
    <Quantity value={speedInLocale} units={unitsLabel} precision={precision} smallUnits={smallUnits} />
  );
};

export default Course;
