import Smart from 'hocs/smart';
import Omnibox from './omnibox-view';

export default Smart(
  Omnibox,
  state => ({
    organisationId: state.session.organisationId,
    textFilter: state.app.textFilter,
    omniboxOpen: state.ui.omniboxOpen,
    now: state.app.now,
  }),
  ac => ({
    setQueryGroupBy: ac.app.setGroupBy,
    setQuerySortBy: ac.app.setSortBy,
    setTextFilter: ac.app.setTextFilter,
    toggleOmni: ac.ui.toggleOmnibox,
    setNow: ac.app.setNow,
    displaySnackbar: ac.app.displaySnackbar,
    setPerformanceMode: ac.app.setPerformanceMode
  })
);
