import makeStyles from '@mui/styles/makeStyles';

const headerBarHeight = 50;
const footerBarHeight = 35;

export default makeStyles(theme => ({
  gridContainer: {
    '& *': {
      scrollbarColor: `${theme.palette.common.scrollBar} ${theme.palette.common.scrollBarBackground}`
    },
    '& *::-webkit-scrollbar-track': {
      background: theme.palette.common.scrollBarBackground
    },
    '& *::-webkit-scrollbar': {
      backgroundColor: theme.palette.common.scrollBarBackground
    },
    '& *::-webkit-scrollbar-thumb': {
      background: theme.palette.common.scrollBar
    }
  },
  headerBar: {
    flexBasis: `${headerBarHeight}px`
  },
  footerBar: {
    width: '100%',
    flexBasis: `${footerBarHeight}px`,
    minHeight: `${footerBarHeight}px`,
    zIndex: 12
  },
  contentContainer: {
    // In fullscreen mode the header and footer are removed
    flexBasis: `calc(100vh - ${headerBarHeight + footerBarHeight}px)`,
    maxHeight: `calc(100vh - ${headerBarHeight + footerBarHeight}px)`,
    display: 'flex'
  },
  content: {
    display: 'flex',
    flex: 1,
    marginLeft: '43px'
  },
  navWrapper: {
    display: 'flex'
  }
}));
