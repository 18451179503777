import React from 'react';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import StatusIcon from './statusIcon-view';

import useStyles from './serviceWidget-styles';

const SystemStatusServiceWidget = ({ service }) => {
  const classes = useStyles();
  const {
    id,
    name,
    status,
    message
  } = service;

  return (
    <>
      <ListItem key={id} className={classes.item}>
        <ListItemText>
          <Typography variant="h5">{name}</Typography>
          <Typography variant="body1">{message}</Typography>
        </ListItemText>
        <ListItemIcon className={classes.iconWrapper}>
          <StatusIcon status={status} />
        </ListItemIcon>
      </ListItem>
    </>
  );
};

export default SystemStatusServiceWidget;
