import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  controlsWrapper: {
    position: 'absolute',
    bottom: 20,
    left: 20
  },
  controlsWrapperEnabled: {
    display: 'block'
  },
  snackBar: {
    backgroundColor: '#43a047'
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: '1.6rem'
  },
  iconVariant: {
    opacity: 0.9,
    fontSize: '1.6rem',
    marginRight: theme.spacing(1)
  },
  mapInstance: {
    position: 'relative',
    display: 'grid'
  },
  mapView: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRow: 'span 1',
    gridColumn: 'span 1'
  },
  trackingPanel: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'inline-block',
    marginTop: '10px',
    padding: theme.spacing(2),
    borderRadius: 4,
    backgroundColor: 'rgba(0,0,0,.4)',
    overflow: 'hidden',
    opacity: 1,
    textAlign: 'center',
    zIndex: 999,
    boxShadow: 'none',
    '& p': {
      fontWeight: 500,
      fontSize: '1rem',
      color: theme.palette.common.whiteConstant
    }
  }
}));
