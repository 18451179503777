import { getRefreshedToken } from '../auth';
import { fetchOptions } from '../../helpers/api';

const BASE_URL = `${import.meta.env.VITE_SERENITY_REST_URL}`;

export const uploadProvisioningCSV = async (file: File): Promise<ProvisioningUploadResponse> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
  };
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const data = new FormData();
  data.append('file', file);
  const response = await fetch(`${BASE_URL}/provisioning/upload`, {
    method: 'POST',
    ...fetchOptions,
    headers,
    body: data
  } as RequestInit);
  // bad requests are our fault, ignore here and pass through to the response object
  if (!response.ok && response.status !== 400) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response.json();
};

export const getAllProvisioningRows = async (): Promise<ProvisioningProcessorRow[]> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  };
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const response = await fetch(`${BASE_URL}/provisioning`, {
    method: 'GET',
    ...fetchOptions,
    headers
  } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return (await response.json())?.rows;
};
