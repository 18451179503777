/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-destructuring */
type Coord = [number, number];

const trimDuplicateConsecutivePoints = (positions: Coord[]): Coord[] => positions.filter((pos, ix, posns) => {
  if (ix !== 0) {
    const prevPos = posns[ix - 1];
    if (pos[0] === prevPos[0] && pos[1] === prevPos[1]) {
      return false;
    }
  }

  return true;
});

const shareSign = (a: number, b: number): boolean => ((a < 0) !== (b < 0));

// Takes a 2D array of points [[lat, lng], [lat, lng]]
// Returns an array of polylines which dont cross map boundaries
const calculatePolylines = (points2d: Coord[]): Coord[][] => {
  const polylines = [];
  let firstPoint = 0;
  for (let i = 1; i < points2d.length; i++) {
    const lat1 = points2d[i - 1][0];
    const lat2 = points2d[i][0];

    // Compare each point with the previous, checking if they cross the edges of the map
    // This can be done by checking if they both have the same sign (+/-)
    const latCrossesEdgeHere = shareSign(lat1, lat2);

    if (latCrossesEdgeHere) {
      // End the line here and start a new line, to avoid drawing from -90 to 90 (or vice versa)
      polylines.push(trimDuplicateConsecutivePoints(points2d.slice(firstPoint, i)));
      firstPoint = i;
    }
  }

  if (firstPoint < points2d.length) {
    polylines.push(trimDuplicateConsecutivePoints(points2d.slice(firstPoint)));
  }

  return polylines;
};

export class CatmulRomSpline {
  interpolated: Coord[]
  alpha: number
  resolution: number
  private lastCoords: Coord[]

  constructor(initialCoordinates: Coord[], alpha = 0.5, resolution = 15) {
    this.alpha = alpha;
    this.resolution = resolution;
    this.interpolated = [];
    if (initialCoordinates.length >= 4) {
      this.lastCoords = [];
      this.calculateWholeSpline(trimDuplicateConsecutivePoints(initialCoordinates.reverse()));
    } else {
      this.lastCoords = initialCoordinates.reverse();
      this.interpolated = initialCoordinates.reverse();
    }
  }

  private calculateWholeSpline(coordinates: Coord[]): void {
    if (coordinates.length < 4) {
      this.interpolated = coordinates;
      return;
    }

    this.interpolated.push(coordinates[0]);

    // Interpolate all segments
    for (let i = 0; i < coordinates.length - 3; i++) {
      this.interpolated.push(...this.calculateSpline(coordinates[i], coordinates[i + 1], coordinates[i + 2], coordinates[i + 3]));
    }

    this.lastCoords = [coordinates[coordinates.length - 3], coordinates[coordinates.length - 2], coordinates[coordinates.length - 1]];
    // The end points
    this.interpolated.push(coordinates[coordinates.length - 1]);
  }

  // TODO: fix this to work with the polylines and stuff, crossing meridians, removing sequential duplicates.
  insertNewCoord(coord: Coord): void {
    if (this.lastCoords.length < 3) {
      this.lastCoords = trimDuplicateConsecutivePoints([...this.lastCoords.slice(0, 3), coord]);
      return;
    }
    this.lastCoords = trimDuplicateConsecutivePoints(this.lastCoords);
    if (this.lastCoords.length < 3) { return; }
    // @ts-ignore
    const interpolated = this.calculateSpline(...this.lastCoords, coord);
    if (this.interpolated.length >= 2) {
      this.interpolated.pop();
    }
    this.interpolated.push(...interpolated);
    this.interpolated.pop();
    this.interpolated.push(this.lastCoords[this.lastCoords.length - 1], coord);
  }

  private tValue(ti: number, pi: Coord, pj: Coord) {
    const xi = pi[0];
    const yi = pi[1];
    const xj = pj[0];
    const yj = pj[1];
    return ((((xj - xi) * (xj - xi) + (yj - yi) * (yj - yi)) ** 0.5) ** this.alpha) + ti;
  }

  // Calculates the spline
  private calculateSpline(p0: Coord, p1: Coord, p2: Coord, p3: Coord): Coord[] {
    const t0 = 0;
    const t1 = this.tValue(t0, p0, p1);
    const t2 = this.tValue(t1, p1, p2);
    const t3 = this.tValue(t2, p2, p3);

    // Calculate value t value for each added point
    const t = this.linearInterpolation(t1, t2);
    let a1x; let a1y; let a2x; let a2y; let a3x; let a3y; let b1x; let b1y; let b2x; let b2y;
    let cx: number; let cy: number;

    const results: Coord[] = [];

    // Calculate coordinates for each added point
    for (let i = 0; i < t.length; i++) {
      a1x = (t1 - t[i]) / (t1 - t0) * p0[0] + (t[i] - t0) / (t1 - t0) * p1[0];
      a1y = (t1 - t[i]) / (t1 - t0) * p0[1] + (t[i] - t0) / (t1 - t0) * p1[1];
      a2x = (t2 - t[i]) / (t2 - t1) * p1[0] + (t[i] - t1) / (t2 - t1) * p2[0];
      a2y = (t2 - t[i]) / (t2 - t1) * p1[1] + (t[i] - t1) / (t2 - t1) * p2[1];

      a3x = (t3 - t[i]) / (t3 - t2) * p2[0] + (t[i] - t2) / (t3 - t2) * p3[0];
      a3y = (t3 - t[i]) / (t3 - t2) * p2[1] + (t[i] - t2) / (t3 - t2) * p3[1];

      b1x = (t2 - t[i]) / (t2 - t0) * a1x + (t[i] - t0) / (t2 - t0) * a2x;
      b1y = (t2 - t[i]) / (t2 - t0) * a1y + (t[i] - t0) / (t2 - t0) * a2y;
      b2x = (t3 - t[i]) / (t3 - t1) * a2x + (t[i] - t1) / (t3 - t1) * a3x;
      b2y = (t3 - t[i]) / (t3 - t1) * a2y + (t[i] - t1) / (t3 - t1) * a3y;

      cx = (t2 - t[i]) / (t2 - t1) * b1x + (t[i] - t1) / (t2 - t1) * b2x;
      cy = (t2 - t[i]) / (t2 - t1) * b1y + (t[i] - t1) / (t2 - t1) * b2y;

      results.push([cx, cy]);
    }

    return results;
  }

  // Perform linear interpolation from value p1 to value p2. Parameter 'resolution' sets the number of interpolated points.
  private linearInterpolation(p1: number, p2: number): Float32Array {
    // Pre-allocate array for the results.
    const interpolated = new Float32Array(this.resolution + 1);
    interpolated[0] = p1;

    const interval = (p2 - p1) / (this.resolution + 1);
    for (let i = 1; i < this.resolution + 1; i++) {
      interpolated[i] = interpolated[i - 1] + interval;
    }

    return interpolated;
  }

  // Transforms a list into a matrix with m columns.

  private static arrayTo2d(array: Float32Array, m: number): Coord[] {
    const matrix: Coord[] = [];
    let k = -1;
    for (let i = 0; i < array.length; i++) {
      if (i % m === 0) {
        k++;
        // @ts-ignore
        matrix[k] = [];
      }
      matrix[k].push(array[i]);
    }
    return matrix;
  }
}
