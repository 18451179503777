import Smart from 'hocs/smart';
import JumpView from './jumpToLatest-view';

export default Smart(
  JumpView,
  state => ({
    now: state.app.now,
    follow: state.map.assetsAreBeingFollowedOnMaps[state.map.selectedMapId],
    selectedMapId: state.map.selectedMapId,
    timezone: state.settings.locale.timezone
  }),
  ac => ({
    setNow: ac.app.setNow,
    displaySnackbar: ac.app.displaySnackbar,
    centerMapOnPosition: ac.map.centerMapOnPosition,
    setSelectedReport: ac.reports.setSelectedReport,
    setHighlightedReportId: ac.reports.setHighlightedReportId,
    setFollow: ac.map.setFollow,
  })
);
