// master color palette, based on https://www.youtube.com/watch?v=9S8LGdpNh4Q

const colorPalette = theme => ({
  // layout
  white: theme === 'dark' ? '#2A323C' : '#FFF',
  black: theme === 'dark' ? '#FFF' : '#000',
  grey: theme === 'dark' ? '#A0A7B4' : '#818285',
  greyLight: theme === 'dark' ? '#242730' : '#E9F0F6',
  greyDark: theme === 'dark' ? '#FFF' : '#073048',
  greyMid: theme === 'dark' ? '#3A414C' : '#E0EAF1',
  // extra
  blue: '#2A93D5',
  turquoise: '#3DD9D7',
  yellow: '#FDBD2B',
  orange: '#E97C39',
  // status
  error: '#CE2525',
  errorLight: 'rgba(206, 37, 37, 0.1)',
  success: '#72C472',
  successLight: 'rgba(114, 196, 114, 0.1)',
  disabled: '#818285',
  disabledLight: '#F3F7F9',
  // interactions
  primary: theme === 'dark' ? '#F5F5F5' : '#2A93D5',
  primaryLight: theme === 'dark' ? '#FFFFFF' : '#3EAFE8',
  primaryDark: theme === 'dark' ? '#2A323C' : '#0D5189'
});

export default colorPalette;
