import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { setAutoFreeze } from 'immer';
import thunk from 'redux-thunk';
import { getAuth } from 'firebase/auth';
// eslint-disable-next-line import/no-cycle
import createReducers from 'reducers';
import rootSaga from 'sagas';
// import websocketMiddleware from 'apis/websocketMiddleware';

const isProduction = process.env.NODE_ENV === 'production';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const userTiming = () => next => action => {
  if (performance.mark === undefined) return next(action);
  performance.mark(`${action.type}_start`);
  const result = next(action);
  performance.mark(`${action.type}_end`);
  performance.measure(
    `${action.type}`,
    `${action.type}_start`,
    `${action.type}_end`
  );
  return result;
};

const initialState = {};
const enhancers = [];
const middleware = [
  thunk,
  sagaMiddleware,
  routerMiddleware(history),
  // websocketMiddleware
];

// Records Redux events in performance profiler:
if (!isProduction) {
  middleware.push(userTiming);
}

// Use redux devtools if available
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    maxAge: 75,
    shouldRecordChanges: !isProduction,
    actionsDenylist: [
      'UPDATE_VIEWPORT',
      'FOV_CHANGED',
      'UPDATE_CURSOR_POSITION',
      'CALCULATE_CLOSEST_REPORT',
    ]
  })
  : compose;

const composedEnhancers = composeEnhancers(
  applyMiddleware(...middleware),
  ...enhancers
);

const rootReducer = createReducers(connectRouter(history));

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
);

// If immer auto-freezes reducers, redux-persist fails (it attempts
// to add an '_persist' key to the returned state)
// https://github.com/rt2zz/redux-persist/issues/747
setAutoFreeze(false);

// Callback here is to synchronously, after store persisted (loaded whether user is logged in), then wait for auth to be initialised,
// call 'INIT' action, which initialises auth things, and used to initialise websockets.
const persistor = persistStore(store, null, () => getAuth().onAuthStateChanged(() => store.dispatch({ type: 'INIT' })));
sagaMiddleware.run(rootSaga);

export { store, persistor, history };

window.store = store;
window.purge = () => persistor.purge();
