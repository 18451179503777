import React, { useState } from 'react';
import {
  Box, Fab, Grid, Tooltip, Link
} from '@mui/material';
import MaterialTable, { MTableBody } from '@material-table/core';
import { Add, Edit } from '@mui/icons-material';
import ConfirmDialog from 'components/shared/confirmDialog';
import tableIcons from 'components/shared/icons/tableIcons';
import TableActions from 'components/shared/materialTable/tableActions';
import { useQuery } from 'react-query';
import { fetchContacts } from 'apis/trackstar/serenity';
import useTranslation from 'hooks/useTranslation';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import insensitiveSort from 'utils/insensitiveSort';
import EditContactModal from './editContactModal';
import CreateContactModal from './createContactModal';
import useStyles from './iceContacts-styles';
import IceContactState from './iceContactState';
import Summary from './iceContactSummary';

const IceContacts = ({
  deviceId,
  organisationId,
  permissions,
  navigate,
  // this is context (org/asset) specific readOnly
  readOnly,
}) => {
  const t = useTranslation('emergencyContacts');
  const classes = useStyles();
  const assetMode = (deviceId && !organisationId) ?? false;
  const organisationMode = (organisationId && !deviceId) ?? false;
  const [people, setPeople] = useState({});
  const [editingContact, setEditingContact] = useState(null);
  const [creatingContact, setCreatingContact] = useState(null);
  const [isCancelButtonHidden, setCancelButtonHidden] = useState(false);
  const isEditable = permissions.canChangeEmergencyContacts && !readOnly;

  const getContacts = useQuery(['contacts', deviceId ?? null], () => fetchContacts(assetMode ? deviceId : undefined), {
    onSuccess: data => {
      const updatedPeople = data.reduce((peopleAcc, contact) => {
        const { name } = contact;
        if (!peopleAcc[name]) peopleAcc[name] = people[name] ?? [];
        const index = peopleAcc[name].findIndex(c => c.id === contact.id);
        if (index >= 0) {
          peopleAcc[name] = [...peopleAcc[name].slice(0, index), contact, ...peopleAcc[name].slice(index + 1)];
        } else {
          peopleAcc[name].push(contact);
        }
        return peopleAcc;
      }, {});
      setPeople(updatedPeople);
    }
  });

  const updatePerson = (prevName, person) => {
    const { [prevName]: prevPerson, ...otherPeople } = people;
    if (!person.length) {
      // all contacts for this person have been deleted, so remove them from people
      setPeople(otherPeople);
    } else {
      // otherwise update the person in question to the new person
      setPeople({ ...otherPeople, [person[0].name]: person });
    }
  };

  // TODO: plug in react-query assets to enable asset contact assignment when deleting contacts
  const assets = [];

  const [dialog, setDialog] = useState({
    show: false, title: '', message: '', confirmFunc: null
  });
  const showConfirmDialog = (title, message, confirmFunc) => setDialog({
    show: true, title, message, confirmFunc
  });

  const actions = isEditable ? [
    contact => ({
      icon: () => <Edit className={classes.actionButton} />,
      tooltip: t('editContact'),
      onClick: () => setEditingContact(contact)
    })
  ] : [];

  const columns = [{
    title: t('name'),
    field: 'name',
    headerStyle: { textAlign: 'left' },
    cellStyle: { textAlign: 'left' },
    sorting: false,
    // defaultSort: 'asc',
    // customSort: sortEmergencyContact,
  }];
  if (assetMode) {
    columns.push({
      title: t('inAnEmergency'),
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
      sorting: false,
      render: contact => (<IceContactState contact={people[contact.name]} />),
    });
  }
  const countryAndCallingCodes = getCountries()
    .map(country => ({ countryCode: country, callingCode: getCountryCallingCode(country) }));
  const getContactCallingCode = contact => contact.split(' ')[0].substring(1);
  const getContactCountryCode = contact => {
    const contactCallingCode = getContactCallingCode(contact);
    // hard coding this to US so that US customers don't have the selector default to 'AG' (first +1 country in the alphabet)
    if (contactCallingCode === '1') return 'US';
    return countryAndCallingCodes.find(ccc => contactCallingCode === ccc.callingCode)?.countryCode;
  };

  // TODO: switch back to <Summary emergencyContacts={iceContacts} /> once ice contacts are refactored
  return (
    <Grid id="emergencyContacts">
      {assetMode && <Summary emergencyContacts={people} />}
      <Box className={classes.materialTable}>

        {/* # Contacts Table */}
        <MaterialTable
          icons={tableIcons}
          data={Object.keys(people).map(c => ({ name: c }))}
          columns={columns}
          actions={actions}
          isLoading={getContacts.isLoading}
          components={{
            Actions: TableActions,
            Body: props => {
              const { data, currentPage, pageSize } = props;
              const enabledContacts = data.filter(row => people[row.name]?.find(contact => contact.iceEmail || contact.icePhone || contact.iceSms)).sort((a, b) => insensitiveSort(a.name, b.name));
              const notEnabledContacts = data.filter(row => !people[row.name]?.find(contact => contact.iceEmail || contact.icePhone || contact.iceSms)).sort((a, b) => insensitiveSort(a.name, b.name));
              const groupedRows = [...enabledContacts, ...notEnabledContacts];
              const newProps = { ...props, data: groupedRows, renderData: groupedRows.slice(currentPage * pageSize, (currentPage * pageSize) + pageSize) };
              return (
                <MTableBody
                  {...newProps}
                />
              );
            }
          }}
          options={{
            draggable: false,
            showTitle: false,
            search: true,
            actionsColumnIndex: -1,
            searchFieldStyle: {
              borderRadius: '4px',
              paddingLeft: '18px',
              paddingRight: '10px'
            },
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
            paging: true,
            pageSizeOptions: [5, 25, 50, 100],
            pageSize: 5,
            emptyRowsWhenPaging: false,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '550px',
          }}
          localization={{
            header: {
              actions: t('actions')
            },
            pagination: {
              labelRows: t('rows'),
              labelDisplayedRows: ` {from}-{to} ${t('of')} {count}`,
              firstTooltip: t('firstPage'),
              previousTooltip: t('previousPage'),
              nextTooltip: t('nextPage'),
              lastTooltip: t('lastPage')
            },
          }}
        />

        {/* # Link to Org Settings page to modify contacts */}
        {assetMode && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            className={classes.orgSettingsLink}
            onClick={e => {
              e.preventDefault();
              navigate('/settings/organisation');
            }}
            underline="hover"
          >
            {readOnly ? t('orgSettingsLinkViewOnly') : t('orgSettingsLink')}
          </Link>
        )}
      </Box>

      {/* # Create New Contact */}
      {organisationMode && isEditable && (
        <Box className={classes.buttonWrapper}>
          <Tooltip title={t('newContactButton')} placement="left">
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => setCreatingContact(true)}>
              <Add />
            </Fab>
          </Tooltip>
        </Box>
      )}

      {/* # Dialogs */}
      {editingContact && (
        <EditContactModal
          deviceId={deviceId}
          assetMode={assetMode}
          organisationMode={organisationMode}
          person={people[editingContact.name]}
          updatePerson={updatePerson}
          allContacts={getContacts.isSuccess && getContacts.data}
          onClose={() => setEditingContact(null)}
          countryAndCallingCodes={countryAndCallingCodes}
          getContactCallingCode={getContactCallingCode}
          getContactCountryCode={getContactCountryCode}
        />
      )}
      {creatingContact && (
        <CreateContactModal
          creatingContact={creatingContact}
          onClose={() => setCreatingContact(null)}
          countryAndCallingCodes={countryAndCallingCodes}
        />
      )}
      <ConfirmDialog
        title={dialog.title}
        message={dialog.message}
        open={dialog.show}
        okButtonText={t('okButton')}
        cancelButtonText={t('cancelButton')}
        onConfirm={dialog.confirmFunc}
        setOpen={value => setDialog({ ...dialog, show: value })}
        isCancelButtonHidden={isCancelButtonHidden}
        setCancelButtonHidden={setCancelButtonHidden}
      />
    </Grid>
  );
};

export default IceContacts;
