import Smart from 'hocs/smart';
import { isLoggedIn } from 'selectors/session';
import Private from './private-view';

export default Smart(
  Private,
  state => ({
    loggedIn: isLoggedIn(state),
    user: state.session.user,
    userSettings: state.settings,
    mapSettings: state.map?.maps?.default,
  }),
  ac => ({
    navigate: ac.router.navigate
  })
);
