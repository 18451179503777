import { IconLayer } from '@deck.gl/layers';

import outlinedFragmentShader from './shaders/outlined-fragment-shader.glsl';

export default class OutlinedIconLayer extends IconLayer {
  draw({ uniforms }) {
    super.draw({
      uniforms:
      {
        ...uniforms,
        outlineColor: [this.props.outlineColor[0] / 255, this.props.outlineColor[1] / 255, this.props.outlineColor[2] / 255, 1.0],
      }
    });
  }

  getShaders() {
    return { ...super.getShaders(), fs: outlinedFragmentShader };
  }

  getPickingInfo(pickParams) {
    const o = super.getPickingInfo(pickParams);
    if (o?.object) {
      return {
        object: o.object,
        layer: o.layer,
        lngLat: o.lngLat
      };
    }
    return null;
  }
}

OutlinedIconLayer.defaultProps = {
  outlineColor: [255, 255, 255],
};
