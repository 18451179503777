import Smart from 'hocs/smart';
import { selectedLeg } from 'selectors/legs';
import Analysisbox from './analysisbox-view';

export default Smart(
  Analysisbox,
  (state: any) => ({
    selectedItem: state.app.selectedItem,
    selectedLeg: selectedLeg(state),
    timezone: state.settings.locale.timezone,
    now: state.app.now,
    selectedMapId: state.map.selectedMapId,
    follow: state.map.assetsAreBeingFollowedOnMaps[state.map.selectedMapId],
    organisationId: state.session.organisationId,
  }),
  (ac: any) => ({
    centerMapOnEvent: ac.map.centerMapOnEvent,
    centerMapOnPosition: ac.map.centerMapOnPosition,
    setSelectedReport: ac.reports.setSelectedReport,
    setHighlightedReportId: ac.reports.setHighlightedReportId,
    setFollow: ac.map.setFollow,
    setMostRecentDeviceReport: ac.reports.setMostRecentDeviceReport,
  })
);
