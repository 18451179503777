import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  breadCrumbWrapper: {
    position: 'relative',
    textAlign: 'left'
  },
  breadCrumb: {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: theme.palette.common.lightBlue,
    padding: '6px 18px',
    borderRadius: 25,
    '& p': {
      color: theme.palette.primary.main,
      fontWeight: '500'
    }
  },
  breadCrumbsActive: {
  },
  rightArrow: {
    fontSize: '125%',
    transform: 'translateY(0.25em)'
  }
}));
