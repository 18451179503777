import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  panel: {
    padding: theme.spacing(3, 4),
    boxShadow: 'none',
    border: theme.border.default
  },
  detailTitle: {
    marginTop: theme.spacing(2)
  },
  selected: {
    '& .MuiFormControlLabel-label h6, .MuiFormControlLabel-label p': {
      color: theme.palette.primary.main
    }
  },
  radioGroup: {
    '& .MuiFormControlLabel-root': {
      padding: 10
    }
  }
}));
