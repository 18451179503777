/* eslint-disable no-underscore-dangle */
import React, {
  useState, useEffect, useMemo, useCallback
} from 'react';
import ReactMapGl, { LinearInterpolator } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import WebMercatorViewport from '@math.gl/web-mercator';
import DeckGL from '@deck.gl/react';
import moment from 'utils/moment';
import { debounce } from 'lodash';
import { project } from 'utils/projection';
import { hexToRGBArray } from 'helpers/color';
import { isSignificantReport } from 'helpers/events';
import reports, { generateSpline } from 'repositories/reports';
import { TRAILS_OPTIONS } from 'constants/trailsoptions';
import {
  useAssetReports,
  useAssetSplines,
  useClosestReport,
  useLatestPosition, useLatestPositionsForAssets
} from 'repositories/reports/hooks';
import useStyles from '../map-styles';
import 'mapbox-gl/dist/mapbox-gl.css';
import AssetIconsLayer from './layers/assetIconsLayer';
import AdsbIconsLayer from './layers/adsbIconsLayer';
import TrailsLayer from './layers/trailsLayer';
import MeasureToolLayer from './layers/measureToolLayer';
import ReportDotLayer from './layers/reportDotLayer';
import EventsLayer from './layers/eventsLayer';
import MissionTargetLayer from './layers/missionTargetLayer';
import KmlLayer from './layers/kmlLayer';
import WindTrailsLayer from './layers/wind/trails';
import WindVelocityLayer from './layers/wind/velocity';
import useDistanceTraveled from './modules/useDistanceTraveled';

const TPReactGlMap = ({
  children,
  template,
  config,
  assets,
  patchViewport,
  appSelectedItemId,
  thisMapSelectedItemId,
  onMouseDown,
  selected,
  follow,
  minZoom,
  maxZoom,
  measureToggle,
  measurementMarker,
  viewport,
  additionalRenders,
  selectItem,
  eventFilter,
  assignItemToMap,
  assignMarkerToAsset,
  setSelectedReport,
  selectedReport,
  missionTargetArea,
  hiddenAssets,
  visibleAssets,
  hideAssetsOnMap,
  showAssetsOnMap,
  hiddenInactiveAssets,
  updateHiddenInactiveAssets,
  kml,
  kmlStatus,
  trailHighlight,
  cbHighlightedReportId,
  displaySnackbar,
  updateCursorPosition,
  adsbAircraft,
  nearestAdsbHex
}) => {
  const classes = useStyles();
  const assetSelected = !!appSelectedItemId;
  const [mouseCoords, setMouseCoords] = useState({ lat: 0, lng: 0 });
  // const [cursorPosition, setCursorPosition] = useState({
  //   lat: 0, lng: 0, x: 0, y: 0, mapId: 0
  // });
  const [hoveringOnDot, setHoveringOnDot] = useState(false);
  const [highlightedReportId, setHighlightedReportId] = useState(null);
  const [previousSelection, setPreviousSelection] = useState(null);
  const [mapTemplate, setMapTemplate] = useState(template);

  const assetTrails = useAssetSplines(assets);
  const latestPositions = useLatestPositionsForAssets(assets);
  const selectedAssetReports = useAssetReports(appSelectedItemId);

  const events = useMemo(() => selectedAssetReports.filter(isSignificantReport), [selectedAssetReports]);

  const assetHighlightTrail = useMemo(() => {
    if (!trailHighlight?.legStart) return [];
    const reportsOnLeg = selectedAssetReports
      .filter(r => r.isValid && r.received >= trailHighlight.legStart.received && r.received <= trailHighlight.legEnd.received);
    return generateSpline(reportsOnLeg);
  }, [selectedAssetReports, trailHighlight]);

  // use mouseCoords rather than cursorPosition to avoid redux overhead
  const closestReport = useClosestReport(
    { latitude: mouseCoords?.lat, longitude: mouseCoords?.lng },
    appSelectedItemId ? [appSelectedItemId] : (config.trailsOption !== 4 ? [] : visibleAssets.map(a => a.id))
  );

  useEffect(() => {
    setTimeout(() => setMapTemplate(template), 1);
  }, [template]);

  // This is needed in several places, so create the viewport up front:
  const wmViewport = useMemo(() => (new WebMercatorViewport(viewport)), [viewport]);

  const eventsProjected = useMemo(() => events
    .filter(e => e.isValid)
    .map(e => ({ id: e.id, position: project(wmViewport, e.latitude, e.longitude) })),
  [events, wmViewport]);

  useEffect(() => {
    if (config.hideInactiveAssets) {
      const assetIdsBeyondSelectedDays = assets.filter(a => moment().subtract(config.inactiveSinceHours, 'hours') > latestPositions[a.id]?.received);
      showAssetsOnMap(hiddenInactiveAssets.filter(assetId => !assetIdsBeyondSelectedDays.includes(assetId)));
      updateHiddenInactiveAssets(assetIdsBeyondSelectedDays);
      hideAssetsOnMap([...assetIdsBeyondSelectedDays]);
    } else if (hiddenInactiveAssets.length > 0) {
      showAssetsOnMap(hiddenInactiveAssets);
      updateHiddenInactiveAssets([]);
    }
  }, [latestPositions, assets, config.hideInactiveAssets, config.inactiveSinceHours, hiddenInactiveAssets, hideAssetsOnMap, showAssetsOnMap, updateHiddenInactiveAssets]);

  const setDefaultPulseColorToRGB = Array.isArray(config.defaultPulseColor)
    ? config.defaultPulseColor
    : hexToRGBArray(config.defaultPulseColor);

  const setFollowPulseColorToRGB = Array.isArray(config.followPulseColor)
    ? config.followPulseColor
    : hexToRGBArray(config.followPulseColor);

  const updateMeasurementMousePath = coords => {
    if (!selected) return;
    if (measureToggle && coords) {
      debounce(() => setMouseCoords({ lat: coords.lat, lng: coords.lng }), 25, { maxWait: 25 });
    }
  };

  const getView = useCallback((bounds, pos) => {
    console.log('calculating view for bounds, pos');
    if (bounds && pos && viewport.width) {
      if (!pos?.isValid) {
        displaySnackbar({ id: 'invalidReport', text: 'The position data for this asset is invalid, please contact TracPlus Support for assistance.', type: 'error' });
        return;
      }
      // eslint-disable-next-line no-underscore-dangle
      const boundedZoom = wmViewport.fitBounds([[bounds._sw.lng, bounds._sw.lat], [bounds._ne.lng, bounds._ne.lat]], { padding: 100 }).zoom;
      // Note: maxZoom is actually really close. If users want to get that close manually they can but the automatic zoom adjustment should avoid getting that close, so we subtract 5 levels:
      let zoom = Math.min(boundedZoom, maxZoom - 5);
      // Only change the zoom level if the jump needs to be big:
      if (Math.abs(viewport.zoom - zoom) < 5) {
        zoom = viewport.zoom;
      }
      patchViewport(config.id, {
        latitude: pos.latitude,
        longitude: pos.longitude,
        zoom,
        transitionDuration: config.animateToSelection ? 5000 : 100
      });
    } else if (pos) {
      // there are no positions, possibly due to asset not updating in last X time period, use assets current position instead
      patchViewport(config.id, {
        latitude: pos.latitude,
        longitude: pos.longitude,
        zoom: maxZoom - 5,
        transitionDuration: 100
      });
    }
  }, [config.animateToSelection, config.id, displaySnackbar, maxZoom, patchViewport, viewport.width, viewport.zoom, wmViewport]);

  const selectedItemPosition = useLatestPosition(thisMapSelectedItemId);

  useEffect(() => {
    if (follow) {
      const pos = selectedItemPosition;
      if (pos) {
        patchViewport(config.id, {
          latitude: pos.latitude,
          longitude: pos.longitude,
          transitionDuration: 0
        });
      }
    }
    // by adding viewport.zoom here, we ensure the tracked asset stays in the center of the map when the user zooms in
  }, [config.id, follow, patchViewport, selectedItemPosition, viewport.zoom]);

  useEffect(() => {
    if (!selected) return;

    const calcBounds = boundReports => {
      if (!boundReports || boundReports.length === 0) return null;
      const coords = boundReports.map(r => [r.longitude, r.latitude]);
      return coords.reduce(
        (b, coord) => b.extend(coord),
        new mapboxgl.LngLatBounds(coords[0], coords[0])
      );
    };

    // this ensures that the zoom and pan are retained when the user changes between map splits with different assets
    // selected so zoom to bounds should only happen when the user selects a new asset on this view
    if (previousSelection === appSelectedItemId) return;

    const bounds = calcBounds(reports.getReportsForAsset(appSelectedItemId, true));
    getView(bounds, latestPositions[appSelectedItemId]);

    setPreviousSelection(appSelectedItemId);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */// We really only want this to run when selectedItem changes
  }, [appSelectedItemId]);

  // Measurement marker creation
  const addMeasurementMarker = lngLat => {
    if (measureToggle) {
      if (appSelectedItemId) {
        assignMarkerToAsset(config.id, appSelectedItemId, { lng: lngLat[0], lat: lngLat[1] });
      }
    }
  };

  const handleMapClick = e => {
    addMeasurementMarker(e.coordinate);
    const { x, y } = e;

    // To match clicks with assets we need to project all the asset positions on the map.
    // We also need to do the same to cluster assets when asset clustering is turned on.
    // So we should probably just do it once and re-use the projected positions values.
    const projected = assets.flatMap(a => {
      const recentRep = latestPositions[a.id];
      if (!recentRep) { return []; }
      const { latitude, longitude } = recentRep;
      const pos = project(wmViewport, latitude, longitude);
      const pos2 = project(wmViewport, latitude, longitude, -1);
      const pos3 = project(wmViewport, latitude, longitude, 1);
      return [{
        x: [pos[0], pos2[0], pos3[0]],
        y: [pos[1], pos2[1], pos3[1]],
        assetId: a.id,
        name: a.name
      }];
    });

    // Check if the click is near any visible assets:
    projected.forEach(p => {
      if (Math.hypot(x - p.x[0], y - p.y[0]) < 48 || Math.hypot(x - p.x[1], y - p.y[1]) < 48 || Math.hypot(x - p.x[2], y - p.y[2]) < 48) {
        if (selected) {
          // Don't allow selecting hidden assets (aka in hiddenAssets array or trailsOption 2 'show only selected asset & trail' mode)
          if (config.trailsOption !== 2 && appSelectedItemId !== p.assetId && !hiddenAssets.find(a => a.id === p.assetId)) {
            const clickedAsset = assets.find(a => a.id === p?.assetId);
            setPreviousSelection({
              id: p.assetId,
              __typename: 'Asset',
              name: p.name
            });
            selectItem(clickedAsset);
            assignItemToMap(config.id, clickedAsset);
          }
        }
      }
    });

    if (closestReport) {
      const pos = project(wmViewport, closestReport.latitude, closestReport.longitude);
      // Check if the click was close to the closest report
      if (Math.hypot(x - pos[0], y - pos[1]) < 32) {
        setSelectedReport(config.id, closestReport);
      } else {
        setSelectedReport(config.id, null);
      }
    }
  };

  const handleMapHover = e => {
    const { x, y } = e;

    if (closestReport) {
      const pos = project(wmViewport, closestReport.latitude, closestReport.longitude);
      setHoveringOnDot(Math.hypot(x - pos[0], y - pos[1]) < 32);
    }
  };

  const dotReport = (selectedReport || closestReport);
  const assetsDataSimple = useMemo(() => (
    {
      assetSelected,
      selectedItemId: thisMapSelectedItemId,
      assets: visibleAssets,
      latestPositions,
      clusters: [],
      selectedAssetPosition: selectedAssetReports[0]
    }
  ), [latestPositions, assetSelected, thisMapSelectedItemId, visibleAssets, selectedAssetReports]);

  const checkEventHover = useCallback(cursor => {
    if (!events || events.length < 1) return;
    const { x, y } = cursor;

    // Can't use forEach since we want to early exit with return if we hit a match:
    for (let i = 0; i < eventsProjected.length; i++) {
      if (Math.hypot(x - eventsProjected[i].position[0], y - eventsProjected[i].position[1]) < 16) {
        setHighlightedReportId(eventsProjected[i].id);
        return;
      }
    }
    setHighlightedReportId(null);
  }, [events, eventsProjected]);

  // When in large orgs, zoom is very slow because of how much data is recalced for ant trails
  // Memoize values going into the layers to ensure they are only updated when they need to be:
  const totalPositions = useDistanceTraveled(visibleAssets, assetTrails);
  const trailData = useMemo(() => ({
    assets: visibleAssets,
    assetSelected,
    assetTrails: config.trailsOption === TRAILS_OPTIONS.allTrailsIcons ? assetTrails : { [thisMapSelectedItemId]: assetTrails[thisMapSelectedItemId] },
    selectedAssetId: thisMapSelectedItemId
  }), [visibleAssets, assetSelected, config.trailsOption, assetTrails, thisMapSelectedItemId]);

  const thisMapSelectedAsset = assets.find(a => a.id === thisMapSelectedItemId);
  const eventsData = useMemo(() => (
    assetSelected && thisMapSelectedAsset && thisMapSelectedItemId ? {
      events: events
        ?.filter(e => e.isValid && e.events.every(ev => !eventFilter?.blacklist?.includes(ev)))
        .filter(event => !hiddenAssets.find(a => a.id === event.assetId)),
      highlightedReportId: cbHighlightedReportId ?? highlightedReportId,
      color: thisMapSelectedAsset?.color ? hexToRGBArray(thisMapSelectedAsset.color) : [255, 105, 180, 255]
    } : null
  ), [thisMapSelectedItemId, assetSelected, cbHighlightedReportId, events, eventFilter, hiddenAssets, highlightedReportId, thisMapSelectedAsset]);

  const dotReportAsset = assets.find(a => a.deviceId === dotReport?.deviceId);
  const closestReportAsset = assets.find(a => a.deviceId === closestReport?.deviceId);
  const reportDotData = useMemo(() => {
    const report = reports.getReport(selectedReport?.id || dotReport?.id);
    return dotReport?.deviceId && dotReportAsset && ({
      color: dotReportAsset?.color ? hexToRGBArray(dotReportAsset.color) : [255, 105, 180, 255],
      closestPointColorAfterSelect: closestReportAsset?.color ? hexToRGBArray(closestReportAsset.color) : [255, 105, 180, 255],
      report,
      selectedReport,
      closestReport,
      allReports: config.reportDots && viewport.zoom > config.hideReportDotsAtZoom ? selectedAssetReports.filter(r => r.isValid) : []
    });
  }, [config.reportDots, config.hideReportDotsAtZoom, closestReport, closestReportAsset, viewport.zoom, dotReport, dotReportAsset, selectedAssetReports, selectedReport]);

  const measureToolData = useMemo(() => ({
    measurementMarker,
    mouseCoords,
    selected,
    currentAssetPosition: selectedItemPosition,
    showMarker: true
  }), [measurementMarker, mouseCoords, selected, selectedItemPosition]);

  let layers = null;

  if (config?.layers) {
    layers = Object.values(config.layers).sort((a, b) => b.order - a.order).map(layer => {
      switch (layer.id) {
        case 'events': return (
          <EventsLayer
            key="events-layer"
            id="events-layer"
            data={eventsData}
            trailsOption={config.trailsOption}
            visble={assetSelected}
          />
        );
        case 'dots': return (
          <ReportDotLayer
            key="report-dot-layer"
            id="report-dot-layer"
            data={reportDotData}
            radius={config.trailWidth * 3.5}
            fixedRadius={config.trailWidth * 2}
            hoveringOnDot={hoveringOnDot}
            trailsOption={config.trailsOption}
            selectedItemId={thisMapSelectedItemId}
            hiddenAssets={hiddenAssets}
          />
        );
        case 'trails': return (
          <TrailsLayer
            key="trails-layer"
            id="trails-layer"
            data={trailData}
            additionalRenders={additionalRenders}
            trailWidth={config.trailWidth}
            animate={config.animateTrails}
            animateSelectedTrailOnly={config.animateSelectedTrailOnly}
            trailsOption={config.trailsOption}
            trailHighlight={trailHighlight}
            highlightTrail={assetHighlightTrail}
            totalPositions={totalPositions}
            zoom={viewport.zoom}
          />
        );
        case 'assetIcons': return (
          <AssetIconsLayer
            key="asset-icons-layer"
            id="asset-icons-layer"
            data={assetsDataSimple}
            follow={follow}
            bearing={viewport.bearing}
            textVisible={config.permanentLabels}
            pulseColor={setDefaultPulseColorToRGB}
            followPulseColor={setFollowPulseColorToRGB}
            trailsOption={config.trailsOption}
            selectedItemId={thisMapSelectedItemId}
            selectedItemIsHidden={hiddenAssets.find(a => a.id === thisMapSelectedItemId)}
            appSelectedItemId={appSelectedItemId}
          />
        );
        case 'missionTarget': return (
          <MissionTargetLayer
            key="mission-target-layer"
            id="mission-target-layer"
            data={missionTargetArea}
            visible={missionTargetArea?.length > 1}
            zoom={viewport.zoom}
          />
        );
        case 'measureTool': return (
          <MeasureToolLayer
            key="measure-tool-layer"
            id="measure-tool-layer"
            data={measureToolData}
            trailWidth={config.trailWidth}
            visible={measureToggle}
            selected={selected}
          />
        );
        case 'kml': return kml.map(kmlFilename => (
          <KmlLayer
            key={`kml-layer-${kmlFilename}`}
            id={`kml-layer-${kmlFilename}`}
            kml={kmlFilename}
            kmlLabels={config.kmlLabels}
            visible={kmlStatus[kmlFilename] === 'visible'}
          />
        ));
        case 'adsbIcons': return (
          <AdsbIconsLayer
            key="adsb-icons-layer"
            id="adsb-icons-layer"
            aircraft={adsbAircraft}
            bearing={viewport.bearing}
            textVisible
            nearestAdsbHex={nearestAdsbHex}
          />
        );
        case 'windVelocity': return (
          <WindVelocityLayer
            key="wind-velocity-layer"
            id="wind-velocity-layer"
            visible={config.windVelocity}
          />
        );
        default: return null;
      }
    });
  }

  // console.log(adsbAircraft.length, config.id)

  return (
    <>
      <div className={classes.mapView}>
        <ReactMapGl
          /* eslint-disable-next-line react/jsx-props-no-spreading */// as per ReactMapGl docs
          {...viewport}
          transitionInterpolator={new LinearInterpolator()}
          width="100%"
          height="100%"
          mapStyle={mapTemplate}
          onViewportChange={vp => {
            patchViewport(config.id, {
              latitude: vp.latitude,
              longitude: vp.longitude,
              zoom: vp.zoom,
              bearing: vp.bearing,
              pitch: vp.pitch,
              width: vp.width,
              height: vp.height,
              // setting transitionDuration to 0 makes the map jerkier when zooming (no interpolation) but reduces clustering recalculations by 66%
              transitionDuration: vp.transitionDuration,
            });
          }}
          onResize={dimensions => {
            patchViewport(config.id, {
              latitude: viewport.latitude,
              longitude: viewport.longitude,
              zoom: viewport.zoom,
              bearing: viewport.bearing,
              pitch: viewport.pitch,
              width: dimensions.width,
              height: dimensions.height,
              transitionDuration: viewport.transitionDuration
            });
          }}
          classes={[classes.wrapper]}
          onMouseMove={e => {
            updateCursorPosition({
              mapId: config.id,
              lat: e.lngLat[1],
              lng: e.lngLat[0],
              x: e.point[0],
              y: e.point[1]
            });
            setMouseCoords({ lat: e.lngLat[1], lng: e.lngLat[0] });
            updateMeasurementMousePath({ lng: e.lngLat[0], lat: e.lngLat[1] });
            checkEventHover({ lng: e.point[0], y: e.point[1] });
          }}
          onMouseOut={() => updateCursorPosition(null)}
          onBlur={() => updateCursorPosition(null)}
          dragPan={!follow && { inertia: 0 }}
          onMouseDown={onMouseDown}
          maxZoom={maxZoom}
          minZoom={minZoom}
          doubleClickZoom={false}
          asyncRender
        >
          <DeckGL
            _animate
            viewState={follow ? {
              ...viewport,
              latitude: selectedAssetReports[0]?.latitude,
              longitude: selectedAssetReports[0]?.longitude
            } : viewport}
            onClick={handleMapClick}
            onHover={handleMapHover}
            getCursor={() => 'default'}
          >
            {layers}
          </DeckGL>
          {config.windTrails && <WindTrailsLayer mapTemplate={mapTemplate} />}
          {children}
        </ReactMapGl>
      </div>
    </>
  );
};

export default TPReactGlMap;
