import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  tableContainer: {
    padding: 0
  },
  materialTable: {
    '& .MuiTableSortLabel-icon': {
      fontSize: '18px'
    },
    '& input.MuiInputBase-input': {
      // NOTE: this is an ugly workaround because there isn't a more specific selector for the search input
      padding: theme.spacing(1.8, 0),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '& .MuiPaper-root': {
      marginTop: 0
    },
    '& > div': {
      marginTop: '15px',
      boxShadow: 'none',
      border: theme.border.default
    },
    '& .MuiToolbar-regular': {
      marginTop: '8px',
      paddingRight: '20px'
    },
    '& .MuiInput-formControl': {
      border: theme.border.default
    },
    '& .MuiInput-formControl::before, & .MuiInput-formControl::after': {
      display: 'none'
    },
    '& table td, & table th': {
      borderBottom: theme.border.default
    },
    '& table tr:last-of-type td': {
      borderBottom: 'none'
    },
    '& .MuiTableRow-root': {
      fontSize: '0.95rem'
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(8 / 3)
    },
    '& .MuiTableCell-root.MuiTableCell-paddingNone.MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .MuiIconButton-root': {
      color: theme.palette.common.text
    }
  },
  addButton: {
    backgroundColor: theme.palette.primary.main,
    height: '3rem',
    marginLeft: 8,
    padding: '19.5px 14px 19.5px 10px',
    borderRadius: 24,
    '& .MuiSvgIcon-root': {
      fontSize: '1.8rem'
    }
  },
  dialogDesc: {
    marginBottom: 20
  },
  dialogSubtext: {
    marginBottom: 25,
    fontWeight: 200,
    fontStyle: 'italic',
  },
  dialogHeading: {
    marginTop: 25,
    marginBottom: 5,
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  inviteInputWrapper: {
    flex: '1',
    minWidth: '540px',
    '& .MuiFormControl-root': {
      '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 20px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(10px, -6px) scale(0.73)',
      }
    },
  },
  inputButton: {
    padding: theme.spacing(1.75, 5),
    marginLeft: theme.spacing(2),
    height: '3rem'
  },
  groupFriendCheck: {
    color: theme.palette.common.text,
  },
}));
