import Smart from "../../../hocs/smart";
import PersistentTable from "./persistentTable-view";

export default Smart(
  PersistentTable,
  state => ({
    rowsPerPage: state.settings.ui.rowsPerPage
  }),
  ac => ({
    updateSetting: ac.settings.updateSetting
  })
)