import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  pageWrapper: {
    backgroundColor: theme.palette.common.lightGrey,
    flex: 1,
    overflowY: 'auto'
  },
  fieldsWrapper: {
    padding: theme.spacing(8, 0),
    textAlign: 'left'
  },
  actionsWrapper: {
    textAlign: 'right',
    '& button:first-child': {
      marginRight: '15px'
    }
  },
  divider: {
    margin: theme.spacing(4, 0),
    borderColor: theme.border.color
  },
  button: {
    fontSize: '1.1rem',
    marginTop: '20px'
  },
  link: {
    textDecoration: 'none'
  },
  linkText: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none'
  }
}));
