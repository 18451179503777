import React from 'react';
import moment from 'moment';
import DistressDialog from 'components/contextbox/asset/distress/distressDialog';
import useInterval from 'hooks/useInterval';
import { getReports } from 'apis/rest/reports';
import reports from 'repositories/reports';

interface LiveUpdateDummyProps {
  timezone: string
  organisationId: string
  setUnreadTextMessage: (deviceId: number, reportId: number) => void
  mostRecentDeviceReport: number
  setMostRecentDeviceReport: (time: number) => void
}

// TODO: I feel like there might be a better place to put this, somewhere in a redux saga? Not sure
const LiveUpdateDummy = ({
  timezone,
  organisationId,
  setUnreadTextMessage,
  mostRecentDeviceReport,
  setMostRecentDeviceReport,
}: LiveUpdateDummyProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [distressReports, setDistressReports] = React.useState<Report[]>([]);

  useInterval(async () => {
    const from = mostRecentDeviceReport ? moment(mostRecentDeviceReport) : moment().subtract('1', 'minute');
    const until = moment();
    const reportsSince = await getReports(organisationId, from, until);
    const newDistressReports: Report[] = [];
    const reportsSinceForAsset = reportsSince.reduce((assetReports, report) => {
      assetReports[report.assetId] = [...(assetReports[report.assetId] || []), report];
      return assetReports;
    }, {} as Record<number, Report[]>);
    Object.values(reportsSinceForAsset).forEach(assetReports => {
      const latestReport = assetReports[0];

      if ((moment.unix(latestReport?.received).diff(moment(), 'h') >= -2)) {
        if (latestReport.events.some(e => e.includes('EVT_DISTRESS'))) {
          if (!distressReports.find(r => r.id === latestReport.id)) {
            newDistressReports.push(latestReport);
            setOpen(true);
          }
        }
      }
      // If any of the new messages arriving are text messages set unread state for the device in redux
      assetReports.forEach(r => {
        if (r.events.includes('EVT_TEXT') && (moment.unix(r?.received).diff(moment(), 'm') >= -10)) {
          setUnreadTextMessage(r.deviceId, r.id);
        }
      });
    });

    // use insertReports because it only triggers render once all are in.
    reports.insertReports(reportsSince, moment().tz(timezone).startOf('day').unix());
    setDistressReports([...distressReports, ...newDistressReports]);

    const latestReport = reportsSince.sort((a, b) => b.received - a.received)[0];
    if (latestReport) {
      setMostRecentDeviceReport(latestReport.received * 1000); // to ms
    }
  }, 10 * 1000);

  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // Live Tag Data (asset status)
  // const { data: liveTagResult } = useLiveTagData(assetIds);
  // useEffect(() => {
  //   if (liveTagResult) {
  //     const { object: { id: assetId }, ...tag } = liveTagResult.assetState;
  //     processInboundTag(assetId, tag, now);
  //   }
  //   // Note: providing `now` as a dependency would re-process the previous
  //   // tag whenever the user changes `now`.  It's value is only needed at
  //   // processing time to calculate whether a tag is current or not.
  // }, [processInboundTag, liveTagResult]);

  // @ts-ignore
  return <DistressDialog open={open} setOpen={setOpen} reports={distressReports} />;
};

export default LiveUpdateDummy;
