import Smart from 'hocs/smart';
import ADSBDetailsOverlay from './adsbDetails-view';

export default Smart(
  ADSBDetailsOverlay,
  state => ({
    currentTimezone: state.settings.locale.timezone
  }),
  () => ({})
);
