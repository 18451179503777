import {
  all, call, put, takeEvery
} from 'redux-saga/effects';
import { getAuth } from 'firebase/auth';
import { fetchUsercodes } from 'apis/trackstar/serenity';
import { fetchGlobalRoles, fetchOrgRoles } from 'apis/organisations';
// const MESSAGING_LIVE = getApiUrl('messaging/v1/live', 'ws');
// import { getApiUrl } from 'helpers/api';

const fetchUser = async (refresh = true) => {
  console.time('fetchUser');
  const user = await getAuth().currentUser;
  console.timeLog('fetchUser', 'got user');
  if (user) {
    const tokenResult = await user.getIdTokenResult(refresh);
    console.timeLog('fetchUser', 'got token');
    localStorage.setItem('bearerToken', tokenResult.token);
    const memberOf = await fetchUsercodes();
    console.timeLog('fetchUser', 'got usercodes');
    const allRoles = await fetchGlobalRoles();
    console.timeLog('fetchUser', 'got global roles');
    const orgRoles = await fetchOrgRoles(memberOf.map(o => o.id), user.uid, allRoles);
    console.timeLog('fetchUser', 'got org roles');
    console.timeEnd('fetchUser');

    return {
      user: {
        id: user.uid,
        name: user.displayName,
        email: user.email,
        profilePicUrl: user.photoURL,
        isStaff: tokenResult.claims?.isStaff || false,
        memberOf,
        orgRoles,
        allRoles,
      }
    };
  }
  return { errored: true };
};

// This emits an action about the current state of auth at page load.
// useful for chaining other things which only happen when the app is
// logged in
export function* watchFetchUser() {
  yield takeEvery([
    'IS_LOGGED_IN', // update user every page load
    'SIGNUP_SUCCESS', // fetch the user on signup
    'LOGIN_SUCCESS', // fetch the user on login
    'FETCH_USER'
  ], function* handle() {
    const res = yield call(fetchUser);
    if (res.errored) {
      yield put({ type: 'LOGIN_FAILURE', payload: 'serverError' });
    } else if (!(res.user.memberOf.length > 0)) {
      yield put({ type: 'LOGIN_FAILURE', payload: 'contactSupport' });
    } else {
      yield put({ type: 'USER_LOADED', payload: res.user });
      // TODO: Update vite proxy to pass through WS
      yield put({ type: 'WS_CONNECT', host: 'http://localhost:8088/reports/live' });
    }
  });
}

export default function* () {
  yield all([
    watchFetchUser()
  ]);
}
