import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XhrBackend from 'i18next-xhr-backend';

// Increase this to force a cachebust when there have been changes to the translation files:
const version = 0.98;

export const initOptions = {
  ns: ['common'],

  defaultNS: 'common',

  interpolation: {
    escapeValue: false
  },

  keySeparator: '.',

  react: {
    useSuspense: true
  },

  // languages to not persist (cookie, localStorage)
  excludeCacheFor: ['localStorage', 'cookie'],

  whitelist: ['en', 'es'],

  nonExplicitWhitelist: true,

  load: 'languageOnly',

  fallbackLng: {
    'en-US': ['en'],
    default: ['en']
  },

  // only detect languages that are in the whitelist
  checkWhitelist: true,

  backend: {
    loadPath: `/locales/{{lng}}/{{ns}}.json?v=${version}`,
    allowMultiLoading: true
  }
};

export const i18nPreInit = i18n.use(XhrBackend).use(LanguageDetector).use(initReactI18next);
export default {
  i18nPreInit,
  initOptions
};
