import makeStyles from '@mui/styles/makeStyles';

const footerBarHeight = 35;

export default makeStyles(theme => ({
  toolbar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.text,
    borderTop: theme.border.default,
    minHeight: footerBarHeight,
    maxHeight: footerBarHeight,
    zIndex: 4,
  },
  positionWrapper: {
    flex: 1,
    textAlign: 'left',
    marginRight: 'auto'
  }
}));
