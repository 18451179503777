import Smart from 'hocs/smart';
import PageTitle from './pageTitle-view';

export default Smart(
  PageTitle,
  state => ({
    location: state.router.location,
  }),
  () => ({})
);
