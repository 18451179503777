import React from 'react';
import useStyles from './colorPreviews-styles';

const CustomColorPickerPreviews = ({ rgb, currentColor }) => {
  const classes = useStyles();

  const currentBackgroudColor = {
    background: currentColor
  };

  const newBackgroundColor = {
    background: `rgb(${rgb.r},${rgb.g}, ${rgb.b})`
  };

  return (
    <div className={classes.swatches}>
      <div className={classes.label}>
        Current
        <div className={classes.current} style={currentBackgroudColor} />
      </div>
      <div className={classes.label}>
        New
        <div className={classes.new} style={newBackgroundColor} />
      </div>
    </div>
  );
};

export default CustomColorPickerPreviews;
