import React from 'react';
import { Box, Grid } from '@mui/material';
import Header from 'components/header';
import StatusBar from 'components/statusbar';
import SystemStatus from 'components/systemStatus';
import Navigation from 'components/navigation';
import { ClassNameMap } from '@mui/styles';
import useStyles from './page-styles';

interface PageProps {
  children: React.ReactNode,
  systemStatusOpen?: boolean,
}

const defaultProps = {
  systemStatusOpen: false,
};

const Page = ({
  children,
  systemStatusOpen,
}: PageProps): JSX.Element => {
  const classes: ClassNameMap = useStyles();

  return (
    <Grid className={classes.gridContainer} container spacing={0} direction="column">
      <Grid item xs={12} className={classes.headerBar}>
        <Header />
      </Grid>

      <Grid item xs={12} className={classes.contentContainer}>
        <Box className={classes.navWrapper}>
          <Navigation />
        </Box>

        <Box className={classes.content}>
          {children}
        </Box>
      </Grid>

      {systemStatusOpen && (<SystemStatus />)}

      <Grid item xs={12} className={classes.footerBar}>
        <StatusBar />
      </Grid>
    </Grid>
  );
};
Page.defaultProps = defaultProps;

export default Page;
