import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  categoriesWrapper: {
    padding: theme.spacing(8, 0),
    textAlign: 'left'
  },
  divider: {
    margin: theme.spacing(4, 0),
    borderColor: theme.border.color
  },
  container: {
    textAlign: 'left'
  },
  alignRight: {
    textAlign: 'right'
  },
  fieldsPanel: {
    padding: theme.spacing(6, 4, 3, 4),
    boxShadow: 'none',
    border: theme.border.default
  },
  formPanel: {
    padding: theme.spacing(3, 4),
    boxShadow: 'none',
    border: theme.border.default
  },
  panel: {
    marginTop: theme.spacing(0),
    boxShadow: 'none',
    border: theme.border.default
  },
  table: {
    '& th, & td': {
      borderBottom: theme.border.default
    },
    '& th': {
      fontWeight: 500,
    }
  },
  tableRow: {
    transition: 'background-color 100ms ease',
    '&:hover': {
      backgroundColor: theme.palette.common.midGrey,
      cursor: 'pointer'
    }
  },
  inputContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(3, 5)
  },
  forgotPasswordButton: {
    padding: theme.spacing(2, 6),
    fontSize: '1rem',
    textTransform: 'inherit'
  },
  inputButton: {
    padding: theme.spacing(2, 6),
    fontSize: '1rem'
  },
  textField: {
    '& > div': {
      backgroundColor: theme.palette.common.lightGrey
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  link: {
    textDecoration: 'none'
  },
  linkText: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
  errorText: {
    marginTop: theme.spacing(1),
    color: 'red'
  },
  actionButtonColor: {
    color: theme.palette.common.actionIcon
  },
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    border: theme.border.default,
    backgroundColor: theme.palette.common.white,
    overflowY: 'auto',
    borderRadius: '6px',
    outline: 'none',
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
    maxHeight: '90%',
    maxWidth: '600px',
    boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)'
  },
  modalHeader: {
    padding: theme.spacing(3, 6),
    borderBottom: theme.border.default
  },
  modalContent: {
    padding: theme.spacing(3, 6)
  },
  modalFooter: {
    padding: theme.spacing(3, 6),
    borderTop: theme.border.default,
    textAlign: 'right'
  }
}));
