import { push } from 'connected-react-router';

// Main contextually-current object
export const selectItem = item => dispatch => {
  if (!item) return;
  dispatch({
    type: 'SELECT_ITEM',
    payload: {
      ...item,
      // eslint-disable-next-line no-underscore-dangle
      type: item.type || item.__typename?.toLowerCase(),
    }
  });
};

export const selectLeg = id => ({
  type: 'SELECT_LEG',
  payload: {
    id
  }
});

export const clearSelection = () => ({
  type: 'CLEAR_SELECTION'
});

// Filters/queries used by the Omnibox
export const setTextFilter = value => ({
  type: 'SET_TEXT_FILTER',
  payload: value,
  hide: true
});
export const setActiveQuery = query => ({
  type: 'SET_ACTIVE_QUERY',
  payload: query
});
export const setQueryGroupBy = field => ({
  type: 'SET_QUERY_GROUP_BY',
  payload: field
});
export const setQuerySortBy = field => ({
  type: 'SET_QUERY_SORT_BY',
  payload: field
});
export const setNow = field => ({
  type: 'SET_NOW',
  payload: field
});

export const setOmniboxGroupBy = config => ({
  type: 'SET_OMNIBOX_GROUP_BY',
  payload: config
});

// Clocks displayed in the Status Bar
export const addClock = timezoneCode => ({
  type: 'ADD_CLOCK',
  payload: timezoneCode
});
export const removeClock = timezoneCode => ({
  type: 'REMOVE_CLOCK',
  payload: timezoneCode
});

export const resetEverything = () => dispatch => {
  dispatch({
    type: 'RESET_EVERYTHING'
  });
  dispatch(push('/'));
};

export const redirectToErrorPage = () => dispatch => {
  dispatch({
    type: 'REDIRECT_TO_ERROR_PAGE'
  });
  dispatch(push('/error-page'));
};

const SNACKBAR_TYPES = {
  info: true,
  error: true,
  warning: true,
  success: true
};
export const displaySnackbar = ({
  id,
  text,
  type = 'info',
  ...options
}) => dispatch => {
  if (!id) throw new Error('Cannot create a Snackbar without an ID.');
  if (!SNACKBAR_TYPES[type]) throw new Error(`Unknown Snackbar Type: '${type}'`);

  dispatch({
    type: 'DISPLAY_SNACKBAR',
    payload: {
      id,
      text,
      type,
      ...options
    }
  });
};

export const destroySnackbar = id => ({
  type: 'DESTROY_SNACKBAR',
  payload: {
    id
  }
});

// Disables intensive features if on is set to true
export const setPerformanceMode = on => ({
  type: 'SET_PERFORMANCE_MODE',
  payload: { on }
});
