import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Paper,
  Grid,
  Box,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchAmsConfiguration, setAmsConfiguration, unsetAmsConfiguration } from 'apis/trackstar/serenity';
import mixpanel from 'mixpanel-browser';
import OverriddenAmsConfig from './overriddenAmsConfig-view';
import useStyles from './assetAms-styles';

const AssetAms = ({
  assetId,
  organisationId,
  readOnly,
  displaySnackbar,
}) => {
  const t = useTranslation('pages.assetView');
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [config, setConfig] = useState({
    assetId,
    ofConcernTimeout: 10,
    overdueTimeout: 10,
    stationaryCount: 0,
  });
  const [assetOverride, setAssetOverride] = useState(false);
  useQuery(['assetAmsConfig', assetId], () => fetchAmsConfiguration(assetId), {
    onSuccess: data => {
      setConfig({
        assetId,
        ofConcernTimeout: data.ofConcernTimeout,
        overdueTimeout: data.overdueTimeout,
        stationaryCount: data.stationaryCount,
      });
      setAssetOverride(!!data.assetOverride);
    }
  });

  const doUpdateAmsConfiguration = useMutation(updatedConfig => setAmsConfiguration(updatedConfig), {
    // onMutate: async variables => {},
    onError: (err, variables, context) => {
      displaySnackbar({ id: 'updateAmsError', text: t('amsSettings.updateAmsError'), type: 'error' });
      mixpanel.track('Update AMS Config', { success: false, assetId, organisationId });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'amsUpdated', text: t('amsSettings.amsUpdated'), type: 'success' });
      mixpanel.track('Update AMS Config', { success: true, assetId, organisationId });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['assetAmsConfig', assetId]);
    },
    mutationKey: 'setAmsConfiguration',
  });

  const doUnsetAmsConfiguration = useMutation(() => unsetAmsConfiguration(assetId), {
    onError: (err, variables, context) => {
      displaySnackbar({ id: 'unsetAmsError', text: t('amsSettings.unsetAmsError'), type: 'error' });
      mixpanel.track('Use Org Default AMS Config', { success: false, assetId, organisationId });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'amsUnset', text: t('amsSettings.amsUnset'), type: 'success' });
      mixpanel.track('Use Org Default AMS Config', { success: true, assetId, organisationId });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['assetAmsConfig', assetId]);
    },
  });

  const toggleAssetOverride = () => {
    if (assetOverride) doUnsetAmsConfiguration.mutate();
    setAssetOverride(!assetOverride);
  };

  return (
    <Box id="amsSettings" className={classes.category}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <Typography variant="h5" gutterBottom>{t('amsSettings.title')}</Typography>
          <Typography variant="body1">{t('amsSettings.description')}</Typography>
        </Grid>
        <Grid item sm={12} md={8}>
          <Paper className={classes.panelWrapper}>
            <Box className={classes.amsSettingsPanel}>
              <RadioGroup
                aria-label="assetAmsOverride"
                name="assetAmsOverride"
                value={assetOverride}
                onChange={toggleAssetOverride}
              >
                <FormControlLabel
                  className={clsx(classes.firstLabel, { selected: !assetOverride })}
                  value={false}
                  control={<Radio />}
                  label={t('amsSettings.useOrganisationDefaults')}
                  disabled={readOnly}
                />
                <FormControlLabel
                  className={clsx(classes.secondLabel, { selected: assetOverride })}
                  value // value = true
                  control={<Radio />}
                  label={t('amsSettings.overrideOrganisationDefaults')}
                  disabled={readOnly}
                />
              </RadioGroup>
              {assetOverride && (
                <OverriddenAmsConfig doUpdateAmsConfiguration={doUpdateAmsConfiguration} assetId={assetId} readOnly={readOnly} config={config} />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetAms;
