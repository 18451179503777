import ToGeoJson from 'helpers/togeojson';
import { CompositeLayer } from 'deck.gl';
import { GeoJsonLayer } from '@deck.gl/layers';
import ldb from 'localdata';

const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const unshorthanded = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(unshorthanded);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
};

const getFillColor = data => {
  const rgb = data.properties.fill ? hexToRgb(data.properties.fill) : [0, 150, 80];
  const alpha = data.properties['fill-opacity'] === undefined ? 80 : data.properties['fill-opacity'] * 255;
  return [...rgb, alpha];
};

const getStrokeColor = data => {
  const rgb = data.properties.stroke ? hexToRgb(data.properties.stroke) : [255, 255, 255];
  const alpha = data.properties['stroke-opacity'] === undefined ? 150 : data.properties['stroke-opacity'] * 255;
  return [...rgb, alpha];
};

class KmlLayer extends CompositeLayer {
  updateState({ props, changeFlags }) {
    if (changeFlags.propsChanged) {
      // Calc GeoJSON from KML:
      if (props.kml !== this.state.pastFilename) {
        console.log('getting kml');
        ldb.get(props.kml, kml => {
          const geoJson = ToGeoJson.kml((new DOMParser()).parseFromString(kml, 'text/xml'));
          this.setState({ geoJson, pastFilename: props.kml });
        });
      } else {
        this.setState({ });
      }
    }
  }

  renderLayers() {
    return [
      new GeoJsonLayer({
        id: `kml-layer-${this.props.id}`,
        data: this.state.geoJson,
        stroked: true,
        filled: true,
        pointType: 'circle',
        lineWidthMinPixels: 2,
        getFillColor,
        getLineColor: getStrokeColor,
        getPointRadius: 10,
        pointRadiusMinPixels: 6,
        visible: this.props.visible
      }),
      new GeoJsonLayer({
        id: `kml-label-layer-${this.props.id}`,
        data: this.state.geoJson,
        pointType: 'text',
        getText: f => f.properties.name,
        key: 'kml-label-layer',
        pickable: false,
        filled: false,
        stroked: false,
        visible: this.props.kmlLabels && this.props.visible,
        textFontWeight: 550,
        getTextSize: 12,
        getTextColor: [255, 255, 255, 255],
        getTextAnchor: 'middle',
        getTextAlignmentBaseline: 'bottom',
        getTextPixelOffset: [0, 22],
        parameters: { depthTest: false },
        textFontSettings: { sdf: true, fontSize: 30, radius: 12 },
        textFontFamily: 'objektiv-mk2,sans-serif',
        textBackground: true,
        getTextBackgroundColor: [0, 0, 0, 128],
        textBackgroundPadding: [5, 3, 5, 5],
      })
    ];
  }
}

export default KmlLayer;
