import Smart from 'hocs/smart';
import View from './leg-view';

export default Smart(
  View,
  state => ({
    selectedLeg: state.app.selectedLeg,
    analysisBoxOpen: state.ui.analysisboxOpen,
  }),
  ac => ({
    toggleAnalysisbox: ac.ui.toggleAnalysisbox,
    selectLeg: ac.app.selectLeg,
  })
);
