import Smart from 'hocs/smart';
import TimeSinceView from './timeSince-view';

export default Smart(
  TimeSinceView,
  state => ({
    now: state.app.now,
    language: state.session.language,
    timezone: state.settings.locale.timezone,
  }),
  () => ({})
);
