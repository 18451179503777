import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  swatches: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '3px 0'
  },
  new: {
    marginTop: '2px',
    height: '15px',
    width: '50px',
    border: theme.border.default,
    borderRadius: '3px'
  },
  current: {
    marginTop: '2px',
    height: '15px',
    width: '50px',
    border: theme.border.default,
    borderRadius: '3px'
  },
  label: {
    position: 'relative',
    padding: '2px 5px 5px 8px',
    fontSize: '12px',
    fontWeight: '400',
    textAlign: 'center',
    color: theme.palette.common.black
  }
}));
