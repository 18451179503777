import { fetchOptions } from 'helpers/api';
import { getRefreshedToken } from 'apis/auth';

const BASE_URL = `${import.meta.env.VITE_SERENITY_REST_URL}`;

interface AssignDeviceToAssetParams {
  organisationId: string
  assetId: number
  deviceId: number
}

export const assignDeviceToAsset = async ({ organisationId, assetId, deviceId }:AssignDeviceToAssetParams): Promise<Response> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const response = await fetch(`${BASE_URL}/organisations/${organisationId}/assets/${assetId}/device/`, {
    method: 'POST',
    ...fetchOptions,
    headers,
    body: JSON.stringify({
      // eslint-disable-next-line quote-props
      'deviceId': deviceId
    })
  } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response.json();
};

interface RemoveDeviceFromAssetParams {
  organisationId: string
  assetId: number
  deviceId: number
}

export const removeDeviceFromAsset = async ({ organisationId, assetId, deviceId }:RemoveDeviceFromAssetParams): Promise<Response> => {
  const token = await getRefreshedToken();
  const headers = {
    authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  if (!token) {
    return new Promise(() => ({ success: false, error: 'Credentials are invalid.', description: '' } as Result));
  }
  const response = await fetch(`${BASE_URL}/organisations/${organisationId}/assets/${assetId}/device/${deviceId}/`, {
    method: 'DELETE',
    ...fetchOptions,
    headers
  } as RequestInit);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response;
};
