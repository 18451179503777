import React from 'react';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { List } from '@mui/material';
import { SortableItem } from './sortableItem';

interface DragAndDropListProps {
  items: string[]
  itemMap: (item: string) => JSX.Element;
  onReorder: (callback: (items: string[]) => string[]) => void;
  secondaryActionMap?: (item: string) => JSX.Element;
}

const DragAndDropList = ({
  items,
  itemMap,
  onReorder,
  secondaryActionMap
}: DragAndDropListProps): JSX.Element => {
  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (active.id !== over?.id && over) {
      onReorder(newItems => {
        const oldIndex = newItems.indexOf(active.id.toString());
        const newIndex = newItems.indexOf(over.id.toString());

        return arrayMove(newItems, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <List>
        <SortableContext items={items}>
          {items.map(item => (
            <SortableItem
              id={item}
              item={item}
              itemMap={itemMap}
              secondaryActionMap={secondaryActionMap}
            />
          ))}
        </SortableContext>
      </List>
    </DndContext>
  );
};

export default DragAndDropList;
