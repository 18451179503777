import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import Modal from 'react-modal';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'react-query-client';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

/* to initialise it on startup */
import { app, db } from 'firebase';

/* polyfill for edge */
import flatMap from 'array.prototype.flatmap';

import ErrorBoundary from './components/shared/errorBoundary';
import App from './components/app';
import { initOptions, i18nPreInit } from './i18n';
import 'react-virtualized/styles.css';
import './index.css';
import { store, persistor, history } from './store';

if (!Array.prototype.flatMap) {
  // shim flatmap for Edge
  delete Array.prototype.flatMap;
  flatMap.shim();
}

const INTERCOM_APP_ID = 'u9wrr5iy';

Modal.setAppElement('#root');

// store.dispatch(initApp()); // this ensures the user and users orgs are correctly loaded after page refresh
i18nPreInit.init(initOptions, err => {
  if (err) console.error('Failed to initialise i18n:', err);
  ReactDOM.render(
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary organisationId={null}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <PersistGate persistor={persistor}>
                  <Route component={App} />
                </PersistGate>
              </LocalizationProvider>
            </ConnectedRouter>
          </Provider>
        </ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </IntercomProvider>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
