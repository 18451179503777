import React from 'react';
import { Box } from '@mui/material';
import * as tagHelper from 'helpers/tags';
import DistressNotifications from './distress';
import OverdueNotifications from './overdue';
import useStyles from './alert-styles';

const filterAssetsByState = (assets, states) => {
  const inDistress = [];
  const overdue = [];
  const ofConcern = [];

  assets.forEach(asset => {
    const assetState = states[asset.id];
    if (!assetState) return;
    if (tagHelper.isInDistress(assetState)) inDistress.push(asset);
    if (tagHelper.isOverdue(assetState)) overdue.push(asset);
    if (tagHelper.isOfConcern) ofConcern.push(asset);
  });

  return {
    inDistress,
    overdue,
    ofConcern
  };
};

const HeaderAlert = ({
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // assetState,
  selectItem,
  navigate,
  organisationId,
  assignItemToMap,
  selectedMapId
}) => {
  const classes = useStyles();
  // TODO: pull assets from trackstar
  // assets (orgId: $orgId) {
  //   id
  //   name
  //   tagsAt (at: $now) {
  //     type
  //     value
  //   }
  //   paginatedPositions (offset: 0, limit: 1) {
  //     latitude
  //     longitude
  //   }
  // }
  const assets = [];

  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // const { inDistress, overdue /* , ofConcern */ } = filterAssetsByState(assets, assetState);

  const selectAsset = asset => {
    selectItem(asset);
    assignItemToMap(selectedMapId, { organisationId, ...asset });
  };

  return (
    <Box className={classes.wrapper}>
      {
        // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
        // <DistressNotifications inDistress={inDistress} selectItem={selectAsset} navigate={navigate} />
        // <OverdueNotifications overdue={overdue} selectItem={selectAsset} navigate={navigate} />
        // TODO: OfConcern (orange?)
      }
    </Box>
  );
};

export default HeaderAlert;
