import React from 'react';
import {
  AppBar,
  Toolbar
} from '@mui/material';
import { Link } from 'react-router-dom';
import TracplusIcon from 'components/shared/icons/tracplusIcon';
import UserPocket from './modules/userPocket';
import Alerts from './modules/alert';
import OrganisationSelector from './modules/organisationSelector';
import Feedback from './modules/feedback';
import useStyles from './header-styles';

const Header = ({
  user,
  organisationId
}) => {
  const classes = useStyles();

  if (!user) return null;

  return (
    <AppBar className={classes.appbar}>
      <Toolbar className={classes.toolbar} disableGutters variant="dense">
        <Link to="/">
          <TracplusIcon />
        </Link>
        <OrganisationSelector />
        {/* <Alerts organisationId={organisationId} /> */}
        {/* <Feedback organisationId={organisationId} /> */}
        <UserPocket user={user} />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
