import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  analysisboxWrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    // transition: 'all 0.15s',
    bottom: 0,
    width: 'calc(100vw - 43px)',
    height: 400,
    zIndex: 2,
    backgroundColor: theme.palette.background.overlay,
  },
  resizer: {
    height: 7,
    width: '100%',
    cursor: 'ns-resize',
    padding: '4px 0 0',
    position: 'absolute',
    zIndex: '100',
    transition: 'all 0.15s',
    backgroundColor: theme.palette.background.overlay,
    '&:hover': {
      backgroundColor: theme.palette.primary.blue,
    },
  },
  isDragging: {
    backgroundColor: theme.palette.primary.blue,
  },
  icon: {
    transition: 'all 0.05s',
    transform: 'rotate(90deg)'
  },
  iconReversed: {
    transform: 'rotate(270deg)'
  },
  collapsed: {
    height: 35,
  },
  collapseButtonWrapper: {
    margin: '0 auto',
    padding: '0 100px 18px 100px',
    position: 'relative',
    background: theme.palette.background.overlay,
  },
  collapseButton: {
    position: 'absolute',
    width: 150,
    height: 30,
    margin: '-10px auto 0 auto',
    paddingTop: 6,
    paddingBottom: 4,
    paddingRight: 16,
    paddingLeft: 16,
    top: -20,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,.4)',
    zIndex: -1,
    transition: 'all 0.05s',
    transform: 'translateY(8px) translateX(-75px)',
    '&:hover': {
      transform: 'translateY(0px) translateX(-75px)',
    },
    '& .MuiTypography-root': {
      display: 'inline',
      padding: '0 5px 10px 0',
      color: '#fff',
      position: 'relative',
      top: -5,
    },
  },
  analysisbox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    boxShadow: 'rgba(0, 0, 0, 0.1) -12px -12px 12px -12px',
    zIndex: 4,
    padding: '0 20px',
    margin: '0 300px 0 300px',
  },
  assetTitle: {
    alignSelf: 'center',
  },
  exportButton: {
    position: 'relative',
    right: 0,
    marginBottom: 7,
    backgroundColor: theme.palette.primary.main,
    height: '3rem',
    padding: '16px 20px 16px 16px',
    borderRadius: 24,
    lineHeight: '3rem',
    '& .MuiSvgIcon-root': {
      marginRight: 4,
      fontSize: '1.6rem'
    },
    '& .buttonText': {
      lineHeight: '3rem',
      position: 'relative',
      bottom: 1
    },
  },
  tableContainer: {
    marginTop: 0,
    textAlign: 'left',
    display: 'grid',
    gridTemplateColumns: '1fr 160px 160px 100px',
  },
  autosizerWrapper: {
    flex: '1 1 auto',
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.common.midGrey}`,
    borderRadius: 4,
    gridColumn: '1 / 5',
  },
  reportsTable: {
    fontSize: '0.95rem',
    color: theme.palette.text.primary,
    '& .ReactVirtualized__Table__headerRow': {
      textAlign: 'left',
      textTransform: 'Capitalize',
      borderBottom: `1px solid ${theme.palette.common.midGrey}`,
      boxSizing: 'border-box',
      cursor: 'pointer',
    },
    '& .ReactVirtualized__Table__headerColumn': {
      padding: '16px 0 16px 14px',
      margin: 0,
      lineHeight: 1,
      outline: 'none',
    },
    '& .ReactVirtualized__Table__headerTruncatedText': {
      padding: '5px 0',
    },
    '& .ReactVirtualized__Grid__innerScrollContainer:focus, .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
    '& .ReactVirtualized__Table__Grid': {
      borderRight: `2px solid ${theme.palette.common.midGrey}`,
    },
    '& .ReactVirtualized__Table__row': {
      textAlign: 'left',
      borderBottom: `1px solid ${theme.palette.common.midGrey}`,
      transition: 'all 300ms ease 0s',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      '&:focus': {
        outline: 'none',
      }
    },
    '& .ReactVirtualized__Table__rowColumn': {
      padding: '12px 0 12px 14px',
      margin: 0,
      lineHeight: 1,
    },
    '& .ReactVirtualized__Table__sortableHeaderIcon': {
      color: theme.palette.common.text,
      fontSize: 18,
      transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      marginLeft: 4,
      userSelect: 'none',
      marginRight: 4,
      position: 'relative',
      top: '-2px',
    },
    '& .ReactVirtualized__Table__sortableHeaderIcon--ASC': {
      transform: 'rotate(180deg)',
    },
    '& .ReactVirtualized__Table__sortableHeaderIcon--DESC': {
      transform: 'rotate(0deg)',
    },
  },
  focusedRow: {
    backgroundColor: theme.palette.common.midGrey,
    '&:hover': {
      backgroundColor: `${theme.palette.common.midGrey} !important`,
    }
  },
  loading: {
    height: 360,
  },
  exampleWrapper: {
  },
  speedDial: {
    '& .MuiFab-root': {
      height: 39,
      width: 39,
    },
    '& .MuiSpeedDialAction-fab': {
      display: 'none',
    },
    '&.MuiSpeedDial-root': {
      position: 'absolute',
      zIndex: 1,
      marginRight: 20,
    },
    '&.MuiSpeedDial-directionDown .MuiSpeedDial-actions': {
      marginTop: 0,
      paddingTop: 0,
    },
    '& .MuiSpeedDialAction-staticTooltipLabel': {
      position: 'relative',
      right: 'auto',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: '0.875rem',
      fontWeight: '500',
      marginTop: 10,
      padding: 10,
      marginRight: 0,
      textAlign: 'center',
      '&:hover': {
        backgroundColor: theme.palette.common.text,
      },
      cursor: 'pointer',
      width: '100%',
    }
    // position: 'absolute',
    // '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    //   bottom: theme.spacing(2),
    //   right: theme.spacing(2),
    // },
    // '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    //   top: theme.spacing(2),
    //   left: theme.spacing(2),
    // },
  },
}));
