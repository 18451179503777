import Smart from 'hocs/smart';
import DistressView from './distressDialog-view';

export default Smart(
  DistressView,
  (state: any) => ({
    altitudeUnit: state.settings.units.altitude,
    speedUnit: state.settings.units.speed,
    bearingUnit: state.settings.units.bearing,
    organisationId: state.session.organisationId,
    timezone: state.settings.locale.timezone,
  }),
  (ac: any) => ({
    fetchDistressIncident: ac.asset.fetchDistressIncident,
  })
);
