import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import DistressDialog from './distressDialog';
import useStyles from './distress-styles';

const ACBDistress = ({
  isInDistress,
  latestReport,
  incident,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const t = useTranslation('contextbox.asset.distress');

  return (
    <Box className={classes.boxContainer}>
      {isInDistress && (
        <Card className={classes.cardContainer}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" className={classes.cardTitle}>{t('title')}</Typography>
            <CardActions className={classes.cardAction}>
              <Button className={classes.distressModalButton} onClick={() => setOpen(true)}>{t('viewDistressDetails')}</Button>
              <DistressDialog open={open} setOpen={setOpen} latestReport={latestReport} incident={incident} />
            </CardActions>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default ACBDistress;
