import React, { useState } from 'react';

import {
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  LinearProgress,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@mui/material';
import { Collections as CollectionsIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { UploadAssetImage, RemoveAssetImage } from 'apis/assetImages';
import clsx from 'clsx';
import { ClassNameMap } from '@mui/styles';
import { TFunction } from 'react-i18next';
import ReactCropper from 'react-cropper';
import 'cropperjs/dist/cropper.min.css'; // force import because react-cropper didn't like to?
import NoHeaderNoFooterLoadingView from '../loading/noHeaderNoFooterLoading-view';

const UploadImageButton = ({ text, classes }:{text:string; classes: ClassNameMap}):JSX.Element => (
  <Button
    variant="contained"
    startIcon={<CollectionsIcon />}
    disableRipple
    classes={{
      root: classes.uploadImageButton,
      contained: classes.uploadImageButton
    }}
    component="label"
    htmlFor="assetImageUpload"
  >
    {text}
  </Button>
);

const RemoveImageButton = ({ text, classes, onClick }:{text:string; classes: ClassNameMap, onClick: () => void}):JSX.Element => (
  <Button
    variant="contained"
    startIcon={<DeleteIcon />}
    disableRipple
    classes={{
      root: classes.removeImageButton,
      contained: classes.removeImageButton
    }}
    component="label"
    onClick={() => onClick()}
  >
    {text}
  </Button>
);

interface AssetImageProps {
  classes: ClassNameMap,
  t: TFunction<'common'>,
  imageUrl: string | undefined | null,
  assetId: number,
  isLoading: boolean,
  canEditAsset: boolean,
  onUpload: (success:boolean) => void,
  onRemove: (success: boolean) => Promise<void>
}

const AssetImage = ({
  classes,
  t,
  imageUrl,
  isLoading,
  assetId,
  canEditAsset,
  onUpload,
  onRemove
}:AssetImageProps): JSX.Element => {
  const [uploading, setUploading] = useState(false);
  const [cropping, setCropping] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageDataUrl, setImageDataUrl] = useState<string>();
  const [cropper, setCropper] = useState<Cropper>();

  const ASPECT_RATIO = 300 / 160;

  const upload = (blob: Blob | null): void => {
    if (!canEditAsset) { return; }
    if (!blob) { onUpload(false); return; }
    setProgress(0);
    const uploadTask = UploadAssetImage(assetId, blob);
    uploadTask.on('state_changed', snapshot => {
      setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      if (snapshot.state === 'running' && !uploading) {
        setUploading(true);
      }
    },
    () => {
      onUpload(false);
    },
    () => {
      setUploading(false);
      onUpload(true);
    });
  };

  const cropAndUpload = (): void => {
    setCropping(false);
    cropper?.getCroppedCanvas().toBlob(upload, 'image/jpeg', 0.8);
  };

  const remove = async (): Promise<void> => {
    setRemoving(true);
    await RemoveAssetImage(assetId)
      .then(() => onRemove(true))
      .catch(() => onRemove(false));
    setRemoving(false);
  };

  const handleAssetFileChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.files?.length) {
      // if (event.target.files[0].size > 10000000) {
      // onUpload(false);
      // return false;
      // }
      // upload(event.target.files[0]);
      const fr = new FileReader();
      fr.onload = () => {
        setImageDataUrl(fr.result?.toString());
      };
      fr.readAsDataURL(event.target.files[0]);
      setCropping(true);
    }
    return true;
  };

  return (
    <Box id="image" className={classes.category}>
      <input type="file" accept={'image/*'} hidden id="assetImageUpload" onChange={handleAssetFileChange} disabled={!canEditAsset} />
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <Typography variant="h5" gutterBottom>{t('imageTitle')}</Typography>
          <Typography variant="body1">{t('imageDescription')}</Typography>
        </Grid>
        <Grid item sm={12} md={8}>
          <Card className={classes.imageCard}>
            <Box className={clsx(classes.imagePanel)}>
              {(isLoading || removing)
                ? (
                  <CardContent className={clsx(classes.imageIconLoading, classes.imageIcon)}>
                    <NoHeaderNoFooterLoadingView />
                  </CardContent>
                )
                : (imageUrl ? (
                  <CardMedia
                    className={clsx(classes.media, classes.imageIcon)}
                    image={imageUrl}
                  >
                    {canEditAsset && (
                    <>
                      <UploadImageButton text={t('replaceImage')} classes={classes} />
                      <RemoveImageButton text={t('removeImage')} classes={classes} onClick={remove} />
                    </>
                    )}
                  </CardMedia>
                ) : (
                  <CardContent className={classes.imageIcon}>
                    {canEditAsset ? <UploadImageButton text={t('uploadImage')} classes={classes} /> : <CollectionsIcon /> }
                  </CardContent>
                )
                )}
            </Box>
            {uploading
              && <LinearProgress variant="determinate" color="primary" value={progress} />}
          </Card>
        </Grid>
      </Grid>
      <Dialog open={cropping}>
        <DialogTitle>{t('cropImageDialogTitle')}</DialogTitle>
        <DialogContent className={classes.cropDialog}>
          {imageDataUrl
            && (
            <ReactCropper
              aspectRatio={ASPECT_RATIO}
              src={imageDataUrl}
              viewMode={2}
              onInitialized={setCropper}
              autoCrop
              cropBoxResizable
              cropBoxMovable
              dragMode="move"
            />
            )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setCropping(false)}>
            {t('cancelButton')}
          </Button>
          <Button variant="contained" onClick={cropAndUpload} color="primary">
            {t('uploadImage')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AssetImage;
