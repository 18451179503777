import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { bearing as uomBearing, speed as uomSpeed } from 'helpers/unitsOfMeasure';

// This hook takes a position and returns the course and speed as a string
// formatted using the units.bearing and units.speed settings from the Settings
// reducer. Updates if the position, units.bearing or units.speed settings change.

const formatBearingAtSpeed = (position, bearingUnits, speedUnits) => {
  if (!position?.timeOfFix) return '--';

  const {
    timeOfFix,
    course = 0,
    speed = 0
  } = position;

  const courseInLocale = uomBearing.fromSI(course, new Date(timeOfFix), position, bearingUnits);
  const courseUnitsLabel = uomBearing.label(bearingUnits, timeOfFix);

  const speedInLocale = uomSpeed.fromKmh(speed, speedUnits);
  const speedUnitsLabel = uomSpeed.label(speedUnits);

  return `${courseInLocale.toFixed(0)} ${courseUnitsLabel} @ ${speedInLocale.toFixed(0)} ${speedUnitsLabel}`;
};

export default position => {
  const bearingUnits = useSelector(state => state.settings.units.bearing);
  const speedUnits = useSelector(state => state.settings.units.speed);

  const [formattedString, setFormattedString] = useState(formatBearingAtSpeed(position, bearingUnits, speedUnits));

  useEffect(() => {
    setFormattedString(formatBearingAtSpeed(position, bearingUnits, speedUnits));
  }, [position, bearingUnits, speedUnits]);

  return formattedString;
};
