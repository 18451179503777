import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import LatLon from 'geodesy/latlon-spherical';

// This hook takes a lat/lng and returns it as a string formatted
// using the units.coordinate setting from the Settings reducer.
// Updates if the lat, lng or units.coordinate setting change.

// Map from our internal format identifier to that provided by geodesy
const coordinateFormatMap = {
  coordinatesDD: 'n',
  coordinatesDMS: 'dms',
  coordinatesDDM: 'dm'
};

const formatCoordinates = (lat, lng, format) => {
  if (!lat || !lng) return '--';
  const geodesyFormatCode = coordinateFormatMap[format];
  return new LatLon(lat, lng).toString(geodesyFormatCode);
};

export default (lat, lng) => {
  const coordFormat = useSelector(state => state.settings.units.coordinate);
  const [formattedString, setFormattedString] = useState(formatCoordinates(lat, lng, coordFormat));

  useEffect(() => {
    setFormattedString(formatCoordinates(lat, lng, coordFormat));
  }, [lat, lng, coordFormat]);

  return formattedString;
};
