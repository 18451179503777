import React from 'react';
import {
  FormControlLabel,
  Checkbox
} from '@mui/material';

import useStyles from './input-styles';

const BoolInput = ({
  id,
  label,
  onChange,
  value,
  disabled,
  readOnly,
  ...other
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.boolField}
      control={(
        <Checkbox
          checked={value}
          onChange={e => onChange(id, e.target.checked)}
          disabled={disabled || readOnly}
        />
      )}
      label={label}
      {...other}
    />
  );
};

export default BoolInput;
