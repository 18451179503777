/* eslint-disable no-param-reassign */
import { PathLayer } from '@deck.gl/layers';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createIterable } from '@deck.gl/core';
import {
  vsDecl, vsMain, fsDecl, fsMainEnd
} from './shaders/ant-shader.glsl';

// This file will need to be updated if/when we move to DeckGL v8 due to breaking changes in the API

const defaultProps = {
  getTotalPositions: { type: 'accessor', value: null },
  dashScale: 10,
  animationSpeed: 10,
  animate: false
};

export default class AntTrailLayer extends PathLayer {
  initializeState(params) {
    super.initializeState(params);
    const attributeManager = this.getAttributeManager();
    attributeManager.addInstanced({
      totalPositions: {
        size: 2,
        update: this.calculateInstanceTotalPositions
      }
    });
  }

  draw({ uniforms }) {
    super.draw({
      uniforms:
      {
        ...uniforms,
        time: this.context.timeline.time,
        dashScale: this.props.dashScale,
        animationSpeed: this.props.animationSpeed,
        animate: this.props.animate
      }
    });
  }

  getShaders() {
    const shaders = super.getShaders();
    shaders.inject = {
      'vs:#decl': vsDecl,
      'vs:#main-end': vsMain,
      'fs:#decl': fsDecl,
      'fs:#main-end': fsMainEnd
    };

    return shaders;
  }

  calculateInstanceTotalPositions(attribute, { startRow, endRow }) {
    const { data, getTotalPositions } = this.props;

    if (!getTotalPositions) {
      attribute.constant = true;
      attribute.value = new Float32Array(2);
      return;
    }

    const { startIndices, numInstances } = this.state;
    // Converts to the old bufferLayout format to get around a deck.gl update without major changes
    // TODO can we make use of the new start index, instead of segment width format?
    const bufferLayout = [];
    for (let i = 0; i < startIndices.length; i++) {
      if (i < startIndices.length - 1) {
        bufferLayout.push(startIndices[i + 1] - startIndices[i]);
      } else {
        bufferLayout.push(numInstances - startIndices[i]);
      }
    }
    const { iterable, objectInfo } = createIterable(data, startRow, endRow);
    let i = 0;

    for (let objectIndex = 0; objectIndex < startRow; objectIndex++) {
      i += bufferLayout[objectIndex] * 2;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const object of iterable) {
      objectInfo.index++;

      const geometrySize = bufferLayout[objectInfo.index];
      const totalPositions = getTotalPositions(object, objectInfo);
      // For each line segment we have [startTotalPosition, endTotalPosition]
      for (let j = 0; j < geometrySize; j++) {
        attribute.value[i++] = totalPositions[j];
        attribute.value[i++] = totalPositions[j + 1];
      }
    }

    attribute.bufferLayout = bufferLayout;
    attribute.constant = false;
  }
}

AntTrailLayer.layerName = 'AntTrailLayer';
AntTrailLayer.defaultProps = defaultProps;
