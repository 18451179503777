import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography, Button, Divider, Paper, Tooltip,
  Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';
import Modal from 'react-modal';
import useTranslation from 'hooks/useTranslation';
import TextInput from 'components/shared/forms/inputs/text';
import { validate as validateEmail } from 'helpers/email';
import { PersonAddDisabled } from '@mui/icons-material';
import ConfirmDialog from 'components/shared/confirmDialog';
import { changeDisplayName, changeEmail, sendPasswordResetEmail } from 'apis/auth';
import { doc } from '@firebase/firestore';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';
import { db } from 'firebase';

const OrgRoleLabel = ({ orgId, uid, allRoles }) => {
  const membershipRef = doc(db, `organisations/${orgId}/members/${uid}`);
  const membership = useFirestoreDocumentData(['membership', orgId], membershipRef);
  if (membership.data) {
    const role = allRoles.find(r => r.id === membership.data.roles[0].path);
    if (role) return <>{role.label}</>;
  }
  if (membership.error) {
    return <>{membership.error.message}</>;
  }
  return <>...</>;
};

const UserSettings = ({
  user,
  classes,
  setOrganisation,
  navigate,
  displaySnackbar,
  leaveOrganisation,
  organisationId,
  isStaff,
  updateUser,
}) => {
  const t = useTranslation('pages.accountSettings');
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [currentPassword, setCurrentPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [credentialOpen, setCredentialOpen] = useState(false);
  const [formValuesAreValid, setFormValuesAreValid] = useState(false);
  const initialDialogState = {
    open: false,
    title: '',
    message: '',
    onConfirm: null
  };
  const [dialog, setDialog] = useState({ ...initialDialogState });

  useEffect(() => {
    setEmailError(validateEmail(email));
    setFormValuesAreValid(name.trim() && !validateEmail(email));
  }, [name, email]);

  const onSubmit = () => {
    const prevUser = { name: user.name, email: user.email };
    if (currentPassword.length === 0) {
      // Re-authentication is required if they want to change their email
      // Firebase making the rules...
      setCredentialOpen(true);
      return;
    }
    setCredentialOpen(false);
    updateUser(name, email);
    const updateEmail = changeEmail(email, user.email, currentPassword);
    const updateName = changeDisplayName(name);
    Promise.all([updateEmail, updateName])
      .then(() => displaySnackbar({
        id: 'savedUserSuccess',
        type: 'success',
        text: `${t('savedChanges')}`,
      }))
      .catch(err => {
        const errorText = err?.message?.search('auth/email-already-in-use') > 0
          ? t('emailInUse')
          : err?.message?.search('auth/wrong-password') > 0
            ? t('wrongPassword')
            : t('failedToSaveUser');
        displaySnackbar({
          id: 'savedUserError',
          type: 'error',
          text: errorText,
        });
        updateUser(prevUser.name, prevUser.email);
        console.error(err);
      });
  };

  const handleResetSubmit = async () => {
    if (email) {
      sendPasswordResetEmail(email)
        .then(() => displaySnackbar({
          id: 'newPasswordSuccess',
          type: 'success',
          text: `${t('newPasswordSuccess')} ${email}`
        }))
        .catch(() => displaySnackbar({
          id: 'newPasswordError',
          type: 'error',
          text: t('newPasswordError')
        }));
    } else {
      displaySnackbar({
        id: 'newPasswordError',
        type: 'error',
        text: t('newPasswordError')
      });
    }
  };

  const onTableRowClick = orgId => {
    setOrganisation(orgId);
    navigate('/settings/organisation');
  };

  return (
    <>
      <Box id="userProfile" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" gutterBottom>{t('userTitle')}</Typography>
            <Typography variant="body1" gutterBottom>{t('userDescription')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Paper className={classes.fieldsPanel}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextInput label={t('nameLabel')} value={name} onChange={(id, nameValue) => setName(nameValue)} hasError={!name.trim()} />
                  {!name.trim() && <Typography className={classes.errorText}>{t('nameIsRequired')}</Typography>}
                </Grid>
                <Grid item xs={12}>
                  <TextInput label={t('emailLabel')} value={email} onChange={(id, emailValue) => setEmail(emailValue)} hasError={!!emailError} />
                  {emailError && <Typography className={classes.errorText}>{t(emailError)}</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button className={classes.inputButton} variant="contained" onClick={onSubmit} disabled={!formValuesAreValid}>{t('userButton')}</Button>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.alignRight}>
                  <Button className={classes.forgotPasswordButton} variant="contained" color="secondary" onClick={handleResetSubmit} disabled={!!emailError}>{t('newPassword')}</Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Divider className={classes.divider} />
      <Box id="organisationDetails" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" gutterBottom>{t('orgTitle')}</Typography>
            <Typography variant="body1" gutterBottom>{t('orgDescription')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Paper className={classes.panel}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('orgTableName')}</TableCell>
                    <TableCell align={isStaff ? 'left' : 'right'}>{t('orgTableRole')}</TableCell>
                    {isStaff && (<TableCell align="center">{t('leave')}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.memberOf.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map(org => (
                    <TableRow key={org.id} className={classes.tableRow}>
                      <TableCell scope="row" onClick={() => onTableRowClick(org.id)}>{org.name}</TableCell>
                      <TableCell align={isStaff ? 'left' : 'right'} onClick={() => onTableRowClick(org.id)}>
                        <OrgRoleLabel orgId={org.id} uid={user.id} allRoles={user.allRoles} />
                      </TableCell>
                      <TableCell align="center">
                        {org.id !== organisationId && (
                          <Tooltip title={t('leaveOrg', { orgName: org.name })}>
                            <PersonAddDisabled
                              className={classes.actionButtonColor}
                              onClick={() => setDialog({
                                ...dialog,
                                open: true,
                                title: t('leaveOrg', { orgName: org.name }),
                                message: t('leaveOrgConfirm', { orgName: org.name }),
                                confirmFunc: () => leaveOrganisation(org)
                              })}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <ConfirmDialog
                okButtonText={t('leave')}
                cancelButtonText={t('cancel')}
                onConfirm={dialog.confirmFunc}
                open={dialog.open}
                setOpen={value => setDialog({ ...dialog, open: value })}
                title={dialog.title}
                message={dialog.message}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Modal
        isOpen={credentialOpen}
        onRequestClose={() => {
          setCredentialOpen(false);
          setCurrentPassword('');
        }}
        className={classes.modalContainer}
        overlayClassName={classes.modalOverlay}
        aria-labelledby="userCredentialHeader"
      >
        <>
          <Box className={classes.modalHeader} id="userCredentialHeader">
            <Typography variant="h2">{t('reauthenticateHeader')}</Typography>
          </Box>

          <Box className={classes.modalContent}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInput label={t('emailLabel')} value={user.email} disabled />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t('passwordLabel')}
                  type="password"
                  value={currentPassword}
                  onChange={(_, v) => setCurrentPassword(v)}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.modalFooter}>
            <Button
              onClick={() => {
                setCredentialOpen(false);
                setCurrentPassword('');
              }}
              tabIndex={-1}
            >
              {t('cancel')}
            </Button>
            <Button
              color="primary"
              onClick={onSubmit}
              className={classes.inputButton}
              variant="contained"
              disabled={currentPassword.length === 0}
            >
              {t('userButton')}
            </Button>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default UserSettings;
