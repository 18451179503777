import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  item: {
    padding: theme.spacing(2, 0)
  },
  iconWrapper: {
    minWidth: '32px'
  }
}));
