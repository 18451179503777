import React from 'react';
import BoolField from '../inputs/bool';
import Select from '../inputs/muiSelect';
import TextField from '../inputs/text';
import SliderField from '../inputs/slider';
import ColorPicker from '../inputs/color';
import AutoComplete from '../inputs/autocomplete';

const FormField = props => {
  switch (props.type) {
    case 'bool': return <BoolField {...props} />;
    case 'select': return <Select {...props} />;
    case 'number': return <TextField {...props} type="number" />;
    case 'text': return <TextField {...props} />;
    case 'slider': return <SliderField {...props} />;
    case 'color': return <ColorPicker {...props} />;
    case 'autocomplete': return <AutoComplete {...props} />;
    default: return <input {...props} />;
  }
};

export default FormField;
