import React, { useState } from 'react';
import {
  Paper, Grid, Button, Select, MenuItem
} from '@mui/material';
import { inviteUser } from 'apis/auth';
import useTranslation from 'hooks/useTranslation';
import { isValidEmail } from 'utils/strings';
import TextInput from 'components/shared/forms/inputs/text';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getMembers } from 'apis/trackstar/serenity';
import Lottie from 'react-lottie-player';
import * as animationData from 'components/pages/loading/loader.json';
import mixpanel from 'mixpanel-browser';

const UserInvitationView = ({
  user,
  organisation,
  orgId,
  displaySnackbar,
  classes,
}) => {
  const t = useTranslation('pages.organisationSettings');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [selectedRole, setSelectedRole] = useState(user.allRoles.find(r => r.label === 'View Only')?.id); // default to 'view only' role
  const queryClient = useQueryClient();
  const orgMembers = useQuery(['organisationMembers', organisation.id], () => getMembers());

  //
  // Invite User
  //
  const doInviteUser = useMutation(inviteUser, {
    onMutate: async variables => {
      // Optimistic update
      await queryClient.cancelQueries(['organisationMembers', variables.orgId]);
      const previousMembers = queryClient.getQueryData(['organisationMembers', variables.orgId]);
      queryClient.setQueryData(
        ['organisationMembers', variables.orgId],
        old => ({ ...old, pendingMembers: [...old.pendingMembers, { name: variables.name, email: variables.email }] })
      );
      return { previousMembers };
    },
    onError: (err, variables, context) => {
      // Roll back optimistic update if invite fails
      queryClient.setQueryData(['organisationMembers', variables.orgId], context?.previousMembers);
      displaySnackbar({ id: 'inviteSendFailed', text: t('inviteSendFailed'), type: 'error' });
      mixpanel.track('Invite User', { success: false, orgId });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'inviteSent', text: t('inviteSent'), type: 'success' });
      mixpanel.track('Invite User', { success: true, orgId });
    },
    onSettled: () => {
      // Fetch latest members on error or success
      queryClient.invalidateQueries(['organisationMembers', organisation.id]);
    },
    mutationKey: 'inviteUser',
  });

  const isInvitedUserExisted = orgMembers.isSuccess && orgMembers.data.members.some(m => m.email?.toLowerCase() === email);
  const isInvitedUserStillPending = orgMembers.isSuccess && orgMembers.data.pendingMembers.some(m => m.email?.toLowerCase() === email);

  const showUserExistedHelperText = isInvitedUserExisted ? t('userExistedHelperText') : '';
  const showUserPendingHelperText = isInvitedUserStillPending ? t('userPendingHelperText') : '';

  const emailValid = isValidEmail(email);
  const isValid = emailValid && name && !isInvitedUserExisted && !isInvitedUserStillPending;

  const sendUserInvite = () => {
    doInviteUser.mutate({
      orgId,
      name,
      email,
      role: selectedRole,
      organisationName: organisation.name,
    });
    setName('');
    setEmail('');
  };

  return (
    <Paper className={classes.formPanel}>
      <Grid container spacing={3}>
        <Grid item sm={5}>
          <TextInput
            id="email"
            label={t('emailLabel')}
            type="email"
            value={email.toLowerCase()}
            onChange={(field, value) => setEmail(value.toLowerCase())}
            hasError={isInvitedUserExisted || isInvitedUserStillPending}
            helperText={showUserExistedHelperText || showUserPendingHelperText}
          />
        </Grid>
        <Grid item sm={4}>
          <TextInput
            id="name"
            label={t('nameLabel')}
            value={name}
            onChange={(field, value) => setName(value)}
          />
        </Grid>
        <Grid item sm={3}>
          <Select
            id={organisation.id}
            value={selectedRole}
            onChange={e => setSelectedRole(e.target.value)}
            className={classes.select}
            variant="outlined"
          >
            {user.allRoles.map(r => <MenuItem key={r.id} value={r.id}>{r.label}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item sm={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.inputButton}
            disabled={!isValid}
            onClick={sendUserInvite}
          >
            {doInviteUser.isLoading ? (
              <Lottie
                loop
                animationData={animationData}
                play
                style={{ width: 30, height: 30 }}
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
              />
            ) : (
              t('inviteButton')
            )}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserInvitationView;
