const HOSTNAME = window?.location?.hostname;

const getEnvironment = (): string => {
  // Prefer production behaviour if unsure. It's more likely to be consistent
  if (HOSTNAME !== 'localhost') return 'production';
  return 'development';
};

export const getApiUrl = (api: string, protocol = 'http'): string => {
  switch (getEnvironment()) {
    case 'production':
      if (protocol === 'ws') return `wss://${HOSTNAME}/api/${api}`;
      return `/api/${api}`;
    default:
      if (api === 'graphql' && protocol === 'ws') return 'ws://localhost:3000/api/graphql';
      if (protocol === 'ws') return `ws://localhost:3000/api/${api}`;
      return `/api/${api}`;
  }
};

interface Headers {
  authorization?: string,
}
export const getHeaders = (): Headers => {
  const token = localStorage.getItem('bearerToken');
  if (!token) return {};
  return {
    authorization: `Bearer ${token}`
  };
};

export const checkStatus = (response: Response): Promise<Response> => (
  response.ok
    ? Promise.resolve(response)
    : Promise.reject(new Error(`${response.status} ${response.statusText}`))
);

// dev system needs an explicit credentials include for cookie based auth
export const fetchOptions = (getEnvironment() === 'development') ? { credentials: 'include' } : {};
