import Smart from 'hocs/smart';
import { distanceByLegForAsset, activityGroupsForAsset } from 'selectors/legs';
import View from './legsTotals-view';

export default Smart(
  View,
  state => ({
    now: state.app.now
  }),
  ac => ({
    updateFilter: ac.reports.filterAssetReports
  })
);
