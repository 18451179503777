import { debounce } from 'lodash';
import { visibleEventsSelector } from 'selectors/events';

export const setBaseLayer = (mapId, baseLayer) => ({
  type: 'SET_BASE_LAYER',
  payload: { mapId, baseLayer }
});
export const moveLayer = (from, to) => ({
  type: 'MOVE_LAYER',
  payload: {
    from,
    to
  }
});

export const jumpToHomeBase = (mapId, homeBase) => ({
  type: 'JUMP_TO_HOME_BASE',
  payload: {
    mapId,
    homeBase
  }
});

export const setFollow = (mapId, isFollowed) => ({
  type: 'SET_ASSET_FOLLOW',
  payload: {
    mapId,
    isFollowed
  }
});

export const resetMap = mapId => dispatch => {
  dispatch({
    type: 'RESET_MAP',
    payload: {
      mapId
    }
  });
};

export const closeMap = mapId => dispatch => {
  dispatch({
    type: 'CLOSE_MAP',
    payload: mapId
  });
};

const dispatchFov = (mapId, viewport, dispatch) => {
  dispatch({
    type: 'FOV_CHANGED',
    payload: {
      mapId,
      viewport
    }
  });
};
const debouncedFov = {};
export const patchViewport = (mapId, viewport) => dispatch => {
  dispatch({
    type: 'PATCH_VIEWPORT',
    payload: {
      mapId,
      viewport
    },
    hide: true
  });
  if (!debouncedFov[mapId]) {
    debouncedFov[mapId] = debounce(dispatchFov, 200);
  }
  debouncedFov[mapId](mapId, viewport, dispatch);
};

export const centerMapOnEvent = eventId => (dispatch, getState) => {
  const state = getState();
  const { selectedMapId } = state.map;
  const event = visibleEventsSelector(state).find(e => e.id === eventId);
  if (event?.position) {
    dispatch(patchViewport(selectedMapId, {
      longitude: event.position.longitude,
      latitude: event.position.latitude
    }));
  }
};

export const centerMapOnPosition = position => (dispatch, getState) => {
  const state = getState();
  const { selectedMapId } = state.map;
  if (position?.latitude && position?.longitude) {
    dispatch(patchViewport(selectedMapId, {
      longitude: position.longitude,
      latitude: position.latitude
    }));
  }
};

export const updateConfig = (mapId, config) => dispatch => {
  dispatch({
    type: 'UPDATE_MAP_CONFIG',
    payload: {
      mapId,
      config
    }
  });
};

const dispatchCursorPosition = (position, dispatch) => {
  dispatch({
    type: 'UPDATE_CURSOR_POSITION',
    payload: position,
    hide: true
  });
};
const debouncedCursorPosition = debounce(dispatchCursorPosition, 100, { maxWait: 100 });
export const updateCursorPosition = position => dispatch => {
  debouncedCursorPosition(position, dispatch);
};

export const updateMapLayout = (layout, defaultMapSettings) => ({
  type: 'UPDATE_MAP_LAYOUT',
  payload: {
    layout,
    defaultMapSettings
  }
});

export const selectMap = mapId => dispatch => {
  dispatch({
    type: 'SELECT_MAP',
    payload: mapId
  });
};

export const setMissionTargetArea = (mapId, targetArea) => ({
  type: 'SET_MISSION_TARGET_AREA',
  payload: {
    mapId,
    targetArea
  }
});

export const setTrailsOption = (mapId, trailsOption) => ({
  type: 'SET_TRAILS_OPTION',
  payload: {
    mapId,
    trailsOption
  }
});

export const assignItemToMap = (mapId, item) => ({
  type: 'ASSIGN_ITEM_TO_MAP',
  payload: {
    mapId,
    item
  }
});

export const unassignItemFromMap = () => ({
  type: 'UNASSIGN_ITEM_FROM_MAP'
});

export const assignMarkerToAsset = (mapId, assetId, position) => ({
  type: 'ASSIGN_MARKER_TO_ASSET',
  payload: {
    mapId,
    assetId,
    lat: position.lat,
    lng: position.lng
  }
});

export const hideAssetsGroup = groupName => ({
  type: 'HIDE_ASSETS_GROUP',
  payload: groupName
});

export const removeFromHiddenAssetGroups = groupName => ({
  type: 'REMOVE_FROM_HIDDEN_ASSET_GROUPS',
  payload: groupName
});

export const hideAssetsOnMap = assets => ({
  type: 'HIDE_ASSETS_ON_MAP',
  payload: assets
});

export const showAssetsOnMap = assets => ({
  type: 'SHOW_ASSETS_ON_MAP',
  payload: assets
});

export const showAllAssetsOnMap = () => ({
  type: 'SHOW_ALL_ASSETS_ON_MAP'
});

export const updateHiddenInactiveAssets = assetIds => ({
  type: 'UPDATE_HIDDEN_INACTIVE_ASSETS',
  payload: assetIds
});

export const setTrailHighlight = highlightTrail => ({
  type: 'SET_TRAIL_HIGHLIGHT',
  payload: {
    highlightTrail
  }
});

export const zoomIn = (mapId, maxZoom) => dispatch => {
  dispatch({
    type: 'ZOOM_IN',
    payload: { mapId, maxZoom }
  });
};

export const zoomOut = (mapId, minZoom) => dispatch => {
  dispatch({
    type: 'ZOOM_OUT',
    payload: { mapId, minZoom }
  });
};

export const setAdsbAircraft = (mapId, aircraft) => dispatch => {
  dispatch({
    type: 'SET_ADSB_AIRCRAFT',
    payload: { mapId, aircraft }
  });
};
