export const gatewayToTransport = (gateway: string): string => {
  const transport = gateway.toLowerCase().split('.');
  if (transport[0].endsWith('aff2')) {
    return 'AFF2';
  }

  if (transport.includes('sbd')) {
    return 'Satellite';
  }

  switch (transport[0]) {
    case 'gsm':
    case 'cellular':
    case 'tracplus':
      return 'Cellular';
    case 'iridium':
    case 'satellite':
    case 'pecos_sbd':
    case 'inmarsat':
      return 'Satellite';
    case 'msgmediaau':
      return 'SMS';
    case 'aff':
    case 'affxml':
      return 'AFF';
    case 'fieldintellsolara':
      return 'API';
    default:
      if (transport.includes('http')) {
        return 'API';
      }
      return 'Unknown';
  }
};
