import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  markerTooltip: {
    textAlign: 'left',
    backgroundColor: theme.palette.common.white,
    '&:before': {
      borderTopColor: 'rgba(0, 0, 0, .4)',
      display: 'none'
    }
  },
  measurementPanel: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(10),
    minWidth: '260px',
    borderRadius: 4,
    overflow: 'hidden',
    opacity: 1,
    textAlign: 'left',
    zIndex: 999,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 6px 12px'
  },
  reportPanelOpen: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: `1px solid ${theme.palette.common.text}`
  },
  measurementPanelHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 0),
    backgroundColor: 'rgba(0,0,0,.4)',
    '& p': {
      color: theme.palette.common.whiteConstant,
      fontWeight: 500,
      fontSize: '1rem'
    }
  },
  measurementPanelBody: {
    padding: theme.spacing(2, 3),
    backgroundColor: 'rgba(0,0,0,.4)',
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(0)
    },
    '& p': {
      color: theme.palette.common.whiteConstant,
      fontSize: '.9rem',
      whiteSpace: 'nowrap',
      '& small': {
        fontSize: '.6rem'
      },
      '&:first-child': {
        textTransform: 'uppercase',
        fontSize: '.6rem',
        fontWeight: 500
      }
    }
  },
  highContrastBackground: {
    backgroundColor: theme.palette.mapControls.bgHighContrast,
  },
  gridItem: {
    '&:first-child': {
      paddingLeft: theme.spacing(0)
    },
    '&:last-child': {
      paddingRight: theme.spacing(0)
    },
    padding: theme.spacing(0, 1)
  },
  gridContainer: {
    paddingBottom: theme.spacing(2)
  }
}));
