import Smart from 'hocs/smart';
import Statusbar from './statusbar-view';

export default Smart(
  Statusbar,
  () => ({
  }),
  ac => ({
    toggleSystemStatus: ac.ui.toggleSystemStatus
  })
);
