import {
  Box, Checkbox, FormControl, FormControlLabel, FormGroup
} from '@mui/material';
import React from 'react';
import { sharePermissions } from 'apis/trackstar/maps';
import { ClassNameMap } from '@mui/styles';

interface PermissionsCheckboxesProps {
  classes: ClassNameMap;
  selectedPermissions: string;
  handleAccessLevelChange: (permission: string) => void;
}

const PermissionsCheckboxes = ({
  classes,
  selectedPermissions,
  handleAccessLevelChange,
}: PermissionsCheckboxesProps): JSX.Element => (
  <Box sx={{ display: 'flex' }}>
    <FormControl component="fieldset" variant="standard">
      <FormGroup className={classes.permissionsWrapper}>
        {Object.keys(sharePermissions).slice(0, 3).map(p => (
          <FormControlLabel
            key={p}
            control={<Checkbox checked={selectedPermissions?.includes(p)} />}
            label={sharePermissions[p]}
            onChange={() => handleAccessLevelChange(p)}
          />
        ))}
      </FormGroup>
    </FormControl>
    <FormControl component="fieldset" variant="standard">
      <FormGroup className={classes.permissionsWrapper}>
        {Object.keys(sharePermissions).slice(3).map(p => (
          <FormControlLabel
            key={p}
            control={<Checkbox checked={selectedPermissions?.includes(p)} />}
            label={sharePermissions[p]}
            onChange={() => handleAccessLevelChange(p)}
          />
        ))}
      </FormGroup>
    </FormControl>
  </Box>
);
export default PermissionsCheckboxes;
