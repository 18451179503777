import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Page from 'components/pages/page';
import MapContainer from 'components/mapContainer';
import Omnibox from 'components/omnibox';
import AnalysisboxContainer from 'components/analysisboxContainer';
import { useQuery } from 'react-query';
import { fetchAssetsDevices } from 'apis/trackstar/serenity';
import DeviceContextbox from 'components/contextbox/device';
import AssetContextbox from 'components/contextbox/asset';
import useStyles from './map-styles';

const MapPage = ({
  organisationId,
  match,
  location,
  setTextFilter,
  selectItem,
  selectedItem,
  now,
  displaySnackbar,
  contextboxOpen,
  assignItemToMap,
}) => {
  const classes = useStyles();

  const getAssetList = useQuery(
    ['assets', localStorage.getItem('organisationId'), now],
    () => fetchAssetsDevices(now),
    {
      select: data => data.assetsWithDevices,
    }
  );
  if (getAssetList.isError) displaySnackbar({ id: 'getAssetListFailedSnackbar', text: 'Failed to get asset list, please reload the page.', type: 'error' });

  useEffect(() => {
    if (getAssetList.isSuccess && match.path === '/search') {
      const searchQuery = new URLSearchParams(location.search).get('q');
      const selectedAssetId = new URLSearchParams(location.search).get('asset');
      const selectedAsset = getAssetList.data.find(a => a.id.toString() === selectedAssetId);
      if (searchQuery) setTextFilter(searchQuery);
      if (selectedAssetId && selectedAsset) {
        selectItem(selectedAsset);
        assignItemToMap('default', selectedAsset);
      }
    }
  }, [match, location, getAssetList.isSuccess, getAssetList.data, setTextFilter, selectItem, assignItemToMap]);

  return (
    <Page>
      <AnalysisboxContainer selectedAsset={selectedItem} />

      <Omnibox />

      <Box className={classes.mapWrapper}>
        <MapContainer assets={getAssetList.data || []} key={organisationId} />
      </Box>

      <AssetContextbox selectedAsset={selectedItem} now={now} contextboxOpen={contextboxOpen} />

    </Page>
  );
};

export default MapPage;
