import Smart from 'hocs/smart';
import PageView from './page-view';

export default Smart(
  PageView,
  state => ({
    organisationId: state.session.organisation ? state.session.organisationId : 'unknown',
    systemStatusOpen: state.ui.systemStatusOpen
  }),
  () => ({})
);
