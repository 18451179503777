import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  accessLevelBar: {
    display: 'inline-block',
    width: 0,
    backgroundColor: theme.palette.primary.activeGreen,
    height: 10,
    padding: 2,
    margin: 1.5,
  },
  accessGrey: {
    backgroundColor: theme.palette.common.midGrey,
  },
  select: {
    position: 'absolute',
    padding: '5px 5px 5px 15px',
    borderRadius: '4px',
    marginLeft: '20px',
    zIndex: 1,
    minWidth: '204px',
    '& :focus': {
      backgroundColor: 'inherit'
    }
  },
  materialTable: {
    '& .MuiTableSortLabel-icon': {
      fontSize: '18px'
    },
    '& input.MuiInputBase-input': {
      // NOTE: this is an ugly workaround because there isn't a more specific selector for the search input
      padding: theme.spacing(1.8, 0),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '& .MuiPaper-root': {
      marginTop: 0
    },
    '& > div': {
      marginTop: '20px',
      boxShadow: 'none',
      border: theme.border.default
    },
    '& .MuiToolbar-regular': {
      marginTop: '8px',
      paddingRight: '20px'
    },
    '& .MuiInput-formControl': {
      border: theme.border.default
    },
    '& .MuiInput-formControl::before, & .MuiInput-formControl::after': {
      display: 'none'
    },
    '& table td, & table th': {
      borderBottom: theme.border.default
    },
    '& table tr:last-of-type td': {
      borderBottom: 'none'
    },
    '& .MuiTableRow-root': {
      fontSize: '0.95rem'
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(8 / 3)
    },
    '& .MuiTableCell-root.MuiTableCell-paddingNone.MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  tableContainer: {
    padding: 0,
    position: 'relative'
  },
  addButton: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    height: '3rem',
    marginLeft: 8,
    padding: '19.5px 14px 19.5px 10px',
    borderRadius: 24,
    '& .MuiSvgIcon-root': {
      fontSize: '1.8rem'
    }
  },
  permissionsCell: {
    '& span + span::before': {
      whiteSpace: 'pre',
      content: '", "'
    }
  },
  actionButton: {
    color: theme.palette.common.text
  },
  categoryTitle: {
    fontWeight: '600'
  },
  formControl: {
    width: '100%',
  },
  permissionsWrapper: {
    '&.MuiFormGroup-root': {
      '& .MuiFormControlLabel-root': {
        padding: 10
      }
    }
  },
  dialogActions: {
    margin: theme.spacing(0, 1, 1, 0),
  },
  pageWrapper: {
    backgroundColor: theme.palette.common.lightGrey,
    padding: theme.spacing(8, 0, 6, 0),
    flex: 1,
    overflowY: 'auto',
    textAlign: 'left'
  },
  contentWrapper: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.palette.common.lightGrey,
    justifyContent: 'space-around',
    alignItems: 'center',
    overflow: 'auto'
  },
  container: {
    height: 'calc(100% - 60px)'
  },
  omniboxContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  byMeTab: {
    '& .MuiSvgIcon-root': {
      // margin: '0 7px 0 0',
      position: 'relative',
      // top: '7px'
    }
  },
  withMeTab: {
    '& .MuiSvgIcon-root': {
      // margin: '0 12px 0 0',
      position: 'relative',
      // top: '9px'
    }
  },
  Tabs: {
    '& .MuiTab-root': {
      fontSize: '1.3rem',
      fontWeight: '500',
      textTransform: 'capitalize',
      fontFamily: theme.typography.fontFamily,
      minHeight: '55px'
    },
    '& .MuiTab-wrapper': {
      display: 'inline'
    }
  },
  inlineIcon: {
    verticalAlign: 'bottom'
  },
}));
