import React from 'react';
import {
  Grid, Box, Paper, Typography
} from '@mui/material';
import IceContacts from 'components/shared/iceContacts';
import useTranslation from 'hooks/useTranslation';
import useStyles from './asset-styles';

const AssetIceContacts = ({
  deviceId,
  readOnly,
}) => {
  const t = useTranslation('pages.assetView');
  const classes = useStyles();

  return (
    <Box id="emergencycontacts" className={classes.category}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <Typography variant="h5" gutterBottom>{t('emergency.title')}</Typography>
          <Typography variant="body1">{t('emergency.description')}</Typography>
        </Grid>
        <Grid item sm={12} md={8}>
          <Paper className={classes.iceContactsPanelWrapper}>
            <Box className={classes.iceContactsPanel}>
              <IceContacts deviceId={deviceId} readOnly={readOnly} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetIceContacts;
