import makeStyles from '@mui/styles/makeStyles';

export const QRCODE_SIZE = 200;

export default makeStyles(theme => ({
  panel: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 4),
    boxShadow: 'none',
    border: theme.border.default
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  qrCodeWrapper: {
    flex: `0 0 ${QRCODE_SIZE + 8}px`,
    height: `${QRCODE_SIZE + 8}px`,
    border: `4px solid ${theme.palette.common.whiteConstant}`,
    marginLeft: theme.spacing(8)
  },
  label: {
    marginTop: theme.spacing(2)
  },
  editableField: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  extraPadding: {
    marginBottom: theme.spacing(2)
  },
  textField: {
    width: '100%'
  },
  errorText: {
    color: '#ff863e'
  }
}));
