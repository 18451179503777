import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  icon: {
    color: theme.palette.common.whiteConstant
  },
  accountWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(1)
  },
  accountImage: {
    height: '96px',
    width: '96px',
    margin: theme.spacing(1),
    marginRight: theme.spacing(3)
  },
  name: {
    marginTop: theme.spacing(2),
    fontSize: '1.2rem'
  },
  email: {
    fontSize: '1rem'
  },
  offsetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(0, 2)
  },
  hr: {
    width: '100%',
    backgroundColor: theme.border.color,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2)
  },
  list: {
    width: '100%',
    margin: 0,
    padding: 0
  },
  listItem: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    transition: 'all 0.15s',
    '&:hover': {
      backgroundColor: theme.palette.common.lightBlue
    },
    '& .MuiTouchRipple-ripple': {
      color: theme.palette.common.black,
    },
  },
  listIconWrapper: {
    minWidth: '35px'
  },
  iconSmall: {
    width: '24px',
    height: '20px',
    '& line, & polyline, & path, & circle, & polygon': {
      stroke: theme.palette.primary.main,
      fill: 'transparent',
    }
  },
  link: {
    textDecoration: 'none'
  },
  rippleClasses: {
    color: 'green',
  }
}));
