import React from 'react';

const Yacht = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m20.612 16.698c-0.57212-6.7103-4.2666-14.933-4.3054-15.011-0.077576-0.17454-0.21333-0.24242-0.3297-0.23273-0.14545 0.0097-0.24242 0.11636-0.29091 0.23273-0.11636 0.25212-3.7333 8.3684-4.3054 15.011-0.0097 0.08727-0.13576 1.1733-0.09697 3.2194 0.03879 1.92 0.23272 5.0909 0.9503 9.2896 0 0.0097 0 0.01939 0.0097 0.02909 0.04848 0.15515 0.55272 1.3091 3.6557 1.3091h0.20364c3.1321 0 3.6072-1.183 3.6557-1.3188 0-0.0097 0.0097-0.01939 0.0097-0.02909 0.71757-4.1891 0.91151-7.36 0.9503-9.2896 0.02909-2.046-0.09697-3.1224-0.10667-3.2097z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Yacht;
