import React from 'react';
import {
  Box,
  Badge
} from '@mui/material';
import { Email, Phone, Sms } from '@mui/icons-material';

import useStyles from './iceContactSummary-styles';

const Summary = ({
  emergencyContacts
}) => {
  const styles = useStyles();
  const allContacts = Object.values(emergencyContacts).flat();

  const phoneContactCount = allContacts?.filter(e => e.icePhone).length;
  const smsContactCount = allContacts?.filter(e => e.iceSms).length;
  const emailContactCount = allContacts?.filter(e => e.iceEmail).length;
  return (
    <Box className={styles.container}>
      <Box className={styles.row}>
        <Badge overlap="circular" color="primary" invisible={!phoneContactCount || phoneContactCount === 0} badgeContent={phoneContactCount} className={styles.badge}>
          <Phone className={styles.icon} />
        </Badge>
        <Badge overlap="circular" color="primary" invisible={!smsContactCount || smsContactCount === 0} badgeContent={smsContactCount} className={styles.badge}>
          <Sms className={styles.icon} />
        </Badge>
        <Badge overlap="circular" color="primary" invisible={!emailContactCount || emailContactCount === 0} badgeContent={emailContactCount} className={styles.badge}>
          <Email className={styles.icon} />
        </Badge>
      </Box>
    </Box>
  );
};

export default Summary;
